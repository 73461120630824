import * as React from "react";
import cn from "classnames";
import { DocumentProps, PageProps, Document, Page, pdfjs } from "react-pdf";
import { Pagination } from "@jhool-io/fe-components";
import styles from "./DocumentViewer.module.scss";
import { useFetchDocument } from "../../hooks/queries";
import Skeleton from "../Skeleton/Skeleton";
import ListState from "../ListState/ListState";
import { IClientDocument } from "../../utils/types/client";
import { IClientInsuranceAttachment } from "../../modules/clients/types/clients.types";
import { removeEnumUnderscore } from "../../utils/helpers";
import { ISessionNote } from "../../utils/types/notes";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface DocumentViewerProps extends DocumentProps {
    /** class to style the document container */
    wrapperClass?: string;
    /** Number of pages */
    numOfPages?: number;
    /** document google bucket url */
    document?: IClientDocument;
    /** attachement google bucket url */
    attachment?: IClientInsuranceAttachment;
    /** note url containing note content */
    note?: ISessionNote;
}

interface ExtendedPageProps extends PageProps {
    /** class to style document in view pages */
    pageClass?: string;
}

type CommonDocumentProps = {
    url: string;
    title: string;
    contentType: string | null;
    thirdPartyType: "dropbox" | "google_drive" | null;
};

const getCommonDocumentProps = (
    document?: IClientDocument,
    attachment?: IClientInsuranceAttachment,
    note?: ISessionNote
): CommonDocumentProps | null => {
    if (document) {
        return {
            url: document.url,
            title: document.title,
            contentType: document.metadata?.content_type || null,
            thirdPartyType: document.third_party_type,
        };
    }

    if (attachment) {
        return {
            url: attachment.url,
            title: attachment.name,
            contentType: attachment.metadata?.content_type || null,
            thirdPartyType: attachment.third_party_type,
        };
    }

    if (note) {
        return {
            url: String(note.note_url),
            title: `${note.client.first_name} ${
                note.client.last_name
            }'s ${removeEnumUnderscore(note.type)}`,
            contentType: note.note_url_metadata?.content_type || null,
            thirdPartyType: null,
        };
    }

    return null;
};

export default function DocumentViewer({
    wrapperClass,
    pageClass,
    document,
    attachment,
    note,
    numOfPages,
    ...props
}: DocumentViewerProps & ExtendedPageProps) {
    const [numPages, setNumPages] = React.useState<null | number>(
        numOfPages || null
    );
    const [pageNumber, setPageNumber] = React.useState(1);

    const commonProps = getCommonDocumentProps(document, attachment, note);
    const url = commonProps?.url || "";
    const title = commonProps?.title || "";
    const contentType = commonProps?.contentType || "";
    const thirdPartyType = commonProps?.thirdPartyType;

    const { data, error, isLoading } = useFetchDocument(
        url,
        Boolean(!thirdPartyType)
    );

    if (thirdPartyType === "google_drive") {
        if (/^image/.test(contentType)) {
            return (
                <div className={styles.docimg}>
                    <img src={url} alt={title} />
                </div>
            );
        }

        return (
            <div>
                {numPages && (
                    <div role="banner" className={styles.document_header}>
                        <p className={styles.title}>{title}</p>
                        <p className={styles.pages_info}>
                            {pageNumber}/{numPages || "--"} pages
                        </p>
                    </div>
                )}
                <div className={cn(wrapperClass, styles.document_container)}>
                    <Document
                        error={
                            <span className={styles.document_error}>
                                Could not load pdf at this time
                            </span>
                        }
                        loading={
                            <span className={styles.document_loading}>
                                Loading pdf..
                            </span>
                        }
                        onLoadSuccess={({ numPages: nextNumPages }) => {
                            setNumPages(nextNumPages);
                            setPageNumber(1);
                        }}
                        onItemClick={({ pageNumber: itemPageNumber }) => {
                            setPageNumber(Number(itemPageNumber));
                        }}
                        {...props}
                        file={url}
                    >
                        <Page
                            pageNumber={pageNumber}
                            height={550}
                            scale={1.2}
                        />
                    </Document>
                </div>
                {numPages && numPages > 1 && (
                    <Pagination
                        totalCount={numPages}
                        totalPages={numPages}
                        currentPage={pageNumber}
                        onPageChange={(page) => setPageNumber(page)}
                        pageSize={1}
                        isCentered
                    />
                )}
            </div>
        );
    }

    if (isLoading) {
        return (
            <Skeleton
                containerTestId="document-viewer-loader"
                height="400px"
                width="100%"
            />
        );
    }

    if (error) {
        return (
            <ListState
                isError
                errorMsg="Could not load document at this time"
                stateHelperText="Try reloading the page to fix this error"
            />
        );
    }

    if (data && /^image/.test(contentType)) {
        return (
            <div className={styles.docimg}>
                <img src={`data:image/png;base64,${data.data}`} alt={title} />
            </div>
        );
    }

    return (
        <div>
            {numPages && (
                <div role="banner" className={styles.document_header}>
                    <p className={styles.title}>{title}</p>
                    <p className={styles.pages_info}>
                        {pageNumber}/{numPages || "--"} pages
                    </p>
                </div>
            )}
            <div className={cn(wrapperClass, styles.document_container)}>
                <Document
                    error={
                        <span className={styles.document_error}>
                            Could not load pdf at this time
                        </span>
                    }
                    loading={
                        <span className={styles.document_loading}>
                            Loading pdf..
                        </span>
                    }
                    onLoadSuccess={({ numPages: nextNumPages }) => {
                        setNumPages(nextNumPages);
                        setPageNumber(1);
                    }}
                    onItemClick={({ pageNumber: itemPageNumber }) => {
                        setPageNumber(Number(itemPageNumber));
                    }}
                    {...props}
                    file={`data:image/png;base64,${data?.data}`}
                >
                    <Page pageNumber={pageNumber} height={550} scale={1.2} />
                </Document>
            </div>
            {numPages && numPages > 1 && (
                <Pagination
                    totalCount={numPages}
                    totalPages={numPages}
                    currentPage={pageNumber}
                    onPageChange={(page) => setPageNumber(page)}
                    pageSize={1}
                    isCentered
                />
            )}
        </div>
    );
}

import * as React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useNavigate, useParams } from "react-router-dom";
import { useIsMutating } from "@tanstack/react-query";
import {
    Dialog,
    DialogContent,
    Button,
    Table,
    Pagination,
} from "@jhool-io/fe-components";
import AddInsurance from "./AddInsurance/AddInsurance";
import InsuranceDetails from "./InsuranceDetails/InsuranceDetails";
import { APP_COLORS, LISTS_DEFAULT_LIMIT } from "../../../../utils/constants";
import { SupportedUploadMethod } from "../../../../utils/types/filepicker";
import { UserPermisions } from "../../../../utils/types/user";
import { useDoesUserHavePermission } from "../../../../hooks/permissions";
import useIntersectionObserver from "../../../../hooks/useIntersectionObserver";
import InsuranceCardDocuments from "./InsuranceCardDocuments/InsuranceCardDocuments";
import { formatDate } from "../../../../utils/helpers";
import MobileListCard from "../../../../shared-ui/MobileListCard/MobileListCard";
import Progress from "../../../../components/Progress/Progress";
import FilePicker from "../../../../components/FilePicker/FilePicker";
import ListState from "../../../../components/ListState/ListState";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import {
    IClientInsuranceDetails,
    InsuranceTabSubModals,
} from "../../types/clients.types";
import {
    useFetchClientInsuranceHistory,
    useFetchInsuranceProviders,
} from "../../hooks/clients.queries";

enum AddInsuranceForms {
    ADD_INSURANCE = "add-insurance-form",
    UPLOAD_DOCUMENT = "upload-document",
}

type InsuranceTabModals = "add-card" | "add-insurance" | "view-insurance";
type SubmitTypes = "save" | "save-continue";

// Table columns
const insuranceProviderColumns: ColumnDef<IClientInsuranceDetails>[] = [
    {
        header: "INSURANCE PROVIDER",
        cell: ({ row }) => (
            <div className="flex gap-x-2 uppercase">
                <span>{row.original.name}</span>
                {row.original.status === "active" && (
                    <span className="flex items-center gap-2">
                        <span className=" size-1 rounded-full bg-black" />
                        <span className="text-primary">Current</span>
                    </span>
                )}
            </div>
        ),
    },
    {
        accessorKey: "employer_name",
        header: "EMPLOYER NAME",
    },
    {
        accessorKey: "non_client_primary_insured_name",
        header: "NON-CLIENT PRIMARY INSURED NAME",
        cell: ({ row }) => row.original.non_client_primary_insured_name || "--",
    },
    {
        accessorKey: "insurance_accepted",
        header: "INSURANCE ACCEPTED",
        cell: ({ row }) => (
            <span
                style={{
                    color: row.original.insurance_accepted
                        ? APP_COLORS.COLOR_PRIMARY
                        : APP_COLORS.COLOR_DANGER,
                }}
            >
                {(row.original.insurance_accepted && "Yes") || "No"}
            </span>
        ),
    },
    {
        accessorKey: "insurance_member_id",
        header: "INSURANCE MEMBER ID",
    },
    {
        header: "START DATE",
        cell: ({ row }) => (
            <span key={row.id}>
                {formatDate(row.original.start_date, true)}
            </span>
        ),
    },
    {
        header: "END DATE",
        cell: ({ row }) => (
            <span key={row.id}>{formatDate(row.original.end_date, true)}</span>
        ),
    },
];

export default function Insurance() {
    const searchParams = new URLSearchParams(window.location.search);

    // Component local states
    const [modalInView, setModalInView] =
        React.useState<InsuranceTabModals | null>(null);
    const [addInsuranceModalInView, setAddInsuranceModalInView] =
        React.useState<AddInsuranceForms>(AddInsuranceForms.ADD_INSURANCE);
    const [insuranceInViewId, setInsuranceInViewId] = React.useState<
        string | null
    >(null);
    const [submitType, setSubmitType] = React.useState<SubmitTypes | null>(
        null
    );

    const [progressStep, setProgressStep] = React.useState<
        AddInsuranceForms | InsuranceTabSubModals
    >(AddInsuranceForms.ADD_INSURANCE);

    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;

    const navigate = useNavigate();

    const pageFilter = Number(searchParams.get("page")) || 1;
    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;

    const mobileListRef = React.useRef<HTMLDivElement>(null);

    const entry = useIntersectionObserver(mobileListRef, { threshold: 0.2 });
    const isVisible = !!entry?.isIntersecting;

    // check if logged in user can add insurance
    const canAddInsurance = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
        UserPermisions.CLIENT_INFO_INSURANCE_WRITE,
        UserPermisions.CLIENT_INFO_ALL_WRITE,
    ]);

    // Fetch client insurance history
    const { isLoading, data, error, isSuccess } =
        useFetchClientInsuranceHistory(clientId, pageFilter, limitFilter);

    // Hook for fetching insurance providers
    useFetchInsuranceProviders();

    // To determine if mutation is currently running, returns 0 || 1
    const addInsuranceIsMutating = useIsMutating(["add-insurance", clientId]);

    // Function to set modal in view
    const handleSetModalInView = (modal: InsuranceTabModals | null) => {
        setModalInView(modal);
    };

    // Returns insurance whose insurance_id equals insuranceInViewId
    const insuranceDetail = data?.data.find(
        (insurance) => insurance.insurance_id === insuranceInViewId
    );

    // onChange handler for page
    const handlePageChange = (page: number) => {
        searchParams.set("page", String(page));
        navigate(`/clients?${searchParams.toString()}`);
    };

    const handleLimitChange = (limit: number) => {
        searchParams.set("limit", String(limit));
        navigate(`/clients?${searchParams.toString()}`);
    };

    const handleTableRowClick = (insuranceId: string) => {
        setModalInView("view-insurance");
        setInsuranceInViewId(insuranceId);
    };

    return (
        <>
            <Dialog
                open={
                    Boolean(insuranceDetail) && modalInView === "view-insurance"
                }
            >
                <DialogContent
                    showFooter={false}
                    handleCloseDialog={() => handleSetModalInView(null)}
                    wrapperClass="w-[1000px]"
                    classNames="!p-0"
                >
                    <div>
                        {insuranceDetail ? (
                            <InsuranceDetails
                                insuranceDetail={insuranceDetail}
                            />
                        ) : null}
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={modalInView === "add-insurance"}>
                <DialogContent
                    showFooter={
                        addInsuranceModalInView === "add-insurance-form"
                    }
                    variant="center"
                    title={
                        addInsuranceModalInView === "add-insurance-form"
                            ? "Add insurance"
                            : "Upload insurance document"
                    }
                    handleCloseDialog={() => handleSetModalInView(null)}
                    saveText="Save and continue"
                    cancelText="Save changes"
                    canCancelButtonSubmit
                    submitBtnFormValue="add-insurance"
                    cancelBtnFormValue="add-insurance"
                    isCancelBtnDisabled={addInsuranceIsMutating > 0}
                    isSubmitBtnDisabled={addInsuranceIsMutating > 0}
                    onSaveClick={() => {
                        setSubmitType("save-continue");
                    }}
                    onCancelClick={() => setSubmitType("save")}
                >
                    <div>
                        <Progress
                            steps={Object.values(AddInsuranceForms)}
                            currentStep={progressStep}
                            className="top-[75px]"
                        />
                        <div className="pt-20">
                            {addInsuranceModalInView ===
                                AddInsuranceForms.ADD_INSURANCE && (
                                <AddInsurance
                                    onFormSubmit={() => {
                                        if (submitType === "save-continue") {
                                            setProgressStep(
                                                AddInsuranceForms.UPLOAD_DOCUMENT
                                            );
                                            setAddInsuranceModalInView(
                                                AddInsuranceForms.UPLOAD_DOCUMENT
                                            );
                                        } else handleSetModalInView(null);
                                    }}
                                />
                            )}
                            {addInsuranceModalInView ===
                                AddInsuranceForms.UPLOAD_DOCUMENT && (
                                <FilePicker
                                    name="filepicker"
                                    uploadMethods={[
                                        {
                                            method: SupportedUploadMethod.manualupload,
                                        },
                                        {
                                            method: SupportedUploadMethod.googledrive,
                                        },
                                        {
                                            method: SupportedUploadMethod.dropbox,
                                        },
                                    ]}
                                    uploadType="insurance-file"
                                    insuranceId={
                                        localStorage.getItem(
                                            "newInsuranceId"
                                        ) as string
                                    }
                                    successCallback={() => {
                                        setModalInView(null);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <div className="hidden sm:flex justify-between items-center bg-white px-24 py-[29px] mb-16 rounded-r8 border border-strokedark">
                <span className=" text-base font-bold">
                    Insurance Provider History
                </span>
                {canAddInsurance && (
                    <Button
                        aria-label="addInsuranceButton"
                        onClick={() => handleSetModalInView("add-insurance")}
                    >
                        Add insurance
                    </Button>
                )}
            </div>

            <div className="block sm:hidden">
                <Button
                    className="w-full mb-24"
                    onClick={() => handleSetModalInView("add-insurance")}
                >
                    Add insurance
                </Button>

                <h3 className="uppercase mb-16 font-medium text-sm text-black">
                    Insurance Provider History
                </h3>
            </div>

            {isLoading && (
                <Skeleton
                    type="table"
                    count={4}
                    containerTestId="insuranceHistory-loader"
                />
            )}

            {error && (
                <ListState
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display client insurance providers at this time please try again later`
                    }
                    stateHelperText="Try reloading this page to solve this issue"
                    isError
                    listHeader="INSURANCE PROVIDER HISTORY"
                    wrapperClass=" mb-32"
                />
            )}

            {data && isSuccess && data.data.length === 0 && (
                <ListState
                    stateHelperText="Client insurance history will appear here when added"
                    isError={false}
                    emptyMessage="No insurance history yet"
                    listHeader="INSURANCE PROVIDER HISTORY"
                    wrapperClass=" mb-32"
                    emptyBtnProps={{
                        showButton: canAddInsurance,
                        buttonText: "Add insurance",
                        onButtonClick: () =>
                            handleSetModalInView("add-insurance"),
                    }}
                />
            )}

            {isSuccess && data && data.data.length > 0 && (
                <>
                    <div className="hidden sm:block">
                        <Table
                            data={data.data}
                            columns={insuranceProviderColumns}
                            handleRowClick={(row) =>
                                handleTableRowClick(row.original.insurance_id)
                            }
                            hasPagination={data.total_count > 20}
                            pagination={
                                <Pagination
                                    totalCount={data.total_count}
                                    totalPages={data.total_pages}
                                    currentPage={pageFilter}
                                    onPageChange={handlePageChange}
                                    onLimitChange={handleLimitChange}
                                    limit={limitFilter}
                                />
                            }
                        />
                    </div>
                    <div className="block sm:hidden">
                        <div role="table" ref={mobileListRef}>
                            {data.data.map((insurance) => (
                                <MobileListCard
                                    key={insurance.insurance_id}
                                    onClickListItem={() =>
                                        handleTableRowClick(
                                            insurance.insurance_id
                                        )
                                    }
                                    className="mb-12"
                                >
                                    <div
                                        className="flex items-center gap-x-12 text-sm text-left font-semibold uppercase mb-16 p-0 "
                                        aria-label="title button"
                                    >
                                        {insurance.name}
                                        {insurance.status === "active" && (
                                            <>
                                                <span className=" size-1 rounded-full bg-black" />
                                                <span className="text-primary">
                                                    Current
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <div className="flex flex-col gap-y-8 p-12 rounded-r8 bg-card-bg">
                                        <span className="flex gap-x-12 text-xs font-semibold items-center">
                                            <span className="text-gray">
                                                Employer Name:
                                            </span>
                                            <span>
                                                {insurance.employer_name}
                                            </span>
                                        </span>
                                        <span className="flex gap-x-12 text-xs font-semibold items-center">
                                            <span className="text-gray">
                                                Calendar Yr.:
                                            </span>
                                            <span
                                                style={{
                                                    color: insurance.is_cal_year
                                                        ? APP_COLORS.COLOR_PRIMARY
                                                        : APP_COLORS.COLOR_DANGER,
                                                }}
                                            >
                                                {insurance.is_cal_year
                                                    ? "Yes"
                                                    : "No"}
                                            </span>
                                        </span>
                                        <span className="flex gap-x-12 text-xs font-semibold items-center">
                                            <span className="text-gray">
                                                Insurance Accepted:
                                            </span>
                                            <span
                                                style={{
                                                    color: insurance.insurance_accepted
                                                        ? APP_COLORS.COLOR_PRIMARY
                                                        : APP_COLORS.COLOR_DANGER,
                                                }}
                                            >
                                                {insurance.insurance_accepted
                                                    ? "Yes"
                                                    : "No"}
                                            </span>
                                        </span>

                                        <span className="flex gap-x-12 text-xs font-semibold items-center">
                                            <span className="text-gray">
                                                Non-client Primary Insured Name:
                                            </span>
                                            <span>
                                                {insurance.non_client_primary_insured_name ||
                                                    "--"}
                                            </span>
                                        </span>

                                        <span className="flex gap-x-12 text-xs font-semibold items-center">
                                            <span className="text-gray">
                                                Insurance Member ID:
                                            </span>

                                            <span>
                                                {insurance.insurance_member_id ||
                                                    "--"}
                                            </span>
                                        </span>

                                        <span className="flex gap-x-12 text-xs font-semibold items-center">
                                            <span className="text-gray">
                                                Start Date
                                            </span>

                                            <span>
                                                {formatDate(
                                                    insurance.start_date
                                                )}
                                            </span>
                                        </span>
                                    </div>
                                </MobileListCard>
                            ))}
                            {data.total_pages > 1 && isVisible && (
                                <Pagination
                                    totalCount={data.total_count}
                                    totalPages={data.total_pages}
                                    currentPage={pageFilter}
                                    onPageChange={handlePageChange}
                                    limit={limitFilter}
                                    onLimitChange={handleLimitChange}
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
            <div style={{ padding: "40px 0" }}>
                <InsuranceCardDocuments />
            </div>
        </>
    );
}

import { differenceInYears, parse, format } from "date-fns";
import moment from "../moment";
/** CHECK IF CLIENT IS A MINOR */
export const isMinor = (age: number) => {
    return age < 18;
};

export const isClientElderly = (clientDOB: Date) =>
    moment().diff(clientDOB, "years", false) >= 65;

/** GET A CLIENT AGE FROM THEIR DATE OF BIRTH */
export const getClientAge = (dob: string) => {
    const date = parse(
        format(new Date(dob), "dd/MM/yyyy"),
        "dd/MM/yyyy",
        new Date()
    );

    const age = differenceInYears(new Date(Date.now()), date);

    return age;
};

/** FORMAT DATESTAMP HOUR AND MINUTE TO APP FORMAT */
export const handleTimeDisplay = (hr: string, min: string) => {
    const hrText = hr.length === 2 ? String(hr) : `0${hr}`;
    const minText = min.length === 2 ? String(min) : `0${min}`;
    const dayTime = Number(hr) >= 12 ? "pm" : "am";

    return `${hrText}:${minText}${dayTime}`;
};

import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    IApiResponse,
    IPaginatedApiResponse,
} from "../../../utils/types/api-response";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    getSingleClaim,
    fetchClaims,
    fetchClaimSubmissionHistory,
    fetchClaimsAttachments,
} from "../services/claims.services";
import {
    SingleClaimResponse,
    IClaim,
    IClaimsFilters,
    IGetClaimFilter,
    IClaimSubmissionHistoryFilters,
    IClaimSubmissionHistory,
    IGetClaimsAttachment,
    IGetClaimsAttachmentResponse,
} from "../types/claims.types";

export function useGetClaim(filters: IGetClaimFilter, isEnabled: boolean) {
    return useQuery<SingleClaimResponse, AxiosError<SingleClaimResponse>>({
        queryKey: ["get-claim", handleRemoveEmptyParamFromQueryParams(filters)],
        queryFn: () => getSingleClaim(filters),
        enabled: isEnabled,
    });
}

export function useFetchClaimsAttachments(
    params: IGetClaimsAttachment,
    enabled?: boolean
) {
    return useQuery<
        IApiResponse<IGetClaimsAttachmentResponse[]>,
        AxiosError<IApiResponse<IGetClaimsAttachmentResponse[]>>
    >({
        queryKey: ["get-claims-attachment", params.claim_id],
        queryFn: () => fetchClaimsAttachments(params),
        enabled,
    });
}

export function useFetchClaims(filters: IClaimsFilters, enabled?: boolean) {
    return useQuery<
        IPaginatedApiResponse<IClaim[]>,
        AxiosError<IApiResponse<IClaim[]>>
    >({
        queryKey: ["claims", handleRemoveEmptyParamFromQueryParams(filters)],
        queryFn: () => fetchClaims(filters),
        enabled,
    });
}

export function useFetchClaimSubmissionHistory(
    claimId: string,
    filters: IClaimSubmissionHistoryFilters
) {
    return useQuery<
        IPaginatedApiResponse<IClaimSubmissionHistory[]>,
        AxiosError<IApiResponse<IClaimSubmissionHistory[]>>
    >({
        queryKey: [
            "claim-submission-history",
            claimId,
            handleRemoveEmptyParamFromQueryParams(filters),
        ],
        queryFn: () => fetchClaimSubmissionHistory(claimId, filters),
    });
}

import cn from "classnames";
import styles from "../../../../modules/notes/components/NoteContent/NoteContent.module.scss";
import { formatDate, removeEnumUnderscore } from "../../../../utils/helpers";
import { useGetDiagnosisCodesDisplay } from "../../../../hooks/helpers";
import CheckIcon from "../../../Icons/Check";
import NoteContentItem from "../NoteContentItem/NoteContentItem";
import { TERMINATION_NOTE_LABELS } from "../../../../utils/constants";
import { IAddendumDetails } from "../../../../utils/types/notes";
import { AppointmentLocationTeletherapy } from "../../../../utils/types/session";
import useGetDiagnosisCodeToDisplay from "../../../../modules/notes/hooks";

interface ViewTerminationNoteProps {
    noteContent: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    };
    fieldAddendums: {
        [key: string]: IAddendumDetails[];
    } | null;
}

export default function ViewTerminationNote({
    noteContent,
    fieldAddendums,
}: ViewTerminationNoteProps) {
    const firstSessionDate = noteContent.first_session_date
        ? formatDate(noteContent.first_session_date, true)
        : "--";

    const lastSessionDate = noteContent.last_session_date
        ? formatDate(noteContent.last_session_date, true)
        : "--";

    const diagonosisCodes = useGetDiagnosisCodesDisplay(
        noteContent.diagnosis_codes
    );

    return (
        <>
            <NoteContentItem
                headerText={TERMINATION_NOTE_LABELS.appointment_location}
                fieldId="appointment_location"
                addendums={fieldAddendums?.appointment_location}
                content={
                    removeEnumUnderscore(noteContent.appointment_location) ||
                    "--"
                }
            />

            <NoteContentItem
                headerText={TERMINATION_NOTE_LABELS.delivery_option}
                fieldId="delivery_option"
                addendums={fieldAddendums?.delivery_option}
                content={noteContent.delivery_option || "--"}
            />

            {noteContent.other_delivery_option &&
            noteContent.delivery_option ===
                AppointmentLocationTeletherapy.OTHER ? (
                <NoteContentItem
                    headerText={TERMINATION_NOTE_LABELS.other_delivery_option}
                    fieldId="other_delivery_option"
                    addendums={fieldAddendums?.other_delivery_option}
                    content={noteContent.other_delivery_option}
                />
            ) : null}
            <NoteContentItem
                headerText={TERMINATION_NOTE_LABELS.provider_location}
                fieldId="provider_location"
                addendums={fieldAddendums?.provider_location}
                content={noteContent.provider_location || "--"}
            />

            {noteContent.other_provider_location &&
            noteContent.provider_location ===
                AppointmentLocationTeletherapy.OTHER ? (
                <NoteContentItem
                    headerText={TERMINATION_NOTE_LABELS.other_provider_location}
                    fieldId="other_provider_location"
                    addendums={fieldAddendums?.other_provider_location}
                    content={noteContent.other_provider_location}
                />
            ) : null}

            <NoteContentItem
                headerText={
                    TERMINATION_NOTE_LABELS.consent_was_received_for_the_teletherapy_session
                }
                fieldId="consent_was_received_for_the_teletherapy_session"
                addendums={
                    fieldAddendums?.consent_was_received_for_the_teletherapy_session
                }
                content={
                    <div className={styles.checkbox}>
                        <span
                            className={cn(styles.checkbox_wrap, {
                                [styles.checked]:
                                    noteContent.consent_was_received_for_the_teletherapy_session,
                            })}
                        >
                            {" "}
                            <CheckIcon />
                        </span>
                        {
                            TERMINATION_NOTE_LABELS.consent_was_received_for_the_teletherapy_session
                        }
                    </div>
                }
            />

            <NoteContentItem
                headerText={
                    TERMINATION_NOTE_LABELS.the_teletherapy_session_was_conducted_via_video
                }
                fieldId="cthe_teletherapy_session_was_conducted_via_video"
                addendums={
                    fieldAddendums?.the_teletherapy_session_was_conducted_via_video
                }
                content={
                    <div className={styles.checkbox}>
                        <span
                            className={cn(styles.checkbox_wrap, {
                                [styles.checked]:
                                    noteContent.the_teletherapy_session_was_conducted_via_video,
                            })}
                        >
                            {" "}
                            <CheckIcon />
                        </span>
                        {
                            TERMINATION_NOTE_LABELS.the_teletherapy_session_was_conducted_via_video
                        }
                    </div>
                }
            />

            <NoteContentItem
                headerText={TERMINATION_NOTE_LABELS.first_session_date}
                fieldId="first_session_date"
                addendums={fieldAddendums?.first_session_date}
                content={firstSessionDate}
            />
            <NoteContentItem
                headerText={TERMINATION_NOTE_LABELS.last_session_date}
                fieldId="last_session_date"
                addendums={fieldAddendums?.last_session_date}
                content={lastSessionDate}
            />

            <NoteContentItem
                headerText={TERMINATION_NOTE_LABELS.total_number_of_sessions}
                fieldId="total_number_of_sessions"
                addendums={fieldAddendums?.total_number_of_sessions}
                content={noteContent.total_number_of_sessions || 0}
            />

            <NoteContentItem
                headerText={TERMINATION_NOTE_LABELS.diagnosis_codes}
                fieldId="diagnosis_codes"
                addendums={fieldAddendums?.diagnosis_codes}
                content={
                    diagonosisCodes && Array.isArray(diagonosisCodes) ? (
                        <div className={styles.checkboxes}>
                            {diagonosisCodes.map((code) => (
                                <div className={styles.checkbox} key={code}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: true,
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    {code}
                                </div>
                            ))}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={TERMINATION_NOTE_LABELS.secondary_diagnosis}
                fieldId="secondary_diagnosis"
                addendums={fieldAddendums?.secondary_diagnosis}
                content={useGetDiagnosisCodeToDisplay(
                    noteContent.secondary_diagnosis
                )}
            />
            <NoteContentItem
                headerText={TERMINATION_NOTE_LABELS.tertiary_diagnosis}
                fieldId="tertiary_diagnosis"
                addendums={fieldAddendums?.tertiary_diagnosis}
                content={useGetDiagnosisCodeToDisplay(
                    noteContent.tertiary_diagnosis
                )}
            />
            <NoteContentItem
                headerText={TERMINATION_NOTE_LABELS.presenting_problems}
                fieldId="presenting_problems"
                addendums={fieldAddendums?.presenting_problems}
                content={noteContent.presenting_problems || "--"}
            />

            <NoteContentItem
                headerText={TERMINATION_NOTE_LABELS.planned_treatment_and_goals}
                fieldId="planned_treatment_and_goals"
                addendums={fieldAddendums?.planned_treatment_and_goals}
                content={noteContent.planned_treatment_and_goals || "--"}
            />

            <NoteContentItem
                headerText={TERMINATION_NOTE_LABELS.course_of_treatment}
                fieldId="course_of_treatment"
                addendums={fieldAddendums?.course_of_treatment}
                content={noteContent.course_of_treatment || "--"}
            />

            <NoteContentItem
                headerText={TERMINATION_NOTE_LABELS.patient_final_condition}
                fieldId="patient_final_condition"
                addendums={fieldAddendums?.patient_final_condition}
                content={noteContent.patient_final_condition || "--"}
            />

            <NoteContentItem
                headerText={TERMINATION_NOTE_LABELS.prognosis}
                fieldId="prognosis"
                addendums={fieldAddendums?.prognosis}
                content={noteContent.prognosis || "--"}
            />
            <NoteContentItem
                headerText={TERMINATION_NOTE_LABELS.reason_for_termination}
                fieldId="reason_for_termination"
                addendums={fieldAddendums?.reason_for_termination}
                content={noteContent.reason_for_termination || "--"}
            />

            <NoteContentItem
                headerText={
                    TERMINATION_NOTE_LABELS.discharge_plan_and_follow_up
                }
                fieldId="discharge_plan_and_follow_up"
                addendums={fieldAddendums?.discharge_plan_and_follow_up}
                content={noteContent.discharge_plan_and_follow_up || "--"}
            />
        </>
    );
}

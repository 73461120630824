import { Input, Select } from "@jhool-io/fe-components";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { STATES } from "../../../../utils/helpers/us-states/us-states";
import {
    PHONE_REGEX,
    normalizePhoneNumber,
} from "../../../../utils/helpers/phonenumber/phonenumber";
import { ICreatePractice } from "../../types/practices.types";
import { useCreatePractice } from "../../hooks/practices.mutations";
import useToast from "../../../../hooks/useToast";

interface AddPracticeFormProps {
    onFormSubmit: () => void;
}

type Option = {
    label: React.ReactNode;
    value: boolean;
};

const schema = yup.object({
    practice_id: yup
        .string()
        .uuid("Practice id must be a valid uuid")
        .required("Practice id is required"),
    practice_name: yup.string().required("Practice name is required"),
    npi: yup.string(),
    primary_address: yup.string(),
    state: yup.string(),
    primary_contact_email: yup
        .string()
        .test("email-validation", "Please enter a valid email", (value) => {
            if (value && value.length > 0) {
                return yup.string().email().isValidSync(value);
            }
            return true;
        }),
    primary_contact_phone_no: yup.string().test({
        test: (val) => (val ? PHONE_REGEX.test(val) : true),
        message: "Field should only contain numbers",
    }),
});

export default function AddPracticeForm({
    onFormSubmit,
}: AddPracticeFormProps) {
    const {
        control,
        handleSubmit,
        register,
        formState: { errors },
        watch,
        setValue,
    } = useForm<ICreatePractice>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const { mutate } = useCreatePractice();

    const queryClient = useQueryClient();

    const { toast } = useToast();

    // Watch for when contact values in our form change
    const contact = watch("primary_contact_phone_no");

    // Get states as select options
    const getClientStateFieldSelectOptions = STATES.map((state) => ({
        label: state.name,
        value: state.abbrv,
    }));

    const onSubmit = (payload: ICreatePractice) => {
        const dataToSend = {
            ...payload,
            npi: payload.npi || undefined,
            primary_address: payload.primary_address || undefined,
            primary_contact_email: payload.primary_contact_email || undefined,
            primary_contact_phone_no:
                payload.primary_contact_phone_no || undefined,
        };

        mutate(dataToSend, {
            onSuccess: (res) => {
                queryClient.invalidateQueries({
                    queryKey: ["practices"],
                });

                toast({
                    mode: "success",
                    message: res.message || "Practice added successfully",
                });
                onFormSubmit();
            },
            onError: (error) => {
                toast({
                    mode: "error",
                    message:
                        error.response?.data.message ||
                        "Could not add new practice at this time",
                });
            },
        });
    };

    // Run helper to format contact values when it change
    React.useEffect(() => {
        if (contact) {
            setValue("primary_contact_phone_no", normalizePhoneNumber(contact));
        }
    }, [contact, setValue]);

    return (
        <form id="add-practice" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("practice_id")}
                    label="Practice Id"
                    hasError={!!errors.practice_id}
                    errorText={errors.practice_id?.message}
                />
                <Input
                    {...register("practice_name")}
                    label="Practice name"
                    hasError={!!errors.practice_name}
                    errorText={errors.practice_name?.message}
                />
            </div>
            <div className="fg">
                <Input {...register("npi")} label="NPI" />
            </div>
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("primary_address")}
                    label="Primary address"
                />
                <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="State"
                            placeholder="State"
                            options={getClientStateFieldSelectOptions}
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                            isSearchable
                        />
                    )}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Input
                    {...register("primary_contact_email")}
                    label="Primary contact email"
                    hasError={!!errors.primary_contact_email}
                    errorText={errors.primary_contact_email?.message}
                />
                <Input
                    {...register("primary_contact_phone_no")}
                    label="Primary contact phone"
                    hasError={!!errors.primary_contact_phone_no}
                    errorText={errors.primary_contact_phone_no?.message}
                />
            </div>
        </form>
    );
}

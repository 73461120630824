import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    IMatchedPayment,
    IMatchedPaymentListFilterTypes,
    IUnmatchedPayment,
    IUnmatchedPaymentFilterTypes,
} from "../types/payments.types";
import {
    IApiResponse,
    IPaginatedApiResponse,
} from "../../../utils/types/api-response";
import {
    fetchMatchedPayments,
    fetchUnMatchedPayments,
} from "../services/payments.services";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";

export function useFetchMatchedPayment(
    filters: IMatchedPaymentListFilterTypes
) {
    return useQuery<
        IPaginatedApiResponse<IMatchedPayment[]>,
        AxiosError<IApiResponse<IMatchedPayment[]>>
    >({
        queryKey: ["matched", handleRemoveEmptyParamFromQueryParams(filters)],
        queryFn: () => fetchMatchedPayments(filters),
    });
}

export function useFetchUnmatchedPayment(
    filters: IUnmatchedPaymentFilterTypes
) {
    return useQuery<
        IPaginatedApiResponse<IUnmatchedPayment[]>,
        AxiosError<IApiResponse<IUnmatchedPayment[]>>
    >({
        queryKey: ["unmatched", handleRemoveEmptyParamFromQueryParams(filters)],
        queryFn: () => fetchUnMatchedPayments(filters),
    });
}

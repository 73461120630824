import { useErrorBoundary } from "react-error-boundary";
import styles from "./AppFallback.module.scss";
import Button from "../../Button/Button";

export default function AppFallback() {
    const { resetBoundary } = useErrorBoundary();

    return (
        <div className={styles.app}>
            <div className={styles.wrap}>
                <p className={styles.logo}>Mantle</p>
                <h3 className="styles.">Something went wrong</h3>
                <p>
                    You just encountered an unexpected error. We have been made
                    aware of it and we will address this issue as quickly as we
                    can. Please use the feedback button to provide more
                    information on the issue. Thank you for your understanding.
                </p>
                <Button
                    type="button"
                    size="small"
                    mode="primary"
                    onClick={resetBoundary}
                    style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginTop: "20px",
                    }}
                >
                    Go back
                </Button>
            </div>
        </div>
    );
}

import axios from "../../../utils/axios";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    IAddUserRestrictionPayload,
    IDeleteRestrictedUserPayload,
    IRestrictedUsersFilterTypes,
} from "../types/client-chart-restriction.types";

// Endpoint to restrict internal user from viewing client note
export async function addUserRestriction(payload: IAddUserRestrictionPayload) {
    const response = await axios.post(`/user/restrict-client-access`, payload);
    return response.data;
}

// endpoint to delete internal user restriction
export async function deleteUserRestriction(
    payload: IDeleteRestrictedUserPayload[]
) {
    const response = await axios.delete(
        `/user/restrict-client-access
    `,
        { data: payload }
    );
    return response.data;
}

// Endpoint to fetch all restricted users
export async function fetchRestrictedUsers(
    filters: IRestrictedUsersFilterTypes
) {
    const response = await axios.get(`/user/restrict-client-access`, {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });
    return response.data;
}

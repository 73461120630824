/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import cn from "classnames";
import { ColumnDef, Row } from "@tanstack/react-table";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import {
    Button,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    Table,
    Tag,
} from "@jhool-io/fe-components";
import Skeleton from "../../Skeleton/Skeleton";
import styles from "./List.module.scss";
import {
    ClientStatus,
    FinancialAssistanceStatus,
    IClient,
    INewClientDetails,
} from "../../../utils/types/client";
import AddClient from "../AddPersonalInfo/AddPersonalInfo";
import {
    useFetchClientList,
    useFetchInsuranceProviders,
} from "../../../hooks/queries/client";
import ListState from "../../ListState/ListState";
import { useDebounce } from "../../../hooks/helpers";
import { useAddClient } from "../../../hooks/mutations/client";
import Progress from "../../Progress/Progress";
import { IPaginatedApiResponse } from "../../../utils/types/api-response";
import useToast from "../../../hooks/useToast";
import { UserPermisions, UserRole } from "../../../utils/types/user";
import { useDoesUserHavePermission } from "../../../hooks/permissions";
import MobileListItem from "../../MobileListItem/MobileListItem";
import { isMinor } from "../../../utils/helpers/age";
import Navbar from "../../Navbar/Navbar";
import {
    handleDisplayClientEmailCorrectly,
    isSmallScreen,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    truncateString,
} from "../../../utils/helpers";
import SearchWithSuggestions from "../../SearchWithSuggestions/SearchWithSuggestions";
import { LISTS_DEFAULT_LIMIT } from "../../../utils/constants";
import CheckPrimaryColorIcon from "../../Icons/CheckPrimaryColor";
import AddInsurance from "../../../modules/clients/components/Insurance/AddInsurance/AddInsurance";
import useShowInsuranceOverSelfPay from "../../../modules/practice-settings/hooks/useShowInsuranceOverSelfPay";
import FilterButton from "../../../shared-ui/Buttons/FilterButton/FilterButton";
import { useAppSelector } from "../../../hooks/useRedux";

enum FormTypes {
    ADD_PERSONAL_INFO = "add-personal-info",
    ADD_INSURANCE = "add-insurance",
}

type SubmitTypes = "save" | "save_continue";

export default function List() {
    const [searchParams, setSearchParams] = useSearchParams();

    // Local component states
    const [rowSelection, setRowSelection] = React.useState({});
    const [formInView, setFormInView] = React.useState<FormTypes | null>(null);
    const [progressStep, setProgressStep] = React.useState(
        FormTypes.ADD_PERSONAL_INFO
    );
    const [submitType, setSubmitType] = React.useState<SubmitTypes | null>(
        null
    );
    const [addClientFormValues] = React.useState<INewClientDetails>();

    const search = searchParams.get("search") || "";
    const insuranceProvider = searchParams.get("insurance_provider") || "";
    const financialAssistanceStatus =
        searchParams.get("financial_assistance_status") || "";

    const { practice } = useAppSelector((state) => state.userPractice);

    const insuranceProviders = useFetchInsuranceProviders();

    const showInsOverSelfPay = useShowInsuranceOverSelfPay();

    const debouncedValue = useDebounce(search, 500);

    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: "",
        direction: "",
    });

    // check if logged in user can edit personal info
    const canAddClient = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
        UserPermisions.CLIENT_INFO_ALL_WRITE,
        UserPermisions.CLIENT_INFO_PERSONAL_WRITE,
    ]);

    const canViewInsuranceProvidersList = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_INSURANCE_READ,
        UserPermisions.CLIENT_INFO_INSURANCE_WRITE,
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
    ]);

    const pageFilter = Number(searchParams.get("page")) || 1;
    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;

    const handleSetClientStatusFilter = () => {
        if (searchParams.get("status")) {
            if (searchParams.get("status") === "all") {
                return "";
            }
            return searchParams.get("status");
        }
        return ClientStatus.ACTIVE;
    };

    const clientStatusFilter = handleSetClientStatusFilter() || "";

    const handleSetProviderId = () => {
        if (
            !practice?.roles?.includes(UserRole.AUDITOR) &&
            practice?.roles?.includes(UserRole.PROVIDER)
        ) {
            return practice?.provider_id;
        }

        return "";
    };

    const sortableColumns = [
        "client_name",
        "preferred_name",
        "pronouns",
        "age",
        "contact",
        "email",
    ];

    // Hook for fetching all clients
    const { data, isLoading, isSuccess, error } = useFetchClientList({
        page: pageFilter,
        search_string: debouncedValue.toLowerCase(),
        limit: limitFilter,
        insurance_provider_id: insuranceProvider || "",
        provider_id: handleSetProviderId() as string,
        sort_attribute: searchParams.get("sort_attr") || sortConfig.key,
        sort_order: searchParams.get("sort_order") || sortConfig.direction,
        financial_assistance_status: financialAssistanceStatus,
        client_status: clientStatusFilter,
    });

    // Query client
    const queryClient = useQueryClient();

    // Hook for toast
    const { toast } = useToast();

    const navigate = useNavigate();

    // Hook for creating new client
    const addClient = useAddClient();

    const handleRowClick = (row: Row<IClient>) => {
        navigate(`/clients/${row.original.client_id}`);
    };

    const handleMobileItemClick = (clientId: string) => {
        navigate(`/clients/${clientId}`);
    };

    const handleSetFormInView = (form: FormTypes | null) => {
        setFormInView(form);
    };

    // Check if add insurance mutation is currently running, returns 0 || 1
    const addClientIsMutating = useIsMutating([
        "add-insurance",
        data?.data[0]?.client_id || "",
    ]);

    // Function for handling add client personal detail form submission
    const handleAddClientPersonalDetails = (payload: INewClientDetails) => {
        if (payload) {
            addClient.mutate(payload, {
                onSuccess: async ({ client, message }) => {
                    queryClient.setQueriesData<
                        IPaginatedApiResponse<IClient[]>
                    >(["clients", 1, ""], (prev) => {
                        if (!prev) {
                            return {
                                count: 1,
                                total_count: 0,
                                current_page: 0,
                                total_pages: 0,
                                message: "Clients fetched successfully!",
                                data: [client],
                            };
                        }
                        return {
                            ...prev,
                            count: prev.count + 1,
                            total_count: prev.total_count + 1,
                            message: "Clients fetched successfully!",
                            data: [client, ...prev.data],
                        };
                    });
                    await queryClient.invalidateQueries({
                        queryKey: ["clients"],
                    });
                    toast({
                        mode: "success",
                        message: message || "Client created successfully!",
                    });

                    if (submitType === "save_continue") {
                        handleSetFormInView(FormTypes.ADD_INSURANCE);
                        setProgressStep(FormTypes.ADD_INSURANCE);
                    } else {
                        handleSetFormInView(null);
                    }
                },
                onError: (err) => {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Couldn't add client personal info at this time",
                    });
                },
            });
        }
    };

    const handleInsuranceProviderChange = (id: string) => {
        if (id === "") searchParams.delete("insurance_provider");
        else searchParams.set("insurance_provider", id);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleFinancialAssistanceStatusChange = (fa: string) => {
        if (fa === "") searchParams.delete("financial_assistance_status");
        else searchParams.set("financial_assistance_status", fa);
        searchParams.set("page", "1");

        setSearchParams(searchParams);
    };

    // Function to call when submitType === "save"
    const handleSubmitAndClose = () => {
        setSubmitType("save");
        handleAddClientPersonalDetails(
            addClientFormValues as INewClientDetails
        );
    };

    // Function to call when submitType === "save_continue"
    const handleSubmitAndContinue = () => {
        setSubmitType("save_continue");
        handleAddClientPersonalDetails(
            addClientFormValues as INewClientDetails
        );
    };

    // Get submit and cancel button "form" value for different submitType
    const addClientPersonalInfoFormValue =
        submitType === "save"
            ? "add-personal-info-save"
            : "add-personal-info-continue";

    // onChange handler for search input
    const handleSetSearchString = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") searchParams.delete("search");
        else searchParams.set("search", e.target.value);
        searchParams.set("page", "1");

        setSearchParams(searchParams);
    };

    const handleClientStatusChange = (status: string) => {
        searchParams.set("status", status);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    // onChange handler for page
    const handlePageChange = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    // onChange handler for limit
    const handleLimitChange = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    const insuranceProviderOptionsForDropdown = showInsOverSelfPay
        ? [
              ...(insuranceProviders?.data?.data || []).map((insurance) => ({
                  insurance_provider_id: insurance.insurance_provider_id,
                  alias: insurance.alias,
              })),
          ]
        : [
              {
                  insurance_provider_id: "self_pay",
                  alias: "Self pay",
              },
              ...(insuranceProviders?.data?.data || []).map((insurance) => ({
                  insurance_provider_id: insurance.insurance_provider_id,
                  alias: insurance.alias,
              })),
          ];

    const getInsuranceTextToDisplay = React.useMemo(
        () =>
            showInsOverSelfPay
                ? [...(insuranceProviders?.data?.data || [])].find(
                      (provider) =>
                          provider.insurance_provider_id === insuranceProvider
                  )?.alias
                : [
                      {
                          insurance_provider_id: "self_pay",
                          alias: "Self pay",
                      },
                      ...(insuranceProviders?.data?.data || []),
                  ].find(
                      (provider) =>
                          provider.insurance_provider_id === insuranceProvider
                  )?.alias,
        [showInsOverSelfPay, insuranceProviders?.data?.data, insuranceProvider]
    );

    // Get client list count
    let clientsCount;
    if (error) clientsCount = "";
    else if (isLoading) clientsCount = "";
    else if (data?.data && data?.data.length === 0) clientsCount = 0;
    else if (data?.data && data?.data.length > 0)
        clientsCount = data.total_count;

    // Cached search suggestions Implementation
    const mantleClientCacheKey = "MANTLE_CACHED_CLIENT_SEARCHES";
    const cachedSearches = localStorage.getItem(mantleClientCacheKey);
    const parsedCachedSearches: string[] = React.useMemo(() => {
        if (cachedSearches) {
            return JSON.parse(cachedSearches);
        }
        return [];
    }, [cachedSearches]);

    // function handling client search cache implementation
    const storeInCache = React.useCallback(
        (val: string) => {
            if (cachedSearches) {
                // if val is already in list, move it to the top
                if (parsedCachedSearches.includes(val)) {
                    // delete item
                    parsedCachedSearches.splice(
                        parsedCachedSearches.indexOf(val),
                        1
                    );
                    // add item to the start of the list
                    parsedCachedSearches.unshift(val);
                } else {
                    parsedCachedSearches.unshift(val);
                    parsedCachedSearches.splice(
                        parsedCachedSearches.length - 1
                    );
                }

                // update cache with latest values
                localStorage.setItem(
                    mantleClientCacheKey,
                    JSON.stringify(parsedCachedSearches)
                );
            } else {
                // if we dont have a list already, initialize one & add the searchVal to the start
                const cachedSearchesArr = new Array(5);
                cachedSearchesArr[0] = val;

                localStorage.setItem(
                    mantleClientCacheKey,
                    JSON.stringify(cachedSearchesArr)
                );
            }
        },
        [cachedSearches, parsedCachedSearches]
    );

    // only store debounced searchTerm in cache if
    // there are results for it
    if (data && data?.total_count > 0 && debouncedValue) {
        storeInCache(debouncedValue);
    }

    const columns: ColumnDef<IClient>[] = React.useMemo(
        () => [
            {
                accessorKey: "client_name",
                header: "CLIENT NAME",
                cell: ({ row }) => (
                    <span className={styles.client_name}>
                        {row.original.first_name} {row.original.last_name}
                        {row.original.age < 18 ? (
                            <>
                                <svg
                                    width="2"
                                    height="2"
                                    viewBox="0 0 2 2"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ margin: "0 6px" }}
                                >
                                    <circle
                                        cx="1"
                                        cy="1"
                                        r="1"
                                        fill="#576A81"
                                    />
                                </svg>
                                <span className={styles.minor}>Minor</span>
                            </>
                        ) : null}
                    </span>
                ),
            },
            {
                accessorKey: "client_chart_number",
                header: "Client Chart number",
                cell: ({ row }) => row.original.client_chart_number || "--",
            },
            {
                accessorKey: "preferred_name",
                header: "PREFERRED NAME",
                cell: ({ row }) => (
                    <span className="capitalize">
                        {row.original.preferred_name || "--"}
                    </span>
                ),
            },
            {
                accessorKey: "pronouns",
                header: "PRONOUNS",
                cell: ({ row }) => (
                    <span className="capitalize">
                        {row.original.pronouns || "--"}
                    </span>
                ),
            },
            {
                accessorKey: "age",
                header: "AGE",
            },
            {
                accessorKey: "contact",
                header: "CONTACT",
            },
            {
                accessorKey: "email",
                header: "EMAIL",
                cell: ({ row }) =>
                    handleDisplayClientEmailCorrectly(row.original.email),
            },
            {
                accessorKey: "client_status",
                header: "CLIENT STATUS",
                cell: ({ row }) => {
                    let bgColor: string;
                    let textColor: string;

                    switch (row.original.client_status) {
                        case ClientStatus.INACTIVE:
                            textColor = "#981F41";
                            bgColor = "rgba(251, 199, 198, 0.50)";
                            break;
                        default:
                            textColor = "#00563E";
                            bgColor = "rgba(204, 250, 233, 0.50)";
                            break;
                    }

                    return (
                        <Tag
                            textColor={textColor}
                            bgColor={bgColor}
                            title={row.original.client_status}
                            className="font-normal px-8 py-4 h-24 rounded-r4 capitalize"
                        />
                    );
                },
            },
            {
                accessorKey: "insurance_provider_name",
                header: "Insurance Provider",
                cell: ({ row }) => (
                    <span
                        className={
                            row.original.self_pay && !showInsOverSelfPay
                                ? "normal-case"
                                : "uppercase"
                        }
                    >
                        {row.original.self_pay && !showInsOverSelfPay
                            ? "Self pay"
                            : (row.original.insurance_provider_name &&
                                  removeEnumUnderscore(
                                      row.original.insurance_provider_name || ""
                                  )) ||
                              "--"}
                    </span>
                ),
            },
            {
                accessorKey: "financial_assistance_status",
                header: "Financial assistance status",
                cell: ({ row }) => (
                    <span className=" capitalize">
                        {row.original.financial_assistance_status || "--"}
                    </span>
                ),
            },
            {
                accessorKey: "alt_email_sent",
                header: "ALT EMAIL SENT",
                cell: ({ row }) => (
                    <span
                        className={
                            row.original.alt_email_sent
                                ? "text-primary"
                                : "text-danger"
                        }
                    >
                        {row.original.alt_email_sent ? "Yes" : "No"}
                    </span>
                ),
            },
        ],
        [showInsOverSelfPay]
    );

    return (
        <>
            <Dialog open={Boolean(formInView)}>
                <DialogContent
                    title="Add client"
                    variant="center"
                    submitBtnFormValue={
                        formInView === "add-personal-info"
                            ? addClientPersonalInfoFormValue
                            : "add-insurance"
                    }
                    showFooter
                    showBackBtn={formInView === "add-insurance"}
                    saveText={
                        formInView === "add-insurance"
                            ? "Add Client"
                            : "Save and Continue"
                    }
                    cancelText={
                        formInView === "add-insurance" ? "Cancel" : "Save"
                    }
                    isCancelBtnDisabled={
                        addClient.isLoading || addClientIsMutating > 0
                    }
                    isSubmitBtnDisabled={
                        addClient.isLoading || addClientIsMutating > 0
                    }
                    handleCloseDialog={() => handleSetFormInView(null)}
                    onCancelClick={
                        formInView === "add-personal-info"
                            ? handleSubmitAndClose
                            : () => handleSetFormInView(null)
                    }
                    onSaveClick={
                        formInView === "add-personal-info"
                            ? handleSubmitAndContinue
                            : undefined
                    }
                    onBackClick={() => {
                        setProgressStep(FormTypes.ADD_PERSONAL_INFO);
                        handleSetFormInView(FormTypes.ADD_PERSONAL_INFO);
                    }}
                    canCancelButtonSubmit={formInView === "add-personal-info"}
                    cancelBtnFormValue="add-personal-info-save"
                >
                    <div>
                        <Progress
                            steps={Object.values(FormTypes)}
                            currentStep={progressStep}
                            className="top-[75px]"
                        />
                        <div className="pt-20">
                            {formInView === "add-personal-info" && (
                                <>
                                    <span className={styles.form_header}>
                                        Personal info
                                    </span>
                                    <AddClient
                                        onFormSubmit={
                                            handleAddClientPersonalDetails
                                        }
                                        submitType={submitType}
                                    />
                                </>
                            )}
                            {formInView === "add-insurance" && (
                                <>
                                    <span className={styles.form_header}>
                                        Insurance Info
                                    </span>
                                    <AddInsurance
                                        newClientId={data?.data[0].client_id}
                                        onFormSubmit={() =>
                                            handleSetFormInView(null)
                                        }
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <Navbar title={`Clients (${clientsCount})`} />
            <div className="page">
                <div className="flex justify-between items-center flex-wrap mb-32 bg-white rounded-r8 px-12 py-16">
                    <div className="flex  gap-12 flex-col ">
                        <SearchWithSuggestions
                            className={styles.search}
                            placeholder="Search by name, email, contact, chart number, or insurance id..."
                            onChange={handleSetSearchString}
                            value={search}
                            suggestions={parsedCachedSearches.filter(
                                (item) => item
                            )}
                            onSuggestionSelect={(val) => {
                                const e = { target: { value: val } };

                                // mimic onChange event target..value structure to invoke the same changeHandler
                                handleSetSearchString(
                                    e as React.ChangeEvent<HTMLInputElement>
                                );
                            }}
                        />

                        <div className="flex items-center gap-12 flex-wrap ">
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <FilterButton
                                        text={
                                            clientStatusFilter === ""
                                                ? "All"
                                                : makeStringFirstLetterCapital(
                                                      clientStatusFilter
                                                  )
                                        }
                                    />
                                </DropdownTrigger>

                                <DropdownContent
                                    align="end"
                                    maxHeight={216}
                                    className="overflow-y-auto"
                                    width="auto"
                                >
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            {
                                                "text-primary":
                                                    clientStatusFilter === "",
                                            }
                                        )}
                                        onClick={() =>
                                            handleClientStatusChange("all")
                                        }
                                    >
                                        All
                                        {clientStatusFilter === "" ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>

                                    {Object.values(ClientStatus).map(
                                        (status) => (
                                            <DropdownItem
                                                className={cn(
                                                    "flex gap-x-8 items-center capitalize text-xs font-medium",
                                                    {
                                                        "text-primary":
                                                            clientStatusFilter ===
                                                            status,
                                                    }
                                                )}
                                                key={status}
                                                onClick={() =>
                                                    handleClientStatusChange(
                                                        status
                                                    )
                                                }
                                            >
                                                {status}
                                                {clientStatusFilter ===
                                                status ? (
                                                    <CheckPrimaryColorIcon />
                                                ) : null}
                                            </DropdownItem>
                                        )
                                    )}
                                </DropdownContent>
                            </Dropdown>
                            {canViewInsuranceProvidersList && (
                                <Dropdown>
                                    <DropdownTrigger
                                        asChild
                                        disabled={
                                            Boolean(insuranceProviders.error) ||
                                            insuranceProviders.isLoading
                                        }
                                    >
                                        <FilterButton
                                            classNames={
                                                getInsuranceTextToDisplay
                                                    ? "uppercase"
                                                    : "normal-case"
                                            }
                                            text={
                                                (insuranceProviders.isLoading &&
                                                    "Loading..") ||
                                                (insuranceProviders.error &&
                                                    "Error loading providers") ||
                                                (insuranceProviders?.data
                                                    ?.data &&
                                                getInsuranceTextToDisplay
                                                    ? truncateString(
                                                          removeEnumUnderscore(
                                                              getInsuranceTextToDisplay ||
                                                                  ""
                                                          )
                                                      )
                                                    : "Insurance")
                                            }
                                        />
                                    </DropdownTrigger>
                                    <DropdownContent
                                        align={
                                            isSmallScreen() ? "start" : "end"
                                        }
                                        width={
                                            isSmallScreen() ? "250px" : "auto"
                                        }
                                        maxHeight={216}
                                        className="overflow-y-auto"
                                    >
                                        <DropdownItem
                                            className={cn(
                                                "uppercase text-xs font-medium flex items-center gap-x-8",
                                                {
                                                    "text-primary":
                                                        !insuranceProvider,
                                                }
                                            )}
                                            onClick={() =>
                                                handleInsuranceProviderChange(
                                                    ""
                                                )
                                            }
                                        >
                                            All
                                            {!insuranceProvider ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                        {insuranceProviderOptionsForDropdown
                                            .sort((a, b) =>
                                                a.alias.localeCompare(b.alias)
                                            )
                                            .map((provider) => (
                                                <DropdownItem
                                                    className={cn(
                                                        "uppercase text-xs font-medium flex items-center gap-x-8",
                                                        {
                                                            "text-primary":
                                                                provider.insurance_provider_id ===
                                                                insuranceProvider,
                                                        }
                                                    )}
                                                    onClick={() =>
                                                        handleInsuranceProviderChange(
                                                            provider.insurance_provider_id
                                                        )
                                                    }
                                                    key={
                                                        provider.insurance_provider_id
                                                    }
                                                >
                                                    {removeEnumUnderscore(
                                                        provider.alias
                                                    ).toUpperCase()}
                                                    {insuranceProvider ===
                                                    provider.insurance_provider_id ? (
                                                        <CheckPrimaryColorIcon />
                                                    ) : null}
                                                </DropdownItem>
                                            ))}
                                    </DropdownContent>
                                </Dropdown>
                            )}
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <FilterButton
                                        text={
                                            financialAssistanceStatus ||
                                            "Financial assistance"
                                        }
                                    />
                                </DropdownTrigger>
                                <DropdownContent
                                    align={isSmallScreen() ? "start" : "end"}
                                >
                                    <DropdownItem
                                        className="uppercase text-xs font-medium flex items-center gap-x-8"
                                        onClick={() =>
                                            handleFinancialAssistanceStatusChange(
                                                ""
                                            )
                                        }
                                    >
                                        All
                                        {!financialAssistanceStatus ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                    {Object.values(
                                        FinancialAssistanceStatus
                                    ).map((status) => (
                                        <DropdownItem
                                            className="uppercase text-xs font-medium flex items-center gap-x-8"
                                            onClick={() =>
                                                handleFinancialAssistanceStatusChange(
                                                    status
                                                )
                                            }
                                            key={status}
                                        >
                                            {status}
                                            {financialAssistanceStatus ===
                                            status ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                    ))}
                                </DropdownContent>
                            </Dropdown>
                        </div>
                    </div>
                    {!practice?.roles?.includes(UserRole.PROVIDER) &&
                        canAddClient && (
                            <Button
                                aria-label="AddClient"
                                onClick={() =>
                                    handleSetFormInView(
                                        FormTypes.ADD_PERSONAL_INFO
                                    )
                                }
                            >
                                Add client
                            </Button>
                        )}
                </div>

                {isLoading && (
                    <Skeleton
                        type="table"
                        containerTestId="clientslist-loader"
                        count={6}
                    />
                )}
                {error && error?.response?.status !== 404 && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            error?.response?.data.message ||
                            `Cannot display clients list at this time please try again later`
                        }
                    />
                )}
                {data && isSuccess && data.data.length === 0 && (
                    <ListState
                        stateHelperText="Client list will appear here once clients are added"
                        isError={false}
                        emptyMessage="No client added yet"
                        wrapperClass={styles.i_stateclass}
                        emptyBtnProps={{
                            showButton: !pageFilter || !limitFilter || !search,
                            buttonText: "Add client",
                            onButtonClick: () =>
                                handleSetFormInView(
                                    FormTypes.ADD_PERSONAL_INFO
                                ),
                        }}
                    />
                )}
                {isSuccess && data && data.data.length > 0 && (
                    <>
                        <div className={styles.table}>
                            <Table
                                data={data.data}
                                columns={columns}
                                rowSelection={rowSelection}
                                setRowSelection={setRowSelection}
                                handleRowClick={handleRowClick}
                                setSortConfig={setSortConfig}
                                sortConfig={sortConfig}
                                sortableColumns={sortableColumns}
                                hasPagination={data.total_count > 20}
                                isColumnVisible={{
                                    alt_email_sent: !showInsOverSelfPay,
                                }}
                                pagination={
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handlePageChange}
                                        limit={limitFilter}
                                        onLimitChange={handleLimitChange}
                                    />
                                }
                            />
                        </div>
                        <div className={styles.list_mobile} role="table">
                            {data.data.map((client) => (
                                <MobileListItem
                                    key={client.client_id}
                                    className={styles.list_card}
                                    showCheckButton={false}
                                    onClickListItem={() =>
                                        handleMobileItemClick(client.client_id)
                                    }
                                >
                                    <span className={styles.client}>
                                        <span className={styles.client_wrap}>
                                            <span className={styles.name}>
                                                {`${client.first_name} ${client.last_name}`}
                                            </span>
                                            {isMinor(client.age) && (
                                                <>
                                                    <span
                                                        className={cn(
                                                            styles.dot,
                                                            styles.dot_small
                                                        )}
                                                    />
                                                    <span
                                                        className={styles.minor}
                                                    >
                                                        minor
                                                    </span>
                                                </>
                                            )}
                                        </span>
                                        <span className={styles.client_wrap}>
                                            <span
                                                className={cn(
                                                    styles.tlight,
                                                    "capitalize"
                                                )}
                                            >
                                                {client.preferred_name || "--"}
                                            </span>
                                            <span
                                                className={cn(
                                                    styles.dot,
                                                    styles.dot_large
                                                )}
                                            />
                                            <span
                                                className={cn(
                                                    styles.tlight,
                                                    "capitalize"
                                                )}
                                            >
                                                {client.pronouns || "--"}
                                            </span>
                                            <span
                                                className={cn(
                                                    styles.dot,
                                                    styles.dot_large
                                                )}
                                            />
                                            <span className={styles.tlight}>
                                                {client.age || "--"}
                                            </span>
                                        </span>
                                        <span className={styles.client_wrap}>
                                            <span className={styles.tlight}>
                                                {client.contact || "--"}
                                            </span>
                                            <span
                                                className={cn(
                                                    styles.dot,
                                                    styles.dot_large
                                                )}
                                            />
                                            <span className={styles.tlight}>
                                                {client.email || "--"}
                                            </span>
                                        </span>
                                        <span className={styles.client_wrap}>
                                            <span
                                                className={cn(
                                                    styles.tlight,
                                                    client.self_pay
                                                        ? "!normal-case"
                                                        : "!uppercase"
                                                )}
                                            >
                                                <span className="normal-case text-gray">
                                                    Insurance Provider
                                                </span>
                                                {`: ${
                                                    client.self_pay &&
                                                    !showInsOverSelfPay
                                                        ? "Self pay"
                                                        : (client.insurance_provider_name &&
                                                              removeEnumUnderscore(
                                                                  client.insurance_provider_name ||
                                                                      ""
                                                              )) ||
                                                          "--"
                                                }`}
                                            </span>
                                        </span>
                                        <span className={styles.client_wrap}>
                                            <span className={styles.tlight}>
                                                <span className="normal-case text-gray">
                                                    Financial assistance status
                                                </span>
                                                {": "}
                                                <span className="capitalize">
                                                    {client.financial_assistance_status ||
                                                        "--"}
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </MobileListItem>
                            ))}
                            {data.total_count > 20 && (
                                <div className="bg-white rounded-r8 p-12 mt-16">
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handlePageChange}
                                        limit={limitFilter}
                                        onLimitChange={handleLimitChange}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

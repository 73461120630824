import Skeleton from "../../../components/Skeleton/Skeleton";
import { useFetchDiagnosisCodes } from "../../../hooks/queries";

export default function useGetDiagnosisCodeToDisplay(diagnosisCode?: string) {
    const { isLoading, error, data } = useFetchDiagnosisCodes();

    if (!diagnosisCode) return "--";

    if (isLoading) {
        return <Skeleton width="100px" height="20px" />;
    }

    if (error) {
        return "Error fetching diagnosis code";
    }

    const diagnosisToDisplay = data.data.find((code) => {
        return code.code === diagnosisCode;
    });

    return diagnosisToDisplay
        ? `${diagnosisToDisplay.code} ${diagnosisToDisplay.description} `
        : "--";
}

export default function ClientsIcon() {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.9987 5.27594C11.9587 5.26927 11.9121 5.26927 11.8721 5.27594C10.9521 5.24261 10.2188 4.48927 10.2188 3.55594C10.2188 2.60261 10.9854 1.83594 11.9387 1.83594C12.8921 1.83594 13.6587 2.60927 13.6587 3.55594C13.6521 4.48927 12.9187 5.24261 11.9987 5.27594Z"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.313 10.1294C12.2263 10.2827 13.233 10.1227 13.9396 9.64938C14.8796 9.02271 14.8796 7.99605 13.9396 7.36938C13.2263 6.89605 12.2063 6.73604 11.293 6.89604"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.98031 5.27594C4.02031 5.26927 4.06698 5.26927 4.10698 5.27594C5.02698 5.24261 5.76031 4.48927 5.76031 3.55594C5.76031 2.60261 4.99365 1.83594 4.04031 1.83594C3.08698 1.83594 2.32031 2.60927 2.32031 3.55594C2.32698 4.48927 3.06031 5.24261 3.98031 5.27594Z"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.6676 10.1294C3.75427 10.2827 2.7476 10.1227 2.04094 9.64938C1.10094 9.02271 1.10094 7.99605 2.04094 7.36938C2.75427 6.89605 3.77427 6.73604 4.6876 6.89604"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.99875 10.2525C7.95875 10.2458 7.91208 10.2458 7.87208 10.2525C6.95208 10.2192 6.21875 9.46583 6.21875 8.5325C6.21875 7.57917 6.98542 6.8125 7.93875 6.8125C8.89208 6.8125 9.65875 7.58583 9.65875 8.5325C9.65208 9.46583 8.91875 10.2258 7.99875 10.2525Z"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.94047 12.3503C8.88047 11.6436 7.12714 11.6436 6.06047 12.3503C5.12047 12.977 5.12047 14.0036 6.06047 14.6303C7.12714 15.3436 8.8738 15.3436 9.94047 14.6303"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

import { usePaymentInputs } from "react-payment-inputs";
import MastercardIcon from "../components/Icons/Mastercard";
import AmexIcon from "../components/Icons/Amex";
import DinersclubIcon from "../components/Icons/DinersClub";
import DiscoverIcon from "../components/Icons/Discover";
import JCBIcon from "../components/Icons/JCB";
import UnionPayIcon from "../components/Icons/UnionPay";
import VisaIcon from "../components/Icons/Visa";
import ChaseBank from "../components/Icons/ChaseBank";
import CapitalOneIcon from "../components/Icons/CapitalOne";

export default function useCreditCardInput() {
    interface CardImages {
        amex: React.ReactElement;
        capitalone: React.ReactElement;
        chase: React.ReactElement;
        dinersclub: React.ReactElement;
        discover: React.ReactElement;
        hipercard: React.ReactElement;
        jcb: React.ReactElement;
        unionpay: React.ReactElement;
        mastercard: React.ReactElement;
        placeholder: React.ReactElement;
        visa: React.ReactElement;
    }

    const cardImages: CardImages = {
        mastercard: <MastercardIcon />,
        capitalone: <CapitalOneIcon />,
        chase: <ChaseBank />,
        amex: <AmexIcon />,
        dinersclub: <DinersclubIcon />,
        discover: <DiscoverIcon />,
        hipercard: <MastercardIcon />,
        jcb: <JCBIcon />,
        unionpay: <UnionPayIcon />,
        visa: <VisaIcon />,
        placeholder: <div />,
    };

    const ERROR_MESSAGES = {
        emptyCardNumber: "Empty Card number",
        invalidCardNumber: "The card number is invalid",
        emptyExpiryDate: "Empty Expiration date",
        monthOutOfRange: "The expiration month must be between 01 and 12",
        yearOutOfRange: "The expiration year cannot be in the past",
        dateOutOfRange: "The expiration date cannot be in the past",
        invalidExpiryDate: "The expiration date is invalid",
        emptyCVC: "Empty Security code",
        invalidCVC: "The security code is invalid",
    };

    return {
        ERROR_MESSAGES,
        cardImages,
        usePaymentInputs,
    };
}

import ViewBilling from "../../../../modules/billing/components/SingleBillViewWrapper/SingleBillViewWrapper";
import Navbar from "../../../../components/Navbar/Navbar";

export default function ViewSpecificBilling() {
    return (
        <>
            <Navbar title="Bills" />
            <div className="page">
                <ViewBilling />
            </div>
        </>
    );
}

import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    IApiResponse,
    IPaginatedApiResponse,
} from "../../../utils/types/api-response";
import {
    IInternalUser,
    IInternalUsersListFilterTypes,
} from "../types/user-management.types";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import { fetchUsersList } from "../services/user-management.services";

export function useFetchUsersList(
    filters: IInternalUsersListFilterTypes,
    enabled?: boolean
) {
    interface ICustomPaginatedApiResponse<DataType>
        extends IPaginatedApiResponse<DataType> {
        practice_domains: string[];
    }
    return useQuery<
        ICustomPaginatedApiResponse<IInternalUser[]>,
        AxiosError<IApiResponse<IInternalUser[]>>
    >({
        queryKey: ["users", handleRemoveEmptyParamFromQueryParams(filters)],
        queryFn: () => fetchUsersList(filters),
        enabled,
    });
}

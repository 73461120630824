import { Timestamp } from "firebase/firestore";
import { ProviderCredential } from "../../clients/types/clients.types";
import { SessionServiceType } from "../../notes/types/notes.types";

export type DayOfWeek = "Sun" | "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat";

export type DayMapping = {
    [key in DayOfWeek]: string;
};

export enum RecurrenceRule {
    WEEKLY = "Weekly",
    BIWEEKLY = "Bi-Weekly",
    TRIWEEKLY = "Tri-Weekly",
    MONTHLY = "Monthly",
    ONE_OFF = "One-Off",
    TWO_MONTHS = "Two-Months",
    THREE_MONTHS = "Three-Months",
}

export enum ProviderStatus {
    ACTIVE = "active",
    INACTIVE = "inactive",
}
export enum AppointmentTypes {
    RECURRING_APPOINTMENT = "Recurring_Appointment",
    ONE_OFF = "One_Off_Appointment",
}

export enum ClientsToShowType {
    SHOW_ONLY_CLIENTS_THAT_REQUIRE_FOLLOW_UP = "Show_only_clients_that_require_follow_up",
    SHOW_ONLY_CLIENTS_WITH_PENDING_CONSULT_CALL = "Show_only_clients_with_pending_consult_call",
    SHOW_ONLY_PAUSED_CLIENTS = "Show_only_paused_clients",
}
export enum CalendarName {
    OFFICE = "Office Window",
    AVAILABILITY = "Availability",
    UNAVAILABLITY = "Unavailability",
    HELD_BOOKINGS = "Held",
    CONFIRMED_BOOKINGS = "Confirmed",
}
export enum CalendarId {
    OFFICE = "office_window_calendar",
    AVAILABILITY = "availability_calendar",
    UNAVAILABLITY = "unavailability_calendar",
    HELD_BOOKINGS = "held_booking_calendar",
    CONFIRMED_BOOKINGS = "confirmed_booking_calendar",
}

export type DateType = string | Date;
export interface ISchedule {
    id?: string;
    calendarId?: string;
    title?: string;
    body?: string;
    start?: DateType;
    end?: DateType;
    goingDuration?: number;
    comingDuration?: number;
    isAllDay?: boolean;
    category?: string;
    dueDateClass?: string;
    location?: string;
    attendees?: string[];
    clinician_id?: string;
    recurrenceRule?: RecurrenceRule;
    isPending?: boolean;
    isFocused?: boolean;
    isVisible?: boolean;
    isReadOnly?: boolean;
    isPrivate?: boolean;
    color?: string;
    bgColor?: string;
    dragBgColor?: string;
    borderColor?: string;
    customStyle?: string;
    raw?: {
        [propName: string]: string | number | boolean | object | null;
    };
    state?: string;
}
export type ServiceDeliveryOption = "in_person" | "remote" | undefined;
export enum DeleteActions {
    CLINICIAN_DELETING_OFFICE_WINDOW = "clinician_deleting_office_window",
    CLINICIAN_DELETING_AVAILABILITY = "clinician_deleting_availability",
    ADMIN_DELETING_CLINICIAN_OFFICE_WINDOW = "admin_deleting_clinician_office_window",
}
export type IOfficeSchedule = IAvailabilitySchedule;
export interface IAvailabilitySchedule extends ISchedule {
    office?: string;
    canEdit?: boolean;
    starting_date?: string;
    ending_date?: string;
    first_name?: string;
    availableService?: string;
    serviceDeliveryOption?: ServiceDeliveryOption;
    action?: DeleteActions;
}

export interface CalenderEvents extends IClientBooking {
    appointment_checks?: IAppointmentChecks;
}
export enum ExperienceLevelType {
    LOWHIGH = "low_to_high",
    YESNO = "yes_or_no",
    CONVFLUENT = "conv_or_fluent",
}

export interface IExperienceLevelOption {
    category: string;
    value: string;
    type: ExperienceLevelType;
    show: boolean;
}

export enum ClinicianMethodology {
    CM = "Conventional Medicine",
    AH = "Alternative or holistic",
    BTB = "By the book",
    CBT = "CBT",
    PCHC = "Psychodynamic",
    SO = "Science Oriented",
    FUNNY = "Funny",
    DIRECT = "Direct",
    SP = "Strong Personality",
    WARM = "Warm",
    RELIGION = "Religion",
    CP = "Cerebal Palsy",
    SJ = "Social Justice",
    EXISTENTIAL = "Existential",
    SE = "Self-Esteem",
    DS = "Down Syndrome",
    HSPE = "HSP or Empath",
    DE = "Disordered Eating",
}
export enum LowHighExperienceLevel {
    LOW = "Low",
    MEDIUM = "Medium",
    HIGH = "High",
    NONE = "None",
}

export enum YesNoExperienceLevel {
    YES = "yes",
    NO = "no",
}

export enum ConvFluentExpLevel {
    CONVERSATIONAL = "Conversational",
    FLUENT = "Fluent",
}
export interface IExperienceLevel {
    [key: string]: {
        level?:
            | LowHighExperienceLevel
            | YesNoExperienceLevel
            | ConvFluentExpLevel;
        do_not_want?: boolean;
    };
}
interface Window {
    id: string;
    start: DateType;
    end: DateType;
}

export interface IOfficeWindow extends Window {
    office: string;
    location: string;
    clinicianId?: string;
    blocked?: boolean;
    ending_date?: string;
}

export interface IAvailabilityWindow extends Window {
    recurrenceRule?: RecurrenceRule;
    location?: string;
    office?: string;
    reservedFor?: string;
    ending_date?: string;
    availableService?: string;
    starting_date?: string;
}
export enum BookingStatus {
    HELD = "Held",
    CONFIRMED = "Confirmed",
}

export interface ProviderCaseloadApp {
    first_name: string;
    last_name: string;
    session_type: string;
    recurrence_rule: RecurrenceRule;
    pending_task: boolean;
    is_paused: boolean;
    session_day: string;
    session_start_hr: number;
    session_start_min: number;
    session_end_hr: number;
    session_end_min: number;
    insurance_provider: string;
    self_pay: boolean;
    session_history_id?: string;
    client_id: string;
}

// to be removed from here after integration
export const defaultClinician = {
    email: "test@gmail.com",
    fullname: "James Brown",
    firstname: "James",
    lastname: "Brown",
    contact_info_client: "",
    contact_info_personal: "(01) 159 969 739",
    locations: ["Brooklyn"],
    credential: ProviderCredential.PHD,
    methodology: [],
    personality_notes: "",
    dob: new Date(),
    start_date: new Date(),
    is_supervisor: false,
    office_windows: [
        {
            end: "2023-11-07T18:00:00.000Z",

            ending_date: "",

            id: "ebfabfa1-593e-44f9-a457-562eb5e16217",

            location: "Remote",

            office: "Alexis Carmody",

            start: "2023-11-07T17:00:00.000Z",
        },
    ],
    available: [],
    unavailable: [],
    held: [],
    confirmed: [
        {
            starting_date: "2023-11-03T19:00:00.000Z",
            clinician_contacted_client: true,
            service_delivery_option: "remote" as ServiceDeliveryOption,
            start: "2023-06-09T19:00:00.000Z",
            recurrenceRule: RecurrenceRule.MONTHLY,
            office: "Adrienne Gorman",
            held_on: "2023-10-25T21:48:58.535Z",
            title: "Available",
            client_display_name: "Aiden Hayes",
            client_id: "-8483-3af6c014589c",
            confirmed_on: "2023-10-26T17:42:07.436Z",
            requested_service: "Individual Therapy",
            calendarId: "availability_calendar",
            location: "Remote",
            is_paused: true,
            end: "2023-06-09T20:00:00.000Z",
            clinician_display_name: "Adrienne Gorman",
            availableService: "",
            id: "63911c1d-1a8b-43ed-9278-92adc994ceb2",
            clinician_id: "a.gorman@humantold.com",
            category: "time",
            status: BookingStatus.CONFIRMED,
            isVisible: true,
        },
        {
            starting_date: "2023-11-21T01:00:00.000Z",
            clinician_contacted_client: true,
            service_delivery_option: "remote" as ServiceDeliveryOption,
            start: "2023-11-21T01:00:00.000Z",
            recurrenceRule: RecurrenceRule.BIWEEKLY,
            office: "Adrienne Gorman",
            held_on: "2023-10-25T21:48:58.535Z",
            title: "Available",
            client_display_name: "Aiden Hayes",
            client_id: "-8483-3af6c014589c",
            confirmed_on: "2023-10-26T17:42:07.436Z",
            requested_service: "Individual Therapy",
            calendarId: "availability_calendar",
            location: "Remote",
            is_paused: true,
            end: "2023-11-21T22:00:00.000Z",
            clinician_display_name: "Adrienne Gorman",
            availableService: "",
            id: "414f-9122-c4a1d1564522",
            clinician_id: "a.gorman@humantold.com",
            category: "time",
            status: BookingStatus.CONFIRMED,
            isVisible: true,
        },
        {
            starting_date: "2023-11-21T02:00:00.000Z",
            clinician_contacted_client: true,
            service_delivery_option: "remote" as ServiceDeliveryOption,
            start: "2023-11-21T02:00:00.000Z",
            recurrenceRule: RecurrenceRule.MONTHLY,
            office: "Adrienne Gorman",
            held_on: "2023-10-25T21:48:58.535Z",
            title: "Available",
            client_display_name: "Aiden Hayes",
            client_id: "-8483-3af6c014589c",
            confirmed_on: "2023-10-26T17:42:07.436Z",
            requested_service: "Individual Therapy",
            calendarId: "availability_calendar",
            location: "Remote",
            end: "2023-11-21T22:00:00.000Z",
            clinician_display_name: "Adrienne Gorman",
            availableService: "",
            id: "414f-9122-c4a1d1564522",
            clinician_id: "a.gorman@humantold.com",
            category: "time",
            status: BookingStatus.CONFIRMED,
            isVisible: true,
        },
        {
            starting_date: "2023-10-03T00:00:00.000Z",
            service_delivery_option: "remote" as ServiceDeliveryOption,
            start: "2023-09-21T13:00:00.000Z",
            recurrenceRule: RecurrenceRule.WEEKLY,
            office: "Adrienne Gorman",
            held_on: "2023-10-25T21:48:58.535Z",
            title: "Available",
            client_display_name: "Aiden Hayes",
            client_id: "ffafe629-a1a8-5392",
            confirmed_on: "2023-10-26T17:42:07.436Z",
            requested_service: "Individual Therapy",
            calendarId: "availability_calendar",
            location: "Remote",
            end: "2023-09-19T01:00:00.000Z",
            clinician_display_name: "Adrienne Gorman",
            availableService: "",
            id: "414f-9122-c4a1d1564522",
            clinician_id: "a.gorman@humantold.com",
            category: "time",
            status: BookingStatus.CONFIRMED,
            isVisible: true,
        },
        {
            starting_date: "2023-10-03T00:00:00.000Z",
            service_delivery_option: "remote" as ServiceDeliveryOption,
            start: "2023-09-21T13:00:00.000Z",
            recurrenceRule: RecurrenceRule.BIWEEKLY,
            office: "Adrienne Gorman",
            held_on: "2023-10-25T21:48:58.535Z",
            title: "Available",
            client_display_name: "Aiden Hayes",
            client_id: "ffafe629-a1a8-5392",
            confirmed_on: "2023-10-26T17:42:07.436Z",
            requested_service: "Individual Therapy",
            calendarId: "availability_calendar",
            location: "Remote",
            end: "2023-09-19T01:00:00.000Z",
            clinician_display_name: "Adrienne Gorman",
            availableService: "",
            id: "414f-9122-c4a1d1564522",
            clinician_id: "a.gorman@humantold.com",
            category: "time",
            status: BookingStatus.CONFIRMED,
            isVisible: true,
        },
    ],
    appointment_checks: {
        "63911c1d-1a8b-43ed-9278-92adc994ceb2": {
            credit_card_validated: null,
            date_added: new Date("2021-09-21T19:00:44.277Z"),
            consent_form_sent: new Date("2021-09-21T19:26:55.068Z"),
            client_added_to_amd: null,
            consult_call_notes:
                "relationship, family planning and parenting. The client is a xfer from Alyssa",
            client_requested_call: true,
        },
    },
    supervisor_id: "",
    experience_levels: {},
    updated_at: new Date(),
    started_practicing: new Date(),
    booking_paused: false,
    marked_as_priority: false,
};

export interface IClientBooking extends IAvailabilityWindow {
    clinician_display_name: string;
    client_display_name: string;
    clinician_id: string;
    client_id: string;
    status: BookingStatus;
    clinician_contacted_client?: boolean;
    starting_date: string;
    requested_service: string;
    created_by_clinician?: boolean;
    is_paused?: boolean;
    date_paused?: string;
    location?: string;
    office?: string;
    service_delivery_option?: ServiceDeliveryOption;
    confirmed_on?: string | null;
    held_on?: string | null;
    date_of_service?: string;
}

export interface IClinician {
    id?: string;
    email: string;
    fullname: string;
    firstname: string;
    lastname: string;
    npi?: string;
    credential: ProviderCredential;
    contact_info_client: string;
    contact_info_personal: string;
    personality_notes: string;
    dob?: Date;
    start_date?: Date;
    locations: string[];
    methodology: ClinicianMethodology[];
    experience_levels: IExperienceLevel;
    office_windows: IOfficeWindow[];
    available: IAvailabilityWindow[];
    unavailable: IAvailabilityWindow[];
    held: IClientBooking[];
    confirmed: IClientBooking[];
    supervisor_id: string;
    updated_at: Date;
    first_name?: string;
    is_supervisor: boolean;
    started_practicing?: Date;
    booking_paused?: boolean;
    marked_as_priority?: boolean;
}

export interface IReservedSchedule extends IAvailabilitySchedule {
    reservedFor?: string;
    clinicianName?: string;
    clinicianId?: string;
    serviceDeliveryOption?: ServiceDeliveryOption;
}
export enum AvailabilityStatus {
    AVAILABLE = "available",
    UNAVAILABLE = "unavailable",
}
export const DEFAULT_TZ = "America/New_York";
export enum DateFormats {
    DAY = "dddd",
    DATE_FORMAT = "MMM Do YYYY",
    DAY_WEEK_FORMAT = "ddd, MMMM Do YYYY",
    DAY_MONTH_FORMAT = "MM/DD",
    DAY_MONTH_YEAR_FORMAT = "MM/DD/YYYY",
    DAY_HOUR_FORMAT = "ddd ha",
    DAY_HOUR_MIN_FORMAT = "MM/DD hh:mma",
    DAY_TIME_FORMAT = "ddd hh:mma",
    DAY_YEAR_HOUR_MIN_FORMAT = "MM/DD/YYYY hh:mma",
    HOUR_FORMAT = "ha",
    HOUR_MIN_FORMAT = "h:mma",
}
export enum TimeCategory {
    EARLY_MORNING = "Early Morning",
    MORNING = "Morning",
    AFTERNOON = "Afternoon",
    EVENING = "Evening",
    LUNCH_TIME = "Lunch Time",
}
export enum CancelationReason {
    NO_SHOW = "No Show",
    TERMINATION = "Termination",
    CANCELLED = "Cancelled",
    WAITLIST = "Waitlist",
    TRANSFER = "Transfer",
}
export enum DayOption {
    SUNDAY = "Sunday",
    MONDAY = "Monday",
    TUESDAY = "Tuesday",
    WEDNESDAY = "Wednesday",
    THURSDAY = "Thursday",
    FRIDAY = "Friday",
    SATURDAY = "Saturday",
}
export enum Location {
    MADISON = "Madison",
    UES = "Upper East Side",
    BROOKLYN = "Brooklyn",
    Remote = "Remote",
}
export enum ClientStatus {
    INQUIRY = "Initial Inquiry",
    INS_VERIFICATION = "Insurance Verification",
    INS_FOLLOWUP = "Insurance Follow-up",
    FEE_SETTING = "Fee Setting",
    APPT_SETTING = "Appointment Setting",
    WAITLIST = "Waitlist",
    APPT_HELD = "Appointment Held",
    APPT_CONFIRMED = "Appointment Confirmed",
    COMPLETED = "Completed",
    DO_NOT_BOOK = "Do Not Book",
    NON_RESPONSIVE = "Non-Responsive",
}
export enum ClientInquiryMethod {
    PSYCH_TODAY = "Psych Today",
    ZDOC = "Zdoc",
    WEB_FORM = "Webform",
    GOOD_THERAPY = "Good Therapy",
    PHONE = "Phone",
    EMAIL = "Email",
    INTERNAL = "Internal",
    INSURANCE_CHECK = "Insurance Check",
    THERAPY_DEN = "Therapy Den",
}
export interface IInquiryFields {
    called_on?: Date;
    emailed_on?: Date;
}

export interface IInsuranceVerificationFields {
    INNDed: string;
    INNCo: string;
    INNOOP: string;
    INNCoins: string;
    OONDed: string;
    OONCo: string;
    OONOOP: string;
    OONCoins: string;
    UCR: string;
    CalYr: string;
    insurance_accepted?: string;
    insurance_status?: string;
    last_verification_date?: Date;
}

export interface ClinicianServiceSelection {
    [clinician_id: string]: {
        services: string[];
        departments: string[];
    };
}

export interface IFeeSettingFields {
    masters_fee: string;
    dr_fee: string;
    dr_fee_a: string;
    dr_fee_b: string;
    dr_fee_c: string;
    psychiatry_fee: string;
    intern_fee: string;
    lic_fee: string;
    lic_a_fee: string;
    lic_b_fee: string;
    lic_c_fee: string;
    extern_fee: string;
    dr_fee_calc?: Record<string, unknown>;
    dr_fee_a_calc?: Record<string, unknown>;
    dr_fee_b_calc?: Record<string, unknown>;
    dr_fee_c_calc?: Record<string, unknown>;
    masters_fee_calc?: Record<string, unknown>;
    psychiatry_fee_calc?: Record<string, unknown>;
    lic_fee_calc?: Record<string, unknown>;
    lic_a_fee_calc?: Record<string, unknown>;
    lic_b_fee_calc?: Record<string, unknown>;
    lic_c_fee_calc?: Record<string, unknown>;
    intern_fee_calc?: Record<string, unknown>;
    extern_fee_calc?: Record<string, unknown>;
    is_self_pay?: boolean;
    testing_fee?: string;
}

export interface InsuranceCard {
    id: string;
    name: string;
    url: string;
}

export enum OfficeRequestStatus {
    APPROVED = "Approved",
    REJECTED = "Rejected",
    PENDING = "Pending",
    APPROVED_WITH_CHANGES = "ApprovedWithChanges",
}

export interface ClinicianOfficeWindowRequests {
    location: string;
    office: string;
    start_date: Date;
    end_date: Date;
    ending_date?: Date | string;
    recurrence: RecurrenceRule;
    requester: string;
    request_date: Date;
    change_end_time_reason: string;
    day: string;
    first_name?: string;
    delivery_option: string;
    status?: OfficeRequestStatus;
    rejection_reason?: string;
    office_window_request_id: string;
    client_name?: string;
    assigned_to?: string;
    available?: boolean;
    clinician_name: string;
    category: "request_list" | "wait_list";
    request_list_count: number;
    waitlist_count: number;
    approve_with_changes_reason?: string;
}

export interface IInsuranceFollowUpFields {
    notes_from_ins_team: string;
    notes_from_intake_team: string;
    email_sent: boolean;
    photo_links: InsuranceCard[];
}

export interface IAppointmentSettingFields {
    call_notes?: string;
    email_notes?: string;
    follow_ups?: string;
}
export interface IAppointmentHeldFields {
    cancellation_notes?: string;
    consent_cc_notes?: string;
}

export interface IAppointmentChecks {
    [appointment_id: string]: {
        date_added: Date;
        consent_form_sent?: Date | null;
        client_added_to_amd?: Date | null;
        credit_card_validated?: Date | null;
        client_requested_call: boolean;
        consult_call_completed?: boolean;
        consult_call_completed_on?: string;
        consult_call_notes: string;
        vaccination_checks_completed_on?: string | null;
    };
}

export interface IAppointmentHistory extends IClientBooking {
    cancelled_on: string;
    cancellation_notes: string;
    cancelled_by?: string;
}

export interface IFeeSelect {
    masters_fee?: boolean;
    dr_fee?: boolean;
    psych_fee?: boolean;
    intern_fee?: boolean;
    testing_fee?: boolean;
    extern_fee?: boolean;
}

export interface IClient {
    id: string;
    email: string;
    phone_no: string;
    first_name: string;
    preferred_name: string;
    last_name: string;
    pronouns: string;
    address: string;
    state?: string;
    date_of_birth: Date;
    zip_code: string;
    insurance_provider: string;
    insurance_member_id: string;
    insurance_plan_employer: string;
    notes: string;
    complete_notes: {
        reason: string;
        notes?: string;
    };
    waitlist_clinician_ids: string[];
    waitlist_locations: string[];
    waitlist_time_categories: TimeCategory[];
    waitlist_days: DayOption[];
    waitlist_wants_intern: boolean;
    waitlist_notes?: string;
    status: ClientStatus;
    primary_insured_name?: string;
    primary_insured_dob?: Date;
    primary_insured_address?: string;
    [ClientStatus.INQUIRY]?: IInquiryFields;
    [ClientStatus.INS_VERIFICATION]?: IInsuranceVerificationFields;
    [ClientStatus.FEE_SETTING]?: IFeeSettingFields;
    [ClientStatus.INS_FOLLOWUP]?: IInsuranceFollowUpFields;
    [ClientStatus.APPT_SETTING]?: IAppointmentSettingFields;
    [ClientStatus.APPT_HELD]?: IAppointmentHeldFields;
    appointment_checks?: IAppointmentChecks;
    appointment_history: IAppointmentHistory[];
    held_bookings: IClientBooking[];
    confirmed_bookings: IClientBooking[];
    inquiry_method: ClientInquiryMethod;
    status_history: { [key: string]: typeof Timestamp | Date };
    has_history?: boolean;
    date_updated?: Date;
    cancellation_notes?: {
        [key: string]: {
            booking: IClientBooking;
            reason: string;
            notes?: string;
        };
    };
    requested_service: string;
    requested_therapist: string;
    assigned_to?: string;
    on_waitlist: boolean;
    is_blacklisted: boolean;
    referred_by: string;
    last_updated_by?: string;
    applicable_fee: IFeeSelect;
}

export interface IProviderCaseload {
    client: {
        first_name: string;
        last_name: string;
        pronouns: string;
        age: number;
        email: string;
        preferred_name: string;
        contact: string;
        client_id: string;
        insurance_provider_name?: string;
        self_pay?: boolean;
        is_paused: boolean;
        pending_task: boolean;
    };
    appointments: {
        session_day: string;
        session_start_hr: number;
        session_start_min: number;
        session_end_hr: number;
        session_end_min: number;
        recurrence_rule: RecurrenceRule;
        session_type: SessionServiceType;
        session_history_id?: string;
    }[];
}

export interface IProviderCaseloadFilters {
    providerId: string;
    search_string?: string;
    show_paused_client?: string;
    show_clients_with_pending_tasks?: string;
    enabled?: boolean;
}

export interface ISuperviseeCaseload {
    provider_id: string;
    first_name: string;
    last_name: string;
    pronouns: string;
    credential: ProviderCredential;
    email: string;
    preferred_name: string;
    contact: string;
    appointments: number;
    active_clients: number;
    paused_clients: number;
    delivery_option: string[];
    pending_notes: number;
    npi?: string;
}
export interface IProvider {
    provider_id: string;
    supervisor_id: string;
    email: string;
    provider_contact_number: string;
    no_of_active_clients: number;
    no_of_supervisees: number;
    is_supervisor: boolean;
    provider_name: string;
    status: ProviderStatus;
    credential: ProviderCredential;
    started_practicing: string | null;
    supervisor_name: string | null;
    user_id: string;
    npi?: string;
}

export interface IProvidersFilterTypes {
    page: number;
    search_string?: string;
    status?: string;
    credential?: string;
    role?: string;
    limit: number;
    provider_id?: string;
    sort_attribute?: string;
    sort_order?: string;
}

export interface IEditProviderInfo {
    npi?: string;
}

interface Client {
    client_id: string;
    first_name: string;
    last_name: string;
    pronouns: string;
    preffered_name: string;
    email: string;
    insurance_provider_name: string | null;
    self_pay: boolean | null;
}

interface Provider {
    provider_id: string;
    first_name: string;
    last_name: string;
    pronouns: string;
    preffered_name: string;
    email: string;
}

interface RecentSession {
    note_id: string;
    invoice_id: string;
    date_of_service: string;
    cpt_code: string;
    insurance_amount: number;
    coinsurance_amount: number;
    insurance_amount_outstanding: number;
    coinsurance_amount_outstanding: number;
}

export interface IWeeklySchedule {
    session_id: string;
    service: string;
    start_date: string;
    status: string;
    session_day: string;
    session_start_hr: number;
    session_start_min: number;
    session_end_hr: number;
    coinsurance_amount: number;
    claim_status: string | null;
    session_end_min: number;
    recurrence_rule: string;
    client: Client;
    note_id?: string;
    note_status?: string;
    provider: Provider;
    session_history_id: string | null;
    start_date_time: string | null;
    end_date_time: string | null;
    is_cancelled: boolean | null;
    cancelled_by: string | null;
    invoice_id: string | null;
    invoice_status: string | null;
    coinsurance_amount_paid: number;
    possible_start_date_time: string;
    recent_sessions: RecentSession[];
    start?: string;
    end?: string;
    claim_id: string | null;
}
export interface ScheduleFilters {
    client_ids: string[];
    provider_ids: string[];
    from_date: string | null;
    to_date: string | null;
    custom_date?: string;
}

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { Input } from "@jhool-io/fe-components";
import { useParams } from "react-router-dom";
import { IAddClientFlag } from "../../types/clients.types";
import useToast from "../../../../hooks/useToast";
import { useUpdateClientFlag } from "../../hooks/clients.mutations";
import { useFetchClientFlags } from "../../hooks/clients.queries";
import { UserRole } from "../../../../utils/types/user";
import { useDoesUserHaveRole } from "../../../../hooks/permissions";

interface EditClientFlagFormProps {
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
    // Flag Id
    flagId: string;
}

// For form validation purposes
const schema = yup.object({
    content: yup
        .string()
        .required("Flag label is required")
        .max(35, "Maximum number of characters is exceeded"),
});

export default function EditClientFlagForm({
    onFormSubmit,
    flagId,
}: EditClientFlagFormProps) {
    const params = useParams();

    const clientId = params.clientId as string;

    const userCanViewFlags = useDoesUserHaveRole([
        UserRole.BILLER,
        UserRole.SUPER_ADMIN,
        UserRole.BILLER_ADMIN,
        UserRole.CODER,
    ]);

    // Fetch client flags
    const clientFlags = useFetchClientFlags(clientId, userCanViewFlags);

    const selectedFlagDetails = clientFlags.data?.data.find(
        (flag) => flag.client_flag_id === flagId
    );

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IAddClientFlag>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: { content: selectedFlagDetails?.content },
    });

    const { toast } = useToast();

    const queryClient = useQueryClient();

    const editFlag = useUpdateClientFlag(clientId, flagId);

    const onSubmit = (data: IAddClientFlag) => {
        editFlag.mutate(data, {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: [clientId, "client-flags"],
                });
                toast({
                    mode: "success",
                    message: res.message || "Flag edited successfully",
                });
                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not edit flag at this time.",
                });
            },
        });
    };

    return (
        <form id="edit-client-flag" onSubmit={handleSubmit(onSubmit)}>
            <Input
                {...register("content")}
                label="Flag label"
                placeholder="Flag label"
                hasError={!!errors.content}
                errorText={errors.content?.message}
                defaultValue={selectedFlagDetails?.content}
            />
        </form>
    );
}

import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    IApiResponse,
    IPaginatedApiResponse,
} from "../../../utils/types/api-response";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    fetchProviderCalendar,
    fetchSessionSummary,
} from "../services/dashboard.services";
import {
    ExpiringPolicy,
    IBillingOverViewResponse,
    ICalendarSchedule,
    ISessionSummary,
} from "../types/dashboard.types";
import { BillingOverviewFilters } from "../../../utils/types/billing";
import {
    fetchBillingDashboardOverview,
    fetchBillingExpiringPolicies,
} from "../../../services/billing";

// Fetch clinicians sessions summary hook
export function useFetchSessionSummary(
    providerId: string,
    customDate?: string,
    enabled?: boolean
) {
    return useQuery<
        IApiResponse<ISessionSummary>,
        AxiosError<IApiResponse<ISessionSummary>>
    >({
        queryKey: [
            "summary",
            providerId,
            handleRemoveEmptyParamFromQueryParams({ customDate }),
        ],
        queryFn: () => fetchSessionSummary(providerId, customDate),
        enabled,
    });
}

// Fetch clinicians calendar schedule hook
export function useFetchProviderCalendar(
    providerId: string,
    enabled?: boolean
) {
    return useQuery<
        IApiResponse<ICalendarSchedule[]>,
        AxiosError<IApiResponse<ICalendarSchedule[]>>
    >({
        queryKey: ["calendar", providerId],
        queryFn: () => fetchProviderCalendar(providerId),
        enabled,
    });
}

export function useFetchBillingOverview(
    filters: Pick<BillingOverviewFilters, "insurance_provider_id">
) {
    return useQuery<
        IApiResponse<IBillingOverViewResponse>,
        AxiosError<IApiResponse<IBillingOverViewResponse>>
    >({
        queryKey: [
            "billing_overview",
            handleRemoveEmptyParamFromQueryParams(filters),
        ],
        queryFn: () => fetchBillingDashboardOverview(filters),
    });
}

export function useFetchExpiringPolicies(filters: BillingOverviewFilters) {
    return useQuery<
        IPaginatedApiResponse<ExpiringPolicy[]>,
        AxiosError<IApiResponse<ExpiringPolicy[]>>
    >({
        queryKey: [
            "expiring_policies",
            handleRemoveEmptyParamFromQueryParams(filters),
        ],
        queryFn: () => fetchBillingExpiringPolicies(filters),
    });
}

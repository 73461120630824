import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { Select } from "@jhool-io/fe-components";
import { useSearchParams } from "react-router-dom";
import useToast from "../../../../hooks/useToast";
import {
    IModifyRolesPayload,
    UserRole,
} from "../../types/user-management.types";
import { useFetchUsersList } from "../../hooks/user-management.queries";
import { useModifyUserRoles } from "../../hooks/user-management.mutations";

interface ModifyRoleProps {
    onFormSubmit: () => void;
    defaultUsers?: string[];
    defaultRoles?: string[];
}

type Options = {
    label: React.ReactNode;
    value: string;
};

const schema = yup.object().shape({
    user_ids: yup
        .array()
        .min(1, "At least one user name is required in this field")
        .required("Username is required"),
    roles: yup
        .array()
        .min(1, "At least one role is required in this field")
        .required("Role is required"),
});

const assignRoleForSelect: Options[] = [
    {
        value: UserRole.SUPER_ADMIN,
        label: "Super Admin",
    },
    {
        value: UserRole.AUDITOR,
        label: "Auditor",
    },
    {
        value: UserRole.BILLER,
        label: "Biller",
    },
    {
        value: UserRole.BILLER_ADMIN,
        label: "Biller Admin",
    },
    {
        value: UserRole.CLIENT_SUPPORT,
        label: "Client Support",
    },
    {
        value: UserRole.CODER,
        label: "Coder",
    },
    {
        value: UserRole.PROVIDER,
        label: "Provider",
    },
    {
        value: UserRole.USER_SUPPORT,
        label: "User Support",
    },
];

export default function ModifyRoles({
    onFormSubmit,
    defaultUsers,
    defaultRoles,
}: ModifyRoleProps) {
    // Local component states
    const [searchValue, setSearchValue] = React.useState("");
    const [searchParams] = useSearchParams();

    // React hook form values
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<IModifyRolesPayload>({
        resolver: yupResolver(schema),
    });

    // Get filter values from url searchparams
    const pageFilter = Number(searchParams.get("page")) || 1;
    const searchFilter = searchParams.get("search") || "";
    const roleFilter = searchParams.get("role") || "";
    const statusFilter =
        searchParams.get("status") === null
            ? ""
            : searchParams.get("status") || "active";
    const limitFilter = Number(searchParams.get("limit")) || 20;

    const { data, isLoading, error } = useFetchUsersList({
        page: pageFilter,
        limit: limitFilter,
        status: statusFilter,
        role: roleFilter || "",
        search_string: searchFilter,
    });

    const modifyRoles = useModifyUserRoles();

    const queryClient = useQueryClient();

    const roles = watch("roles");

    const users = watch("user_ids");

    const { toast } = useToast();

    const UserNamesForSelect = data?.data.map((user) => ({
        label: `${user.first_name} ${user.last_name}`,
        value: user.user_id,
    }));

    // Get filtered options for users list
    const filteredUsers = UserNamesForSelect?.filter((option) =>
        option.label.toLowerCase().includes(searchValue.toLowerCase())
    );

    const onSubmit = (payload: IModifyRolesPayload) => {
        const dataToSend = {
            user_ids: payload.user_ids,
            roles: payload.roles,
        };

        modifyRoles.mutate(dataToSend, {
            onSuccess: (res) => {
                queryClient.invalidateQueries({ queryKey: [`users`] });
                toast({
                    mode: "success",
                    message: res.message || "Roles modified successfully",
                });
                onFormSubmit();
            },

            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not modify roles at this time",
                });
            },
        });
    };

    return (
        <div>
            <form
                id="modify_user_role"
                name="modify_user_role"
                aria-label="modify_user_role"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="fg">
                    <Controller
                        defaultValue={defaultUsers}
                        name="user_ids"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label="User name"
                                placeholder={
                                    (!data && isLoading && "Loading...") ||
                                    (error &&
                                        !isLoading &&
                                        "Error loading users") ||
                                    (data && !isLoading && "User name")
                                }
                                filterOption={() => true}
                                isSearchable
                                options={filteredUsers}
                                onInputChange={(value) => setSearchValue(value)}
                                onChange={(val) => {
                                    field.onChange(
                                        (val as Options[]).map((id) => id.value)
                                    );
                                }}
                                hasError={!!errors?.user_ids}
                                errorText={
                                    errors?.roles?.type === "typeError"
                                        ? "User name is required"
                                        : errors?.user_ids?.message
                                }
                                isMulti
                                multiHasValues={
                                    defaultUsers
                                        ? defaultUsers.length > 0
                                        : users.length > 0
                                }
                                hideSelectedOptions
                                defaultValue={UserNamesForSelect?.filter(
                                    (user) => defaultUsers?.includes(user.value)
                                )}
                                isLongListInDialog
                            />
                        )}
                    />
                </div>
                <div className="fg">
                    <Controller
                        name="roles"
                        control={control}
                        defaultValue={defaultRoles}
                        render={({ field }) => (
                            <Select
                                label="Assign Role"
                                placeholder="Assign Role"
                                options={assignRoleForSelect}
                                onChange={(val) => {
                                    field.onChange(
                                        (val as Options[]).map(
                                            (role) => role.value
                                        )
                                    );
                                }}
                                hasError={!!errors?.roles}
                                errorText={
                                    errors?.roles?.type === "typeError"
                                        ? "Roles are required"
                                        : errors?.roles?.message
                                }
                                isMulti
                                hideSelectedOptions
                                multiHasValues={
                                    defaultRoles
                                        ? defaultRoles.length > 0
                                        : roles.length > 0
                                }
                                defaultValue={assignRoleForSelect?.filter(
                                    (role) => defaultRoles?.includes(role.value)
                                )}
                                isSearchable
                                isLongListInDialog
                            />
                        )}
                    />
                </div>
            </form>
        </div>
    );
}

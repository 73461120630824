import * as React from "react";
import {
    Tag,
    Card,
    Dialog,
    Button,
    DialogContent,
    DropdownTrigger,
    Dropdown,
    DropdownContent,
    DropdownItem,
} from "@jhool-io/fe-components";
import { Link, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { getColorStylesForCalendarId } from "../../../../utils/constants";
import {
    cn,
    convertTimeTo12HoursFormat,
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import CalendarDayDarker from "../../../../components/Icons/CalendarDayDarker";
import ClientInfo from "../ClientInfo/ClientInfo";
import patternBg from "../../../../assets/images/patternbg.png";
import { useFetchProviderCalendar } from "../../hooks/dashboard.queries";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import { ICalendarSchedule } from "../../types/dashboard.types";
import MoreIcon from "../../../../components/Icons/More";
import { useCancelSession } from "../../../../hooks/mutations/tasks";
import useToast from "../../../../hooks/useToast";
import { useAppSelector } from "../../../../hooks/useRedux";

export default function CalendarViewWrapper() {
    const [scheduleInfo, setScheduleInfo] = React.useState<null | {
        clientId: string;
        isPaused: boolean;
        isPending: boolean;
    }>(null);
    const [showFullHeight, setShowFullHeight] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [sessionHistoryId, setSessionHistoryId] = React.useState<
        string | null
    >(null);

    const navigate = useNavigate();

    const { isOpen } = useAppSelector((state) => state.nav);

    const { practice } = useAppSelector((state) => state.userPractice);

    const { data, isLoading, error, isSuccess } = useFetchProviderCalendar(
        practice?.provider_id || "",
        Boolean(practice?.provider_id)
    );

    const cancelSession = useCancelSession();

    // Query client
    const queryClient = useQueryClient();

    const { toast } = useToast();

    const useSmallScreenStyle =
        (isOpen && window.matchMedia("(max-width: 1320px)").matches) ||
        (!isOpen && window.matchMedia("(max-width: 1000px)").matches);

    const handleShowDialog = (schedule: ICalendarSchedule) => {
        setScheduleInfo({
            clientId: schedule.client_id,
            isPaused: schedule.is_paused,
            isPending: schedule.pending_task,
        });
    };

    const handleCloseDialog = () => {
        setScheduleInfo(null);
        navigate("/");
    };

    const handleCancelSession = (shouldNavigate: boolean) => {
        if (!sessionHistoryId) {
            toast({
                mode: "error",
                message: "Could not cancel session at this time",
            });
        } else {
            const payload = {
                session_history_ids: [sessionHistoryId],
            };

            cancelSession.mutate(payload, {
                onSuccess: () => {
                    queryClient.invalidateQueries({
                        queryKey: ["calendar", practice?.provider_id || ""],
                    });
                    toast({
                        mode: "success",
                        message: "Session cancelled successfully!",
                    });
                    if (shouldNavigate) {
                        navigate(`/add-note?note_type=cancellation_note`);
                    }
                    setShowModal(false);
                    setSessionHistoryId(null);
                },
                onError: () => {
                    toast({
                        mode: "error",
                        message: "Could not cancel session",
                    });
                },
            });
        }
    };

    return (
        <>
            <Dialog open={Boolean(scheduleInfo)}>
                <DialogContent
                    variant="drawer"
                    handleCloseDialog={handleCloseDialog}
                    classNames="p-0"
                    wrapperClass="w-[1000px]"
                    showFooter={false}
                >
                    <div>
                        {scheduleInfo && (
                            <ClientInfo scheduleInfo={scheduleInfo} />
                        )}
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={showModal}>
                <DialogContent
                    title="Cancel session"
                    variant="center"
                    onSaveClick={() => handleCancelSession(true)}
                    handleCloseDialog={() => setShowModal(false)}
                    isCancelBtnDisabled={cancelSession.isLoading}
                    isSubmitBtnDisabled={cancelSession.isLoading}
                    classNames="pt-24 pb-48"
                    onCancelClick={() => handleCancelSession(false)}
                    cancelText="Yes,cancel"
                    saveText="No,create No show note"
                >
                    <div>
                        <h3 className="font-medium text-xl mb-8">
                            Report session as Cancelled
                        </h3>
                        <p className="text-gray font-normal text-base">
                            Did the client cancel the session at least 24 hours
                            before the start time
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
            <div
                className={cn("h-[calc(100%-709px)] relative", {
                    "h-full overflow-y-auto":
                        showFullHeight && useSmallScreenStyle,
                    "h-[300px] overflow-hidden":
                        !showFullHeight && useSmallScreenStyle,
                })}
            >
                <div
                    className={cn(
                        "border-b border-b-strokedark px-24 h-[79px] flex flex-col gap-y-4 pb-[10px] pt-[29px] items-start sticky top-0 w-full z-10 bg-white",
                        {
                            "flex-row justify-between p-16 h-[54px] items-center":
                                useSmallScreenStyle,
                        }
                    )}
                >
                    <div className="flex gap-x-8">
                        <CalendarDayDarker />
                        <h3 className="text-base leading-none font-bold">
                            My Schedule
                        </h3>
                    </div>
                    <Link
                        to="/my-schedule?view=caseload"
                        className="text-primary text-xss font-semibold ml-24"
                    >
                        View caseload
                    </Link>
                </div>
                <div
                    className={cn(
                        "px-24 py-32 flex flex-col gap-y-16 relative h-[calc(709px-79px-32px)]",
                        {
                            "py-20 px-16 h-full": useSmallScreenStyle,
                        }
                    )}
                >
                    {isLoading &&
                        [1, 2, 3, 4].map((item) => (
                            <div
                                key={item}
                                className="flex flex-col gap-y-4 w-full mb-16 p-[6px] rounded-r8 border border-strokedark"
                            >
                                <div className="flex justify-between items-center">
                                    <Skeleton
                                        containerTestId="skeleton-loader"
                                        width="30%"
                                        height={5}
                                    />
                                    <Skeleton
                                        containerTestId="skeleton-loader"
                                        width="10%"
                                        height={5}
                                    />
                                </div>
                                <Skeleton
                                    containerTestId="skeleton-loader"
                                    width="20%"
                                    height={5}
                                />
                                <Skeleton
                                    containerTestId="skeleton-loader"
                                    width="10%"
                                    height={15}
                                />
                            </div>
                        ))}
                    {error && error.response?.status !== 404 && (
                        <ListState
                            isError
                            errorMsg={
                                error?.response?.data?.message ||
                                "Could not load your schedule as this time"
                            }
                            stateHelperText="Try reloading this page to solve this issue"
                            context="general"
                        />
                    )}
                    {data && isSuccess && data.data.length === 0 && (
                        <ListState
                            stateHelperText="Your schedule will appear here when available"
                            isError={false}
                            emptyMessage="No schedule found"
                            context="general"
                        />
                    )}
                    {data && isSuccess && data.data.length > 0 && (
                        <div>
                            {data?.data.map((schedule, index) => {
                                const { recurrence_rule: recurrenceRule } =
                                    schedule;
                                const colorStyles = getColorStylesForCalendarId(
                                    recurrenceRule as string
                                );
                                const style = {
                                    background: colorStyles.backgroundColor,
                                    color: colorStyles.color,
                                };
                                return (
                                    <div
                                        key={`${index + 1}`}
                                        className="last:mb-0 mb-16"
                                    >
                                        <div className="text-xss text-gray font-medium mb-8">
                                            {`${convertTimeTo12HoursFormat(
                                                `${schedule.start_hr}:${schedule.start_min}`
                                            )} - ${convertTimeTo12HoursFormat(
                                                `${schedule.end_hr}:${schedule.end_min}`
                                            )}`}
                                        </div>
                                        <Card classNames="p-4 border border-strokelight">
                                            <div
                                                className="px-16 py-8 h-[auto] relative rounded-[4px]"
                                                style={{
                                                    borderLeft: `2px solid ${style.color}`,
                                                    background:
                                                        style.background,
                                                }}
                                            >
                                                <div className="flex justify-between items-center gap-x-8">
                                                    <Button
                                                        className="capitalize text-xs font-semibold underline"
                                                        onClick={() =>
                                                            handleShowDialog(
                                                                schedule
                                                            )
                                                        }
                                                        variant="normal"
                                                        size="auto"
                                                    >
                                                        {`${schedule.first_name} ${schedule.last_name}`}
                                                    </Button>
                                                    <p className="font-semibold text-x8 text-gray">
                                                        {`Next - ${formatDate(
                                                            schedule.next_session_date,
                                                            true
                                                        )}`}
                                                    </p>
                                                </div>
                                                <p className="font-semibold text-xss mb-4 mt-[6px] text-gray">
                                                    {makeStringFirstLetterCapital(
                                                        removeEnumUnderscore(
                                                            schedule.service
                                                        )
                                                    )}
                                                </p>
                                                <div className="flex justify-between gap-x-8">
                                                    <div className="flex flex-wrap gap-x-4">
                                                        <Tag
                                                            bgColor={
                                                                getColorStylesForCalendarId(
                                                                    schedule.recurrence_rule as string
                                                                ).tagBgColor
                                                            }
                                                            textColor={
                                                                getColorStylesForCalendarId(
                                                                    schedule.recurrence_rule as string
                                                                ).textColor
                                                            }
                                                            title={
                                                                schedule.recurrence_rule as string
                                                            }
                                                            className="capitalize min-h-16 rounded-[2px] text-[8px]"
                                                        />

                                                        {schedule.pending_task && (
                                                            <Tag
                                                                bgColor="#FBC7C6"
                                                                textColor="#981F41"
                                                                title="Pending Task"
                                                                className="capitalize min-h-16 rounded-[2px] text-[8px]"
                                                            />
                                                        )}
                                                        {schedule.is_paused && (
                                                            <Tag
                                                                bgColor="#F7E5A4"
                                                                textColor="#634D17"
                                                                title="Paused"
                                                                className="capitalize min-h-16 rounded-[2px] text-[8px]"
                                                            />
                                                        )}
                                                        {schedule.insurance_provider && (
                                                            <Tag
                                                                bgColor="#ACECF5"
                                                                textColor="#1A738D"
                                                                title={removeEnumUnderscore(
                                                                    schedule.insurance_provider
                                                                )}
                                                                className="min-h-16 rounded-[2px] text-[8px] uppercase"
                                                            />
                                                        )}
                                                    </div>
                                                    {schedule.session_history_id && (
                                                        <Dropdown>
                                                            <DropdownTrigger
                                                                asChild
                                                            >
                                                                <Button
                                                                    variant="normal"
                                                                    size="auto"
                                                                    className="w-16 h-16 rounded-[16px] shadow-morebtn relative z-[1] data-[state=open]:border-primary-800"
                                                                >
                                                                    <MoreIcon />
                                                                </Button>
                                                            </DropdownTrigger>
                                                            <DropdownContent
                                                                align="end"
                                                                width="auto"
                                                                className="-mt-4"
                                                            >
                                                                {/* <DropdownItem className="text-xs">
                                                                Change
                                                                appointment
                                                            </DropdownItem> */}
                                                                <DropdownItem
                                                                    className="text-xs"
                                                                    onClick={() => {
                                                                        setShowModal(
                                                                            true
                                                                        );
                                                                        setSessionHistoryId(
                                                                            schedule.session_history_id ||
                                                                                ""
                                                                        );
                                                                    }}
                                                                >
                                                                    Cancel
                                                                    session
                                                                </DropdownItem>
                                                                {/* <DropdownItem className="text-xs">
                                                                Reschedule
                                                                appointment
                                                            </DropdownItem> */}
                                                            </DropdownContent>
                                                        </Dropdown>
                                                    )}
                                                </div>
                                                <img
                                                    className="top-0 absolute right-[16px] h-full w-[90px]"
                                                    alt="pattern background"
                                                    src={patternBg}
                                                />
                                            </div>
                                        </Card>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
                {useSmallScreenStyle &&
                    data &&
                    isSuccess &&
                    data.data.length > 2 && (
                        <div
                            className={cn(
                                "py-[10px] absolute bottom-0 flex items-center justify-center blur-cal w-full z-[2]",
                                {
                                    "static -mt-20": showFullHeight,
                                }
                            )}
                        >
                            <Button
                                variant="normal"
                                size="auto"
                                onClick={() =>
                                    setShowFullHeight(!showFullHeight)
                                }
                                className="text-black text-xss font-medium px-32 py-[5px] rounded-[4px] border-[0.4px] border-strokedark bg-white"
                            >
                                {showFullHeight ? "Show less" : "Load more"}
                            </Button>
                        </div>
                    )}
            </div>
        </>
    );
}

import axios from "../../../utils/axios";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    ICreatePractice,
    IEditPractice,
    IPracticesListFilter,
} from "../types/practices.types";

export async function fetchAllPractices(filters: IPracticesListFilter) {
    const response = await axios.get("/practices", {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });

    return response.data;
}

export async function createPractice(payload: ICreatePractice) {
    const response = await axios.post(`/practice`, payload);
    return response.data;
}

export async function updatePractice(
    practiceId: string,
    payload: IEditPractice
) {
    const response = await axios.patch(`/practices/${practiceId}`, payload);
    return response.data;
}

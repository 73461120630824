import * as React from "react";
import { Outlet } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/react";
import cn from "classnames";
import SideNav from "../../components/SideNav/SideNav";
import ScrollToTop from "../../utils/ScrollToTop";
import styles from "./AppContainer.module.scss";
import { useFetchDiagnosisCodes } from "../../hooks/queries";
import ContentFallback from "../../components/ErrorBoundaryFallback/ContentFallback/ContentFallback";
import { useAppSelector } from "../../hooks/useRedux";
import { useFetchPracticeSettings } from "../../modules/practice-settings/hooks/queries/practice-settings";

export default function AppContainer() {
    // Fetch diagnosis codes
    useFetchDiagnosisCodes();

    useFetchPracticeSettings({
        category: "general",
        setting_name: "prioritize_insurance_over_self_pay",
    });

    const { isOpen } = useAppSelector((state) => state.nav);

    const { practice } = useAppSelector((state) => state.userPractice);

    // TODO - use loading state here instead
    if (!practice) return null;

    return (
        <div className={styles.app}>
            <ScrollToTop />
            <div className={styles.inner}>
                <SideNav />
                <ErrorBoundary
                    fallback={<ContentFallback />}
                    onError={(err) => {
                        Sentry.captureException(err);
                    }}
                >
                    <div
                        className={cn(styles.main, {
                            [styles.main_full]: !isOpen,
                        })}
                        id="main"
                    >
                        <main id="main">
                            <Outlet />
                        </main>
                    </div>
                </ErrorBoundary>
            </div>
        </div>
    );
}

/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { ColumnDef, Row } from "@tanstack/react-table";
import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    Table,
} from "@jhool-io/fe-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import {
    IClaim,
    ClaimStatus,
    ClaimsHasRemit,
} from "../../../types/claims.types";
import {
    formatDate,
    getTotalAmountOfTableRowsSelected,
    showMoneyInAppFormat,
} from "../../../../../utils/helpers";
import MoreIcon from "../../../../../components/Icons/More";
import { getStatusTag } from "../../../helpers/claims.helper";
import { useFetchClaims } from "../../../hooks/claims.queries";
import Skeleton from "../../../../../components/Skeleton/Skeleton";
import ListState from "../../../../../components/ListState/ListState";
import { IAppCustomDates } from "../../../../../utils/types";
import {
    useBulkCreateClaim,
    useBulkUpdateClaims,
    useCreateDraftClaim,
    useDeleteClaims,
    useUpdateClaimStatus,
} from "../../../hooks/claims.mutations";
import useToast from "../../../../../hooks/useToast";
import { LISTS_DEFAULT_LIMIT } from "../../../../../utils/constants";
import TrashIcon from "../../../../../components/Icons/Trash";

interface ReadyForSubmissionListProps {
    searchString: string;
}

type Dialogs =
    | "update_claim"
    | "delete_claim"
    | "delete_claims"
    | "update_claims";

export default function ReadyForSubmissionList({
    searchString,
}: ReadyForSubmissionListProps) {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [rowSelection, setRowSelection] = React.useState({});
    const [getSelectedClaimsData, setGetSelectedClaimsData] =
        React.useState<Row<IClaim>[]>();
    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: "",
        direction: "",
    });
    const [dialogInView, setDialogInView] = React.useState<Dialogs | null>(
        null
    );
    const [selectedClaimId, setSelectedClaimId] = React.useState<string | null>(
        null
    );

    const sortableColumns = [
        "generated_on",
        "date_of_service",
        "submitted_on",
        "insurance_name",
        "claim_status",
        "client_name",
        "cpt_code",
    ];

    const pageFilter = Number(searchParams.get("ready_for_sub_page") || 1);

    const limitFilter =
        Number(searchParams.get("ready_for_sub_limit")) || LISTS_DEFAULT_LIMIT;

    const fromDateFilter = searchParams.get("note_dos_from");
    const toDateFilter = searchParams.get("note_dos_to");
    const insuranceProviderFilter =
        searchParams.get("insurance_provider") || "";
    const customDateFilter =
        (searchParams.get("date_range") as IAppCustomDates) ||
        IAppCustomDates.LAST_90_DAYS;
    const claimsStatusFilter = searchParams.get("status") as ClaimStatus;
    const hasRemitStatusFilter = searchParams.get("has_remits") || "";

    const getStatusFilter = () => {
        if (claimsStatusFilter) {
            if (
                [ClaimStatus.READY_FOR_SUBMISSION].includes(claimsStatusFilter)
            ) {
                return [claimsStatusFilter];
            }

            return [""];
        }

        return [ClaimStatus.READY_FOR_SUBMISSION];
    };

    const handleConfigAttribute = () => {
        if (sortableColumns.includes(sortConfig.key)) {
            return sortConfig.key;
        }

        return undefined;
    };

    const getHasRemitFilter = () => {
        if (hasRemitStatusFilter === ClaimsHasRemit.SHOW_CLAIMS_WITH_REMIT) {
            return "true";
        }
        if (hasRemitStatusFilter === ClaimsHasRemit.HIDE_CLAIMS_WITH_REMIT) {
            return "false";
        }
        return "";
    };

    const claimSourceFilter = searchParams.get("source") || "";

    const { data, error, isLoading, isSuccess } = useFetchClaims({
        claim_statuses: getStatusFilter(),
        custom_date: customDateFilter,
        search_string: searchString,
        note_dos_from: fromDateFilter || undefined,
        note_dos_to: toDateFilter || undefined,
        insurance_provider_id: insuranceProviderFilter,
        page: pageFilter,
        limit: limitFilter,
        has_remits: getHasRemitFilter(),
        sort_attribute:
            searchParams.get("ready_for_sub_sort_attr") ||
            handleConfigAttribute(),
        sort_order:
            searchParams.get("ready_for_sub_sort_order") ||
            sortConfig.direction,
        claim_source: claimSourceFilter,
    });

    const queryClient = useQueryClient();

    // Toast for success and error states
    const { toast } = useToast();

    // hook for updating claim status
    const patchClaimStatus = useUpdateClaimStatus();
    const deleteClaims = useDeleteClaims();
    const bulkUpdateClaims = useBulkUpdateClaims();
    const regenerateDraftClaim = useCreateDraftClaim();
    const bulkRegenerateClaims = useBulkCreateClaim();

    const selectedClaimObject = data?.data.find(
        (claim) => claim.claim_id === selectedClaimId
    );

    // onChange handler for page
    const handlePageChange = (page: number) => {
        searchParams.set("ready_for_sub_page", String(page));
        setSearchParams(searchParams);
    };

    const handleLimitChange = (limit: number) => {
        searchParams.set("ready_for_sub_limit", String(limit));
        setSearchParams(searchParams);
    };

    const handleDialogInView = (dialog: Dialogs | null, claimId?: string) => {
        if (claimId) {
            setDialogInView(dialog);
            setSelectedClaimId(claimId);
        } else {
            setDialogInView(dialog);
            setSelectedClaimId(null);
        }
    };

    // Array of selected claims ids
    const selectedClaimsIds =
        getSelectedClaimsData &&
        getSelectedClaimsData.map(
            (item: Row<IClaim>) => item.original.claim_id
        );

    const handleRowClick = (row: Row<IClaim>) => {
        navigate(
            `/claims/${row.original.claim_id}/${row.original.client_id}/${row.original.note_id}?key=claims&claims_view=view&viewFrom=claims&tab=submission_ready&claims_sub_tab=claims&active_claim_id=${row.original.claim_id}`
        );
    };

    const handleChangeStatus = (id: string, status: ClaimStatus) => {
        if (status === ClaimStatus.SUBMITTED) {
            const claimToUpdate = data?.data.find(
                (claim) => claim.claim_id === id
            );

            if (claimToUpdate) {
                let errorMessage = "";

                if (claimToUpdate.claim_content?.patientState?.length !== 2) {
                    errorMessage = `Invalid patient state`;
                }

                if (!claimToUpdate.claim_content?.subscriberCity) {
                    errorMessage = errorMessage
                        ? `${errorMessage}, Subscriber city is missing`
                        : `Subscriber city is missing`;
                }

                if (!claimToUpdate.claim_content?.patientCity) {
                    errorMessage = errorMessage
                        ? `${errorMessage}, Patient city is missing`
                        : `Patient city is missing`;
                }

                if (
                    !claimToUpdate.claim_content?.referringProviderNPI ||
                    claimToUpdate.claim_content?.referringProviderNPI
                        ?.length !== 10
                ) {
                    errorMessage = errorMessage
                        ? `${errorMessage}, Provider NPI should be 10 digits`
                        : `Provider NPI should be 10 digits`;
                }

                if (
                    claimToUpdate?.claim_content
                        ?.patientRelationshipToSubscriber === "18"
                ) {
                    if (
                        `${claimToUpdate.claim_content?.subscriberFirstName} ${claimToUpdate?.claim_content?.subscriberLastName}`.toLowerCase() !==
                        `${claimToUpdate.claim_content?.patientFirstName} ${claimToUpdate.claim_content?.patientLastName}`.toLowerCase()
                    ) {
                        errorMessage = errorMessage
                            ? `${errorMessage}, Patient name must be the same as subscriber name when patient relationship to insured is "SELF."`
                            : `Patient name must be the same as subscriber name when patient relationship to insured is "SELF.`;
                    }
                }

                if (errorMessage) {
                    toast({
                        mode: "error",
                        message: (
                            <>
                                {errorMessage.split(",").map((msg) => (
                                    <span
                                        key={msg}
                                        className="block leading-loose font-medium"
                                    >
                                        {msg}
                                    </span>
                                ))}
                            </>
                        ),
                        duration: 10000,
                    });
                    return;
                }
            }
        }
        const changeStatusPayload = {
            claim_id: id,
            claim_status: status,
        };
        patchClaimStatus.mutate(changeStatusPayload, {
            onSuccess: async (response) => {
                await queryClient.invalidateQueries(["claims"]);
                toast({
                    mode: "success",
                    message:
                        response?.message ||
                        "Claim status updated successfully",
                });
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not update claim status at this time",
                });
            },
        });
    };

    const handleDeleteClaims = () => {
        deleteClaims.mutate(
            selectedClaimsIds && selectedClaimsIds?.length > 0
                ? selectedClaimsIds
                : [selectedClaimId || ""],
            {
                onSuccess: async (res) => {
                    await queryClient.invalidateQueries([
                        "claims",
                        { claim_statuses: ["ready_for_submission"] },
                    ]);
                    toast({
                        mode: "success",
                        message:
                            `${res.message}` || "Claims deleted successfully",
                    });
                    handleDialogInView(null);
                    setRowSelection({});
                },

                onError(err) {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not delete claims, please try again",
                    });
                },
            }
        );
    };

    // Validate selected claims before submitting (changing status from ready for submission)
    const validateSelectedClaims = () => {
        if (!getSelectedClaimsData) return undefined;

        let claimsWithErrorMessage: Record<string, string[]> = {};

        // eslint-disable-next-line no-restricted-syntax
        for (const claim of getSelectedClaimsData) {
            const claimToUpdate = claim.original;

            claimsWithErrorMessage = {
                ...claimsWithErrorMessage,
                [claimToUpdate.claim_id]: [],
            };

            if (claimToUpdate.claim_content?.patientState?.length !== 2) {
                claimsWithErrorMessage[claimToUpdate.claim_id].push(
                    "Invalid patient state"
                );
            }

            if (!claimToUpdate.claim_content?.subscriberCity) {
                claimsWithErrorMessage[claimToUpdate.claim_id].push(
                    "Subscriber city is missing"
                );
            }

            if (!claimToUpdate.claim_content?.patientCity) {
                claimsWithErrorMessage[claimToUpdate.claim_id].push(
                    "Patient city is missing"
                );
            }

            if (
                !claimToUpdate?.claim_content?.referringProviderNPI ||
                claimToUpdate?.claim_content?.referringProviderNPI?.length !==
                    10
            ) {
                claimsWithErrorMessage[claimToUpdate.claim_id].push(
                    "Provider NPI should be 10 digits"
                );
            }

            if (
                claimToUpdate?.claim_content
                    ?.patientRelationshipToSubscriber === "18"
            ) {
                const subscriberName =
                    `${claimToUpdate.claim_content?.subscriberFirstName} ${claimToUpdate?.claim_content?.subscriberLastName}`.toLowerCase();
                const patientName =
                    `${claimToUpdate.claim_content?.patientFirstName} ${claimToUpdate.claim_content?.patientLastName}`.toLowerCase();

                if (subscriberName !== patientName) {
                    claimsWithErrorMessage[claimToUpdate.claim_id].push(
                        `Patient name must be the same as subscriber name when patient relationship to insured is "SELF."`
                    );
                }
            }
        }

        return claimsWithErrorMessage;
    };

    const handleBulkUpdate = () => {
        if (validateSelectedClaims()) {
            const claimsToSubmitWithTheirErrors =
                validateSelectedClaims() as Record<string, string[]>;

            // check if any of the selected claim has an empty errors array
            const claimsIdsWithEmptyErrorsArray = Object.keys(
                claimsToSubmitWithTheirErrors
            ).filter((key) => claimsToSubmitWithTheirErrors[key].length === 0);

            const claimsIdsWithErrorsArray = Object.keys(
                claimsToSubmitWithTheirErrors
            ).filter((key) => claimsToSubmitWithTheirErrors[key].length !== 0);

            if (
                claimsIdsWithErrorsArray.length ===
                getSelectedClaimsData?.length
            ) {
                toast({
                    mode: "error",
                    message: (
                        <span className="flex flex-col">
                            <span className="font-semibold ">{`${claimsIdsWithErrorsArray.length} not submitted`}</span>
                            {data?.data
                                .filter((claim) => {
                                    const claimId = claim.claim_id;
                                    return claimsIdsWithErrorsArray.includes(
                                        claimId
                                    );
                                })
                                .map((claim) => (
                                    <span key={claim.claim_id}>{`Claim for ${
                                        claim.client_name
                                    } : DOS: ${
                                        claim.date_of_service
                                            ? formatDate(claim.date_of_service)
                                            : "--"
                                    } check -  ${claimsToSubmitWithTheirErrors[
                                        claim.claim_id
                                    ].join(", ")}`}</span>
                                ))}
                        </span>
                    ),
                    duration: 15000,
                });
            } else if (claimsIdsWithEmptyErrorsArray.length > 0) {
                const updateStatusPayload = {
                    claim_ids: claimsIdsWithEmptyErrorsArray,
                    claim_status: ClaimStatus.SUBMITTED,
                };

                bulkUpdateClaims.mutate(updateStatusPayload, {
                    onSuccess: async (res) => {
                        await queryClient.invalidateQueries(["claims"]);
                        toast({
                            mode: "success",
                            message:
                                res.message ||
                                `${claimsIdsWithEmptyErrorsArray.length} updated, ${claimsIdsWithErrorsArray} not updated`,
                        });
                        setRowSelection({});
                    },
                    onError: (err) => {
                        toast({
                            mode: "error",
                            message:
                                err.message ||
                                "Could not change claim status at this time",
                        });
                    },
                });
            }
        }
    };

    const handleBulkRegenerateClaims = () => {
        const noteIds =
            data?.data
                .filter((claim) => selectedClaimsIds?.includes(claim.claim_id))
                .map((claim) => claim.note_id) || [];

        bulkRegenerateClaims.mutate(
            { note_ids: noteIds },
            {
                onSuccess: async ({ message, data: res }) => {
                    await queryClient.invalidateQueries([
                        "claims",
                        { claim_statuses: ["ready_for_submission"] },
                    ]);

                    toast({
                        mode: "success",
                        message: `${res.regenerated_claims.length} claim(s) updated, ${res.unregenerated_claims.length} claim(s) not updated`,
                        title: message || `Claims updated successfully`,
                    });

                    handleDialogInView(null);
                    setRowSelection({});
                },
                onError: () => {
                    toast({
                        mode: "error",
                        message: "Cannot update claims at this time",
                    });
                },
            }
        );
    };

    const handleRegenerateClaims = () => {
        const changeStatusPayload = {
            note_id: selectedClaimObject?.note_id || "",
            overwrite_existing: true,
        };
        regenerateDraftClaim.mutate(changeStatusPayload, {
            onSuccess: async () => {
                await queryClient.invalidateQueries([
                    "claims",
                    { claim_statuses: ["ready_for_submission"] },
                ]);

                toast({
                    mode: "success",
                    message: `Claim updated successfully`,
                });

                handleDialogInView(null);
            },
            onError: () => {
                toast({
                    mode: "error",
                    message: "Cannot update claim at this time",
                });
            },
        });
    };

    const columns: ColumnDef<IClaim>[] = [
        {
            id: "select",
            header: ({ table }) => (
                <Checkbox
                    handleChange={table.getToggleAllRowsSelectedHandler()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={table.getIsAllRowsSelected()}
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    handleChange={row.getToggleSelectedHandler()}
                    indeterminate={row.getIsSomeSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={row.getIsSelected()}
                />
            ),
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "client_name",
                    header: "CLIENT NAME",
                    cell: ({ row }) => (
                        <span className="capitalize">
                            {row.original.client_name}
                        </span>
                    ),
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "date_of_service",
                    header: "DATE OF SERVICE",
                    cell: ({ row }) => formatDate(row.original.date_of_service),
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "claim_status",
                    header: "STATUS",
                    cell: ({ row }) => getStatusTag(row.original.claim_status),
                },
            ],
        },
        {
            accessorKey: "claim_source",
            header: "CLAIM SOURCE",
            cell: ({ row }) => row.original.claim_source || "--",
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "insurance_name",
                    header: "INSURANCE",
                    cell: ({ row }) => (
                        <span className="uppercase">
                            {row.original.insurance_name}
                        </span>
                    ),
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "provider_name",
                    header: "PROVIDER",
                    cell: ({ row }) => (
                        <span className="capitalize">
                            {row.original.provider_name}
                        </span>
                    ),
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "cpt_code",
                    header: "CPT CODE",
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "charge_amount",
                    header: "CHARGE",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(row.original.charge_amount),
                },
            ],
        },

        {
            header: "OUTSTANDING",
            columns: [
                {
                    accessorKey: "insurance_amount_outstanding",
                    header: "INSURANCE",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(
                            row.original.insurance_amount_outstanding
                        ),
                },
                {
                    accessorKey: "coinsurance_amount_outstanding",
                    header: "CO-INSURANCE",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(
                            row.original.coinsurance_amount_outstanding
                        ),
                },
            ],
        },
        {
            accessorKey: "external_reference_id",
            header: "CLAIM NO",
        },
        {
            accessorKey: "has_claim_attachments",
            header: "Attached doc",
            cell: ({ row }) => (
                <span
                    className={
                        row.original.has_claim_attachments
                            ? "text-primary"
                            : "text-danger"
                    }
                >
                    {row.original.has_claim_attachments ? "Yes" : "No"}
                </span>
            ),
        },
        {
            accessorKey: "accepts_assignment",
            header: "ACCEPTS ASSIGNMENT",
            cell: ({ row }) => (
                <span
                    className={
                        row.original.accepts_assignment
                            ? "text-primary"
                            : "text-danger"
                    }
                >
                    {row.original.accepts_assignment ? "Yes" : "No"}
                </span>
            ),
        },
        {
            accessorKey: "claim_id",
            header: "",
            cell: ({ row }) => (
                <Dropdown>
                    <DropdownTrigger asChild>
                        <Button
                            size="auto"
                            variant="normal"
                            className="w-16 h-16 rounded-[16px] shadow-morebtn relative z-[1] data-[state=open]:border-primary-800 mt-1"
                        >
                            <MoreIcon />
                        </Button>
                    </DropdownTrigger>
                    <DropdownContent
                        width="auto"
                        className="text-gray"
                        align="end"
                    >
                        <DropdownItem
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDialogInView(
                                    "update_claim",
                                    row.original.claim_id
                                );
                            }}
                        >
                            Update Claim
                        </DropdownItem>
                        <DropdownItem
                            onClick={(e) => {
                                e.stopPropagation();
                                handleChangeStatus(
                                    row.original.claim_id,
                                    ClaimStatus.SUBMITTED
                                );
                            }}
                        >
                            Submit Claim
                        </DropdownItem>
                        <DropdownItem
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDialogInView(
                                    "delete_claim",
                                    row.original.claim_id
                                );
                            }}
                        >
                            Delete Claim
                        </DropdownItem>
                    </DropdownContent>
                </Dropdown>
            ),
        },
    ];

    return (
        <>
            <Dialog
                open={
                    dialogInView === "delete_claim" ||
                    dialogInView === "delete_claims"
                }
            >
                <DialogContent
                    title={
                        dialogInView === "delete_claim"
                            ? "Delete Claim"
                            : "Delete Claims"
                    }
                    variant="center"
                    handleCloseDialog={() => setDialogInView(null)}
                    isSubmitBtnDisabled={deleteClaims.isLoading}
                    isCancelBtnDisabled={deleteClaims.isLoading}
                    cancelText="No,cancel"
                    saveText={`Yes,delete claim${
                        dialogInView === "delete_claim" ? "" : "s"
                    }`}
                    isDeleting
                    onSaveClick={handleDeleteClaims}
                >
                    <div className="px-8">
                        <h3 className="font-medium text-xl mb-8">
                            {`Are you sure you want to delete ${
                                dialogInView === "delete_claim"
                                    ? "this claim"
                                    : "the claims"
                            }`}
                        </h3>
                        <p className="text-gray font-normal text-base">
                            Please note that this action cannot be undone.
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                open={
                    dialogInView === "update_claim" ||
                    dialogInView === "update_claims"
                }
            >
                <DialogContent
                    variant="center"
                    title={`Update claim${
                        dialogInView === "update_claims" ? "s" : ""
                    }`}
                    showFooter
                    cancelText="Cancel"
                    saveText={`Yes, Update Claim${
                        dialogInView === "update_claims" ? "s" : ""
                    }`}
                    onSaveClick={
                        dialogInView === "update_claims"
                            ? handleBulkRegenerateClaims
                            : handleRegenerateClaims
                    }
                    handleCloseDialog={() => handleDialogInView(null)}
                    isSubmitBtnDisabled={
                        regenerateDraftClaim.isLoading ||
                        bulkRegenerateClaims.isLoading
                    }
                    isCancelBtnDisabled={
                        regenerateDraftClaim.isLoading ||
                        bulkRegenerateClaims.isLoading
                    }
                    classNames="pt-24 pb-48"
                >
                    <div>
                        <h3 className="font-medium text-xl mb-8">
                            {`Are you sure you want to update claim${
                                dialogInView === "update_claims" ? "s" : ""
                            }?`}
                        </h3>
                        <p className="text-gray font-normal text-base">
                            {`This will update claim${
                                dialogInView === "update_claims" ? "s" : ""
                            } with recent changes to the
                            client, provider and practice? This action will
                            update the data on the claim${
                                dialogInView === "update_claims" ? "s" : ""
                            }.`}
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
            {error && error?.response?.status !== 404 && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg="Cannot display claims at this time please try again later"
                />
            )}
            {isLoading && (
                <Skeleton
                    containerTestId="ready-for-submission-claims-loader"
                    type="table"
                    count={6}
                />
            )}
            {data && isSuccess && (
                <>
                    {data.data.length === 0 && (
                        <ListState
                            stateHelperText="Claims ready for submission will appear here once available "
                            emptyMessage="No claims yet"
                            isError={false}
                        />
                    )}

                    {data.data.length > 0 && (
                        <>
                            {getTotalAmountOfTableRowsSelected(rowSelection)
                                .amount > 0 && (
                                <div className="flex items-center mb-16 gap-x-12">
                                    <div className="font-medium text-base">
                                        {
                                            getTotalAmountOfTableRowsSelected(
                                                rowSelection
                                            ).amountString
                                        }
                                        :
                                    </div>
                                    <Button
                                        variant="secondary"
                                        onClick={() =>
                                            handleDialogInView("update_claims")
                                        }
                                    >
                                        Update claims
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onClick={handleBulkUpdate}
                                        disabled={bulkUpdateClaims.isLoading}
                                    >
                                        Submit claims
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onClick={handleDeleteClaims}
                                        disabled={deleteClaims.isLoading}
                                        className="gap-x-8"
                                    >
                                        <TrashIcon /> Delete claims
                                    </Button>
                                </div>
                            )}
                            <Table
                                columns={columns}
                                data={data.data}
                                handleRowClick={handleRowClick}
                                setRowSelection={setRowSelection}
                                rowSelection={rowSelection}
                                setGetSelectedData={setGetSelectedClaimsData}
                                hasPagination={data.total_count > 20}
                                isHeaderFixed
                                setSortConfig={setSortConfig}
                                sortConfig={sortConfig}
                                sortableColumns={sortableColumns}
                                pagination={
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handlePageChange}
                                        limit={limitFilter}
                                        onLimitChange={handleLimitChange}
                                    />
                                }
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
}

/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { Tabs } from "@jhool-io/fe-components";
import { useSearchParams } from "react-router-dom";
import InsuranceAgingReport from "../InsuranceAgingReport/InsuranceAgingReport";
import CoInsuranceAgingReport from "../CoInsuranceAgingReport/CoInsuranceAgingReport";

export default function AgingReportWrapper() {
    const [, setSearchParams] = useSearchParams();

    const handleSetTabInUrl = (tab: string) => {
        setSearchParams(() => `?report=aging_report&tab=${tab}`);
    };

    const tabItems = [
        {
            key: "insurance",
            label: "Insurance",
            children: <InsuranceAgingReport />,
            onClick: () => handleSetTabInUrl("insurance"),
        },
        {
            key: "coinsurance",
            label: "Co-insurance",
            children: <CoInsuranceAgingReport />,
            onClick: () => handleSetTabInUrl("coinsurance"),
        },
    ];

    return <Tabs items={tabItems} />;
}

import Navbar from "../../../components/Navbar/Navbar";
import TasksListsWrapper from "../components/TasksListsWrapper";

export default function TasksView() {
    return (
        <>
            <Navbar title="Tasks" />
            <div className="page">
                <TasksListsWrapper />
            </div>
        </>
    );
}

import * as React from "react";
import cn from "classnames";
import Button from "../Button/Button";
import Card from "../Card/Card";
import NavArrowDownIcon from "../Icons/NavArrowDown";
import NavArrowUpIcon from "../Icons/NavArrowUp";
import styles from "./Accordion.module.scss";

interface AccordionProps {
    /** Accordion header */
    header: React.ReactNode;
    /** Accordion content */
    content: React.ReactNode;
    /** Determines accordion initial state */
    isAccordionMaximised?: boolean;
    /** Optional style object to add inline styling */
    style?: Record<string, string>;
    /** Optional extra style for content */
    className?: string;
    /** Optional classname to style card */
    cardClass?: string;
    /** Maximize toggle button position */
    buttonPosition?: "right" | "left";
    /** Accordion variant relative to position of arrow for minimizing and maximizing the accordion */
    variant?: "arrow-fixed" | "arrow-flexed";
    /** Hide accordion arrow */
    hideArrow?: boolean;
}

export default function Accordion({
    header,
    content,
    style,
    isAccordionMaximised,
    className,
    cardClass,
    buttonPosition = "right",
    variant = "arrow-fixed",
    hideArrow = false,
}: AccordionProps) {
    const [isMaximised, setIsMaximised] = React.useState<boolean>(
        isAccordionMaximised || false
    );

    const itemsRef = React.useRef<HTMLDivElement>(null);

    const handleToggleMaximizeState = () => {
        setIsMaximised((state) => !state);
    };

    React.useEffect(() => {
        if (itemsRef.current) {
            if (isMaximised) {
                itemsRef.current.style.height = `auto`;
            } else {
                itemsRef.current.style.height = `0`;
            }
        }
    }, [isMaximised]);

    if (variant === "arrow-flexed") {
        return (
            <Card style={style} className={cn(styles.flexed, cardClass)}>
                <div className={styles.flexed_header}>
                    {header}
                    {!hideArrow ? (
                        <Button
                            ariaLabel="nav arrow"
                            type="button"
                            size="auto"
                            onClick={handleToggleMaximizeState}
                            mode="transparent"
                            className={cn(styles.flexed_btn)}
                        >
                            {isMaximised ? (
                                <NavArrowUpIcon />
                            ) : (
                                <NavArrowDownIcon />
                            )}
                        </Button>
                    ) : null}
                </div>
                <div
                    ref={itemsRef}
                    className={`${styles.flexed_content} ${className}`}
                >
                    {content}
                </div>
            </Card>
        );
    }

    return (
        <Card
            type="dashboard"
            style={style}
            className={cn(styles.accordion, cardClass)}
        >
            <Button
                ariaLabel="nav arrow"
                type="button"
                size="auto"
                onClick={handleToggleMaximizeState}
                mode="transparent"
                className={cn(styles.togglebtn, styles.togglebtn_right, {
                    [styles.hide]: buttonPosition === "left",
                })}
            >
                {isMaximised ? <NavArrowUpIcon /> : <NavArrowDownIcon />}
            </Button>
            <div
                className={cn(styles.header, {
                    [styles.header_btnleft]: buttonPosition === "left",
                })}
            >
                {header}
                <Button
                    ariaLabel="nav arrow"
                    type="button"
                    size="auto"
                    onClick={handleToggleMaximizeState}
                    mode="transparent"
                    className={cn(styles.togglebtn, styles.togglebtn_left, {
                        [styles.hide]: buttonPosition === "right",
                    })}
                >
                    {isMaximised ? <NavArrowUpIcon /> : <NavArrowDownIcon />}
                </Button>
            </div>
            <div ref={itemsRef} className={`${styles.content} ${className}`}>
                {content}
            </div>
        </Card>
    );
}

export default function UserStrokeIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="user 1">
                <g id="Group">
                    <path
                        id="Vector"
                        d="M10.0009 8.05642C11.6884 8.05642 13.0564 6.68841 13.0564 5.00087C13.0564 3.31333 11.6884 1.94531 10.0009 1.94531C8.31333 1.94531 6.94531 3.31333 6.94531 5.00087C6.94531 6.68841 8.31333 8.05642 10.0009 8.05642Z"
                        stroke="#576A81"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_2"
                        d="M15.2915 17.2426C16.2471 16.9415 16.7493 15.8859 16.3437 14.9704C15.2659 12.5359 12.8337 10.8359 10.0004 10.8359C7.16706 10.8359 4.73484 12.5359 3.65706 14.9704C3.2515 15.887 3.75372 16.9415 4.70928 17.2426C6.07039 17.6715 7.8715 18.0582 10.0004 18.0582C12.1293 18.0582 13.9304 17.6715 15.2915 17.2426Z"
                        stroke="#576A81"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </svg>
    );
}

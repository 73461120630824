import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { TextArea } from "@jhool-io/fe-components";
import { ClaimStatus, IBulkUpdateClaims } from "../../../types/claims.types";
import { useBulkUpdateClaims } from "../../../hooks/claims.mutations";
import useToast from "../../../../../hooks/useToast";
import {
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../../utils/helpers";

interface BulkUpdateStatusProps {
    /** function to call when the form's submit button is clicked */
    onFormSubmit(): void;
    /** the IDs of the claims */
    claimsId: string[];
    /** the note Id */
    claimStatus: ClaimStatus;
}

const schema = yup.object().shape({
    status_notes: yup.string(),
});

export default function BulkUpdateClaimForm({
    onFormSubmit,
    claimsId,
    claimStatus,
}: BulkUpdateStatusProps) {
    const { register, handleSubmit } = useForm<IBulkUpdateClaims>({
        resolver: yupResolver(schema),
    });

    const { toast } = useToast();

    const queryClient = useQueryClient();

    const bulkUpdateClaims = useBulkUpdateClaims();

    const onSubmit = (payload: IBulkUpdateClaims) => {
        const updateStatusPayload = {
            ...payload,
            claim_ids: claimsId,
            claim_status: claimStatus,
        };

        bulkUpdateClaims.mutate(updateStatusPayload, {
            onSuccess: (res) => {
                queryClient.invalidateQueries(["claims"]);
                toast({
                    mode: "success",
                    message:
                        res.message || "Claims status updated successfully",
                });
                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.message ||
                        "Could not change claim status at this time",
                });
            },
        });
    };
    return (
        <form id="bulk-update-claims" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-5 text-xs">
                The selected claims will be marked as
                <span className=" text-sm ml-1 font-semibold">
                    {makeStringFirstLetterCapital(
                        removeEnumUnderscore(claimStatus)
                    )}
                </span>
            </div>
            <TextArea
                {...register("status_notes")}
                label="Optional status note"
                placeholder="Status note"
                maxLength={40}
            />
        </form>
    );
}

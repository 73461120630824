import { Input } from "@jhool-io/fe-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQueryClient } from "@tanstack/react-query";
import { useUpdateProviderInfo } from "../../hooks/providers.mutations";
import { IEditProviderInfo } from "../../types/providers.types";
import useToast from "../../../../hooks/useToast";

interface EditProviderProps {
    onFormSubmit: () => void;
    providerId: string;
    defaultNPI?: string;
}

const schema = yup.object({
    npi: yup.string(),
});

export default function EditProvider({
    onFormSubmit,
    providerId,
    defaultNPI,
}: EditProviderProps) {
    const { register, handleSubmit } = useForm<IEditProviderInfo>({
        resolver: yupResolver(schema),
    });

    const updateProviderInfo = useUpdateProviderInfo(providerId);

    const queryClient = useQueryClient();

    const { toast } = useToast();

    const onSubmit = (payload: IEditProviderInfo) => {
        updateProviderInfo.mutate(payload, {
            onSuccess: (res) => {
                queryClient.invalidateQueries({ queryKey: ["providers"] });
                toast({
                    mode: "success",
                    message:
                        res.message || "Provider info updated successfully",
                });
                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not update provider info at this time",
                });
            },
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} id="update-provider-info">
            <Input
                {...register("npi")}
                label="NPI"
                placeholder="NPI"
                defaultValue={defaultNPI}
            />
        </form>
    );
}

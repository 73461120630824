export default function CopyIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_4474_3283)">
                <path
                    opacity="0.4"
                    d="M12.5713 3V0H5.42843C5.2011 0 4.98309 0.0790176 4.82234 0.21967C4.66159 0.360322 4.57129 0.551088 4.57129 0.75V12.25C4.57129 12.4489 4.66159 12.6397 4.82234 12.7803C4.98309 12.921 5.2011 13 5.42843 13H15.1427C15.37 13 15.5881 12.921 15.7488 12.7803C15.9096 12.6397 15.9999 12.4489 15.9999 12.25V3H12.5713Z"
                    fill="#0B132B"
                />
                <path
                    d="M3.42857 12.2499V2.99994H0.857143C0.629814 2.99994 0.411797 3.07896 0.251051 3.21961C0.0903059 3.36026 0 3.55103 0 3.74994L0 15.2499C0 15.4489 0.0903059 15.6396 0.251051 15.7803C0.411797 15.9209 0.629814 15.9999 0.857143 15.9999H10.5714C10.7988 15.9999 11.0168 15.9209 11.1775 15.7803C11.3383 15.6396 11.4286 15.4489 11.4286 15.2499V13.9999H5.42857C4.89831 13.9994 4.38993 13.8149 4.01498 13.4868C3.64003 13.1587 3.42914 12.7139 3.42857 12.2499ZM15.75 2.28119L13.3929 0.218691C13.3131 0.149104 13.2184 0.0939656 13.1142 0.0564304C13.01 0.0188952 12.8984 -0.000300525 12.7857 -5.87799e-05H12.5714V2.99994H16V2.81057C16 2.61212 15.9101 2.42176 15.75 2.28119Z"
                    fill="#0B132B"
                />
            </g>
            <defs>
                <clipPath id="clip0_4474_3283">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IEditProviderInfo, IProvider } from "../types/providers.types";
import { IApiResponse } from "../../../utils/types/api-response";
import { updateProviderInfo } from "../services/providers.services";

export function useUpdateProviderInfo(providerId: string) {
    return useMutation<
        IApiResponse<IEditProviderInfo>,
        AxiosError<IApiResponse<IProvider>>,
        IEditProviderInfo
    >({
        mutationKey: ["update-provider-info"],
        mutationFn: (data: IEditProviderInfo) =>
            updateProviderInfo(providerId, data),
    });
}

export default function TrendingDownIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.6693 12L12.1959 10.4733L8.9426 7.22L6.27594 9.88667L1.33594 4.94L2.27594 4L6.27594 8L8.9426 5.33333L13.1426 9.52667L14.6693 8V12H10.6693Z"
                fill="#F93C65"
            />
        </svg>
    );
}

import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IApiResponse } from "../../../utils/types/api-response";
import {
    ICreatePractice,
    IEditPractice,
    IPractice,
} from "../types/practices.types";
import { createPractice, updatePractice } from "../services/practices.services";

export function useCreatePractice() {
    return useMutation<
        IApiResponse<IPractice>,
        AxiosError<IApiResponse<IPractice>>,
        ICreatePractice
    >({
        mutationKey: ["add-practice"],
        mutationFn: (payload: ICreatePractice) => createPractice(payload),
    });
}

export function useUpdatePractice(practiceId: string) {
    return useMutation<
        IApiResponse<IPractice>,
        AxiosError<IApiResponse<IPractice>>,
        IEditPractice
    >({
        mutationKey: ["update-practice"],
        mutationFn: (payload: IEditPractice) =>
            updatePractice(practiceId, payload),
    });
}

import axios from "../../../utils/axios";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    ICreatePayerPayload,
    IDeletePayerPayload,
    IGetPayersFilter,
} from "../types/payers.types";

// endpoint to get payers
export async function getPayers(filters: IGetPayersFilter) {
    const response = await axios.get(`/insurance_providers`, {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });

    return response.data;
}

// create a new payer
export async function createPayer(payload: ICreatePayerPayload) {
    const response = await axios.post(`/insurance_providers`, payload);

    return response.data;
}

// edit existing payer
export async function editPayer(payload: ICreatePayerPayload) {
    const response = await axios.patch(`/insurance_providers`, payload);

    return response.data;
}

// delete a payer
export async function deletePayer(payload: IDeletePayerPayload) {
    const response = await axios.delete(`/insurance_providers`, {
        data: payload,
    });

    return response.data;
}

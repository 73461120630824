export default function MapIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="map 1">
                <g id="Group">
                    <path
                        id="Vector"
                        d="M6.94434 3.10156V14.7682"
                        stroke="#576A81"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_2"
                        d="M13.0557 5.23438V16.901"
                        stroke="#576A81"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_3"
                        d="M2.81434 3.97577L6.62767 3.12799C6.83434 3.08244 7.04989 3.09577 7.24878 3.16799L12.751 5.1691C12.9499 5.24133 13.1654 5.25577 13.3721 5.2091L16.7032 4.4691C17.3977 4.31466 18.0554 4.84244 18.0554 5.55355V14.9447C18.0554 15.4658 17.6932 15.9169 17.1854 16.0291L13.3721 16.8769C13.1654 16.9224 12.9499 16.9091 12.751 16.8369L7.24878 14.8358C7.04989 14.7635 6.83434 14.7491 6.62767 14.7958L3.29656 15.5358C2.60211 15.6902 1.94434 15.1624 1.94434 14.4513V5.06022C1.94434 4.5391 2.30656 4.08799 2.81434 3.97577Z"
                        stroke="#576A81"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </svg>
    );
}

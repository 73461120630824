import { ColumnDef, Row } from "@tanstack/react-table";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Table } from "@jhool-io/fe-components";
import { useFetchNoteActivities } from "../../../../hooks/queries/note";
import { IActivity } from "../../../../utils/types/notes";
import { formatDate } from "../../../../utils/helpers";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import MobileListItem from "../../../../shared-ui/MobileListItem/MobileListItem";

const columns: ColumnDef<IActivity>[] = [
    {
        accessorKey: "activity_datetime",
        header: "date",
        cell: ({ row }) => formatDate(row.original.activity_datetime),
    },
    {
        accessorKey: "note_activity_id",
        header: "Time",
        cell: ({ row }) =>
            formatDate(row.original.activity_datetime, false, "h:mma"),
    },
    {
        accessorKey: "activity_user",
        header: "AUTHOR",
        cell: ({ row }) => (
            <span className="capitalize">
                {`${row.original.activity_user.first_name} ${row.original.activity_user.last_name}`}
            </span>
        ),
    },
    {
        accessorKey: "activity_description",
        header: "DESCRIPTION",
        cell: ({ row }) =>
            row.original.metadata && row.original.metadata.flag_for_audit_notes
                ? `${row.original.activity_description} - ${row.original.metadata.flag_for_audit_notes}`
                : row.original.activity_description,
    },
];

export default function NoteActivityList() {
    // Get id from url params
    const params = useParams();
    const noteId = params.noteId as string;

    const navigate = useNavigate();
    const location = useLocation();

    const { data, error, isLoading, isSuccess } =
        useFetchNoteActivities(noteId);

    const handleRowClick = (row: Row<IActivity>) => {
        if (row.original.metadata && row.original.metadata.addendum_field_id) {
            navigate(
                `${location.pathname}#${row.original.metadata.addendum_field_id}?note_tab=content`
            );
        }
    };

    return (
        <>
            {isLoading && (
                <Skeleton
                    type="table"
                    containerTestId="activities-loader"
                    count={6}
                />
            )}
            {error && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display notes activity at this time please try again later`
                    }
                />
            )}
            {data && isSuccess && (
                <>
                    <div className="hidden md:block">
                        <Table
                            columns={columns}
                            data={data.data.note_activities}
                            handleRowClick={(row) => {
                                return row.original.metadata &&
                                    row.original.metadata.addendum_field_id
                                    ? handleRowClick(row)
                                    : undefined;
                            }}
                            handleRowStyle={(row) =>
                                !row.original.metadata
                                    ? {
                                          pointerEvents: "none",
                                      }
                                    : {
                                          pointerEvents: "all",
                                      }
                            }
                        />
                    </div>
                    <div className="block md:hidden" role="table">
                        <div className="flex flex-col gap-y-12">
                            {data.data.note_activities.map((activity) => (
                                <MobileListItem
                                    key={activity.note_activity_id}
                                    showCheckButton={false}
                                    topChildren={
                                        <div className="flex flex-col gap-y-4">
                                            <span className="capitalize font-semibold text-base">
                                                {`${activity.activity_user.first_name} ${activity.activity_user.last_name}`}
                                            </span>
                                        </div>
                                    }
                                    bottomChildren={
                                        <div className="flex flex-col gap-y-8 w-full items-start">
                                            <div className="flex items-center w-full text-left gap-x-4">
                                                <div className="basis-1/2">
                                                    <span className="block font-medium text-xss uppercase">
                                                        date
                                                    </span>
                                                    <span className="block text-gray font-semibold text-xs">
                                                        {formatDate(
                                                            activity.activity_datetime
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="basis-1/2">
                                                    <span className="block font-medium text-xss uppercase">
                                                        Time
                                                    </span>
                                                    <span className="block text-gray font-semibold text-xs">
                                                        {formatDate(
                                                            activity.activity_datetime,
                                                            false,
                                                            "h:mma"
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <span className="block font-medium text-xss uppercase mb-4">
                                                    description
                                                </span>
                                                <div className="block !text-gray !font-semibold !text-xs">
                                                    {}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />
                            ))}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

import { SvgProps } from "../../utils/types";

export default function TimesIcon({ className, stroke }: SvgProps) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M4.50781 11.4955L8.00315 8.00022L11.4985 11.4955M11.4985 4.50488L8.00248 8.00022L4.50781 4.50488"
                stroke={stroke || "#1a738d"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

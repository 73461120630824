import * as React from "react";
import cn from "classnames";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Dialog, DialogContent } from "@jhool-io/fe-components";
import { useIsMutating } from "@tanstack/react-query";
import styles from "./AppointmentDetails.module.scss";
import {
    ModifierTypes,
    NoteLabels,
    NoteTypes,
} from "../../../utils/types/notes";
import EditIcon from "../../Icons/Edit";
import {
    removeEnumUnderscore,
    hideNoteField,
    getTimeDuration,
    formatDate,
} from "../../../utils/helpers";
import Accordion from "../../Accordion/Accordion";
import { useFetchSessionNote } from "../../../hooks/queries/note";
import EditNoteAppointmentDetailsForm from "../../../modules/notes/components/EditNoteAppointmentDetailsForm/EditNoteAppointmentDetailsForm";
import { useAppSelector } from "../../../hooks/useRedux";

export default function AppointmentDetails() {
    const [showEditDialog, setShowEditDialog] = React.useState(false);

    const { practice } = useAppSelector((state) => state.userPractice);

    // Get id from url params
    const params = useParams();
    const noteId = params.noteId as string;
    const clientId = params.clientId as string;

    const location = useLocation();

    const { data, isSuccess } = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    const modifiers = Object.values(ModifierTypes);

    const matchingModifiers = modifiers.filter((value) =>
        data?.data?.modifier_codes?.includes(value.split(" ")[0])
    );

    const isEditNoteMutating = useIsMutating(["edit-note"]);

    /* Determines the value of the content parameter of the
       hideField() function */
    const getHideFieldContext = location.pathname.includes("notes")
        ? "view-note"
        : "create-note";

    const getProviderWithRole = (roleType: "author" | "supervisor") => {
        const roleAvailable = data?.data.note_signatures?.find(
            (item) => item.role === roleType
        );

        return roleAvailable;
    };

    const handleShowEditDialog = () => {
        setShowEditDialog((state) => !state);
    };

    if (!data && !isSuccess) {
        return null;
    }

    return (
        <>
            <Dialog open={showEditDialog}>
                <DialogContent
                    title="Edit Appointment Details"
                    handleCloseDialog={handleShowEditDialog}
                    variant="center"
                    isSubmitBtnDisabled={isEditNoteMutating > 0}
                    isCancelBtnDisabled={isEditNoteMutating > 0}
                    submitBtnFormValue="edit-appointment-details"
                >
                    <EditNoteAppointmentDetailsForm
                        onFormSubmit={handleShowEditDialog}
                    />
                </DialogContent>
            </Dialog>
            <div className={styles.web}>
                <h2 className={cn(styles.title)}>
                    Appointment Details
                    <Button
                        onClick={handleShowEditDialog}
                        variant="normal"
                        size="auto"
                        aria-label="edit-btn"
                    >
                        <EditIcon />
                    </Button>
                </h2>
                <div
                    className={cn(styles.details, {
                        [styles.details_cancellation]:
                            data.data.type === NoteTypes.CANCELLATION_NOTE,
                        [styles.details_supervision]:
                            data.data.type === NoteTypes.SUPERVISION_NOTE,
                    })}
                >
                    <div className={styles.detail}>
                        <span className="section-info fs-exclude">
                            {practice?.is_supervisor &&
                            data.data.type === NoteTypes.SUPERVISION_NOTE
                                ? "Supervisee’s client"
                                : "Client name"}
                        </span>
                        <Link
                            to={`/clients/${data.data.client.client_id}`}
                            className={cn("section-value", styles.clientname)}
                        >
                            {`${data.data.client.first_name} ${data.data.client.last_name}`}
                        </Link>
                    </div>
                    <div className={styles.detail}>
                        <span className="section-info fs-exclude">
                            Date of service
                        </span>
                        <span className="section-value">
                            {data.data.date_of_service
                                ? formatDate(
                                      data.data.date_of_service as string
                                  )
                                : "--"}
                        </span>
                    </div>
                    <div className={styles.detail}>
                        <span className="section-info fs-exclude">Time</span>
                        <span className="section-value">
                            {`${formatDate(
                                data.data.session_start_time as string,
                                false,
                                "h:mmaaa"
                            )} - ${formatDate(
                                data.data.session_end_time as string,
                                false,
                                "h:mmaaa"
                            )}`}
                        </span>
                    </div>
                    <div className={styles.detail}>
                        <span className="section-info fs-exclude">
                            Duration
                        </span>
                        <span className="section-value">
                            {getTimeDuration(
                                formatDate(
                                    data.data.session_start_time || "",
                                    false,
                                    "HH:mm"
                                ),
                                formatDate(
                                    data.data.session_end_time || "",
                                    false,
                                    "HH:mm"
                                )
                            )}
                        </span>
                    </div>
                    <div className={styles.detail}>
                        <span className="section-info fs-exclude">
                            Appointment type
                        </span>
                        <span className="section-value">
                            {removeEnumUnderscore(
                                data.data.appointment_type || ""
                            )}
                        </span>
                    </div>
                    <div className={styles.detail}>
                        <span className="section-info fs-exclude">
                            Provider name
                        </span>
                        <span className="section-value">
                            {`${data.data.provider.first_name} ${data.data.provider.last_name}`}
                        </span>
                    </div>
                    {getProviderWithRole("author") ? (
                        <div className={styles.detail}>
                            <span className="section-info fs-exclude">
                                Provider signature
                            </span>
                            <span className="section-value">
                                <span
                                    style={{
                                        display: "inline-block",
                                        marginRight: "3px",
                                    }}
                                >
                                    {getProviderWithRole("author")?.signature}
                                </span>
                                (
                                {formatDate(
                                    getProviderWithRole("author")
                                        ?.signature_date_time as string
                                )}
                                ,
                                <span
                                    className={styles.time}
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "3px",
                                    }}
                                >
                                    {formatDate(
                                        getProviderWithRole("author")
                                            ?.signature_date_time as string,
                                        false,
                                        "h:mm a"
                                    )}
                                </span>
                                )
                            </span>
                        </div>
                    ) : null}
                    {getProviderWithRole("supervisor") ? (
                        <div className={styles.detail}>
                            <span className="section-info fs-exclude">
                                Supervisor name
                            </span>
                            <span className="section-value">
                                {getProviderWithRole("supervisor")?.first_name
                                    ? `${
                                          getProviderWithRole("supervisor")
                                              ?.first_name
                                      } ${
                                          getProviderWithRole("supervisor")
                                              ?.last_name
                                      }`
                                    : "--"}
                            </span>
                        </div>
                    ) : null}

                    {getProviderWithRole("supervisor")?.signature ? (
                        <div className={styles.detail}>
                            <span className="section-info fs-exclude">
                                Supervisor signature
                            </span>
                            <span className="section-value">
                                <span
                                    style={{
                                        display: "inline-block",
                                        marginRight: "3px",
                                    }}
                                >
                                    {
                                        getProviderWithRole("supervisor")
                                            ?.signature
                                    }
                                </span>
                                (
                                {formatDate(
                                    getProviderWithRole("supervisor")
                                        ?.signature_date_time as string
                                )}
                                ,
                                <span
                                    className={styles.time}
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "3px",
                                    }}
                                >
                                    {formatDate(
                                        getProviderWithRole("supervisor")
                                            ?.signature_date_time as string,
                                        false,
                                        "h:mm a"
                                    )}
                                </span>
                                )
                            </span>
                        </div>
                    ) : null}
                    {data.data.modifier_codes?.length !== 0 &&
                        matchingModifiers.map((code, i) => (
                            <div className={styles.detail} key={code}>
                                <span className="section-info fs-exclude">
                                    {`Modifier ${i + 1}`}
                                </span>
                                <span className="section-value">{code}</span>
                            </div>
                        ))}

                    {data.data.type && (
                        <div className={styles.detail}>
                            <span className="section-info fs-exclude">
                                Note type
                            </span>
                            <span className="section-value">
                                {NoteLabels[data.data.type as string] || "--"}
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.mobile}>
                <Accordion
                    cardClass={styles.cardclass}
                    header={
                        <>
                            <h2 className={cn(styles.title)}>
                                Appointment Details
                                <Button
                                    onClick={handleShowEditDialog}
                                    variant="normal"
                                    size="auto"
                                    aria-label="edit-btn"
                                >
                                    <EditIcon />
                                </Button>
                            </h2>
                            <div className={styles.details}>
                                <div className={styles.detail}>
                                    <span className="section-info fs-exclude">
                                        {practice?.is_supervisor &&
                                        data.data.type ===
                                            NoteTypes.SUPERVISION_NOTE
                                            ? "Supervisee’s client"
                                            : "Client name"}
                                    </span>
                                    <Link
                                        to={`/clients/${data.data.client.client_id}`}
                                        className={cn(
                                            "section-value",
                                            styles.clientname
                                        )}
                                    >
                                        {`${data.data.client.first_name} ${data.data.client.last_name}`}
                                    </Link>
                                </div>
                                <div className={styles.detail}>
                                    <span className="section-info fs-exclude">
                                        Date of service
                                    </span>
                                    <span className="section-value">
                                        {data.data.date_of_service
                                            ? formatDate(
                                                  data.data
                                                      .date_of_service as string
                                              )
                                            : null}
                                    </span>
                                </div>
                            </div>
                        </>
                    }
                    content={
                        <div className={styles.details}>
                            <div className={styles.detail}>
                                <span className="section-info fs-exclude">
                                    Time
                                </span>
                                <span className="section-value">
                                    {`${formatDate(
                                        data.data.session_start_time as string,
                                        false,
                                        "h:mmaaa"
                                    )} - ${formatDate(
                                        data.data.session_end_time as string,
                                        false,
                                        "h:mmaaa"
                                    )}`}
                                </span>
                            </div>
                            <div className={styles.detail}>
                                <span className="section-info fs-exclude">
                                    Duration
                                </span>
                                <span className="section-value">
                                    {getTimeDuration(
                                        formatDate(
                                            data.data.session_start_time || "",
                                            false,
                                            "HH:mm"
                                        ),
                                        formatDate(
                                            data.data.session_end_time || "",
                                            false,
                                            "HH:mm"
                                        )
                                    )}
                                </span>
                            </div>
                            <div className={styles.detail}>
                                <span className="section-info fs-exclude">
                                    Appointment type
                                </span>
                                <span className="section-value">
                                    {removeEnumUnderscore(
                                        data.data.appointment_type || ""
                                    )}
                                </span>
                            </div>
                            <div className={styles.detail}>
                                <span className="section-info fs-exclude">
                                    Provider name
                                </span>
                                <span className="section-value">
                                    {`${data.data.provider.first_name} ${data.data.provider.last_name}`}
                                </span>
                            </div>
                            {getProviderWithRole("author") ? (
                                <div
                                    className={styles.detail}
                                    hidden={hideNoteField(
                                        data.data.type,
                                        "provider_signature",
                                        getHideFieldContext
                                    )}
                                >
                                    <span className="section-info fs-exclude">
                                        Provider signature
                                    </span>
                                    <span className="section-value">
                                        <span
                                            style={{
                                                display: "inline-block",
                                                marginRight: "3px",
                                            }}
                                        >
                                            {
                                                getProviderWithRole("author")
                                                    ?.signature
                                            }
                                        </span>
                                        (
                                        {formatDate(
                                            getProviderWithRole("author")
                                                ?.signature_date_time as string
                                        )}
                                        ,
                                        <span
                                            className={styles.time}
                                            style={{
                                                display: "inline-block",
                                                marginLeft: "3px",
                                            }}
                                        >
                                            {formatDate(
                                                getProviderWithRole("author")
                                                    ?.signature_date_time as string,
                                                false,
                                                "h:mm a"
                                            )}
                                        </span>
                                        )
                                    </span>
                                </div>
                            ) : null}
                            {getProviderWithRole("supervisor") ? (
                                <div className={styles.detail}>
                                    <span className="section-info fs-exclude">
                                        Supervisor name
                                    </span>
                                    <span className="section-value">
                                        {getProviderWithRole("supervisor")
                                            ?.first_name
                                            ? `${
                                                  getProviderWithRole(
                                                      "supervisor"
                                                  )?.first_name
                                              } ${
                                                  getProviderWithRole(
                                                      "supervisor"
                                                  )?.last_name
                                              }`
                                            : "--"}
                                    </span>
                                </div>
                            ) : null}

                            {getProviderWithRole("supervisor")?.signature ? (
                                <div className={styles.detail}>
                                    <span className="section-info fs-exclude">
                                        Supervisor signature
                                    </span>
                                    <span className="section-value">
                                        <span
                                            style={{
                                                display: "inline-block",
                                                marginRight: "3px",
                                            }}
                                        >
                                            {
                                                getProviderWithRole(
                                                    "supervisor"
                                                )?.signature
                                            }
                                        </span>
                                        (
                                        {formatDate(
                                            getProviderWithRole("supervisor")
                                                ?.signature_date_time as string
                                        )}
                                        ,
                                        <span
                                            className={styles.time}
                                            style={{
                                                display: "inline-block",
                                                marginLeft: "3px",
                                            }}
                                        >
                                            {formatDate(
                                                getProviderWithRole(
                                                    "supervisor"
                                                )
                                                    ?.signature_date_time as string,
                                                false,
                                                "h:mm a"
                                            )}
                                        </span>
                                        )
                                    </span>
                                </div>
                            ) : null}
                            {data.data.modifier_codes?.length !== 0 &&
                                matchingModifiers.map((code, i) => (
                                    <div className={styles.detail} key={code}>
                                        <span className="section-info fs-exclude">
                                            {`Modifier ${i + 1}`}
                                        </span>
                                        <span className="section-value">
                                            {code}
                                        </span>
                                    </div>
                                ))}
                            {data.data.type && (
                                <div className={styles.detail}>
                                    <span className="section-info fs-exclude">
                                        Note type
                                    </span>
                                    <span className="section-value">
                                        {NoteLabels[data.data.type as string] ||
                                            "--"}
                                    </span>
                                </div>
                            )}
                        </div>
                    }
                />
            </div>
        </>
    );
}

/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import {
    Button,
    Checkbox,
    DatePicker,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SearchInput,
    Table,
} from "@jhool-io/fe-components";
import { ColumnDef, Row } from "@tanstack/react-table";
import { useNavigate, useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import {
    FlaggedNoteForAuditStatus,
    INote,
    NoteLabels,
    NoteListSortAttribute,
    NoteStatus,
    NoteTypes,
    noteTypesForExport,
} from "../../../../utils/types/notes";
import { useFetchNotesList } from "../../../../hooks/queries/note";
import usePractice from "../../../../hooks/usePractice";
import {
    APP_COLORS,
    CPT_CODES,
    LISTS_DEFAULT_LIMIT,
} from "../../../../utils/constants";
import { useDebounce } from "../../../../hooks/helpers";
import {
    cn,
    convertDateFilterStringToDate,
    formatDate,
    getTotalAmountOfTableRowsSelected,
    isSmallScreen,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    truncateString,
} from "../../../../utils/helpers";
import { UserPermisions, UserRole } from "../../../../utils/types/user";
import { useFetchInsuranceProviders } from "../../../../hooks/queries/client";
import useShowInsuranceOverSelfPay from "../../../../modules/practice-settings/hooks/useShowInsuranceOverSelfPay";
import { useFetchProviders } from "../../../../hooks/queries/provider";
import useToast from "../../../../hooks/useToast";
import {
    useChangeNoteArchiveStatus,
    useExportNotes,
} from "../../../../hooks/mutations/note";
import MoreButton from "../../../../shared-ui/Buttons/MoreButton/MoreButton";
import Navbar from "../../../Navbar/Navbar";
import ArchiveNote from "../../NoteContent/ArchiveNote/ArchiveNote";
import FlagNoteForm from "../../../../modules/notes/components/FlagNoteForm/FlagNoteForm";
import ListState from "../../../ListState/ListState";
import Skeleton from "../../../Skeleton/Skeleton";
import MobileListItem from "../../../../shared-ui/MobileListItem/MobileListItem";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import TableFiltersDisplay from "../../../../shared-ui/TableFiltersDisplay/TableFiltersDisplay";
import CheckPrimaryColorIcon from "../../../Icons/CheckPrimaryColor";
import ChevronDownIcon from "../../../Icons/ChevronDown";
import styles from "../NoteListWrapper/NoteListWrapper.module.scss";
import { getFlaggedNoteStatusTag } from "../../../../modules/notes/helpers/notes.helpers";
import { useExportPdfs } from "../../../../hooks/queries";

type Dialogs = "archive-note" | "flag_note" | "unflag_note" | "loader";

export default function FlaggedNotesList() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [dialogInView, setDialogInView] = React.useState<Dialogs | null>(
        null
    );
    const [rowSelection, setRowSelection] = React.useState({});
    const [codeToConfirmId, setCodeToConfirmId] = React.useState<string | null>(
        null
    );

    const [notesCount, setNotesCount] = React.useState("");
    const [selectedNotesId, setSelectedNotesId] = React.useState<string[]>([]);
    const [showProviderListPopover, setShowProviderListPopover] =
        React.useState(false);
    const [showAuthorPopover, setShowAuthorPopover] = React.useState(false);
    const [providerNameSearchString, setProviderNameSearchString] =
        React.useState("");
    const [authorSearchString, setAuthorSearchString] = React.useState("");
    const [getSelectedData, setGetSelectedData] =
        React.useState<Row<INote>[]>();
    const [jobId, setJobId] = React.useState<string | null>(null);
    const [pdfCount, setPdfCount] = React.useState(0);

    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: searchParams.get("sort_attr") || "",
        direction: searchParams.get("sort_order") || "",
    });

    const { practice } = usePractice();

    // Get filters from url
    const clientFilter = searchParams.get("search_string") || "";
    const providerNameFilter = searchParams.get("provider_name") || "";
    const authorFilter = searchParams.get("author") || "";
    const cptCodeFilter = searchParams.get("cpt_code") || "";
    const insuranceProviderFilter =
        searchParams.get("insurance_provider") || "";
    const noteTypeFilter = searchParams.getAll("type[]");
    const flaggedStatusFilter = searchParams.getAll("flagged_status[]");
    const fromDateFilter = searchParams.get("from_date");
    const toDateFilter = searchParams.get("to_date");
    const showArchivedFilter = searchParams.get("show_archived") || "false";
    const fromDateCreatedFilter = searchParams.get("from_date_created");
    const toDateCreatedFilter = searchParams.get("to_date_created");
    const pageFilter = Number(searchParams.get("page")) || 1;
    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;

    const debouncedClienFilter = useDebounce(clientFilter);
    const debouncedProviderNameSearchString = useDebounce(
        providerNameSearchString
    );
    const debouncedAuthorSearchString = useDebounce(authorSearchString);

    // date filters values for DatePicker component
    // Converts the string values to a date in the correct timezone
    const fromDateFilterToDate = convertDateFilterStringToDate(fromDateFilter);
    const toDateFilterToDate = convertDateFilterStringToDate(toDateFilter);

    const fromDateCreatedFilterToDate = convertDateFilterStringToDate(
        fromDateCreatedFilter
    );
    const toDateCreatedFilterToDate =
        convertDateFilterStringToDate(toDateCreatedFilter);

    const handleResetProvidersSearchString = () => {
        setProviderNameSearchString("");
        setAuthorSearchString("");
    };

    const insuranceProviders = useFetchInsuranceProviders();

    const showInsOverSelfPay = useShowInsuranceOverSelfPay();

    const providers = useFetchProviders({
        search_string:
            debouncedProviderNameSearchString || debouncedAuthorSearchString,
        page: 1,
        limit: 20,
    });

    const getInsuranceTextToDisplay = React.useMemo(
        () =>
            showInsOverSelfPay
                ? [...(insuranceProviders?.data?.data || [])].find(
                      (provider) =>
                          provider.insurance_provider_id ===
                          insuranceProviderFilter
                  )?.name
                : [
                      {
                          insurance_provider_id: "self_pay",
                          name: "Self pay",
                      },
                      ...(insuranceProviders?.data?.data || []),
                  ].find(
                      (provider) =>
                          provider.insurance_provider_id ===
                          insuranceProviderFilter
                  )?.name,
        [
            showInsOverSelfPay,
            insuranceProviders?.data?.data,
            insuranceProviderFilter,
        ]
    );

    const getAuthorTextToDisplay = providers.data?.data.find(
        (author) => author.user_id === authorFilter
    )?.provider_name;

    const handleDialogInView = (modal: Dialogs | null) => {
        setDialogInView(() => modal);
    };

    const sortableColumns = [
        "date_of_service",
        "client_name",
        "provider_name",
        "created_at",
    ];

    const handleConfigAttribute = () => {
        if (sortableColumns.includes(sortConfig.key)) {
            return sortConfig.key as NoteListSortAttribute;
        }

        return undefined;
    };

    const statusOptions = practice?.roles.includes(UserRole.SUPER_ADMIN)
        ? Object.values(FlaggedNoteForAuditStatus)
        : [
              FlaggedNoteForAuditStatus.FLAGGED,
              FlaggedNoteForAuditStatus.REQUIRES_ACTION,
              FlaggedNoteForAuditStatus.SENT_FOR_CHANGES,
          ];

    // Hook for fetching all notes
    const { data, isLoading, isSuccess, error } = useFetchNotesList(
        {
            cpt_code: cptCodeFilter,
            type: noteTypeFilter,
            from_date: fromDateFilter,
            to_date: toDateFilter,
            from_created_at_date: fromDateCreatedFilter,
            to_created_at_date: toDateCreatedFilter,
            search_string: debouncedClienFilter,
            provider_name: providerNameFilter,
            page: pageFilter,
            insurance_provider_id: insuranceProviderFilter,
            limit: limitFilter,
            show_archived: showArchivedFilter,
            sort_attribute:
                searchParams.get("sort_attr") || handleConfigAttribute(),
            sort_order: searchParams.get("sort_order") || sortConfig.direction,
            author_id: authorFilter,
            flagged_for_audit_status:
                flaggedStatusFilter.length > 0
                    ? flaggedStatusFilter
                    : statusOptions,
        },
        ""
    );

    const doesNoteHaveBill = (note: INote) => {
        const notesTypesWithoutBill = [
            NoteTypes.SAFETY_PLAN,
            NoteTypes.SUPERVISION_NOTE,
            NoteTypes.GENERAL_UPDATE_NOTE,
            NoteTypes.SUPERVISOR_CHECK_IN,
            NoteTypes.PAUSE_NOTIFICATION_NOTE,
        ];
        return (
            !notesTypesWithoutBill.includes(note.note_type) &&
            Boolean(note.date_of_service)
        );
    };

    const userShouldNaviagteToBills = [
        UserRole.BILLER,
        UserRole.SUPER_ADMIN,
        UserRole.BILLER_ADMIN,
    ].some((role) => practice?.roles?.includes(role));

    const navigate = useNavigate();

    // Clicked note navigation for web
    const handleRowClick = (row: Row<INote>) => {
        if (
            row.original.status === NoteStatus.CODED &&
            doesNoteHaveBill(row.original) &&
            userShouldNaviagteToBills
        ) {
            navigate(
                `/billing-and-claims/${row.original.client.client_id}/${row.original.note_id}?viewFrom=session_note`
            );
        } else {
            navigate(
                `/notes/${row.original.client.client_id}/${row.original.note_id}?viewFrom=session_note`
            );
        }
    };

    // Clicked note navigation for mobile
    const handleMobileNoteClick = (note: INote) => {
        if (
            note.status === NoteStatus.CODED &&
            doesNoteHaveBill(note) &&
            userShouldNaviagteToBills
        ) {
            navigate(
                `/billing-and-claims/${note.client.client_id}/${note.note_id}?viewFrom=session_note`
            );
        } else {
            navigate(
                `/notes/${note.client.client_id}/${note.note_id}?viewFrom=session_note`
            );
        }
    };

    /** FILTER INPUTS ONCHANGE FUNCTIONS */
    const handleSetClientNameFilter = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.value === "") searchParams.delete("search_string");
        else searchParams.set("search_string", e.target.value);
        searchParams.set("page", "1");
        localStorage.setItem("flaggednotes_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleSetProviderNameFilter = (provider: string) => {
        if (provider === "") searchParams.delete("provider_name");
        else searchParams.set("provider_name", provider);
        searchParams.set("page", "1");
        localStorage.setItem("flaggednotes_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleSetAuthorFilter = (author: string) => {
        if (author === "") searchParams.delete("author");
        else searchParams.set("author", author);
        searchParams.set("page", "1");
        localStorage.setItem("flaggednotes_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleSetTypeFilter = (type: string) => {
        if (noteTypeFilter.includes(type)) {
            searchParams.delete("type[]", type);
        } else {
            searchParams.append("type[]", type);
        }
        searchParams.set("page", "1");
        localStorage.setItem("flaggednotes_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleFlaggedStatusFilterChange = (status: string) => {
        if (flaggedStatusFilter.includes(status)) {
            searchParams.delete("flagged_status[]", status);
        } else {
            searchParams.append("flagged_status[]", status);
        }
        searchParams.set("page", "1");
        localStorage.setItem("flaggednotes_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleSetInsuranceProviderFilter = (id: string) => {
        if (id === "") searchParams.delete("insurance_provider");
        else searchParams.set("insurance_provider", id);
        localStorage.setItem("flaggednotes_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleSetCptCodeFilter = (cptCode: string) => {
        if (cptCode === "") searchParams.delete("cpt_code");
        else searchParams.set("cpt_code", cptCode);
        localStorage.setItem("flaggednotes_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleSetArchivedNotesFilter = (archived: string) => {
        if (archived === "") searchParams.delete("show_archived");
        else searchParams.set("show_archived", archived);
        localStorage.setItem("flaggednotes_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem("flaggednotes_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem("flaggednotes_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleFromDateCreatedFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date_created");
        else searchParams.set("from_date_created", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem("notelist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleToDateCreatedFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date_created");
        else searchParams.set("to_date_created", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem("notelist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleSetPageFilter = (page: number) => {
        searchParams.set("page", String(page));
        localStorage.setItem("flaggednotes_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleSetLimitFilter = (limit: number) => {
        searchParams.set("limit", String(limit));
        localStorage.setItem("flaggednotes_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const getArchivedNoteFilterToDisplay = () => {
        if (showArchivedFilter === "true") {
            return "Archived notes";
        }
        return "Unarchived notes";
    };

    const insuranceProviderOptionsForDropdown = showInsOverSelfPay
        ? [
              ...(insuranceProviders?.data?.data || []).map((insurance) => ({
                  insurance_provider_id: insurance.insurance_provider_id,
                  name: insurance.name,
              })),
          ]
        : [
              {
                  insurance_provider_id: "self_pay",
                  name: "Self pay",
              },
              ...(insuranceProviders?.data?.data || []).map((insurance) => ({
                  insurance_provider_id: insurance.insurance_provider_id,
                  name: insurance.name,
              })),
          ];

    const selectedIds = getSelectedData?.map(
        (item: Row<INote>) => item.original.note_id
    );

    const mobileListRef = React.useRef<HTMLDivElement>(null);

    // Array of details of the selected notes(web)
    const notesDetails =
        selectedIds &&
        selectedIds.map((noteId: string) =>
            data?.data.find((note) => note.note_id === noteId)
        );

    // Array of details of the selected notes(mobile)
    const selectedNotesDetails =
        selectedNotesId &&
        selectedNotesId.map((noteId: string) =>
            data?.data.find((note) => note.note_id === noteId)
        );

    const detailsArray =
        selectedNotesId.length > 0 ? selectedNotesDetails : notesDetails;

    // Details of a single note
    const noteDetails = data?.data.find((note) => {
        return note.note_id === codeToConfirmId;
    });

    const isSuperAdmin = practice?.roles.includes(UserRole.SUPER_ADMIN);

    const { toast } = useToast();

    const queryClient = useQueryClient();

    const unarchiveNote = useChangeNoteArchiveStatus();

    const archiveNoteIsMutating = useIsMutating(["archive-note"]);
    const flagNoteIsMutating = useIsMutating(["flag-note"]);

    const handleUnarchive = (id?: string) => {
        const noteIds =
            selectedIds && selectedIds.length > 0
                ? selectedIds
                : selectedNotesId;

        const dataToSend = {
            note_ids_to_archive: noteIds.length === 0 && id ? [id] : noteIds,
            archive_status: false,
        };

        unarchiveNote.mutate(dataToSend, {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: [`notes`],
                });

                await queryClient.invalidateQueries({
                    queryKey: ["supervisee"],
                });

                toast({
                    mode: "success",
                    message: res.message || "Note unarchived successfully",
                });

                setRowSelection({});
                setSelectedNotesId([]);
            },

            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not unarchive note at this time",
                });
            },
        });
    };

    const exportNotes = useExportNotes();

    const handleExportNotes = (noteid?: string) => {
        const noteIds =
            selectedIds && selectedIds.length > 0
                ? selectedIds
                : selectedNotesId;

        const dataToSend = {
            session_note_ids:
                noteIds.length === 0 && noteid ? [noteid] : noteIds,
        };

        exportNotes.mutate(dataToSend, {
            onSuccess: async (res) => {
                handleDialogInView("loader");
                await new Promise<void>((resolve) => {
                    const timer = setTimeout(() => {
                        clearTimeout(timer);
                        setJobId(res.data.export_job_id);
                        setPdfCount(res.data.expected_pdf_count);
                        resolve();
                    }, 8000);
                });
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.message || "Could not export notes at this time",
                });
            },
        });
    };

    const exportNote = useExportPdfs(
        {
            export_job_id: jobId as string,
            pdf_type: "session_note",
            expected_pdf_count: pdfCount,
        },
        Boolean(jobId)
    );

    if (jobId && !exportNote.isLoading) {
        setJobId(null);
        handleDialogInView(null);
        setRowSelection({});
    }

    const canArchiveNote = () => {
        if (
            practice?.permissions?.includes(
                UserPermisions.CLIENT_NOTES_ALL_READ
            ) ||
            practice?.permissions?.includes(
                UserPermisions.CLIENT_INFO_NOTES_WRITE
            )
        ) {
            return true;
        }

        return false;
    };

    const showBulkFlagNotesBtn =
        practice?.permissions?.includes(
            UserPermisions.CLIENT_INFO_NOTES_FLAG_FOR_AUDIT_WRITE
        ) &&
        detailsArray?.every(
            (note) =>
                note?.flagged_for_audit_status ===
                FlaggedNoteForAuditStatus.UNFLAGGED
        ) &&
        detailsArray?.every(
            (note) =>
                note?.status === NoteStatus.PENDING_CODE_REVIEW ||
                note?.status === NoteStatus.CODED
        );

    const showFlagOption = (row: Row<INote>) =>
        row.original.flagged_for_audit_status !==
            FlaggedNoteForAuditStatus.FLAGGED &&
        (row.original.status === NoteStatus.CODED ||
            row.original.status === NoteStatus.PENDING_CODE_REVIEW) &&
        practice?.permissions?.includes(
            UserPermisions.CLIENT_INFO_NOTES_FLAG_FOR_AUDIT_WRITE
        );

    // Toggles note selection for mobile
    const toggleNoteSelection = (noteId: string) => {
        if (selectedNotesId.includes(noteId)) {
            setSelectedNotesId(selectedNotesId.filter((id) => id !== noteId));
        } else {
            setSelectedNotesId([...selectedNotesId, noteId]);
        }
    };

    const columns: ColumnDef<INote>[] = React.useMemo(
        () => [
            {
                id: "select",
                header: ({ table }) => (
                    <Checkbox
                        handleChange={table.getToggleAllRowsSelectedHandler()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        label=""
                        name="row"
                        value="row"
                        isChecked={table.getIsAllRowsSelected()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        handleChange={row.getToggleSelectedHandler()}
                        indeterminate={row.getIsSomeSelected()}
                        label=""
                        name="row"
                        value="row"
                        isChecked={row.getIsSelected()}
                    />
                ),
            },
            {
                accessorKey: "date_of_service",
                header: "DATE OF SERVICE",
                cell: ({ row }) =>
                    row.original.date_of_service
                        ? formatDate(row.original.date_of_service)
                        : "--",
            },
            {
                accessorKey: "note_type",
                header: "NOTE TYPE",
                cell: ({ row }) => NoteLabels[row.original.note_type],
            },
            {
                accessorKey: "client_name",
                header: "CLIENT",
                cell: ({ row }) => (
                    <span className="capitalize">{`${row.original.client?.first_name}
            ${row.original.client?.last_name}`}</span>
                ),
            },
            {
                accessorKey: "cpt_code",
                header: "CPT CODE",
                cell: ({ row }) => row.original.cpt_code || "--",
            },
            {
                accessorKey: "provider_name",
                header: "PROVIDER",
                cell: ({ row }) => (
                    <span className="capitalize">
                        {`${row.original.provider?.first_name} ${row.original.provider?.last_name}`}
                    </span>
                ),
            },
            {
                accessorKey: "author",
                header: "CREATED BY",
                cell: ({ row }) => (
                    <span className="capitalize">
                        {`${row.original.author.first_name} ${row.original.author.last_name}`}
                    </span>
                ),
            },
            {
                accessorKey: "created_at",
                header: "CREATED ON",
                cell: ({ row }) => formatDate(row.original.created_at),
            },
            {
                accessorKey: "flagged_note_status",
                header: "STATUS",
                cell: ({ row }) =>
                    row.original.flagged_for_audit_status
                        ? getFlaggedNoteStatusTag(
                              row.original.flagged_for_audit_status
                          )
                        : "--",
            },
            {
                accessorKey: "client",
                header: "INSURANCE PROVIDER",
                cell: ({ row }) => (
                    <span
                        className={
                            row.original.client.self_pay && !showInsOverSelfPay
                                ? "normal-case"
                                : "uppercase"
                        }
                    >
                        {row.original.client.self_pay && !showInsOverSelfPay
                            ? "Self pay"
                            : (row.original.client.insurance_provider_name &&
                                  removeEnumUnderscore(
                                      row.original.client
                                          .insurance_provider_name || ""
                                  )) ||
                              "--"}
                    </span>
                ),
            },
            {
                accessorKey: "note_id",
                header: "",
                cell: ({ row }) => (
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <MoreButton
                                onPointerDown={() => {
                                    setCodeToConfirmId(
                                        () => row.original.note_id
                                    );
                                }}
                            />
                        </DropdownTrigger>
                        <DropdownContent align="end" width="auto">
                            {canArchiveNote() &&
                                row.original.is_archived === false &&
                                row.original.status !== NoteStatus.DRAFT && (
                                    <DropdownItem
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDialogInView("archive-note");
                                        }}
                                    >
                                        Archive note
                                    </DropdownItem>
                                )}
                            {canArchiveNote() && row.original.is_archived && (
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleUnarchive(row.original.note_id);
                                    }}
                                >
                                    Unarchive note
                                </DropdownItem>
                            )}
                            {showFlagOption(row) && (
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDialogInView("flag_note");
                                    }}
                                >
                                    Flag for audit
                                </DropdownItem>
                            )}
                            {row.original.flagged_for_audit_status ===
                                FlaggedNoteForAuditStatus.FLAGGED &&
                                practice?.permissions?.includes(
                                    UserPermisions.CLIENT_INFO_NOTES_FLAG_FOR_AUDIT_WRITE
                                ) && (
                                    <DropdownItem
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDialogInView("unflag_note");
                                        }}
                                    >
                                        Unflag note
                                    </DropdownItem>
                                )}
                            {noteTypesForExport.includes(
                                row.original.note_type
                            ) &&
                                isSuperAdmin &&
                                row.original.status !== NoteStatus.DRAFT && (
                                    <DropdownItem
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleExportNotes(
                                                row.original.note_id
                                            );
                                        }}
                                    >
                                        Export to pdf
                                    </DropdownItem>
                                )}
                        </DropdownContent>
                    </Dropdown>
                ),
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    React.useEffect(() => {
        if (setNotesCount) {
            if (error) setNotesCount("");
            else if (isLoading) setNotesCount("");
            else if (data && data?.total_count > 0) {
                setNotesCount(String(data.total_count));
            } else if (data && data.data.length === 0) {
                setNotesCount("");
            }
        }
    }, [data, error, isLoading, setNotesCount]);

    React.useEffect(() => {
        searchParams.set("sort_attr", sortConfig.key);
        searchParams.set("sort_order", sortConfig.direction);
        localStorage.setItem("flaggednotes_filters", searchParams.toString());
        setSearchParams(searchParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortConfig]);

    return (
        <>
            <Dialog open={dialogInView === "archive-note"}>
                <DialogContent
                    title="Archive Note"
                    variant="center"
                    showFooter
                    saveText="Archive Note"
                    submitBtnFormValue="archive-note"
                    handleCloseDialog={() => handleDialogInView(null)}
                    isSubmitBtnDisabled={archiveNoteIsMutating > 0}
                    isCancelBtnDisabled={archiveNoteIsMutating > 0}
                >
                    <ArchiveNote
                        onFormSubmit={() => {
                            handleDialogInView(null);
                            setRowSelection({});
                            setSelectedNotesId([]);
                        }}
                        noteId={noteDetails?.note_id as string}
                        selectedIds={selectedIds}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={
                    dialogInView === "flag_note" ||
                    dialogInView === "unflag_note"
                }
            >
                <DialogContent
                    title={
                        dialogInView === "flag_note"
                            ? "Flag for Audit"
                            : "Unflag Note for Audit"
                    }
                    variant="center"
                    showFooter
                    saveText={
                        dialogInView === "flag_note"
                            ? "Flag for Audit"
                            : "Unflag Note for Audit"
                    }
                    submitBtnFormValue="flag-note"
                    handleCloseDialog={() => {
                        handleDialogInView(null);
                        setRowSelection({});
                    }}
                    isSubmitBtnDisabled={flagNoteIsMutating > 0}
                    isCancelBtnDisabled={flagNoteIsMutating > 0}
                >
                    <FlagNoteForm
                        onFormSubmit={() => {
                            handleDialogInView(null);
                            setRowSelection({});
                            setSelectedNotesId([]);
                        }}
                        noteId={noteDetails?.note_id}
                        selectedIds={selectedIds}
                        action={
                            dialogInView === "flag_note" ? "flag" : "unflag"
                        }
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "loader"}>
                <DialogContent
                    title="Export in progress"
                    variant="center"
                    handleCloseDialog={() => handleDialogInView(null)}
                    showFooter={false}
                >
                    <div className="loader">
                        <div className="loader-icon" />
                        <div className="loader-text">
                            Please wait while the export is in progress.
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <Navbar title={`Flagged Notes (${notesCount})`} />
            <div className="page">
                <div className="rounded-r8 bg-white py-16 px-12 mb-32">
                    <div className="flex flex-col gap-y-24">
                        <div className="flex items-center justify-between flex-wrap gap-12">
                            <SearchInput
                                defaultValue={clientFilter}
                                onChange={handleSetClientNameFilter}
                                containerClass="w-[400px] max-w-full"
                                placeholder="Search by client name or chart number"
                            />
                        </div>
                        <div className="flex items-center justify-between gap-x-12 flex-wrap gap-y-24">
                            <div className="flex items-center gap-x-12 flex-wrap gap-y-12">
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <FilterButton text="Note type" />
                                    </DropdownTrigger>
                                    <DropdownContent
                                        align="end"
                                        width="auto"
                                        maxHeight={216}
                                        className="overflow-y-auto"
                                    >
                                        {Object.values(NoteTypes).map(
                                            (type) => (
                                                <DropdownItem
                                                    key={type}
                                                    onClick={() =>
                                                        handleSetTypeFilter(
                                                            type
                                                        )
                                                    }
                                                    isMulti
                                                    itemValue={type}
                                                    values={noteTypeFilter}
                                                >
                                                    {makeStringFirstLetterCapital(
                                                        removeEnumUnderscore(
                                                            NoteLabels[type]
                                                        )
                                                    )}
                                                </DropdownItem>
                                            )
                                        )}
                                    </DropdownContent>
                                </Dropdown>

                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <FilterButton text="Status" />
                                    </DropdownTrigger>
                                    <DropdownContent
                                        align="end"
                                        width="auto"
                                        maxHeight={216}
                                        className="overflow-y-auto"
                                    >
                                        {statusOptions.map((status) => (
                                            <DropdownItem
                                                key={status}
                                                onClick={() =>
                                                    handleFlaggedStatusFilterChange(
                                                        status
                                                    )
                                                }
                                                isMulti
                                                itemValue={status}
                                                values={flaggedStatusFilter}
                                            >
                                                {makeStringFirstLetterCapital(
                                                    removeEnumUnderscore(status)
                                                )}
                                            </DropdownItem>
                                        ))}
                                    </DropdownContent>
                                </Dropdown>

                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <Button
                                            size="auto"
                                            variant="normal"
                                            className={cn(
                                                "px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize",
                                                {
                                                    uppercase:
                                                        insuranceProviderFilter,
                                                }
                                            )}
                                        >
                                            {insuranceProviders.isLoading &&
                                                "Loading.."}
                                            {insuranceProviders.error &&
                                                "Error loading providers"}
                                            {insuranceProviders?.data?.data && (
                                                <>
                                                    {getInsuranceTextToDisplay
                                                        ? truncateString(
                                                              removeEnumUnderscore(
                                                                  getInsuranceTextToDisplay
                                                              )
                                                          )
                                                        : "Insurance provider"}
                                                    <ChevronDownIcon
                                                        stroke={
                                                            APP_COLORS.COLOR_BLACK
                                                        }
                                                    />
                                                </>
                                            )}
                                        </Button>
                                    </DropdownTrigger>
                                    <DropdownContent
                                        align={
                                            isSmallScreen() ? "start" : "end"
                                        }
                                        width={
                                            isSmallScreen() ? "250px" : "auto"
                                        }
                                        maxHeight={216}
                                        className="overflow-y-auto"
                                    >
                                        <DropdownItem
                                            className={cn(
                                                "uppercase text-xs font-medium flex items-center gap-x-8",
                                                {
                                                    "text-primary":
                                                        !insuranceProviderFilter,
                                                }
                                            )}
                                            onClick={() =>
                                                handleSetInsuranceProviderFilter(
                                                    ""
                                                )
                                            }
                                        >
                                            All
                                            {!insuranceProviderFilter ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                        {insuranceProviderOptionsForDropdown
                                            .sort((a, b) =>
                                                a.name.localeCompare(b.name)
                                            )
                                            .map((provider) => (
                                                <DropdownItem
                                                    className={cn(
                                                        "uppercase text-xs font-medium flex items-center gap-x-8",
                                                        {
                                                            "text-primary":
                                                                provider.insurance_provider_id ===
                                                                insuranceProviderFilter,
                                                        }
                                                    )}
                                                    onClick={() =>
                                                        handleSetInsuranceProviderFilter(
                                                            provider.insurance_provider_id
                                                        )
                                                    }
                                                    key={
                                                        provider.insurance_provider_id
                                                    }
                                                >
                                                    {removeEnumUnderscore(
                                                        provider.name
                                                    ).toUpperCase()}
                                                    {insuranceProviderFilter ===
                                                    provider.insurance_provider_id ? (
                                                        <CheckPrimaryColorIcon />
                                                    ) : null}
                                                </DropdownItem>
                                            ))}
                                    </DropdownContent>
                                </Dropdown>

                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <FilterButton
                                            classNames="normal-case"
                                            text={cptCodeFilter || "CPT code"}
                                        />
                                    </DropdownTrigger>
                                    <DropdownContent
                                        maxHeight={216}
                                        className="overflow-y-auto"
                                    >
                                        <DropdownItem
                                            className={cn(
                                                "flex gap-x-8 items-center text-xs font-medium",
                                                {
                                                    "text-primary":
                                                        cptCodeFilter === "",
                                                }
                                            )}
                                            onClick={() =>
                                                handleSetCptCodeFilter("")
                                            }
                                        >
                                            All
                                            {!cptCodeFilter ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                        {CPT_CODES.map((code) => (
                                            <DropdownItem
                                                key={code}
                                                className={cn(
                                                    "flex gap-x-8 items-center text-xs font-medium",
                                                    {
                                                        "text-primary":
                                                            cptCodeFilter ===
                                                            code,
                                                    }
                                                )}
                                                onClick={() =>
                                                    handleSetCptCodeFilter(code)
                                                }
                                            >
                                                {code}
                                                {cptCodeFilter === code ? (
                                                    <CheckPrimaryColorIcon />
                                                ) : null}
                                            </DropdownItem>
                                        ))}
                                    </DropdownContent>
                                </Dropdown>

                                <Popover
                                    open={showProviderListPopover}
                                    onOpenChange={(open) => {
                                        setShowProviderListPopover(open);
                                        handleResetProvidersSearchString();
                                    }}
                                >
                                    <PopoverTrigger
                                        asChild
                                        onClick={() =>
                                            setShowProviderListPopover(true)
                                        }
                                    >
                                        <FilterButton
                                            classNames={
                                                providerNameFilter
                                                    ? "capitalize"
                                                    : "normal-case"
                                            }
                                            text={
                                                providerNameFilter
                                                    ? truncateString(
                                                          providerNameFilter
                                                      )
                                                    : "Provider"
                                            }
                                        />
                                    </PopoverTrigger>
                                    <PopoverContent
                                        className="px-4 pt-[3px] py-[3px] w-[316px] rounded-r8 border-none shadow-[0px_0px_0px_1px_#98a1b219,_0px_15px_35px_-5px_#11182626,_0px_5px_15px_0px_#00000014]"
                                        align="start"
                                    >
                                        <SearchInput
                                            placeholder="Search by provider name"
                                            containerClass="h-24"
                                            onChange={(e) =>
                                                setProviderNameSearchString(
                                                    e.target.value
                                                )
                                            }
                                        />

                                        <div className="flex flex-col items-start max-h-[230px] overflow-y-auto">
                                            {providers.isLoading && (
                                                <div className="flex flex-col gap-y-4 items-stretch w-full">
                                                    {[1, 2, 3, 4, 5].map(
                                                        (item) => (
                                                            <Skeleton
                                                                key={item}
                                                                width="100%"
                                                                height="24px"
                                                                containerTestId="providers-loader"
                                                            />
                                                        )
                                                    )}
                                                </div>
                                            )}
                                            {providers.error && (
                                                <ListState
                                                    isError
                                                    context="general"
                                                    stateHelperText="Please try again later"
                                                    errorMsg={
                                                        providers.error
                                                            ?.response?.data
                                                            ?.message ||
                                                        "Error loading providers"
                                                    }
                                                />
                                            )}
                                            {providers.data?.data &&
                                                providers.isSuccess && (
                                                    <>
                                                        <Button
                                                            size="auto"
                                                            variant="normal"
                                                            className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start !rounded-none"
                                                            onClick={() => {
                                                                handleSetProviderNameFilter(
                                                                    ""
                                                                );
                                                                setShowProviderListPopover(
                                                                    false
                                                                );
                                                                handleResetProvidersSearchString();
                                                            }}
                                                        >
                                                            All
                                                        </Button>
                                                        {providers?.data?.data.map(
                                                            (provider) => (
                                                                <Button
                                                                    key={
                                                                        provider.provider_id
                                                                    }
                                                                    size="auto"
                                                                    variant="normal"
                                                                    className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start gap-x-8 !rounded-none"
                                                                    onClick={() => {
                                                                        handleSetProviderNameFilter(
                                                                            `${provider.provider_name.toLowerCase()}`
                                                                        );
                                                                        setShowProviderListPopover(
                                                                            false
                                                                        );
                                                                        handleResetProvidersSearchString();
                                                                    }}
                                                                >
                                                                    {
                                                                        provider.provider_name
                                                                    }
                                                                    {providerNameFilter.toLowerCase() ===
                                                                    provider.provider_name.toLowerCase() ? (
                                                                        <CheckPrimaryColorIcon />
                                                                    ) : null}
                                                                </Button>
                                                            )
                                                        )}
                                                    </>
                                                )}
                                        </div>
                                    </PopoverContent>
                                </Popover>

                                <Popover
                                    open={showAuthorPopover}
                                    onOpenChange={(open) => {
                                        setShowAuthorPopover(open);
                                        handleResetProvidersSearchString();
                                    }}
                                >
                                    <PopoverTrigger
                                        asChild
                                        onClick={() =>
                                            setShowAuthorPopover(true)
                                        }
                                    >
                                        <FilterButton
                                            classNames={
                                                authorFilter
                                                    ? "capitalize"
                                                    : "normal-case"
                                            }
                                            text={
                                                getAuthorTextToDisplay
                                                    ? truncateString(
                                                          getAuthorTextToDisplay
                                                      )
                                                    : "Author"
                                            }
                                        />
                                    </PopoverTrigger>
                                    <PopoverContent
                                        className="px-4 pt-[3px] py-[3px] w-[316px] rounded-r8 border-none shadow-[0px_0px_0px_1px_#98a1b219,_0px_15px_35px_-5px_#11182626,_0px_5px_15px_0px_#00000014]"
                                        align="start"
                                    >
                                        <SearchInput
                                            placeholder="Search by name"
                                            containerClass="h-24"
                                            onChange={(e) =>
                                                setAuthorSearchString(
                                                    e.target.value
                                                )
                                            }
                                        />

                                        <div className="flex flex-col items-start max-h-[230px] overflow-y-auto">
                                            {providers.isLoading && (
                                                <div className="flex flex-col gap-y-4 items-stretch w-full">
                                                    {[1, 2, 3, 4, 5].map(
                                                        (item) => (
                                                            <Skeleton
                                                                key={item}
                                                                width="100%"
                                                                height="24px"
                                                                containerTestId="providers-loader"
                                                            />
                                                        )
                                                    )}
                                                </div>
                                            )}
                                            {providers.error && (
                                                <ListState
                                                    isError
                                                    context="general"
                                                    stateHelperText="Please try again later"
                                                    errorMsg={
                                                        providers.error
                                                            ?.response?.data
                                                            ?.message ||
                                                        "Error loading authors"
                                                    }
                                                />
                                            )}
                                            {providers.data?.data &&
                                                providers.isSuccess && (
                                                    <>
                                                        <Button
                                                            size="auto"
                                                            variant="normal"
                                                            className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start !rounded-none"
                                                            onClick={() => {
                                                                handleSetAuthorFilter(
                                                                    ""
                                                                );
                                                                setShowAuthorPopover(
                                                                    false
                                                                );
                                                                handleResetProvidersSearchString();
                                                            }}
                                                        >
                                                            All
                                                        </Button>
                                                        {providers?.data?.data.map(
                                                            (provider) => (
                                                                <Button
                                                                    key={
                                                                        provider.provider_id
                                                                    }
                                                                    size="auto"
                                                                    variant="normal"
                                                                    className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start gap-x-8 !rounded-none"
                                                                    onClick={() => {
                                                                        handleSetAuthorFilter(
                                                                            provider.user_id
                                                                        );
                                                                        setShowAuthorPopover(
                                                                            false
                                                                        );
                                                                        handleResetProvidersSearchString();
                                                                    }}
                                                                >
                                                                    {
                                                                        provider.provider_name
                                                                    }
                                                                    {authorFilter ===
                                                                    provider.user_id ? (
                                                                        <CheckPrimaryColorIcon />
                                                                    ) : null}
                                                                </Button>
                                                            )
                                                        )}
                                                    </>
                                                )}
                                        </div>
                                    </PopoverContent>
                                </Popover>

                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <FilterButton
                                            classNames="normal-case"
                                            text={getArchivedNoteFilterToDisplay()}
                                        />
                                    </DropdownTrigger>
                                    <DropdownContent>
                                        <DropdownItem
                                            className={cn(
                                                "flex gap-x-8 items-center text-xs font-medium",
                                                {
                                                    "text-primary":
                                                        showArchivedFilter ===
                                                        "true",
                                                }
                                            )}
                                            onClick={() =>
                                                handleSetArchivedNotesFilter(
                                                    "true"
                                                )
                                            }
                                        >
                                            Archived notes
                                            {showArchivedFilter === "true" ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                        <DropdownItem
                                            className={cn(
                                                "flex gap-x-8 items-center text-xs font-medium",
                                                {
                                                    "text-primary":
                                                        showArchivedFilter ===
                                                        "false",
                                                }
                                            )}
                                            onClick={() =>
                                                handleSetArchivedNotesFilter(
                                                    "false"
                                                )
                                            }
                                        >
                                            Unarchived notes
                                            {showArchivedFilter === "false" ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                    </DropdownContent>
                                </Dropdown>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <FilterButton text="Date Created" />
                                    </PopoverTrigger>
                                    <PopoverContent
                                        align="end"
                                        className="p-16 gap-y-12"
                                    >
                                        <DatePicker
                                            label="From"
                                            placeholderText="MM/DD/YYYY"
                                            selected={
                                                fromDateCreatedFilterToDate
                                            }
                                            onChange={
                                                handleFromDateCreatedFilterChange
                                            }
                                            maxDate={
                                                toDateCreatedFilterToDate ||
                                                new Date(Date.now())
                                            }
                                            isClearable
                                        />
                                        <DatePicker
                                            label="To"
                                            placeholderText="MM/DD/YYYY"
                                            selected={toDateCreatedFilterToDate}
                                            onChange={
                                                handleToDateCreatedFilterChange
                                            }
                                            minDate={
                                                fromDateCreatedFilterToDate
                                            }
                                            isClearable
                                        />
                                    </PopoverContent>
                                </Popover>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <FilterButton text="Date Of Service" />
                                    </PopoverTrigger>
                                    <PopoverContent
                                        align="end"
                                        className="p-16 gap-y-12"
                                    >
                                        <DatePicker
                                            label="From"
                                            placeholderText="MM/DD/YYYY"
                                            selected={fromDateFilterToDate}
                                            onChange={
                                                handleFromDateFilterChange
                                            }
                                            maxDate={
                                                toDateFilterToDate ||
                                                new Date(Date.now())
                                            }
                                            isClearable
                                        />
                                        <DatePicker
                                            label="To"
                                            placeholderText="MM/DD/YYYY"
                                            selected={toDateFilterToDate}
                                            onChange={handleToDateFilterChange}
                                            minDate={fromDateFilterToDate}
                                            isClearable
                                        />
                                    </PopoverContent>
                                </Popover>
                            </div>
                        </div>
                    </div>
                    <TableFiltersDisplay
                        appliedFilters={[
                            {
                                key: "type[]",
                                values: noteTypeFilter.map(
                                    (type) => NoteLabels[type]
                                ),
                            },
                            {
                                key: "flagged_status[]",
                                values: flaggedStatusFilter,
                            },
                        ]}
                        wrapperClass="mt-12"
                    />
                </div>

                {isLoading ? (
                    <Skeleton
                        type="table"
                        containerTestId="list-loader"
                        count={8}
                    />
                ) : null}
                {error && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            error?.response?.data.message ||
                            `Cannot display notes list at this time please try again later`
                        }
                    />
                )}
                {data && isSuccess && data.data.length === 0 && (
                    <ListState
                        stateHelperText="Notes list will appear here once they are added"
                        isError={false}
                        emptyMessage="No note added yet"
                    />
                )}

                {isSuccess && data && data.data.length > 0 && (
                    <>
                        {getTotalAmountOfTableRowsSelected(rowSelection)
                            .amount > 0 && (
                            <div className={styles.selected}>
                                <div className={styles.selected_count}>
                                    {
                                        getTotalAmountOfTableRowsSelected(
                                            rowSelection
                                        ).amountString
                                    }
                                    :
                                </div>

                                {detailsArray?.every(
                                    (note) =>
                                        note?.note_type &&
                                        noteTypesForExport.includes(
                                            note?.note_type
                                        )
                                ) &&
                                    detailsArray?.every(
                                        (note) =>
                                            note?.status !== NoteStatus.DRAFT
                                    ) &&
                                    isSuperAdmin && (
                                        <Button
                                            variant="secondary"
                                            onClick={() => handleExportNotes()}
                                        >
                                            Export to pdf
                                        </Button>
                                    )}

                                {canArchiveNote() &&
                                    detailsArray?.every(
                                        (note) => note?.is_archived === false
                                    ) &&
                                    detailsArray?.every(
                                        (note) =>
                                            note?.status !== NoteStatus.DRAFT
                                    ) && (
                                        <Button
                                            variant="secondary"
                                            onClick={() =>
                                                handleDialogInView(
                                                    "archive-note"
                                                )
                                            }
                                        >
                                            Archive note
                                        </Button>
                                    )}

                                {canArchiveNote() &&
                                    detailsArray?.every(
                                        (note) => note?.is_archived
                                    ) && (
                                        <Button
                                            variant="secondary"
                                            onClick={() => handleUnarchive()}
                                        >
                                            Unarchive note
                                        </Button>
                                    )}

                                {showBulkFlagNotesBtn && (
                                    <Button
                                        variant="secondary"
                                        onClick={() =>
                                            handleDialogInView("flag_note")
                                        }
                                    >
                                        Flag for audit
                                    </Button>
                                )}
                                {practice?.permissions?.includes(
                                    UserPermisions.CLIENT_INFO_NOTES_FLAG_FOR_AUDIT_WRITE
                                ) &&
                                    !detailsArray?.every(
                                        (note) =>
                                            note?.flagged_for_audit_status ===
                                            FlaggedNoteForAuditStatus.UNFLAGGED
                                    ) && (
                                        <Button
                                            variant="secondary"
                                            onClick={() =>
                                                handleDialogInView(
                                                    "unflag_note"
                                                )
                                            }
                                        >
                                            Unflag note
                                        </Button>
                                    )}
                            </div>
                        )}
                        {selectedNotesId.length > 0 && (
                            <div className="mb-16 flex items-center gap-x-8 text-sm">
                                <div>
                                    {`${selectedNotesId.length}  selected`} :
                                </div>
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <FilterButton
                                            classNames="bg-primary normal-case text-white *:stroke-white"
                                            text="Actions"
                                            stroke={APP_COLORS.COLOR_WHITE}
                                        />
                                    </DropdownTrigger>
                                    <DropdownContent width="auto">
                                        <div
                                            className={
                                                styles.action_dropdown_items
                                            }
                                        >
                                            {detailsArray?.every(
                                                (note) =>
                                                    note?.note_type &&
                                                    noteTypesForExport.includes(
                                                        note?.note_type
                                                    )
                                            ) &&
                                                isSuperAdmin && (
                                                    <DropdownItem
                                                        onClick={() =>
                                                            handleExportNotes()
                                                        }
                                                    >
                                                        Export to pdf
                                                    </DropdownItem>
                                                )}
                                            {canArchiveNote() &&
                                                detailsArray?.every(
                                                    (note) =>
                                                        note?.is_archived ===
                                                        false
                                                ) &&
                                                detailsArray?.every(
                                                    (note) =>
                                                        note?.status !==
                                                        NoteStatus.DRAFT
                                                ) && (
                                                    <DropdownItem
                                                        onClick={() =>
                                                            handleDialogInView(
                                                                "archive-note"
                                                            )
                                                        }
                                                    >
                                                        Archive note
                                                    </DropdownItem>
                                                )}
                                            {showBulkFlagNotesBtn && (
                                                <DropdownItem
                                                    onClick={() =>
                                                        handleDialogInView(
                                                            "flag_note"
                                                        )
                                                    }
                                                >
                                                    Flag for audit
                                                </DropdownItem>
                                            )}
                                            {practice?.permissions?.includes(
                                                UserPermisions.CLIENT_INFO_NOTES_FLAG_FOR_AUDIT_WRITE
                                            ) &&
                                                !detailsArray?.every(
                                                    (note) =>
                                                        note?.flagged_for_audit_status ===
                                                        FlaggedNoteForAuditStatus.UNFLAGGED
                                                ) && (
                                                    <DropdownItem
                                                        onClick={() =>
                                                            handleDialogInView(
                                                                "unflag_note"
                                                            )
                                                        }
                                                    >
                                                        Unflag note
                                                    </DropdownItem>
                                                )}
                                            {canArchiveNote() &&
                                                detailsArray?.every(
                                                    (note) => note?.is_archived
                                                ) && (
                                                    <DropdownItem
                                                        onClick={() =>
                                                            handleUnarchive()
                                                        }
                                                    >
                                                        Unarchive note
                                                    </DropdownItem>
                                                )}
                                        </div>
                                    </DropdownContent>
                                </Dropdown>
                            </div>
                        )}
                        <div className={styles.list_web}>
                            <Table
                                columns={columns}
                                data={data?.data}
                                rowSelection={rowSelection}
                                setRowSelection={setRowSelection}
                                handleRowClick={handleRowClick}
                                setGetSelectedData={setGetSelectedData}
                                className={styles.table}
                                setSortConfig={setSortConfig}
                                sortConfig={sortConfig}
                                sortableColumns={sortableColumns}
                                hasPagination={data.total_count > 20}
                                pagination={
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handleSetPageFilter}
                                        limit={limitFilter}
                                        onLimitChange={handleSetLimitFilter}
                                    />
                                }
                            />
                        </div>
                        <div
                            role="table"
                            ref={mobileListRef}
                            className="block md:hidden"
                        >
                            <div className="flex flex-col gap-y-12">
                                {data.data.map((note) => (
                                    <MobileListItem
                                        key={note.note_id}
                                        onClickCheckButton={() =>
                                            toggleNoteSelection(note.note_id)
                                        }
                                        isSelected={selectedNotesId.includes(
                                            note.note_id
                                        )}
                                        showCheckButton
                                        topChildren={
                                            <div className="flex items-center gap-x-8 text-base font-semibold capitalize">
                                                {`${note.client.first_name} ${note.client.last_name}`}
                                            </div>
                                        }
                                        bottomChildren={
                                            <Button
                                                variant="normal"
                                                size="auto"
                                                className="flex flex-col gap-y-8 w-full items-start"
                                                onClick={() =>
                                                    handleMobileNoteClick(note)
                                                }
                                            >
                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <span className="basis-1/2 text-gray font-semibold text-xs">
                                                        {formatDate(
                                                            note.date_of_service
                                                        )}
                                                    </span>
                                                    <span className="basis-1/2 font-semibold text-xs">
                                                        {
                                                            NoteLabels[
                                                                note.note_type
                                                            ]
                                                        }
                                                    </span>
                                                </div>
                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <span className="w-1/2 text-gray font-semibold text-xs">
                                                        Provider:
                                                    </span>
                                                    <span className="w-1/2 font-semibold text-xs">
                                                        {`${note.provider?.first_name} ${note.provider?.last_name}`}
                                                    </span>
                                                </div>
                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <span className="w-1/2 text-gray font-semibold text-xs">
                                                        Created on:
                                                    </span>
                                                    <span className="w-1/2 font-semibold text-xs">
                                                        {formatDate(
                                                            note.created_at
                                                        )}
                                                    </span>
                                                </div>
                                                {!practice?.provider_id && (
                                                    <div className="flex items-center w-full text-left gap-x-4">
                                                        <span className="w-1/2 text-gray font-semibold text-xs">
                                                            Insurance Provider:
                                                        </span>
                                                        <span
                                                            className={
                                                                note.client
                                                                    .self_pay
                                                                    ? "normal-case w-1/2 font-semibold text-xs"
                                                                    : "uppercase w-1/2 font-semibold text-xs"
                                                            }
                                                        >
                                                            {note.client
                                                                .self_pay &&
                                                            !showInsOverSelfPay
                                                                ? "Self pay"
                                                                : (note.client
                                                                      .insurance_provider_name &&
                                                                      removeEnumUnderscore(
                                                                          note
                                                                              .client
                                                                              .insurance_provider_name ||
                                                                              ""
                                                                      )) ||
                                                                  "--"}
                                                        </span>
                                                    </div>
                                                )}
                                            </Button>
                                        }
                                    />
                                ))}
                            </div>
                            {data.total_count > 20 && (
                                <div className="bg-white p-12 rounded-r8 mt-16">
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handleSetPageFilter}
                                        limit={limitFilter}
                                        onLimitChange={handleSetLimitFilter}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

import * as React from "react";

export default function DinersclubIcon() {
    return (
        <svg
            width="23"
            height="20"
            viewBox="0 0 23 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.46076 19.3333H12.9222C18.3254 19.3333 22.6311 15.0466 22.8 9.75132V9.66726C22.7156 4.28788 18.3254 -0.0828604 12.9222 0.00119234H9.03863C3.88867 0.169298 -0.16376 4.54004 0.00509081 9.75132C0.00509081 14.9626 4.22637 19.2493 9.46076 19.3333ZM1.69361 9.66722C1.69361 5.29648 5.32391 1.68221 9.71404 1.68221C14.1042 1.68221 17.7345 5.29648 17.7345 9.66722C17.7345 14.038 14.1042 17.6522 9.71404 17.6522C5.32391 17.6522 1.69361 14.038 1.69361 9.66722ZM10.9804 15.0466V4.37191C13.9353 5.12838 15.7927 8.07023 15.0329 11.0121C14.5263 13.0293 13.0066 14.5423 10.9804 15.0466ZM4.47965 8.32239C3.71982 11.2642 5.49276 14.2901 8.44765 15.0466V4.37191C6.50586 4.87622 4.9862 6.38917 4.47965 8.32239Z"
                fill="#0079BE"
            />
        </svg>
    );
}

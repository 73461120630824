import * as React from "react";
import cn from "classnames";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Input } from "@jhool-io/fe-components";
import styles from "./ApproveNote.module.scss";
import { IApproveNotePayload } from "../../../utils/types/notes";
import NoteDetails from "../NotesDetails/NoteDetails";
import { useApproveNote } from "../../../hooks/mutations/note";
import useToast from "../../../hooks/useToast";

interface ApproveNoteProps {
    /**  Function to call when form submit button is clicked */
    onFormSubmit(): void;
    showDetails?: boolean;
    /** Id of single note */
    noteId: string;
    /** Ids of selected notes */
    selectedIds?: string[];
}

const schema = yup.object({
    signature: yup.string().required("Provider's signature is required"),
});

export default function ApproveNote({
    onFormSubmit,
    showDetails = true,
    noteId,
    selectedIds,
}: ApproveNoteProps) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IApproveNotePayload>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });
    const params = useParams();

    const clientId = params.clientId as string;

    const approveSuperviseeNote = useApproveNote();

    const { toast } = useToast();

    // Query client
    const queryClient = useQueryClient();

    const onSubmit = (data: IApproveNotePayload) => {
        const dataToSend = {
            note_ids:
                selectedIds && selectedIds.length > 0 ? selectedIds : [noteId],
            signature: data.signature as string,
            signature_date_time: new Date(Date.now()).toISOString(),
        };
        approveSuperviseeNote.mutate(dataToSend, {
            onSuccess: async (res) => {
                queryClient.invalidateQueries({
                    queryKey: [clientId, "session-note", noteId],
                });

                queryClient.invalidateQueries({
                    queryKey: ["notes"],
                });

                await queryClient.invalidateQueries({
                    queryKey: ["supervisee"],
                });
                toast({
                    mode: "success",
                    message:
                        res.message || "Note approved and signed successfully",
                });
                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not approve note at this time",
                });
            },
        });
    };

    return (
        <form
            id="note-approval"
            className={styles.approve_note}
            onSubmit={handleSubmit(onSubmit)}
            aria-label="approve note form"
            autoComplete="off"
        >
            {showDetails && (
                <div className="mb-24">
                    <NoteDetails showProvider />
                </div>
            )}

            <div className={styles.sign_note}>
                <p className={cn(styles.sign_note_title, "fs-exclude")}>
                    Sign note now
                </p>
                <Input
                    {...register("signature")}
                    label="Provider's Initials"
                    placeholder="Provider's Initials"
                    aria-label="provider-initials"
                    hasError={!!errors.signature}
                    errorText={errors.signature?.message}
                />
            </div>
        </form>
    );
}

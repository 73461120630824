import {
    Button,
    Card,
    Dialog,
    DialogContent,
    DialogTrigger,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Tooltip,
} from "@jhool-io/fe-components";
import * as React from "react";
import { Link, useSearchParams } from "react-router-dom";
import InfoCircleIcon from "../../../../components/Icons/InfoCircle";
import ExpiringPoliciesList from "../ExpiringPoliciesList/ExpiringPoliciesList";
import {
    ExpiryDateRange,
    IBillingOverViewResponse,
} from "../../types/dashboard.types";
import { IApiResponse } from "../../../../utils/types/api-response";
import {
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    showMoneyInAppFormat,
    truncateString,
} from "../../../../utils/helpers";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import { APP_COLORS } from "../../../../utils/constants";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import { useFetchExpiringPolicies } from "../../hooks/dashboard.queries";

interface BillingStats {
    billingStats: IApiResponse<IBillingOverViewResponse> | undefined;
    isLoading: boolean;
}
export default function BillingStatCards({
    billingStats,
    isLoading,
}: BillingStats) {
    const [showPolicies, setShowPolicies] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const dateFilter = searchParams.get("expiry_date") || "this_month";
    const insuranceProviderFilter =
        searchParams.get("insurance_provider") || "";

    const { data: expiringPolicyData, isLoading: expiringPolicyLoading } =
        useFetchExpiringPolicies({
            custom_date: dateFilter,
            insurance_provider_id: insuranceProviderFilter,
            sort_attribute: "end_date",
            sort_order: "DESC",
        });

    const handlePolicyModalToggle = () => {
        setShowPolicies((state) => !state);
    };

    const handleExpiryDateRangeFilter = (filter: string) => {
        searchParams.set("expiry_date", filter);
        setSearchParams(searchParams);
    };

    return (
        <>
            {(isLoading || expiringPolicyLoading) && (
                <Card classNames="grid grid-cols-[repeat(3,minmax(312px,1fr))] md:grid-cols-[repeat(3,minmax(350px,1fr))] overflow-x-auto gap-x-12">
                    <Skeleton
                        height={200}
                        width="100%"
                        containerTestId="billing-stats-loader"
                    />
                    <Skeleton
                        height={200}
                        width="100%"
                        containerTestId="billing-stats-loader"
                    />
                    <Skeleton
                        height={200}
                        width="100%"
                        containerTestId="billing-stats-loader"
                    />
                </Card>
            )}

            {!isLoading && expiringPolicyData && (
                <div className="grid grid-cols-[repeat(3,minmax(350px,1fr))] md:grid-cols-[repeat(3,minmax(400px,1fr))] overflow-x-auto gap-x-12">
                    <Card classNames="px-12 md:px-16 py-16 md:py-24 bg-[#FFF6FB] border-none">
                        <div className="mb-12 md:mb-16 flex items-center justify-between">
                            <div className="flex items-center gap-x-8">
                                <h3 className="font-bold text-sm md:text-base">
                                    Recent remits
                                </h3>
                                <Tooltip
                                    classNames="w-[25rem] bg-[#0B132B] text-white text-xs font-normal"
                                    content="Recent remits shows a list of recently received payments from insurance companies."
                                >
                                    <Button size="auto" variant="normal">
                                        <InfoCircleIcon
                                            stroke="#0B132B"
                                            fill="#0B132B"
                                        />
                                    </Button>
                                </Tooltip>
                            </div>
                            <Link
                                to="/remits?navLinkInUrl=claims"
                                className="font-semibold text-primary text-[10px]"
                            >
                                View all remits
                            </Link>
                        </div>
                        <span className="text-graydark text-xs font-medium block mb-8">
                            Total Remits
                        </span>
                        <span className="font-bold text-[32px] leading-[40px] block">
                            {billingStats?.data?.total_remits || 0}
                        </span>
                        <div className="mt-16 md:mt-24 text-xss">
                            <div className="r4 bg-[#F6E7EF] py-[5px] px-8 grid grid-cols-3 gap-x-8 mb-12 md:mb-16">
                                <span className="font-normal text-graydark">
                                    Date of service
                                </span>
                                <span className="font-normal text-left text-graydark">
                                    Client
                                </span>
                                <span className="font-normal text-center text-graydark">
                                    Amount
                                </span>
                            </div>
                            {billingStats?.data?.remits.length === 0 && (
                                <div className="flex flex-col text-center justify-center items-center mt-24">
                                    <p className="text-sm font-medium">
                                        No recent remits
                                    </p>
                                    <p className="text-gray text-xs font-normal w-[70%] max-[768px]:w-[90%] flex-wrap leading-normal">
                                        No recent remit found. When you receive
                                        a remit, it will be displayed here
                                    </p>
                                </div>
                            )}
                            {billingStats?.data?.remits
                                ?.slice(0, 3)
                                ?.map((remit, index) => (
                                    <div
                                        className="grid grid-cols-[repeat(3,_1fr)] px-8 gap-y-12 md:gap-y-16 gap-x-8 font-medium pb-16"
                                        key={`${index + 1} `}
                                    >
                                        <span>
                                            {formatDate(
                                                remit.date_of_service || ""
                                            )}
                                        </span>
                                        <span className="text-left">
                                            {remit.client_name}
                                        </span>
                                        <span className="text-center">
                                            {showMoneyInAppFormat(remit.amount)}
                                        </span>
                                    </div>
                                ))}
                        </div>
                    </Card>
                    <Card classNames="px-12 md:px-16 py-16 md:py-24 bg-[#ECFBFF] border-none">
                        <div className="mb-12 md:mb-16 flex items-center justify-between">
                            <div className="flex items-center gap-x-8">
                                <h3 className="font-bold text-sm md:text-base">
                                    Draft Claims
                                </h3>
                                <Tooltip
                                    classNames="w-[25rem] bg-[#0B132B] text-white text-xs font-normal"
                                    content="Draft claims shows claims that are in progress or pending submission."
                                >
                                    <Button size="auto" variant="normal">
                                        <InfoCircleIcon
                                            stroke="#0B132B"
                                            fill="#0B132B"
                                        />
                                    </Button>
                                </Tooltip>
                            </div>
                            <Link
                                to="/claims?navLinkInUrl=claims"
                                className="font-semibold text-primary text-[10px]"
                            >
                                View all claims
                            </Link>
                        </div>
                        <span className="text-graydark text-xs font-medium block mb-8">
                            Total Claims
                        </span>
                        <span className="font-bold text-[32px] leading-[40px] block">
                            {billingStats?.data?.total_draft_claims || 0}
                        </span>
                        <div className="mt-16 md:mt-24 text-xss">
                            <div className="r4 bg-[#DFEFF3] py-[5px] px-8 grid grid-cols-3 gap-x-8 mb-12 md:mb-16">
                                <span className="font-normal text-graydark">
                                    Date of service
                                </span>
                                <span className="font-normal text-left text-graydark">
                                    Client
                                </span>
                                <span className="font-normal text-center text-graydark ">
                                    CPT Code
                                </span>
                            </div>
                            {billingStats?.data?.draft_claims?.length === 0 && (
                                <div className="flex flex-col text-center justify-center items-center mt-24">
                                    <p className="text-sm font-medium">
                                        No draft claims
                                    </p>
                                    <p className="text-gray text-xs font-normal w-[70%] max-[768px]:w-[90%] flex-wrap leading-normal">
                                        No draft claims found. To generate a
                                        draft claim, go to the
                                        &quot;Claims&quot; section.
                                    </p>
                                </div>
                            )}
                            {billingStats?.data?.draft_claims
                                ?.slice(0, 3)
                                ?.map((draft, index) => (
                                    <div
                                        className="grid grid-cols-[repeat(3,_1fr)] px-8 gap-y-12 md:gap-y-16 gap-x-8 font-medium pb-16"
                                        key={`${index + 1} `}
                                    >
                                        <span>
                                            {formatDate(
                                                draft.date_of_service || ""
                                            )}
                                        </span>
                                        <span className="text-left">
                                            {draft.client_name}
                                        </span>
                                        <span className="text-center">
                                            {draft.cpt_code}
                                        </span>
                                    </div>
                                ))}
                        </div>
                    </Card>
                    <Card classNames="px-12 md:px-16 py-16 md:py-24 bg-[#EDF0FA] border-none">
                        <div className="mb-12 md:mb-16 flex items-center justify-between">
                            <div className="flex items-center gap-x-8">
                                <h3 className="font-bold text-sm md:text-base">
                                    Expiring Policies
                                </h3>
                                <Tooltip
                                    classNames="w-[25rem] bg-[#0B132B] text-white text-xs font-normal"
                                    content="Expiring policies shows patients with insurance policies nearing expiration. Or Shows patients that their insurance policies are about to expire"
                                >
                                    <Button size="auto" variant="normal">
                                        <InfoCircleIcon
                                            stroke="#0B132B"
                                            fill="#0B132B"
                                        />
                                    </Button>
                                </Tooltip>
                            </div>
                            <Dialog open={showPolicies}>
                                <DialogTrigger asChild>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="font-semibold text-primary text-[10px]"
                                        onClick={handlePolicyModalToggle}
                                    >
                                        View all expiring policies
                                    </Button>
                                </DialogTrigger>
                                <DialogContent
                                    handleCloseDialog={handlePolicyModalToggle}
                                    showFooter={false}
                                    classNames="w-auto"
                                    wrapperClass="w-[1186px]"
                                >
                                    <ExpiringPoliciesList />
                                </DialogContent>
                            </Dialog>
                        </div>
                        <div className="flex justify-between">
                            <span className="text-graydark text-xs font-medium block mb-8">
                                Total Expiring Policies
                            </span>
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="flex gap-x-8 items-center p-[2px]  border-[0.2px]"
                                    >
                                        <span className="text-xs text-gray font-normal capitalize">
                                            {dateFilter
                                                ? truncateString(
                                                      removeEnumUnderscore(
                                                          dateFilter
                                                      )
                                                  )
                                                : "Date range"}
                                        </span>
                                        <ChevronDownIcon
                                            stroke={APP_COLORS.COLOR_GRAY}
                                        />
                                    </Button>
                                </DropdownTrigger>
                                <DropdownContent
                                    align="end"
                                    maxHeight={216}
                                    width={200}
                                    className="overflow-y-auto"
                                >
                                    {Object.values(ExpiryDateRange).map(
                                        (range) => (
                                            <DropdownItem
                                                key={range}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleExpiryDateRangeFilter(
                                                        range
                                                    );
                                                }}
                                            >
                                                {makeStringFirstLetterCapital(
                                                    removeEnumUnderscore(range)
                                                )}
                                            </DropdownItem>
                                        )
                                    )}
                                </DropdownContent>
                            </Dropdown>
                        </div>

                        <span className="font-bold text-[32px] leading-[40px] block">
                            {expiringPolicyData?.data.length || 0}
                        </span>
                        <div className="mt-16 md:mt-24 text-xss">
                            <div className="r4 bg-[#E0E5F7] py-[5px] px-8 grid grid-cols-4 gap-x-8 mb-12 md:mb-16">
                                <span className="font-normal text-graydark">
                                    Client
                                </span>
                                <span className="font-normal text-left text-graydark">
                                    Policy start date
                                </span>
                                <span className="font-normal text-left text-graydark">
                                    Policy end date
                                </span>
                                <span className="font-normal text-left text-graydark">
                                    FAP end date
                                </span>
                            </div>
                            {expiringPolicyData?.data.length === 0 && (
                                <div className="flex flex-col text-center justify-center items-center mt-24">
                                    <p className="text-sm font-medium">
                                        No expiring policies
                                    </p>
                                    <p className="text-gray text-xs font-normal w-[70%] max-[768px]:w-[90%] flex-wrap leading-normal">
                                        Expiring policies will show up here as
                                        they near their expiration date.
                                    </p>
                                </div>
                            )}
                            {expiringPolicyData?.data
                                ?.slice(0, 3)
                                ?.map((policy, index) => (
                                    <div
                                        className="grid grid-cols-4 px-8 gap-x-8 gap-y-12 md:gap-y-16 font-medium pb-16"
                                        key={`${index + 1} `}
                                    >
                                        <Link
                                            to={`/clients/${policy.client_id}`}
                                            className="text-primary underline capitalize"
                                        >
                                            {makeStringFirstLetterCapital(
                                                policy.client_name
                                            )}
                                        </Link>
                                        <span className="text-left">
                                            {formatDate(
                                                policy.start_date,
                                                true
                                            )}
                                        </span>

                                        <span className="text-left">
                                            {formatDate(policy.end_date, true)}
                                        </span>

                                        <span className="text-left">
                                            {policy.financial_assistance_end_date
                                                ? formatDate(
                                                      policy.financial_assistance_end_date,
                                                      true
                                                  )
                                                : "--"}
                                        </span>
                                    </div>
                                ))}
                        </div>
                    </Card>
                </div>
            )}
        </>
    );
}

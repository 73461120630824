export default function CalendarDay() {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="16" height="17" fill="#E5E5E5" />
            <g
                id="Main Dashboard 1.0 - Approved version"
                clipPath="url(#clip0_29139_50286)"
            >
                <rect
                    width="1440"
                    height="1458"
                    transform="translate(-312 -225)"
                    fill="#FBFBFB"
                />
                <g id="Frame 1000002446">
                    <rect
                        x="-23.5"
                        y="-120.5"
                        width="1119"
                        height="298"
                        rx="7.5"
                        fill="white"
                    />
                    <rect
                        x="-23.5"
                        y="-120.5"
                        width="1119"
                        height="298"
                        rx="7.5"
                        stroke="#E7E7E7"
                    />
                    <g id="Frame 1000002539">
                        <g
                            id="calendar-days 4"
                            clipPath="url(#clip1_29139_50286)"
                        >
                            <g id="Group">
                                <path
                                    id="Vector"
                                    d="M5.10938 2.94477V1.16699"
                                    stroke="#0B132B"
                                    strokeOpacity="0.64"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    id="Vector_2"
                                    d="M10.8906 2.94477V1.16699"
                                    stroke="#0B132B"
                                    strokeOpacity="0.64"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    id="Vector_3"
                                    d="M12.2222 2.94434H3.77778C2.79594 2.94434 2 3.74027 2 4.72211V12.2777C2 13.2595 2.79594 14.0554 3.77778 14.0554H12.2222C13.2041 14.0554 14 13.2595 14 12.2777V4.72211C14 3.74027 13.2041 2.94434 12.2222 2.94434Z"
                                    stroke="#0B132B"
                                    strokeOpacity="0.64"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    id="Vector_4"
                                    d="M2 6.05566H14"
                                    stroke="#0B132B"
                                    strokeOpacity="0.64"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    id="Vector_5"
                                    d="M7.99826 7.83398C7.50849 7.83398 7.10938 8.2331 7.10938 8.72287C7.10938 9.21265 7.50849 9.61176 7.99826 9.61176C8.48804 9.61176 8.88715 9.21265 8.88715 8.72287C8.88715 8.2331 8.48804 7.83398 7.99826 7.83398Z"
                                    fill="#0B132B"
                                    fillOpacity="0.64"
                                />
                                <path
                                    id="Vector_6"
                                    d="M11.1115 9.61176C11.6013 9.61176 12.0004 9.21265 12.0004 8.72287C12.0004 8.2331 11.6013 7.83398 11.1115 7.83398C10.6218 7.83398 10.2227 8.2331 10.2227 8.72287C10.2227 9.21265 10.6218 9.61176 11.1115 9.61176Z"
                                    fill="#0B132B"
                                    fillOpacity="0.64"
                                />
                                <path
                                    id="Vector_7"
                                    d="M7.99826 10.5C7.50849 10.5 7.10938 10.8991 7.10938 11.3889C7.10938 11.8787 7.50849 12.2778 7.99826 12.2778C8.48804 12.2778 8.88715 11.8787 8.88715 11.3889C8.88715 10.8991 8.48804 10.5 7.99826 10.5Z"
                                    fill="#0B132B"
                                    fillOpacity="0.64"
                                />
                                <path
                                    id="Vector_8"
                                    d="M4.88889 10.5C4.39911 10.5 4 10.8991 4 11.3889C4 11.8787 4.39911 12.2778 4.88889 12.2778C5.37867 12.2778 5.77778 11.8787 5.77778 11.3889C5.77778 10.8991 5.37867 10.5 4.88889 10.5Z"
                                    fill="#0B132B"
                                    fillOpacity="0.64"
                                />
                                <path
                                    id="Vector_9"
                                    d="M11.1115 10.5C10.6218 10.5 10.2227 10.8991 10.2227 11.3889C10.2227 11.8787 10.6218 12.2778 11.1115 12.2778C11.6013 12.2778 12.0004 11.8787 12.0004 11.3889C12.0004 10.8991 11.6013 10.5 11.1115 10.5Z"
                                    fill="#0B132B"
                                    fillOpacity="0.64"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_29139_50286">
                    <rect
                        width="1440"
                        height="1458"
                        fill="white"
                        transform="translate(-312 -225)"
                    />
                </clipPath>
                <clipPath id="clip1_29139_50286">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

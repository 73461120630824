import axios from "../../../utils/axios";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    IAddClientFlag,
    ICardFilterTypes,
    IClientDocumentsListFilters,
    IClientEditInsuranceDetails,
    IClientMemoListFilterTypes,
    IClientNewInsuranceDetails,
    IClientNotesListFilterTypes,
    IClientProviderHistoryListFilters,
    IEditClientFee,
    INewCreditCardDetails,
    INewMemo,
} from "../types/clients.types";

// Endpoint to add client payment card
export async function addPaymentCard(
    clientId: string,
    data: INewCreditCardDetails
) {
    const response = await axios.post(
        `/client/${clientId}/payment-cards`,
        data
    );
    return response.data;
}

// Endpoint to add client insurance details
export async function addClientInsuranceDetails(
    clientId: string,
    data: IClientNewInsuranceDetails
) {
    const response = await axios.post(`/client/${clientId}/insurance`, data);
    return response.data;
}

// Function to add payment card label
export async function addPaymentCardLabel(
    clientId: string,
    paymentCardId: string,
    payload: {
        card_label: string;
    }
) {
    const response = await axios.patch(
        `/client/${clientId}/payment-cards/${paymentCardId}`,
        {
            ...payload,
        }
    );
    return response.data;
}

// Endpoint to fetch card transaction history
export async function fetchCardTransactionHistory(
    clientId: string,
    paymentId: string,
    filters: ICardFilterTypes
) {
    const response = await axios.get(
        `/client/${clientId}/payment-cards/${paymentId}/transactions`,
        {
            params: handleRemoveEmptyParamFromQueryParams(filters),
        }
    );
    return response.data;
}

// Endpoint to fetch client credit cards list
export async function fetchClientCreditCards(clientId: string) {
    const response = await axios.get(`/client/${clientId}/payment-cards`);
    return response.data;
}

// Endpoint to fetch client documents list
export async function fetchClientDocuments(
    filters: IClientDocumentsListFilters
) {
    const response = await axios.get(`/client/${filters.client_id}/documents`, {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });
    return response.data;
}

// Endpoint to delete client document
export async function deleteClientDocument(
    clientId: string,
    documentId: string
) {
    const response = await axios.delete(
        `/client/${clientId}/documents/${documentId}`
    );

    return response.data;
}

// Endpoint to fetch all insurance providers
export async function fetchInsuranceProviders() {
    const response = await axios.get(`/insurance_providers`);
    return response.data;
}

// Function to validate payment card
export async function validatePaymentCard(
    clientId: string,
    paymentCardId: string
) {
    const response = await axios.post(
        `/client/${clientId}/payment-cards/${paymentCardId}/validate`
    );
    return response.data;
}

// Endpoint to edit client insurance details
export async function editClientInsuranceDetails(
    clientId: string,
    insuranceId: string,
    data: IClientEditInsuranceDetails
) {
    const response = await axios.patch(
        `client/${clientId}/insurance/${insuranceId}`,
        data
    );
    return response.data;
}

// Endpoint to delete insurance attachment
export async function deleteInsuranceAttachment(
    clientId: string,
    attachmentId: string
) {
    const response = await axios.delete(
        `/client/${clientId}/insurance/attachment/${attachmentId}`
    );
    return response.data;
}

// Endpoint to fetch client insurance history
export async function fetchClientInsuranceHistory(
    clientId: string,
    page = 1,
    limit = 20
) {
    const response = await axios.get(`/client/${clientId}/insurance`, {
        params: {
            page,
            limit,
        },
    });
    return response.data;
}

export async function fetchClientProviderHistory(
    clientId: string,
    filters: IClientProviderHistoryListFilters
) {
    const response = await axios.get(`/client/${clientId}/provider_history`, {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });

    return response.data;
}

export async function fetchClientMemos(
    clientId: string,
    filters: IClientMemoListFilterTypes
) {
    const response = await axios.get(`/client/${clientId}/memos`, {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });

    return response.data;
}

// Function to add new memos
export async function addClientMemo(clientId: string, data: INewMemo) {
    const response = await axios.post(`/client/${clientId}/memos`, data);
    return response.data;
}

// Endpoint to fetch client notes list
export async function fetchClientNotes(
    clientId: string,
    filters: IClientNotesListFilterTypes
) {
    const response = await axios.get(`/client/${clientId}/notes`, {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });

    return response.data;
}

// Endpoint to fetch client fees
export async function fetchClientFees(clientId: string, limit: number) {
    const response = await axios.get(`/client/${clientId}/fees?limit=${limit}`);
    return response.data;
}

export async function editClientCurrentFee(
    clientId: string,
    feeId: string,
    data: IEditClientFee
) {
    const response = await axios.patch(
        `/client/${clientId}/fees/${feeId}`,
        data
    );
    return response.data;
}

// Endpoint to add client flag
export async function addClientFlag(clientId: string, payload: IAddClientFlag) {
    const response = await axios.post(`/client/${clientId}/flags`, payload);
    return response.data;
}

// Endpoint to get all client flags
export async function fetchClientFlags(clientId: string) {
    const response = await axios.get(`/client/${clientId}/flags`);
    return response.data;
}

// Endpoint to delete client flag
export async function deleteClientFlags(clientId: string, flagId: string) {
    const response = await axios.delete(`/client/${clientId}/flags/${flagId}`);
    return response.data;
}

// Endpoint to update client flag
export async function updateClientFlag(
    clientId: string,
    flagId: string,
    payload: IAddClientFlag
) {
    const response = await axios.patch(
        `/client/${clientId}/flags/${flagId}`,
        payload
    );
    return response.data;
}

import * as React from "react";

export interface IPinnedClient {
    client_name: string;
    client_id: string;
    pin_location: string;
    pin_module: PinModule;
    pinned_practice_id?: string;
}

export type PinModule = "client_chart" | "bill" | "note";

interface PinnedClientsContextProps {
    handlePinClient: (params: {
        clientId: string;
        clientName: string;
        pinModule: PinModule;
    }) => void;
    isClientPinnedInLocation: (clientId: string, module: PinModule) => boolean;
    handleUnpinClient: (clientId: string, module: PinModule) => void;
    pinnedClients: IPinnedClient[];
}

export const PinnedClientsContext =
    React.createContext<PinnedClientsContextProps | null>(null);

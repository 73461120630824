export default function BackIcon() {
    return (
        <svg
            width="14"
            height="17"
            viewBox="0 0 14 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_5077_1444)">
                <path
                    d="M13.25 7.21875H4.29999L2.99999 8.5L4.29968 9.78125H13.25C13.6656 9.78125 14 9.4375 14 9.01156V7.98594C14.0014 7.88625 13.983 7.78729 13.946 7.69471C13.909 7.60214 13.8541 7.51779 13.7844 7.4465C13.7147 7.37521 13.6316 7.3184 13.5399 7.27931C13.4482 7.24023 13.3497 7.21965 13.25 7.21875Z"
                    fill="#576A81"
                />
                <path
                    d="M6.30281 1.70379C6.29531 1.71098 6.28781 1.71848 6.28063 1.72598L0.218752 7.95504C0.0776606 8.10076 -0.00122547 8.29565 -0.00122547 8.49848C-0.00122547 8.70131 0.0776606 8.8962 0.218752 9.04192L6.28125 15.2741C6.34767 15.3436 6.42716 15.3993 6.51516 15.4381C6.60315 15.4768 6.69793 15.4978 6.79405 15.4998C6.89018 15.5019 6.98576 15.4849 7.07531 15.4499C7.16487 15.4149 7.24664 15.3626 7.31594 15.296C7.32344 15.2891 7.33063 15.2816 7.33781 15.2741L8.02938 14.5625C8.17159 14.4141 8.24994 14.2158 8.2476 14.0103C8.24527 13.8048 8.16243 13.6083 8.01688 13.4632L2.99406 8.50004L8.01688 3.53754C8.16385 3.39316 8.24767 3.19645 8.25001 2.99043C8.25235 2.78442 8.17303 2.58586 8.02938 2.43817L7.33719 1.72629C7.27082 1.65678 7.19138 1.60105 7.10343 1.56229C7.01549 1.52353 6.92076 1.50251 6.82467 1.50042C6.72858 1.49833 6.63303 1.51521 6.54348 1.55011C6.45393 1.58501 6.37215 1.63724 6.30281 1.70379Z"
                    fill="#576A81"
                />
            </g>
            <defs>
                <clipPath id="clip0_5077_1444">
                    <rect
                        width="14"
                        height="16"
                        fill="white"
                        transform="matrix(-1 0 0 1 14 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

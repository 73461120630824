import { Button } from "@jhool-io/fe-components";
import { useSearchParams } from "react-router-dom";
import TimesIcon from "../../components/Icons/Times";
import { APP_COLORS } from "../../utils/constants";
import {
    cn,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../utils/helpers";

interface TableFiltersDisplayProps {
    /** list of applied filters - key and value(s) */
    appliedFilters: { key: string; values: string[] }[];
    /** wrapper class */
    wrapperClass?: string;

    // the key of local storage item to clear
    localStorageKey?: string;
}

export default function TableFiltersDisplay({
    appliedFilters,
    wrapperClass,
    localStorageKey,
}: TableFiltersDisplayProps) {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleClearAllFilters = () => {
        appliedFilters.forEach((filter) => {
            searchParams.delete(filter.key);
        });
        setSearchParams(searchParams);
        if (localStorageKey) {
            localStorage.setItem(localStorageKey, searchParams.toString());
        }
    };

    const clearSingleFilter = (filterKey: string) => {
        searchParams.delete(filterKey);
        setSearchParams(searchParams);
        if (localStorageKey) {
            localStorage.setItem(localStorageKey, searchParams.toString());
        }
    };

    if (
        appliedFilters.filter(
            (filter) => filter.values && filter.values.length > 0
        ).length === 0
    )
        return null;

    return (
        <div className={cn("flex flex-col gap-y-4", wrapperClass)}>
            <div className="gap-x-8 flex items-center ">
                <span className="text-xs font-medium pr-8 border-r border-r-strokedark text-black-main">
                    Filters
                </span>
                <Button
                    size="auto"
                    variant="normal"
                    className="text-xs font-medium text-primary"
                    onClick={handleClearAllFilters}
                >
                    Clear all
                </Button>
            </div>
            <div className="flex items-center gap-12 flex-wrap">
                {appliedFilters
                    .filter((filter) => filter.values.length !== 0)
                    .map((filter) => (
                        <div
                            className="px-8 py-[2px] rounded-r4 bg-[#D3F7FA] w-auto inline-flex items-center gap-x-4"
                            key={filter.key}
                        >
                            <span className="text-xs text-primary leading-[18px] tracking-[0.36px] font-medium">
                                {`${makeStringFirstLetterCapital(
                                    removeEnumUnderscore(filter.key)
                                ).replace("[]", "")}:`}
                            </span>
                            <div className="text-xs leading-[18px] tracking-[0.36px] font-medium">
                                {filter.values
                                    .map((value) =>
                                        makeStringFirstLetterCapital(
                                            removeEnumUnderscore(value)
                                        )
                                    )
                                    .join(", ")}
                            </div>
                            <Button
                                size="auto"
                                variant="normal"
                                className="size-16 flex items-center justify-center"
                                onClick={() => {
                                    clearSingleFilter(filter.key);
                                }}
                            >
                                <TimesIcon
                                    stroke={APP_COLORS.COLOR_GRAY}
                                    className="size-[12px]"
                                />
                            </Button>
                        </div>
                    ))}
            </div>
        </div>
    );
}

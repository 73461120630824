export default function NavigateLeft() {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_dd_30835_35367)">
                <circle
                    cx="16"
                    cy="16"
                    r="16"
                    transform="matrix(-1 0 0 1 34 1)"
                    fill="white"
                />
            </g>
            <path
                d="M19.6667 21.1654L15.5 16.9987L19.6667 12.832"
                stroke="#0B132B"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <filter
                    id="filter0_dd_30835_35367"
                    x="0"
                    y="0"
                    width="36"
                    height="36"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius="1"
                        operator="dilate"
                        in="SourceAlpha"
                        result="effect1_dropShadow_30835_35367"
                    />
                    <feOffset />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.341176 0 0 0 0 0.415686 0 0 0 0 0.505882 0 0 0 0.16 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_30835_35367"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_30835_35367"
                        result="effect2_dropShadow_30835_35367"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_30835_35367"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}

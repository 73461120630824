import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { IGetPayersFilter, PayersResponse } from "../types/payers.types";
import { getPayers } from "../services/payers.services";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";

export function useGetPayers(filters: IGetPayersFilter) {
    return useQuery<PayersResponse, AxiosError<PayersResponse>>({
        queryKey: ["payers", handleRemoveEmptyParamFromQueryParams(filters)],
        queryFn: () => getPayers(filters),
    });
}

import axios from "../../../utils/axios";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import { ScheduleFilters } from "../types/schedule.types";

// Endpoint to fetch provider's caseload
export async function fetchWeeklySchedule(params: ScheduleFilters) {
    const response = await axios.get(`/schedules`, {
        params: handleRemoveEmptyParamFromQueryParams(params),
    });

    return response.data;
}

import React from "react";
import styles from "./Avatar.module.scss";
import { cn } from "../../utils/helpers";

export interface AvatarProps {
    /** Src of image avatar */
    src?: string;
    /* default label without images */
    label?: string;
    /** describes the image */
    alt?: string;
    /** Function to call when avatar is clicked */
    onClick?: () => void;
    /** Extra optional classname */
    className?: string;
}

export default function Avatar({
    src,
    label,
    alt,
    onClick,
    className,
}: AvatarProps) {
    return (
        <button
            className={cn(styles.avatar, className)}
            onClick={onClick}
            aria-label="avatar"
        >
            {src ? (
                <img className={styles.image} src={src} alt={alt} />
            ) : (
                <span className={styles.default_label} role="presentation">
                    {label}
                </span>
            )}
        </button>
    );
}

import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";

import {
    IAddNoteMessage,
    IAddNoteMessageResponse,
    IDeleteNoteMessage,
    IDeleteNoteMessageResponse,
    IFlagNote,
} from "../types/notes.types";
import { IApiResponse } from "../../../utils/types/api-response";
import {
    addNoteMessages,
    deleteNoteMessage,
    flagNote,
} from "../services/notes.services";

export function useDeleteMessage(noteId: string) {
    return useMutation<
        IApiResponse<IDeleteNoteMessageResponse>,
        AxiosError<IApiResponse<IDeleteNoteMessageResponse>>,
        IDeleteNoteMessage
    >({
        mutationKey: ["delete-message", noteId],
        mutationFn: (data: IDeleteNoteMessage) =>
            deleteNoteMessage(noteId, data),
    });
}

export function useAddNoteMessaging(noteId: string) {
    return useMutation<
        IApiResponse<IAddNoteMessageResponse>,
        AxiosError<IApiResponse<IAddNoteMessageResponse>>,
        IAddNoteMessage
    >({
        mutationKey: ["add_message", noteId],
        mutationFn: (data: IAddNoteMessage) => addNoteMessages(noteId, data),
    });
}

export function useFlagNote() {
    interface IFlagNoteResponse {
        updated_notes: string[];
        unupdated_notes: string[];
    }

    return useMutation<
        IApiResponse<IFlagNoteResponse>,
        AxiosError<IApiResponse<IFlagNoteResponse>>,
        IFlagNote
    >({
        mutationKey: ["flag-note"],
        mutationFn: (data: IFlagNote) => flagNote(data),
    });
}

import * as React from "react";
import Button from "../Button/Button";
import NavArrowUpIcon from "../Icons/NavArrowUp";
import styles from "./ScrollToTopButton.module.scss";

export default function ScrollToTopButton() {
    const [show, setShow] = React.useState(false);

    const mainWrapper = document.querySelector("#main");
    const handleScrollToTop = () => {
        mainWrapper?.scrollIntoView({ behavior: "smooth" });
    };

    React.useEffect(() => {
        const handleShowButton = () => {
            if (window.scrollY > 600) {
                setShow(true);
            } else {
                setShow(false);
            }
        };

        window.addEventListener("scroll", handleShowButton);

        return () => window.removeEventListener("scroll", handleShowButton);
    }, []);

    if (show) {
        return (
            <Button
                className={styles.scrollbtn}
                type="button"
                mode="transparent"
                size="auto"
                onClick={handleScrollToTop}
            >
                <NavArrowUpIcon />
            </Button>
        );
    }

    return null;
}

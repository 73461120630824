export enum AppNotificationType {
    ASSIGNED_TODO = "assigned_todo",
    NEW_NOTE_MESSAGE = "new_note_message",
}

export interface AppNotification {
    id: string;
    subject: string;
    description: string;
    sent_at: string;
    read_at?: Date;
    is_read?: boolean;
    type: AppNotificationType;
    recipient_user_id: string;
    due_at?: Date;
    metadata?: {
        todo_id?: string;
        message_id?: string;
        note_id?: string;
        client_id?: string;
        client_first_name?: string;
    };
}

import { SvgProps } from "../../utils/types";

export default function DocumentCopyIcon({ className }: SvgProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            className={className}
        >
            <path
                d="M8.5 6.7V8.2C8.5 10.2 7.7 11 5.7 11H3.8C1.8 11 1 10.2 1 8.2V6.3C1 4.3 1.8 3.5 3.8 3.5H5.3"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.50078 6.7H6.90078C5.70078 6.7 5.30078 6.3 5.30078 5.1V3.5L8.50078 6.7Z"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.80078 1H7.80078"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.5 2.5C3.5 1.67 4.17 1 5 1H6.31"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.0007 4V7.095C11.0007 7.87 10.3707 8.5 9.5957 8.5"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11 4H9.5C8.375 4 8 3.625 8 2.5V1L11 4Z"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

import axios from "../../../utils/axios";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    IEditProviderInfo,
    IProviderCaseloadFilters,
    IProvidersFilterTypes,
} from "../types/providers.types";

// Endpoint to fetch provider's caseload
export async function fetchProviderCaseload(params: IProviderCaseloadFilters) {
    const response = await axios.get(`/${params.providerId}/caseload`, {
        params: handleRemoveEmptyParamFromQueryParams(params),
    });

    return response.data;
}

// Endpoint to fetch Supervisees caseload
export async function fetchSuperviseesCaseload(
    providerId: string,
    search_string?: string
) {
    const response = await axios.get(`/provider/${providerId}/supervisee`, {
        params: handleRemoveEmptyParamFromQueryParams({
            search_string,
        }),
    });
    return response.data;
}

export async function fetchProviders(filters: IProvidersFilterTypes) {
    const response = await axios.get(`/providers`, {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });
    return response.data;
}

export async function updateProviderInfo(
    providerId: string,
    data: IEditProviderInfo
) {
    const response = await axios.patch(`/providers/${providerId}`, data);
    return response.data;
}

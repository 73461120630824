import { Tag, Tabs } from "@jhool-io/fe-components";
import { Link } from "react-router-dom";
import PendingTasks from "../PendingTasks/PendingTasks";
import PhoneIcon from "../../../../components/Icons/Phone";
import MapIcon from "../../../../components/Icons/Map";
import UserWithLaptopIcon from "../../../../components/Icons/UserWithLaptop";
import HealthIcon from "../../../../components/Icons/Health";
import SessionNotes from "../SessionNotes/SessionNotes";
import { useFetchClientBasicAndPersonalInfo } from "../../../../hooks/queries/client";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import UserStrokeIcon from "../../../../components/Icons/UserStroke";
import { removeEnumUnderscore } from "../../../../utils/helpers";

interface ClientInfoProps {
    /** client to display info id */
    scheduleInfo: {
        clientId: string;
        isPaused: boolean;
        isPending: boolean;
    };
}

export default function ClientInfo({ scheduleInfo }: ClientInfoProps) {
    const clientBasicDetails = useFetchClientBasicAndPersonalInfo(
        scheduleInfo.clientId
    );

    const tabItems = [
        {
            key: "tasks",
            label: "Tasks",
            children: (
                <div className="mt-40">
                    <PendingTasks clientId={scheduleInfo.clientId} />
                </div>
            ),
        },
        {
            key: "session-notes",
            label: "Session notes",
            children: <SessionNotes clientId={scheduleInfo.clientId} />,
        },
    ];

    const client = clientBasicDetails?.data?.data;

    return (
        <>
            <div className="py-24 px-32 border-b border-b-strokedark mb-32">
                {clientBasicDetails.isLoading && (
                    <div className="flex items-center justify-between mb-40">
                        <div className="flex flex-col gap-y-8 w-1/2">
                            <Skeleton
                                containerTestId="loader"
                                className="h-24"
                                width="50%"
                            />
                            <Skeleton
                                containerTestId="loader"
                                className="h-24"
                                width="20%"
                            />
                        </div>
                        <Skeleton
                            containerTestId="loader"
                            className="h-40 w-[20%]"
                        />
                    </div>
                )}
                {clientBasicDetails.error && (
                    <div className="flex items-center justify-center font-semibold text-sm text-danger">
                        <p>
                            {clientBasicDetails.error.response?.data.message ||
                                "Could not load client details at this time"}
                        </p>
                    </div>
                )}
                {clientBasicDetails.data && clientBasicDetails.isSuccess && (
                    <div className="flex justify-between items-center">
                        <div className="flex flex-col justify-center">
                            <h3 className="text-xxl font-bold capitalize">{`${clientBasicDetails.data.data.first_name} ${clientBasicDetails.data.data.last_name}`}</h3>
                            {(scheduleInfo.isPending ||
                                scheduleInfo.isPaused) && (
                                <div className="flex gap-x-4 items-center mt-8">
                                    {scheduleInfo.isPending && (
                                        <Tag
                                            bgColor="#FBC7C6"
                                            textColor="#981F41"
                                            title="Pending Task"
                                            className="text-xs py-4 px-8"
                                        />
                                    )}
                                    {scheduleInfo.isPaused && (
                                        <Tag
                                            bgColor="#F7E5A4"
                                            textColor="#634D17"
                                            title="Paused"
                                            className="text-xs py-4 px-8"
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                        <Link
                            to={`/clients/${scheduleInfo.clientId}`}
                            className="py-[10px] px-32 rounded-r8 bg-[#b9baa333] text-sm text-gray text-semibold"
                        >
                            Go to full client chart
                        </Link>
                    </div>
                )}
            </div>
            {clientBasicDetails.data && clientBasicDetails.isSuccess && (
                <div className="px-32 grid grid-cols-[repeat(5,1fr)] gap-x-32 mb-40 text-wrap">
                    <div className="flex flex-col items-start">
                        <span className="mb-16">
                            <UserStrokeIcon />
                        </span>
                        <span className="text-gray text-xs font-medium uppercase mb-4">
                            PREFERRED NAME
                        </span>
                        <span className="text-sm font-regular">{`${
                            client?.preferred_name || "--"
                        } (${client?.age}years)`}</span>
                    </div>
                    <div className="flex flex-col items-start">
                        <span className="mb-16">
                            <PhoneIcon />
                        </span>
                        <span className="text-gray text-xs font-medium uppercase mb-4">
                            CONTACT & EMAIL
                        </span>
                        <span className="text-sm font-regular">{`${client?.contact}, ${client?.email}`}</span>
                    </div>
                    <div className="flex flex-col items-start">
                        <span className="mb-16">
                            <MapIcon />
                        </span>
                        <span className="text-gray text-xs font-medium uppercase mb-4">
                            Address
                        </span>
                        <span className="text-sm font-regular">
                            {client?.address}
                        </span>
                    </div>
                    <div className="flex flex-col items-start">
                        <span className="mb-16">
                            <UserWithLaptopIcon />
                        </span>
                        <span className="text-gray text-xs font-medium uppercase mb-4">
                            INSURANCE PROVIDER
                        </span>
                        <span className="text-sm font-regular uppercase">
                            {client?.insurance_provider
                                ? removeEnumUnderscore(
                                      client.insurance_provider
                                  )
                                : "--"}
                        </span>
                    </div>
                    <div className="flex flex-col items-start">
                        <span className="mb-16">
                            <HealthIcon />
                        </span>
                        <span className="text-gray text-xs font-medium uppercase mb-4">
                            DIAGNOSIS
                        </span>
                        <span className="text-sm font-regular">
                            {client?.latest_diagnosis.description || "--"}
                        </span>
                    </div>
                </div>
            )}
            <div className="px-32">
                <Tabs className="w-full mb-0" size="medium" items={tabItems} />
            </div>
        </>
    );
}

import { Input } from "@jhool-io/fe-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams, useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { IAppCustomDates } from "../../../../utils/types";
import useToast from "../../../../hooks/useToast";
import { useSendStatementOfAccount } from "../../hooks/mutations/reports.mutations";

interface SendStatementOfAccountFormProps {
    onFormSubmit: () => void;
    defaultEmail?: string;
}

interface FormProps {
    emails: string;
}

// Regular expression for validating a list of emails separated by commas
const emailListRegex =
    /^([\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7})(,\s*[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7})*$/;

const schema = yup.object({
    emails: yup
        .string()
        .matches(
            emailListRegex,
            "Invalid format, please enter comma separated emails"
        ),
});

export default function SendClientStatementOfAccountForm({
    onFormSubmit,
    defaultEmail,
}: SendStatementOfAccountFormProps) {
    const [searchParams] = useSearchParams();

    const params = useParams();

    const { clientId } = params;

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormProps>({
        resolver: yupResolver(schema),
    });

    const sendStatement = useSendStatementOfAccount({
        action: "email",
        client_ids: [clientId as string],
        insurance_provider_id:
            searchParams.get("insurance_provider") || undefined,
        custom_date:
            (searchParams.get("custom_date") as IAppCustomDates) || undefined,
        from_date: searchParams.get("from_date")
            ? format(
                  new Date(searchParams.get("from_date") as string),
                  "yyyy-MM-dd"
              )
            : null,
        to_date: searchParams.get("to_date")
            ? format(
                  new Date(searchParams.get("to_date") as string),
                  "yyyy-MM-dd"
              )
            : null,
        only_coins_outstanding: searchParams.get("only_coins") === "yes",
    });

    const { toast } = useToast();

    const splitEmails = (input: string) => {
        // Split the input string by commas and trim each email
        const emails = input.split(",").map((email) => email.trim());

        return emails;
    };

    const onSubmit = (payload: FormProps) => {
        const dataToSend = {
            emails: splitEmails(payload.emails),
        };
        sendStatement.mutate(dataToSend, {
            onSuccess: (res) => {
                toast({
                    mode: "success",
                    message:
                        res.message || "Statement of account sent successfully",
                });
                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not update send statement of account at this time",
                });
            },
        });
    };

    return (
        <form
            id={
                searchParams.get("only_coins") === "yes"
                    ? "send-coins-attement"
                    : "send-statement"
            }
            onSubmit={handleSubmit(onSubmit)}
        >
            <Input
                {...register("emails")}
                label="Email(Add multiple emails seperated by comma)"
                placeholder="Add multiple emails seperated by comma"
                defaultValue={defaultEmail}
                hasError={!!errors.emails}
                errorText={errors.emails?.message}
            />
        </form>
    );
}

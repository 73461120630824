import { useEffect, useState } from "react";
import { RRule } from "rrule";
import {
    Button,
    Tag,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
} from "@jhool-io/fe-components";
import { CalenderEvents, RecurrenceRule } from "../../types/schedule.types";
import MoreIcon from "../../../../components/Icons/More";
import {
    doesClientRequireConsultCall,
    getNumberOfWeeks,
} from "../../helpers/schedule.helpers";
import moment from "../../../../utils/moment";
import { getColorStylesForCalendarId } from "../../../../utils/constants";
import BigCalendar from "../Calendar/BigCalender";

interface CalenderBoxViewProps {
    schedules?: CalenderEvents[];
}

function CustomDayEvent({ event }: { event: CalenderEvents }) {
    return (
        <div className="rbc-timeslot-group">
            <div className="rbc-display_name">{event.client_display_name}</div>
            <div className="rbc-requested-service">
                {event.requested_service}
            </div>
            <div className="rbc-wrapper">
                <div style={{ marginRight: 4 }}>
                    <Tag
                        bgColor={
                            getColorStylesForCalendarId(
                                event.recurrenceRule as string
                            ).tagBgColor
                        }
                        textColor={
                            getColorStylesForCalendarId(
                                event.recurrenceRule as string
                            ).textColor
                        }
                        title={event.recurrenceRule as string}
                        className="text-[8px]"
                    />
                </div>

                {(event.clinician_contacted_client ||
                    doesClientRequireConsultCall(event)) && (
                    <div style={{ margin: "0 4px 0 0" }}>
                        <Tag
                            bgColor="#FFDAD9"
                            textColor="#981F41"
                            title="Pending Task"
                            className="text-[8px]"
                        />
                    </div>
                )}
                {event.is_paused && (
                    <Tag
                        bgColor="#FFF3C7"
                        textColor="#926B0F"
                        title="Paused"
                        className="text-[8px]"
                    />
                )}
            </div>
            <div
                className="fg fg-space-between two flex"
                style={{ marginTop: 12 }}
            >
                <p>
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <Button size="auto" variant="normal">
                                <MoreIcon />
                            </Button>
                        </DropdownTrigger>
                        <DropdownContent width={180} align="end">
                            <DropdownItem>Change appointment</DropdownItem>
                            <DropdownItem>Cancel next session</DropdownItem>
                            <DropdownItem>Reschedule appointment</DropdownItem>
                        </DropdownContent>
                    </Dropdown>
                </p>
            </div>
        </div>
    );
}

export default function ScheduleDayView({ schedules }: CalenderBoxViewProps) {
    const [events, setEvents] = useState([] as CalenderEvents[]);

    // the details of the clicked event
    const [
        ,
        // eventDetails
        setEventDetails,
    ] = useState<CalenderEvents>({} as CalenderEvents);

    const eventStyleGetter = (event: CalenderEvents) => {
        const { recurrenceRule } = event;
        const colorStyles = getColorStylesForCalendarId(
            recurrenceRule as string
        );
        const style = {
            background: colorStyles.backgroundColor,
            borderLeft: `2px solid ${colorStyles.color} !important`,
            fontSize: 14,
        };
        return {
            style,
        };
    };

    const components = {
        day: {
            event: CustomDayEvent,
        },
    };

    useEffect(() => {
        const generateEvents = async () => {
            const allEvents = [] as CalenderEvents[];
            schedules?.forEach((schedule) => {
                const numberOfWeeks = getNumberOfWeeks(schedule.recurrenceRule);
                const startingMoment = moment(schedule.start);
                let endDate = schedule.ending_date
                    ? moment(schedule.ending_date)
                    : null;

                let occurrences: Date[] = [];
                let recurrenceRule;

                if (
                    endDate &&
                    schedule.recurrenceRule !== RecurrenceRule.ONE_OFF
                ) {
                    recurrenceRule = {
                        freq: RRule.WEEKLY,
                        interval: numberOfWeeks,
                        dtstart: startingMoment.toDate(),
                    };
                }
                if (
                    !endDate &&
                    schedule.recurrenceRule !== RecurrenceRule.ONE_OFF
                ) {
                    // If there is no ending date and it's not a one-off event,
                    // set a default count to end at the last day of the current year to prevent an infinite loop
                    recurrenceRule = {
                        freq: RRule.WEEKLY,
                        interval: numberOfWeeks,
                        dtstart: startingMoment.toDate(),
                    };
                    endDate = moment().endOf("year");
                }
                if (schedule.recurrenceRule === RecurrenceRule.ONE_OFF) {
                    occurrences.push(startingMoment.toDate());
                } else {
                    const rruleOptions = {
                        ...recurrenceRule,
                        until: (endDate as moment.Moment).toDate(),
                    };

                    occurrences = new RRule(rruleOptions).all();
                }

                occurrences.forEach((date: Date) => {
                    allEvents.push({
                        ...schedule,
                        start: moment(date)
                            .set({
                                hour: moment(schedule.start).hours(),
                                minute: moment(schedule.start).minutes(),
                            })
                            .toDate(),
                        end: moment(date)
                            .set({
                                hour: moment(schedule.end).hours(),
                                minute: moment(schedule.end).minutes(),
                            })
                            .toDate(),
                    });
                });
            });

            setEvents(allEvents);
        };

        generateEvents();
    }, [schedules]);

    return (
        <BigCalendar
            events={events}
            views={["day"]}
            components={components}
            setEventDetails={setEventDetails}
            eventStyleGetter={eventStyleGetter}
        />
    );
}

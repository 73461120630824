/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Table,
} from "@jhool-io/fe-components";
import { ref, remove } from "firebase/database";
import { Navigate, useSearchParams } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import useToast from "../../../../hooks/useToast";
import { UserRole } from "../../../user-management/types/user-management.types";
import { rtdb } from "../../../../firebase";
import { IThirtydaysProvider } from "../../types/thirtydays-providers";
import { formatDate } from "../../../../utils/helpers";
import MoreButton from "../../../../shared-ui/Buttons/MoreButton/MoreButton";
import useThirtydaysProviders from "../../../../hooks/useThirtydaysProviders";
import ListState from "../../../../components/ListState/ListState";
import NewThirtydaysProviderForm from "../NewThirtydaysProviderForm/NewThirtydaysProviderForm";
import { useAppSelector } from "../../../../hooks/useRedux";

type DialogTypes = "add" | "delete";

export default function ThirtydaysProvidersList() {
    const [dialogInView, setDialogInView] = React.useState<DialogTypes | null>(
        null
    );
    const [providerInViewId, setProviderInViewId] = React.useState<
        string | null
    >(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const addingProvider = searchParams.get("adding_provider");

    const { practice } = useAppSelector((state) => state.userPractice);

    const { providers } = useThirtydaysProviders();

    const { toast } = useToast();

    if (!practice?.roles.includes(UserRole.SUPER_ADMIN)) {
        Navigate({ to: "/", replace: true });
    }

    const handleCloseDialog = () => {
        setDialogInView(null);

        searchParams.delete("adding_provider");
        setSearchParams(searchParams);
    };

    const handleDeleteProvider = async () => {
        try {
            const dbRef = ref(rtdb, `/thirtydaysproviders/${providerInViewId}`);

            await remove(dbRef);
            toast({
                mode: "success",
                message: "Provider removed successfully",
            });
            setDialogInView(null);
            setProviderInViewId(null);
        } catch {
            toast({
                mode: "error",
                message:
                    "Could not remove provider at this time, please try again later",
            });
        }
    };

    const columns: ColumnDef<IThirtydaysProvider>[] = React.useMemo(
        () => [
            {
                accessorKey: "provider_name",
                header: "Provider name",
                cell: ({ row }) => (
                    <span className="capitalize">
                        {row.original.provider_name}
                    </span>
                ),
            },
            {
                accessorKey: "created_at",
                header: "Created at",
                cell: ({ row }) =>
                    row.original.created_at
                        ? formatDate(
                              row.original.created_at,
                              false,
                              "MM/dd/yyyy, hh:mmaaa"
                          )
                        : "--",
            },
            {
                accessorKey: "id",
                header: "",
                cell: ({ row }) => (
                    <Dropdown>
                        <DropdownTrigger>
                            <MoreButton />
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            <DropdownItem
                                onClick={() => {
                                    setProviderInViewId(row.original.id);
                                    setDialogInView("delete");
                                }}
                            >
                                Delete
                            </DropdownItem>
                        </DropdownContent>
                    </Dropdown>
                ),
            },
        ],
        []
    );

    return (
        <>
            <Dialog open={dialogInView === "delete"}>
                <DialogContent
                    handleCloseDialog={() => {
                        setDialogInView(null);
                        setProviderInViewId(null);
                    }}
                    variant="center"
                    title="Delete provider"
                    saveText="Delete provider"
                    onSaveClick={handleDeleteProvider}
                    isDeleting
                >
                    <div>
                        <h3 className="font-medium text-xl mb-8">
                            Are you sure you want to delete provider?
                        </h3>
                        <p className="text-gray font-normal text-base">
                            This will delete this provider from the list and
                            cannot be reversed
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "add"}>
                <DialogContent
                    handleCloseDialog={handleCloseDialog}
                    variant="center"
                    title="Add provider"
                    saveText="Add provider"
                    submitBtnFormValue="add-provider"
                    isSubmitBtnDisabled={Boolean(addingProvider)}
                    isCancelBtnDisabled={Boolean(addingProvider)}
                >
                    <NewThirtydaysProviderForm
                        onFormSubmit={handleCloseDialog}
                    />
                </DialogContent>
            </Dialog>
            <div>
                <div className="mb-32 bg-white p-16 rounded-r8 flex justify-end items-center flex-wrap gap-y-16">
                    <Button onClick={() => setDialogInView("add")}>
                        Add new
                    </Button>
                </div>
                {providers && (
                    <div>
                        {providers.length === 0 && (
                            <ListState
                                isError={false}
                                stateHelperText="No provider added yet, add one to get started"
                                emptyMessage="No provider to show"
                            />
                        )}
                        {providers.length > 0 && (
                            <Table columns={columns} data={providers || []} />
                        )}
                    </div>
                )}
            </div>
        </>
    );
}

export default function AmexIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="800"
            width="1200"
            version="1.0"
            viewBox="-44.36475 -13.741875 384.4945 82.45125"
        >
            <path
                d="M260.45 0c-1.0725 0-1.9412.8688-1.9412 1.94v13.6063H294.45L278.0825.0013 260.45 0m35.315 19.6625a1.9339 1.9339 0 00-1.9412-1.9387h-13.6026v35.9424l15.5375-16.375.006-17.6287M276.1 54.9675c1.07 0 1.9387-.87 1.9387-1.9437V39.4225h-35.9425l16.37 15.5425 17.6338.002m-35.3175-19.6595c0 1.0725.8687 1.9463 1.9437 1.9463h13.6V1.3088l-15.5412 16.37-.003 17.6287"
                fill="#117aca"
            />
            <path
                d="M76.0413 6.5613V23.16h-23.125V6.5613l-8.4226-.0075v41.8525h8.4226v-17.98h23.125v17.98h8.4362V6.5613h-8.4362m112.0462 0v41.835h36.9513l-4.68-7.365h-23.825v-10.605h23.07v-7.0938h-23.07v-9.5787h23.785l4.585-7.1925h-36.8163M13.1862 6.5512C4.425 6.5512 0 11.8775 0 19.6275V35.25c0 8.9687 5.7025 13.1563 13.1425 13.1563l26.425-.003-4.8988-7.65H14.775c-4.2263 0-6.0675-1.5275-6.0675-6.2576V20.3225c0-4.5725 1.55-6.3562 6.18-6.3562h19.9887l4.7038-7.415H13.1862m137.9413-.01c-5.06 0-10.2463 3.0412-10.2463 10.8637v2.01c0 8.155 4.99 11.2662 9.9826 11.2875h17.5237c1.82 0 3.2987.3025 3.2987 3.3787l-.002 3.5538c-.0462 2.7637-1.425 3.3963-3.3587 3.3963h-23.0538l-4.7225 7.365h28.3188c6.8288 0 11.465-3.3975 11.465-11.245v-2.8838c0-7.5875-4.3075-11.1725-10.9862-11.1725h-16.7326c-1.855 0-3.1475-.5063-3.1475-3.2537v-2.8938c0-2.34.8863-3.1937 3.0475-3.1937l21.955-.0088 4.5888-7.2138-27.93.01m-42.1805.0201L89.1038 48.4038h9.3787l3.865-8.6413h21.5637l3.8525 8.6413h9.42l-19.89-41.8425zm4.1612 8.6875l7.625 17.3912h-15.2012l7.5762-17.3912"
                fill="#211e1e"
            />
        </svg>
    );
}

import * as React from "react";
import { Button } from "@jhool-io/fe-components";
import { cn } from "../../../utils/helpers";
import ChevronDownIcon from "../../../components/Icons/ChevronDown";
import { APP_COLORS } from "../../../utils/constants";

interface FilterButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    // Button text
    text: string;
    // Extra classnames for styling
    classNames?: string;
    // Icon stroke color
    stroke?: string;
}

const FilterButton = React.forwardRef<HTMLButtonElement, FilterButtonProps>(
    ({ text, classNames, stroke, ...props }, ref) => (
        <Button
            size="auto"
            variant="normal"
            className={cn(
                "px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium self-start md:self-center capitalize bg-white",
                classNames
            )}
            ref={ref}
            {...props}
        >
            {text}
            <ChevronDownIcon stroke={stroke || APP_COLORS.COLOR_BLACK} />
        </Button>
    )
);

FilterButton.displayName = "FilterButton";
export default FilterButton;

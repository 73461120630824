import React from "react";
import { Button } from "@jhool-io/fe-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar";
import StatementOfAccountPreview from "../components/StatementOfAccountPreview/StatementOfAccountPreview";
import AgingReportWrapper from "../components/AgingReportWrapper/AgingReportWrapper";

export default function BillingReports() {
    const [searchParams, setSearchParams] = useSearchParams();

    const reportInUrl = searchParams.get("report") || "statement_of_account";

    const navigate = useNavigate();

    const handleMenuItemClick = (key: string) => {
        searchParams.set("report", key);
        setSearchParams(searchParams);
        navigate(`/reports/billing-reports?report=${key}`);
    };

    const menuItems = [
        {
            name: "Statement of account",
            disabled: false,
            key: "statement_of_account",
        },
        { name: "Aging report", disabled: false, key: "aging_report" },
        {
            name: "Pending payment resolution",
            disabled: true,
            key: "pending_payment_resolution",
        },
        {
            name: "Insurance reimbursement",
            disabled: true,
            key: "insurance_reimbursement",
        },
        { name: "End of month", disabled: true, key: "end_of_month" },
        {
            name: "Patient demographic",
            disabled: true,
            key: "patient_demographic",
        },
        {
            name: "Credits and reversals",
            disabled: true,
            key: "credits_reversals",
        },
        {
            name: "Insurance client list",
            disabled: true,
            key: "insurance_clients",
        },
    ];

    return (
        <>
            <Navbar title="Statement of Account" />
            <div className="page">
                <div className="text-[24px] w-[303px] font-semibold leading-[32px]">
                    Generate and download statement of accounts
                </div>

                <div className="mt-32 flex gap-x-[43px] w-auto overflow-hidden">
                    <div className="shrink-0 ">
                        {menuItems.map((item, index) => (
                            <Button
                                variant="normal"
                                mode="outline"
                                key={`${index + 1}`}
                                size="auto"
                                className="flex items-start justify-between pb-24 gap-x-12"
                                onClick={() =>
                                    !item.disabled &&
                                    handleMenuItemClick(item.key)
                                }
                                disabled={item.disabled}
                            >
                                <div
                                    className={`flex flex-col text-sm ${
                                        reportInUrl === item.key
                                            ? "font-bold "
                                            : "font-normal "
                                    }    ${
                                        reportInUrl === item.key
                                            ? "text-primary"
                                            : "text-gray"
                                    } relative items-start before:content-[''] before:size-4 before:rounded-full ${
                                        reportInUrl === item.key
                                            ? "before:bg-primary"
                                            : "before:bg-gray"
                                    } after:content-[''] before:absolute before:top-[7px] before:left-0 ${
                                        index !== menuItems.length - 1 &&
                                        "after:bg-strokelight"
                                    }  after:w-px after:h-[2.8rem] after:absolute after:left-[1.5px] after:top-[20px] pl-24 `}
                                >
                                    {item.name}
                                </div>
                            </Button>
                        ))}
                    </div>

                    <div className="flex-1 overflow-x-auto overflow-hidden">
                        {reportInUrl === "statement_of_account" && (
                            <StatementOfAccountPreview />
                        )}
                        {reportInUrl === "aging_report" && (
                            <AgingReportWrapper />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

import { IAppCustomDates } from "../../../utils/types";
import { WriteOffCodes } from "../../../utils/types/billing";
import { ClaimStatus } from "../../claims/types/claims.types";

export interface AdjustmentProps {
    no: string;
    group: string;
    description: string;
    amount: number;
    quantity: number;
    total: number;
}

export interface InsuranceProps {
    description: string;
    payment_date: string;
    payment_type: string;
    amount: number;
    payment_method: string;
    date_posted: string;
    payment_notes: string;
    bill_status: string;
    auth_code: string;
    payment_status: string;
}

export enum RemitStatus {
    POSTED = "posted",
    UN_POSTED = "unposted",
}

export enum RemitSource {
    MANUAL = "Manual",
    ELECTRONIC = "Electronic",
}

export interface IBatchRemit {
    remittance_batch_id: string;
    insurance_provider_name: string;
    remit_received_date: string;
    remit_batch_status: RemitStatus;
    remit_source: RemitSource;
    remit_amount: number;
    no_of_claims_payments: number;
    is_system_created: boolean;
    no_of_unmatched_payments: number;
    no_of_matched_payments: number;
    remit_batch_payment_ref: string;
    remit_batch_ref_id: string;
    practice_id: string;
    no_of_claims_payments_from_file: number;
    amount_of_unmatched_payments: number;
    remit_batch_eob_url: string;
    no_of_claims: number;
}

export interface IBatchRemitFilters {
    batch_remit_status?: string;
    batch_remit_id?: string;
    has_payments?: string;
    has_claims?: string;
    insurance_provider_name?: string;
    from_date?: string | null;
    to_date?: string | null;
    page?: number;
    limit?: number;
    sort_attribute?: string;
    sort_order?: string;
    remit_source?: string;
    search_string?: string;
    dos_from_date?: string | null;
    dos_to_date?: string | null;
}
export interface IAddManualRemit {
    remit_batch_payment_ref: string;
    insurance_provider_id: string;
    no_of_claims_payments: number;
    remit_date: string;
    amount: number;
}

export interface IAddRemitClaims {
    claim_ids: string[];
}

export interface IClaimsReport {
    claim_id: string;
    reason: string;
}
export interface IAddRemitResponse {
    message: string;
    remittance_batch_id: string;
    added_claims: string[];
    unadded_claims: IClaimsReport[];
}
export interface IClaimAdjustments {
    quantity: number;
    group_code: string;
    amount: number;
    reason_code: string;
    reason_description: string;
    amount_2: number;
    reason_code_2: string;
    reason_description_2: string;
}

export interface IInsurancePayments {
    payment_id: string;
    payment_description: string;
    date_posted: string;
    payment_type: string;
    payment_date: string;
    payment_method: string;
    amount: number;
    payment_notes: string;
    invoice_status: string;
    auth_code: string;
    processor_payment_status: string;
    payment_status: string;
    writeoff_code: WriteOffCodes;
    remark_codes: string[];
    payment_info?: {
        refund_reason?: string;
        cheque_number?: string;
        authorization_number?: string;
    };
    posted_by: {
        first_name: string;
        last_name: string;
    };
    eft_number?: string;
}

export interface IRemit {
    is_archived: boolean;
    archived_notes: string | null;
    remittance_batch_id: string;
    remittance_id: string;
    claim_id: string;
    claim_status_code: string;
    claim_status_description: string;
    remit_payment_date: string;
    date_of_service: string;
    claim_number: string;
    claim_status: ClaimStatus;
    client: {
        first_name: string;
        last_name: string;
        client_id: string;
    };
    cpt_code: string;
    charged: number;
    note_id: string;
    paid_amount: number;
    ucr?: string;
    outstanding: number;
    expected_amount: number;
    patient_responsibility_amount: number;
    remit_status: RemitStatus;
    invoice_id: string;
    era_blob: string;
    has_eob: boolean;
    claim_adjustments: IClaimAdjustments[];
    insurance_payments: IInsurancePayments[];
    insurance_provider_name: string;
    insurance_expected: number;
    patient_paid: number;
    patient_outstanding: number;
    insurance_outstanding: number;
    insurance_charged: number;
    insurance_paid: number;
    patient_charged: number;
    total_outstanding: number;
    patient_writeoff: number;
    insurance_writeoff: number;
    patient_accounting: number;
    insurance_accounting: number;
}

export interface IRemitsFilters {
    batch_remit_id?: string;
    note_id?: string;
    search_string?: string;
    claim_status?: string;
    remittance_id?: string;
    page?: number;
    limit?: number;
    claim_id?: string;
    sort_attribute?: string;
    sort_order?: string;
    from_date?: string | null;
    to_date?: string | null;
    custom_date?: IAppCustomDates;
    show_archived_remits?: string;
}

export interface IUpdateRemitStatus {
    remittance_batch_ids: string[];
    status: string;
}

export interface IRemitDocument {
    remittance_attachment_id: string;
    url: string;
    name: string;
    metadata: {
        content_type: string;
    } | null;
}

export interface IArchiveRemitPayload {
    remit_ids_to_archive: string[];
    archive_notes?: string;
    archive_status: boolean;
}

export interface IDeleteManualBatchRemit {
    batch_remit_ids: string[];
}

import { SvgProps } from "../../utils/types";

export default function InfoCircleIcon({ fill }: SvgProps) {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6 5.5C5.86739 5.5 5.74022 5.55268 5.64645 5.64645C5.55268 5.74021 5.5 5.86739 5.5 6V8C5.5 8.13261 5.55268 8.25979 5.64645 8.35355C5.74022 8.44732 5.86739 8.5 6 8.5C6.13261 8.5 6.25979 8.44732 6.35356 8.35355C6.44732 8.25979 6.5 8.13261 6.5 8V6C6.5 5.86739 6.44732 5.74021 6.35356 5.64645C6.25979 5.55268 6.13261 5.5 6 5.5ZM6.19 3.54C6.06827 3.48999 5.93173 3.48999 5.81 3.54C5.74863 3.5638 5.69255 3.59948 5.645 3.645C5.60084 3.6936 5.56532 3.74941 5.54 3.81C5.51201 3.86934 5.49831 3.93441 5.5 4C5.49962 4.0658 5.51223 4.13103 5.53712 4.19195C5.562 4.25287 5.59866 4.30828 5.645 4.355C5.6936 4.39917 5.74941 4.43468 5.81 4.46C5.88575 4.49112 5.96798 4.50316 6.04948 4.49506C6.13097 4.48695 6.20922 4.45896 6.27736 4.41353C6.3455 4.36811 6.40144 4.30664 6.44026 4.23454C6.47908 4.16243 6.49959 4.08189 6.5 4C6.49816 3.86762 6.44637 3.74082 6.355 3.645C6.30745 3.59948 6.25138 3.5638 6.19 3.54ZM6 1C5.0111 1 4.0444 1.29324 3.22215 1.84265C2.39991 2.39206 1.75904 3.17295 1.3806 4.08658C1.00217 5.00021 0.90315 6.00555 1.09608 6.97545C1.289 7.94536 1.76521 8.83627 2.46447 9.53553C3.16373 10.2348 4.05465 10.711 5.02455 10.9039C5.99446 11.0969 6.99979 10.9978 7.91342 10.6194C8.82705 10.241 9.60794 9.6001 10.1573 8.77785C10.7068 7.95561 11 6.98891 11 6C11 5.34339 10.8707 4.69321 10.6194 4.08658C10.3681 3.47995 9.99983 2.92876 9.53554 2.46447C9.07124 2.00017 8.52005 1.63188 7.91342 1.3806C7.30679 1.12933 6.65661 1 6 1ZM6 10C5.20888 10 4.43552 9.7654 3.77772 9.32588C3.11992 8.88635 2.60723 8.26164 2.30448 7.53073C2.00173 6.79983 1.92252 5.99556 2.07686 5.21964C2.2312 4.44371 2.61216 3.73098 3.17158 3.17157C3.73099 2.61216 4.44372 2.2312 5.21964 2.07686C5.99557 1.92252 6.79983 2.00173 7.53074 2.30448C8.26164 2.60723 8.88635 3.11992 9.32588 3.77772C9.76541 4.43552 10 5.20887 10 6C10 7.06087 9.57858 8.07828 8.82843 8.82843C8.07828 9.57857 7.06087 10 6 10Z"
                fill={fill || "#2E76FF"}
            />
        </svg>
    );
}

import { Tabs } from "@jhool-io/fe-components";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Navbar from "../../../../components/Navbar/Navbar";
import MatchedPaymentsList from "../MatchedPaymentsList/MatchedPaymentsList";
import UnmatchedPaymentsList from "../UnmatchedPaymentsList/UnmatchedPaymentsList";
import { IUnmatchedPaymentFilterTypes } from "../../types/payments.types";
import { useFetchUnmatchedPayment } from "../../hooks/payments.queries";

const unMatchedFiltersInitialState: IUnmatchedPaymentFilterTypes = {
    search_string: "",
    custom_date: "",
    invoice_status: "",
    page: 1,
    limit: 20,
    to_date: null,
    from_date: null,
};

export default function PaymentsListWrapper() {
    const [searchParams] = useSearchParams();
    const [totalMatchedCount, setTotalMatchedCount] = React.useState("");
    const [totalUnMatchedCount, setTotalUnMatchedCount] = React.useState("");

    const navigate = useNavigate();

    const unmatchedPaymnetCount = useFetchUnmatchedPayment(
        unMatchedFiltersInitialState
    );

    let defaultUnMatchedPaymentCount;
    if (unmatchedPaymnetCount.error || unmatchedPaymnetCount.isLoading) {
        defaultUnMatchedPaymentCount = "";
    } else if (unmatchedPaymnetCount.data?.data) {
        defaultUnMatchedPaymentCount =
            unmatchedPaymnetCount.data.total_count === 0 ||
            !unmatchedPaymnetCount.data.total_count
                ? ""
                : String(unmatchedPaymnetCount.data.total_count);
    }

    const tabInUrl = searchParams.get("tab");

    const handleSetTabInUrl = (tab: string) => {
        navigate(`/billing-payments?tab=${tab}`);
    };

    const tabItems = [
        {
            label: "Matched",
            key: "matched",
            children: (
                <MatchedPaymentsList
                    setTotalMatchedCount={setTotalMatchedCount}
                />
            ),
            count: String(totalMatchedCount) || "0",
            onClick: () => handleSetTabInUrl("matched"),
        },
        {
            label: "Unmatched",
            key: "unmatched",
            children: (
                <UnmatchedPaymentsList
                    setTotalUnMatchedCount={setTotalUnMatchedCount}
                />
            ),
            count: totalUnMatchedCount
                ? String(
                      totalUnMatchedCount === "0" ? "0" : totalUnMatchedCount
                  )
                : defaultUnMatchedPaymentCount,
            onClick: () => handleSetTabInUrl("unmatched"),
        },
    ];

    return (
        <>
            <Navbar title="Payments" />
            <div className="page">
                <Tabs
                    items={tabItems}
                    size="medium"
                    className="w-full rounded-b-none"
                    defaultActiveKey={tabInUrl || tabItems[0].key}
                />
            </div>
        </>
    );
}

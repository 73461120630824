export default function UnionUpIcon() {
    return (
        <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.97895 5.14737C7.24737 4.87895 7.73684 4.87895 8.00526 5.14737L10.8474 7.98947C11.1316 8.27368 11.1316 8.73158 10.8474 9.01579C10.5632 9.3 10.1053 9.3 9.82105 9.01579L7.5 6.69474L5.17895 9.01579C5.03684 9.15789 4.84737 9.23684 4.6579 9.23684C4.46842 9.23684 4.27895 9.15789 4.13684 9.01579C3.85263 8.73158 3.85263 8.27368 4.13684 7.98947L6.97895 5.14737Z"
                fill="#576A81"
            />
            <path
                d="M7.5 0C11.6368 0 15 3.36316 15 7.5C15 11.6368 11.6368 15 7.5 15C3.36316 15 0 11.6368 0 7.5C0 3.36316 3.36316 0 7.5 0ZM7.5 13.5474C10.8316 13.5474 13.5474 10.8316 13.5474 7.5C13.5474 4.16842 10.8316 1.45263 7.5 1.45263C4.16842 1.45263 1.45263 4.16842 1.45263 7.5C1.45263 10.8316 4.16842 13.5474 7.5 13.5474Z"
                fill="#576A81"
            />
        </svg>
    );
}

import React from "react";
import cn from "classnames";
import styles from "./Input.module.scss";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    /** Label text */
    label?: string;
    /** Check if the input has error */
    hasError?: boolean;
    /** Displays error message when input has error */
    errorText?: string;
    /** Extra optional classname */
    className?: string;
    /** Input name to link input's label to input */
    name?: string;
    /** Determine if component should be hidden */
    hidden?: boolean;
    /** Is field required, this will display a "*" text beside the label" */
    isRequired?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            label,
            hasError,
            name,
            errorText,
            className,
            hidden,
            isRequired,
            ...props
        },
        ref
    ) => {
        if (!label) {
            return (
                <div hidden={hidden}>
                    <div className={styles.container}>
                        <input
                            className={cn(
                                styles.input,

                                {
                                    [styles.input_nolabel]: !label,
                                    [styles.inputNoError]: hasError === false,
                                    [styles.inputError]: hasError === true,
                                    [styles.input_required]: isRequired,
                                },
                                className
                            )}
                            aria-label={label}
                            id={name}
                            name={name}
                            {...props}
                            ref={ref}
                            placeholder={
                                isRequired
                                    ? `* ${props.placeholder}`
                                    : props.placeholder
                            }
                        />
                    </div>
                    {hasError ? (
                        <p className={cn(styles.error)}>{errorText}</p>
                    ) : null}
                </div>
            );
        }

        return (
            <div hidden={hidden}>
                <div className={styles.container}>
                    <input
                        className={cn(
                            styles.input,
                            {
                                [styles.inputNoError]: hasError === false,
                                [styles.inputError]: hasError === true,
                                [styles.input_required]: isRequired,
                            },
                            className
                        )}
                        aria-label={label}
                        id={name}
                        name={name}
                        {...props}
                        ref={ref}
                        placeholder={
                            isRequired
                                ? `* ${props.placeholder}`
                                : props.placeholder
                        }
                    />
                    <label
                        htmlFor={name}
                        className={cn(styles.label, {
                            [styles.label_required]: isRequired,
                        })}
                    >
                        {label}
                    </label>
                </div>
                {hasError ? (
                    <p className={cn(styles.error)}>{errorText}</p>
                ) : null}
            </div>
        );
    }
);

Input.displayName = "Input";

export default Input;

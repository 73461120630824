import React from "react";
import { SvgProps } from "../../utils/types";

export default function CancelIcon({ fill, className }: SvgProps) {
    return (
        <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M0.209292 8.09241C0.0752809 8.22649 0 8.40829 0 8.59786C0 8.78743 0.0752809 8.96924 0.209292 9.10332L0.714746 9.60877C0.848825 9.74278 1.03063 9.81806 1.2202 9.81806C1.40977 9.81806 1.59158 9.74278 1.72566 9.60877L3.99997 7.33445L2.48361 5.81809L0.209292 8.09241ZM7.79066 2.53286L7.2852 2.02741C7.15112 1.8934 6.96931 1.81812 6.77975 1.81812C6.59018 1.81812 6.40837 1.8934 6.27429 2.02741L3.99997 4.30173L5.51634 5.81809L7.79066 3.54377C7.92467 3.40969 7.99995 3.22788 7.99995 3.03832C7.99995 2.84875 7.92467 2.66694 7.79066 2.53286Z"
                fill={fill || "#0B7A75"}
            />
            <path
                d="M7.79066 8.09241C7.92467 8.22649 7.99995 8.40829 7.99995 8.59786C7.99995 8.78743 7.92467 8.96924 7.79066 9.10332L7.2852 9.60877C7.15112 9.74278 6.96931 9.81806 6.77975 9.81806C6.59018 9.81806 6.40837 9.74278 6.27429 9.60877L0.209292 3.54377C0.0752809 3.40969 0 3.22788 0 3.03832C0 2.84875 0.0752809 2.66694 0.209292 2.53286L0.714746 2.02741C0.848825 1.8934 1.03063 1.81812 1.2202 1.81812C1.40977 1.81812 1.59158 1.8934 1.72566 2.02741L7.79066 8.09241Z"
                fill={fill || "#0B7A75"}
            />
        </svg>
    );
}

export default function MenuIcon() {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3 12.3984H21M3 6.39844H21M3 18.3984H21"
                stroke="#0B132B"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

import { useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useQueryClient } from "@tanstack/react-query";
import {
    FamilyIntakeNotePayload,
    INewNoteAppoinmentDetails,
    INewSessionNote,
    NoteTypes,
} from "../../../utils/types/notes";
import {
    CreateNoteFormsPayload,
    SessionServiceType,
} from "../types/notes.types";
import {
    formatZonedTimeToUtc,
    handleFormatDatePickerValue,
    showUserLocalTime,
} from "../../../utils/helpers";
import {
    AppointmentLocation,
    AppointmentLocationTeletherapy,
    SessionPlaceOfServiceCode,
} from "../../../utils/types/session";
import { useFetchUserDetails } from "../../../hooks/queries/user";
import {
    useEditDraftNote,
    useSignSessionNote,
} from "../../../hooks/mutations/note";
import useToast from "../../../hooks/useToast";
import { getNoteListNavigateUrl } from "../helpers/notes.helpers";
import usePractice from "../../../hooks/usePractice";

export function useCreateNote(
    noteAppointmentDetails: INewNoteAppoinmentDetails
) {
    const [searchParams, setSearchParams] = useSearchParams();

    const noteId = searchParams.get("note_id") as string;

    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const { practice } = usePractice();

    const userDetails = useFetchUserDetails();
    const editDraftNote = useEditDraftNote();
    const signDraftNote = useSignSessionNote();

    const { toast } = useToast();

    const validateInputs = (payload: CreateNoteFormsPayload) => {
        let newSessionNotePayload: INewSessionNote;
        let diagnosisCodes: string[] | null = null;
        // we have this field in safety plan note
        let whenShouldFollowUpBeDoneForThisClient: string | null = null;
        // we have this field in termination note
        let lastSessionDate: string | null = null;
        let firstSessionDate: string | null = null;
        let dateOfNextSession: string | null = null;
        let dateOfLastExam: string | null = null;
        let expectedTerminationDate: string | null = null;
        let detailsForDiminishedPoorLimitedQualityPatientOrientedEvidence = "";
        let arrayOfdetailsForDiminishedPoorLimitedQualityPatientOrientedEvidences: string[] =
            [];
        let family: FamilyIntakeNotePayload[] = [];
        let deliveryOption: string | undefined;
        let otherDeliveryOption: string | undefined;
        let otherFrequencyOfSession: string | undefined;
        let providerLocation: string | undefined;
        let otherProviderLocation: string | undefined;
        let consentWasReceivedForTheTeletherapySession: boolean | undefined;
        let theTeletherapySessionWasConductedViaVideo: boolean | undefined;
        let posCode: string | undefined;
        const dateOfService = noteAppointmentDetails?.date_of_service
            ? (noteAppointmentDetails.session_start_time as string)
            : undefined;
        if ("diagnosis_codes" in payload) {
            if (payload.diagnosis_codes && payload.diagnosis_codes.length > 0) {
                diagnosisCodes = payload.diagnosis_codes;
            }
        }
        if ("appointment_location" in payload) {
            if (
                payload.appointment_location === AppointmentLocation.IN_PERSON
            ) {
                deliveryOption = undefined;
                posCode = SessionPlaceOfServiceCode.OFFICE;
                otherDeliveryOption = undefined;
                consentWasReceivedForTheTeletherapySession = undefined;
                theTeletherapySessionWasConductedViaVideo = undefined;
                providerLocation = undefined;
                otherProviderLocation = undefined;
            } else if (
                payload.appointment_location === AppointmentLocation.TELETHERAPY
            ) {
                deliveryOption = payload.delivery_option;
                otherDeliveryOption = payload.other_delivery_option;
                consentWasReceivedForTheTeletherapySession =
                    payload.consent_was_received_for_the_teletherapy_session;
                theTeletherapySessionWasConductedViaVideo =
                    payload.the_teletherapy_session_was_conducted_via_video;
                providerLocation = payload.provider_location;
                otherProviderLocation = payload.other_provider_location;
                if (
                    payload.delivery_option ===
                    AppointmentLocationTeletherapy.HOME
                ) {
                    posCode =
                        SessionPlaceOfServiceCode.TELEHEALTH_PROVIDED_IN_PATIENTS_HOME;
                } else if (
                    payload.delivery_option ===
                        AppointmentLocationTeletherapy.OFFICE ||
                    payload.delivery_option ===
                        AppointmentLocationTeletherapy.OTHER
                ) {
                    posCode =
                        SessionPlaceOfServiceCode.TELEHEALTH_PROVIDED_OTHER_THAN_IN_PATIENTS_HOME;
                }
                if (
                    payload.delivery_option !==
                    AppointmentLocationTeletherapy.OTHER
                ) {
                    otherDeliveryOption = undefined;
                }
                if (
                    payload.provider_location !==
                    AppointmentLocationTeletherapy.OTHER
                ) {
                    otherProviderLocation = undefined;
                }
            }
        }
        if ("frequency_of_sessions" in payload) {
            if (payload.frequency_of_sessions !== "Other") {
                otherFrequencyOfSession = undefined;
            } else {
                otherFrequencyOfSession = payload.other_frequency_of_sessions;
            }
        }
        if ("when_should_follow_up_be_done_for_this_client" in payload) {
            whenShouldFollowUpBeDoneForThisClient =
                payload.when_should_follow_up_be_done_for_this_client
                    ? `${handleFormatDatePickerValue(
                          payload.when_should_follow_up_be_done_for_this_client
                      )}T00:00:00.000`
                    : null;
        }
        if ("last_session_date" in payload) {
            lastSessionDate = payload.last_session_date
                ? formatZonedTimeToUtc(payload.last_session_date)
                : null;
        }
        if ("first_session_date" in payload) {
            firstSessionDate = payload?.first_session_date
                ? formatZonedTimeToUtc(payload?.first_session_date)
                : null;
        }
        if ("date_of_next_session" in payload) {
            dateOfNextSession = payload?.date_of_next_session
                ? formatZonedTimeToUtc(payload?.date_of_next_session)
                : null;
        }
        if ("date_of_last_exam" in payload) {
            dateOfLastExam = payload?.date_of_last_exam
                ? formatZonedTimeToUtc(payload?.date_of_last_exam)
                : null;
        }
        if ("expected_termination_date" in payload) {
            expectedTerminationDate = payload?.expected_termination_date
                ? formatZonedTimeToUtc(payload?.expected_termination_date)
                : null;
        }
        if (
            "patients_ability_and_capability_to_respond_to_treatment" in payload
        ) {
            const shouldHaveDetails =
                payload.patients_ability_and_capability_to_respond_to_treatment
                    ? payload.patients_ability_and_capability_to_respond_to_treatment?.includes(
                          "Diminished/poor or limited-quality patient-oriented evidence"
                      )
                    : false;
            detailsForDiminishedPoorLimitedQualityPatientOrientedEvidence =
                shouldHaveDetails
                    ? payload.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                    : "";
        }
        if ("family" in payload) {
            const arrayOfShouldHaveDetails = payload.family.map((fam) => {
                return (
                    fam.patients_ability_and_capability_to_respond_to_treatment?.includes(
                        "Diminished/poor or limited-quality patient-oriented evidence"
                    ) || false
                );
            });
            arrayOfdetailsForDiminishedPoorLimitedQualityPatientOrientedEvidences =
                payload.family.map((fam, i) =>
                    arrayOfShouldHaveDetails[i]
                        ? fam.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                        : ""
                );
            family = payload.family.map(
                (fam: FamilyIntakeNotePayload, i: number) => ({
                    ...fam,
                    details_for_diminished_poor_limited_quality_patient_oriented_evidence:
                        arrayOfdetailsForDiminishedPoorLimitedQualityPatientOrientedEvidences[
                            i
                        ],
                    family_id: fam?.family_id || uuidv4(),
                })
            );
        }

        // eslint-disable-next-line prefer-const
        newSessionNotePayload = {
            session_id: noteAppointmentDetails?.session_id,
            appointment_type:
                noteAppointmentDetails.appointment_type as SessionServiceType,
            session_history_id:
                noteAppointmentDetails.session_history_id || null,
            client_id: noteAppointmentDetails.client_id,
            provider_id: noteAppointmentDetails.provider_id as string,
            date_of_service:
                dateOfService !== undefined
                    ? formatZonedTimeToUtc(
                          noteAppointmentDetails.session_start_time as string
                      )
                    : null,
            session_start_time: formatZonedTimeToUtc(
                noteAppointmentDetails.session_start_time as string
            ),
            session_end_time: formatZonedTimeToUtc(
                noteAppointmentDetails.session_end_time as string
            ),
            reason_for_short_duration:
                noteAppointmentDetails.reason_for_short_duration,
            pos_code: posCode,
            note_content: {
                ...payload,
                diagnosis_codes: diagnosisCodes || undefined,
                when_should_follow_up_be_done_for_this_client:
                    whenShouldFollowUpBeDoneForThisClient,
                first_session_date: firstSessionDate,
                last_session_date: lastSessionDate,
                date_of_next_session: dateOfNextSession,
                expected_termination_date: expectedTerminationDate,
                date_of_last_exam: dateOfLastExam,
                other_frequency_of_sessions: otherFrequencyOfSession,
                delivery_option: deliveryOption,
                other_delivery_option: otherDeliveryOption,
                provider_location: providerLocation,
                other_provider_location: otherProviderLocation,
                consent_was_received_for_the_teletherapy_session:
                    consentWasReceivedForTheTeletherapySession,
                the_teletherapy_session_was_conducted_via_video:
                    theTeletherapySessionWasConductedViaVideo,
                details_for_diminished_poor_limited_quality_patient_oriented_evidence:
                    detailsForDiminishedPoorLimitedQualityPatientOrientedEvidence,
                family,
            },
            note_type: noteAppointmentDetails.note_type,
            cancelled_by:
                noteAppointmentDetails.note_type === NoteTypes.CANCELLATION_NOTE
                    ? userDetails.data?.user_id
                    : undefined,
        };
        if (noteAppointmentDetails.note_type === NoteTypes.SAFETY_PLAN) {
            newSessionNotePayload.note_content.when_should_follow_up_be_done_for_this_client =
                whenShouldFollowUpBeDoneForThisClient;
        }
        if (noteAppointmentDetails.note_type === NoteTypes.TERMINATION_NOTE) {
            newSessionNotePayload.note_content.first_session_date =
                firstSessionDate;
            newSessionNotePayload.note_content.last_session_date =
                lastSessionDate;
        }
        if (
            noteAppointmentDetails.note_type ===
                NoteTypes.INDIVIDUAL_INTAKE_NOTE ||
            noteAppointmentDetails.note_type ===
                NoteTypes.INDIVIDUAL_TREATMENT_REVIEW ||
            noteAppointmentDetails.note_type ===
                NoteTypes.FAMILY_TREATMENT_REVIEW
        ) {
            newSessionNotePayload.note_content.details_for_diminished_poor_limited_quality_patient_oriented_evidence =
                detailsForDiminishedPoorLimitedQualityPatientOrientedEvidence;
        }
        if (noteAppointmentDetails.note_type === NoteTypes.FAMILY_INTAKE_NOTE) {
            newSessionNotePayload.note_content.family =
                newSessionNotePayload.note_content.family.map(
                    (fam: FamilyIntakeNotePayload, i: number) => ({
                        ...fam,
                        details_for_diminished_poor_limited_quality_patient_oriented_evidence:
                            arrayOfdetailsForDiminishedPoorLimitedQualityPatientOrientedEvidences[
                                i
                            ],
                    })
                );
        }

        const returnedPayload = {
            noteId: noteId as string,
            data: {
                note_content: newSessionNotePayload.note_content,
                date_of_service:
                    newSessionNotePayload.date_of_service as string,
                session_start_time:
                    newSessionNotePayload.session_start_time as string,
                session_end_time:
                    newSessionNotePayload.session_end_time as string,
                reason_for_short_duration:
                    newSessionNotePayload.reason_for_short_duration,
                pos_code: posCode,
            },
        };

        return returnedPayload;
    };

    const handleSaveAsDraftNote = (payload: CreateNoteFormsPayload) => {
        const payloadToSend = validateInputs(payload);

        editDraftNote.mutate(payloadToSend, {
            onSuccess: async () => {
                await queryClient.invalidateQueries({
                    queryKey: [
                        noteAppointmentDetails.client_id,
                        `session-note`,
                        noteId,
                    ],
                });
                queryClient.invalidateQueries({
                    queryKey: [`notes`],
                });

                navigate(
                    `/notes/${noteAppointmentDetails?.client_id}/${noteId}`,
                    { replace: true }
                );
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not edit note at this time",
                });
            },
        });
    };

    const handleAutoSaveNote = (payload: CreateNoteFormsPayload) => {
        const payloadToSend = validateInputs(payload);

        editDraftNote.mutate(payloadToSend, {
            onSuccess: async () => {
                await queryClient.invalidateQueries({
                    queryKey: [
                        noteAppointmentDetails.client_id,
                        `session-note`,
                        noteId,
                    ],
                });
                queryClient.invalidateQueries({
                    queryKey: [`notes`],
                });

                searchParams.set("lastSaved", showUserLocalTime());
                setSearchParams(searchParams);
            },
        });
    };

    const handleSignNote = (payload: CreateNoteFormsPayload) => {
        if (noteId) {
            signDraftNote.mutate(
                {
                    noteId,
                    data: {
                        signature: payload.signature as string,
                        signature_date_time: new Date(Date.now()).toISOString(),
                    },
                },
                {
                    onSuccess: async (response) => {
                        toast({
                            mode: "success",
                            message:
                                response.message || "Note signed successfully",
                        });
                        await queryClient.invalidateQueries({
                            queryKey: [
                                practice?.is_supervisor
                                    ? `notes`
                                    : noteAppointmentDetails.client_id,
                                `session-note`,
                                noteId,
                            ],
                        });
                        queryClient.invalidateQueries({
                            queryKey: [`notes`],
                        });
                        navigate(
                            practice?.is_supervisor
                                ? getNoteListNavigateUrl(practice?.roles || [])
                                : `/notes/${noteAppointmentDetails?.client_id}/${noteId}`,
                            { replace: true }
                        );
                    },
                    onError: (err) => {
                        toast({
                            mode: "error",
                            message:
                                err.response?.data.message ||
                                "Could not sign note at this time",
                        });
                    },
                }
            );
        }
    };

    return {
        handleSignNote,
        handleAutoSaveNote,
        handleSaveAsDraftNote,
    };
}

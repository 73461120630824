import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IApiResponse } from "../../../../utils/types/api-response";
import { IAddSettingChanges } from "../../types/practice-settings";
import { addSetting } from "../../services/practice-settings";

export function useAddSettingsChanges() {
    interface SettingResponse {
        message: string;
    }
    return useMutation<
        IApiResponse<SettingResponse>,
        AxiosError<IApiResponse<SettingResponse>>,
        IAddSettingChanges
    >({
        mutationKey: ["add-setting"],
        mutationFn: (payload: IAddSettingChanges) => addSetting(payload),
    });
}

import PatientsBillsList from "../PatientBillsList/PatientsBillsList";
import PaymentsOverview from "../PaymentsOverview/PaymentsOverview";

export default function PaymentTabWrapper() {
    return (
        <div className="flex flex-col gap-y-24">
            <PaymentsOverview />
            <PatientsBillsList />
        </div>
    );
}

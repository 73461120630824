import cn from "classnames";
import ClockIcon from "../../Icons/Clock";
import styles from "./Notification.module.scss";
import { ViewEditorText } from "../../TextEditor/ViewEditorText/ViewEditorText";

interface NotificationProps {
    /** Details of notification */
    details: string;
    /** Title of the notification */
    title: string;
    /** How long ago the notification was sent */
    timeLaspe: string;
    /** Boolean to check if notification has been read or not */
    isUnread: boolean;
    /** Type of notification */
    type: "not_reminder" | "reminder";
    /** Function to call when notification is clicked */
    onClick?: () => void;
    /** Function to call when a notification is hovered */
    onHover?: () => void;
}

export default function Notification({
    details,
    title,
    timeLaspe,
    isUnread,
    type,
    onClick,
    onHover,
}: NotificationProps) {
    return (
        <div>
            <button
                onMouseOver={onHover}
                onFocus={onHover}
                onClick={onClick}
                onKeyDown={(event) => {
                    if (event.key === "Enter" || event.key === " ") {
                        onClick?.();
                    }
                }}
                className={styles.notif}
                aria-label="Notification"
            >
                <div className={styles.inner}>
                    <h3 className={styles.type}>{title}</h3>
                    <h3 className={styles.title}>
                        <ViewEditorText text={details} />
                    </h3>
                    <div
                        className={cn(styles.rt_footer, {
                            [styles.rt_footer_reminder]: type === "reminder",
                        })}
                    >
                        {timeLaspe ? (
                            <>
                                <ClockIcon />
                                <span className={styles.tl}>{timeLaspe}</span>
                            </>
                        ) : null}
                    </div>
                </div>
                <div
                    className={cn(styles.sidebar, {
                        [styles.sidebar_active]: isUnread,
                        [styles.sidebar_reminder]: type === "reminder",
                    })}
                />
            </button>
        </div>
    );
}

import cn from "classnames";
import * as React from "react";
import { useErrorBoundary } from "react-error-boundary";
import styles from "./ContentFallback.module.scss";
import Button from "../../Button/Button";
import MenuIcon from "../../Icons/Menu";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import { toggleNavState } from "../../../redux/features/nav/navSlice";

export default function ContentFallback() {
    const { isOpen } = useAppSelector((state) => state.nav);
    const dispatch = useAppDispatch();

    const { resetBoundary } = useErrorBoundary();

    return (
        <div
            className={cn(styles.main, {
                [styles.main_full]: !isOpen,
            })}
            id="main"
        >
            <div className={styles.navbar}>
                {!isOpen && (
                    <Button
                        type="button"
                        className={styles.menubutton}
                        size="auto"
                        mode="transparent"
                        onClick={() => dispatch(toggleNavState())}
                    >
                        <MenuIcon />
                    </Button>
                )}
            </div>
            <div className={styles.wrap}>
                <h3 className="styles.">Something went wrong</h3>
                <p>
                    You just encountered an unexpected error. We have been made
                    aware of it and we will address this issue as quickly as we
                    can. Please use the feedback button to provide more
                    information on the issue. Thank you for your understanding.
                </p>
                <Button
                    type="button"
                    size="small"
                    mode="primary"
                    onClick={resetBoundary}
                    style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginTop: "20px",
                    }}
                >
                    Go back
                </Button>
            </div>
        </div>
    );
}

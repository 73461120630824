import { IAppCustomDates } from "../../../utils/types";
import { BillStatus } from "../../billing/types/billing.types";
import { NoteStatus, SessionServiceType } from "../../notes/types/notes.types";

export enum DayInterval {
    ALL_TIME = "ALL_TIME",
    DAY_1_TO_30_DAYS = "DAY_1_TO_30_DAYS",
    DAY_31_TO_60_DAYS = "DAY_31_TO_60_DAYS",
    DAY_61_TO_90_DAYS = "DAY_61_TO_90_DAYS",
    DAY_91_TO_120_DAYS = "DAY_91_TO_120_DAYS",
    GREATER_THAN_120_DAYS = "GREATER_THAN_120_DAYS",
}

export interface ClientStatementSummary {
    client_name: string;
    client_id: string;
    total_amount_outstanding: number;
    amount_outstanding_ins: number;
    amount_outstanding_coins: number;
    total_amount_collected: number;
    amount_collected_ins: number;
    amount_collected_coins: number;
    total_amount_charged: number;
    amount_charged_ins: number;
    amount_charged_coins: number;
    total_amount_refunded: number;
    amount_refunded_ins: number;
    amount_refunded_coins: number;
    total_amount_written_off: number;
    amount_written_off_ins: number;
    amount_written_off_coins: number;
}
export interface ClientStatementBreakdown {
    date_of_service: string;
    cpt_code: string;
    note_type: string;
    start_date_time: string;
    end_date_time: string;
    invoice_id: string;
    insurance_payment_date: string;
    insurance_payment_amount: number;
    coinsurance_payment_date: string;
    coinsurance_payment_amount: number;
    insurance_amount_charged: number;
    coinsurance_amount_charged: number;
    insurance_provider: string;
    description: string;
}

export interface IStatementOfAccountPreviewResponse {
    client_statements: {
        [client_id: string]: {
            statement_summary: ClientStatementSummary;
            statement_breakdown: ClientStatementBreakdown[];
            description: string;
        };
    };
}

export interface IStatementOfAccountFilters {
    action: string;
    client_ids: string[];
    insurance_provider_id?: string;
    custom_date: IAppCustomDates;
    from_date?: string | null;
    to_date?: string | null;
    from_amount_outstanding_ins?: string;
    to_amount_outstanding_ins?: string;
    from_amount_outstanding_coins?: string;
    to_amount_outstanding_coins?: string;
    send_to_client_email?: boolean;
    only_coins_outstanding?: boolean;
}

export interface IStatementOfAccount {
    statement_summary: ClientStatementSummary;
    statement_breakdown: ClientStatementBreakdown[];
    description: string;
}

export interface ISendStatementOfAccount {
    emails?: string[];
}

export interface ISessionActivity {
    provider_id: string;
    last_name: string;
    first_name: string;
    credential: string;
    supervisor_provider_id: string;
    supervisor_last_name: string;
    supervisor_first_name: string;
    no_of_clients: number;
    sessions_completed: number;
    sessions_cancelled: number;
    notes_completed: number;
    notes_due: number;
    notes_in_draft: number;
    notes_pending_changes: number;
    notes_pending_review: number;
}

export interface ISessionActivityResponse {
    from_date: string;
    to_date: string;
    summary: {
        total_no_of_clients: number;
        total_sessions_completed: number;
        total_notes_completed: number;
        total_notes_in_draft: number;
        total_notes_pending_changes: number;
        total_notes_pending_review: number;
        total_sessions_cancelled: number;
    };
    rows: ISessionActivity[];
}

export interface IProviderSessionActivityItem {
    note_id?: string;
    client_id: string;
    service: SessionServiceType;
    client: {
        client_id: string;
        first_name: string;
        last_name: string;
    };
    date_of_service: string;
    note_type: string;
    note_status?: NoteStatus;
    start_date_time: string;
    end_date_time: string;
    duration_secs: number;
    is_cancelled: boolean;
    is_note_late: boolean;
    created_at: string;
}

export interface IProviderSessionStatsResponse {
    from_date: string;
    to_date: string;
    activity_list: IProviderSessionActivityItem[];
}

export interface ISessionActivityFilters {
    provider_name?: string;
    provider_credential?: string;
    custom_date?: string;
    from_date?: string | null;
    to_date?: string | null;
    note_status?: string[];
    from_created_at_date?: string | null;
    to_created_at_date?: string | null;
    include_non_billable_notes?: string;
}

export interface IProviderSessionStatsFilters {
    custom_date?: string;
    from_date?: string | null;
    to_date?: string | null;
    note_status?: string[];
    from_created_at_date?: string | null;
    to_created_at_date?: string | null;
    include_non_billable_notes?: string;
}

export interface IAgingReportResponse {
    date_of_service: string;
    collected: number;
    amount_outstanding: number;
    insurance_amount: number;
    invoice_id: string;
    coinsurance_amount: number;
    charge_amount: number;
    claim_external_reference_id: string;
    cpt_code: string;
    pending_settlement: number;
    invoice_status: BillStatus;
    note_id: string;
    note_url: string;
    submitted_on: string;
    provider: {
        user_id: string;
        provider_id: string;
        first_name: string;
        last_name: string;
        npi: string;
    };
    client: {
        user_id: string;
        client_id: string;
        first_name: string;
        last_name: string;
        date_of_birth: string;
    };
    diagnosis_codes: string[];
    age: number;
}

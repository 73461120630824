import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Input,
    RadioInput,
    Select,
    TextArea,
} from "@jhool-io/fe-components";

import {
    ISessionNote,
    TypeOfDisclosure,
    MethodOfDisclosure,
    IGetClientNotes,
} from "../../../../utils/types/notes";
import { useFetchDiagnosisCodes } from "../../../../hooks/queries";
import NoteFormInfoBox from "../../NoteFormInfoBox/NoteFormInfoBox";
import { useFetchClientNotesList } from "../../../../hooks/queries/note";
import {
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import LoadPreviousSessionNotificationModal from "../../LoadPreviousSessionNotificatiModal/LoadPreviousSessionNotificationModal";
import { RECORD_OF_DISCLOSURE_NOTE_LABELS } from "../../../../utils/constants";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import { IEditRecordOfDisclosurePayload } from "../../../../modules/notes/types/notes.types";
import { useEditNote } from "../../../../modules/notes/hooks/edit-note";
import EditNoteFooter from "../../../../modules/notes/components/NoteCreationFooters/EditNoteFooter/EditNoteFooter";

interface RecordOfDisclosureProps {
    noteDetails: ISessionNote;
    currentAction: string | null;
}

type Option = {
    label: React.ReactNode;
    value: string;
};

export default function EditRecordOfDisclosure({
    noteDetails,
    currentAction,
}: RecordOfDisclosureProps) {
    // Fetch diagonis codes
    const { data, isLoading, error } = useFetchDiagnosisCodes();

    const [showPrompt, setShowPrompt] = React.useState(false);
    const [selectedNote, setSelectedNote] = React.useState(
        {} as IGetClientNotes
    );

    const { handleAutoSaveNote, handleEditDraftNote, handleSignNote } =
        useEditNote();

    // Get diagnosis codes for select
    const DiagnosisCodesForSelect = data?.data.map((diagnosis) => ({
        label: `${diagnosis.code} ${diagnosis.description}`,
        value: diagnosis.code,
    }));

    const clientNoteParams = {
        type: noteDetails?.type as string,
        load_previous_notes: true,
        provider_id: noteDetails?.provider?.provider_id as string,
    };
    const { data: notes } = useFetchClientNotesList(
        noteDetails?.client?.client_id as string,
        clientNoteParams
    );

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm<IEditRecordOfDisclosurePayload>({
        resolver: yupResolver(
            yup.object({
                duration: yup.string(),
                diagnosis_codes: yup.array().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .array()
                        .max(1, "You can only select 1 diagnosis")
                        .min(1, "Diagnosis is required")
                        .required("Diagnosis is required"),
                    otherwise: yup
                        .array()
                        .max(1, "You can only select 1 diagnosis")
                        .nullable(),
                }),
                secondary_diagnosis: yup.string(),
                tertiary_diagnosis: yup.string(),
                disclosure_to_whom: yup.string(),
                disclosure_type: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("Disclosure type is required"),
                    otherwise: yup.string().nullable(),
                }),
                purpose_of_disclosure: yup.string(),
                type_disclosed: yup.string(),
                method_of_disclosure: yup.string(),
                signature: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("Signature is required"),
                    otherwise: yup.string(),
                }),
            })
        ),
        mode: "onChange",
        defaultValues: noteDetails.note_content || undefined,
    });

    const diagnosisCodes = watch("diagnosis_codes");

    // Get methods of disclosure for select
    const MethodsOfDisclosureForSelect = Object.values(MethodOfDisclosure).map(
        (method) => {
            return {
                label: method,
                value: method,
            };
        }
    );

    // Get types of disclosure for select
    const TypesOfDisclosureForSelect = Object.values(TypeOfDisclosure).map(
        (form) => {
            return {
                label: form,
                value: form,
            };
        }
    );

    // Function to handle item click and set the selected note content
    const handleLoadPreviousSession = (note: IGetClientNotes) => {
        setValue("diagnosis_codes", note.note_content.diagnosis_codes);
        setValue("secondary_diagnosis", note.note_content.secondary_diagnosis);
        setValue("tertiary_diagnosis", note.note_content.tertiary_diagnosis);
        setValue("disclosure_to_whom", note.note_content.disclosure_to_whom);
        setValue("disclosure_type", note.note_content.disclosure_type);
        setValue("duration", note.note_content.duration);
        setValue(
            "method_of_disclosure",
            note.note_content.method_of_disclosure
        );
        setValue(
            "purpose_of_disclosure",
            note.note_content.purpose_of_disclosure
        );
        setValue("type_disclosed", note.note_content.type_disclosed);
        setShowPrompt(false);
    };

    return (
        <div>
            <LoadPreviousSessionNotificationModal
                showPrompt={showPrompt}
                setShowPrompt={setShowPrompt}
                handleLoadPreviousSession={() =>
                    handleLoadPreviousSession(selectedNote)
                }
            />
            {notes && notes.data.length > 0 && (
                <div className="flex justify-end mb-24">
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <FilterButton text="Load previous session note" />
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            {notes &&
                                notes?.data?.map((note) => (
                                    <DropdownItem
                                        key={note.note_id}
                                        onClick={() => {
                                            setShowPrompt(true);
                                            setSelectedNote(note);
                                        }}
                                    >
                                        <div>
                                            {makeStringFirstLetterCapital(
                                                removeEnumUnderscore(
                                                    note.note_type
                                                )
                                            )}{" "}
                                            - Note content Details -{" "}
                                            {formatDate(note.date_of_service)}
                                        </div>
                                    </DropdownItem>
                                ))}
                        </DropdownContent>
                    </Dropdown>
                </div>
            )}
            <form
                className="pb-[60px] md:pb-[150px]"
                onSubmit={handleSubmit(handleSignNote)}
                aria-label="edit-form"
                id="edit-note-form"
            >
                <div className="mb-24">
                    <NoteFormInfoBox />
                </div>

                <div className="fg">
                    <Controller
                        name="diagnosis_codes"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label={
                                    RECORD_OF_DISCLOSURE_NOTE_LABELS.diagnosis_codes
                                }
                                isRequired
                                placeholder={
                                    (!data && isLoading && "Loading...") ||
                                    (error &&
                                        !isLoading &&
                                        "Error loading diagnosis") ||
                                    (data &&
                                        !isLoading &&
                                        RECORD_OF_DISCLOSURE_NOTE_LABELS.diagnosis_codes)
                                }
                                isSearchable
                                onChange={(val) => {
                                    field.onChange(
                                        (val as Option[]).map(
                                            (code) => code.value
                                        )
                                    );
                                }}
                                options={DiagnosisCodesForSelect}
                                isDisabled={isLoading || Boolean(error)}
                                hasError={!!errors.diagnosis_codes}
                                value={
                                    DiagnosisCodesForSelect?.filter(
                                        (diagnosis) =>
                                            field.value?.includes(
                                                diagnosis.value
                                            )
                                    ) || null
                                }
                                errorText={
                                    errors.diagnosis_codes?.type === "typeError"
                                        ? "Diagnosis codes are required"
                                        : errors.diagnosis_codes?.message
                                }
                                isMulti
                                multiHasValues={
                                    diagnosisCodes && diagnosisCodes.length > 0
                                }
                                onBlur={handleSubmit(handleAutoSaveNote)}
                            />
                        )}
                    />
                </div>
                <div className="fg">
                    <Controller
                        name="secondary_diagnosis"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label={
                                    RECORD_OF_DISCLOSURE_NOTE_LABELS.secondary_diagnosis
                                }
                                placeholder={
                                    (!data && isLoading && "Loading...") ||
                                    (error &&
                                        !isLoading &&
                                        "Error loading diagnosis") ||
                                    (data &&
                                        !isLoading &&
                                        RECORD_OF_DISCLOSURE_NOTE_LABELS.secondary_diagnosis)
                                }
                                isSearchable
                                onChange={(val) =>
                                    field.onChange((val as Option).value)
                                }
                                options={[
                                    { label: "Select diagnosis", value: "" },
                                    ...(DiagnosisCodesForSelect || []),
                                ]}
                                isDisabled={isLoading || Boolean(error)}
                                hasError={!!errors.secondary_diagnosis}
                                value={
                                    DiagnosisCodesForSelect?.find((diagnosis) =>
                                        field.value?.includes(diagnosis.value)
                                    ) || null
                                }
                                errorText={errors.secondary_diagnosis?.message}
                                onBlur={handleSubmit(handleAutoSaveNote)}
                            />
                        )}
                    />
                </div>
                <div className="fg">
                    <Controller
                        name="tertiary_diagnosis"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label={
                                    RECORD_OF_DISCLOSURE_NOTE_LABELS.tertiary_diagnosis
                                }
                                placeholder={
                                    (!data && isLoading && "Loading...") ||
                                    (error &&
                                        !isLoading &&
                                        "Error loading diagnosis") ||
                                    (data &&
                                        !isLoading &&
                                        RECORD_OF_DISCLOSURE_NOTE_LABELS.tertiary_diagnosis)
                                }
                                isSearchable
                                onChange={(val) =>
                                    field.onChange((val as Option).value)
                                }
                                options={[
                                    { label: "Select diagnosis", value: "" },
                                    ...(DiagnosisCodesForSelect || []),
                                ]}
                                isDisabled={isLoading || Boolean(error)}
                                hasError={!!errors.tertiary_diagnosis}
                                value={
                                    DiagnosisCodesForSelect?.find((diagnosis) =>
                                        field.value?.includes(diagnosis.value)
                                    ) || null
                                }
                                errorText={errors.tertiary_diagnosis?.message}
                                onBlur={handleSubmit(handleAutoSaveNote)}
                            />
                        )}
                    />
                </div>
                <div className="fg">
                    <Input
                        {...register("disclosure_to_whom")}
                        label={
                            RECORD_OF_DISCLOSURE_NOTE_LABELS.disclosure_to_whom
                        }
                        placeholder={
                            RECORD_OF_DISCLOSURE_NOTE_LABELS.disclosure_to_whom
                        }
                        defaultValue={
                            noteDetails.note_content?.disclosure_to_whom
                        }
                        onBlur={handleSubmit(handleAutoSaveNote)}
                    />
                </div>
                <p className="note-label-req">
                    {RECORD_OF_DISCLOSURE_NOTE_LABELS.disclosure_type}
                </p>
                <div className="fg fg-space-between two flex">
                    <RadioInput
                        {...register("disclosure_type")}
                        label="Authorized disclosure"
                        value="Authorized disclosure"
                        defaultChecked={
                            noteDetails.note_content.disclosure_type ===
                            "Authorized disclosure"
                        }
                        onBlur={handleSubmit(handleAutoSaveNote)}
                    />

                    <RadioInput
                        {...register("disclosure_type")}
                        label="Unauthorized disclosure"
                        value="Unauthorized disclosure"
                        defaultChecked={
                            noteDetails.note_content.disclosure_type ===
                            "Unauthorized disclosure"
                        }
                        onBlur={handleSubmit(handleAutoSaveNote)}
                    />
                </div>
                {errors.disclosure_type ? (
                    <div className="note-error">
                        <p>Disclosure type is required</p>
                    </div>
                ) : null}
                <div className="fg">
                    <TextArea
                        {...register("purpose_of_disclosure")}
                        placeholder={
                            RECORD_OF_DISCLOSURE_NOTE_LABELS.purpose_of_disclosure
                        }
                        label={
                            RECORD_OF_DISCLOSURE_NOTE_LABELS.purpose_of_disclosure
                        }
                        defaultValue={
                            noteDetails.note_content?.purpose_of_disclosure
                        }
                        onBlur={handleSubmit(handleAutoSaveNote)}
                    />
                </div>
                <div className="fg">
                    <Controller
                        name="type_disclosed"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label={
                                    RECORD_OF_DISCLOSURE_NOTE_LABELS.type_disclosed
                                }
                                placeholder={
                                    RECORD_OF_DISCLOSURE_NOTE_LABELS.type_disclosed
                                }
                                options={TypesOfDisclosureForSelect}
                                onChange={(val) =>
                                    field.onChange((val as Option).value)
                                }
                                hasError={!!errors.type_disclosed}
                                errorText={errors.type_disclosed?.message}
                                value={
                                    TypesOfDisclosureForSelect?.find(
                                        (disclosed) =>
                                            disclosed.value === field.value
                                    ) || null
                                }
                                onBlur={handleSubmit(handleAutoSaveNote)}
                            />
                        )}
                    />
                </div>
                <div className="fg">
                    <Controller
                        name="method_of_disclosure"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label={
                                    RECORD_OF_DISCLOSURE_NOTE_LABELS.method_of_disclosure
                                }
                                placeholder={
                                    RECORD_OF_DISCLOSURE_NOTE_LABELS.method_of_disclosure
                                }
                                options={MethodsOfDisclosureForSelect}
                                onChange={(val) =>
                                    field.onChange((val as Option).value)
                                }
                                hasError={!!errors.method_of_disclosure}
                                errorText={errors.method_of_disclosure?.message}
                                value={
                                    MethodsOfDisclosureForSelect.find(
                                        (method) => method.value === field.value
                                    ) || null
                                }
                                onBlur={handleSubmit(handleAutoSaveNote)}
                            />
                        )}
                    />
                </div>

                <div className="fg-info fg-line">
                    <p>Sign note here</p>
                    <div className="fg">
                        <Input
                            {...register("signature")}
                            hasError={!!errors.signature}
                            errorText={errors.signature?.message}
                            label="Provider's Initials"
                            placeholder="Provider's Initials"
                            autoComplete="off"
                            isRequired
                        />
                    </div>
                </div>
                <div>
                    <EditNoteFooter
                        onSaveButtonClick={handleSubmit(handleEditDraftNote)}
                        noteDOS={noteDetails.date_of_service}
                    />
                </div>
            </form>
        </div>
    );
}

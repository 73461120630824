import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import axios from "../../../utils/axios";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    IChangeStatusPayload,
    IInternalUsersListFilterTypes,
    IModifyRolesPayload,
    INewUsers,
} from "../types/user-management.types";

export async function fetchUsersList(filters: IInternalUsersListFilterTypes) {
    const response = await axios.get(`/users`, {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });
    return response.data;
}

export async function deleteUsers(userIds: string[]) {
    const response = await axios.delete(`/users`, {
        data: { user_ids: userIds },
    });
    return response.data;
}

export async function addUser(data: INewUsers) {
    const response = await axios.post(`/users`, data);
    return response.data;
}

export async function changeUserStatus(data: IChangeStatusPayload) {
    const response = await axios.patch(`/users/status`, data);
    return response.data;
}

export async function modifyUserRole(data: IModifyRolesPayload) {
    const response = await axios.patch(`/users/role`, data);
    return response.data;
}

export async function resetUserPassword(emails: string[]) {
    const auth = getAuth();

    const promises = emails.map((email) => {
        return sendPasswordResetEmail(auth, email);
    });

    await Promise.all(promises);
}

import React from "react";

export default function MastercardIcon() {
    return (
        <svg
            width="25"
            height="16"
            viewBox="0 0 25 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.4178 15.4918C21.6052 15.4918 24.9998 12.1376 24.9998 8.00001C24.9998 3.86244 21.6052 0.50827 17.4178 0.50827C15.5412 0.50827 13.8237 1.18198 12.4998 2.29798C11.1758 1.18212 9.45851 0.508508 7.58197 0.508508C3.39456 0.508508 0 3.86267 0 8.00025C0 12.1378 3.39456 15.492 7.58197 15.492C9.45864 15.492 11.1761 14.8183 12.5 13.7023C13.824 14.8181 15.5413 15.4918 17.4178 15.4918Z"
                fill="#ED0006"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 13.7017C14.1301 12.3276 15.1638 10.2829 15.1638 7.99977C15.1638 5.71659 14.1301 3.67197 12.5 2.29785C13.824 1.18178 15.5415 0.508026 17.4182 0.508026C21.6056 0.508026 25.0002 3.86219 25.0002 7.99977C25.0002 12.1373 21.6056 15.4915 17.4182 15.4915C15.5415 15.4915 13.824 14.8178 12.5 13.7017Z"
                fill="#F9A000"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 13.7023C14.1303 12.3282 15.164 10.2835 15.164 8.00015C15.164 5.71685 14.1303 3.67213 12.5 2.298C10.8697 3.67213 9.83594 5.71685 9.83594 8.00015C9.83594 10.2835 10.8697 12.3282 12.5 13.7023Z"
                fill="#FF5E00"
            />
        </svg>
    );
}

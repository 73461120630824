import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { ContentState } from "draft-js";
import { Input, Select } from "@jhool-io/fe-components";
import { IRequestChangePayload } from "../../../utils/types/notes";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NoteDetails from "../NotesDetails/NoteDetails";
import useToast from "../../../hooks/useToast";
import { useRequestChange } from "../../../hooks/mutations/note";
import { AddTextEditor } from "../../TextEditor/AddTextEditor/AddTextEditor";
import styles from "../../../modules/notes/components/NoteContent/NoteContent.module.scss";

interface RequestChangesProps {
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
}

const schema = yup.object({
    request_reason: yup.string().required("Request reason is required"),
    other_reason: yup
        .string()
        .when("request_reason", (request_reason, field) =>
            request_reason === "Other"
                ? field.required("Other reason is required")
                : field
        ),
});

type Option = {
    label: React.ReactNode;
    value: string;
};

export default function RequestChanges({ onFormSubmit }: RequestChangesProps) {
    const OptionsForChanges = [
        "Incomplete or missing information",
        "Lack of detail",
        "Incorrect information",
        "Failure to meet regulatory requirements",
        "Insufficient justification",
        "Other reason",
    ].map((reason) => ({
        label: reason,
        value: reason,
    }));

    const {
        register,
        watch,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IRequestChangePayload>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const OtherReasons = watch("request_reason", "");

    const params = useParams();
    const noteId = params.noteId as string;
    const clientId = params.clientId as string;
    const [description, setDescription] = React.useState("");
    const [isDescriptionProvided, setIsDescriptionProvided] =
        useState<boolean>(true);
    const [getCurrentContent, setGetCurrentContent] = useState<ContentState>();

    const { toast } = useToast();

    // Query client
    const queryClient = useQueryClient();

    const requestChange = useRequestChange(noteId);

    const onSubmit = (data: IRequestChangePayload) => {
        if (!getCurrentContent?.hasText()) {
            // Description is empty
            setIsDescriptionProvided(false);
            return;
        }
        setIsDescriptionProvided(true);
        const dataToSend = {
            request_details: description,
            request_reason:
                data.request_reason === "Other reason"
                    ? (data.other_reason as string)
                    : (data.request_reason as string),
        };
        requestChange.mutate(dataToSend, {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: [noteId, `change-request`],
                });
                await queryClient.invalidateQueries({
                    queryKey: [clientId, `session-note`, noteId],
                });

                toast({
                    mode: "success",
                    message: res.message || "Changes requested successfully",
                });
                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not request for changes at this time",
                });
            },
        });
    };

    return (
        <form id="request-changes" onSubmit={handleSubmit(onSubmit)}>
            <NoteDetails showProvider />

            <div className="fg" style={{ paddingTop: 20 }}>
                <Controller
                    name="request_reason"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Reason for request"
                            placeholder="Reason for request"
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                            options={OptionsForChanges}
                            hasError={!!errors.request_reason}
                            errorText={errors.request_reason?.message}
                        />
                    )}
                />
            </div>
            {OtherReasons === "Other reason" && (
                <div className="fg">
                    <Input
                        {...register("other_reason")}
                        aria-label="other-reasons"
                        label="Specify Other"
                        placeholder="Specify Other"
                        hasError={!!errors.request_reason}
                        errorText={errors.request_reason?.message}
                    />
                </div>
            )}
            <div className="fg">
                <AddTextEditor
                    title="Description"
                    onEditorTextChange={setDescription}
                    setIsRequiredFieldProvided={setGetCurrentContent}
                    isRequiredFieldProvided={
                        isDescriptionProvided || getCurrentContent?.hasText()
                    }
                    titleClass="font-medium text-xs text-black leading-none"
                />
                {!isDescriptionProvided && !getCurrentContent?.hasText() && (
                    <div className={styles.error}>Description is required</div>
                )}
            </div>
        </form>
    );
}

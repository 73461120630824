import Navbar from "../../../components/Navbar/Navbar";
import ViewClaim from "../components/ViewClaims/ViewClaim";

export default function ViewSingleClaim() {
    return (
        <>
            <Navbar title="Claim Info" />
            <div className="page">
                <ViewClaim />
            </div>
        </>
    );
}

import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import { fetchRestrictedUsers } from "../services/client-chart-restriction.services";
import {
    IRestrictedUser,
    IRestrictedUsersFilterTypes,
} from "../types/client-chart-restriction.types";
import { IApiResponse } from "../../../utils/types/api-response";

export function useFetchRestrictedUsersList(
    filters: IRestrictedUsersFilterTypes
) {
    return useQuery<
        IApiResponse<IRestrictedUser[]>,
        AxiosError<IApiResponse<IRestrictedUser[]>>
    >({
        queryKey: [
            "restricted-users",
            handleRemoveEmptyParamFromQueryParams(filters),
        ],
        queryFn: () => fetchRestrictedUsers(filters),
    });
}

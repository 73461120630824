import { Button } from "@jhool-io/fe-components";
import { cn } from "../../utils/helpers";

interface MobileListCardProps {
    /** onClick handler for list item */
    onClickListItem?(): void;
    /** List item children */
    children: React.ReactNode;
    /** classname for additional styling */
    className?: string;
    /** Optional style object to add more styling */
    style?: Record<string, string>;
}

export default function MobileListCard({
    children,
    onClickListItem,
    className,
    style,
}: MobileListCardProps) {
    return (
        <div
            className={cn(
                "w-full p-12 rounded-r8 bg-white border border-[#ECEEF2]",
                className
            )}
            style={style}
        >
            <Button
                aria-label="list item"
                onClick={onClickListItem}
                className="w-full"
                variant="normal"
                size="auto"
            >
                {children}
            </Button>
        </div>
    );
}

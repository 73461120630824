import { useEffect, useState } from "react";
import { PDFDocument, PDFName } from "pdf-lib";
import { Card } from "@jhool-io/fe-components";
import { useParams } from "react-router-dom";

import pdf from "../../../../assets/pdfs/fillable-1500-claims-form.pdf";
import { useGetClaim } from "../../hooks/claims.queries";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import { formatDate } from "../../../../utils/helpers";
import { getPhoneNumberParts } from "../../helpers/claims.helper";
import { getStateInShortForm } from "../../../../utils/helpers/us-states/us-states";

function FillableClaimsForm() {
    const { claimId } = useParams();

    const [pdfInfo, setPdfInfo] = useState("");

    const { data, isLoading, error } = useGetClaim(
        {
            claim_id: claimId as string,
        },
        Boolean(claimId)
    );

    const claimInView = data?.data[0];

    useEffect(() => {
        async function showPdf() {
            const arrayBuffer = await fetch(pdf).then((res) =>
                res.arrayBuffer()
            );
            const pdfDoc = await PDFDocument.load(arrayBuffer);

            const form = pdfDoc.getForm();

            // pdf form metadata
            pdfDoc.setTitle("Fillable Claims Form");
            pdfDoc.setAuthor("Mantle");
            pdfDoc.setProducer("Mantle");

            const claimContent = claimInView?.claim_content;

            // autofill the form using claimsData
            if (claimContent) {
                //
                // **** PAYER DETAILS SECTION**** //
                const payerNameField = form.getTextField("insurance_name");
                payerNameField.setText(claimContent.payerName ?? "");

                const payerAddress1Field =
                    form.getTextField("insurance_address");
                payerAddress1Field.setText(claimContent.payerAddress1 ?? "");

                const payerAddress2Field =
                    form.getTextField("insurance_address2");
                payerAddress2Field.setText(claimContent.payerAddress2 ?? "");

                const payerDetailsField = form.getTextField(
                    "insurance_city_state_zip"
                );

                payerDetailsField.setText(
                    `${claimContent.payerCity ?? ""}${
                        claimContent.payerState
                            ? `, ${claimContent.payerState}`
                            : ""
                    } ${claimContent.payerZip ?? ""}`
                );
                // **** END OF PAYER DETAILS SECTION**** //

                // **** PERSONAL INFO SECTION **** //
                // 1. ->  insurance type
                if (claimContent.insuranceType) {
                    const insuranceTypeCheckBoxField =
                        form.getCheckBox("insurance_type");
                    const widgets =
                        insuranceTypeCheckBoxField.acroField.getWidgets();

                    let selectedType: number;
                    switch (claimContent.insuranceType) {
                        case "medicare":
                            selectedType = 0;
                            break;
                        case "medicaid":
                            selectedType = 1;
                            break;
                        case "tricare":
                            selectedType = 2;
                            break;
                        case "champva":
                            selectedType = 3;
                            break;
                        case "group_health_plan":
                            selectedType = 4;
                            break;
                        case "feca_blk_lung":
                            selectedType = 5;
                            break;
                        case "other":
                            selectedType = 6;
                            break;
                        default:
                            selectedType = 6;
                    }

                    widgets[selectedType].setAppearanceState(
                        widgets[selectedType].getOnValue() as PDFName
                    );
                }

                // 1.A -> insured's ID number
                const subscriberIdField = form.getTextField("insurance_id");
                subscriberIdField.setText(claimContent.subscriberId);

                // 2. -> patient name
                const patientsNameField = form.getTextField("pt_name");
                patientsNameField.setText(
                    `${claimContent.patientLastName ?? ""}${
                        claimContent.patientFirstName
                            ? `, ${claimContent.patientFirstName}`
                            : ""
                    } ${
                        claimContent.patientMiddleName
                            ? `, ${claimContent.patientMiddleName.charAt(0)}`
                            : ""
                    }`
                );

                // 3. -> patient birth month
                const patientsBirthMonth = form.getTextField("birth_mm");
                patientsBirthMonth.setText(
                    formatDate(claimContent.patientBirthdate, true, "M")
                );

                // 3. -> patient birth day
                const patientsBirthDay = form.getTextField("birth_dd");
                patientsBirthDay.setText(
                    formatDate(claimContent.patientBirthdate, true, "d")
                );

                // 3. -> patient birth year
                const patientsBirthYear = form.getTextField("birth_yy");
                patientsBirthYear.setText(
                    formatDate(claimContent.patientBirthdate, true, "y")
                );

                // 3. -> patient's sex
                if (claimContent.patientSex) {
                    const insuranceTypeCheckBoxField = form.getCheckBox("sex");
                    const widgets =
                        insuranceTypeCheckBoxField.acroField.getWidgets();

                    if (claimContent.patientSex === "M") {
                        widgets[0].setAppearanceState(
                            widgets[0].getOnValue() as PDFName
                        );
                    } else if (claimContent.patientSex === "F") {
                        widgets[1].setAppearanceState(
                            widgets[1].getOnValue() as PDFName
                        );
                    }
                }

                // 4. -> insured's Name
                const subscriberNameField = form.getTextField("ins_name");
                subscriberNameField.setText(
                    `${claimContent.subscriberLastName ?? ""}${
                        claimContent.subscriberFirstName
                            ? `, ${claimContent.subscriberFirstName}`
                            : ""
                    } ${
                        claimContent.subscriberMiddleName
                            ? `, ${claimContent.subscriberMiddleName.charAt(0)}`
                            : ""
                    }`
                );

                // 5. -> patient's address
                const patientStreetField = form.getTextField("pt_street");
                patientStreetField.setText(claimContent.patientAddress);

                // 5. -> patient's city
                const patientCityField = form.getTextField("pt_city");
                patientCityField.setText(claimContent.patientCity);

                // 5. -> patient's state
                const patientStateField = form.getTextField("pt_state");
                patientStateField.setText(
                    getStateInShortForm(claimContent.patientState)
                );

                // 5. -> patient's zip
                const patientZipField = form.getTextField("pt_zip");
                patientZipField.setText(claimContent.patientZip);

                // 5. -> patient's phone
                const patientPhoneField = form.getTextField("pt_phone");
                const patientNumber = getPhoneNumberParts(
                    claimContent.patientPhone
                )[2];

                patientPhoneField.setText(patientNumber);

                const patientAreaCodeField = form.getTextField("pt_AreaCode");

                const patientAreaCode = getPhoneNumberParts(
                    claimContent.patientPhone
                )[1];

                patientAreaCodeField.setText(patientAreaCode);

                // 6. -> Patient's relationship to insured
                if (claimContent.patientRelationshipToSubscriber) {
                    const patientRlshpField = form.getCheckBox("rel_to_ins");
                    const widgets = patientRlshpField.acroField.getWidgets();

                    if (claimContent.patientRelationshipToSubscriber === "18") {
                        widgets[0].setAppearanceState(
                            widgets[0].getOnValue() as PDFName
                        );
                    } else if (
                        claimContent.patientRelationshipToSubscriber === "01"
                    ) {
                        widgets[1].setAppearanceState(
                            widgets[1].getOnValue() as PDFName
                        );
                    } else if (
                        claimContent.patientRelationshipToSubscriber === "19"
                    ) {
                        widgets[2].setAppearanceState(
                            widgets[2].getOnValue() as PDFName
                        );
                    } else if (
                        claimContent.patientRelationshipToSubscriber === "G8"
                    ) {
                        widgets[3].setAppearanceState(
                            widgets[3].getOnValue() as PDFName
                        );
                    }
                }

                // 7. -> Insured's address
                const insuredStreetField = form.getTextField("ins_street");
                insuredStreetField.setText(claimContent.subscriberAddress);

                // 7. -> Insured's address
                const insuredCityField = form.getTextField("ins_city");
                insuredCityField.setText(claimContent.subscriberCity);

                // 7. -> Insured's state
                const insuredStateField = form.getTextField("ins_state");
                insuredStateField.setText(
                    getStateInShortForm(claimContent.subscriberState)
                );

                // 7. -> Insured's zip
                const insuredZipField = form.getTextField("ins_zip");
                insuredZipField.setText(claimContent.subscriberZip);

                // 7. -> Insured's phone

                const insuredPhoneField = form.getTextField("ins_phone");
                const insuredPhoneNo = getPhoneNumberParts(
                    claimContent.subscriberPhone
                )[2];

                insuredPhoneField.setText(insuredPhoneNo);

                // 7. -> Insured's Area code
                const insuredAreaCodeField =
                    form.getTextField("ins_phone area");

                const insuredAreaCode = getPhoneNumberParts(
                    claimContent.subscriberPhone
                )[1];

                insuredAreaCodeField.setText(insuredAreaCode);

                // 9. -> Other insured's name
                const otherInsuredNameField =
                    form.getTextField("other_ins_name");
                otherInsuredNameField.setText(
                    `${claimContent.otherSubscriberFirstName ?? ""} ${
                        claimContent.otherSubscriberMiddleName ?? ""
                    } ${claimContent.otherSubscriberLastName ?? ""}`
                );

                // 9a -> Other Insured's policy/group number
                const otherInsuredNumber =
                    form.getTextField("other_ins_policy");
                otherInsuredNumber.setText(claimContent.otherSubscriberId);

                // 9d -> Other Insured's plan name
                const otherInsuredPlanName = form.getTextField(
                    "other_ins_plan_name"
                );
                otherInsuredPlanName.setText(
                    claimContent.otherInsurancePlanProgram
                );

                // 10a -> Is Condition related to Employment

                const employmentField = form.getCheckBox("employment");
                const employmentWidgets =
                    employmentField.acroField.getWidgets();

                if (claimContent.isConditionRelatedToEmployment === "Y") {
                    employmentWidgets[0].setAppearanceState(
                        employmentWidgets[0].getOnValue() as PDFName
                    );
                } else {
                    employmentWidgets[1].setAppearanceState(
                        employmentWidgets[1].getOnValue() as PDFName
                    );
                }

                // 10b -> Is Condition related to auto accident
                const autoAccidentField = form.getCheckBox("pt_auto_accident");
                const autoAccidentWidgets =
                    autoAccidentField.acroField.getWidgets();

                if (claimContent.isConditionAutoAccidentRelated === "Y") {
                    autoAccidentWidgets[0].setAppearanceState(
                        autoAccidentWidgets[0].getOnValue() as PDFName
                    );
                } else {
                    autoAccidentWidgets[1].setAppearanceState(
                        autoAccidentWidgets[1].getOnValue() as PDFName
                    );
                }

                // 10b -> Is Condition related to auto accident -> place
                const autoAccidentPlaceField =
                    form.getTextField("accident_place");
                autoAccidentPlaceField.setText(
                    claimContent.autoAccidentState.slice(0, 3)
                );

                // 10c -> Is Condition related to other accident
                const otherAccidentField = form.getCheckBox("other_accident");
                const otherAccidentWidgets =
                    otherAccidentField.acroField.getWidgets();

                if (claimContent.isConditionOtherAccidentRelated === "Y") {
                    otherAccidentWidgets[0].setAppearanceState(
                        otherAccidentWidgets[0].getOnValue() as PDFName
                    );
                } else {
                    otherAccidentWidgets[1].setAppearanceState(
                        otherAccidentWidgets[1].getOnValue() as PDFName
                    );
                }

                // 11 -> Insured's Policy Group / FECA Number
                const insuredPolicyField = form.getTextField("ins_policy");
                insuredPolicyField.setText(claimContent.subscriberPolicyGroup);

                // 11a ->Insured's date of birth. MM
                const insuredBirthMonthField = form.getTextField("ins_dob_mm");
                insuredBirthMonthField.setText(
                    formatDate(claimContent.subscriberBirthdate, true, "M")
                );

                // 11a ->Insured's date of birth. DD
                const insuredBirthDayField = form.getTextField("ins_dob_dd");
                insuredBirthDayField.setText(
                    formatDate(claimContent.subscriberBirthdate, true, "d")
                );

                // 11a ->Insured's date of birth. YY
                const insuredBirthYearField = form.getTextField("ins_dob_yy");
                insuredBirthYearField.setText(
                    formatDate(claimContent.subscriberBirthdate, true, "y")
                );

                // 11a -> Insured Sex
                if (claimContent.subscriberSex) {
                    const insuredSex = form.getCheckBox("ins_sex");
                    const widgets = insuredSex.acroField.getWidgets();

                    if (claimContent.subscriberSex === "M") {
                        widgets[0].setAppearanceState(
                            widgets[0].getOnValue() as PDFName
                        );
                    } else if (claimContent.subscriberSex === "F") {
                        widgets[1].setAppearanceState(
                            widgets[1].getOnValue() as PDFName
                        );
                    }
                }

                // 11c -> Insured Plan Name
                const insuredPlanNameField = form.getTextField("ins_plan_name");
                insuredPlanNameField.setText(claimContent.insuranceGroupName);

                // 11d -> Another health benefit plan - True if 9, 9a, & 9d contain values

                const otherPlanField = form.getCheckBox("ins_benefit_plan");
                const otherPlansWidgets = otherPlanField.acroField.getWidgets();

                if (
                    form.getTextField("other_ins_name").getText()?.trim() ||
                    form.getTextField("other_ins_policy").getText()?.trim() ||
                    form.getTextField("other_ins_plan_name").getText()?.trim()
                ) {
                    otherPlansWidgets[0].setAppearanceState(
                        otherPlansWidgets[0].getOnValue() as PDFName
                    );
                } else {
                    otherPlansWidgets[1].setAppearanceState(
                        otherPlansWidgets[1].getOnValue() as PDFName
                    );
                }

                // 12 -> Patient's Signature - Sign
                const patientSignField = form.getTextField("pt_signature");
                patientSignField.setText(claimContent.patientSignature);

                // 12 -> Patient's Signature - Date
                const patientSignDateField = form.getTextField("pt_date");
                patientSignDateField.setText(
                    claimContent.patientSignatureDate.replace(/\//g, "")
                );

                // 13 -> Insured's Signature
                const insuredSignField = form.getTextField("ins_signature");
                insuredSignField.setText(claimContent.subscriberSignature);
                // **** END OF PERSONAL INFO SECTION **** //

                // **** DIAGNOSIS INFO SECTION **** //
                // 21 -> Diagnosis or nature of illness
                // Diagnosis Code 1
                const diagnosisCode1Field = form.getTextField("diagnosis1");
                diagnosisCode1Field.setText(claimContent.diagnosisCode1);

                // Diagnosis Code 2
                const diagnosisCode2Field = form.getTextField("diagnosis2");
                diagnosisCode2Field.setText(claimContent.diagnosisCode2);

                // Diagnosis Code 3
                const diagnosisCode3Field = form.getTextField("diagnosis3");
                diagnosisCode3Field.setText(claimContent.diagnosisCode3);

                // Diagnosis Code 4
                const diagnosisCode4Field = form.getTextField("diagnosis4");
                diagnosisCode4Field.setText(claimContent.diagnosisCode4);

                // Diagnosis Code 5
                const diagnosisCode5Field = form.getTextField("diagnosis5");
                diagnosisCode5Field.setText(claimContent.diagnosisCode5);

                // Diagnosis Code 6
                const diagnosisCode6Field = form.getTextField("diagnosis6");
                diagnosisCode6Field.setText(claimContent.diagnosisCode6);

                // Diagnosis Code 7
                const diagnosisCode7Field = form.getTextField("diagnosis7");
                diagnosisCode7Field.setText(claimContent.diagnosisCode7);

                // Diagnosis Code 8
                const diagnosisCode8Field = form.getTextField("diagnosis8");
                diagnosisCode8Field.setText(claimContent.diagnosisCode8);

                // Diagnosis Code 9
                const diagnosisCode9Field = form.getTextField("diagnosis9");
                diagnosisCode9Field.setText(claimContent.diagnosisCode9);

                // Diagnosis Code 10
                const diagnosisCode10Field = form.getTextField("diagnosis10");
                diagnosisCode10Field.setText(claimContent.diagnosisCode10);

                // Diagnosis Code 11
                const diagnosisCode11Field = form.getTextField("diagnosis11");
                diagnosisCode11Field.setText(claimContent.diagnosisCode11);

                // Diagnosis Code 12
                const diagnosisCode12Field = form.getTextField("diagnosis12");
                diagnosisCode12Field.setText(claimContent.diagnosisCode12);

                // 17 -> Name of Referring Provider
                const referringProviderNameField =
                    form.getTextField("ref_physician");
                referringProviderNameField.setText(
                    `${claimContent.referringProviderFirstName ?? ""} ${
                        claimContent.referringProviderMiddleName ?? ""
                    } ${claimContent.referringProviderLastName ?? ""} ${
                        claimContent.referringProviderSuffix ?? ""
                    }`
                );

                // 17b -> Referring Provider NPI
                const referringProviderNPIField =
                    form.getTextField("id_physician");
                referringProviderNPIField.setText(
                    claimContent.referringProviderNPI ?? ""
                );

                // 20 -> Outside lab
                const outsideLabField = form.getCheckBox("lab");
                const labWidgets = outsideLabField.acroField.getWidgets();

                if (claimContent.outsideLabCharge) {
                    labWidgets[0].setAppearanceState(
                        labWidgets[0].getOnValue() as PDFName
                    );
                } else {
                    labWidgets[1].setAppearanceState(
                        labWidgets[1].getOnValue() as PDFName
                    );
                }

                // 20 -> Outside lab charge
                const outsideLabChargeField = form.getTextField("charge");
                outsideLabChargeField.setText(
                    claimContent.outsideLabCharge
                        ? `${parseFloat(claimContent.outsideLabCharge).toFixed(
                              2
                          )}`
                        : ""
                );

                // **** END DIAGNOSIS INFO SECTION **** //

                // **** SERVICES INFO SECTION **** //
                // 24.A Date of Service
                // Date Row <-> 1
                const fromDate1MMField = form.getTextField("sv1_mm_from");
                fromDate1MMField.setText(
                    claimContent.serviceDetails[0] &&
                        claimContent.serviceDetails[0].fromDate
                        ? formatDate(
                              claimContent.serviceDetails[0].fromDate,
                              true,
                              "M"
                          )
                        : ""
                );

                const toDate1MMField = form.getTextField("sv1_mm_end");
                toDate1MMField.setText(
                    claimContent.serviceDetails[0] &&
                        claimContent.serviceDetails[0].toDate
                        ? formatDate(
                              claimContent.serviceDetails[0].toDate,
                              true,
                              "M"
                          )
                        : ""
                );

                const fromDate1DDField = form.getTextField("sv1_dd_from");
                fromDate1DDField.setText(
                    claimContent.serviceDetails[0] &&
                        claimContent.serviceDetails[0].fromDate
                        ? formatDate(
                              claimContent.serviceDetails[0].fromDate,
                              true,
                              "d"
                          )
                        : ""
                );

                const toDate1DDField = form.getTextField("sv1_dd_end");
                toDate1DDField.setText(
                    claimContent.serviceDetails[0] &&
                        claimContent.serviceDetails[0].toDate
                        ? formatDate(
                              claimContent.serviceDetails[0].toDate,
                              true,
                              "d"
                          )
                        : ""
                );

                const fromDate1YYField = form.getTextField("sv1_yy_from");
                fromDate1YYField.setText(
                    claimContent.serviceDetails[0] &&
                        claimContent.serviceDetails[0].fromDate
                        ? formatDate(
                              claimContent.serviceDetails[0].fromDate,
                              true,
                              "y"
                          ).slice(2)
                        : ""
                );

                const toDate1YYField = form.getTextField("sv1_yy_end");
                toDate1YYField.setText(
                    claimContent.serviceDetails[0] &&
                        claimContent.serviceDetails[0].toDate
                        ? formatDate(
                              claimContent.serviceDetails[0].toDate,
                              true,
                              "y"
                          ).slice(2)
                        : ""
                );

                // Date Row <-> 2
                const fromDate2MMField = form.getTextField("sv2_mm_from");
                fromDate2MMField.setText(
                    claimContent.serviceDetails[1] &&
                        claimContent.serviceDetails[1].fromDate
                        ? formatDate(
                              claimContent.serviceDetails[1].fromDate,
                              true,
                              "M"
                          )
                        : ""
                );

                const toDate2MMField = form.getTextField("sv2_mm_end");
                toDate2MMField.setText(
                    claimContent.serviceDetails[1] &&
                        claimContent.serviceDetails[1].toDate
                        ? formatDate(
                              claimContent.serviceDetails[1].toDate,
                              true,
                              "M"
                          )
                        : ""
                );

                const fromDate2DDField = form.getTextField("sv2_dd_from");
                fromDate2DDField.setText(
                    claimContent.serviceDetails[1] &&
                        claimContent.serviceDetails[1].fromDate
                        ? formatDate(
                              claimContent.serviceDetails[1].fromDate,
                              true,
                              "d"
                          )
                        : ""
                );

                const toDate2DDField = form.getTextField("sv2_dd_end");
                toDate2DDField.setText(
                    claimContent.serviceDetails[1] &&
                        claimContent.serviceDetails[1].toDate
                        ? formatDate(
                              claimContent.serviceDetails[1].toDate,
                              true,
                              "d"
                          )
                        : ""
                );

                const fromDate2YYField = form.getTextField("sv2_yy_from");
                fromDate2YYField.setText(
                    claimContent.serviceDetails[1] &&
                        claimContent.serviceDetails[1].fromDate
                        ? formatDate(
                              claimContent.serviceDetails[1].fromDate,
                              true,
                              "y"
                          ).slice(2)
                        : ""
                );

                const toDate2YYField = form.getTextField("sv2_yy_end");
                toDate2YYField.setText(
                    claimContent.serviceDetails[1] &&
                        claimContent.serviceDetails[1].toDate
                        ? formatDate(
                              claimContent.serviceDetails[1].toDate,
                              true,
                              "y"
                          ).slice(2)
                        : ""
                );

                // Date Row <-> 3
                const fromDate3MMField = form.getTextField("sv3_mm_from");
                fromDate3MMField.setText(
                    claimContent.serviceDetails[2] &&
                        claimContent.serviceDetails[2].fromDate
                        ? formatDate(
                              claimContent.serviceDetails[2].fromDate,
                              true,
                              "M"
                          )
                        : ""
                );

                const toDate3MMField = form.getTextField("sv3_mm_end");
                toDate3MMField.setText(
                    claimContent.serviceDetails[2] &&
                        claimContent.serviceDetails[2].toDate
                        ? formatDate(
                              claimContent.serviceDetails[2].toDate,
                              true,
                              "M"
                          )
                        : ""
                );

                const fromDate3DDField = form.getTextField("sv3_dd_from");
                fromDate3DDField.setText(
                    claimContent.serviceDetails[2] &&
                        claimContent.serviceDetails[2].fromDate
                        ? formatDate(
                              claimContent.serviceDetails[2].fromDate,
                              true,
                              "d"
                          )
                        : ""
                );

                const toDate3DDField = form.getTextField("sv3_dd_end");
                toDate3DDField.setText(
                    claimContent.serviceDetails[2] &&
                        claimContent.serviceDetails[2].toDate
                        ? formatDate(
                              claimContent.serviceDetails[2].toDate,
                              true,
                              "d"
                          )
                        : ""
                );

                const fromDate3YYField = form.getTextField("sv3_yy_from");
                fromDate3YYField.setText(
                    claimContent.serviceDetails[2] &&
                        claimContent.serviceDetails[2].fromDate
                        ? formatDate(
                              claimContent.serviceDetails[2].fromDate,
                              true,
                              "y"
                          ).slice(2)
                        : ""
                );

                const toDate3YYField = form.getTextField("sv3_yy_end");
                toDate3YYField.setText(
                    claimContent.serviceDetails[2] &&
                        claimContent.serviceDetails[2].toDate
                        ? formatDate(
                              claimContent.serviceDetails[2].toDate,
                              true,
                              "y"
                          ).slice(2)
                        : ""
                );

                // From Date Row <-> 4
                const fromDate4MMField = form.getTextField("sv4_mm_from");
                fromDate4MMField.setText(
                    claimContent.serviceDetails[3] &&
                        claimContent.serviceDetails[3].fromDate
                        ? formatDate(
                              claimContent.serviceDetails[3].fromDate,
                              true,
                              "M"
                          )
                        : ""
                );

                const toDate4MMField = form.getTextField("sv4_mm_end");
                toDate4MMField.setText(
                    claimContent.serviceDetails[3] &&
                        claimContent.serviceDetails[3].toDate
                        ? formatDate(
                              claimContent.serviceDetails[3].toDate,
                              true,
                              "M"
                          )
                        : ""
                );

                const fromDate4DDField = form.getTextField("sv4_dd_from");
                fromDate4DDField.setText(
                    claimContent.serviceDetails[3] &&
                        claimContent.serviceDetails[3].fromDate
                        ? formatDate(
                              claimContent.serviceDetails[3].fromDate,
                              true,
                              "d"
                          )
                        : ""
                );

                const toDate4DDField = form.getTextField("sv4_dd_end");
                toDate4DDField.setText(
                    claimContent.serviceDetails[3] &&
                        claimContent.serviceDetails[3].toDate
                        ? formatDate(
                              claimContent.serviceDetails[3].toDate,
                              true,
                              "d"
                          )
                        : ""
                );

                const fromDate4YYField = form.getTextField("sv4_yy_from");
                fromDate4YYField.setText(
                    claimContent.serviceDetails[3] &&
                        claimContent.serviceDetails[3].fromDate
                        ? formatDate(
                              claimContent.serviceDetails[3].fromDate,
                              true,
                              "y"
                          ).slice(2)
                        : ""
                );

                const toDate4YYField = form.getTextField("sv4_yy_end");
                toDate4YYField.setText(
                    claimContent.serviceDetails[3] &&
                        claimContent.serviceDetails[3].toDate
                        ? formatDate(
                              claimContent.serviceDetails[3].toDate,
                              true,
                              "y"
                          ).slice(2)
                        : ""
                );

                // From Date Row <-> 5
                const fromDate5MMField = form.getTextField("sv5_mm_from");
                fromDate5MMField.setText(
                    claimContent.serviceDetails[4] &&
                        claimContent.serviceDetails[4].fromDate
                        ? formatDate(
                              claimContent.serviceDetails[4].fromDate,
                              true,
                              "M"
                          )
                        : ""
                );

                const toDate5MMField = form.getTextField("sv5_mm_end");
                toDate5MMField.setText(
                    claimContent.serviceDetails[4] &&
                        claimContent.serviceDetails[4].toDate
                        ? formatDate(
                              claimContent.serviceDetails[4].toDate,
                              true,
                              "M"
                          )
                        : ""
                );

                const fromDate5DDField = form.getTextField("sv5_dd_from");
                fromDate5DDField.setText(
                    claimContent.serviceDetails[4] &&
                        claimContent.serviceDetails[4].fromDate
                        ? formatDate(
                              claimContent.serviceDetails[4].fromDate,
                              true,
                              "d"
                          )
                        : ""
                );

                const toDate5DDField = form.getTextField("sv5_dd_end");
                toDate5DDField.setText(
                    claimContent.serviceDetails[4] &&
                        claimContent.serviceDetails[4].toDate
                        ? formatDate(
                              claimContent.serviceDetails[4].toDate,
                              true,
                              "d"
                          )
                        : ""
                );

                const fromDate5YYField = form.getTextField("sv5_yy_from");
                fromDate5YYField.setText(
                    claimContent.serviceDetails[4] &&
                        claimContent.serviceDetails[4].fromDate
                        ? formatDate(
                              claimContent.serviceDetails[4].fromDate,
                              true,
                              "y"
                          ).slice(2)
                        : ""
                );

                const toDate5YYField = form.getTextField("sv5_yy_end");
                toDate5YYField.setText(
                    claimContent.serviceDetails[4] &&
                        claimContent.serviceDetails[4].toDate
                        ? formatDate(
                              claimContent.serviceDetails[4].toDate,
                              true,
                              "y"
                          ).slice(2)
                        : ""
                );

                // From Date Row <-> 6
                const fromDate6MMField = form.getTextField("sv6_mm_from");
                fromDate6MMField.setText(
                    claimContent.serviceDetails[5] &&
                        claimContent.serviceDetails[5].fromDate
                        ? formatDate(
                              claimContent.serviceDetails[5].fromDate,
                              true,
                              "M"
                          )
                        : ""
                );

                const toDate6MMField = form.getTextField("sv6_mm_end");
                toDate6MMField.setText(
                    claimContent.serviceDetails[5] &&
                        claimContent.serviceDetails[5].toDate
                        ? formatDate(
                              claimContent.serviceDetails[5].toDate,
                              true,
                              "M"
                          )
                        : ""
                );

                const fromDate6DDField = form.getTextField("sv6_dd_from");
                fromDate6DDField.setText(
                    claimContent.serviceDetails[5] &&
                        claimContent.serviceDetails[5].fromDate
                        ? formatDate(
                              claimContent.serviceDetails[5].fromDate,
                              true,
                              "d"
                          )
                        : ""
                );

                const toDate6DDField = form.getTextField("sv6_dd_end");
                toDate6DDField.setText(
                    claimContent.serviceDetails[5] &&
                        claimContent.serviceDetails[5].toDate
                        ? formatDate(
                              claimContent.serviceDetails[5].toDate,
                              true,
                              "d"
                          )
                        : ""
                );

                const fromDate6YYField = form.getTextField("sv6_yy_from");
                fromDate6YYField.setText(
                    claimContent.serviceDetails[5] &&
                        claimContent.serviceDetails[5].fromDate
                        ? formatDate(
                              claimContent.serviceDetails[5].fromDate,
                              true,
                              "y"
                          ).slice(2)
                        : ""
                );

                const toDate6YYField = form.getTextField("sv6_yy_end");
                toDate6YYField.setText(
                    claimContent.serviceDetails[5] &&
                        claimContent.serviceDetails[5].toDate
                        ? formatDate(
                              claimContent.serviceDetails[5].toDate,
                              true,
                              "y"
                          ).slice(2)
                        : ""
                );

                // Place of Service 1
                const place1Field = form.getTextField("place1");
                place1Field.setText(
                    claimContent.serviceDetails[0]
                        ? claimContent.serviceDetails[0].placeOfServiceCode?.slice(
                              0,
                              3
                          )
                        : ""
                );

                // Place of Service 2
                const place2Field = form.getTextField("place2");
                place2Field.setText(
                    claimContent.serviceDetails[1]
                        ? claimContent.serviceDetails[1].placeOfServiceCode?.slice(
                              0,
                              3
                          )
                        : ""
                );

                // Place of Service 3
                const place3Field = form.getTextField("place3");
                place3Field.setText(
                    claimContent.serviceDetails[2]
                        ? claimContent.serviceDetails[2].placeOfServiceCode?.slice(
                              0,
                              3
                          )
                        : ""
                );

                // Place of Service 4
                const place4Field = form.getTextField("place4");
                place4Field.setText(
                    claimContent.serviceDetails[3]
                        ? claimContent.serviceDetails[3].placeOfServiceCode?.slice(
                              0,
                              3
                          )
                        : ""
                );

                // Place of Service 5
                const place5Field = form.getTextField("place5");
                place5Field.setText(
                    claimContent.serviceDetails[4]
                        ? claimContent.serviceDetails[4].placeOfServiceCode?.slice(
                              0,
                              3
                          )
                        : ""
                );

                // Place of Service 6
                const place6Field = form.getTextField("place6");
                place6Field.setText(
                    claimContent.serviceDetails[5]
                        ? claimContent.serviceDetails[5].placeOfServiceCode?.slice(
                              0,
                              3
                          )
                        : ""
                );

                // CPT 1
                const cpt1Field = form.getTextField("cpt1");
                cpt1Field.setText(
                    claimContent.serviceDetails[0]
                        ? claimContent.serviceDetails[0].procedureCode
                        : ""
                );

                // CPT 2
                const cpt2Field = form.getTextField("cpt2");
                cpt2Field.setText(
                    claimContent.serviceDetails[1]
                        ? claimContent.serviceDetails[1].procedureCode
                        : ""
                );

                // CPT 3
                const cpt3Field = form.getTextField("cpt3");
                cpt3Field.setText(
                    claimContent.serviceDetails[2]
                        ? claimContent.serviceDetails[2].procedureCode
                        : ""
                );

                // CPT 4
                const cpt4Field = form.getTextField("cpt4");
                cpt4Field.setText(
                    claimContent.serviceDetails[3]
                        ? claimContent.serviceDetails[3].procedureCode
                        : ""
                );

                // CPT 5
                const cpt5Field = form.getTextField("cpt5");
                cpt5Field.setText(
                    claimContent.serviceDetails[4]
                        ? claimContent.serviceDetails[4].procedureCode
                        : ""
                );

                // CPT 6
                const cpt6Field = form.getTextField("cpt6");
                cpt6Field.setText(
                    claimContent.serviceDetails[5]
                        ? claimContent.serviceDetails[5].procedureCode
                        : ""
                );

                // Modifier 1
                const modifier1Field = form.getTextField("mod1");
                modifier1Field.setText(
                    claimContent.serviceDetails[0] &&
                        claimContent.serviceDetails[0].modifiers
                        ? claimContent.serviceDetails[0].modifiers[0]
                        : ""
                );

                const modifier1AField = form.getTextField("mod1a");
                modifier1AField.setText(
                    claimContent.serviceDetails[0] &&
                        claimContent.serviceDetails[0].modifiers
                        ? claimContent.serviceDetails[0].modifiers[1]
                        : ""
                );

                const modifier1BField = form.getTextField("mod1b");
                modifier1BField.setText(
                    claimContent.serviceDetails[0] &&
                        claimContent.serviceDetails[0].modifiers
                        ? claimContent.serviceDetails[0].modifiers[2]
                        : ""
                );

                const modifier1CField = form.getTextField("mod1c");
                modifier1CField.setText(
                    claimContent.serviceDetails[0] &&
                        claimContent.serviceDetails[0].modifiers
                        ? claimContent.serviceDetails[0].modifiers[3]
                        : ""
                );

                // Modifier 2
                const modifier2Field = form.getTextField("mod2");
                modifier2Field.setText(
                    claimContent.serviceDetails[1] &&
                        claimContent.serviceDetails[1].modifiers
                        ? claimContent.serviceDetails[1].modifiers[0]
                        : ""
                );

                const modifier2AField = form.getTextField("mod2a");
                modifier2AField.setText(
                    claimContent.serviceDetails[1] &&
                        claimContent.serviceDetails[1].modifiers
                        ? claimContent.serviceDetails[1].modifiers[1]
                        : ""
                );

                const modifier2BField = form.getTextField("mod2b");
                modifier2BField.setText(
                    claimContent.serviceDetails[1] &&
                        claimContent.serviceDetails[1].modifiers
                        ? claimContent.serviceDetails[1].modifiers[2]
                        : ""
                );

                const modifier2CField = form.getTextField("mod2c");
                modifier2CField.setText(
                    claimContent.serviceDetails[1] &&
                        claimContent.serviceDetails[1].modifiers
                        ? claimContent.serviceDetails[1].modifiers[3]
                        : ""
                );

                // Modifier 3
                const modifier3Field = form.getTextField("mod3");
                modifier3Field.setText(
                    claimContent.serviceDetails[2] &&
                        claimContent.serviceDetails[2].modifiers
                        ? claimContent.serviceDetails[2].modifiers[0]
                        : ""
                );

                const modifier3AField = form.getTextField("mod3a");
                modifier3AField.setText(
                    claimContent.serviceDetails[2] &&
                        claimContent.serviceDetails[2].modifiers
                        ? claimContent.serviceDetails[2].modifiers[1]
                        : ""
                );

                const modifier3BField = form.getTextField("mod3b");
                modifier3BField.setText(
                    claimContent.serviceDetails[2] &&
                        claimContent.serviceDetails[2].modifiers
                        ? claimContent.serviceDetails[2].modifiers[2]
                        : ""
                );

                const modifier3CField = form.getTextField("mod3c");
                modifier3CField.setText(
                    claimContent.serviceDetails[2] &&
                        claimContent.serviceDetails[2].modifiers
                        ? claimContent.serviceDetails[2].modifiers[3]
                        : ""
                );

                // Modifier 3
                const modifier4Field = form.getTextField("mod4");
                modifier4Field.setText(
                    claimContent.serviceDetails[3] &&
                        claimContent.serviceDetails[3].modifiers
                        ? claimContent.serviceDetails[3].modifiers[0]
                        : ""
                );

                const modifier4AField = form.getTextField("mod4a");
                modifier4AField.setText(
                    claimContent.serviceDetails[3] &&
                        claimContent.serviceDetails[3].modifiers
                        ? claimContent.serviceDetails[3].modifiers[1]
                        : ""
                );

                const modifier4BField = form.getTextField("mod4b");
                modifier4BField.setText(
                    claimContent.serviceDetails[3] &&
                        claimContent.serviceDetails[3].modifiers
                        ? claimContent.serviceDetails[3].modifiers[2]
                        : ""
                );

                const modifier4CField = form.getTextField("mod4c");
                modifier4CField.setText(
                    claimContent.serviceDetails[3] &&
                        claimContent.serviceDetails[3].modifiers
                        ? claimContent.serviceDetails[3].modifiers[3]
                        : ""
                );

                // Diagnosis Pointer
                const diagPointer1Field = form.getTextField("diag1");
                diagPointer1Field.setText(
                    claimContent.serviceDetails[0] &&
                        claimContent.serviceDetails[0].diagnosisPointers
                        ? claimContent.serviceDetails[0].diagnosisPointers[0]
                        : ""
                );

                const diagPointer2Field = form.getTextField("diag2");
                diagPointer2Field.setText(
                    claimContent.serviceDetails[1] &&
                        claimContent.serviceDetails[1].diagnosisPointers
                        ? claimContent.serviceDetails[1].diagnosisPointers[0]
                        : ""
                );

                const diagPointer3Field = form.getTextField("diag3");
                diagPointer3Field.setText(
                    claimContent.serviceDetails[2] &&
                        claimContent.serviceDetails[2].diagnosisPointers
                        ? claimContent.serviceDetails[2].diagnosisPointers[0]
                        : ""
                );

                const diagPointer4Field = form.getTextField("diag4");
                diagPointer4Field.setText(
                    claimContent.serviceDetails[3] &&
                        claimContent.serviceDetails[3].diagnosisPointers
                        ? claimContent.serviceDetails[3].diagnosisPointers[0]
                        : ""
                );

                const diagPointer5Field = form.getTextField("diag5");
                diagPointer5Field.setText(
                    claimContent.serviceDetails[4] &&
                        claimContent.serviceDetails[4].diagnosisPointers
                        ? claimContent.serviceDetails[4].diagnosisPointers[0]
                        : ""
                );

                const diagPointer6Field = form.getTextField("diag6");
                diagPointer6Field.setText(
                    claimContent.serviceDetails[5] &&
                        claimContent.serviceDetails[5].diagnosisPointers
                        ? claimContent.serviceDetails[5].diagnosisPointers[0]
                        : ""
                );

                const chargesField = form.getTextField("ch1");
                chargesField.setText(
                    claimContent.serviceDetails[0] &&
                        claimContent.serviceDetails[0].charge
                        ? `${parseFloat(
                              String(claimContent.serviceDetails[0].charge)
                          ).toFixed(2)}    `
                        : ""
                );

                const charges2Field = form.getTextField("ch2");
                charges2Field.setText(
                    claimContent.serviceDetails[1] &&
                        claimContent.serviceDetails[1].charge
                        ? `${parseFloat(
                              String(claimContent.serviceDetails[1].charge)
                          ).toFixed(2)}    `
                        : ""
                );

                const charges3Field = form.getTextField("ch3");
                charges3Field.setText(
                    claimContent.serviceDetails[2] &&
                        claimContent.serviceDetails[2].charge
                        ? `${parseFloat(
                              String(claimContent.serviceDetails[2].charge)
                          ).toFixed(2)}    `
                        : ""
                );

                const charges4Field = form.getTextField("ch4");
                charges4Field.setText(
                    claimContent.serviceDetails[3] &&
                        claimContent.serviceDetails[3].charge
                        ? `${parseFloat(
                              String(claimContent.serviceDetails[3].charge)
                          ).toFixed(2)}    `
                        : ""
                );

                const charges5Field = form.getTextField("ch5");
                charges5Field.setText(
                    claimContent.serviceDetails[4] &&
                        claimContent.serviceDetails[4].charge
                        ? `${parseFloat(
                              String(claimContent.serviceDetails[4].charge)
                          ).toFixed(2)}    `
                        : ""
                );

                const charges6Field = form.getTextField("ch6");
                charges6Field.setText(
                    claimContent.serviceDetails[5] &&
                        claimContent.serviceDetails[5].charge
                        ? `${parseFloat(
                              String(claimContent.serviceDetails[5].charge)
                          ).toFixed(2)}    `
                        : ""
                );

                const day1Field = form.getTextField("day1");
                day1Field.setText(
                    claimContent.serviceDetails[0] &&
                        claimContent.serviceDetails[0].units
                        ? String(claimContent.serviceDetails[0].units)
                        : ""
                );

                const day2Field = form.getTextField("day2");
                day2Field.setText(
                    claimContent.serviceDetails[1] &&
                        claimContent.serviceDetails[1].units
                        ? String(claimContent.serviceDetails[1].units)
                        : ""
                );

                const day3Field = form.getTextField("day3");
                day3Field.setText(
                    claimContent.serviceDetails[2] &&
                        claimContent.serviceDetails[2].units
                        ? String(claimContent.serviceDetails[2].units)
                        : ""
                );

                const day4Field = form.getTextField("day4");
                day4Field.setText(
                    claimContent.serviceDetails[3] &&
                        claimContent.serviceDetails[3].units
                        ? String(claimContent.serviceDetails[3].units)
                        : ""
                );

                const day5Field = form.getTextField("day5");
                day5Field.setText(
                    claimContent.serviceDetails[4] &&
                        claimContent.serviceDetails[4].units
                        ? String(claimContent.serviceDetails[4].units)
                        : ""
                );

                const day6Field = form.getTextField("day6");
                day6Field.setText(
                    claimContent.serviceDetails[5] &&
                        claimContent.serviceDetails[5].units
                        ? String(claimContent.serviceDetails[5].units)
                        : ""
                );

                const npi1Field = form.getTextField("local1");
                npi1Field.setText(
                    claimContent.serviceDetails[0] &&
                        claimContent.serviceDetails[0].renderingProviderNPI
                        ? claimContent.serviceDetails[0].renderingProviderNPI
                        : ""
                );

                const npi2Field = form.getTextField("local2");
                npi2Field.setText(
                    claimContent.serviceDetails[1] &&
                        claimContent.serviceDetails[1].renderingProviderNPI
                        ? claimContent.serviceDetails[1].renderingProviderNPI
                        : ""
                );

                const npi3Field = form.getTextField("local3");
                npi3Field.setText(
                    claimContent.serviceDetails[2] &&
                        claimContent.serviceDetails[2].renderingProviderNPI
                        ? claimContent.serviceDetails[2].renderingProviderNPI
                        : ""
                );

                const npi4Field = form.getTextField("local4");
                npi4Field.setText(
                    claimContent.serviceDetails[3] &&
                        claimContent.serviceDetails[3].renderingProviderNPI
                        ? claimContent.serviceDetails[3].renderingProviderNPI
                        : ""
                );

                const npi5Field = form.getTextField("local5");
                npi5Field.setText(
                    claimContent.serviceDetails[4] &&
                        claimContent.serviceDetails[4].renderingProviderNPI
                        ? claimContent.serviceDetails[4].renderingProviderNPI
                        : ""
                );

                const npi6Field = form.getTextField("local6");
                npi6Field.setText(
                    claimContent.serviceDetails[5] &&
                        claimContent.serviceDetails[5].renderingProviderNPI
                        ? claimContent.serviceDetails[5].renderingProviderNPI
                        : ""
                );

                // 25 - Federal TaxID
                const taxIdField = form.getTextField("tax_id");
                taxIdField.setText(claimContent.federalTaxId);

                // 25 - Federal TaxID type
                if (claimContent.federalTaxIdType) {
                    const taxIdTypeField = form.getCheckBox("ssn");
                    const widgets = taxIdTypeField.acroField.getWidgets();

                    if (claimContent.federalTaxIdType === "S") {
                        widgets[0].setAppearanceState(
                            widgets[0].getOnValue() as PDFName
                        );
                    } else if (claimContent.federalTaxIdType === "E") {
                        widgets[1].setAppearanceState(
                            widgets[1].getOnValue() as PDFName
                        );
                    }
                }

                // 26 - Patient Account Number
                const patientAccountField = form.getTextField("pt_account");
                patientAccountField.setText(
                    claimContent.claimReferenceOrPatientAcctNo
                );

                // 27. Accept assignment
                const acceptAssignmentField = form.getCheckBox("assignment");
                const acceptAssignWidgets =
                    acceptAssignmentField.acroField.getWidgets();

                if (claimContent.acceptAssignment === "Y") {
                    acceptAssignWidgets[0].setAppearanceState(
                        acceptAssignWidgets[0].getOnValue() as PDFName
                    );
                } else {
                    acceptAssignWidgets[1].setAppearanceState(
                        acceptAssignWidgets[1].getOnValue() as PDFName
                    );
                }

                // 28. Total charge empty spaces are important for form view
                const totalChargeField = form.getTextField("t_charge");
                totalChargeField.setText(
                    `${parseFloat(String(claimContent.totalCharge)).toFixed(
                        2
                    )}  `
                );

                // 29. amount paid
                const amountPaidField = form.getTextField("amt_paid");
                amountPaidField.setText(
                    `${parseFloat(String(claimContent.amountPaid)).toFixed(
                        2
                    )}  `
                );

                // 31. -> Physician signature
                const physicianSignatureField = form.getTextField(
                    "physician_signature"
                );
                physicianSignatureField.setText(
                    `${claimContent.renderingProviderFirstName ?? ""} ${
                        claimContent.renderingProviderMiddleName ?? ""
                    } ${claimContent.renderingProviderLastName ?? ""} ${
                        claimContent.renderingProviderSuffix ?? ""
                    }`
                );

                // 31 -> Physician signature date
                const physicianSignatureDateField =
                    form.getTextField("physician_date");
                physicianSignatureDateField.setText(
                    claimContent.renderingProviderSignatureDate.replace(
                        /\//g,
                        ""
                    )
                );

                // 32 -> Facility Location Info - Name
                const facilityNameField = form.getTextField("fac_name");
                facilityNameField.setText(claimContent.facilityName);

                // 32 -> Facility Location Info -> Street
                const facilityAddressField = form.getTextField("fac_street");
                facilityAddressField.setText(
                    `${claimContent.facilityAddress1 ?? ""} ${
                        claimContent.facilityAddress2 ?? ""
                    }`
                );

                // 32 -> Facility Location Info -> Location
                const facilityLocationField = form.getTextField("fac_location");
                facilityLocationField.setText(
                    `${
                        claimContent.facilityCity
                            ? `${claimContent.facilityCity},`
                            : ""
                    } ${claimContent.facilityState ?? ""} ${
                        claimContent.facilityZip ?? ""
                    }`
                );

                // 32 a -> Facility Location NPI
                const facilityNPIField = form.getTextField("pin1");
                facilityNPIField.setText(claimContent.facilityNPI ?? "");

                // 33 -> Billing Provider Info
                const billingProviderNameField = form.getTextField("doc_name");
                billingProviderNameField.setText(
                    claimContent.billingProviderName
                );

                const billingProviderStreetField =
                    form.getTextField("doc_street");
                billingProviderStreetField.setText(
                    `${claimContent.billingProviderAddress1 ?? ""} ${
                        claimContent.billingProviderAddress2 ?? ""
                    }`
                );

                const billingProviderLocationField =
                    form.getTextField("doc_location");
                billingProviderLocationField.setText(
                    `${
                        claimContent.billingProviderCity
                            ? `${claimContent.billingProviderCity},`
                            : ""
                    } ${claimContent.billingProviderState ?? ""} ${
                        claimContent.billingProviderZipCode ?? ""
                    }`
                );

                const billingProviderPhoneField =
                    form.getTextField("doc_phone");
                billingProviderPhoneField.setText(
                    getPhoneNumberParts(claimContent.billingProviderPhone)[2]
                );

                const billingProviderPhoneAreaField =
                    form.getTextField("doc_phone area");
                billingProviderPhoneAreaField.setText(
                    getPhoneNumberParts(claimContent.billingProviderPhone)[1]
                );

                // 33B -> Billing Provider NPI
                const billingProviderPinField = form.getTextField("pin");
                billingProviderPinField.setText(
                    claimContent.billingProviderNPI
                );

                // **** END OF SERVICES INFO SECTION **** //
            }

            const pdfBytes = await pdfDoc.save();
            const blobObj = new Blob([pdfBytes], { type: "application/pdf" });
            const docUrl = URL.createObjectURL(blobObj);
            setPdfInfo(docUrl);
        }

        showPdf();
    }, [claimInView?.claim_content]);

    if (isLoading) {
        return (
            <Skeleton
                containerTestId="claims-form-loader"
                height="400px"
                width="100%"
            />
        );
    }

    if (error) {
        return (
            <ListState
                isError
                errorMsg="Could not load document at this time"
                stateHelperText="Try reloading the page to fix this error"
            />
        );
    }

    return (
        <Card>
            <iframe
                src={pdfInfo}
                data-testid="form-wrapper"
                title="Health Insurance Claim Form"
                className="relative h-[calc(100vh-200px)] w-full"
            />
        </Card>
    );
}

export default FillableClaimsForm;

import * as React from "react";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { ColumnDef, Row } from "@tanstack/react-table";
import { SearchInput, Table } from "@jhool-io/fe-components";
import { useFetchSuperviseesCaseload } from "../../hooks/providers.queries";
import {
    handleDisplayClientEmailCorrectly,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import { ISuperviseeCaseload } from "../../types/providers.types";
import ListState from "../../../../components/ListState/ListState";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import { NoteStatus } from "../../../notes/types/notes.types";
import MobileListItem from "../../../../shared-ui/MobileListItem/MobileListItem";
import { useAppSelector } from "../../../../hooks/useRedux";

const columns: ColumnDef<ISuperviseeCaseload>[] = [
    {
        accessorKey: "name",
        header: "PROVIDER NAME",
        cell: ({ row }) =>
            `${row.original.first_name} ${row.original.last_name}`,
    },
    {
        accessorKey: "credential",
        header: "Credential",
    },
    {
        accessorKey: "email",
        header: "PROVIDER EMAIL",
        cell: ({ row }) =>
            handleDisplayClientEmailCorrectly(row.original.email),
    },
    {
        accessorKey: "delivery_option",
        header: "LOCATIONS",
        cell: ({ row }) =>
            row.original.delivery_option.map((option, i) => {
                if (i + 1 === row.original.delivery_option.length) {
                    return `${removeEnumUnderscore(
                        makeStringFirstLetterCapital(option)
                    )}`;
                }
                return `${removeEnumUnderscore(
                    makeStringFirstLetterCapital(option)
                )}, `;
            }),
    },
    {
        accessorKey: "contact",
        header: "CONTACT",
    },
    {
        accessorKey: "npi",
        header: "NPI",
        cell: ({ row }) => row.original.npi || "--",
    },
    {
        accessorKey: "active_clients",
        header: "CLIENTS",
    },
    {
        accessorKey: "pending_notes",
        header: "NOTES PENDING APPROVAL",
        cell: ({ row }) => (
            <Link
                to={`/notes?tab=my_supervisees_notes&provider_name=${row.original.first_name} ${row.original.last_name}&status[]=${NoteStatus.PENDING_SUPERVISOR_REVIEW}`}
                className=" text-primary underline"
                onClick={(e) => e.stopPropagation()}
            >
                {row.original.pending_notes}
            </Link>
        ),
    },
    {
        accessorKey: "appointments",
        header: "APPOINTMENTS",
    },
];

export default function SuperviseeCaseload() {
    const [searchParams, setSearchParams] = useSearchParams();

    const params = useParams();

    const navigate = useNavigate();

    const providerId = params.providerId as string;

    const searchString = searchParams.get("search") || "";

    const { practice } = useAppSelector((state) => state.userPractice);

    const { data, isLoading, error, isSuccess } = useFetchSuperviseesCaseload(
        providerId || (practice?.provider_id as string),
        searchString
    );

    const handleRowClick = (row: Row<ISuperviseeCaseload>) => {
        if (practice?.provider_id) return;

        navigate(`/providers/${row.original.provider_id}`);
    };

    // onChange handler for search input
    const handleSetSearchString = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") searchParams.delete("search");
        else searchParams.set("search", e.target.value);

        setSearchParams(searchParams);
    };

    return (
        <>
            <div className="mb-32 bg-white p-16 rounded-r8">
                <SearchInput
                    containerClass="w-[400px]"
                    placeholder="Search"
                    onChange={handleSetSearchString}
                    defaultValue={searchString}
                />
            </div>
            {isLoading && (
                <Skeleton
                    type="table"
                    containerTestId="providers-loader"
                    width="100%"
                />
            )}
            {error && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display supervisees caseload at this time please try again later`
                    }
                />
            )}
            {data && isSuccess && data.data.length === 0 && (
                <ListState
                    listHeader="SUPERVISEES CASELOAD"
                    emptyMessage="No caseload for supervisees yet"
                    isError={false}
                    stateHelperText="Supervisees caseload will appear here when added"
                    emptyBtnProps={{ showButton: false }}
                />
            )}
            {data && isSuccess && data.data.length > 0 && (
                <>
                    <div className="hidden sm:block">
                        <Table
                            columns={columns}
                            data={data.data}
                            handleRowClick={handleRowClick}
                        />
                    </div>
                    <div role="table" className="block sm:hidden">
                        <div className="flex flex-col gap-y-12">
                            {data.data.map((supervisee) => (
                                <MobileListItem
                                    key={supervisee.provider_id}
                                    topChildren={
                                        <div className="flex items-center gap-x-8 text-base font-semibold capitalize">
                                            {`${supervisee.first_name} ${supervisee.last_name}`}{" "}
                                            <span className=" w-1 h-1 rounded-full bg-gray" />
                                            <span className=" text-sm font-medium">
                                                {supervisee.credential}
                                            </span>
                                        </div>
                                    }
                                    bottomChildren={
                                        <Link
                                            className="flex flex-col gap-y-8 w-full items-start"
                                            to={`/providers/${supervisee.provider_id}`}
                                        >
                                            <div className="flex items-center w-full text-left gap-x-4">
                                                <span className="w-1/2 text-gray font-semibold text-xs">
                                                    Email:
                                                </span>
                                                <span className="w-1/2 font-semibold text-xs">
                                                    {supervisee.email}
                                                </span>
                                            </div>
                                            <div className="flex items-center w-full text-left gap-x-4">
                                                <span className="w-1/2 text-gray font-semibold text-xs">
                                                    Locations:
                                                </span>
                                                <span className="w-1/2 font-semibold text-xs capitalize">
                                                    {supervisee.delivery_option.join(
                                                        ","
                                                    )}
                                                </span>
                                            </div>
                                            <div className="flex items-center w-full text-left gap-x-4">
                                                <span className="w-1/2 text-gray font-semibold text-xs">
                                                    Contact:
                                                </span>
                                                <span className="w-1/2 font-semibold text-xs">
                                                    {supervisee.contact}
                                                </span>
                                            </div>
                                            <div className="flex items-center w-full text-left gap-x-4">
                                                <span className="w-1/2 text-gray font-semibold text-xs">
                                                    NPI:
                                                </span>
                                                <span className="w-1/2 font-semibold text-xs">
                                                    {supervisee.npi}
                                                </span>
                                            </div>
                                            <div className="flex items-center w-full text-left gap-x-4">
                                                <span className="w-1/2 text-gray font-semibold text-xs">
                                                    Pending notes:
                                                </span>
                                                <span className="w-1/2 font-semibold text-xs">
                                                    <Link
                                                        onClick={(e) =>
                                                            e.stopPropagation()
                                                        }
                                                        className=" text-primary underline"
                                                        to={`/notes?tab=my_supervisees_notes&provider_name=${supervisee.first_name} ${supervisee.last_name}&status[]=${NoteStatus.PENDING_SUPERVISOR_REVIEW}`}
                                                    >
                                                        {
                                                            supervisee.pending_notes
                                                        }
                                                    </Link>
                                                </span>
                                            </div>
                                        </Link>
                                    }
                                />
                            ))}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

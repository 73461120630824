import React from "react";

export default function BellIcon() {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21 18.0677C19.5267 18.0677 18.3333 16.8744 18.3333 15.401V9.06771C18.3333 5.57037 15.4973 2.73438 12 2.73438C8.50267 2.73438 5.66667 5.57037 5.66667 9.06771V15.401C5.66667 16.8744 4.47333 18.0677 3 18.0677H21Z"
                stroke="#0B132B"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.238 21.0995L10.2379 21.0993C10.2276 21.0506 10.24 20.999 10.2715 20.9603C10.3037 20.9206 10.3507 20.8984 10.4007 20.8984H13.5993C13.6493 20.8984 13.6963 20.9206 13.7285 20.9603C13.7615 21.0009 13.7732 21.053 13.7634 21.0993L13.7634 21.0995C13.5797 21.972 12.8508 22.5651 12.0007 22.5651C11.1505 22.5651 10.4216 21.972 10.238 21.0995ZM12.0007 23.0651C13.0953 23.0651 14.022 22.2984 14.2527 21.2024C14.294 21.0064 14.2433 20.8011 14.1167 20.6451C13.99 20.4891 13.8007 20.3984 13.5993 20.3984H10.4007C10.1993 20.3984 10.01 20.4891 9.88334 20.6451C9.75667 20.8011 9.70734 21.0064 9.74867 21.2024C9.97934 22.2984 10.906 23.0651 12.0007 23.0651Z"
                stroke="#0B132B"
            />
        </svg>
    );
}

import * as React from "react";
import cn from "classnames";
import { useParams, useSearchParams } from "react-router-dom";
import { useIsMutating } from "@tanstack/react-query";
import { useFetchSessionNote } from "../../../hooks/queries/note";
import ListState from "../../ListState/ListState";
import AppointmentDetails from "../AppointmentDetails/AppointmentDetails";
import EditCancellation from "./EditCancellation/EditCancellation";
import styles from "./EditNote.module.scss";
import EditRecordOfDisclosure from "./EditRecordOfDisclosure/EditRecordOfDisclosure";
import EditSoapNote from "./EditSoapNote/EditSoapNote";
import EditSupervision from "./EditSupervision/EditSupervision";
import EditTermination from "./EditTermination/EditTermination";
import EditSafetyPlan from "./EditSafetyPlan/EditSafetyPlan";
import EditTreatmentReview from "./EditTreatmentReview/EditTreatmentReview";
import EditIndividualIntake from "./EditIndividualIntake/EditIndividualIntake";
import EditFamilyIntake from "./EditFamilyIntake/EditFamilyIntake";
import InfoIcon from "../../Icons/InfoIcon";
import EditGeneralNote from "./EditGeneralNote/EditGeneralNote";
import EditSupervisorCheckInNote from "./EditSupervisorCheckInNote/EditSupervisorCheckInNote";
import { useAppSelector } from "../../../hooks/useRedux";
import EditPauseNotification from "./EditPauseNotification/EditPauseNotification";
import {
    NoteStatus,
    NoteTypes,
} from "../../../modules/notes/types/notes.types";

export default function EditNote() {
    const [searchParams] = useSearchParams();

    // Get id from url params
    const params = useParams();
    const noteId = params.noteId as string;
    const clientId = params.clientId as string;

    const { isOpen } = useAppSelector((state) => state.nav);

    const timeLastSaved = searchParams.get("lastSaved") || "";

    // Get currentAction search param from url
    // helps us to determine what type of action is being carried
    // out on the note
    const currentAction = searchParams.get("action");

    // To determine if mutation is currently running, returns 0 || 1
    const isEditDraftNoteMutating = useIsMutating(["edit-note"]);

    // Hook for fetching note
    const { data, error, isLoading, isSuccess } = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    // Determines the edit form to display
    const handleEditNoteFormToShow = () => {
        switch (data?.data.type) {
            case NoteTypes.CANCELLATION_NOTE:
                return (
                    <EditCancellation
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.SUPERVISION_NOTE:
                return (
                    <EditSupervision
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.RECORD_OF_DISCLOSURE:
                return (
                    <EditRecordOfDisclosure
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.FAMILY_SOAP_NOTE:
                return (
                    <EditSoapNote
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.INDIVIDUAL_SOAP_NOTE:
                return (
                    <EditSoapNote
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.TERMINATION_NOTE:
                return (
                    <EditTermination
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.SAFETY_PLAN:
                return (
                    <EditSafetyPlan
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.INDIVIDUAL_TREATMENT_REVIEW:
                return (
                    <EditTreatmentReview
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.FAMILY_TREATMENT_REVIEW:
                return (
                    <EditTreatmentReview
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.INDIVIDUAL_INTAKE_NOTE:
                return (
                    <EditIndividualIntake
                        currentAction={currentAction}
                        noteDetails={data.data}
                        isMinor={false}
                    />
                );

            case NoteTypes.MINOR_INTAKE_NOTE:
                return (
                    <EditIndividualIntake
                        currentAction={currentAction}
                        noteDetails={data.data}
                        isMinor
                    />
                );

            case NoteTypes.FAMILY_INTAKE_NOTE:
                return (
                    <EditFamilyIntake
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.GENERAL_UPDATE_NOTE:
                return (
                    <EditGeneralNote
                        noteDetails={data.data}
                        currentAction={currentAction}
                    />
                );
            case NoteTypes.SUPERVISOR_CHECK_IN:
                return (
                    <EditSupervisorCheckInNote
                        noteDetails={data.data}
                        currentAction={currentAction}
                    />
                );
            case NoteTypes.PAUSE_NOTIFICATION_NOTE:
                return (
                    <EditPauseNotification
                        noteDetails={data.data}
                        currentAction={currentAction}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            {isLoading && (
                <div className="page-loader !h-[calc(100vh-72px-38px)] md:!h-[calc(100vh-72px-48px)]">
                    <span />
                </div>
            )}
            {error && (
                <ListState
                    isError
                    stateHelperText="Please submit a feedback or Try reloading this page"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display edit forms at this time, please try again later`
                    }
                />
            )}
            {isSuccess && data ? (
                <div>
                    {data.data.note_status === NoteStatus.DRAFT ? (
                        <div className={styles.wrapper}>
                            <div className={styles.wrapform}>
                                {isEditDraftNoteMutating > 0 ||
                                timeLastSaved ? (
                                    <div
                                        className={cn(styles.autosave, {
                                            [styles.autosave_full]: !isOpen,
                                        })}
                                    >
                                        {isEditDraftNoteMutating > 0 ? (
                                            <span className={styles.saving}>
                                                Autosaving...
                                            </span>
                                        ) : (
                                            <span className={styles.saved}>
                                                <InfoIcon />
                                                {`Autosaved at ${timeLastSaved}`}
                                            </span>
                                        )}
                                    </div>
                                ) : null}
                                <div className={styles.appointment}>
                                    <AppointmentDetails />
                                </div>

                                {handleEditNoteFormToShow()}
                            </div>
                        </div>
                    ) : (
                        <ListState
                            isError
                            stateHelperText="Please go to the create note screen"
                            errorMsg="You cannot edit this note because it is not a draft note"
                        />
                    )}
                </div>
            ) : null}
        </>
    );
}

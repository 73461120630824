import { SvgProps } from "../../utils/types";

export default function PinIcon({ fill, className }: SvgProps) {
    return (
        <svg
            width="10"
            height="12"
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M1.99316 5.28838V1.93359C1.85835 1.93359 1.72907 1.88004 1.63374 1.78472C1.53842 1.68939 1.48486 1.5601 1.48486 1.42529C1.48486 1.29048 1.53842 1.16119 1.63374 1.06587C1.72907 0.970545 1.85835 0.916992 1.99316 0.916992H8.09277C8.22758 0.916992 8.35687 0.970545 8.4522 1.06587C8.54752 1.16119 8.60107 1.29048 8.60107 1.42529C8.60107 1.5601 8.54752 1.68939 8.4522 1.78472C8.35687 1.88004 8.22758 1.93359 8.09277 1.93359V5.28838C8.56651 5.80685 8.82066 6.32226 8.95638 6.7289C9.03059 6.95154 9.0682 7.14063 9.08854 7.27787C9.09886 7.35035 9.10565 7.42329 9.10887 7.49644V7.51372L9.10938 7.52033V7.52439C9.10938 7.52439 9.10683 7.45781 9.10938 7.5249C9.10938 7.65971 9.05582 7.789 8.9605 7.88433C8.86517 7.97965 8.73588 8.0332 8.60107 8.0332H5.55127V10.5747C5.55127 10.7095 5.49772 10.8388 5.40239 10.9341C5.30707 11.0295 5.17778 11.083 5.04297 11.083C4.90816 11.083 4.77887 11.0295 4.68355 10.9341C4.58822 10.8388 4.53467 10.7095 4.53467 10.5747V8.0332H1.48486C1.35005 8.0332 1.22077 7.97965 1.12544 7.88433C1.03012 7.789 0.976562 7.65971 0.976562 7.5249V7.51372C0.976822 7.49099 0.977839 7.46827 0.979612 7.44561C0.983711 7.38951 0.989644 7.33357 0.997403 7.27787C1.01773 7.14063 1.05535 6.95154 1.12956 6.7289C1.26528 6.32226 1.51943 5.80685 1.99316 5.28787V5.28838ZM7.07617 1.93359H3.00977V5.4917C3.00974 5.6265 2.95616 5.75577 2.86083 5.85107C2.4303 6.2816 2.21478 6.704 2.1055 7.0166H7.98095C7.87166 6.704 7.65564 6.2816 7.22561 5.85107C7.17832 5.80392 7.14078 5.74792 7.11513 5.68625C7.08949 5.62459 7.07625 5.55848 7.07617 5.4917V1.93359Z"
                fill={fill || "white"}
            />
        </svg>
    );
}

import axios from "../utils/axios";

import {
    INewClientDetails,
    INewClientFee,
    EditClientBasicInfo,
    IClientNewInsuranceDetails,
    IClientEditPersonalInfo,
    IClientEditInsuranceDetails,
    IAddRelationInfo,
    INewCreditCardDetails,
    IClientFilterTypes,
    IClientDocumentFilters,
    ICardFilterTypes,
    IEditMemo,
} from "../utils/types/client";
import { handleRemoveEmptyParamFromQueryParams } from "../utils/helpers";

// Endpoint to fetch client basic and personal info
export async function fetchClientBasicAndPersonalInfo(clientId: string) {
    const response = await axios.get(`/clients/${clientId}`);
    return response.data;
}

export async function addClient(data: INewClientDetails) {
    const response = await axios.post(`/client`, data);
    return response.data;
}

// Endpoint to fetch client insurance history
export async function fetchClientInsuranceHistory(
    clientId: string,
    page = 1,
    limit = 20
) {
    const response = await axios.get(`/client/${clientId}/insurance`, {
        params: {
            page,
            limit,
        },
    });
    return response.data;
}

// Endpoint to fetch client list
export async function fetchClientList(filters: IClientFilterTypes) {
    const response = await axios.get(`/clients`, {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });
    return response.data;
}

// Endpoint to fetch card transaction history
export async function fetchCardTransactionHistory(
    clientId: string,
    paymentId: string,
    filters: ICardFilterTypes
) {
    const response = await axios.get(
        `/client/${clientId}/payment-cards/${paymentId}/transactions`,
        {
            params: handleRemoveEmptyParamFromQueryParams(filters),
        }
    );
    return response.data;
}

// Endpoint to fetch client documents list
export async function fetchClientDocuments(filters: IClientDocumentFilters) {
    const response = await axios.get(`/client/${filters.client_id}/documents`, {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });
    return response.data;
}

// Endpoint to fetch client credit cards list
export async function fetchClientCreditCards(clientId: string) {
    const response = await axios.get(`/client/${clientId}/payment-cards`);
    return response.data;
}

// Endpoint to Edit client basic info
export async function editClientBasicInfo(
    clientId: string,
    data: EditClientBasicInfo
) {
    const response = await axios.patch(`client/${clientId}/basic_info`, data);
    return response.data;
}

// Endpoint to add client insurance details
export async function addClientInsuranceDetails(
    clientId: string,
    data: IClientNewInsuranceDetails
) {
    const response = await axios.post(`/client/${clientId}/insurance`, data);
    return response.data;
}

// Endpoint to fetch all insurance providers
export async function fetchInsuranceProviders() {
    const response = await axios.get(`/insurance_providers`);
    return response.data;
}

// Endpoint to add client fees
export async function addClientFees(clientId: string, data: INewClientFee) {
    const response = await axios.post(`/client/${clientId}/fees`, data);
    return response.data;
}

// Endpoint to update client personal info
export async function editClientPersonalInfo(
    clientId: string,
    data: IClientEditPersonalInfo
) {
    const response = await axios.patch(
        `client/${clientId}/personal_info`,
        data
    );
    return response.data;
}

// Endpoint to edit client insurance details
export async function editClientInsuranceDetails(
    clientId: string,
    insuranceId: string,
    data: IClientEditInsuranceDetails
) {
    const response = await axios.patch(
        `client/${clientId}/insurance/${insuranceId}`,
        data
    );
    return response.data;
}

// Endpoint to delete insurance attachment
export async function deleteInsuranceAttachment(
    clientId: string,
    attachmentId: string
) {
    const response = await axios.delete(
        `/client/${clientId}/insurance/attachment/${attachmentId}`
    );
    return response.data;
}

// Endpoint to delete client document
export async function deleteClientDocument(
    clientId: string,
    documentId: string
) {
    const response = await axios.delete(
        `/client/${clientId}/documents/${documentId}`
    );
    return response.data;
}

// Endpoint to add client relation info
export async function addRelationInfo(data: IAddRelationInfo) {
    const response = await axios.post(`/client/relation_info`, data);
    return response.data;
}

// Endpoint to add client payment card
export async function addPaymentCard(
    clientId: string,
    data: INewCreditCardDetails
) {
    const response = await axios.post(
        `/client/${clientId}/payment-cards`,
        data
    );
    return response.data;
}

// Function to validate payment card
export async function validatePaymentCard(
    clientId: string,
    paymentCardId: string
) {
    const response = await axios.post(
        `/client/${clientId}/payment-cards/${paymentCardId}/validate`
    );
    return response.data;
}

// Function to add payment card label
export async function addPaymentCardLabel(
    clientId: string,
    paymentCardId: string,
    payload: {
        card_label: string;
    }
) {
    const response = await axios.patch(
        `/client/${clientId}/payment-cards/${paymentCardId}`,
        {
            ...payload,
        }
    );
    return response.data;
}

export async function deleteClientMemo(memoIds: string[]) {
    const response = await axios.delete(`/client/memo`, {
        data: { memo_ids: memoIds },
    });
    return response.data;
}

export async function editClientMemo(data: IEditMemo) {
    const response = await axios.patch(`/client/memo/${data.memoId}`, {
        ...data,
    });
    return response.data;
}

import cn from "classnames";
import styles from "./Progress.module.scss";

interface ProgressProps {
    /** Array of steps the progress component
        should have 
    */
    steps: string[];
    /** Current step of the progress component
         this value will be updated in the component
         in which it is being used
     */
    currentStep: string;
    /** Context in which progress bar is used
        just modal for now, more union types will be added
     */
    context?: "modal" | "others";
    /** additional classname */
    className?: string;
}

export default function Progress({
    steps,
    currentStep,
    className,
    context = "modal",
}: ProgressProps) {
    const stepsTuple = [...steps] as const;

    return (
        <div
            className={cn(styles.progress, className, {
                [styles.progress_modal]: context === "modal",
            })}
        >
            <div className={styles.steps}>
                {stepsTuple.map((item, i) => (
                    <span
                        role="presentation"
                        key={item}
                        className={cn(styles.step, {
                            [styles.step_active]:
                                stepsTuple[i] === currentStep ||
                                i <= stepsTuple.indexOf(currentStep),
                        })}
                    />
                ))}
            </div>
        </div>
    );
}

export default function UnpinIcon() {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.6198 10.977C13.8801 11.2373 13.8801 11.6593 13.6198 11.9196C13.3595 12.1799 12.9375 12.1799 12.6771 11.9196L10.7918 10.0343L10.7431 10.0836L10.3326 12.1368C10.293 12.3351 10.1955 12.5172 10.0524 12.6602C9.65257 13.0598 9.00454 13.0596 8.60489 12.6599L6.50048 10.555L3.67181 13.3836C3.4115 13.6439 2.98946 13.6439 2.72915 13.3836C2.46884 13.1233 2.46884 12.7013 2.72915 12.441L5.55781 9.61229L3.45229 7.50677C3.05287 7.10734 3.05275 6.45978 3.45203 6.06022C3.5949 5.91725 3.77692 5.8198 3.97511 5.78018L6.02848 5.36962L6.07715 5.32029L4.19148 3.43462C3.93101 3.17415 3.93109 2.75183 4.19165 2.49145C4.45208 2.23121 4.87414 2.23128 5.13448 2.49162L13.6198 10.977ZM7.02115 6.26295L6.68648 6.59762L4.80515 6.97429L9.13848 11.3076L9.51448 9.42629L9.84915 9.09162L7.02115 6.26295ZM12.6285 6.31229L12.2065 6.73429C11.9461 6.99462 11.9461 7.41669 12.2063 7.67712C12.4667 7.93768 12.889 7.93775 13.1495 7.67729L13.5711 7.25562L14.0425 7.72695C14.5635 7.20597 14.5634 6.36129 14.0424 5.84034L10.2712 2.06956C9.75054 1.54896 8.90644 1.54899 8.38581 2.06962L8.85715 2.54095L8.43515 2.96295C8.17481 3.22329 8.17473 3.64536 8.43498 3.90579C8.69535 4.16635 9.11768 4.16642 9.37815 3.90595L9.79981 3.48429L12.6285 6.31229Z"
                fill="white"
            />
        </svg>
    );
}

import {
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
} from "firebase/auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FirebaseError } from "firebase/app";
import { Link, useNavigate } from "react-router-dom";
import { Button, Input } from "@jhool-io/fe-components";
import AuthWrapper from "../components/AuthWrapper/AuthWrapper";
import GoogleIcon from "../../../components/Icons/GoogleIcon";
import useToast from "../../../hooks/useToast";
import { auth } from "../../../firebase";

interface ILoginProps {
    email: string;
    password: string;
}

const schema = yup.object({
    email: yup
        .string()
        .email("Please enter a valid email")
        .required("Email is required"),
    password: yup.string().required("Password is required"),
});

export default function LoginPage() {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<ILoginProps>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const redirectUrl = localStorage.getItem("from") || "/";

    const navigate = useNavigate();

    const { toast } = useToast();

    const handleErrorMessageToShow = (errorCode: string) => {
        if (errorCode === "auth/wrong-password") {
            return "Your email or password is incorrect.";
        }
        if (errorCode === "auth/user-not-found") {
            return "We couldn't find an account with that email. Please check your email.";
        }
        return "Could not log you in at this time, please try again later";
    };

    const onSubmit = async (data: ILoginProps) => {
        try {
            await signInWithEmailAndPassword(auth, data.email, data.password);

            navigate(redirectUrl, {
                replace: true,
            });
        } catch (error) {
            if (error instanceof FirebaseError) {
                toast({
                    mode: "error",
                    message: handleErrorMessageToShow(error.code),
                });
            } else {
                toast({
                    mode: "error",
                    message:
                        "Could not log you in at this time, please try again later",
                });
            }
        }
    };

    async function signinWithGoogle() {
        try {
            await signInWithPopup(auth, new GoogleAuthProvider());

            navigate(redirectUrl, {
                replace: true,
            });
        } catch (error) {
            if (error instanceof FirebaseError) {
                toast({
                    mode: "error",
                    message: handleErrorMessageToShow(error.code),
                });
            } else {
                toast({
                    mode: "error",
                    message:
                        "Could not log you in at this time, please try again later",
                });
            }
        }
    }

    return (
        <AuthWrapper>
            <>
                <h2 className="text-xxl md:text-[40px] font-bold mb-24 md:mb-40 text-center">
                    Welcome back
                </h2>
                <Button
                    size="auto"
                    mode="outline"
                    variant="secondary"
                    className="rounded-r8 w-full text-xs md:text-base h-36 md:h-[56px] gap-x-16 mt-24 md:mt-40 mb-20 md:mb-32"
                    onClick={() => signinWithGoogle()}
                    disabled={isSubmitting}
                >
                    <GoogleIcon /> Continue with Google
                </Button>
                <div className="flex items-center justify-between before:content-[''] before:w-[45%] before:h-[1px] before:bg-strokedark after:content-[''] after:w-[45%] after:h-[1px] after:bg-strokedark">
                    OR
                </div>
                <form
                    className="mt-24 md:mt-32"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="mb-24">
                        <Input
                            {...register("email")}
                            label="Email Address"
                            placeholder="Enter your email address"
                            className="h-40 md:h-[56px] text-base placeholder:text-base placeholder:text-gray"
                            hasError={!!errors.email}
                            errorText={errors.email?.message}
                        />
                    </div>
                    <Input
                        {...register("password")}
                        label="Password"
                        type="password"
                        placeholder="Enter your password"
                        className="h-40 md:h-[56px] text-base placeholder:text-base placeholder:text-gray"
                        hasError={!!errors.password}
                        errorText={errors.password?.message}
                    />
                    <div className="mt-12 text-right mb-24 md:mb-40">
                        <Link
                            to="/reset-password"
                            className="text-sm font-medium"
                        >
                            Reset password?
                        </Link>
                    </div>
                    <Button
                        disabled={isSubmitting}
                        className="h-40 md:h-[56px] text-xs md:text-base py-[18px] w-full"
                        type="submit"
                    >
                        Login
                    </Button>
                </form>
            </>
        </AuthWrapper>
    );
}

export default function AvailabilityIcon() {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.10573 8.24927C6.08573 8.24927 6.0724 8.24927 6.0524 8.24927C6.01906 8.2426 5.9724 8.2426 5.9324 8.24927C3.99906 8.18927 2.53906 6.66927 2.53906 4.79594C2.53906 2.88927 4.0924 1.33594 5.99906 1.33594C7.90573 1.33594 9.45906 2.88927 9.45906 4.79594C9.4524 6.66927 7.98573 8.18927 6.12573 8.24927C6.11906 8.24927 6.1124 8.24927 6.10573 8.24927ZM5.99906 2.33594C4.64573 2.33594 3.53906 3.4426 3.53906 4.79594C3.53906 6.12927 4.57906 7.2026 5.90573 7.24927C5.94573 7.2426 6.0324 7.2426 6.11906 7.24927C7.42573 7.18927 8.4524 6.11594 8.45906 4.79594C8.45906 3.4426 7.3524 2.33594 5.99906 2.33594Z"
                fill="#576A81"
            />
            <path
                d="M11.0256 8.33073C11.0056 8.33073 10.9856 8.33073 10.9656 8.32406C10.6923 8.35073 10.4123 8.1574 10.3856 7.88406C10.3589 7.61073 10.5256 7.36406 10.7989 7.33073C10.8789 7.32406 10.9656 7.32406 11.0389 7.32406C12.0123 7.27073 12.7723 6.47073 12.7723 5.49073C12.7723 4.4774 11.9523 3.6574 10.9389 3.6574C10.6656 3.66406 10.4389 3.4374 10.4389 3.16406C10.4389 2.89073 10.6656 2.66406 10.9389 2.66406C12.4989 2.66406 13.7723 3.9374 13.7723 5.4974C13.7723 7.03073 12.5723 8.27073 11.0456 8.33073C11.0389 8.33073 11.0323 8.33073 11.0256 8.33073Z"
                fill="#576A81"
            />
            <path
                d="M6.11307 15.5307C4.80641 15.5307 3.49307 15.1974 2.49974 14.5307C1.57307 13.9174 1.06641 13.0774 1.06641 12.1641C1.06641 11.2507 1.57307 10.4041 2.49974 9.78406C4.49974 8.4574 7.73974 8.4574 9.72641 9.78406C10.6464 10.3974 11.1597 11.2374 11.1597 12.1507C11.1597 13.0641 10.6531 13.9107 9.72641 14.5307C8.72641 15.1974 7.41974 15.5307 6.11307 15.5307ZM3.05307 10.6241C2.41307 11.0507 2.06641 11.5974 2.06641 12.1707C2.06641 12.7374 2.41974 13.2841 3.05307 13.7041C4.71307 14.8174 7.51307 14.8174 9.17307 13.7041C9.81307 13.2774 10.1597 12.7307 10.1597 12.1574C10.1597 11.5907 9.80641 11.0441 9.17307 10.6241C7.51307 9.5174 4.71307 9.5174 3.05307 10.6241Z"
                fill="#576A81"
            />
            <path
                d="M12.2268 14.3333C11.9934 14.3333 11.7868 14.1733 11.7401 13.9333C11.6868 13.66 11.8601 13.4 12.1268 13.34C12.5468 13.2533 12.9334 13.0867 13.2334 12.8533C13.6134 12.5667 13.8201 12.2067 13.8201 11.8267C13.8201 11.4467 13.6134 11.0867 13.2401 10.8067C12.9468 10.58 12.5801 10.42 12.1468 10.32C11.8801 10.26 11.7068 9.99335 11.7668 9.72001C11.8268 9.45335 12.0934 9.28001 12.3668 9.34001C12.9401 9.46668 13.4401 9.69335 13.8468 10.0067C14.4668 10.4733 14.8201 11.1333 14.8201 11.8267C14.8201 12.52 14.4601 13.18 13.8401 13.6533C13.4268 13.9733 12.9068 14.2067 12.3334 14.32C12.2934 14.3333 12.2601 14.3333 12.2268 14.3333Z"
                fill="#576A81"
            />
        </svg>
    );
}

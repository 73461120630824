import * as React from "react";
import { Button, Dialog, DialogContent, Switch } from "@jhool-io/fe-components";
import { useParams, useSearchParams } from "react-router-dom";
import { useIsMutating } from "@tanstack/react-query";
import AddTask from "../../../tasks/components/AddTask/AddTask";
import PendingTasks from "../../../dashboard/components/PendingTasks/PendingTasks";

type Dialogs = "add_task";

export default function ClientTasksList() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [dialogInView, setDialogInView] = React.useState<Dialogs | null>(
        null
    );

    const params = useParams();
    const clientId = params.clientId || "";

    const taskTabInUrl = searchParams.get("task_subtab") || "pending_tasks";

    const addTaskIsMutating = useIsMutating(["add-todo"]);

    const handleSwitchButtonClick = (tab: string) => {
        searchParams.set("task_subtab", tab);
        setSearchParams(searchParams);
    };

    const switchOptions = [
        {
            title: "Pending tasks",
            key: "pending_tasks",
            onClick: () => handleSwitchButtonClick("pending_tasks"),
        },
        {
            title: "All Tasks",
            key: "all_tasks",
            onClick: () => handleSwitchButtonClick("all_tasks"),
        },
    ];

    const handleDialogInView = (dialog: Dialogs | null) => {
        setDialogInView(dialog);
    };

    return (
        <>
            <Dialog open={dialogInView === "add_task"}>
                <DialogContent
                    title="Add task"
                    handleCloseDialog={() => handleDialogInView(null)}
                    variant="center"
                    showFooter
                    saveText="Add task"
                    submitBtnFormValue="add-todo"
                    isSubmitBtnDisabled={addTaskIsMutating > 0}
                    isCancelBtnDisabled={addTaskIsMutating > 0}
                >
                    <AddTask onFormSubmit={() => handleDialogInView(null)} />
                </DialogContent>
            </Dialog>
            <div className="flex justify-between gap-12 flex-wrap mb-32 bg-white p-16 rounded-r8 items-center">
                <Switch
                    switchOptions={switchOptions}
                    activeSwitch={taskTabInUrl}
                />
                <div>
                    <Button onClick={() => handleDialogInView("add_task")}>
                        Add task
                    </Button>
                </div>
            </div>
            <div className="bg-white p-16 rounded-r8">
                {taskTabInUrl === "pending_tasks" && (
                    <PendingTasks
                        clientId={clientId}
                        context="client_chart_pending_tasks"
                    />
                )}
                {taskTabInUrl === "all_tasks" && (
                    <PendingTasks
                        context="client_chart_all_tasks"
                        clientId={clientId}
                    />
                )}
            </div>
        </>
    );
}

import * as React from "react";
import cn from "classnames";
import { useParams, useSearchParams } from "react-router-dom";
import { useIsMutating } from "@tanstack/react-query";
import { useFetchSessionNote } from "../../../hooks/queries/note";
import { formatDate, getTimeDuration } from "../../../utils/helpers";
import {
    EditNoteSteps,
    INewNoteAppoinmentDetails,
    NoteTypes,
} from "../../../utils/types/notes";
import ListState from "../../ListState/ListState";
import Skeleton from "../../Skeleton/Skeleton";
import AppointmentDetails from "../AppointmentDetails/AppointmentDetails";
import EditCancellation from "./EditCancellation/EditCancellation";
import styles from "./EditNote.module.scss";
import EditRecordOfDisclosure from "./EditRecordOfDisclosure/EditRecordOfDisclosure";
import EditSoapNote from "./EditSoapNote/EditSoapNote";
import EditSupervision from "./EditSupervision/EditSupervision";
import EditTermination from "./EditTermination/EditTermination";
import EditSafetyPlan from "./EditSafetyPlan/EditSafetyPlan";
import EditTreatmentReview from "./EditTreatmentReview/EditTreatmentReview";
import EditIndividualIntake from "./EditIndividualIntake/EditIndividualIntake";
import EditFamilyIntake from "./EditFamilyIntake/EditFamilyIntake";
import InfoIcon from "../../Icons/InfoIcon";
import AddNoteMetadata from "../CreateNote/AddNoteMetadata/AddNoteMetadata";
import EditGeneralNote from "./EditGeneralNote/EditGeneralNote";
import EditSupervisorCheckInNote from "./EditSupervisorCheckInNote/EditSupervisorCheckInNote";
import { useAppSelector } from "../../../hooks/useRedux";
import EditPauseNotification from "./EditPauseNotification/EditPauseNotification";

interface EditNoteFormWrapperProps {
    currentEditNoteStepInView?: EditNoteSteps;
    currentNoteAppointmentDetails?: INewNoteAppoinmentDetails;
}

export default function EditNote({
    currentEditNoteStepInView,
    currentNoteAppointmentDetails,
}: EditNoteFormWrapperProps) {
    const [noteAppointmentDetails, setNoteAppointmentDetails] =
        React.useState<INewNoteAppoinmentDetails | null>(
            currentNoteAppointmentDetails || null
        );
    const [editNoteStepInView, setEditNoteStepInView] =
        React.useState<EditNoteSteps>(currentEditNoteStepInView || "edit-note");

    const [searchParams] = useSearchParams();

    // Get id from url params
    const params = useParams();
    const noteId = params.noteId as string;
    const clientId = params.clientId as string;

    const { isOpen } = useAppSelector((state) => state.nav);

    const timeLastSaved = searchParams.get("lastSaved") || "";

    // Get currentAction search param from url
    // helps us to determine what type of action is being carried
    // out on the note
    const currentAction = searchParams.get("action");

    // To determine if mutation is currently running, returns 0 || 1
    const isEditDraftNoteMutating = useIsMutating(["edit-note"]);

    // Hook for fetching note
    const { data, error, isLoading, isSuccess } = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    // Function for setting createNoteInView state
    const handleEditNoteStepInView = (step: EditNoteSteps) => {
        setEditNoteStepInView(() => step);
        window.scrollTo(0, 0);
    };

    // Function for setting noteAppointmentDetails state
    const handleSetAppointmentDetailsInView = (
        appointmentDetails: INewNoteAppoinmentDetails | null
    ) => {
        setNoteAppointmentDetails(() => appointmentDetails);
    };

    // Determines the edit form to display
    const handleEditNoteFormToShow = () => {
        switch (data?.data.type) {
            case NoteTypes.CANCELLATION_NOTE:
                return (
                    <EditCancellation
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.SUPERVISION_NOTE:
                return (
                    <EditSupervision
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.RECORD_OF_DISCLOSURE:
                return (
                    <EditRecordOfDisclosure
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.FAMILY_SOAP_NOTE:
                return (
                    <EditSoapNote
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.INDIVIDUAL_SOAP_NOTE:
                return (
                    <EditSoapNote
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.TERMINATION_NOTE:
                return (
                    <EditTermination
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.SAFETY_PLAN:
                return (
                    <EditSafetyPlan
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.INDIVIDUAL_TREATMENT_REVIEW:
                return (
                    <EditTreatmentReview
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.FAMILY_TREATMENT_REVIEW:
                return (
                    <EditTreatmentReview
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.INDIVIDUAL_INTAKE_NOTE:
                return (
                    <EditIndividualIntake
                        currentAction={currentAction}
                        noteDetails={data.data}
                        isMinor={false}
                    />
                );

            case NoteTypes.MINOR_INTAKE_NOTE:
                return (
                    <EditIndividualIntake
                        currentAction={currentAction}
                        noteDetails={data.data}
                        isMinor
                    />
                );

            case NoteTypes.FAMILY_INTAKE_NOTE:
                return (
                    <EditFamilyIntake
                        currentAction={currentAction}
                        noteDetails={data.data}
                    />
                );

            case NoteTypes.GENERAL_UPDATE_NOTE:
                return (
                    <EditGeneralNote
                        noteDetails={data.data}
                        currentAction={currentAction}
                    />
                );
            case NoteTypes.SUPERVISOR_CHECK_IN:
                return (
                    <EditSupervisorCheckInNote
                        noteDetails={data.data}
                        currentAction={currentAction}
                    />
                );
            case NoteTypes.PAUSE_NOTIFICATION_NOTE:
                return (
                    <EditPauseNotification
                        noteDetails={data.data}
                        currentAction={currentAction}
                    />
                );
            default:
                return null;
        }
    };

    const getProviderWithRole = (roleType: string) => {
        const roleAvailable = data?.data.note_signatures?.find(
            (item) => item.role === roleType
        );

        return roleAvailable;
    };

    React.useEffect(() => {
        if (data?.data) {
            setNoteAppointmentDetails({
                note_type: data?.data.type as NoteTypes,
                client_name: `${data?.data.client.first_name} ${data?.data.client.last_name}`,
                appointment_type: data?.data.appointment_type,
                session_history_id: data?.data.session_history_id || null,
                date_of_service: data.data.date_of_service
                    ? formatDate(
                          data?.data.session_start_time,
                          false,
                          "yyyy-MM-dd'T'HH:mm:ss'Z'"
                      )
                    : "--",
                session_start_time: formatDate(
                    data?.data.session_start_time,
                    false,
                    "yyyy-MM-dd'T'HH:mm:ss'Z'"
                ),
                session_end_time: formatDate(
                    data?.data.session_end_time,
                    false,
                    "yyyy-MM-dd'T'HH:mm:ss'Z'"
                ),
                reason_for_short_duration: data?.data.reason_for_short_duration,
                provider_name: `${data.data.provider.first_name} ${data.data.provider.last_name}`,
                session_id: data?.data.session_id as string,
                client_id: data?.data.client.client_id as string,
                duration: getTimeDuration(
                    formatDate(
                        data?.data?.session_start_time || "",
                        false,
                        "HH:mm"
                    ),
                    formatDate(
                        data?.data?.session_end_time || "",
                        false,
                        "HH:mm"
                    )
                ),
                provider_signature: getProviderWithRole("author")?.signature,
                provider_signature_datetime:
                    getProviderWithRole("author")?.signature_date_time,
                supervisor_name: getProviderWithRole("supervisor")
                    ? `${getProviderWithRole("supervisor")?.first_name} ${
                          getProviderWithRole("supervisor")?.last_name
                      }`
                    : "--",
                supervisor_signature:
                    getProviderWithRole("supervisor")?.signature,
                supervisor_signature_datetime:
                    getProviderWithRole("supervisor")?.signature_date_time,
                provider_id: data?.data.provider.provider_id,
                first_session_date: null,
                no_of_sessions: null,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.data]);

    return (
        <>
            {isLoading && (
                <div className={styles.editskeleton}>
                    <Skeleton
                        containerTestId="edit-loader"
                        width="150px"
                        height={20}
                    />
                    <Skeleton
                        count={5}
                        height={60}
                        width="100%"
                        containerTestId="edit-loader"
                    />
                </div>
            )}
            {error && error?.response?.status !== 404 && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display edit forms at this time, please try again later`
                    }
                />
            )}
            {isSuccess && data && (
                <>
                    {editNoteStepInView === "appointment-details" && (
                        <AddNoteMetadata
                            noteAppointmentDetails={noteAppointmentDetails}
                            handleCreateNoteStepInView={
                                handleEditNoteStepInView
                            }
                            handleSetAppointmentDetailsInView={
                                handleSetAppointmentDetailsInView
                            }
                        />
                    )}
                    {editNoteStepInView === "edit-note" &&
                        noteAppointmentDetails && (
                            <div className={styles.wrapper}>
                                <div className={styles.wrapform}>
                                    {isEditDraftNoteMutating > 0 ||
                                    timeLastSaved ? (
                                        <div
                                            className={cn(styles.autosave, {
                                                [styles.autosave_full]: !isOpen,
                                            })}
                                        >
                                            {isEditDraftNoteMutating > 0 ? (
                                                <span className={styles.saving}>
                                                    Autosaving...
                                                </span>
                                            ) : (
                                                <span className={styles.saved}>
                                                    <InfoIcon /> Autosaved at
                                                    {timeLastSaved}
                                                </span>
                                            )}
                                        </div>
                                    ) : null}
                                    <div className={styles.appointment}>
                                        {noteAppointmentDetails && (
                                            <AppointmentDetails
                                                details={noteAppointmentDetails}
                                                noteType={
                                                    noteAppointmentDetails.note_type
                                                }
                                                showEditButton
                                                editButtonClicked={() =>
                                                    handleEditNoteStepInView(
                                                        "appointment-details"
                                                    )
                                                }
                                            />
                                        )}
                                    </div>

                                    {handleEditNoteFormToShow()}
                                </div>
                            </div>
                        )}
                </>
            )}
        </>
    );
}

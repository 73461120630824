import * as React from "react";
import { Route, Routes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Buffer } from "buffer";
import NotFound from "./pages/NotFound";
import Dashboard from "./pages/AppContainer";
import { RequireAuth } from "./utils/RequireAuth";
import routes from "./utils/Routes";
import { useFetchUserDetails } from "./hooks/queries/user";
import CheckAuth from "./utils/CheckAuth";
import LoginPage from "./modules/auth/pages/Login";
import ResetPasswordPage from "./modules/auth/pages/ResetPassword";
import DashboardToDisplay from "./modules/dashboard/components/DashboardToDisplay/DashboardToDisplay";
import ScrollToTopButton from "./components/ScrollToTopButton/ScrollToTopButton";
import { DEFAULT_PRACTICE_ID } from "./utils/constants";
import usePractice from "./hooks/usePractice";

window.Buffer = Buffer;

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
    const { fetchStatus } = useFetchUserDetails();

    const { practice } = usePractice();

    /**
     * JULY 10 2024 - Update current pinned clients in local storage
     * We are using this to update the currently stored pinned clients in local storage key to
     * the new format for the key. `from "pinned_clients" to `pinned_clients_${practice_id}`
     */
    if (DEFAULT_PRACTICE_ID === practice?.practice_id) {
        const previousPins = localStorage.getItem("pinned_clients");

        if (previousPins) {
            localStorage.setItem(
                `pinned_clients_${practice?.practice_id}`,
                previousPins
            );

            localStorage.removeItem("pinned_clients");
        }
    }

    if (fetchStatus === "fetching") {
        return (
            <div className="page-loader">
                <span />
            </div>
        );
    }

    return (
        <SentryRoutes>
            <Route
                path="/"
                element={
                    <RequireAuth>
                        <>
                            <Dashboard />
                            <ScrollToTopButton />
                        </>
                    </RequireAuth>
                }
            >
                <Route index element={<DashboardToDisplay />} />
                {routes.map((item) => (
                    <Route
                        key={item.path}
                        path={item.path}
                        element={<item.component />}
                    />
                ))}
            </Route>
            <Route
                path="login"
                element={
                    <CheckAuth>
                        <LoginPage />
                    </CheckAuth>
                }
            />
            <Route
                path="reset-password"
                element={
                    <CheckAuth>
                        <ResetPasswordPage />
                    </CheckAuth>
                }
            />
            <Route path="*" element={<NotFound />} />
        </SentryRoutes>
    );
}

export default App;

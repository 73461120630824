import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import cn from "classnames";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Button,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Popover,
    PopoverContent,
    PopoverTrigger,
    RadioInput,
    SearchInput,
    TextArea,
} from "@jhool-io/fe-components";
import { useDebounce } from "../../../../hooks/helpers";
import useToast from "../../../../hooks/useToast";
import { useFetchUserDetails } from "../../../../hooks/queries/user";
import { useFetchUsersList } from "../../../user-management/hooks/user-management.queries";
import { IAddNoteMessage } from "../../../../utils/types/notes";
import Card from "../../../../components/Card/Card";
import Avatar from "../../../../components/Avatar/Avatar";
import ListState from "../../../../components/ListState/ListState";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import {
    formatDate,
    truncateString,
    userInitials,
} from "../../../../utils/helpers";
import MoreButton from "../../../../shared-ui/Buttons/MoreButton/MoreButton";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import { APP_COLORS } from "../../../../utils/constants";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import {
    useAddNoteMessaging,
    useDeleteMessage,
} from "../../hooks/notes.mutations";
import { IDeleteNoteMessage, IGetMessages } from "../../types/notes.types";
import { useFetchMessages } from "../../hooks/notes.queries";

type AppComboBoxOptions = {
    value: string;
    label: string;
};

function Messages() {
    // Toast for success and error states
    const { toast } = useToast();

    // Query client
    const queryClient = useQueryClient();
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const textarea = textareaRef.current;
    const [selectedProvider, setSelectedProvider] = useState(true);
    const [selectedPublic, setSelectedPublic] = useState(false);
    const [userSearchString, setUserSearchString] = React.useState("");
    const [showUsersListPopover, setShowUsersListPopover] =
        React.useState(false);

    const [messageSearchString, setMessageSearchString] = useState("");
    const debouncedUserSearchValue = useDebounce(userSearchString, 500);
    const debouncedMessageSeachString = useDebounce(
        (messageSearchString.length > 3 && messageSearchString) || "",
        500
    );
    const [message, setMessage] = useState("");
    const [showPrompt, setShowPrompt] = useState(false);
    const params = useParams();
    const noteId = params.noteId as string;
    const clientId = params.clientId as string;
    const [selectedMessageId, setSelectedMessageId] = useState("");

    // Hook to get logged in user
    const loggedInUser = useFetchUserDetails();

    //  Hook to add note message
    const addMessage = useAddNoteMessaging(noteId);

    // Hook to fetch session note
    const { data } = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    // Hook to fetch messages
    const {
        data: noteMessages,
        isLoading,
        isSuccess,
        error,
    } = useFetchMessages(noteId, debouncedMessageSeachString);

    // hook to delete messages
    const deleteNoteMessage = useDeleteMessage(noteId);

    // Fetch internal users
    const usersList = useFetchUsersList({
        search_string: userSearchString
            ? debouncedUserSearchValue?.toLowerCase()
            : `${data?.data.provider.first_name} ${data?.data.provider.last_name}`,
        page: 0,
        limit: 20,
    });

    const [selectedRecipientOption, setSelectedRecipientOption] =
        React.useState<AppComboBoxOptions | null>({
            value: "",
            label: "",
        });
    // check if add message is mutating
    const addMessageIsMutating = useIsMutating(["add_message", noteId]);

    // function to create a note message
    const handleAddNoteMessage = () => {
        let dataToSend: IAddNoteMessage;
        if (!message) {
            return;
        }
        if (selectedPublic) {
            dataToSend = {
                is_public: true,
                message,
            };
        } else {
            dataToSend = {
                is_public: false,
                recipient_user_id: selectedRecipientOption?.label
                    ? selectedRecipientOption?.value
                    : usersList?.data?.data[0]?.user_id,
                message,
            };
        }
        addMessage.mutate(dataToSend, {
            onSuccess: (res) => {
                toast({
                    mode: "success",
                    message: res.message || "Message sent successfully",
                });
                if (textarea) {
                    textarea.style.height = "4.5rem";
                    textarea.style.overflowY = "hidden";
                }
                setMessage("");
                queryClient.invalidateQueries({
                    queryKey: [noteId, "get-messages"],
                });
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not send message at this time",
                });
            },
        });
    };

    // function to delete a note message
    const handleDeleteNoteMessage = () => {
        const dataToSend: IDeleteNoteMessage = {
            message_id: selectedMessageId,
        };
        deleteNoteMessage.mutate(dataToSend, {
            onSuccess: (res) => {
                toast({
                    mode: "success",
                    message: res.message || "Message deleted successfully",
                });
                setShowPrompt(false);
                queryClient.invalidateQueries({
                    queryKey: [noteId, "get-messages"],
                });
            },
            onError: (err) => {
                setShowPrompt(false);
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not delete message at this time",
                });
            },
        });
    };

    // function to highlight keywords being searched
    const highlightQuery = (text: string, user: string, msg: IGetMessages) => {
        const regex = new RegExp(messageSearchString, "gi");

        const highlightedText = text.replace(
            regex,
            (match) =>
                `<span class="bg-primary font-bold text-white rounded-r4">${match}</span>`
        );

        const highlightedUser = user.replace(
            regex,
            (match) =>
                `<span class="bg-primary font-bold text-white rounded-r4">${match}</span>`
        );
        const firstName = msg.sender_first_name;
        const lastName = msg.sender_last_name;
        return {
            highlightedText,
            highlightedUser,
            firstName,
            lastName,
        };
    };

    const autoExpandTextarea = () => {
        const maxHeight = 100;
        if (textarea) {
            textarea.style.height = "4.5rem"; // Reset the height to recalculate
            const { scrollHeight } = textarea;
            // Adjust the textarea's height based on scrollHeight
            if (scrollHeight > maxHeight) {
                textarea.style.height = `${maxHeight}px`;
                textarea.style.overflowY = "scroll";
            } else {
                textarea.style.height = "4.5rem";
                textarea.style.overflowY = "hidden";
            }
        }
    };

    const handleInputChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { value } = event.target;
        setMessage(value);
        autoExpandTextarea();
    };

    const selectedValue =
        (userSearchString && selectedRecipientOption?.label) ||
        `${usersList?.data?.data?.[0]?.first_name} ${usersList?.data?.data?.[0]?.last_name}` ||
        "";

    return (
        <div className="h-[80vh] flex overflow-hidden">
            <Dialog open={showPrompt}>
                <DialogContent
                    title="Delete message"
                    handleCloseDialog={() => setShowPrompt(false)}
                    variant="center"
                    saveText="Delete message"
                    onSaveClick={handleDeleteNoteMessage}
                    onCancelClick={() => setShowPrompt(false)}
                    isCancelBtnDisabled={deleteNoteMessage.isLoading}
                    isSubmitBtnDisabled={deleteNoteMessage.isLoading}
                >
                    <div>
                        <div className="text-gray text-center text-base leading-[24px] mt-10">
                            Are you sure you want to delete this message?
                        </div>
                        <div className="mt-20 text-sm text-center">
                            Please note that this action cannot be undone, and
                            the message will be permanently removed from this
                            note
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <Card
                type="dashboard"
                className="flex flex-col flex-grow p-0 max-[690px]:w-full border border-strokedark"
            >
                <div className="border-b border-b-strokedark py-24 pl-32 max-[690px]:p-24 pr-24 flex justify-between flex-none max-[690px]:flex-col max-[690px]:gap-y-12">
                    <div>
                        <Avatar label="CH" />{" "}
                        <span className="pl-10 text-sm font-semibold">
                            Chats
                        </span>
                    </div>
                    <div>
                        <SearchInput
                            placeholder="Search by sender or by keyword"
                            value={messageSearchString}
                            containerClass="w-[500px] max-[690px]:w-full"
                            onChange={(e) =>
                                setMessageSearchString(e.target.value)
                            }
                        />
                    </div>
                </div>
                {error && error.response?.status !== 404 && (
                    <ListState
                        errorMsg={
                            error?.response?.data.message ||
                            `Cannot display messages at this time please try again later`
                        }
                        stateHelperText="Try reloading this page to solve this issue"
                        isError
                        cardClass="!shadow-none !border-none"
                    />
                )}
                {isLoading && (
                    <div>
                        <Card className="flex flex-col gap-y-12 h-full p-10 ">
                            <div className="flex gap-x-10 pt-24 pr-24 pl-32">
                                <Skeleton
                                    height={32}
                                    width={32}
                                    className="rounded-[100%]"
                                    containerTestId="messages-loader"
                                />{" "}
                                <Skeleton
                                    height={30}
                                    width="20%"
                                    containerTestId="messages-loader"
                                />
                            </div>
                            <div className="mt-20 ml-[76px] rounded-r4">
                                <Skeleton
                                    className="h-[100px] w-[543px]"
                                    containerTestId="messages-loader"
                                />
                            </div>
                            <div className="flex gap-x-10 pt-24 pr-24 pl-32">
                                <Skeleton
                                    height={32}
                                    width={32}
                                    className="rounded-[100%]"
                                    containerTestId="messages-loader"
                                />{" "}
                                <Skeleton
                                    height={30}
                                    width="20%"
                                    containerTestId="messages-loader"
                                />
                            </div>
                            <div className="mt-20 ml-[76px] rounded-r4">
                                <Skeleton
                                    className="h-[100px] w-[543px]"
                                    containerTestId="messages-loader"
                                />
                            </div>
                        </Card>
                    </div>
                )}
                <div>
                    {!isLoading &&
                        noteMessages &&
                        isSuccess &&
                        noteMessages.data.length === 0 && (
                            <ListState
                                emptyMessage="No message history yet"
                                stateHelperText="You can send a message about this note to the provider or anyone in the practice"
                                isError={false}
                                cardClass="!shadow-none !border-none"
                            />
                        )}
                </div>
                <div className="flex-grow overflow-auto">
                    {!isLoading &&
                        noteMessages &&
                        noteMessages.data.length > 0 &&
                        noteMessages?.data.map((msg, index) => {
                            const {
                                highlightedText,
                                highlightedUser,
                                firstName,
                                lastName,
                            } = highlightQuery(
                                msg.message,
                                `${msg.sender_first_name} ${msg.sender_last_name}`,
                                msg
                            );
                            return (
                                <div key={msg.message}>
                                    <div className="flex text-base pt-24 pr-24 min-[690px]:pl-32 h-auto max-[690px]:p-24">
                                        <Avatar
                                            label={userInitials(
                                                firstName,
                                                lastName
                                            )}
                                        />
                                        <div className="flex">
                                            <div
                                                className="pl-12 text-[#576A81] text-xs font-semibold"
                                                // eslint-disable-next-line react/no-danger
                                                dangerouslySetInnerHTML={{
                                                    __html: highlightedUser,
                                                }}
                                            />

                                            <div className="w-[3px] h-[3px] bg-[#576a81] rounded-[50%] mt-10 ml-4" />
                                            <div className="pl-[6px] text-[#576A81] text-xs font-semibold">
                                                {`${formatDate(
                                                    msg.sent_time as string,
                                                    false,
                                                    "MMM dd"
                                                )},`}
                                            </div>
                                            <div className="pl-[6px] text-[#576A81] text-xs font-semibold">
                                                {formatDate(
                                                    msg.sent_time as string,
                                                    false,
                                                    "h:mm a"
                                                )}
                                            </div>
                                        </div>
                                        {msg.is_public === true && (
                                            <div style={{ display: "flex" }}>
                                                {" "}
                                                <div className="w-[3px] h-[3px] bg-[#576a81] rounded-[50%] mt-10 ml-4" />
                                                <div className="pl-8 text-[#576A81] text-xs font-semibold">
                                                    Public
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className={`"h-auto min-[690px]:w-[54.3rem] ml-[76px] max-[690px]:ml-24 pt-16 pr-12 pl-16 mb-8 rounded-r4 max-[690px]:w-full" ${
                                            index % 2 === 0
                                                ? "bg-[#fafafa]"
                                                : "bg-[#EEFDFDA3]"
                                        }`}
                                    >
                                        <div
                                            className="w-[448px] text-xs font-normal leading[20px] max-[690px]:w-full"
                                            // eslint-disable-next-line react/no-danger
                                            dangerouslySetInnerHTML={{
                                                __html: highlightedText,
                                            }}
                                        />
                                        <div className="flex justify-end pb-12">
                                            {`${loggedInUser.data?.first_name} ${loggedInUser.data?.last_name}` ===
                                                `${msg.sender_first_name} ${msg.sender_last_name}` && (
                                                <Dropdown>
                                                    <DropdownTrigger asChild>
                                                        <MoreButton
                                                            onPointerDown={() => {
                                                                setSelectedMessageId(
                                                                    msg.message_id
                                                                );
                                                            }}
                                                        />
                                                    </DropdownTrigger>
                                                    <DropdownContent
                                                        align="end"
                                                        width="auto"
                                                    >
                                                        <DropdownItem
                                                            onClick={() => {
                                                                setShowPrompt(
                                                                    !showPrompt
                                                                );
                                                            }}
                                                            className="text-danger"
                                                        >
                                                            Delete Message
                                                        </DropdownItem>
                                                    </DropdownContent>
                                                </Dropdown>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
                <div className="flex flex-col gap-y-20 pb-24 border-t border-t-strokedark flex-none">
                    <div className="flex gap-x-8 pl-32 pt-12 max-[500px]:flex-col max-[690px]:gap-y-12 ">
                        <Popover
                            open={showUsersListPopover}
                            onOpenChange={setShowUsersListPopover}
                        >
                            <PopoverTrigger
                                asChild
                                onClick={() => setShowUsersListPopover(true)}
                                className="w-auto pr-10 !h-32 !rounded-r6 text-gray max-[500px]:w-[80%]"
                            >
                                <Button
                                    size="auto"
                                    variant="normal"
                                    className={cn(
                                        " shadow-active gap-x-8 data-[state=open]:border-primary font-medium flex justify-between",
                                        {
                                            capitalize: selectedValue,
                                        }
                                    )}
                                    onClick={() => {
                                        setSelectedPublic(false);
                                        setSelectedProvider(true);
                                    }}
                                >
                                    <div className="flex items-center">
                                        <RadioInput
                                            label=""
                                            checked={selectedProvider}
                                            onClick={(e) => {
                                                const target =
                                                    e.target as HTMLInputElement;
                                                setSelectedPublic(false);
                                                setSelectedProvider(
                                                    target.checked
                                                );
                                            }}
                                            wrapperClass="border-none"
                                        />{" "}
                                        {usersList.isLoading ? (
                                            "loading"
                                        ) : (
                                            <p>
                                                {" "}
                                                Send to:{" "}
                                                {truncateString(
                                                    selectedValue || "",
                                                    18
                                                )}
                                            </p>
                                        )}
                                    </div>
                                    <ChevronDownIcon
                                        stroke={APP_COLORS.COLOR_GRAY}
                                    />
                                </Button>
                            </PopoverTrigger>

                            <PopoverContent
                                className="px-4 pt-[3px] py-[3px] w-[316px] rounded-r8 border-none shadow-[0px_0px_0px_1px_#98a1b219,_0px_15px_35px_-5px_#11182626,_0px_5px_15px_0px_#00000014] z-[40]"
                                align="start"
                            >
                                <SearchInput
                                    placeholder="Search by provider name"
                                    containerClass="h-24"
                                    onChange={(e) =>
                                        setUserSearchString(e.target.value)
                                    }
                                />

                                <div className="flex flex-col items-start max-h-[230px] overflow-y-auto">
                                    {usersList.isLoading && (
                                        <div className="flex flex-col gap-y-4 items-stretch w-full">
                                            {[1, 2, 3, 4, 5].map((item) => (
                                                <Skeleton
                                                    key={item}
                                                    width="100%"
                                                    height="24px"
                                                    containerTestId="client-loader"
                                                />
                                            ))}
                                        </div>
                                    )}

                                    {usersList.error && (
                                        <ListState
                                            isError
                                            context="general"
                                            stateHelperText="Please try again later"
                                            errorMsg={
                                                usersList.error?.response?.data
                                                    ?.message ||
                                                "Error loading providers"
                                            }
                                        />
                                    )}

                                    {usersList.data?.data &&
                                        usersList.isSuccess && (
                                            <div>
                                                {usersList.data?.data &&
                                                    usersList.data.data.length >
                                                        0 &&
                                                    usersList?.data?.data.map(
                                                        (user) => (
                                                            <Button
                                                                key={
                                                                    user.user_id
                                                                }
                                                                size="auto"
                                                                variant="normal"
                                                                className="text-xs font-medium capitalize w-full justify-start !rounded-none"
                                                                onClick={() => {
                                                                    setSelectedRecipientOption(
                                                                        {
                                                                            value: user.user_id,
                                                                            label: `${user.first_name} ${user.last_name}`,
                                                                        }
                                                                    );
                                                                    setShowUsersListPopover(
                                                                        false
                                                                    );
                                                                }}
                                                            >
                                                                {`${user.first_name} ${user.last_name}`}
                                                            </Button>
                                                        )
                                                    )}
                                            </div>
                                        )}
                                </div>
                            </PopoverContent>
                        </Popover>
                        <Popover>
                            <PopoverTrigger
                                asChild
                                className="w-auto pr-10 !h-32 !rounded-r6 text-gray max-[500px]:w-[80%]"
                            >
                                <Button
                                    size="auto"
                                    variant="normal"
                                    className={`shadow-active gap-x-8 font-medium flex justify-between ${
                                        selectedPublic ? "border-primary" : ""
                                    }`}
                                    onClick={() => {
                                        setSelectedProvider(false);
                                        setSelectedPublic(true);
                                    }}
                                >
                                    <div className="flex items-center">
                                        <RadioInput
                                            label=""
                                            checked={selectedPublic}
                                            onClick={(e) => {
                                                const target =
                                                    e.target as HTMLInputElement;
                                                setSelectedProvider(false);
                                                setSelectedPublic(
                                                    target.checked
                                                );
                                            }}
                                            wrapperClass="border-none"
                                        />{" "}
                                        <p> Send to: Public</p>
                                    </div>
                                </Button>
                            </PopoverTrigger>
                        </Popover>
                    </div>
                    <div className="flex pl-32 gap-x-12 pr-24 max-[500px]:flex-col max-[500px]:gap-y-12">
                        <TextArea
                            placeholder="Enter your message"
                            onChange={handleInputChange}
                            style={{
                                height: "4.5rem",
                                overflow: "hidden",
                            }}
                            value={message}
                            ref={textareaRef}
                            id="text_area"
                        />
                        <Button
                            className="h-[4.5rem]"
                            iconRight={
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17"
                                    height="16"
                                    viewBox="0 0 17 16"
                                    fill="none"
                                >
                                    <path
                                        d="M14.5545 1.94336L7.30469 9.19314"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M14.6744 2.42624L10.982 13.9134C10.8558 14.3071 10.3216 14.3658 10.1136 14.0076L7.30556 9.19336L2.49134 6.38535C2.134 6.17647 2.19178 5.64313 2.58556 5.51691L14.0727 1.82447C14.4433 1.70535 14.7936 2.05558 14.6744 2.42624Z"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            }
                            disabled={addMessageIsMutating > 0}
                            onClick={handleAddNoteMessage}
                        >
                            Send
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default Messages;

import Navbar from "../../../components/Navbar/Navbar";
import NoteAuditDashboard from "../components/NoteAuditDashboard/NoteAuditDashboard";

export default function NoteAuditDashboardPage() {
    return (
        <>
            <Navbar title="Note Audit" />
            <div className="page">
                <NoteAuditDashboard />
            </div>
        </>
    );
}

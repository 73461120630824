import { SvgProps } from "../../utils/types";

export default function ChevronLeftIcon({ className }: SvgProps) {
    return (
        <svg
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M1.07874 7.46949L7.15216 1.39637C7.44497 1.10355 7.91997 1.10355 8.21278 1.39637L8.92122 2.1048C9.21372 2.3973 9.21403 2.87105 8.92247 3.16418L4.10904 7.9998L8.92216 12.8357C9.21403 13.1289 9.21341 13.6026 8.92091 13.8951L8.21247 14.6035C7.91966 14.8964 7.44466 14.8964 7.15185 14.6035L1.07874 8.53011C0.785928 8.2373 0.785928 7.7623 1.07874 7.46949Z"
                fill="#0B132B"
            />
        </svg>
    );
}

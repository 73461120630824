import cn from "classnames";
import { Button } from "@jhool-io/fe-components";
import { cn as cnt } from "../../utils/helpers";

import Card from "../Card/Card";
import TimesTwoIcon from "../Icons/TimesTwo";
import styles from "./ListState.module.scss";

interface ListStateProps {
    /** Class for adding more styling to wrapper card */
    cardClass?: string;
    /** Class for styling state component inner container */
    wrapperClass?: string;
    /** Text to display useful information about the current state */
    stateHelperText: string;
    /** Check if loading of list resulted in an error */
    isError: boolean;
    /** Error message to display if loading of list resulted in an error */
    errorMsg?: string;
    /** Empty state message */
    emptyMessage?: string;
    /** List header to display */
    listHeader?: React.ReactNode;
    /** Empty state button props */
    emptyBtnProps?: {
        /** Determines if button should be shown */
        showButton: boolean;
        /** Onclick function for empty state button */
        onButtonClick?: () => void;
        /** Button text */
        buttonText?: string;
        disabled?: boolean;
    };
    context?: "table" | "general";
}

export default function ListState({
    stateHelperText,
    isError,
    errorMsg,
    listHeader,
    wrapperClass,
    emptyBtnProps,
    emptyMessage,
    cardClass,
    context = "table",
}: ListStateProps) {
    if (context === "general") {
        return (
            <article
                className={cnt(
                    "flex items-center h-full flex-col justify-center py-20 px-12",
                    cardClass
                )}
            >
                <div
                    className={cnt(
                        "h-auto flex items-center justify-content flex-col",
                        wrapperClass
                    )}
                >
                    {isError && (
                        <span
                            className="rounded-full flex items-center justify-center size-40 bg-[#f717350d] mb-12 *:size-[30px]"
                            role="presentation"
                        >
                            <TimesTwoIcon />
                        </span>
                    )}
                    <p className="font-semibold text-sm mb-4 text-center">
                        {isError && errorMsg}
                        {!isError && emptyMessage}
                    </p>
                    <p className="max-w-full w-[256px] font-regular text-xs text-gray text-center leading-none">
                        {stateHelperText}
                    </p>
                    {!isError && emptyBtnProps?.showButton && (
                        <Button
                            variant="primary"
                            size="small"
                            onClick={emptyBtnProps.onButtonClick}
                            className="mt-12"
                            disabled={emptyBtnProps.disabled}
                        >
                            {emptyBtnProps.buttonText}
                        </Button>
                    )}
                </div>
            </article>
        );
    }

    return (
        <Card type="dashboard" className={cardClass}>
            {listHeader && (
                <h3 className="font-medium text-sm uppercase">{listHeader}</h3>
            )}
            <div className={cn(styles.wrapper, wrapperClass, "fs-exclude")}>
                {isError && (
                    <span className={styles.iconwrapper} role="presentation">
                        <TimesTwoIcon />
                    </span>
                )}
                <p className={styles.statetext}>
                    {isError && errorMsg}
                    {!isError && emptyMessage}
                </p>
                <p className={styles.helpertext}>{stateHelperText}</p>
                {!isError && emptyBtnProps?.showButton && (
                    <Button
                        variant="primary"
                        size="small"
                        onClick={emptyBtnProps.onButtonClick}
                        className="mt-12"
                        disabled={emptyBtnProps.disabled}
                    >
                        {emptyBtnProps.buttonText}
                    </Button>
                )}
            </div>
        </Card>
    );
}

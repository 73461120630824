export default function StatsCardVector() {
    return (
        <svg
            width="97"
            height="83"
            viewBox="0 0 97 83"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="h-[70px]"
        >
            <path
                d="M10.7771 0V82.4813"
                stroke="url(#paint0_radial_31448_16796)"
                strokeOpacity="0.13"
                strokeWidth="1.11461"
            />
            <path
                d="M28.0204 0V82.4813"
                stroke="url(#paint1_radial_31448_16796)"
                strokeOpacity="0.13"
                strokeWidth="1.11461"
            />
            <path
                d="M45.2668 0V82.4813"
                stroke="url(#paint2_radial_31448_16796)"
                strokeOpacity="0.13"
                strokeWidth="1.11461"
            />
            <path
                d="M62.5103 0V82.4813"
                stroke="url(#paint3_radial_31448_16796)"
                strokeOpacity="0.13"
                strokeWidth="1.11461"
            />
            <path
                d="M79.7567 0V82.4813"
                stroke="url(#paint4_radial_31448_16796)"
                strokeOpacity="0.13"
                strokeWidth="1.11461"
            />
            <path
                d="M0 13.8916L97 13.8916"
                stroke="url(#paint5_radial_31448_16796)"
                strokeOpacity="0.13"
                strokeWidth="1.11461"
            />
            <path
                d="M0 27.7832L97 27.7832"
                stroke="url(#paint6_radial_31448_16796)"
                strokeOpacity="0.13"
                strokeWidth="1.11461"
            />
            <path
                d="M0 41.6748L97 41.6748"
                stroke="url(#paint7_radial_31448_16796)"
                strokeOpacity="0.13"
                strokeWidth="1.11461"
            />
            <path
                d="M0 55.5654L97 55.5654"
                stroke="url(#paint8_radial_31448_16796)"
                strokeOpacity="0.13"
                strokeWidth="1.11461"
            />
            <path
                d="M0 69.457L97 69.457"
                stroke="url(#paint9_radial_31448_16796)"
                strokeOpacity="0.13"
                strokeWidth="1.11461"
            />
            <defs>
                <radialGradient
                    id="paint0_radial_31448_16796"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(11.2771 41.2407) rotate(90) scale(41.2407 0.5)"
                >
                    <stop stopColor="#BC2C7C" />
                    <stop offset="1" stopColor="#0F96DA" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint1_radial_31448_16796"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(28.5204 41.2407) rotate(90) scale(41.2407 0.5)"
                >
                    <stop stopColor="#BC2C7C" />
                    <stop offset="1" stopColor="#0F96DA" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint2_radial_31448_16796"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(45.7668 41.2407) rotate(90) scale(41.2407 0.5)"
                >
                    <stop stopColor="#BC2C7C" />
                    <stop offset="1" stopColor="#0F96DA" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint3_radial_31448_16796"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(63.0103 41.2407) rotate(90) scale(41.2407 0.5)"
                >
                    <stop stopColor="#BC2C7C" />
                    <stop offset="1" stopColor="#0F96DA" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint4_radial_31448_16796"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(80.2567 41.2407) rotate(90) scale(41.2407 0.5)"
                >
                    <stop stopColor="#BC2C7C" />
                    <stop offset="1" stopColor="#0F96DA" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint5_radial_31448_16796"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(48.5 14.3916) rotate(90) scale(0.5 48.5)"
                >
                    <stop stopColor="#BC2C7C" />
                    <stop offset="1" stopColor="#0F96DA" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint6_radial_31448_16796"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(48.5 28.2832) rotate(90) scale(0.5 48.5)"
                >
                    <stop stopColor="#BC2C7C" />
                    <stop offset="1" stopColor="#0F96DA" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint7_radial_31448_16796"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(48.5 42.1748) rotate(90) scale(0.5 48.5)"
                >
                    <stop stopColor="#BC2C7C" />
                    <stop offset="1" stopColor="#0F96DA" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint8_radial_31448_16796"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(48.5 56.0654) rotate(90) scale(0.5 48.5)"
                >
                    <stop stopColor="#BC2C7C" />
                    <stop offset="1" stopColor="#0F96DA" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint9_radial_31448_16796"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(48.5 69.957) rotate(90) scale(0.5 48.5)"
                >
                    <stop stopColor="#BC2C7C" />
                    <stop offset="1" stopColor="#0F96DA" stopOpacity="0" />
                </radialGradient>
            </defs>
        </svg>
    );
}

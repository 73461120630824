import styles from "./NoteSuggestion.module.scss";
import Pill from "../../Pill/Pill";

export type NoteSuggestionType = "green" | "amber" | "red";
interface NoteSuggestionProps {
    type: NoteSuggestionType;
    details: string;
}

const suggestionTypePillAndStyle: {
    [key: string]: {
        pill: JSX.Element;
        className: string;
    };
} = {
    green: {
        pill: (
            <Pill
                bgcolor="rgba(11, 122, 117, 0.1)"
                label="Looks Great"
                borderStyle="1px solid #0b7a75"
            />
        ),
        className: styles.suggestion_green,
    },
    red: {
        pill: (
            <Pill
                bgcolor="rgba(229, 68, 78, 0.1)"
                label="Needs major improvement"
                borderStyle="1px solid #e5444e"
            />
        ),
        className: styles.suggestion_red,
    },
    amber: {
        pill: (
            <Pill
                bgcolor="rgb(255,191,0,0.1)"
                label="Needs minor improvement"
                borderStyle="1px solid #ffbf00"
            />
        ),
        className: styles.suggestion_amber,
    },
};

export default function NoteSuggestion({ type, details }: NoteSuggestionProps) {
    return (
        <div className={styles.suggestion}>
            <div className={styles.suggestion_pill}>
                {suggestionTypePillAndStyle[type].pill}
            </div>
            <div className={suggestionTypePillAndStyle[type].className}>
                {details}
            </div>
        </div>
    );
}

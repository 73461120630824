export default function PenWritingIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.3936 5.48047L16.4113 7.75825"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.10689 16.9441C7.10689 16.9441 9.91689 16.3585 11.2191 14.6341L17.6736 6.08409C18.3024 5.25076 18.1369 4.0652 17.3036 3.43631C16.4702 2.80743 15.2847 2.97298 14.6558 3.80631L8.20133 12.3563C6.89911 14.0808 7.10689 16.9441 7.10689 16.9441Z"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.70234 16.9436C2.55456 15.5025 2.03901 14.2125 1.95678 13.3102C1.74123 10.9325 4.39678 9.66469 4.37901 7.01135C4.36789 5.30913 3.26123 3.93358 2.36012 3.05469"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default function ArrowFilledDown() {
    return (
        <svg
            width="11"
            height="6"
            viewBox="0 0 11 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.11133 1C1.11133 1 4.66688 5 5.55577 5C6.44466 5 10.0002 1 10.0002 1"
                stroke="white"
                strokeWidth="1.43143"
                strokeLinecap="round"
            />
        </svg>
    );
}

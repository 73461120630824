// Enums for types of appointments
export enum AppointmentType {
    REMOTE = "Remote",
    INPERSON = "In-person",
}
// Diagnosis code details
export interface IDiagnosisCode {
    diagnosis_code_id: string;
    code: string;
    description: string;
    created_at: string;
    updated_at: string;
}

// these are all optional because not all tables have these columns
export interface ISortTable {
    date_of_service?: string;
    client?: {
        first_name: string;
        last_name: string;
    };
    first_name?: string;
    provider_name?: string;
    client_name?: string;
    description?: string;
}

// Custom dates type for filtering in app
export enum IAppCustomDates {
    ALL_TIME = "all_time",
    LAST_24_HRS = "last_24_hrs",
    THIS_WEEK = "this_week",
    LAST_WEEK = "last_week",
    LAST_TWO_WEEKS = "last_two_weeks",
    THIS_MONTH = "this_month",
    LAST_MONTH = "last_month",
    LAST_30_DAYS = "last_30_days",
    LAST_60_DAYS = "last_60_days",
    LAST_90_DAYS = "last_90_days",
    LAST_120_DAYS = "last_120_days",
    THIS_YEAR = "this_year",
}

export enum AppSortOrder {
    ASC = "ASC",
    DESC = "DESC",
}

export interface SvgProps {
    /** extra classname for styling svg */
    className?: string;
    /** prop for updating the svg path stroke color */
    stroke?: string;
    /** prop for updating the svg path fill color */
    fill?: string;
    /** prop for adding stroke width */
    strokeWidth?: string;
}

export interface IExportPdf {
    export_job_id: string;
    pdf_type: string;
    expected_pdf_count?: number;
}

export interface IProviderSessionFilters {
    show_sessions_with_term_notes?: boolean;
    search_string?: string;
}

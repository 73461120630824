export default function WhiteArrowDownIcon() {
    return (
        <svg
            width="8"
            height="6"
            viewBox="0 0 8 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_6278_10286)">
                <path
                    opacity="0.8"
                    d="M4 4.9445L0 0.777832H8L4 4.9445Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_6278_10286">
                    <rect
                        width="8"
                        height="5"
                        fill="white"
                        transform="translate(0 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

import Navbar from "../../../components/Navbar/Navbar";
import SingleNoteView from "../components/SingleNoteView/SingleNoteView";

export default function NotePage() {
    return (
        <>
            <Navbar title="Note" />
            <div className="page">
                <SingleNoteView />
            </div>
        </>
    );
}

import * as React from "react";
import { useParams } from "react-router-dom";
import { showMoneyInAppFormat } from "../../../../utils/helpers";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import { useFetchInvoices } from "../../hooks/queries/billing.queries";
import { LISTS_DEFAULT_LIMIT } from "../../../../utils/constants";

export default function PaymentsOverview() {
    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;

    // Hook for fetching all invoice
    const { data, isLoading, error } = useFetchInvoices({
        client_id: clientId,
        limit: LISTS_DEFAULT_LIMIT,
        page: 1,
    });

    const clientPaymentSummary = data?.summary;

    return (
        <>
            {error && (
                <ListState
                    isError
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display payment info at this time please try again later`
                    }
                    listHeader="Payment Info"
                    stateHelperText="Try reloading this page to solve this issue"
                />
            )}

            {!error && (
                <div className="bg-white rounded-r12 p-12 flex flex-col gap-24 lg:gap-0 lg:flex-row lg:items-center relative">
                    <div className="flex-1">
                        <h3 className="uppercase text-sm font-semibold mb-12">
                            Total Outstanding
                        </h3>

                        <div className="flex flex-col md:flex-row gap-12">
                            <article className=" bg-white p-4 rounded-r8 flex-1">
                                <div className="p-12 bg-card-bg  rounded-r8 flex flex-col ">
                                    <p className="text-xs font-medium text-gray mb-16 uppercase">
                                        Insurance
                                    </p>
                                    {isLoading ? (
                                        <Skeleton
                                            containerTestId="payment-overview-item"
                                            width="80px"
                                        />
                                    ) : (
                                        <p className="text-lg font-semibold text-black">
                                            {showMoneyInAppFormat(
                                                clientPaymentSummary?.total_insurance_amount_owed &&
                                                    clientPaymentSummary.total_insurance_amount_owed <=
                                                        0
                                                    ? Math.abs(
                                                          clientPaymentSummary.total_insurance_amount_owed
                                                      )
                                                    : (clientPaymentSummary?.total_insurance_amount_owed &&
                                                          clientPaymentSummary.total_insurance_amount_owed *
                                                              -1) ||
                                                          0
                                            )}
                                        </p>
                                    )}
                                </div>
                            </article>

                            <article className=" bg-white p-4 rounded-r8 flex-1">
                                <div className="p-12 bg-card-bg  rounded-r8 flex flex-col ">
                                    <p className="text-xs font-medium text-gray mb-16 uppercase">
                                        Co-Insurance
                                    </p>
                                    {isLoading ? (
                                        <Skeleton
                                            containerTestId="payment-overview-item"
                                            width="80px"
                                        />
                                    ) : (
                                        <p className="text-lg font-semibold text-black">
                                            {showMoneyInAppFormat(
                                                clientPaymentSummary?.total_coinsurance_amount_owed &&
                                                    clientPaymentSummary.total_coinsurance_amount_owed <=
                                                        0
                                                    ? Math.abs(
                                                          clientPaymentSummary.total_coinsurance_amount_owed
                                                      )
                                                    : (clientPaymentSummary?.total_coinsurance_amount_owed &&
                                                          clientPaymentSummary.total_coinsurance_amount_owed *
                                                              -1) ||
                                                          0
                                            )}
                                        </p>
                                    )}
                                </div>
                            </article>
                        </div>
                    </div>

                    <div className="hidden lg:block mx-24 w-[1px] bg-strokedark h-[48px] transform translate-y-[10%]" />

                    <div className="flex-1">
                        <h3 className="uppercase text-sm font-semibold mb-12">
                            Total Collected
                        </h3>

                        <div className="flex gap-12 flex-col md:flex-row">
                            <article className=" bg-white p-4 rounded-r8 flex-1">
                                <div className="p-12 bg-card-bg  rounded-r8 flex flex-col">
                                    <p className="text-xs font-medium text-gray mb-16 uppercase">
                                        Insurance
                                    </p>
                                    {isLoading ? (
                                        <Skeleton
                                            containerTestId="payment-overview-item"
                                            width="80px"
                                        />
                                    ) : (
                                        <p className="text-lg font-semibold text-black">
                                            {(clientPaymentSummary &&
                                                showMoneyInAppFormat(
                                                    clientPaymentSummary.total_insurance_amount_collected
                                                )) ||
                                                0}
                                        </p>
                                    )}
                                </div>
                            </article>
                            <article className=" bg-white p-4 rounded-r8 flex-1">
                                <div className="p-12 bg-card-bg rounded-r8 flex flex-col">
                                    <p className="text-xs font-medium text-gray mb-16 uppercase">
                                        Co-Insurance
                                    </p>
                                    {isLoading ? (
                                        <Skeleton
                                            containerTestId="payment-overview-item"
                                            width="80px"
                                        />
                                    ) : (
                                        <p className="text-lg font-semibold text-black ">
                                            {(clientPaymentSummary &&
                                                showMoneyInAppFormat(
                                                    clientPaymentSummary.total_coinsurance_amount_collected
                                                )) ||
                                                0}
                                        </p>
                                    )}
                                </div>
                            </article>
                            <article className=" bg-white p-4 rounded-r8 flex-1">
                                <div className="p-12 bg-card-bg rounded-r8 flex flex-col">
                                    <p className="text-xs font-medium text-gray mb-16 uppercase">
                                        Unmatched
                                    </p>
                                    {isLoading ? (
                                        <Skeleton
                                            containerTestId="payment-overview-item"
                                            width="80px"
                                        />
                                    ) : (
                                        <p className="text-lg font-semibold text-black ">
                                            {(clientPaymentSummary &&
                                                showMoneyInAppFormat(
                                                    clientPaymentSummary.total_unmatched_amount
                                                )) ||
                                                0}
                                        </p>
                                    )}
                                </div>
                            </article>
                        </div>
                    </div>

                    <div className="hidden lg:block mx-24 w-[1px] bg-strokedark h-[48px] transform translate-y-[10%]" />

                    <div className="flex-1">
                        <h3 className="uppercase text-sm font-semibold mb-12">
                            Total Write-off
                        </h3>

                        <div className="flex gap-12 flex-col md:flex-row">
                            <article className=" bg-white p-4 rounded-r8 flex-1">
                                <div className="p-12 bg-card-bg  rounded-r8 flex flex-col">
                                    <p className="text-xs font-medium text-gray mb-16 uppercase">
                                        Insurance
                                    </p>
                                    {isLoading ? (
                                        <Skeleton
                                            containerTestId="payment-overview-item"
                                            width="80px"
                                        />
                                    ) : (
                                        <p className="text-lg font-semibold text-black">
                                            {(clientPaymentSummary &&
                                                showMoneyInAppFormat(
                                                    clientPaymentSummary.total_insurance_amount_written_off
                                                )) ||
                                                0}
                                        </p>
                                    )}
                                </div>
                            </article>
                            <article className=" bg-white p-4 rounded-r8 flex-1">
                                <div className="p-12 bg-card-bg rounded-r8 flex flex-col">
                                    <p className="text-xs font-medium text-gray mb-16 uppercase">
                                        Co-Insurance
                                    </p>
                                    {isLoading ? (
                                        <Skeleton
                                            containerTestId="payment-overview-item"
                                            width="80px"
                                        />
                                    ) : (
                                        <p className="text-lg font-semibold text-black ">
                                            {(clientPaymentSummary &&
                                                showMoneyInAppFormat(
                                                    clientPaymentSummary.total_coinsurance_amount_written_off
                                                )) ||
                                                0}
                                        </p>
                                    )}
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { Alert, Input } from "@jhool-io/fe-components";
import { useParams } from "react-router-dom";
import useToast from "../../../../hooks/useToast";
import { useAddClientFlag } from "../../hooks/clients.mutations";
import { IAddClientFlag } from "../../types/clients.types";

interface AddClientFlagFormProps {
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
}

// For form validation purposes
const schema = yup.object({
    content: yup
        .string()
        .required("Flag label is required")
        .max(35, "Maximum number of characters is exceeded"),
});

export default function AddClientFlagForm({
    onFormSubmit,
}: AddClientFlagFormProps) {
    const params = useParams();

    const { clientId } = params;

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IAddClientFlag>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const { toast } = useToast();

    const queryClient = useQueryClient();

    const addFlag = useAddClientFlag(clientId || "");

    const onSubmit = (data: IAddClientFlag) => {
        addFlag.mutate(data, {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: [clientId, "client-flags"],
                });
                toast({
                    mode: "success",
                    message: res.message || "Flag added successfully",
                });
                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not add flag at this time.",
                });
            },
        });
    };

    return (
        <form id="add-client-flag" onSubmit={handleSubmit(onSubmit)}>
            <Alert
                classNames=" mb-12"
                type="info"
                description="A Client Flag is a customizable label that highlights notes or reminders linked to a client."
            />
            <Input
                {...register("content")}
                label="Flag label"
                placeholder="Flag label"
                hasError={!!errors.content}
                errorText={errors.content?.message}
            />
        </form>
    );
}

export default function HealthIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M7.47493 18.3307H12.4749C16.6416 18.3307 18.3083 16.6641 18.3083 12.4974V7.4974C18.3083 3.33073 16.6416 1.66406 12.4749 1.66406H7.47493C3.30827 1.66406 1.6416 3.33073 1.6416 7.4974V12.4974C1.6416 16.6641 3.30827 18.3307 7.47493 18.3307Z"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.6416 10.5821L6.6416 10.5655C7.2666 10.5655 7.9666 11.0405 8.19993 11.6238L9.14993 14.0238C9.3666 14.5655 9.70827 14.5655 9.92493 14.0238L11.8333 9.18212C12.0166 8.71546 12.3583 8.69879 12.5916 9.14046L13.4583 10.7821C13.7166 11.2738 14.3833 11.6738 14.9333 11.6738H18.3166"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

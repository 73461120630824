/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import {
    Button,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Input,
    Select,
    SelectElement,
} from "@jhool-io/fe-components";
import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import Navbar from "../../../components/Navbar/Navbar";
import { useFetchPracticeSettings } from "../hooks/queries/practice-settings";
import {
    IAddSettingChanges,
    SettingsType,
    SingleSetting,
} from "../types/practice-settings";
import RadioInput from "../../../components/RadioInput/RadioInput";
import {
    cn,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../utils/helpers";
import ListState from "../../../components/ListState/ListState";
import Skeleton from "../../../components/Skeleton/Skeleton";
import styles from "./PracticeSettings.module.scss";
import CheckPrimaryColorIcon from "../../../components/Icons/CheckPrimaryColor";
import { useAddSettingsChanges } from "../hooks/mutations/practise-settings";
import Card from "../../../components/Card/Card";
import { useFetchInsuranceProviders } from "../../../hooks/queries/client";
import ChevronDownIcon from "../../../components/Icons/ChevronDown";

type Options = {
    label: React.ReactNode;
    value: string;
};

interface CurrentValue {
    [key: string]: string;
}

export default function PracticeSettings() {
    // Query client
    const queryClient = useQueryClient();

    const categorySet = new Set();
    const [finalSettings, setFinalSettings] = React.useState<SingleSetting[]>(
        []
    );
    const [showModal, setShowModal] = React.useState(false);
    const [focusedIndex, setFocusedIndex] =
        React.useState<React.ReactNode>(null);

    const [filters, setFilters] = React.useState({
        settingsName: "",
        category: "",
    });
    const [addingMoreData, setAddingMoreData] = React.useState({
        show: false,
        setting_id: "",
        current_value: {},
    });
    const [showDefaultInsuranceProviders, setShowDefaultInsuranceProviders] =
        React.useState(true);
    const insuranceProviderSelectRef = React.useRef<SelectElement | null>(null);
    const [showAlert, setShowAlert] = React.useState<boolean>(false);
    const [dataToSend, setDataToSend] = React.useState<IAddSettingChanges>(
        {} as IAddSettingChanges
    );
    const [insuranceProviderSearchValue, setInsuranceProviderSearchValue] =
        React.useState("");

    // Hook for fetching all practice setting
    const { data, isLoading, isSuccess, error } = useFetchPracticeSettings({
        setting_name: filters.settingsName,
        category: filters.category,
    });

    const defaultInsuranceProviders = data?.data?.settings?.filter(
        (setting) => setting.setting_name === "insurance_providers"
    ) as SingleSetting[];

    const defaultFeeTypes = data?.data?.settings?.filter(
        (setting) => setting.setting_name === "fee_type"
    ) as SingleSetting[];

    // current array values of insurance providers
    const currentArray =
        defaultInsuranceProviders?.[0]?.current_value &&
        JSON.parse(
            (defaultInsuranceProviders &&
                defaultInsuranceProviders[0]?.current_value) ||
                ""
        );

    // default array values of insurance providers
    const defaultArray =
        defaultInsuranceProviders?.[0]?.default_value &&
        JSON.parse(
            (defaultInsuranceProviders &&
                defaultInsuranceProviders[0]?.default_value) ||
                ""
        );

    // default values to show on insurance provider multi select
    const providerDefaultValues = currentArray || defaultArray;

    const defaultSettingId = defaultInsuranceProviders?.[0]?.setting_id;

    // state for handling insurance provider changes
    const [selectedProviders, setSelectedProviders] = React.useState({
        setting_id: defaultSettingId,
        current_value: JSON.stringify(providerDefaultValues),
    } as IAddSettingChanges);

    const defaultValuesForFeeType =
        (defaultFeeTypes && defaultFeeTypes?.[0]?.current_value) ||
        defaultFeeTypes?.[0]?.default_value ||
        "";

    // state for handling fee type changes
    const [selectedFeeType, setSelectedFeeType] = React.useState(
        defaultValuesForFeeType
    );

    // query to add new setting change
    const addNewSettings = useAddSettingsChanges();

    const storedSettingsArray = JSON.parse(
        localStorage.getItem("settingsArray") || "[]"
    );
    const storedCategoriesArray = JSON.parse(
        localStorage.getItem("categoriesArray") || "[]"
    );

    const settingsNameTypesForSelect: Options[] = [
        {
            value: "",
            label: (
                <>All {!filters.settingsName && <CheckPrimaryColorIcon />}</>
            ),
        },
        ...storedSettingsArray.map((item: string) => ({
            value: item,
            label: (
                <>
                    {makeStringFirstLetterCapital(removeEnumUnderscore(item))}{" "}
                    {filters.settingsName === item && <CheckPrimaryColorIcon />}
                </>
            ),
        })),
    ];

    const categoryTypesForSelect: Options[] = [
        {
            value: "",
            label: <>All {!filters.category && <CheckPrimaryColorIcon />}</>,
        },
        ...storedCategoriesArray
            .filter((item: string) => {
                // Check if the category is already in the set
                if (categorySet.has(item)) {
                    return false; // Skip this category, if it is a duplicate
                }
                // Add the category to the set if its not a duplicate
                categorySet.add(item);
                return true;
            })
            .map((item: string) => ({
                value: item,
                label: (
                    <>
                        {makeStringFirstLetterCapital(
                            removeEnumUnderscore(item)
                        )}{" "}
                        {filters.category === item && <CheckPrimaryColorIcon />}
                    </>
                ),
            })),
    ];

    const insuranceProviders = useFetchInsuranceProviders();

    const InsuranceProvidersForSelect = insuranceProviders?.data?.data?.map(
        (insurance) => ({
            label: insurance.alias,
            value: insurance.name,
        })
    );

    // Get filtered options for clients list
    const filteredInsuranceProvider = InsuranceProvidersForSelect?.filter(
        (option) =>
            option.label
                .toLowerCase()
                .includes(insuranceProviderSearchValue.toLowerCase())
    );

    // this is used to rearrange the settings by category
    const settingsByCategory: { [key: string]: SingleSetting[] } = {};
    finalSettings.forEach((setting) => {
        const { category } = setting;

        if (!settingsByCategory[category]) {
            settingsByCategory[category] = [];
        }
        settingsByCategory[category].push(setting);
    });

    // this is used to get the key and value for settings of type key_value from default value which is a string with key and value
    React.useEffect(() => {
        if (data?.data.settings) {
            const generateInputFields = () => {
                const resultFields: SingleSetting[] =
                    [] as unknown as SingleSetting[];
                data?.data.settings.forEach((setting) => {
                    if (setting.setting_type === "key_value") {
                        try {
                            const valuesToUse = setting.current_value
                                ? JSON.parse(setting.current_value)
                                : JSON.parse(setting.default_value);

                            const keyValuesArray = Object.keys(valuesToUse).map(
                                (key) => ({
                                    key,
                                    value: valuesToUse[key],
                                })
                            );
                            // Add the keyValuesArray to the setting
                            setting.keyValuesArray = keyValuesArray;
                        } catch (err) {
                            //
                        }
                    }
                    return resultFields.push(setting);
                });
                setFinalSettings(resultFields);
            };
            generateInputFields();
        }
    }, [data?.data.settings]);

    // function to call endpoint to update setting change
    const onSubmit = (payload: IAddSettingChanges) => {
        const payloadDataToSend = {
            setting_id: payload.setting_id,
            current_value: payload.current_value,
        };
        addNewSettings.mutate(payloadDataToSend, {
            onSuccess: async () => {
                setShowAlert(true);
                queryClient.invalidateQueries(["practice-admin"]);
            },
        });
    };

    // function to know which key value pair is focused on
    const handleFocus = (index: React.ReactNode) => {
        setFocusedIndex(index);
    };

    // function to convert string to array
    function convertToArray(inputString: string) {
        // Split the string by commas
        const array = inputString.split(",").map((item: string) => item.trim());
        return array;
    }

    // function to handle onChange of key value pair inputs
    const handleKeyValueInputChange = (
        newKey: string,
        newValue: string,
        settingId: string
    ) => {
        setFinalSettings((prevSettings) => {
            // Find the index of the setting with the given settingId
            const settingIndex = prevSettings.findIndex(
                (setting) => setting.setting_id === settingId
            );

            // If the setting is found
            if (settingIndex !== -1) {
                // Duplicate the setting to avoid modifying the state directly
                const updatedSetting = { ...prevSettings[settingIndex] };

                if (updatedSetting.keyValuesArray) {
                    // Find the index of the key-value pair with the specified key
                    const keyValueIndex =
                        updatedSetting.keyValuesArray.findIndex(
                            (item) => item.key === newKey
                        );

                    // If keyValueIndex is found, update the existing key-value pair
                    if (keyValueIndex !== -1) {
                        updatedSetting.keyValuesArray[keyValueIndex] = {
                            key: newKey,
                            value: newValue,
                        };
                    }

                    // Update the current_value in dataToSend based on keyValuesArray
                    const currentValue = updatedSetting.keyValuesArray.reduce(
                        (result, { key, value }) => {
                            result[key] = value;
                            return result;
                        },
                        {} as Record<string, string>
                    );

                    // If keyValueIndex is not found, add the new key and value to dataToSend
                    if (keyValueIndex === -1) {
                        setDataToSend({
                            setting_id: settingId,
                            current_value: {
                                ...currentValue,
                                key: newKey,
                                value: newValue,
                            },
                        });
                    } else {
                        setDataToSend({
                            setting_id: settingId,
                            current_value: currentValue,
                        });
                    }
                    // Update the final settings with the modified settings array
                    return [
                        ...prevSettings.slice(0, settingIndex),
                        updatedSetting,
                        ...prevSettings.slice(settingIndex + 1),
                    ];
                }
            }
            return [...prevSettings];
        });
    };

    // function to submit setting change for key value pair
    const handleBlur = () => {
        const transformedObject: CurrentValue = {};

        Object.entries(dataToSend.current_value).forEach(
            ([key, value]: [string, unknown]) => {
                if (key === "key") {
                    transformedObject[value as string] = dataToSend
                        .current_value[key] as string;
                } else if (key === "value") {
                    transformedObject[dataToSend.current_value.key as string] =
                        value as string;
                } else if (value !== "" && key !== "") {
                    transformedObject[key] = value as string;
                }
            }
        );
        // Validation: Check if all keys and values in transformedObject are not empty
        const isValid = Object.values(transformedObject).every(
            (val) => val !== ""
        );

        if (dataToSend.current_value && isValid) {
            onSubmit({
                setting_id: addingMoreData.setting_id,
                current_value: {
                    ...addingMoreData.current_value,
                    ...transformedObject,
                },
            });
            setShowModal(false);
            setShowAlert(true);
        }
    };

    // this is used to remove the alert after 1secs if the user has not closed it
    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowAlert(false);
        }, 1500);
        // Clear the timeout when the component unmounts or showAlert changes
        return () => clearTimeout(timeoutId);
    }, [showAlert]);

    return (
        <>
            <Dialog open={showModal}>
                <DialogContent
                    title="Add new setting"
                    handleCloseDialog={() => setShowModal(false)}
                    variant="center"
                    showFooter
                    cancelText="Cancel"
                    saveText="Add new setting"
                    onSaveClick={handleBlur}
                    isCancelBtnDisabled={addNewSettings.isLoading}
                    isSubmitBtnDisabled={addNewSettings.isLoading}
                >
                    <>
                        <div>
                            <Input
                                defaultValue=""
                                placeholder="Key"
                                label="Key"
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setDataToSend({
                                        ...dataToSend,
                                        current_value: {
                                            key: e.target.value,
                                            value: dataToSend?.current_value
                                                ?.value,
                                        },
                                    });
                                }}
                            />
                        </div>
                        <div className="mt-[26px]">
                            <Input
                                defaultValue=""
                                placeholder="Value"
                                label="Value"
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setDataToSend({
                                        ...dataToSend,
                                        current_value: {
                                            key: dataToSend?.current_value?.key,
                                            value: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </div>
                    </>
                </DialogContent>
            </Dialog>
            <div>
                <Navbar title="Practice Settings" />
                <div className="page">
                    {(!error || (error && error?.response?.status === 404)) && (
                        <div className="flex justify-between">
                            <div className="flex items-center gap-x-12 w-1/2 *:w-1/2 mb-32">
                                <Select
                                    label="Setting Name"
                                    placeholder="Setting Name"
                                    options={settingsNameTypesForSelect}
                                    defaultValue={filters.settingsName}
                                    onChange={(value) => {
                                        setFilters({
                                            ...filters,
                                            settingsName: (value as Options)
                                                .value,
                                        });
                                    }}
                                    isDisabled={isLoading}
                                    isSearchable
                                />
                                <Select
                                    label="Category"
                                    placeholder="Category"
                                    options={categoryTypesForSelect}
                                    defaultValue={filters.category}
                                    onChange={(value) => {
                                        setFilters({
                                            ...filters,
                                            category: (value as Options).value,
                                        });
                                    }}
                                    isDisabled={isLoading}
                                    isSearchable
                                />
                            </div>
                        </div>
                    )}
                    {error && error?.response?.status !== 404 && (
                        <ListState
                            isError
                            stateHelperText="Try reloading this page to solve this issue"
                            errorMsg={
                                error?.response?.data.message ||
                                `Cannot display settings at this time please try again later`
                            }
                        />
                    )}
                    {data && isSuccess && data.data.settings.length === 0 && (
                        <ListState
                            stateHelperText="Settings list will appear here"
                            isError={false}
                            emptyMessage="There are no settings yet"
                        />
                    )}
                    {isLoading && (
                        <Skeleton
                            type="table"
                            containerTestId="practice-settings-loader"
                            count={6}
                        />
                    )}
                    {!isLoading &&
                        isSuccess &&
                        data &&
                        data.data.settings.length > 0 && (
                            <div>
                                {Object.entries(settingsByCategory).map(
                                    ([category, categorySettings]) => {
                                        const sortedSettings = categorySettings
                                            .slice()
                                            .sort((a, b) => {
                                                const labelA =
                                                    a.setting_label.toUpperCase();
                                                const labelB =
                                                    b.setting_label.toUpperCase();

                                                if (labelA < labelB) {
                                                    return -1;
                                                }
                                                if (labelA > labelB) {
                                                    return 1;
                                                }
                                                return 0;
                                            });
                                        return (
                                            <div
                                                key={category}
                                                className="mb-20"
                                            >
                                                <Card className="pb-[32px]">
                                                    <h2 className="text-base text-black border-b border-[#E7E7E7] pb-24 pt-32 pl-24">
                                                        {makeStringFirstLetterCapital(
                                                            category
                                                        )}
                                                    </h2>
                                                    <div
                                                        className={
                                                            styles.parent
                                                        }
                                                    >
                                                        {sortedSettings?.map(
                                                            (setting) => (
                                                                <div
                                                                    key={
                                                                        setting.setting_id
                                                                    }
                                                                    className="justify-between mt-20 flex-col relative items-start text-gray mb-8 capitalize pl-24"
                                                                >
                                                                    <h3 className="text-sm font-semibold leading-7 text-black">
                                                                        {" "}
                                                                        {
                                                                            setting.setting_label
                                                                        }
                                                                    </h3>
                                                                    <div>
                                                                        {setting.setting_type ===
                                                                            SettingsType.KEY_VALUE && (
                                                                            <div
                                                                                className={
                                                                                    styles.wrapper
                                                                                }
                                                                            >
                                                                                {setting?.keyValuesArray?.map(
                                                                                    (
                                                                                        {
                                                                                            key,
                                                                                            value,
                                                                                        },
                                                                                        arrayIndex
                                                                                    ) => (
                                                                                        <div
                                                                                            key={`${
                                                                                                arrayIndex +
                                                                                                1
                                                                                            }`}
                                                                                            className="flex flex-col"
                                                                                        >
                                                                                            <div className="mt-5">
                                                                                                <div>
                                                                                                    {key !==
                                                                                                        "" && (
                                                                                                        <h4 className="text-xs font-medium leading-7 capitalize">
                                                                                                            {typeof key ===
                                                                                                            "string"
                                                                                                                ? removeEnumUnderscore(
                                                                                                                      key
                                                                                                                  )
                                                                                                                : key}
                                                                                                        </h4>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div className="mt-10 relative">
                                                                                                    <Input
                                                                                                        defaultValue={
                                                                                                            typeof value ===
                                                                                                            "string"
                                                                                                                ? removeEnumUnderscore(
                                                                                                                      value
                                                                                                                  )
                                                                                                                : value
                                                                                                        }
                                                                                                        placeholder="Value"
                                                                                                        className="w-[95%] max-sm:w-[95%]"
                                                                                                        onFocus={() =>
                                                                                                            handleFocus(
                                                                                                                arrayIndex
                                                                                                            )
                                                                                                        }
                                                                                                        onBlur={
                                                                                                            handleBlur
                                                                                                        }
                                                                                                        onChange={(
                                                                                                            e: React.ChangeEvent<HTMLInputElement>
                                                                                                        ) => {
                                                                                                            setAddingMoreData(
                                                                                                                {
                                                                                                                    ...addingMoreData,
                                                                                                                    setting_id:
                                                                                                                        setting.setting_id,
                                                                                                                }
                                                                                                            );
                                                                                                            handleKeyValueInputChange(
                                                                                                                key ||
                                                                                                                    dataToSend
                                                                                                                        ?.current_value
                                                                                                                        ?.key ||
                                                                                                                    "",
                                                                                                                e
                                                                                                                    .target
                                                                                                                    .value,
                                                                                                                setting.setting_id
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                    {focusedIndex ===
                                                                                                        arrayIndex &&
                                                                                                        showAlert &&
                                                                                                        dataToSend.setting_id ===
                                                                                                            setting.setting_id && (
                                                                                                            <div className="ml-20 absolute top-[1.2rem] right-[5rem]">
                                                                                                                <svg
                                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                                    width="16"
                                                                                                                    height="16"
                                                                                                                    viewBox="0 0 16 16"
                                                                                                                    fill="none"
                                                                                                                >
                                                                                                                    <path
                                                                                                                        d="M8 1C6.61553 1 5.26216 1.41054 4.11101 2.17971C2.95987 2.94888 2.06266 4.04213 1.53285 5.32122C1.00303 6.6003 0.86441 8.00776 1.13451 9.36563C1.4046 10.7235 2.07129 11.9708 3.05026 12.9497C4.02922 13.9287 5.2765 14.5954 6.63437 14.8655C7.99224 15.1356 9.3997 14.997 10.6788 14.4672C11.9579 13.9373 13.0511 13.0401 13.8203 11.889C14.5895 10.7378 15 9.38447 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1ZM7 10.795L4.5 8.295L5.295 7.5L7 9.205L10.705 5.5L11.503 6.293L7 10.795Z"
                                                                                                                        fill="#1A738D"
                                                                                                                    />
                                                                                                                </svg>
                                                                                                            </div>
                                                                                                        )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                                {setting.keyValuesArray && (
                                                                                    <div>
                                                                                        <button
                                                                                            className={
                                                                                                styles.add_more
                                                                                            }
                                                                                            onClick={() => {
                                                                                                setShowModal(
                                                                                                    true
                                                                                                );
                                                                                                let currentValue =
                                                                                                    {};
                                                                                                const settingIndex =
                                                                                                    categorySettings.findIndex(
                                                                                                        (
                                                                                                            item
                                                                                                        ) =>
                                                                                                            item.setting_id ===
                                                                                                            setting.setting_id
                                                                                                    );
                                                                                                const currentSetting =
                                                                                                    {
                                                                                                        ...categorySettings[
                                                                                                            settingIndex
                                                                                                        ],
                                                                                                    };

                                                                                                if (
                                                                                                    currentSetting.keyValuesArray
                                                                                                ) {
                                                                                                    currentValue =
                                                                                                        currentSetting.keyValuesArray.reduce(
                                                                                                            (
                                                                                                                result,
                                                                                                                {
                                                                                                                    key,
                                                                                                                    value,
                                                                                                                }
                                                                                                            ) => {
                                                                                                                result[
                                                                                                                    key
                                                                                                                ] =
                                                                                                                    value;
                                                                                                                return result;
                                                                                                            },
                                                                                                            {} as Record<
                                                                                                                string,
                                                                                                                string
                                                                                                            >
                                                                                                        );
                                                                                                }
                                                                                                setAddingMoreData(
                                                                                                    {
                                                                                                        ...addingMoreData,
                                                                                                        setting_id:
                                                                                                            setting.setting_id,
                                                                                                        current_value:
                                                                                                            currentValue,
                                                                                                    }
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            Add
                                                                                            new
                                                                                            setting
                                                                                        </button>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                        {setting.setting_type ===
                                                                            SettingsType.ARRAY &&
                                                                            setting.setting_name ===
                                                                                "insurance_providers" && (
                                                                                <div className="mt-12 relative w-[46%] max-sm:w-[auto]">
                                                                                    <Select
                                                                                        ref={
                                                                                            insuranceProviderSelectRef
                                                                                        }
                                                                                        isSearchable
                                                                                        filterOption={() =>
                                                                                            true
                                                                                        }
                                                                                        multiHasValues
                                                                                        isMulti
                                                                                        placeholder="Insurance Provider (Type multiple)"
                                                                                        value={
                                                                                            showDefaultInsuranceProviders
                                                                                                ? InsuranceProvidersForSelect?.filter(
                                                                                                      (
                                                                                                          insurance
                                                                                                      ) => {
                                                                                                          return selectedProviders?.current_value?.includes(
                                                                                                              insurance.value
                                                                                                          );
                                                                                                      }
                                                                                                  ) ||
                                                                                                  []
                                                                                                : undefined
                                                                                        }
                                                                                        aria-label="Insurance Provider"
                                                                                        label="Insurance Provider"
                                                                                        options={
                                                                                            filteredInsuranceProvider
                                                                                        }
                                                                                        onChange={(
                                                                                            values: any
                                                                                        ) => {
                                                                                            const namesArray =
                                                                                                values?.map(
                                                                                                    (provider: {
                                                                                                        value: any;
                                                                                                    }) =>
                                                                                                        provider.value
                                                                                                );
                                                                                            setSelectedProviders(
                                                                                                {
                                                                                                    ...selectedProviders,
                                                                                                    setting_id:
                                                                                                        setting.setting_id,
                                                                                                    current_value:
                                                                                                        JSON.stringify(
                                                                                                            namesArray
                                                                                                        ),
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                        onInputChange={(
                                                                                            value
                                                                                        ) => {
                                                                                            setInsuranceProviderSearchValue(
                                                                                                value
                                                                                            );
                                                                                            setShowDefaultInsuranceProviders(
                                                                                                true
                                                                                            );
                                                                                        }}
                                                                                        onBlur={() =>
                                                                                            onSubmit(
                                                                                                selectedProviders
                                                                                            )
                                                                                        }
                                                                                        openMenuOnClick={
                                                                                            false
                                                                                        }
                                                                                        menuIsOpen={
                                                                                            insuranceProviderSearchValue.length >
                                                                                            0
                                                                                        }
                                                                                    />

                                                                                    {showAlert &&
                                                                                        selectedProviders?.setting_id ===
                                                                                            setting.setting_id && (
                                                                                            <div className="ml-20 absolute top-[3.2rem] right-[4rem]">
                                                                                                <svg
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width="16"
                                                                                                    height="16"
                                                                                                    viewBox="0 0 16 16"
                                                                                                    fill="none"
                                                                                                >
                                                                                                    <path
                                                                                                        d="M8 1C6.61553 1 5.26216 1.41054 4.11101 2.17971C2.95987 2.94888 2.06266 4.04213 1.53285 5.32122C1.00303 6.6003 0.86441 8.00776 1.13451 9.36563C1.4046 10.7235 2.07129 11.9708 3.05026 12.9497C4.02922 13.9287 5.2765 14.5954 6.63437 14.8655C7.99224 15.1356 9.3997 14.997 10.6788 14.4672C11.9579 13.9373 13.0511 13.0401 13.8203 11.889C14.5895 10.7378 15 9.38447 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1ZM7 10.795L4.5 8.295L5.295 7.5L7 9.205L10.705 5.5L11.503 6.293L7 10.795Z"
                                                                                                        fill="#1A738D"
                                                                                                    />
                                                                                                </svg>
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                            )}
                                                                        {setting.setting_type ===
                                                                            SettingsType.ARRAY &&
                                                                            setting.setting_name !==
                                                                                "insurance_providers" && (
                                                                                <div>
                                                                                    <div className="mt-12 relative w-[46%] max-sm:w-[95%]">
                                                                                        <Input
                                                                                            defaultValue={
                                                                                                setting?.current_value?.replace(
                                                                                                    /^\[|'|"|\]$/g,
                                                                                                    ""
                                                                                                ) ||
                                                                                                setting?.default_value?.replace(
                                                                                                    /^\[|'|"|\]$/g,
                                                                                                    ""
                                                                                                ) ||
                                                                                                ""
                                                                                            }
                                                                                            onChange={(
                                                                                                e: React.ChangeEvent<HTMLInputElement>
                                                                                            ) => {
                                                                                                setDataToSend(
                                                                                                    {
                                                                                                        ...dataToSend,
                                                                                                        setting_id:
                                                                                                            setting.setting_id,
                                                                                                        current_value:
                                                                                                            JSON.stringify(
                                                                                                                convertToArray(
                                                                                                                    e
                                                                                                                        .target
                                                                                                                        .value
                                                                                                                )
                                                                                                            ),
                                                                                                    }
                                                                                                );
                                                                                            }}
                                                                                            placeholder="Enter value"
                                                                                            onBlur={() =>
                                                                                                onSubmit(
                                                                                                    dataToSend
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                        {showAlert &&
                                                                                            dataToSend.setting_id ===
                                                                                                setting.setting_id && (
                                                                                                <div className="ml-20 absolute top-[1.2rem] right-[1rem]">
                                                                                                    <svg
                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                        width="16"
                                                                                                        height="16"
                                                                                                        viewBox="0 0 16 16"
                                                                                                        fill="none"
                                                                                                    >
                                                                                                        <path
                                                                                                            d="M8 1C6.61553 1 5.26216 1.41054 4.11101 2.17971C2.95987 2.94888 2.06266 4.04213 1.53285 5.32122C1.00303 6.6003 0.86441 8.00776 1.13451 9.36563C1.4046 10.7235 2.07129 11.9708 3.05026 12.9497C4.02922 13.9287 5.2765 14.5954 6.63437 14.8655C7.99224 15.1356 9.3997 14.997 10.6788 14.4672C11.9579 13.9373 13.0511 13.0401 13.8203 11.889C14.5895 10.7378 15 9.38447 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1ZM7 10.795L4.5 8.295L5.295 7.5L7 9.205L10.705 5.5L11.503 6.293L7 10.795Z"
                                                                                                            fill="#1A738D"
                                                                                                        />
                                                                                                    </svg>
                                                                                                </div>
                                                                                            )}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        {setting.setting_type ===
                                                                            SettingsType.STRING &&
                                                                            setting.setting_name !==
                                                                                "fee_type" && (
                                                                                <div>
                                                                                    <div className="mt-12 relative w-[46%] max-sm:w-[95%]">
                                                                                        <Input
                                                                                            defaultValue={
                                                                                                setting.current_value ||
                                                                                                setting.default_value ||
                                                                                                ""
                                                                                            }
                                                                                            onChange={(
                                                                                                e: React.ChangeEvent<HTMLInputElement>
                                                                                            ) => {
                                                                                                setDataToSend(
                                                                                                    {
                                                                                                        ...dataToSend,
                                                                                                        setting_id:
                                                                                                            setting.setting_id,
                                                                                                        current_value:
                                                                                                            e
                                                                                                                .target
                                                                                                                .value,
                                                                                                    }
                                                                                                );
                                                                                            }}
                                                                                            placeholder="Enter value"
                                                                                            onBlur={() =>
                                                                                                onSubmit(
                                                                                                    dataToSend
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                        {showAlert &&
                                                                                            dataToSend.setting_id ===
                                                                                                setting.setting_id && (
                                                                                                <div className="ml-20 absolute top-[1.2rem] right-[1rem]">
                                                                                                    <svg
                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                        width="16"
                                                                                                        height="16"
                                                                                                        viewBox="0 0 16 16"
                                                                                                        fill="none"
                                                                                                    >
                                                                                                        <path
                                                                                                            d="M8 1C6.61553 1 5.26216 1.41054 4.11101 2.17971C2.95987 2.94888 2.06266 4.04213 1.53285 5.32122C1.00303 6.6003 0.86441 8.00776 1.13451 9.36563C1.4046 10.7235 2.07129 11.9708 3.05026 12.9497C4.02922 13.9287 5.2765 14.5954 6.63437 14.8655C7.99224 15.1356 9.3997 14.997 10.6788 14.4672C11.9579 13.9373 13.0511 13.0401 13.8203 11.889C14.5895 10.7378 15 9.38447 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1ZM7 10.795L4.5 8.295L5.295 7.5L7 9.205L10.705 5.5L11.503 6.293L7 10.795Z"
                                                                                                            fill="#1A738D"
                                                                                                        />
                                                                                                    </svg>
                                                                                                </div>
                                                                                            )}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        {setting.setting_name ===
                                                                            "fee_type" && (
                                                                            <div>
                                                                                <div className="mt-12 relative w-[46%] max-sm:w-[95%]">
                                                                                    <Dropdown>
                                                                                        <DropdownTrigger
                                                                                            asChild
                                                                                        >
                                                                                            <Button
                                                                                                className="w-full h-[4.3rem] text-xs justify-between items-center font-normal data-[state=open]:border-primary px-12 "
                                                                                                variant="secondary"
                                                                                                mode="outline"
                                                                                            >
                                                                                                <span className="text-gray">
                                                                                                    {
                                                                                                        selectedFeeType
                                                                                                    }
                                                                                                </span>

                                                                                                <ChevronDownIcon stroke="#576a81" />
                                                                                            </Button>
                                                                                        </DropdownTrigger>
                                                                                        <DropdownContent
                                                                                            align="center"
                                                                                            className="h-auto w-96 px-16 pt-6 z-20 overflow-y-auto"
                                                                                        >
                                                                                            <DropdownItem
                                                                                                className={cn(
                                                                                                    "flex gap-x-8 justify-between items-center text-xs text-gray cursor-pointer font-medium",
                                                                                                    {
                                                                                                        "text-primary":
                                                                                                            selectedFeeType ===
                                                                                                            "OON",
                                                                                                    }
                                                                                                )}
                                                                                                onClick={() => {
                                                                                                    setSelectedFeeType(
                                                                                                        "OON"
                                                                                                    );
                                                                                                    setDataToSend(
                                                                                                        {
                                                                                                            ...dataToSend,
                                                                                                            setting_id:
                                                                                                                setting.setting_id,
                                                                                                            current_value:
                                                                                                                "OON",
                                                                                                        }
                                                                                                    );
                                                                                                    onSubmit(
                                                                                                        {
                                                                                                            ...dataToSend,
                                                                                                            setting_id:
                                                                                                                setting.setting_id,
                                                                                                            current_value:
                                                                                                                "OON",
                                                                                                        }
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                OON
                                                                                            </DropdownItem>
                                                                                            <DropdownItem
                                                                                                className={cn(
                                                                                                    "flex gap-x-8 justify-between items-center text-xs text-gray cursor-pointer font-medium",
                                                                                                    {
                                                                                                        "text-primary":
                                                                                                            selectedFeeType ===
                                                                                                            "INN",
                                                                                                    }
                                                                                                )}
                                                                                                onClick={() => {
                                                                                                    setSelectedFeeType(
                                                                                                        "INN"
                                                                                                    );
                                                                                                    setDataToSend(
                                                                                                        {
                                                                                                            ...dataToSend,
                                                                                                            setting_id:
                                                                                                                setting.setting_id,
                                                                                                            current_value:
                                                                                                                "INN",
                                                                                                        }
                                                                                                    );
                                                                                                    onSubmit(
                                                                                                        {
                                                                                                            ...dataToSend,
                                                                                                            setting_id:
                                                                                                                setting.setting_id,
                                                                                                            current_value:
                                                                                                                "INN",
                                                                                                        }
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                INN
                                                                                            </DropdownItem>
                                                                                        </DropdownContent>
                                                                                    </Dropdown>
                                                                                    {showAlert &&
                                                                                        dataToSend.setting_id ===
                                                                                            setting.setting_id && (
                                                                                            <div className="ml-20 absolute top-[1.2rem] right-[3rem]">
                                                                                                <svg
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width="16"
                                                                                                    height="16"
                                                                                                    viewBox="0 0 16 16"
                                                                                                    fill="none"
                                                                                                >
                                                                                                    <path
                                                                                                        d="M8 1C6.61553 1 5.26216 1.41054 4.11101 2.17971C2.95987 2.94888 2.06266 4.04213 1.53285 5.32122C1.00303 6.6003 0.86441 8.00776 1.13451 9.36563C1.4046 10.7235 2.07129 11.9708 3.05026 12.9497C4.02922 13.9287 5.2765 14.5954 6.63437 14.8655C7.99224 15.1356 9.3997 14.997 10.6788 14.4672C11.9579 13.9373 13.0511 13.0401 13.8203 11.889C14.5895 10.7378 15 9.38447 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1ZM7 10.795L4.5 8.295L5.295 7.5L7 9.205L10.705 5.5L11.503 6.293L7 10.795Z"
                                                                                                        fill="#1A738D"
                                                                                                    />
                                                                                                </svg>
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                            </div>
                                                                        )}

                                                                        {setting.setting_type ===
                                                                            SettingsType.BOOLEAN && (
                                                                            <div className="mt-10 mb-10 flex items-center max-sm:flex max-sm:flex-col">
                                                                                <div className="flex items-center w-[22.5%] max-sm:w-[95%] pl-10 max-sm:mr-10 bg-white font-normal rounded-r8 border-[0.8px] border-[#E7E7E7] outline-none h-[43px]">
                                                                                    <RadioInput
                                                                                        name="radio-input"
                                                                                        label="True"
                                                                                        value="true"
                                                                                        checked={
                                                                                            (setting.current_value ??
                                                                                                setting.default_value) ===
                                                                                            "true"
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) => {
                                                                                            setDataToSend(
                                                                                                {
                                                                                                    ...dataToSend,
                                                                                                    setting_id:
                                                                                                        setting.setting_id,
                                                                                                    current_value:
                                                                                                        e
                                                                                                            .currentTarget
                                                                                                            .value,
                                                                                                    setting_name:
                                                                                                        setting.setting_name,
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                        onBlur={() =>
                                                                                            onSubmit(
                                                                                                dataToSend
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="flex items-center max-sm:mt-5 max-sm:ml-0 w-[22.5%] max-sm:w-[95%] max-sm:mr-10 pl-10 bg-white font-normal rounded-r8 border-[0.8px] border-[#E7E7E7] outline-none h-[43px] ml-[15px]">
                                                                                    <RadioInput
                                                                                        name="radio-input"
                                                                                        label="False"
                                                                                        value="false"
                                                                                        checked={
                                                                                            (setting.current_value ??
                                                                                                setting.default_value) ===
                                                                                            "false"
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) => {
                                                                                            setDataToSend(
                                                                                                {
                                                                                                    ...dataToSend,
                                                                                                    setting_id:
                                                                                                        setting.setting_id,
                                                                                                    current_value:
                                                                                                        e
                                                                                                            .currentTarget
                                                                                                            .value,
                                                                                                    setting_name:
                                                                                                        setting.setting_name,
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                        onBlur={() =>
                                                                                            onSubmit(
                                                                                                dataToSend
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>

                                                                                {showAlert &&
                                                                                    dataToSend.setting_id ===
                                                                                        setting.setting_id && (
                                                                                        <div className="ml-10 ">
                                                                                            <svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                width="16"
                                                                                                height="16"
                                                                                                viewBox="0 0 16 16"
                                                                                                fill="none"
                                                                                            >
                                                                                                <path
                                                                                                    d="M8 1C6.61553 1 5.26216 1.41054 4.11101 2.17971C2.95987 2.94888 2.06266 4.04213 1.53285 5.32122C1.00303 6.6003 0.86441 8.00776 1.13451 9.36563C1.4046 10.7235 2.07129 11.9708 3.05026 12.9497C4.02922 13.9287 5.2765 14.5954 6.63437 14.8655C7.99224 15.1356 9.3997 14.997 10.6788 14.4672C11.9579 13.9373 13.0511 13.0401 13.8203 11.889C14.5895 10.7378 15 9.38447 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1ZM7 10.795L4.5 8.295L5.295 7.5L7 9.205L10.705 5.5L11.503 6.293L7 10.795Z"
                                                                                                    fill="#1A738D"
                                                                                                />
                                                                                            </svg>
                                                                                        </div>
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                        {setting.setting_type ===
                                                                            SettingsType.NUMBER && (
                                                                            <div className="mt-10 relative w-[46%] max-sm:w-[95%]">
                                                                                <Input
                                                                                    label=""
                                                                                    defaultValue={
                                                                                        setting.current_value ||
                                                                                        setting.default_value ||
                                                                                        ""
                                                                                    }
                                                                                    placeholder="Enter value"
                                                                                    type="number"
                                                                                    onBlur={() =>
                                                                                        onSubmit(
                                                                                            dataToSend
                                                                                        )
                                                                                    }
                                                                                    onChange={(
                                                                                        e: React.ChangeEvent<HTMLInputElement>
                                                                                    ) =>
                                                                                        setDataToSend(
                                                                                            {
                                                                                                ...dataToSend,
                                                                                                setting_id:
                                                                                                    setting.setting_id,
                                                                                                current_value:
                                                                                                    e
                                                                                                        .target
                                                                                                        .value,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                />
                                                                                {showAlert &&
                                                                                    dataToSend.setting_id ===
                                                                                        setting.setting_id && (
                                                                                        <div className="ml-20 absolute top-[1.2rem] right-[1rem]">
                                                                                            <svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                width="16"
                                                                                                height="16"
                                                                                                viewBox="0 0 16 16"
                                                                                                fill="none"
                                                                                            >
                                                                                                <path
                                                                                                    d="M8 1C6.61553 1 5.26216 1.41054 4.11101 2.17971C2.95987 2.94888 2.06266 4.04213 1.53285 5.32122C1.00303 6.6003 0.86441 8.00776 1.13451 9.36563C1.4046 10.7235 2.07129 11.9708 3.05026 12.9497C4.02922 13.9287 5.2765 14.5954 6.63437 14.8655C7.99224 15.1356 9.3997 14.997 10.6788 14.4672C11.9579 13.9373 13.0511 13.0401 13.8203 11.889C14.5895 10.7378 15 9.38447 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1ZM7 10.795L4.5 8.295L5.295 7.5L7 9.205L10.705 5.5L11.503 6.293L7 10.795Z"
                                                                                                    fill="#1A738D"
                                                                                                />
                                                                                            </svg>
                                                                                        </div>
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </Card>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        )}
                </div>
            </div>
        </>
    );
}

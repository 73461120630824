import Tp from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import React from "react";

import styles from "./Tooltip.module.scss";

interface TooltipProps {
    /** Trigger for opening tooltip, string should be one of 'hover','click','focus' */
    trigger?: string;
    /** Tooltip placement relative to its triggerElement */
    placement?:
        | "left"
        | "right"
        | "top"
        | "bottom"
        | "topLeft"
        | "topRight"
        | "bottomLeft"
        | "bottomRight"
        | "rightTop"
        | "rightBottom"
        | "leftTop"
        | "leftBottom";
    /** Tooltip content */
    content: React.ReactNode;
    /** Determines if tooltip arrow is visible or not */
    showArrow: boolean;
    /** Id which gets attached to the tooltip content. Can be used with aria-describedby to achieve Screenreader-Support. */
    id?: string;
    /** Trigger Element  */
    children: React.ReactElement;
    /** Optional className property for extending tooltip */
    className?: string;
}

export default function Tooltip({
    trigger,
    placement,
    content,
    showArrow,
    id,
    children,
    className,
}: TooltipProps) {
    return (
        <Tp
            trigger={trigger || "hover"}
            placement={placement || "bottom"}
            overlay={
                <div className={className || styles.tooltip}>{content}</div>
            }
            showArrow={showArrow}
            id={id}
        >
            {children}
        </Tp>
    );
}

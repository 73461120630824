import { CANCELLATION_NOTE_LABELS } from "../../../../utils/constants";
import { IAddendumDetails } from "../../../../utils/types/notes";
import NoteContentItem from "../NoteContentItem/NoteContentItem";

interface ViewCancellationNoteProps {
    noteContent: { [key: string]: string };
    fieldAddendums: {
        [key: string]: IAddendumDetails[];
    } | null;
}

export default function ViewCancellationNote({
    noteContent,
    fieldAddendums,
}: ViewCancellationNoteProps) {
    return (
        <NoteContentItem
            headerText={CANCELLATION_NOTE_LABELS.reason}
            fieldId="reason"
            addendums={fieldAddendums?.reason}
            content={noteContent.reason || "--"}
        />
    );
}

import { useParams } from "react-router-dom";
import { useFetchProviders } from "../../modules/providers/hooks/providers.queries";
import Navbar from "../../components/Navbar/Navbar";
import CaseloadWrapper from "../../modules/providers/components/CaseloadWrapper/CaseloadWrapper";

export default function Provider() {
    const params = useParams();
    const providerId = params.providerId as string;

    const providers = useFetchProviders({
        search_string: "",
        page: 1,
        limit: 20,
        provider_id: providerId,
    });

    let providerName;
    if (providers.error) providerName = "";
    else if (providers.isLoading) providerName = "";
    else if (providers.data.data && providers.data.data.length > 0)
        providerName = providers.data.data[0]?.is_supervisor
            ? `${providers.data.data[0]?.provider_name}'s Clients and Supervisees`
            : `${providers.data.data[0]?.provider_name}'s Clients`;

    return (
        <>
            <Navbar title={providerName || ""} />
            <div className="page">
                <CaseloadWrapper
                    isSupervisor={
                        providers?.data?.data &&
                        Boolean(providers?.data?.data[0]?.is_supervisor)
                    }
                />
            </div>
        </>
    );
}

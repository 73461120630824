export default function DotIcon() {
    return (
        <svg
            width="5"
            height="6"
            viewBox="0 0 5 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="2.5" cy="3" r="2.5" fill="#576A81" opacity="0.4" />
        </svg>
    );
}

import { Tag } from "@jhool-io/fe-components";
import { removeEnumUnderscore } from "../../../utils/helpers";
import { PaymentStatus } from "../types/payments.types";

export const getPaymentStatusTag = (status: PaymentStatus) => {
    let bgColor: string;
    let textColor: string;

    switch (status) {
        case PaymentStatus.COMPLETED:
            textColor = "#00563E";
            bgColor = "rgba(204, 250, 233, 0.50)";
            break;
        case PaymentStatus.PENDING:
            textColor = "#634D17";
            bgColor = "rgba(247, 229, 164, 0.50)";
            break;
        case PaymentStatus.FAILED:
            textColor = "#981F41";
            bgColor = "rgba(251, 199, 198, 0.50)";
            break;
        case PaymentStatus.REFUNDED:
            textColor = "#165574";
            bgColor = "rgba(172, 222, 250, 0.50)";
            break;
        case PaymentStatus.VOIDED:
            textColor = "#981F41";
            bgColor = "rgba(251, 199, 198, 0.50)";
            break;
        default:
            textColor = "#0B132B";
            bgColor = "#F5F5F5";
    }

    return (
        <Tag
            bgColor={bgColor}
            textColor={textColor}
            title={removeEnumUnderscore(status)}
            className="capitalize w-auto h-auto text-nowrap px-8 py-4 rounded-r4"
        />
    );
};

import Navbar from "../../../components/Navbar/Navbar";
import RemitClaims from "../components/RemitClaims/RemitClaims";

export default function Remits() {
    return (
        <>
            <Navbar title="Remit Claims" />
            <div className="page">
                <RemitClaims />
            </div>
        </>
    );
}

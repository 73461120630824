import { format } from "date-fns";
import axios from "../utils/axios";
import { handleRemoveEmptyParamFromQueryParams } from "../utils/helpers";
import {
    IAddPayment,
    IGetPayment,
    IUnmatchedPaymentFilterTypes,
    IUpdateInvoice,
    IAddPaymentToMatch,
    IMatchedPaymentFilterTypes,
    IAllUnmatchedPaymentFilterTypes,
    IDeleteUnMatchedPayment,
    IRefreshPaymentStatus,
    IChargeClient,
    IUnmatchPayment,
    IRefundPayment,
    IBillingAdminDashboardFilters,
    IUpdateInvoiceCharge,
    IRematchPayment,
    IBulkPayment,
    ISplitPaymentPayload,
    IInvoiceNotesFilters,
    BillingOverviewFilters,
    IUpdatePaymentWriteoff,
    IMovePayment,
} from "../utils/types/billing";

// Endpoint to update invoice status
export async function patchInvoiceStatus(payload: IUpdateInvoice) {
    const response = await axios.patch(`/invoice`, payload);

    return response.data;
}

// Endpoint to refresh payment status
export async function refreshPaymentStatus(payload: IRefreshPaymentStatus) {
    const response = await axios.patch(`/invoice/payment_update`, payload);

    return response.data;
}
// Endpoint to match payment
export async function matchPayment(payload: IAddPaymentToMatch) {
    const response = await axios.post(`/invoice/match_payments`, payload);

    return response.data;
}

// Endpoint to add invoice payment
export async function addInvoicePayment(
    invoiceId: string,
    payload: IAddPayment
) {
    const response = await axios.post(`/invoice/${invoiceId}/payment`, payload);
    return response.data;
}

// Endpoint to get invoice payment
export async function getInvoicePayment(
    invoiceId: string,
    payload: IGetPayment
) {
    const response = await axios.get(`/invoice/${invoiceId}/payments`, {
        params: payload,
    });

    return response.data;
}

// Endpoint to fetch unmatched payment
export async function fetchClientUnmatchedPayments(
    filters: IUnmatchedPaymentFilterTypes
) {
    const response = await axios.get(`/invoice/client/unmatched_payments`, {
        params: handleRemoveEmptyParamFromQueryParams({
            ...filters,
        }),
    });
    return response.data;
}

// Endpoint to fetch billing dashboard overview
export async function fetchBillingDashboardOverview(
    filters: Pick<BillingOverviewFilters, "insurance_provider_id">
) {
    const response = await axios.get(`/dashboard/biller-overview`, {
        params: handleRemoveEmptyParamFromQueryParams({
            ...filters,
        }),
    });
    return response.data;
}

// Endpoint to fetch billing dashboard expiring policies
export async function fetchBillingExpiringPolicies(
    filters: BillingOverviewFilters
) {
    const response = await axios.get(`/dashboard/expiring-policies`, {
        params: handleRemoveEmptyParamFromQueryParams({
            ...filters,
        }),
    });
    return response.data;
}

// Endpoint to fetch matched payment
export async function fetchMatchedPayments(
    filters: IMatchedPaymentFilterTypes
) {
    const response = await axios.get(`/invoice/matched_payments`, {
        params: handleRemoveEmptyParamFromQueryParams({
            ...filters,
            from_date: filters.from_date
                ? format(filters.from_date, "yyyy-MM-dd")
                : null,
            to_date: filters.to_date
                ? format(filters.to_date, "yyyy-MM-dd")
                : null,
        }),
    });
    return response.data;
}

// Endpoint to fetch unmatched payment
export async function fetchAllUnMatchedPayments(
    filters: IAllUnmatchedPaymentFilterTypes
) {
    const response = await axios.get(`/invoice/unmatched_payments`, {
        params: handleRemoveEmptyParamFromQueryParams({
            ...filters,
            from_date: filters.from_date
                ? format(filters.from_date, "yyyy-MM-dd")
                : null,
            to_date: filters.to_date
                ? format(filters.to_date, "yyyy-MM-dd")
                : null,
        }),
    });
    return response.data;
}

// Endpoint to delete unmatched payment
export async function deleteUnMatchedPayment(payload: IDeleteUnMatchedPayment) {
    const response = await axios.delete(`/invoice/unmatched_payments`, {
        data: payload,
    });
    return response.data;
}

// Endpoint to delete matched payment
export async function unMatchPayment(payload: IUnmatchPayment) {
    const response = await axios.post(`/invoice/unmatch_payments`, {
        ...payload,
    });
    return response.data;
}

// Endpoint to move payment
export async function movePayment(payload: IMovePayment) {
    const response = await axios.patch(`/invoice/update_matched_payments`, {
        ...payload,
    });
    return response.data;
}

// Endpoint to delete refund payment
export async function refundPayment(payload: IRefundPayment) {
    const response = await axios.patch(`/invoice/refund-payment`, {
        ...payload,
    });
    return response.data;
}

export async function chargeClient(clientId: string, payload: IChargeClient) {
    const response = await axios.post(
        `/client/${clientId}/charge-payment-card`,
        payload
    );
    return response.data;
}

export async function fetchBillingAdminDashboardList(
    params: IBillingAdminDashboardFilters
) {
    const response = await axios.get(`/dashboard/billing`, {
        params: handleRemoveEmptyParamFromQueryParams(params),
    });
    return response.data;
}

export async function updateInvoiceCharge(payload: IUpdateInvoiceCharge) {
    const response = await axios.patch(`/invoice/charge`, payload);
    return response.data;
}

export async function updatePaymentWriteoff(payload: IUpdatePaymentWriteoff) {
    const response = await axios.patch(`/payment/${payload.payment_id}`, {
        ...payload,
    });

    return response.data;
}

// Endpoint to download the statement of account pdf
export async function downloadStatementOfAccount(
    pdfType: string,
    exportJobId: string
) {
    await axios
        .get(`/pdf/export/${pdfType}/${exportJobId}`, {
            responseType: "blob",
        })
        .then((res) => {
            const href = window.URL.createObjectURL(res.data);

            const anchorElement = document.createElement("a");
            anchorElement.href = href;

            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        });
}

export async function rematchPayment(payload: IRematchPayment) {
    const response = await axios.patch(
        `/invoice/update_matched_payments`,
        payload
    );
    return response.data;
}

export async function bulkPayInvoices(payload: IBulkPayment) {
    const response = await axios.post(`/client/bulk-pay-invoices`, payload);
    return response.data;
}

export async function splitPayment(payload: ISplitPaymentPayload) {
    const response = await axios.post(`/payment/split`, payload);
    return response.data;
}

// endpoint to get all invoice notes for a specific invoice
export async function getInvoiceNotes(
    invoice_id: string,
    filters: IInvoiceNotesFilters
) {
    const response = await axios.get(`/invoice/${invoice_id}/notes`, {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });

    return response.data;
}

// endpoint to create invoice note
export async function createInvoiceNote(
    invoice_id: string,
    description: string
) {
    const response = await axios.post(
        `/invoice/${invoice_id}/notes
    `,
        { description }
    );

    return response.data;
}

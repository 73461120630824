import * as React from "react";
import { removeEnumUnderscore } from "../../utils/helpers";
import styles from "./Pill.module.scss";

interface PillProps {
    /** Signifies the background color of the pill */
    bgcolor: string;
    /** Indicates the label of the pill */
    label: string;
    /** Indicates the border style of the pill */
    borderStyle?: string;
}

export default function Pill({ bgcolor, label, borderStyle }: PillProps) {
    return (
        <span
            aria-label="pill-status"
            className={styles.pill}
            style={{
                backgroundColor: `${bgcolor}`,
                border: `${borderStyle}`,
            }}
        >
            {removeEnumUnderscore(label)}
        </span>
    );
}

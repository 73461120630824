export default function CheckPrimaryColorIcon() {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_7354_6991)">
                <path
                    opacity="0.4"
                    d="M11.8242 3.38482L6.20508 9.03513L4.5 7.3263L10.1276 1.67786C10.1828 1.62213 10.2485 1.57783 10.3209 1.5475C10.3933 1.51718 10.471 1.50141 10.5495 1.5011C10.628 1.5008 10.7057 1.51596 10.7784 1.54573C10.851 1.5755 10.917 1.61928 10.9727 1.67458L10.976 1.67786L11.8242 2.53122C11.9368 2.64474 12 2.79814 12 2.95802C12 3.1179 11.9368 3.2713 11.8242 3.38482Z"
                    fill="#0B7A75"
                />
                <path
                    d="M6.20325 9.03726L4.92426 10.3233C4.81262 10.4358 4.66086 10.4993 4.50237 10.4999C4.34387 10.5006 4.19162 10.4382 4.07911 10.3266L4.07583 10.3233L0.175827 6.40077C0.0632029 6.28733 0 6.13395 0 5.9741C0 5.81424 0.0632029 5.66086 0.175827 5.54742L1.02426 4.69406C1.13573 4.58183 1.28715 4.51839 1.44532 4.51765C1.60349 4.5169 1.7555 4.57891 1.86801 4.69007L1.87176 4.69406L6.20325 9.03726Z"
                    fill="#0B7A75"
                />
            </g>
            <defs>
                <clipPath id="clip0_7354_6991">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

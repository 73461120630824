import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IApiResponse } from "../../../utils/types/api-response";
import {
    IAddBulkInvoicePayment,
    IAddBulkInvoicePaymentResponse,
} from "../types/billing.types";
import { addBulkInvoicePayment } from "../services/billing.services";

export function useAddBulkInvoicePayment() {
    return useMutation<
        IApiResponse<IAddBulkInvoicePaymentResponse>,
        AxiosError<IApiResponse<IAddBulkInvoicePaymentResponse>>,
        IAddBulkInvoicePayment
    >({
        mutationKey: ["bulk-invoice-payment"],
        mutationFn: (payload: IAddBulkInvoicePayment) =>
            addBulkInvoicePayment(payload),
    });
}

import { SvgProps } from "../../utils/types";

export default function ArrowRightIcon({ stroke }: SvgProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
        >
            <path
                d="M4.33333 10.1654L8.5 5.9987L4.33333 1.83203"
                stroke={stroke || "#0B132B"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

import * as React from "react";
import { Dialog, DialogContent } from "@jhool-io/fe-components";
import { useIsMutating } from "@tanstack/react-query";

import { IPayer } from "../../types/payers.types";
import EditPayerDetails from "../EditPayerDetails/EditPayerDetails";
import PayerDetailsInfo from "../PayerDetailsInfo/PayerDetailsInfo";

interface Props {
    selectedPayer: IPayer;
    isEditView: boolean;
    setIsEditView: React.Dispatch<React.SetStateAction<boolean>>;
    onFormSubmit: () => void;
}

export default function PayerDetails({
    selectedPayer,
    onFormSubmit,
    isEditView,
    setIsEditView,
}: Props) {
    const isEditingPayerMutating = useIsMutating(["update_payer"]);

    return (
        <>
            <Dialog open={isEditView}>
                <DialogContent
                    variant="center"
                    title="Edit Payer Details"
                    onCancelClick={() => setIsEditView(false)}
                    handleCloseDialog={() => setIsEditView(false)}
                    isSubmitBtnDisabled={isEditingPayerMutating > 0}
                    submitBtnFormValue="edit-payer-form"
                    wrapperClass="w-[750px]"
                >
                    <EditPayerDetails
                        selectedPayer={selectedPayer}
                        onFormSubmit={onFormSubmit}
                    />
                </DialogContent>
            </Dialog>

            <PayerDetailsInfo
                onDeleteComplete={onFormSubmit}
                selectedPayer={selectedPayer}
                setIsEditView={setIsEditView}
            />
        </>
    );
}

import * as React from "react";
import { useFetchDiagnosisCodes } from "../queries";
import Skeleton from "../../components/Skeleton/Skeleton";
import { ModifierTypes } from "../../utils/types/notes";

export function useDebounce<T>(value: T, delay = 500): T {
    const [debounceValue, setDebounceValue] = React.useState<T>(value);

    React.useEffect(() => {
        const handler = setTimeout(() => {
            setDebounceValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debounceValue;
}

/**
 * Get a string or any other ui component you want of diagnosis codes with their code and description from an array of diagnosis codes with just their codes
 */
export function useGetDiagnosisCodesDisplay(
    noteContentDiagnosisCodes: string[] | null
): React.ReactNode {
    // Fetch diagnosis codes
    const { isLoading, error, data } = useFetchDiagnosisCodes();

    if (isLoading) {
        return <Skeleton width="30%" height="20px" />;
    }

    if (error) {
        return "Error fetching diagnosis codes, pleasr try again later";
    }

    if (data) {
        if (noteContentDiagnosisCodes && noteContentDiagnosisCodes.length > 0) {
            const codes = data.data.filter((code) => {
                return noteContentDiagnosisCodes.includes(code.code);
            });

            return codes.map(
                (code, i) =>
                    `${code.code} ${code.description}${
                        codes.length === i + 1 ? `` : ` , `
                    }`
            );
        }

        return "--";
    }

    return "--";
}

/**
 * Get a string or any other ui component you want of modifier codes with their code and description from an array of modifier codes with just their codes
 */
export function useGetModifierCodesDisplay(
    noteContentModifierCodes: string[] | null
): React.ReactNode {
    if (noteContentModifierCodes && noteContentModifierCodes.length > 0) {
        const codes = Object.values(ModifierTypes).filter((modifier) =>
            noteContentModifierCodes.includes(modifier.split(" ")[0])
        );

        return codes.map(
            (code, i) => `${code}${codes.length === i + 1 ? `` : ` , `}`
        );
    }

    return "--";
}

/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { DatePicker, Input, RadioInput, Table } from "@jhool-io/fe-components";

import {
    Control,
    Controller,
    FormState,
    UseFormRegister,
    UseFormSetValue,
} from "react-hook-form";
import styles from "../EditClaims.module.scss";
import {
    IClaimDetailsContent,
    ClaimsServiceDetails,
    SingleClaim,
} from "../../../types/claims.types";

import { formatDate } from "../../../../../utils/helpers";

interface ServicesInfoFormProps {
    setValue: UseFormSetValue<IClaimDetailsContent>;
    claimDetails: SingleClaim;
    register: UseFormRegister<IClaimDetailsContent>;
    formState: FormState<IClaimDetailsContent>;
    control: Control<IClaimDetailsContent, unknown>;
}

export default function ServicesInfoForm({
    setValue,
    claimDetails,
    register,
    formState: { errors },
    control,
}: ServicesInfoFormProps) {
    const columns: ColumnDef<ClaimsServiceDetails>[] = [
        {
            accessorKey: "dateColumn",
            header: () => (
                <h3>
                    A. <br /> Date(s) of service:
                </h3>
            ),
            columns: [
                {
                    accessorKey: "fromDate",
                    header: () => (
                        <h3>
                            From <br />
                            <span>MM</span> <span>DD</span> <span>YY</span>{" "}
                        </h3>
                    ),
                    cell: ({ row }) => (
                        <div className="flex items-center place-content-center gap-2">
                            <Input
                                value={
                                    row.original.fromDate
                                        ? formatDate(
                                              row.original.fromDate,
                                              true,
                                              "M"
                                          )
                                        : ""
                                }
                                className="w-32 h-11 px-2 rounded-r6 border-[0.4px] border-strokedark text-xs font-normal text-center"
                                disabled
                            />
                            <Input
                                value={
                                    row.original.fromDate
                                        ? formatDate(
                                              row.original.fromDate,
                                              true,
                                              "d"
                                          )
                                        : ""
                                }
                                className="w-32 h-11 px-2 rounded-r6 border-[0.4px] border-strokedark text-xs font-normal text-center"
                                disabled
                            />
                            <Input
                                value={
                                    row.original.fromDate
                                        ? formatDate(
                                              row.original.fromDate,
                                              true,
                                              "y"
                                          )
                                        : ""
                                }
                                className="w-44 h-11 px-2 rounded-r6 border-[0.4px] border-strokedark text-xs font-normal text-center"
                                disabled
                            />
                        </div>
                    ),
                },
                {
                    accessorKey: "toDate",
                    header: () => (
                        <h3>
                            To <br />
                            <span>MM</span> <span>DD</span> <span>YY</span>{" "}
                        </h3>
                    ),
                    cell: ({ row }) => (
                        <div className="flex items-center place-content-center gap-2">
                            <Input
                                value={
                                    row.original.toDate
                                        ? formatDate(
                                              row.original.fromDate,
                                              true,
                                              "M"
                                          )
                                        : ""
                                }
                                className="w-32 h-11 px-2 rounded-r6 border-[0.4px] border-strokedark text-xs font-normal text-center"
                                disabled
                            />
                            <Input
                                value={
                                    row.original.toDate
                                        ? formatDate(
                                              row.original.fromDate,
                                              true,
                                              "d"
                                          )
                                        : ""
                                }
                                className="w-32 h-11 px-2 rounded-r6 border-[0.4px] border-strokedark text-xs font-normal text-center"
                                disabled
                            />
                            <Input
                                value={
                                    row.original.toDate
                                        ? formatDate(
                                              row.original.fromDate,
                                              true,
                                              "y"
                                          )
                                        : ""
                                }
                                className="w-44 h-11 px-2 rounded-r6 border-[0.4px] border-strokedark text-xs font-normal text-center"
                                disabled
                            />
                        </div>
                    ),
                },
            ],
        },

        {
            accessorKey: "placeOfServiceCode",
            header: () => (
                <div className={styles.cellheader}>
                    <h3>
                        B. <br /> Place of service
                    </h3>
                </div>
            ),
            cell: ({ row }) => (
                <Input
                    value={row.original.placeOfServiceCode}
                    className="h-11 min-w-max px-2 rounded-r6 border-[0.4px] border-strokedark text-xs font-normal text-center"
                    disabled
                />
            ),
        },

        {
            accessorKey: "cptWrapper",
            header: () => (
                <h3>
                    C. <br /> Procedures, Services or Supplies (Explain Unusual
                    Circumstances)
                </h3>
            ),
            columns: [
                {
                    accessorKey: "cpt",
                    header: () => <h3>CPT/HCPS</h3>,
                    cell: ({ row }) => (
                        <Input
                            value={row.original.procedureCode}
                            className="w-[60px] h-11 px-2 rounded-r6 border-[0.4px] border-strokedark text-xs font-normal text-center"
                            disabled
                        />
                    ),
                },

                {
                    accessorKey: "cpt",
                    header: () => <h3>MODIFIER A</h3>,
                    cell: ({ row }) => (
                        <Input
                            value={
                                row.original.modifiers
                                    ? row.original.modifiers[0]
                                    : ""
                            }
                            className="w-[60px] h-11 px-2 rounded-r6 border-[0.4px] border-strokedark text-xs font-normal text-center"
                            disabled
                        />
                    ),
                },

                {
                    accessorKey: "cpt",
                    header: () => <h3>MODIFIER B</h3>,
                    cell: ({ row }) => (
                        <Input
                            value={
                                row.original.modifiers
                                    ? row.original.modifiers[1]
                                    : ""
                            }
                            className="w-[60px] h-11 px-2 rounded-r6 border-[0.4px] border-strokedark text-xs font-normal text-center"
                            disabled
                        />
                    ),
                },

                {
                    accessorKey: "cpt",
                    header: () => <h3>MODIFIER C</h3>,
                    cell: ({ row }) => (
                        <Input
                            value={
                                row.original.modifiers
                                    ? row.original.modifiers[2]
                                    : ""
                            }
                            className="w-[60px] h-11 px-2 rounded-r6 border-[0.4px] border-strokedark text-xs font-normal text-center"
                            disabled
                        />
                    ),
                },

                {
                    accessorKey: "cpt",
                    header: () => <h3>MODIFIER D</h3>,
                    cell: ({ row }) => (
                        <Input
                            value={
                                row.original.modifiers
                                    ? row.original.modifiers[3]
                                    : ""
                            }
                            className="w-[60px] h-11 px-2 rounded-r6 border-[0.4px] border-strokedark text-xs font-normal text-center"
                            disabled
                        />
                    ),
                },
            ],
        },

        {
            accessorKey: "diagnosis_pointer",
            header: () => (
                <div className={styles.cellheader}>
                    <h3>
                        D. <br /> Diagnosis Pointer
                    </h3>
                </div>
            ),
            cell: ({ row }) => (
                <select
                    value={
                        row.original.diagnosisPointers
                            ? row.original.diagnosisPointers[0]
                            : ""
                    }
                    className="min-w-[72px] p-4
                  h-11 rounded-r6 border-[1px] border-strokedark
                   bg-white"
                    disabled
                >
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                    <option value="F">F</option>
                    <option value="G">G</option>
                    <option value="H">H</option>
                    <option value="I">I</option>
                    <option value="J">J</option>
                    <option value="K">K</option>
                    <option value="L">L</option>
                </select>
            ),
        },

        {
            accessorKey: "charges",
            header: () => (
                <div className={styles.cellheader}>
                    <h3>
                        E. <br /> Charges
                    </h3>
                </div>
            ),
            cell: ({ row }) => (
                <Input
                    className="h-11 min-w-[60px] px-2 rounded-r6 border-[0.4px] border-strokedark text-xs font-normal text-center"
                    value={row.original.charge}
                    disabled
                />
            ),
        },

        {
            accessorKey: "units",
            header: () => (
                <div className={styles.cellheader}>
                    <h3>
                        F. <br /> Days or Units
                    </h3>
                </div>
            ),
            cell: ({ row }) => (
                <Input
                    className="h-11 min-w-[60px] px-2 rounded-r6 border-[0.4px] border-strokedark text-xs font-normal text-center"
                    value={row.original.units}
                    disabled
                />
            ),
        },

        {
            accessorKey: "rpi",
            header: () => (
                <div className={styles.cellheader}>
                    <h3>
                        G. <br /> Rendering Provider ID #
                    </h3>
                </div>
            ),
            cell: ({ row }) => (
                <Input
                    className="h-11 min-w-[74px] px-2 rounded-r6 border-[0.4px] border-strokedark text-xs font-normal text-center"
                    value={row.original.renderingProviderNPI || ""}
                    disabled
                />
            ),
        },
    ];

    React.useEffect(() => {
        const claimContent = claimDetails.claim_content;

        setValue("totalCharge", claimContent.totalCharge);
        setValue("amountPaid", claimContent.amountPaid);
        setValue("federalTaxIdType", claimContent.federalTaxIdType);
        setValue("federalTaxId", claimContent.federalTaxId);
        setValue(
            "claimReferenceOrPatientAcctNo",
            claimContent.claimReferenceOrPatientAcctNo
        );
        setValue("acceptAssignment", claimContent.acceptAssignment);
        setValue(
            "renderingProviderSignatureDate",
            formatDate(
                claimContent.renderingProviderSignatureDate,
                true,
                "yyyy-MM-dd"
            )
        );
        setValue("facilityName", claimContent.facilityName);
        setValue("facilityAddress1", claimContent.facilityAddress1);
        setValue("facilityCity", claimContent.facilityCity);
        setValue("facilityState", claimContent.facilityState);
        setValue("facilityZip", claimContent.facilityZip);
        setValue("facilityNPI", claimContent.facilityNPI);
        setValue("billingProviderName", claimContent.billingProviderName);
        setValue(
            "billingProviderAddress1",
            claimContent.billingProviderAddress1
        );
        setValue("billingProviderCity", claimContent.billingProviderCity);
        setValue("billingProviderState", claimContent.billingProviderState);
        setValue("billingProviderPhone", claimContent.billingProviderPhone);
        setValue("billingProviderZipCode", claimContent.billingProviderZipCode);
        setValue("billingProviderNPI", claimContent.billingProviderNPI);
    }, [claimDetails.claim_content, setValue]);

    return (
        <div className="py-48 md:px-24">
            <h4 className="text-sm font-semibold mb-24 uppercase md:sticky top-20 z-[4] text-primary">
                Services
            </h4>

            <div className={styles.list}>
                <Table
                    columns={columns}
                    data={claimDetails.claim_content.serviceDetails}
                    className="list"
                />
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24 mt-32">
                <div className="flex-1">
                    <h5
                        className="text-xs font-semibold
                    leading-tight mb-2
                    "
                    >
                        Total Charge
                    </h5>
                </div>
                <div className="flex-[3] flex flex-wrap gap-12">
                    <div className="min-w-[260px]">
                        <h6 className="text-gray text-xs font-medium mb-4">
                            $ USD
                        </h6>

                        <Input
                            placeholder="00"
                            type="number"
                            {...register("totalCharge")}
                            hasError={!!errors.totalCharge}
                            errorText={errors.totalCharge?.message}
                            showCurrency
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24 mt-32">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Amount Paid
                    </h5>
                </div>
                <div className="flex-[3] flex flex-wrap gap-12">
                    <div className="min-w-[260px]">
                        <h6 className="text-gray text-xs font-medium mb-4">
                            $ USD
                        </h6>
                        <Input
                            placeholder="00"
                            type="number"
                            {...register("amountPaid")}
                            hasError={!!errors.amountPaid}
                            errorText={errors.amountPaid?.message}
                            showCurrency
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Federal Tax I.D Type
                    </h5>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <Controller
                        name="federalTaxIdType"
                        control={control}
                        render={({ field }) => (
                            <RadioInput
                                label="SSN"
                                wrapperClass="min-w-[120px]"
                                checked={field.value === "S"}
                                onChange={() =>
                                    setValue("federalTaxIdType", "S")
                                }
                            />
                        )}
                    />

                    <Controller
                        name="federalTaxIdType"
                        control={control}
                        render={({ field }) => (
                            <RadioInput
                                label="EIN"
                                wrapperClass="min-w-[120px]"
                                checked={field.value === "E"}
                                onChange={() =>
                                    setValue("federalTaxIdType", "E")
                                }
                            />
                        )}
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24 mt-32">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Federal Tax ID
                    </h5>
                </div>
                <div className="flex-[3] flex flex-wrap gap-12">
                    <Input
                        placeholder="1086275B"
                        className="max-w-[40%]"
                        {...register("federalTaxId")}
                        hasError={!!errors.federalTaxId}
                        errorText={errors.federalTaxId?.message}
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24 mt-32">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Patient&apos;s Account Number
                    </h5>
                </div>
                <div className="flex-[3] flex flex-wrap gap-12">
                    <Input
                        placeholder="1086275B"
                        className="max-w-[40%]"
                        {...register("claimReferenceOrPatientAcctNo")}
                        hasError={!!errors.claimReferenceOrPatientAcctNo}
                        errorText={
                            errors.claimReferenceOrPatientAcctNo?.message
                        }
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Accept Assignment
                    </h5>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <Controller
                        name="acceptAssignment"
                        control={control}
                        render={({ field }) => (
                            <RadioInput
                                label="Yes"
                                wrapperClass="min-w-[120px]"
                                checked={field.value === "Y"}
                                onChange={() =>
                                    setValue("acceptAssignment", "Y")
                                }
                            />
                        )}
                    />

                    <Controller
                        name="acceptAssignment"
                        control={control}
                        render={({ field }) => (
                            <RadioInput
                                label="No"
                                wrapperClass="min-w-[120px]"
                                checked={field.value === "N"}
                                onChange={() =>
                                    setValue("acceptAssignment", "N")
                                }
                            />
                        )}
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Signature Of Physician and Supplier
                    </h5>
                    <p className="text-gray mb-[0.6rem] text-xss font-semibold">
                        I certify that the elements on the reverse apply to this
                        bill and are made a part thereof.
                    </p>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <div className="min-w-[260px]">
                        <h6 className="text-gray text-xs font-medium mb-4">
                            Signed
                        </h6>
                        <Input
                            placeholder="Signature"
                            {...register("referringProviderFirstName")}
                            hasError={!!errors.referringProviderFirstName}
                            errorText={
                                errors.referringProviderFirstName?.message
                            }
                        />
                    </div>

                    <div>
                        <h6 className="text-gray text-xs font-medium mb-4">
                            Date
                        </h6>
                        <div className="flex items-center">
                            <Controller
                                control={control}
                                name="renderingProviderSignatureDate"
                                render={({ field }) => {
                                    return (
                                        <DatePicker
                                            selected={new Date(field.value)}
                                            value={new Date(
                                                field.value
                                            ).toDateString()}
                                            onChange={(date) => {
                                                if (date) {
                                                    setValue(
                                                        "renderingProviderSignatureDate",
                                                        formatDate(
                                                            new Date(
                                                                date
                                                            ).toDateString(),
                                                            true,
                                                            "yyyy-MM-dd"
                                                        )
                                                    );
                                                }
                                            }}
                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Service Facility Name
                    </h5>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <Input
                        placeholder="Service Facility Name"
                        className="max-w-[40%]"
                        {...register("facilityName")}
                        hasError={!!errors.facilityName}
                        errorText={errors.facilityName?.message}
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Service Facility Info & PH#
                    </h5>
                </div>

                <div className="flex-[3]">
                    <div className="flex flex-wrap gap-12 md:max-w-[80%]">
                        <Input
                            placeholder="Brooklyn, Upper East Side, Financial District"
                            className="w-full"
                            {...register("facilityAddress1")}
                            hasError={!!errors.facilityAddress1}
                            errorText={errors.facilityAddress1?.message}
                        />
                        <div className="flex flex-col md:flex-row items-center gap-12 w-full">
                            <Input
                                placeholder="Brooklyn"
                                {...register("facilityCity")}
                                hasError={!!errors.facilityCity}
                                errorText={errors.facilityCity?.message}
                            />
                            <Input
                                placeholder="NY"
                                {...register("facilityState")}
                                hasError={!!errors.facilityState}
                                errorText={errors.facilityState?.message}
                            />
                            <Input
                                placeholder="11208"
                                {...register("facilityZip")}
                                hasError={!!errors.facilityZip}
                                errorText={errors.facilityZip?.message}
                            />
                        </div>

                        <div className=" w-full">
                            <h6 className="text-gray text-xs font-medium mb-4">
                                Facility NPI
                            </h6>
                            <Input
                                placeholder="1086275B"
                                className="max-w-[260px]"
                                {...register("facilityNPI")}
                                hasError={!!errors.facilityNPI}
                                errorText={errors.facilityNPI?.message}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Billing Provider Name
                    </h5>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <Input
                        placeholder="Billing Provider Name"
                        className="max-w-[40%]"
                        {...register("billingProviderName")}
                        hasError={!!errors.billingProviderName}
                        errorText={errors.billingProviderName?.message}
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Billing Provider Info & PH#
                    </h5>
                </div>

                <div className="flex-[3]">
                    <div className="flex flex-wrap gap-12 md:max-w-[80%]">
                        <Input
                            placeholder="Brooklyn, Upper East Side, Financial District"
                            className="w-full"
                            {...register("billingProviderAddress1")}
                            hasError={!!errors.billingProviderAddress1}
                            errorText={errors.billingProviderAddress1?.message}
                        />
                        <div className="flex flex-col md:flex-row items-center gap-12 w-full">
                            <Input
                                placeholder="Brooklyn"
                                {...register("billingProviderCity")}
                                hasError={!!errors.billingProviderCity}
                                errorText={errors.billingProviderCity?.message}
                            />
                            <Input
                                placeholder="NY"
                                {...register("billingProviderState")}
                                hasError={!!errors.billingProviderState}
                                errorText={errors.billingProviderState?.message}
                            />
                            <Input
                                placeholder="(01) 159 969 739"
                                {...register("billingProviderPhone")}
                                hasError={!!errors.billingProviderPhone}
                                errorText={errors.billingProviderPhone?.message}
                            />
                            <Input
                                placeholder="11208"
                                {...register("billingProviderZipCode")}
                                hasError={!!errors.billingProviderZipCode}
                                errorText={
                                    errors.billingProviderZipCode?.message
                                }
                            />
                        </div>

                        <div className=" w-full">
                            <h6 className="text-gray text-xs font-medium mb-4">
                                NPI
                            </h6>
                            <Input
                                placeholder="1086275B"
                                className="max-w-[260px]"
                                {...register("billingProviderNPI")}
                                hasError={!!errors.billingProviderNPI}
                                errorText={errors.billingProviderNPI?.message}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

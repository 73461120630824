import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { parseISO } from "date-fns";
import {
    Input,
    Button,
    Alert,
    Popover,
    PopoverTrigger,
    PopoverContent,
    SearchInput,
    DatePicker,
} from "@jhool-io/fe-components";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "../../../../hooks/helpers";
import { useFetchTasks } from "../../../../hooks/queries/tasks";
import { useFetchUsersList } from "../../../../hooks/queries/user-management";
import {
    cn,
    formatZonedTimeToUtc,
    removeEnumUnderscore,
    truncateString,
} from "../../../../utils/helpers";
import { IUpdateTask, TaskStatus } from "../../../../utils/types/tasks";
import { useUpdateTask } from "../../../../hooks/mutations/tasks";
import useToast from "../../../../hooks/useToast";
import { AddTextEditor } from "../../../../components/TextEditor/AddTextEditor/AddTextEditor";
import { APP_COLORS } from "../../../../utils/constants";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import styles from "./ReassignTask.module.scss";
import { useFetchUserDetails } from "../../../../hooks/queries/user";

type ComboOption = {
    value: string;
    label: string;
};

interface ReassignTaskProps {
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
    /**
     * Current action
     */
    action?: "reassign" | "forward";
}

const schema = yup.object({
    due_date: yup.date(),
    description: yup.string(),
});

export default function ReassignTask({
    onFormSubmit,
    action = "reassign",
}: ReassignTaskProps) {
    const [internalUserSearchString, setInternalUserSearchString] =
        React.useState("");
    const [selectedInternalUser, setSelectedInternalUser] =
        React.useState<ComboOption | null>(null);
    const [shoudlAllowLessThanTwoHours, setShouldAllowLessThanTwoHours] =
        React.useState<null | "asked" | "allow">(null);
    const [taskDescription, setTaskDescription] = React.useState("");
    const [internalUserError, setInternalUserError] = React.useState<
        string | null
    >(null);
    const [showInternalUsersPopover, setShowInternalUsersPopover] =
        React.useState(false);

    const loggedInUser = useFetchUserDetails();

    const [searchParams] = useSearchParams();

    // React hook form values
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IUpdateTask>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const { data } = useFetchTasks({
        status: [
            TaskStatus.COMPLETED,
            TaskStatus.IN_PROGRESS,
            TaskStatus.NOT_STARTED,
        ],
        todo_id: searchParams.get("task_id") || "",
    });

    // Local component states
    const [localDueDate, setLocalDueDate] = React.useState<Date | null>(
        data?.data[0].due_date ? parseISO(data?.data[0].due_date) : null
    );

    const debouncedInternalUserSearchValue = useDebounce(
        internalUserSearchString,
        500
    );

    // Fetch providers
    const internalUsersList = useFetchUsersList({
        page: 1,
        limit: 20,
        search_string: debouncedInternalUserSearchValue,
    });

    const queryClient = useQueryClient();

    const { toast } = useToast();

    const update = useUpdateTask();

    const onSubmit = (values: IUpdateTask) => {
        if (!selectedInternalUser) {
            setInternalUserError("Field is required");
            return;
        }
        const payload = {
            todos_to_update: [
                {
                    todo_id: data?.data[0].todo_id as string,
                    status: data?.data[0].status as TaskStatus,
                    title: data?.data[0].title as string,
                    description: taskDescription || undefined,
                    due_date: values.due_date
                        ? formatZonedTimeToUtc(values.due_date)
                        : undefined,
                    assignee_user_id: selectedInternalUser?.value as string,
                },
            ],
        };

        update.mutate(payload, {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: ["todos"],
                });

                toast({
                    mode: "success",
                    message: "Task updated successfully!",
                });
                onFormSubmit();
            },

            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not update the task",
                });
            },
        });
    };

    const popoverLabel = action === "forward" ? "Forward to" : "Re-assign to";

    return (
        <form id="reassign-task" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg">
                <Input
                    label="Currently assigned to"
                    placeholder="Currently assigned to"
                    defaultValue={
                        data?.data[0].assignee?.user_id
                            ? `${data?.data[0].assignee?.first_name} ${
                                  data?.data[0].assignee?.last_name
                              } ${
                                  loggedInUser.data?.user_id ===
                                  data?.data[0].assignee?.user_id
                                      ? "(You)"
                                      : ""
                              }`
                            : ""
                    }
                    disabled
                />
            </div>

            <p className="text-xs font-medium">
                {action === "reassign" &&
                    "Select who you want to re-assign this task to"}
                {action === "forward" &&
                    "Select who you want to forward this task to"}
            </p>

            <Popover
                open={showInternalUsersPopover}
                onOpenChange={setShowInternalUsersPopover}
            >
                <PopoverTrigger
                    asChild
                    onClick={() => setShowInternalUsersPopover(true)}
                    className="w-full"
                >
                    <Button
                        size="auto"
                        variant="normal"
                        className={cn(
                            "px-16 h-40 shadow-active gap-x-8 text-xs data-[state=open]:border-primary font-medium flex justify-between",
                            {
                                capitalize: selectedInternalUser,
                            }
                        )}
                    >
                        {selectedInternalUser
                            ? truncateString(selectedInternalUser.label, 18)
                            : popoverLabel}
                        <ChevronDownIcon stroke={APP_COLORS.COLOR_BLACK} />
                    </Button>
                </PopoverTrigger>

                <PopoverContent
                    className="px-4 pt-[3px] py-[3px] w-[316px] rounded-r8 border-none shadow-[0px_0px_0px_1px_#98a1b219,_0px_15px_35px_-5px_#11182626,_0px_5px_15px_0px_#00000014] z-[60]"
                    align="start"
                >
                    <SearchInput
                        placeholder="Search"
                        containerClass="h-24"
                        onChange={(e) =>
                            setInternalUserSearchString(e.target.value)
                        }
                    />

                    <div className="flex flex-col items-start max-h-[230px] overflow-y-auto">
                        {internalUsersList.isLoading && (
                            <div className="flex flex-col gap-y-4 items-stretch w-full">
                                {[1, 2, 3, 4, 5].map((item) => (
                                    <Skeleton
                                        key={item}
                                        width="100%"
                                        height="24px"
                                        containerTestId="client-loader"
                                    />
                                ))}
                            </div>
                        )}

                        {internalUsersList.error && (
                            <ListState
                                isError
                                context="general"
                                stateHelperText="Please try again later"
                                errorMsg={
                                    internalUsersList.error?.response?.data
                                        ?.message || "Error loading users"
                                }
                            />
                        )}

                        {internalUsersList.data?.data &&
                            internalUsersList.isSuccess && (
                                <>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start !rounded-none"
                                        onClick={() => {
                                            setInternalUserSearchString("");
                                            setSelectedInternalUser(null);
                                            setShowInternalUsersPopover(false);
                                        }}
                                    >
                                        None
                                    </Button>
                                    {internalUsersList?.data?.data
                                        .filter(
                                            (user) =>
                                                user.user_id !==
                                                data?.data[0].assignee?.user_id
                                        )
                                        .map((user) => (
                                            <Button
                                                key={user.user_id}
                                                size="auto"
                                                variant="normal"
                                                className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start !rounded-none"
                                                onClick={() => {
                                                    setSelectedInternalUser({
                                                        label: removeEnumUnderscore(
                                                            `${user.first_name} ${user.last_name}`
                                                        ),
                                                        value: user.user_id,
                                                    });
                                                    setShowInternalUsersPopover(
                                                        false
                                                    );
                                                }}
                                            >
                                                {`${user.first_name} ${user.last_name}`}
                                            </Button>
                                        ))}
                                </>
                            )}
                    </div>
                </PopoverContent>
            </Popover>

            {internalUserError ? (
                <p className="text-danger text-xs mt-[0.8rem]">
                    {internalUserError}
                </p>
            ) : null}

            {action === "forward" ? (
                <>
                    <div className="my-32">
                        <Controller
                            name="due_date"
                            control={control}
                            render={({ field }) => (
                                <div className="fg">
                                    <DatePicker
                                        label="Due date"
                                        selected={localDueDate}
                                        minDate={new Date(Date.now())}
                                        hasError={!!errors?.due_date}
                                        onChange={(date) => {
                                            field.onChange(date);
                                            setLocalDueDate(date);
                                        }}
                                        errorText={
                                            errors?.due_date?.type ===
                                            "typeError"
                                                ? "Invalid date value"
                                                : errors?.due_date?.message
                                        }
                                        shouldCloseOnSelect={false}
                                        popperPlacement="auto"
                                    />
                                </div>
                            )}
                        />
                    </div>

                    <AddTextEditor
                        title="Add description"
                        onEditorTextChange={setTaskDescription}
                        isRequiredFieldProvided
                        defaultValue={data?.data[0].description || ""}
                        titleClass={styles.descriptionLabel}
                        editorClass={styles.descriptionWrapper}
                    />
                </>
            ) : null}

            {shoudlAllowLessThanTwoHours === "asked" ? (
                <div className="flex flex-col mt-24 gap-12 md:flex-row items-center">
                    <Alert
                        type="warning"
                        description="This task is due in less than two hours, and you won't get a reminder. Are you sure you want to continue?"
                    />

                    <Button
                        variant="primary"
                        size="small"
                        onClick={() => setShouldAllowLessThanTwoHours("allow")}
                        type="button"
                    >
                        Continue
                    </Button>
                </div>
            ) : null}
        </form>
    );
}

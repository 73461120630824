/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import cn from "classnames";
import { ColumnDef, Row } from "@tanstack/react-table";
import { format } from "date-fns";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import {
    Alert,
    Checkbox,
    DatePicker,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SearchInput,
    Table,
    Button,
} from "@jhool-io/fe-components";
import {
    convertDateFilterStringToDate,
    formatDate,
    getTotalAmountOfTableRowsSelected,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    truncateString,
} from "../../../../utils/helpers";
import {
    INote,
    ISuperviseeNote,
    NoteLabels,
    NoteStatus,
    NoteTypes,
} from "../../../../utils/types/notes";
import { useFetchSuperviseeNotesList } from "../../../../hooks/queries/note";
import { useDebounce } from "../../../../hooks/helpers";
import Skeleton from "../../../Skeleton/Skeleton";
import ListState from "../../../ListState/ListState";
import styles from "../NoteListWrapper/NoteListWrapper.module.scss";
import {
    APP_COLORS,
    CPT_CODES,
    LISTS_DEFAULT_LIMIT,
} from "../../../../utils/constants";
import ApproveNote from "../../ApproveNote/ApproveNote";
import { UserPermisions } from "../../../../utils/types/user";
import ArchiveNote from "../../NoteContent/ArchiveNote/ArchiveNote";
import {
    useChangeNoteArchiveStatus,
    useDeleteDraftNote,
} from "../../../../hooks/mutations/note";
import useToast from "../../../../hooks/useToast";
import { getNoteStatusTag } from "../../../../modules/notes/helpers/notes.helpers";
import MoreButton from "../../../../shared-ui/Buttons/MoreButton/MoreButton";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import CheckPrimaryColorIcon from "../../../Icons/CheckPrimaryColor";
import TableFiltersDisplay from "../../../../shared-ui/TableFiltersDisplay/TableFiltersDisplay";
import MobileListItem from "../../../../shared-ui/MobileListItem/MobileListItem";
import { useFetchProviders } from "../../../../hooks/queries/provider";
import useShowInsuranceOverSelfPay from "../../../../modules/practice-settings/hooks/useShowInsuranceOverSelfPay";
import { useAppSelector } from "../../../../hooks/useRedux";

type Dialogs = "approve" | "delete-confirmation" | "archive";

export default function SuperviseeNoteList({
    setNotesCount,
}: {
    setNotesCount?: (count: string) => void;
}) {
    const [searchParams, setSearchParams] = useSearchParams();

    const [showAuthorPopover, setShowAuthorPopover] = React.useState(false);
    const [authorSearchString, setAuthorSearchString] = React.useState("");

    // Get filters from url
    const providerNameFilter = searchParams.get("provider_name") || "";
    const authorFilter = searchParams.get("author") || "";
    const cptCodeFilter = searchParams.get("cpt_code") || "";
    const statusFilter = searchParams.getAll("status[]");
    const noteTypeFilter = searchParams.getAll("type[]");
    const fromDateFilter = searchParams.get("from_date");
    const toDateFilter = searchParams.get("to_date");
    const showArchivedFilter = searchParams.get("show_archived") || "false";
    const pageFilter = Number(searchParams.get("page")) || 1;
    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;

    const debouncedProviderNameFilter = useDebounce(providerNameFilter);
    const debouncedAuthorSearchString = useDebounce(authorSearchString);

    const [rowSelection, setRowSelection] = React.useState({});
    const [selectedNoteId, setSelectedNoteId] = React.useState<string | null>(
        null
    );
    const [selectedNotes, setSelectedNotes] = React.useState<string[]>([]);
    const [dialogInView, setDialogInView] = React.useState<Dialogs | null>(
        null
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [getSelectedData, setGetSelectedData] = React.useState<any>();

    const fromDateFilterToDate = convertDateFilterStringToDate(fromDateFilter);
    const toDateFilterToDate = convertDateFilterStringToDate(toDateFilter);

    const { toast } = useToast();

    const showInsOverSelfPay = useShowInsuranceOverSelfPay();

    const handleDialogInView = (modal: Dialogs | null) => {
        setDialogInView(() => modal);
    };

    const navigate = useNavigate();

    const { practice } = useAppSelector((state) => state.userPractice);

    const handleArchivedFilter = () => {
        if (showArchivedFilter === "all") {
            return "";
        }
        return showArchivedFilter;
    };

    const { data, isLoading, error, isSuccess } = useFetchSuperviseeNotesList(
        practice?.provider_id || "",
        {
            status: statusFilter,
            cpt_code: cptCodeFilter,
            type: noteTypeFilter,
            from_date: fromDateFilter,
            to_date: toDateFilter,
            provider_name: debouncedProviderNameFilter,
            page: pageFilter,
            limit: limitFilter,
            show_archived: handleArchivedFilter(),
            author_id: authorFilter,
        },
        Boolean(
            !(
                statusFilter &&
                statusFilter.length === 1 &&
                /,+/.test(statusFilter[0])
            )
        )
    );

    const providers = useFetchProviders({
        search_string: debouncedAuthorSearchString,
        page: 1,
        limit: 20,
    });

    const getAuthorTextToDisplay = providers.data?.data.find(
        (author) => author.user_id === authorFilter
    )?.provider_name;

    const handleSetProviderNameFilter = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.value === "") searchParams.delete("provider_name");
        else searchParams.set("provider_name", e.target.value);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleSetAuthorFilter = (author: string) => {
        if (author === "") searchParams.delete("author");
        else searchParams.set("author", author);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleSetTypeFilter = (type: string) => {
        if (noteTypeFilter.includes(type)) {
            searchParams.delete("type[]", type);
        } else {
            searchParams.append("type[]", type);
        }
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleSetStatusFilter = (status: string) => {
        if (statusFilter.includes(status)) {
            searchParams.delete("status[]", status);
        } else {
            searchParams.append("status[]", status);
        }
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleSetCptCodeFilter = (cptCode: string) => {
        if (cptCode === "") searchParams.delete("cpt_code");
        else searchParams.set("cpt_code", cptCode);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleSetArchivedNotesFilter = (archived: string) => {
        searchParams.set("show_archived", archived);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleSetPageFilter = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const handleSetLimitFilter = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    // Clicked note navigation for web
    const handleRowClick = (row: Row<ISuperviseeNote>) => {
        if (row.original.status === NoteStatus.DRAFT) {
            navigate(
                `/notes/${row.original.note_id}/${row.original.client.client_id}/edit`
            );
        } else {
            navigate(
                `/notes/${row.original.client.client_id}/${row.original.note_id}?viewFrom=session_note`
            );
        }
    };

    // Clicked note navigation for mobile
    const handleMobileNoteClick = (note: INote) => {
        if (note.status === NoteStatus.DRAFT) {
            navigate(`/notes/${note.note_id}/${note.client.client_id}/edit`);
        } else {
            navigate(
                `/notes/${note.client.client_id}/${note.note_id}?viewFrom=session_note`
            );
        }
    };

    const mobileListRef = React.useRef<HTMLDivElement>(null);

    const selectedIds = getSelectedData?.map(
        (item: Row<ISuperviseeNote>) => item.original.note_id
    );

    // Array of details of the selected notes(web)
    const notesDetails =
        selectedIds &&
        selectedIds.map((noteId: string) =>
            data?.data.find((note) => note.note_id === noteId)
        );

    // Array of details of the selected notes(mobile)
    const selectedNotesDetails =
        selectedNotes &&
        selectedNotes.map((noteId: string) =>
            data?.data.find((note) => note.note_id === noteId)
        );

    const detailsArray =
        selectedNotes.length > 0 ? selectedNotesDetails : notesDetails;

    const showButtons =
        getTotalAmountOfTableRowsSelected(rowSelection).amount > 0 ||
        selectedNotes.length > 0;

    // Toggles note selection for mobile
    const toggleNoteSelection = (noteId: string) => {
        if (selectedNotes.includes(noteId)) {
            setSelectedNotes(selectedNotes.filter((id) => id !== noteId));
        } else {
            setSelectedNotes([...selectedNotes, noteId]);
        }
    };

    // Handle approve action clicked
    const handleApproveActionClicked = (note: ISuperviseeNote) => {
        if (note.note_type === NoteTypes.SAFETY_PLAN) {
            toast({
                mode: "warning",
                message:
                    "Before giving your approval, please thoroughly review this safety plan note by opening it and going through its contents.",
            });
        } else {
            handleDialogInView("approve");
            setSelectedNoteId(note.note_id);
        }
    };

    const canArchiveNote = () => {
        if (
            practice?.permissions?.includes(
                UserPermisions.CLIENT_NOTES_ALL_READ
            ) ||
            practice?.permissions?.includes(
                UserPermisions.CLIENT_INFO_NOTES_WRITE
            )
        ) {
            return true;
        }

        return false;
    };

    const unarchiveNote = useChangeNoteArchiveStatus();

    const deleteNote = useDeleteDraftNote();

    const queryClient = useQueryClient();

    const screenIsGreaterThan500 =
        window.matchMedia("(min-width: 500px)").matches;

    const handleUnarchive = () => {
        const dataToSend = {
            note_ids_to_archive: selectedIds,
            archive_status: false,
        };

        unarchiveNote.mutate(dataToSend, {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: [`notes`],
                });

                await queryClient.invalidateQueries({
                    queryKey: ["supervisee"],
                });

                toast({
                    mode: "success",
                    message: res.message || "Note unarchived successfully",
                });

                navigate(`/notes`);

                setRowSelection({});
                setSelectedNotes([]);
            },

            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not unarchive note at this time",
                });
            },
        });
    };

    // Function to call for deleting note
    const handleDeleteNote = () => {
        const noteIds = selectedNotes.length > 0 ? selectedNotes : selectedIds;

        deleteNote.mutate(
            noteIds.length === 0 ? [selectedNoteId as string] : noteIds,
            {
                async onSuccess(res) {
                    await queryClient.invalidateQueries({
                        queryKey: [`notes`],
                    });
                    await queryClient.invalidateQueries({
                        queryKey: ["supervisee"],
                    });
                    toast({
                        mode: "success",
                        message:
                            res.message || "Draft note(s) deleted successfully",
                    });
                    setSelectedNoteId(null);

                    setRowSelection({});
                    setSelectedNotes([]);
                    handleDialogInView(null);
                },
                onError(err) {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Cannot delete note(s) at this moment, please try again",
                    });
                },
            }
        );
    };

    // Check if note mutation actions are in progress
    const archiveNoteIsMutating = useIsMutating(["archive-note"]);
    const approveNoteIsMutating = useIsMutating(["approve-note"]);

    // Check if selected notes(mobile) contain notes with note type of safety_plan
    const notesToApproveHaveSafetyPlanMobile = data?.data.filter(
        (note) =>
            selectedNotes?.includes(note.note_id) &&
            note.note_type === NoteTypes.SAFETY_PLAN
    );

    // Check if selected notes(web) contain notes with note type of safety_plan
    const notesToApproveHaveSafetyPlanWeb = data?.data.filter(
        (note) =>
            selectedIds?.includes(note.note_id) &&
            note.note_type === NoteTypes.SAFETY_PLAN
    );

    // Notes ids to be apporoved(web)
    const noteIdsToApproveForWeb = () => {
        const arrayOfIdsOfSelectedSafetyPlanNotes =
            notesToApproveHaveSafetyPlanWeb?.map((note) => note.note_id);

        return selectedIds?.filter(
            (id: string) => !arrayOfIdsOfSelectedSafetyPlanNotes?.includes(id)
        );
    };

    // Notes ids to be apporoved(mobile)
    const noteIdsToApproveForMobile = () => {
        const arrayOfIdsOfSelectedSafetyPlanNotes =
            notesToApproveHaveSafetyPlanMobile?.map((note) => note.note_id);

        return selectedNotes?.filter(
            (id: string) => !arrayOfIdsOfSelectedSafetyPlanNotes?.includes(id)
        );
    };

    // Check to determine whether to show warning alert in the approve note modal
    const doesSelectedNotesHaveSafetyPlan =
        (notesToApproveHaveSafetyPlanMobile &&
            notesToApproveHaveSafetyPlanMobile.length > 0) ||
        (notesToApproveHaveSafetyPlanWeb &&
            notesToApproveHaveSafetyPlanWeb.length > 0);

    const handleApproveActionClick = () => {
        if (
            (noteIdsToApproveForWeb()?.length === 0 &&
                notesToApproveHaveSafetyPlanWeb &&
                notesToApproveHaveSafetyPlanWeb?.length > 0) ||
            (noteIdsToApproveForMobile()?.length === 0 &&
                notesToApproveHaveSafetyPlanMobile &&
                notesToApproveHaveSafetyPlanMobile?.length > 0)
        ) {
            toast({
                mode: "warning",
                message:
                    "You've only selected safety plan note(s), please thoroughly review each by opening and going through its contents before giving your approval.",
            });
        } else {
            handleDialogInView("approve");
        }
    };

    const getArchivedNoteFilterToDisplay = () => {
        if (showArchivedFilter === "true") {
            return "Archived notes";
        }
        return "Unarchived notes";
    };

    const columns: ColumnDef<ISuperviseeNote>[] = [
        {
            id: "select",
            header: ({ table }) => (
                <Checkbox
                    handleChange={table.getToggleAllRowsSelectedHandler()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    name="row"
                    value="row"
                    isChecked={table.getIsAllRowsSelected()}
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    handleChange={row.getToggleSelectedHandler()}
                    indeterminate={row.getIsSomeSelected()}
                    name="row"
                    value="row"
                    isChecked={row.getIsSelected()}
                />
            ),
        },
        {
            accessorKey: "date_of_service",
            header: "DATE OF SERVICE",
            cell: ({ row }) =>
                row.original.date_of_service
                    ? formatDate(row.original.date_of_service)
                    : "--",
        },
        {
            accessorKey: "note_type",
            header: "NOTE TYPE",
            cell: ({ row }) => NoteLabels[row.original.note_type],
        },
        {
            accessorKey: "provider",
            header: "SUPERVISEE NAME",
            cell: ({ row }) => (
                <span className="capitalize">
                    {`${row.original.provider?.first_name} ${row.original.provider?.last_name}`}
                </span>
            ),
        },
        {
            accessorKey: "client",
            header: "Client",
            cell: ({ row }) => (
                <span className="capitalize">
                    {`${row.original.client?.first_name} ${row.original.client?.last_name}`}
                </span>
            ),
        },
        {
            accessorKey: "cpt_code",
            header: "CPT CODE",
            cell: ({ row }) => row.original.cpt_code || "--",
        },
        {
            accessorKey: "author",
            header: "CREATED BY",
            cell: ({ row }) => (
                <span className="capitalize">
                    {`${row.original.author.first_name} ${row.original.author.last_name}`}
                </span>
            ),
        },
        {
            accessorKey: "created_at",
            header: "CREATED ON",
            cell: ({ row }) => formatDate(row.original.created_at),
        },
        {
            accessorKey: "status",
            header: "STATUS",
            cell: ({ row }) =>
                getNoteStatusTag(row.original.status as NoteStatus),
        },
        {
            accessorKey: "client",
            header: "INSURANCE PROVIDER",
            cell: ({ row }) => (
                <span
                    className={
                        row.original.client.self_pay && !showInsOverSelfPay
                            ? "normal-case"
                            : "uppercase"
                    }
                >
                    {row.original.client.self_pay && !showInsOverSelfPay
                        ? "Self pay"
                        : (row.original.client.insurance_provider_name &&
                              removeEnumUnderscore(
                                  row.original.client.insurance_provider_name ||
                                      ""
                              )) ||
                          "--"}
                </span>
            ),
        },
        {
            accessorKey: "note_id",
            header: "",
            cell: ({ row }) =>
                (row.original.status === NoteStatus.PENDING_SUPERVISOR_REVIEW ||
                    row.original.status === NoteStatus.DRAFT) &&
                !row.original.is_archived && (
                    <Dropdown>
                        <DropdownTrigger
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            asChild
                        >
                            <MoreButton />
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            {row.original.status ===
                                NoteStatus.PENDING_SUPERVISOR_REVIEW &&
                                !row.original.is_archived && (
                                    <DropdownItem
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleApproveActionClicked(
                                                row.original
                                            );
                                        }}
                                    >
                                        Approve and sign note
                                    </DropdownItem>
                                )}
                            {row.original.status === NoteStatus.DRAFT && (
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDialogInView(
                                            "delete-confirmation"
                                        );
                                        setSelectedNoteId(row.original.note_id);
                                    }}
                                >
                                    Delete draft
                                </DropdownItem>
                            )}
                        </DropdownContent>
                    </Dropdown>
                ),
        },
    ];

    React.useEffect(() => {
        if (setNotesCount) {
            if (error) setNotesCount("");
            else if (isLoading) setNotesCount("");
            else if (data && data?.total_count > 0) {
                setNotesCount(String(data.total_count));
            } else if (data && data.data.length === 0) {
                setNotesCount("");
            }
        }
    }, [data, error, isLoading, setNotesCount]);

    React.useEffect(() => {
        if (
            statusFilter &&
            statusFilter.length === 1 &&
            /,+/.test(statusFilter[0])
        ) {
            searchParams.delete("status[]");
            statusFilter[0].split(",").forEach((status) => {
                searchParams.append("status[]", status);
            });

            setSearchParams(searchParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Dialog open={dialogInView === "approve"}>
                <DialogContent
                    title="Approve and sign note"
                    variant="center"
                    handleCloseDialog={() => handleDialogInView(null)}
                    saveText="Approve and sign note"
                    showFooter
                    submitBtnFormValue="note-approval"
                    isSubmitBtnDisabled={approveNoteIsMutating > 0}
                    isCancelBtnDisabled={approveNoteIsMutating > 0}
                >
                    <>
                        {doesSelectedNotesHaveSafetyPlan && (
                            <div className="mb-24">
                                <Alert
                                    type="warning"
                                    description="Please note that selected safety plan notes will be excluded from this action. Kindly thoroughly review each safety plan note by opening it and going through its contents before approving and signing them individually."
                                    style={{ width: "100%" }}
                                />
                            </div>
                        )}
                        <ApproveNote
                            noteId={selectedNoteId as string}
                            selectedIds={
                                noteIdsToApproveForWeb() ||
                                noteIdsToApproveForMobile()
                            }
                            showDetails={false}
                            onFormSubmit={() => {
                                handleDialogInView(null);
                                setRowSelection({});
                                setSelectedNotes([]);
                            }}
                        />
                    </>
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "archive"}>
                <DialogContent
                    title="Archive Note"
                    variant="center"
                    showFooter
                    saveText="Archive Note"
                    submitBtnFormValue="archive-note"
                    handleCloseDialog={() => handleDialogInView(null)}
                    isSubmitBtnDisabled={archiveNoteIsMutating > 0}
                    isCancelBtnDisabled={archiveNoteIsMutating > 0}
                >
                    <ArchiveNote
                        onFormSubmit={() => {
                            handleDialogInView(null);
                            setRowSelection({});
                            setSelectedNotes([]);
                        }}
                        selectedIds={selectedIds}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "delete-confirmation"}>
                <DialogContent
                    title="Delete Draft"
                    isDeleting
                    showFooter
                    variant="center"
                    saveText="Delete Draft"
                    handleCloseDialog={() => handleDialogInView(null)}
                    onSaveClick={handleDeleteNote}
                    isSubmitBtnDisabled={deleteNote.isLoading}
                    isCancelBtnDisabled={deleteNote.isLoading}
                >
                    <div>
                        <h3 className="font-medium text-xl mb-8">
                            Delete draft note
                        </h3>
                        <p className="text-gray font-normal text-base">
                            Are you sure you want to delete this draft note?
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
            <div>
                <div className="rounded-r8 bg-white py-16 px-12 mb-32">
                    <div className="flex flex-col gap-y-24">
                        <SearchInput
                            defaultValue={providerNameFilter}
                            onChange={handleSetProviderNameFilter}
                            containerClass="w-[500px]"
                            placeholder="Search by intern"
                        />
                        <div className="flex items-center justify-between gap-x-12 flex-wrap gap-y-24">
                            <div className="flex items-center gap-x-12 flex-wrap gap-y-12">
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <FilterButton text="Note type" />
                                    </DropdownTrigger>
                                    <DropdownContent
                                        align="end"
                                        width="auto"
                                        maxHeight={216}
                                        className="overflow-y-auto"
                                    >
                                        {Object.values(NoteTypes).map(
                                            (type) => (
                                                <DropdownItem
                                                    key={type}
                                                    onClick={() =>
                                                        handleSetTypeFilter(
                                                            type
                                                        )
                                                    }
                                                    isMulti
                                                    itemValue={type}
                                                    values={noteTypeFilter}
                                                >
                                                    {makeStringFirstLetterCapital(
                                                        removeEnumUnderscore(
                                                            NoteLabels[type]
                                                        )
                                                    )}
                                                </DropdownItem>
                                            )
                                        )}
                                    </DropdownContent>
                                </Dropdown>
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <FilterButton text="Status" />
                                    </DropdownTrigger>
                                    <DropdownContent
                                        align="end"
                                        width="auto"
                                        maxHeight={216}
                                        className="overflow-y-auto"
                                    >
                                        {Object.values(NoteStatus).map(
                                            (status) => (
                                                <DropdownItem
                                                    key={status}
                                                    onClick={() =>
                                                        handleSetStatusFilter(
                                                            status
                                                        )
                                                    }
                                                    isMulti
                                                    itemValue={status}
                                                    values={statusFilter}
                                                >
                                                    {makeStringFirstLetterCapital(
                                                        removeEnumUnderscore(
                                                            status
                                                        )
                                                    )}
                                                </DropdownItem>
                                            )
                                        )}
                                    </DropdownContent>
                                </Dropdown>
                                {(practice?.permissions?.includes(
                                    UserPermisions.AUDIT_DASHBOARD_READ
                                ) ||
                                    !practice?.provider_id) && (
                                    <Popover
                                        open={showAuthorPopover}
                                        onOpenChange={(open) => {
                                            setShowAuthorPopover(open);
                                            setAuthorSearchString("");
                                        }}
                                    >
                                        <PopoverTrigger
                                            asChild
                                            onClick={() =>
                                                setShowAuthorPopover(true)
                                            }
                                        >
                                            <FilterButton
                                                classNames={
                                                    authorFilter
                                                        ? "capitalize"
                                                        : "normal-case"
                                                }
                                                text={
                                                    getAuthorTextToDisplay
                                                        ? truncateString(
                                                              getAuthorTextToDisplay
                                                          )
                                                        : "Author"
                                                }
                                            />
                                        </PopoverTrigger>
                                        <PopoverContent
                                            className="px-4 pt-[3px] py-[3px] w-[316px] rounded-r8 border-none shadow-[0px_0px_0px_1px_#98a1b219,_0px_15px_35px_-5px_#11182626,_0px_5px_15px_0px_#00000014]"
                                            align="start"
                                        >
                                            <SearchInput
                                                placeholder="Search by name"
                                                containerClass="h-24"
                                                onChange={(e) =>
                                                    setAuthorSearchString(
                                                        e.target.value
                                                    )
                                                }
                                            />

                                            <div className="flex flex-col items-start max-h-[230px] overflow-y-auto">
                                                {providers.isLoading && (
                                                    <div className="flex flex-col gap-y-4 items-stretch w-full">
                                                        {[1, 2, 3, 4, 5].map(
                                                            (item) => (
                                                                <Skeleton
                                                                    key={item}
                                                                    width="100%"
                                                                    height="24px"
                                                                    containerTestId="providers-loader"
                                                                />
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                                {providers.error && (
                                                    <ListState
                                                        isError
                                                        context="general"
                                                        stateHelperText="Please try again later"
                                                        errorMsg={
                                                            providers.error
                                                                ?.response?.data
                                                                ?.message ||
                                                            "Error loading authors"
                                                        }
                                                    />
                                                )}
                                                {providers.data?.data &&
                                                    providers.isSuccess && (
                                                        <>
                                                            <Button
                                                                size="auto"
                                                                variant="normal"
                                                                className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start !rounded-none"
                                                                onClick={() => {
                                                                    handleSetAuthorFilter(
                                                                        ""
                                                                    );
                                                                    setShowAuthorPopover(
                                                                        false
                                                                    );
                                                                    setAuthorSearchString(
                                                                        ""
                                                                    );
                                                                }}
                                                            >
                                                                All
                                                            </Button>
                                                            {providers?.data?.data.map(
                                                                (provider) => (
                                                                    <Button
                                                                        key={
                                                                            provider.provider_id
                                                                        }
                                                                        size="auto"
                                                                        variant="normal"
                                                                        className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start gap-x-8 !rounded-none"
                                                                        onClick={() => {
                                                                            handleSetAuthorFilter(
                                                                                provider.user_id
                                                                            );
                                                                            setShowAuthorPopover(
                                                                                false
                                                                            );
                                                                            setAuthorSearchString(
                                                                                ""
                                                                            );
                                                                        }}
                                                                    >
                                                                        {
                                                                            provider.provider_name
                                                                        }
                                                                        {authorFilter ===
                                                                        provider.user_id ? (
                                                                            <CheckPrimaryColorIcon />
                                                                        ) : null}
                                                                    </Button>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                            </div>
                                        </PopoverContent>
                                    </Popover>
                                )}
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <FilterButton
                                            classNames="normal-case"
                                            text={cptCodeFilter || "CPT code"}
                                        />
                                    </DropdownTrigger>
                                    <DropdownContent
                                        maxHeight={216}
                                        className="overflow-y-auto"
                                    >
                                        <DropdownItem
                                            className={cn(
                                                "flex gap-x-8 items-center text-xs font-medium",
                                                {
                                                    "text-primary":
                                                        cptCodeFilter === "",
                                                }
                                            )}
                                            onClick={() =>
                                                handleSetCptCodeFilter("")
                                            }
                                        >
                                            All
                                            {!cptCodeFilter ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                        {CPT_CODES.map((code) => (
                                            <DropdownItem
                                                key={code}
                                                className={cn(
                                                    "flex gap-x-8 items-center text-xs font-medium",
                                                    {
                                                        "text-primary":
                                                            cptCodeFilter ===
                                                            code,
                                                    }
                                                )}
                                                onClick={() =>
                                                    handleSetCptCodeFilter(code)
                                                }
                                            >
                                                {code}
                                                {cptCodeFilter === code ? (
                                                    <CheckPrimaryColorIcon />
                                                ) : null}
                                            </DropdownItem>
                                        ))}
                                    </DropdownContent>
                                </Dropdown>

                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <FilterButton
                                            classNames="normal-case"
                                            text={getArchivedNoteFilterToDisplay()}
                                        />
                                    </DropdownTrigger>
                                    <DropdownContent>
                                        <DropdownItem
                                            className={cn(
                                                "flex gap-x-8 items-center text-xs font-medium",
                                                {
                                                    "text-primary":
                                                        showArchivedFilter ===
                                                        "true",
                                                }
                                            )}
                                            onClick={() =>
                                                handleSetArchivedNotesFilter(
                                                    "true"
                                                )
                                            }
                                        >
                                            Archived notes
                                            {showArchivedFilter === "true" ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                        <DropdownItem
                                            className={cn(
                                                "flex gap-x-8 items-center text-xs font-medium",
                                                {
                                                    "text-primary":
                                                        showArchivedFilter ===
                                                        "false",
                                                }
                                            )}
                                            onClick={() =>
                                                handleSetArchivedNotesFilter(
                                                    "false"
                                                )
                                            }
                                        >
                                            Unarchived notes
                                            {showArchivedFilter === "false" ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                    </DropdownContent>
                                </Dropdown>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <FilterButton text="Date" />
                                    </PopoverTrigger>
                                    <PopoverContent
                                        align="end"
                                        className="p-16 gap-y-12"
                                    >
                                        <DatePicker
                                            label="From"
                                            placeholderText="MM/DD/YYYY"
                                            selected={fromDateFilterToDate}
                                            onChange={
                                                handleFromDateFilterChange
                                            }
                                            maxDate={
                                                toDateFilterToDate ||
                                                new Date(Date.now())
                                            }
                                            isClearable
                                        />
                                        <DatePicker
                                            label="To"
                                            placeholderText="MM/DD/YYYY"
                                            selected={toDateFilterToDate}
                                            onChange={handleToDateFilterChange}
                                            minDate={fromDateFilterToDate}
                                            isClearable
                                        />
                                    </PopoverContent>
                                </Popover>
                            </div>
                            <div className="w-full min-[500px]:w-auto">
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <FilterButton
                                            classNames="bg-primary normal-case text-white *:stroke-white w-full"
                                            text="Create note"
                                            stroke={APP_COLORS.COLOR_WHITE}
                                        />
                                    </DropdownTrigger>
                                    <DropdownContent
                                        align="end"
                                        width={
                                            screenIsGreaterThan500
                                                ? "auto"
                                                : undefined
                                        }
                                        maxHeight={
                                            screenIsGreaterThan500
                                                ? 216
                                                : "auto"
                                        }
                                        className="overflow-y-auto"
                                    >
                                        {Object.values(NoteTypes)
                                            .sort((a, b) => a.localeCompare(b))
                                            .map((noteType) => (
                                                <DropdownItem
                                                    key={noteType}
                                                    className={
                                                        screenIsGreaterThan500
                                                            ? "justify-start"
                                                            : "flex justify-center items-center"
                                                    }
                                                >
                                                    <Link
                                                        to={`/add-note?note_type=${noteType}`}
                                                    >
                                                        {NoteLabels[noteType]}
                                                    </Link>
                                                </DropdownItem>
                                            ))}
                                    </DropdownContent>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <TableFiltersDisplay
                        appliedFilters={[
                            {
                                key: "status[]",
                                values: statusFilter,
                            },
                            {
                                key: "type[]",
                                values: noteTypeFilter.map(
                                    (type) => NoteLabels[type]
                                ),
                            },
                        ]}
                        wrapperClass="mt-12"
                    />
                </div>
                {isLoading ? (
                    <Skeleton
                        type="table"
                        containerTestId="list-loader"
                        count={8}
                    />
                ) : null}
                {error && error?.response?.status !== 404 && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            error?.response?.data.message ||
                            `Cannot display supervisee notes list at this time please try again later`
                        }
                    />
                )}
                {data && isSuccess && data.data.length === 0 && (
                    <ListState
                        stateHelperText="Supervisee notes list will appear here once they are added"
                        isError={false}
                        emptyMessage="No note added yet"
                    />
                )}
                {isSuccess && data && data.data.length > 0 && (
                    <>
                        {showButtons && (
                            <div className={styles.selected}>
                                <div className={styles.selected_count}>
                                    {selectedNotes.length > 0
                                        ? `${selectedNotes.length} selected`
                                        : getTotalAmountOfTableRowsSelected(
                                              rowSelection
                                          ).amountString}
                                </div>
                                {detailsArray?.every(
                                    (note: ISuperviseeNote) =>
                                        note?.status ===
                                        NoteStatus.PENDING_SUPERVISOR_REVIEW
                                ) &&
                                    detailsArray.every(
                                        (note: ISuperviseeNote) =>
                                            note?.is_archived === false
                                    ) && (
                                        <Button
                                            variant="secondary"
                                            onClick={handleApproveActionClick}
                                        >
                                            Approve and sign
                                        </Button>
                                    )}

                                {canArchiveNote() &&
                                    detailsArray.every(
                                        (note: ISuperviseeNote) =>
                                            note?.is_archived === false
                                    ) &&
                                    detailsArray?.every(
                                        (note: INote) =>
                                            note?.status !== NoteStatus.DRAFT
                                    ) && (
                                        <Button
                                            variant="secondary"
                                            onClick={() =>
                                                handleDialogInView("archive")
                                            }
                                        >
                                            Archive note
                                        </Button>
                                    )}

                                {canArchiveNote() &&
                                    detailsArray.every(
                                        (note: ISuperviseeNote) =>
                                            note?.is_archived
                                    ) && (
                                        <Button
                                            variant="secondary"
                                            onClick={() => handleUnarchive()}
                                        >
                                            Unarchive note
                                        </Button>
                                    )}

                                {detailsArray?.every(
                                    (note: ISuperviseeNote) =>
                                        note?.status === NoteStatus.DRAFT
                                ) && (
                                    <Button
                                        className="bg-danger text-white hover:bg-danger"
                                        onClick={() =>
                                            handleDialogInView(
                                                "delete-confirmation"
                                            )
                                        }
                                    >
                                        Delete draft
                                    </Button>
                                )}
                            </div>
                        )}
                        <div className={styles.list_web}>
                            <Table
                                columns={columns}
                                data={data.data}
                                rowSelection={rowSelection}
                                setRowSelection={setRowSelection}
                                handleRowClick={handleRowClick}
                                setGetSelectedData={setGetSelectedData}
                                hasPagination={data.total_count > 20}
                                pagination={
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handleSetPageFilter}
                                        limit={limitFilter}
                                        onLimitChange={handleSetLimitFilter}
                                    />
                                }
                            />
                        </div>
                        <div
                            className="block md:hidden"
                            role="table"
                            ref={mobileListRef}
                        >
                            <div className="flex flex-col gap-y-12">
                                {data.data.map((note) => (
                                    <MobileListItem
                                        key={note.note_id}
                                        onClickCheckButton={() =>
                                            toggleNoteSelection(note.note_id)
                                        }
                                        isSelected={selectedNotes.includes(
                                            note.note_id
                                        )}
                                        topChildren={
                                            <div className="flex items-center gap-x-8 text-base font-semibold capitalize">
                                                {`${note.client.first_name} ${note.client.last_name}`}
                                                <span>
                                                    {getNoteStatusTag(
                                                        note.status
                                                    )}
                                                </span>
                                            </div>
                                        }
                                        bottomChildren={
                                            <Button
                                                variant="normal"
                                                size="auto"
                                                className="flex flex-col gap-y-8 w-full items-start"
                                                onClick={() =>
                                                    handleMobileNoteClick(note)
                                                }
                                            >
                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <span className="w-1/2 text-gray font-semibold text-xs">
                                                        {formatDate(
                                                            note.date_of_service
                                                        )}
                                                    </span>
                                                    <span className="w-1/2 font-semibold text-xs">
                                                        {
                                                            NoteLabels[
                                                                note.note_type
                                                            ]
                                                        }
                                                    </span>
                                                </div>
                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <span className="w-1/2 text-gray font-semibold text-xs">
                                                        Provider:
                                                    </span>
                                                    <span className="w-1/2 font-semibold text-xs">
                                                        {`${note.provider?.first_name} ${note.provider?.last_name}`}
                                                    </span>
                                                </div>
                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <span className="w-1/2 text-gray font-semibold text-xs">
                                                        Created on:
                                                    </span>
                                                    <span className="w-1/2 font-semibold text-xs">
                                                        {formatDate(
                                                            note.created_at
                                                        )}
                                                    </span>
                                                </div>
                                            </Button>
                                        }
                                    />
                                ))}
                            </div>
                            {data.total_count > 20 && (
                                <div className="bg-white p-12 rounded-r8 mt-16">
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handleSetPageFilter}
                                        limit={limitFilter}
                                        onLimitChange={handleSetLimitFilter}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Container, render } from "react-dom";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { handleNoteContentToDisplay } from "./modules/notes/components/NoteContent/NoteContent";
import { NoteTypes } from "./utils/types/notes";

/**
 * Contains JS functions that are meant to be used by external services.
 * e.g. to render the note component as a standalone page for export.
 */
function renderSessionNoteComponent(
    noteType: NoteTypes,
    noteContent: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    },
    containerDom: Container
) {
    render(
        <QueryClientProvider client={new QueryClient({})}>
            <BrowserRouter>
                <Routes>
                    <Route
                        path="*"
                        element={handleNoteContentToDisplay(
                            noteType,
                            noteContent
                        )}
                    />
                </Routes>
                <Toaster />
            </BrowserRouter>
        </QueryClientProvider>,
        containerDom
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).renderSessionNoteComponent = renderSessionNoteComponent;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserPractice } from "../../../modules/user-management/types/user-management.types";
import { areArraysEqual } from "../../../utils/helpers";
import axiosInstance from "../../../utils/axios";

export interface PracticeState {
    practice: UserPractice | null;
}

interface ChangePracticeProps {
    practice: UserPractice | null;
    updateLocation: boolean;
}

const initialState: PracticeState = {
    practice: localStorage.getItem("practice")
        ? JSON.parse(localStorage.getItem("practice") || "{}")
        : null,
};

export const practiceSlice = createSlice({
    name: "userPractice",
    initialState,
    reducers: {
        handleChangePractice: (
            state,
            action: PayloadAction<ChangePracticeProps>
        ) => {
            const { practice, updateLocation } = action.payload;
            if (practice) {
                if (
                    state.practice?.practice_id === practice.practice_id &&
                    areArraysEqual(state.practice?.roles, practice.roles)
                ) {
                    return;
                }
                state.practice = practice;

                localStorage.setItem("practice", JSON.stringify(practice));

                axiosInstance.defaults.headers.common.practice_id =
                    practice.practice_id;
            } else {
                state.practice = practice;
                axiosInstance.defaults.headers.common.practice_id = undefined;
            }

            if (updateLocation) {
                window.location.href = "/";
            }
        },
    },
});

export const { handleChangePractice } = practiceSlice.actions;

export default practiceSlice.reducer;

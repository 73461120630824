export default function SearchIcon() {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.6821 12.5348C9.66576 13.3251 8.38866 13.7958 7.00167 13.7958C3.68704 13.7958 1 11.108 1 7.79241C1 4.47685 3.68704 1.78906 7.00167 1.78906C10.3163 1.78906 13.0033 4.47685 13.0033 7.79241C13.0033 9.17966 12.533 10.457 11.743 11.4736L14.7799 14.5077C15.0731 14.8006 15.0734 15.2757 14.7806 15.569C14.4878 15.8622 14.0128 15.8625 13.7196 15.5696L10.6821 12.5348ZM11.5029 7.79241C11.5029 10.2791 9.48765 12.2949 7.00167 12.2949C4.5157 12.2949 2.50042 10.2791 2.50042 7.79241C2.50042 5.30574 4.5157 3.2899 7.00167 3.2899C9.48765 3.2899 11.5029 5.30574 11.5029 7.79241Z"
                fill="#576A81"
            />
        </svg>
    );
}

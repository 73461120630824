export default function NoteFormInfoBox() {
    return (
        <article className="p-16 flex flex-col w-full rounded-r6 bg-white shadow-[0px_0px_5px_rgba(11, 19, 43, 0.08)] text-xs">
            <p className="mb-px font-semibold">Instructions</p>
            <ol className="list-inside font-regular">
                <li className="list-decimal list-inside">
                    The fields marked with an asterisk (
                    <span className="text-danger">*</span>) are required before
                    you can submit your notes.
                </li>
                <li className="list-decimal list-inside">
                    Before you can submit your session notes, they must be
                    signed. You have the option to save your notes as a draft
                    before making a submission.
                </li>
            </ol>
        </article>
    );
}

import Navbar from "../../../components/Navbar/Navbar";
import RestrictedUsersList from "../components/RestrictedUsersList/RestrictedUsersList";

export default function ClientChartRestrictionPage() {
    return (
        <>
            <Navbar title="Client chart restrictions" />
            <div className="page">
                <RestrictedUsersList />
            </div>
        </>
    );
}

import { ColumnDef } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import { Pagination, Table } from "@jhool-io/fe-components";
import { formatDate, removeEnumUnderscore } from "../../../../utils/helpers";
import { IClaimSubmissionHistory } from "../../types/claims.types";
import { useFetchClaimSubmissionHistory } from "../../hooks/claims.queries";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";

const columns: ColumnDef<IClaimSubmissionHistory>[] = [
    {
        accessorKey: "created_at",
        header: "DATE",
        cell: ({ row }) => formatDate(row.original.created_at),
    },
    {
        accessorKey: "created_at",
        header: "Time",
        cell: ({ row }) => formatDate(row.original.created_at, false, "h:mma"),
    },
    {
        accessorKey: "author",
        header: "AUTHOR",
        cell: ({ row }) =>
            row.original.author.first_name ? (
                <span className="capitalize">
                    {`${row.original.author.first_name} ${row.original.author.last_name}`}
                </span>
            ) : (
                "System"
            ),
    },
    {
        accessorKey: "description",
        header: "DESCRIPTION",
        cell: ({ row }) => removeEnumUnderscore(row.original.description),
    },
];

interface ClaimSubmissionHistoryProps {
    claimId: string;
}

export default function ClaimSubmissionHistory({
    claimId,
}: ClaimSubmissionHistoryProps) {
    const [searchParams, setSearchParams] = useSearchParams();

    const pageFilter = Number(searchParams.get("page") || 1);

    const limitFilter = Number(searchParams.get("limit")) || 20;

    const { data, error, isLoading, isSuccess } =
        useFetchClaimSubmissionHistory(claimId, {
            page: pageFilter,
            limit: limitFilter,
        });

    const handleLimitChange = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    // onChange handler for page
    const handlePageChange = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };
    return (
        <>
            {isLoading && (
                <Skeleton
                    type="table"
                    containerTestId="claim-activities-loader"
                    count={6}
                />
            )}
            {error && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display claims activity at this time please try again later`
                    }
                />
            )}
            {data && isSuccess && (
                <>
                    {data.data.length === 0 && (
                        <ListState
                            isError={false}
                            emptyMessage="No claims activity"
                            stateHelperText="Claims activity will appear here"
                        />
                    )}
                    {data.data.length > 0 && (
                        <Table
                            columns={columns}
                            data={data.data}
                            hasPagination={data.total_count > 20}
                            pagination={
                                <Pagination
                                    totalCount={data.total_count}
                                    totalPages={data.total_pages}
                                    currentPage={pageFilter}
                                    onPageChange={handlePageChange}
                                    limit={limitFilter}
                                    onLimitChange={handleLimitChange}
                                />
                            }
                        />
                    )}
                </>
            )}
        </>
    );
}

export default function ReportsIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.3346 5.5026V12.0026C13.3346 14.0026 12.1413 14.6693 10.668 14.6693H5.33464C3.8613 14.6693 2.66797 14.0026 2.66797 12.0026V5.5026C2.66797 3.33594 3.8613 2.83594 5.33464 2.83594C5.33464 3.24927 5.50128 3.6226 5.77462 3.89593C6.04795 4.16927 6.4213 4.33594 6.83464 4.33594H9.16797C9.99464 4.33594 10.668 3.6626 10.668 2.83594C12.1413 2.83594 13.3346 3.33594 13.3346 5.5026Z"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.6654 2.83594C10.6654 3.6626 9.99203 4.33594 9.16536 4.33594H6.83203C6.4187 4.33594 6.04535 4.16927 5.77201 3.89594C5.49868 3.6226 5.33203 3.24927 5.33203 2.83594C5.33203 2.00927 6.00536 1.33594 6.83203 1.33594H9.16536C9.5787 1.33594 9.95205 1.50261 10.2254 1.77594C10.4987 2.04927 10.6654 2.4226 10.6654 2.83594Z"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.33203 8.66406H7.9987"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.33203 11.3359H10.6654"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

import { Button, Tag } from "@jhool-io/fe-components";
import * as React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import styles from "./ScheduleWeekView.module.scss";
import usePractice from "../../../../../hooks/usePractice";
import {
    DayMapping,
    DayOfWeek,
    IWeeklySchedule,
} from "../../../types/schedule.types";
import { useFetchWeeklySchedule } from "../../../hooks/schedule.queries";
import moment from "../../../../../utils/moment";
import Skeleton from "../../../../../components/Skeleton/Skeleton";
import ListState from "../../../../../components/ListState/ListState";
import { getColorStylesForCalendarId } from "../../../../../utils/constants";
import useShowInsuranceOverSelfPay from "../../../../practice-settings/hooks/useShowInsuranceOverSelfPay";
import {
    convertTimeTo12HoursFormat,
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../../utils/helpers";
import { getNoteStatusTag } from "../../../helpers/schedule.helpers";
import { NoteStatus } from "../../../../notes/types/notes.types";
import SendLinkIcon from "../../../../../components/Icons/SendLink";

// single event in the calendar
function SingleSchedule({ schedule }: { schedule: IWeeklySchedule }) {
    const navigate = useNavigate();
    const { recurrence_rule: recurrenceRule } = schedule;
    const colorStyles = getColorStylesForCalendarId(recurrenceRule as string);
    const style = {
        background: colorStyles.backgroundColor,
        borderLeft: colorStyles.color,
        height: "100%",
    };

    const showInsOverSelfPay = useShowInsuranceOverSelfPay();

    const dateColor = () => {
        if (schedule.start_date) {
            if (schedule?.session_id) {
                return {
                    textColor: "#2F6217",
                    bgColor: "#CCFAE9CC",
                };
            }
        }
        return {
            textColor: "#634D17",
            bgColor: "#F7E5A480",
        };
    };

    const navigateTo =
        schedule.session_id && schedule.note_id
            ? `/notes/${schedule.client.client_id}/${schedule.note_id}?viewFrom=session_note`
            : `/clients/${schedule.client.client_id}`;

    return (
        <div
            className={styles["schedule-item"]}
            style={{
                background: style.background,
                cursor: "pointer",
            }}
        >
            <Link to={navigateTo} className="h-full flex flex-col">
                <div
                    style={{
                        backgroundColor: `${style.borderLeft}`,
                    }}
                    className="absolute -left-[1.5px] w-[3px] h-full top-0"
                />
                <div className="rbc-display_name">
                    {`${schedule.client.first_name} ${schedule.client.last_name}`}
                </div>
                <div className="rbc-requested-service">
                    {makeStringFirstLetterCapital(
                        removeEnumUnderscore(schedule.service)
                    )}
                </div>
                <div className="rbc-wrapper">
                    <Tag
                        title={schedule.recurrence_rule as string}
                        textColor={
                            getColorStylesForCalendarId(
                                schedule.recurrence_rule as string
                            ).textColor
                        }
                        bgColor={
                            getColorStylesForCalendarId(
                                schedule.recurrence_rule as string
                            ).tagBgColor
                        }
                        className="text-[8px] font-medium py-4 min-h-0"
                    />

                    <Tag
                        title={
                            schedule?.session_id
                                ? formatDate(schedule?.start_date as string)
                                : formatDate(
                                      schedule?.possible_start_date_time as string
                                  )
                        }
                        textColor={dateColor().textColor}
                        bgColor={dateColor().bgColor}
                        className="text-[8px] font-medium py-4 min-h-0"
                    />

                    {schedule.note_status && (
                        <Tag
                            title={removeEnumUnderscore(
                                makeStringFirstLetterCapital(
                                    schedule.note_status
                                )
                            )}
                            textColor={
                                getNoteStatusTag(
                                    schedule.note_status as NoteStatus
                                ).textColor
                            }
                            bgColor={
                                getNoteStatusTag(
                                    schedule.note_status as NoteStatus
                                ).bgColor
                            }
                            className="text-[8px] font-medium py-4 min-h-0"
                        />
                    )}
                    {schedule.client.insurance_provider_name ||
                    (schedule.client.self_pay && !showInsOverSelfPay) ? (
                        <Tag
                            title={
                                schedule.client.insurance_provider_name
                                    ? removeEnumUnderscore(
                                          makeStringFirstLetterCapital(
                                              schedule.client
                                                  .insurance_provider_name
                                          )
                                      )
                                    : "SELF PAY"
                            }
                            textColor="#1A738D"
                            bgColor="#ACECF5"
                            className="text-[8px] font-medium py-4 min-h-0"
                        />
                    ) : null}
                </div>
                <div className="flex justify-between mt-auto pb-[4px]">
                    <div className="flex items-center text-x8 font-semibold text-gray">
                        {`${convertTimeTo12HoursFormat(
                            `${schedule.session_start_hr}:${schedule.session_start_min}`
                        )?.toUpperCase()} - ${convertTimeTo12HoursFormat(
                            `${schedule.session_end_hr}:${schedule.session_end_min}`
                        )?.toUpperCase()}`}
                    </div>
                    <Button
                        variant="normal"
                        onClick={() => navigate(navigateTo)}
                        className="p-0 min-h-0"
                    >
                        <SendLinkIcon />
                    </Button>
                </div>
            </Link>
        </div>
    );
}

export default function ScheduleWeekView() {
    const { practice } = usePractice();
    const [searchParams] = useSearchParams();
    const startOfWeek = searchParams.get("from_date");
    const clientFilters = searchParams.getAll("client_ids[]") || [];
    const providersFilters = searchParams.getAll("provider_ids[]") || [];
    const endOfWeek = searchParams.get("to_date");
    const loggedInProviderId = practice?.provider_id || "";

    const getProviderIds = () => {
        if (providersFilters.length > 0) {
            return providersFilters;
        }

        if (loggedInProviderId) {
            return [`${loggedInProviderId}`];
        }

        return [];
    };

    const { data, isLoading, error, isSuccess } = useFetchWeeklySchedule({
        client_ids: clientFilters,
        provider_ids: getProviderIds(),
        from_date: startOfWeek,
        to_date: endOfWeek,
    });

    const allSchedulesByDay: { [key: string]: IWeeklySchedule[] } = {
        sun: [],
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
    };
    const startOfWeekMoment = moment(startOfWeek, "YYYY-MM-DD");

    const daysOfWeekFormatted = Array.from({ length: 7 }).map((_, index) =>
        startOfWeekMoment.clone().add(index, "days").format("ddd MMM D")
    );

    const daysOfWeek = Object.keys(allSchedulesByDay);

    // Group schedules by day of the week based on session_start_date
    const seenSchedules = new Set();

    const dayOfWeekMap: DayMapping = {
        Sun: "sun",
        Mon: "mon",
        Tue: "tue",
        Wed: "wed",
        Thu: "thu",
        Fri: "fri",
        Sat: "sat",
    };

    data?.data?.forEach((s) => {
        // Get the day of the week
        const dayOfWeek = dayOfWeekMap[s.session_day as DayOfWeek];

        // Set start time directly using session_start_hr and session_start_min
        const startTime = moment()
            .set("hour", s.session_start_hr)
            .set("minute", s.session_start_min)
            .set("second", 0)
            .set("millisecond", 0);

        // Set end time similarly
        const endTime = moment()
            .set("hour", s.session_end_hr)
            .set("minute", s.session_end_min)
            .set("second", 0)
            .set("millisecond", 0);

        const schedule = {
            ...s,
            start: startTime.toISOString(),
            end: endTime.toISOString(),
        };

        const scheduleKey = `${s.session_day}_${s.session_start_hr}_${s.session_start_min}_${s.session_end_hr}_${s.session_end_min}_${s.client.first_name}_${s.client.last_name}_${s.service}`;

        if (!seenSchedules.has(scheduleKey)) {
            allSchedulesByDay[dayOfWeek]?.push(schedule);
            seenSchedules.add(scheduleKey);
        }
    });

    const timeSlots = Array.from({ length: 24 }).map((_, index) => {
        const time = moment()
            .startOf("day")
            .add(index + 1, "hours");
        return {
            label: time.format("LT"),
            value: time,
            hour: index,
        };
    });

    React.useEffect(() => {
        if (data?.data) {
            const get7amContainer = document.getElementById(
                "7"
            ) as HTMLDivElement;

            if (get7amContainer) {
                get7amContainer.scrollIntoView({ behavior: "instant" });
            }
        }
    }, [data?.data]);

    return (
        <div>
            {isLoading && (
                <div className="pb-[72px]">
                    <Skeleton width="100%" height="200px" />
                </div>
            )}
            {error && (
                <ListState
                    isError
                    errorMsg={
                        error.response?.data.message ||
                        "Could not load user caseload at this time"
                    }
                    stateHelperText="Try reloading this page to solve this issue"
                    context="general"
                    cardClass="h-[200px]"
                />
            )}
            {data && isSuccess && (
                <div className="border border-strokedark rounded-r4">
                    <div className={styles["week-calendar"]}>
                        <div className={styles.time}>
                            <div />
                            {timeSlots.map((timeSlot, index) => (
                                <div
                                    key={`${index + 1}`}
                                    className={styles["single-time"]}
                                >
                                    {timeSlot.label}
                                </div>
                            ))}
                        </div>
                        <div className={styles.days}>
                            {daysOfWeek.map((day, dayIndex) => (
                                <div key={day}>
                                    <div className={styles.weekdays}>
                                        {daysOfWeekFormatted[dayIndex]}
                                    </div>
                                    <div className={styles["single-day"]}>
                                        {timeSlots.map((timeSlot, index) => (
                                            <div
                                                key={`${timeSlot.hour}`}
                                                className={
                                                    styles["schedule-wrap"]
                                                }
                                                id={String(timeSlot.hour)}
                                                style={{
                                                    scrollMarginTop: "30px",
                                                }}
                                            >
                                                {allSchedulesByDay[day]
                                                    .filter((s) => {
                                                        return (
                                                            s.session_start_hr ===
                                                            index
                                                        );
                                                    })
                                                    .map((s) => (
                                                        <div
                                                            key={s.session_id}
                                                            style={{
                                                                height: "100%",
                                                            }}
                                                        >
                                                            <SingleSchedule
                                                                schedule={s}
                                                            />
                                                        </div>
                                                    ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

/* eslint-disable react/no-unstable-nested-components */
import { Pagination, Table, Tag } from "@jhool-io/fe-components";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import {
    formatDate,
    showMoneyInAppFormat,
} from "../../../../../../utils/helpers";
import ListState from "../../../../../../components/ListState/ListState";
import Skeleton from "../../../../../../components/Skeleton/Skeleton";
import { useFetchCardTransactionHistory } from "../../../../hooks/clients.queries";
import { ICardHistory } from "../../../../types/clients.types";
import { LISTS_DEFAULT_LIMIT } from "../../../../../../utils/constants";

export default function CardTransactionHistory() {
    // Get id from url params
    const params = useParams();

    const clientId = params.clientId as string;
    const [searchParams, setSearchParams] = useSearchParams();
    const cardPaymentID = searchParams.get("payment_id");

    const pageFilter = Number(searchParams.get("transaction_page") || 1);
    const limitFilter =
        Number(searchParams.get("transaction_limit")) || LISTS_DEFAULT_LIMIT;

    // Fetch client credit cards
    const { data, isLoading, error, isSuccess } =
        useFetchCardTransactionHistory(clientId, cardPaymentID || "", {
            page: pageFilter,
            limit: limitFilter,
        });

    // onChange handler for page
    const handlePageChange = (page: number) => {
        searchParams.set("transaction_page", String(page));
        setSearchParams(searchParams);
    };

    const handleLimitChange = (limit: number) => {
        searchParams.set("transaction_limit", String(limit));
        setSearchParams(searchParams);
    };

    // function to get only first word of transaction status
    const extractFirstWord = (str: string) => {
        return str.split(/[A-Z]/)[0];
    };

    const columns: ColumnDef<ICardHistory>[] = React.useMemo(
        () => [
            {
                accessorKey: "trans_id",
                header: "Trans ID",
            },
            {
                accessorKey: "invoice_number",
                header: "Invoice Number",
            },

            {
                accessorKey: "transaction_status",
                header: "STATUS",
                cell: ({ row }) => {
                    let bgColor: string;
                    let textColor: string;

                    switch (row.original.transaction_status) {
                        case "settledSuccessfully":
                            textColor = "#00563E";
                            bgColor = "#CCFAE9";
                            break;
                        case "refundSettledSuccessfully":
                            textColor = "#981F41";
                            bgColor = "#FBC7C6";
                            break;
                        case "voided":
                            textColor = "#981F41";
                            bgColor = "#FBC7C6";
                            break;
                        case "expired":
                            textColor = "#0B132B";
                            bgColor = "#F5F5F5";
                            break;
                        default:
                            textColor = "#926B0F";
                            bgColor = "#E9A85C";
                    }

                    return (
                        <Tag
                            title={extractFirstWord(
                                row.original.transaction_status
                            )}
                            textColor={textColor}
                            bgColor={bgColor}
                            className="capitalize font-normal text-xs p-4 rounded-r4"
                        />
                    );
                },
            },
            {
                accessorKey: "submit_time_utc",
                header: "Submit Date",
                cell: ({ row }) => formatDate(row.original.submit_time_utc),
            },
            {
                accessorKey: "settlement_time_utc",
                header: "Settlement Date",
                cell: ({ row }) => formatDate(row.original.settlement_time_utc),
            },
            {
                accessorKey: "settle_amount",
                header: "Amount",
                cell: ({ row }) =>
                    showMoneyInAppFormat(row.original.settle_amount),
            },
            {
                accessorKey: "customer",
                header: "Customer",
                cell: ({ row }) =>
                    row.original.first_name && row.original.last_name
                        ? `${row.original.first_name} ${row.original.last_name}`
                        : "--",
            },
            {
                accessorKey: "payment_description",
                header: "Description",
                cell: ({ row }) => (
                    <span className="text-wrap">
                        {row.original.payment_description || "--"}
                    </span>
                ),
            },
        ],
        []
    );

    return (
        <div>
            {error && error?.response?.status !== 404 && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display transaction history at this time please try again later`
                    }
                />
            )}
            {data && isSuccess && data?.data?.length === 0 && (
                <ListState
                    stateHelperText="Card transaction history list will appear here"
                    isError={false}
                    emptyMessage="There is no transaction history"
                />
            )}
            {isLoading && (
                <Skeleton
                    type="table"
                    containerTestId="card-history-loader"
                    count={6}
                />
            )}
            {data && data?.data?.length > 0 && isSuccess && !isLoading && (
                <Table
                    columns={columns}
                    data={data.data}
                    hasPagination={data.total_count > 20}
                    pagination={
                        <Pagination
                            totalCount={data.total_count}
                            totalPages={data.total_pages}
                            currentPage={pageFilter}
                            onPageChange={handlePageChange}
                            onLimitChange={handleLimitChange}
                        />
                    }
                />
            )}
        </div>
    );
}

import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IPractice, IPracticesListFilter } from "../types/practices.types";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import { fetchAllPractices } from "../services/practices.services";
import {
    IApiResponse,
    IPaginatedApiResponse,
} from "../../../utils/types/api-response";

export function useFetchAllPractices(
    filters: IPracticesListFilter,
    enabled?: boolean
) {
    return useQuery<
        IPaginatedApiResponse<IPractice[]>,
        AxiosError<IApiResponse<IPractice[]>>
    >({
        queryKey: ["practices", handleRemoveEmptyParamFromQueryParams(filters)],
        queryFn: () => fetchAllPractices(filters),
        enabled,
    });
}

/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import cn from "classnames";
import { ColumnDef, Row } from "@tanstack/react-table";
import { format } from "date-fns";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import {
    Checkbox,
    Pagination,
    Table,
    Dropdown,
    DropdownTrigger,
    DropdownContent,
    DropdownItem,
    Button,
    Dialog,
    DialogContent,
    Alert,
    SearchInput,
    Popover,
    PopoverTrigger,
    PopoverContent,
    DatePicker,
} from "@jhool-io/fe-components";
import {
    convertDateFilterStringToDate,
    formatDate,
    getTotalAmountOfTableRowsSelected,
    isSmallScreen,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    truncateString,
} from "../../../../utils/helpers";
import {
    FlaggedNoteForAuditStatus,
    INote,
    NoteLabels,
    NoteListSortAttribute,
    NoteStatus,
    NoteTypes,
    noteTypesForExport,
} from "../../../../utils/types/notes";
import { useDebounce } from "../../../../hooks/helpers";
import { useFetchUserDetails } from "../../../../hooks/queries/user";
import { useFetchNotesList } from "../../../../hooks/queries/note";
import ListState from "../../../ListState/ListState";
import Skeleton from "../../../Skeleton/Skeleton";
import styles from "../NoteListWrapper/NoteListWrapper.module.scss";
import {
    APP_COLORS,
    CPT_CODES,
    LISTS_DEFAULT_LIMIT,
} from "../../../../utils/constants";
import useToast from "../../../../hooks/useToast";
import ChangeCode from "../../NoteContent/ChangeCode/ChangeCode";
import {
    useChangeCptCode,
    useChangeNoteArchiveStatus,
    useDeleteDraftNote,
    useExportNotes,
} from "../../../../hooks/mutations/note";
import { UserPermisions, UserRole } from "../../../../utils/types/user";
import { useDoesUserHavePermission } from "../../../../hooks/permissions";
import ArchiveNote from "../../NoteContent/ArchiveNote/ArchiveNote";
import ApproveNote from "../../ApproveNote/ApproveNote";
import { useExportPdfs } from "../../../../hooks/queries";
import { useFetchInsuranceProviders } from "../../../../hooks/queries/client";
import { getNoteStatusTag } from "../../../../modules/notes/helpers/notes.helpers";
import MoreButton from "../../../../shared-ui/Buttons/MoreButton/MoreButton";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import TableFiltersDisplay from "../../../../shared-ui/TableFiltersDisplay/TableFiltersDisplay";
import ChevronDownIcon from "../../../Icons/ChevronDown";
import CheckPrimaryColorIcon from "../../../Icons/CheckPrimaryColor";
import { useFetchProviders } from "../../../../hooks/queries/provider";
import MobileListItem from "../../../../shared-ui/MobileListItem/MobileListItem";
import usePractice from "../../../../hooks/usePractice";
import FlagNoteForm from "../../../../modules/notes/components/FlagNoteForm/FlagNoteForm";
import useShowInsuranceOverSelfPay from "../../../../modules/practice-settings/hooks/useShowInsuranceOverSelfPay";

type Dialogs =
    | "loader"
    | "delete-confirmation"
    | "approve"
    | "archive-note"
    | "change-code"
    | "flag_note"
    | "unflag_note";

export interface FilterTypes {
    status: string[];
    type: string[];
    cptCode: string;
    insuranceProvider: string;
    fromDate: null | Date;
    toDate: null | Date;
    page: number;
}

interface NoteListProps {
    viewAll?: boolean;
    setNotesCount?: (count: string) => void;
}

export default function NoteList({ viewAll, setNotesCount }: NoteListProps) {
    const [searchParams, setSearchParams] = useSearchParams();

    const [dialogInView, setDialogInView] = React.useState<Dialogs | null>(
        null
    );
    const [rowSelection, setRowSelection] = React.useState({});
    const [codeToConfirmId, setCodeToConfirmId] = React.useState<string | null>(
        null
    );
    const [selectedNotesId, setSelectedNotesId] = React.useState<string[]>([]);
    const [jobId, setJobId] = React.useState<string | null>(null);
    const [pdfCount, setPdfCount] = React.useState(0);
    const [showProviderListPopover, setShowProviderListPopover] =
        React.useState(false);
    const [showAuthorPopover, setShowAuthorPopover] = React.useState(false);
    const [providerNameSearchString, setProviderNameSearchString] =
        React.useState("");
    const [authorSearchString, setAuthorSearchString] = React.useState("");
    const [getSelectedData, setGetSelectedData] =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        React.useState<any>();

    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: searchParams.get("sort_attr") || "",
        direction: searchParams.get("sort_order") || "",
    });

    const loggedInUser = useFetchUserDetails();
    const { practice } = usePractice();

    // Get filters from url
    const clientFilter = searchParams.get("search_string") || "";
    const providerNameFilter = searchParams.get("provider_name") || "";
    const authorFilter = searchParams.get("author") || "";
    const cptCodeFilter = searchParams.get("cpt_code") || "";
    const insuranceProviderFilter =
        searchParams.get("insurance_provider") || "";
    const statusFilter = searchParams.getAll("status[]");
    const noteTypeFilter = searchParams.getAll("type[]");
    const fromDateFilter = searchParams.get("from_date");
    const toDateFilter = searchParams.get("to_date");
    const showArchivedFilter = searchParams.get("show_archived") || "false";
    const fromDateCreatedFilter = searchParams.get("from_date_created");
    const toDateCreatedFilter = searchParams.get("to_date_created");
    const pageFilter = Number(searchParams.get("page")) || 1;
    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;

    const debouncedClienFilter = useDebounce(clientFilter);
    const debouncedProviderNameSearchString = useDebounce(
        providerNameSearchString
    );
    const debouncedAuthorSearchString = useDebounce(authorSearchString);

    const isSuperAdmin = practice?.roles.includes(UserRole.SUPER_ADMIN);

    // date filters values for DatePicker component
    // Converts the string values to a date in the correct timezone
    const fromDateFilterToDate = convertDateFilterStringToDate(fromDateFilter);
    const toDateFilterToDate = convertDateFilterStringToDate(toDateFilter);

    const fromDateCreatedFilterToDate = convertDateFilterStringToDate(
        fromDateCreatedFilter
    );
    const toDateCreatedFilterToDate =
        convertDateFilterStringToDate(toDateCreatedFilter);

    const screenIsGreaterThan500 =
        window.matchMedia("(min-width: 500px)").matches;

    const handleResetProvidersSearchString = () => {
        setProviderNameSearchString("");
        setAuthorSearchString("");
    };

    const insuranceProviders = useFetchInsuranceProviders();

    const showInsOverSelfPay = useShowInsuranceOverSelfPay();

    const providers = useFetchProviders({
        search_string:
            debouncedProviderNameSearchString || debouncedAuthorSearchString,
        page: 1,
        limit: 20,
    });

    const getInsuranceTextToDisplay = React.useMemo(
        () =>
            showInsOverSelfPay
                ? [...(insuranceProviders?.data?.data || [])].find(
                      (provider) =>
                          provider.insurance_provider_id ===
                          insuranceProviderFilter
                  )?.name
                : [
                      {
                          insurance_provider_id: "self_pay",
                          name: "Self pay",
                      },
                      ...(insuranceProviders?.data?.data || []),
                  ].find(
                      (provider) =>
                          provider.insurance_provider_id ===
                          insuranceProviderFilter
                  )?.name,
        [
            showInsOverSelfPay,
            insuranceProviders?.data?.data,
            insuranceProviderFilter,
        ]
    );

    const getAuthorTextToDisplay = providers.data?.data.find(
        (author) => author.user_id === authorFilter
    )?.provider_name;

    const handleDialogInView = (modal: Dialogs | null) => {
        setDialogInView(() => modal);
    };

    const sortableColumns = [
        "date_of_service",
        "client_name",
        "provider_name",
        "created_at",
    ];

    const handleConfigAttribute = () => {
        if (sortableColumns.includes(sortConfig.key)) {
            return sortConfig.key as NoteListSortAttribute;
        }

        return undefined;
    };

    const tabInView = searchParams.get("tab");

    // Hook for fetching all notes
    const { data, isLoading, isSuccess, error } = useFetchNotesList(
        {
            status: statusFilter,
            cpt_code: cptCodeFilter,
            type: noteTypeFilter,
            from_date: fromDateFilter,
            to_date: toDateFilter,
            from_created_at_date: fromDateCreatedFilter,
            to_created_at_date: toDateCreatedFilter,
            search_string: debouncedClienFilter,
            provider_name: providerNameFilter,
            page: pageFilter,
            insurance_provider_id: insuranceProviderFilter,
            limit: limitFilter,
            show_archived: showArchivedFilter,
            sort_attribute:
                searchParams.get("sort_attr") || handleConfigAttribute(),
            sort_order: searchParams.get("sort_order") || sortConfig.direction,
            author_id: authorFilter,
            tab_name: viewAll ? "all_notes" : "",
        },
        viewAll ? "" : (practice?.provider_id as string),
        Boolean(
            !(
                statusFilter &&
                statusFilter.length === 1 &&
                /,+/.test(statusFilter[0])
            )
        )
    );

    // check if logged in user can create note
    const canWriteNote = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
        UserPermisions.CLIENT_INFO_NOTES_WRITE,
    ]);

    const hideCreateButtonWithRole = () => {
        if (
            practice?.roles?.includes(UserRole.BILLER) ||
            practice?.roles?.includes(UserRole.BILLER_ADMIN) ||
            practice?.roles?.includes(UserRole.CODER)
        ) {
            return false;
        }
        return true;
    };

    const doesNoteHaveBill = (note: INote) => {
        const notesTypesWithoutBill = [
            NoteTypes.SAFETY_PLAN,
            NoteTypes.SUPERVISION_NOTE,
            NoteTypes.GENERAL_UPDATE_NOTE,
            NoteTypes.SUPERVISOR_CHECK_IN,
            NoteTypes.PAUSE_NOTIFICATION_NOTE,
        ];
        return (
            !notesTypesWithoutBill.includes(note.note_type) &&
            Boolean(note.date_of_service)
        );
    };

    const userShouldNaviagteToBills = [
        UserRole.BILLER,
        UserRole.SUPER_ADMIN,
        UserRole.BILLER_ADMIN,
    ].some((role) => practice?.roles?.includes(role));

    const navigate = useNavigate();

    // Clicked note navigation for web
    const handleRowClick = (row: Row<INote>) => {
        if (
            loggedInUser.data &&
            practice?.provider_id &&
            row.original.status === NoteStatus.DRAFT
        ) {
            navigate(
                `/notes/${row.original.note_id}/${row.original.client.client_id}/edit?note_type=${row.original.note_type}&session_history_id=${row.original.session_history_id}&appt_type=${row.original.appointment_type}`
            );
        } else if (
            row.original.status === NoteStatus.CODED &&
            doesNoteHaveBill(row.original) &&
            userShouldNaviagteToBills
        ) {
            navigate(
                `/billing-and-claims/${row.original.client.client_id}/${row.original.note_id}?viewFrom=session_note`
            );
        } else {
            navigate(
                `/notes/${row.original.client.client_id}/${row.original.note_id}?viewFrom=session_note`
            );
        }
    };

    // Clicked note navigation for mobile
    const handleMobileNoteClick = (note: INote) => {
        if (
            loggedInUser.data &&
            practice?.provider_id &&
            note.status === NoteStatus.DRAFT
        ) {
            navigate(
                `/notes/${note.note_id}/${note.client.client_id}/edit?note_type=${note.note_type}&session_history_id=${note.session_history_id}&appt_type=${note.appointment_type}`
            );
        } else if (
            note.status === NoteStatus.CODED &&
            doesNoteHaveBill(note) &&
            userShouldNaviagteToBills
        ) {
            navigate(
                `/billing-and-claims/${note.client.client_id}/${note.note_id}?viewFrom=session_note`
            );
        } else {
            navigate(
                `/notes/${note.client.client_id}/${note.note_id}?viewFrom=session_note`
            );
        }
    };

    /** FILTER INPUTS ONCHANGE FUNCTIONS */
    const handleSetClientNameFilter = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.value === "") searchParams.delete("search_string");
        else searchParams.set("search_string", e.target.value);
        searchParams.set("page", "1");
        localStorage.setItem("notelist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleSetProviderNameFilter = (provider: string) => {
        if (provider === "") searchParams.delete("provider_name");
        else searchParams.set("provider_name", provider);
        searchParams.set("page", "1");
        localStorage.setItem("notelist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleSetAuthorFilter = (author: string) => {
        if (author === "") searchParams.delete("author");
        else searchParams.set("author", author);
        searchParams.set("page", "1");
        localStorage.setItem("notelist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleSetTypeFilter = (type: string) => {
        if (noteTypeFilter.includes(type)) {
            searchParams.delete("type[]", type);
        } else {
            searchParams.append("type[]", type);
        }
        searchParams.set("page", "1");
        localStorage.setItem("notelist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleSetStatusFilter = (status: string) => {
        if (statusFilter.includes(status)) {
            searchParams.delete("status[]", status);
        } else {
            searchParams.append("status[]", status);
        }
        searchParams.set("page", "1");
        localStorage.setItem("notelist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };
    const handleSetInsuranceProviderFilter = (id: string) => {
        if (id === "") searchParams.delete("insurance_provider");
        else searchParams.set("insurance_provider", id);
        localStorage.setItem("notelist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleSetCptCodeFilter = (cptCode: string) => {
        if (cptCode === "") searchParams.delete("cpt_code");
        else searchParams.set("cpt_code", cptCode);
        localStorage.setItem("notelist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleSetArchivedNotesFilter = (archived: string) => {
        if (archived === "") searchParams.delete("show_archived");
        else searchParams.set("show_archived", archived);
        localStorage.setItem("notelist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem("notelist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem("notelist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleFromDateCreatedFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date_created");
        else searchParams.set("from_date_created", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem("notelist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleToDateCreatedFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date_created");
        else searchParams.set("to_date_created", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem("notelist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleSetPageFilter = (page: number) => {
        searchParams.set("page", String(page));
        localStorage.setItem("notelist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleSetLimitFilter = (limit: number) => {
        searchParams.set("limit", String(limit));
        localStorage.setItem("notelist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const getArchivedNoteFilterToDisplay = () => {
        if (showArchivedFilter === "true") {
            return "Archived notes";
        }
        return "Unarchived notes";
    };

    const insuranceProviderOptionsForDropdown = showInsOverSelfPay
        ? [
              ...(insuranceProviders?.data?.data || []).map((insurance) => ({
                  insurance_provider_id: insurance.insurance_provider_id,
                  name: insurance.name,
              })),
          ]
        : [
              {
                  insurance_provider_id: "self_pay",
                  name: "Self pay",
              },
              ...(insuranceProviders?.data?.data || []).map((insurance) => ({
                  insurance_provider_id: insurance.insurance_provider_id,
                  name: insurance.name,
              })),
          ];

    const handleSuccess = () => {
        setRowSelection({});
        setSelectedNotesId([]);
    };

    const confirmCodes = useChangeCptCode();

    const selectedIds = getSelectedData?.map(
        (item: Row<INote>) => item.original.note_id
    );

    const mobileListRef = React.useRef<HTMLDivElement>(null);

    // Array of details of the selected notes(web)
    const notesDetails =
        selectedIds &&
        selectedIds.map((noteId: string) =>
            data?.data.find((note) => note.note_id === noteId)
        );

    // Array of details of the selected notes(mobile)
    const selectedNotesDetails =
        selectedNotesId &&
        selectedNotesId.map((noteId: string) =>
            data?.data.find((note) => note.note_id === noteId)
        );

    const detailsArray =
        selectedNotesId.length > 0 ? selectedNotesDetails : notesDetails;

    // Details of a single note
    const noteDetails = data?.data.find((note) => {
        return note.note_id === codeToConfirmId;
    });

    const { toast } = useToast();

    const queryClient = useQueryClient();

    const handleConfirmCodes = (noteId?: string) => {
        interface ConfirmCodePayload {
            [noteId: string]: {
                cpt_code: string;
                modifier_codes: string[];
                confirm_code: boolean;
            };
        }

        // Multi-selected notes
        const confirmCodesPayload: ConfirmCodePayload =
            detailsArray && detailsArray?.length !== 0
                ? detailsArray.reduce(
                      (payload: ConfirmCodePayload, note: INote) => {
                          return {
                              ...payload,
                              [noteId || ""]: {
                                  cpt_code: note.cpt_code || "",
                                  modifier_codes: note.modifier_codes || [],
                                  confirm_code: true,
                              },
                          };
                      },
                      {}
                  )
                : {};

        // Single note action
        const confirmCodePayload = {
            [String(noteId)]: {
                cpt_code: noteDetails?.cpt_code || "",
                modifier_codes: noteDetails?.modifier_codes || [],
                confirm_code: true,
            },
        };

        confirmCodes.mutate(
            detailsArray && detailsArray?.length !== 0
                ? confirmCodesPayload
                : confirmCodePayload,
            {
                onSuccess(res) {
                    queryClient.invalidateQueries({
                        queryKey: [`notes`],
                    });
                    toast({
                        mode: "success",
                        message:
                            res.message || "Cpt code confirmed successfully",
                    });
                    handleSuccess();
                },
                onError(err) {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not confirm code at this moment, please try again",
                    });
                },
            }
        );
    };

    const exportNotes = useExportNotes();

    const handleExportNotes = (noteid?: string) => {
        const dataToSend = {
            session_note_ids: noteid
                ? [noteid]
                : detailsArray?.map((note: INote) => note.note_id),
        };

        exportNotes.mutate(dataToSend, {
            onSuccess: async (res) => {
                handleDialogInView("loader");
                await new Promise<void>((resolve) => {
                    const timer = setTimeout(() => {
                        clearTimeout(timer);
                        setJobId(res.data.export_job_id);
                        setPdfCount(res.data.expected_pdf_count);
                        resolve();
                    }, 8000);
                });
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.message || "Could not export notes at this time",
                });
            },
        });
    };

    const deleteNote = useDeleteDraftNote();

    // Function to call for deleting note
    const handleDeleteNote = () => {
        const noteIds = selectedIds?.length > 0 ? selectedIds : selectedNotesId;

        deleteNote.mutate(
            noteIds.length === 0 ? [noteDetails?.note_id] : noteIds,
            {
                async onSuccess(res) {
                    await queryClient.invalidateQueries({
                        queryKey: [`notes`],
                    });
                    toast({
                        mode: "success",
                        message:
                            res.message || "Draft note(s) deleted successfully",
                    });

                    // Close delete confirmation modal
                    handleSuccess();
                    handleDialogInView(null);
                },
                onError(err) {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Cannot delete note(s) at this moment, please try again",
                    });
                },
            }
        );
    };

    const unarchiveNote = useChangeNoteArchiveStatus();

    const handleUnarchive = (id?: string) => {
        const noteIds = selectedIds?.length > 0 ? selectedIds : selectedNotesId;

        const dataToSend = {
            note_ids_to_archive: noteIds.length === 0 ? [id] : noteIds,
            archive_status: false,
        };

        unarchiveNote.mutate(dataToSend, {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: [`notes`],
                });

                await queryClient.invalidateQueries({
                    queryKey: ["supervisee"],
                });

                toast({
                    mode: "success",
                    message: res.message || "Note unarchived successfully",
                });

                setRowSelection({});
                setSelectedNotesId([]);
            },

            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not unarchive note at this time",
                });
            },
        });
    };

    const exportNote = useExportPdfs(
        {
            export_job_id: jobId as string,
            pdf_type: "session_note",
            expected_pdf_count: pdfCount,
        },
        Boolean(jobId)
    );

    if (jobId && !exportNote.isLoading) {
        setJobId(null);
        handleDialogInView(null);
        setRowSelection({});
    }

    const showActions =
        practice?.roles?.includes(UserRole.CODER) ||
        practice?.roles?.includes(UserRole.SUPER_ADMIN);

    const canApproveNote =
        (viewAll &&
            loggedInUser.data?.user_id !== noteDetails?.provider?.user_id &&
            !noteDetails?.is_archived) ||
        (viewAll &&
            detailsArray?.every(
                (note: INote) =>
                    note?.provider?.user_id !== loggedInUser.data?.user_id
            ) &&
            detailsArray?.every((note: INote) => note?.is_archived === false));

    const canArchiveNote = () => {
        if (
            practice?.permissions?.includes(
                UserPermisions.CLIENT_NOTES_ALL_READ
            ) ||
            practice?.permissions?.includes(
                UserPermisions.CLIENT_INFO_NOTES_WRITE
            )
        ) {
            return true;
        }

        return false;
    };

    const canViewProvidersFilter =
        (practice?.permissions?.includes(UserPermisions.AUDIT_DASHBOARD_READ) &&
            viewAll) ||
        (!practice?.provider_id && viewAll);

    const showBulkFlagNotesBtn =
        practice?.permissions?.includes(
            UserPermisions.CLIENT_INFO_NOTES_FLAG_FOR_AUDIT_WRITE
        ) &&
        detailsArray?.every(
            (note: INote) =>
                note?.flagged_for_audit_status !==
                FlaggedNoteForAuditStatus.FLAGGED
        ) &&
        detailsArray?.every(
            (note: INote) =>
                note?.status === NoteStatus.PENDING_CODE_REVIEW ||
                note?.status === NoteStatus.CODED
        );

    const showFlagOption = (row: Row<INote>) =>
        row.original.flagged_for_audit_status !==
            FlaggedNoteForAuditStatus.FLAGGED &&
        (row.original.status === NoteStatus.CODED ||
            row.original.status === NoteStatus.PENDING_CODE_REVIEW) &&
        practice?.permissions?.includes(
            UserPermisions.CLIENT_INFO_NOTES_FLAG_FOR_AUDIT_WRITE
        );

    // Toggles note selection for mobile
    const toggleNoteSelection = (noteId: string) => {
        if (selectedNotesId.includes(noteId)) {
            setSelectedNotesId(selectedNotesId.filter((id) => id !== noteId));
        } else {
            setSelectedNotesId([...selectedNotesId, noteId]);
        }
    };

    const handleNoteHasMoreActions = (row: Row<INote>) => {
        if (
            noteTypesForExport.includes(row.original.note_type) ||
            (showActions &&
                row.original.status === NoteStatus.PENDING_CODE_REVIEW) ||
            row.original.status === NoteStatus.DRAFT ||
            (canApproveNote &&
                row.original.status === NoteStatus.PENDING_SUPERVISOR_REVIEW) ||
            canArchiveNote()
        ) {
            return true;
        }
        return false;
    };

    // Handle approve action clicked
    const handleApproveActionClicked = (note: INote) => {
        if (note.note_type === NoteTypes.SAFETY_PLAN) {
            toast({
                mode: "warning",
                message:
                    "Before giving your approval, please thoroughly review this safety plan note by opening it and going through its contents.",
            });
        } else {
            handleDialogInView("approve");
        }
    };

    // Table columns
    const columns: ColumnDef<INote>[] = React.useMemo(
        () => [
            {
                id: "select",
                header: ({ table }) => (
                    <Checkbox
                        handleChange={table.getToggleAllRowsSelectedHandler()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        label=""
                        name="row"
                        value="row"
                        isChecked={table.getIsAllRowsSelected()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        handleChange={row.getToggleSelectedHandler()}
                        indeterminate={row.getIsSomeSelected()}
                        label=""
                        name="row"
                        value="row"
                        isChecked={row.getIsSelected()}
                    />
                ),
            },
            {
                accessorKey: "date_of_service",
                header: "DATE OF SERVICE",
                cell: ({ row }) =>
                    row.original.date_of_service
                        ? formatDate(row.original.date_of_service)
                        : "--",
            },
            {
                accessorKey: "note_type",
                header: "NOTE TYPE",
                cell: ({ row }) => NoteLabels[row.original.note_type],
            },
            {
                accessorKey: "client_name",
                header: "CLIENT",
                cell: ({ row }) => (
                    <span className="capitalize">{`${row.original.client?.first_name}
            ${row.original.client?.last_name}`}</span>
                ),
            },
            {
                accessorKey: "cpt_code",
                header: "CPT CODE",
                cell: ({ row }) => row.original.cpt_code || "--",
            },
            {
                accessorKey: "provider_name",
                header: "PROVIDER",
                cell: ({ row }) => (
                    <span className="capitalize">
                        {`${row.original.provider?.first_name} ${row.original.provider?.last_name}`}
                    </span>
                ),
            },
            {
                accessorKey: "author",
                header: "CREATED BY",
                cell: ({ row }) => (
                    <span className="capitalize">
                        {`${row.original.author.first_name} ${row.original.author.last_name}`}
                    </span>
                ),
            },
            {
                accessorKey: "created_at",
                header: "CREATED ON",
                cell: ({ row }) => formatDate(row.original.created_at),
            },
            {
                accessorKey: "status",
                header: "STATUS",
                cell: ({ row }) =>
                    getNoteStatusTag(row.original.status as NoteStatus),
            },
            {
                accessorKey: "client",
                header: "INSURANCE PROVIDER",
                cell: ({ row }) => (
                    <span
                        className={
                            row.original.client.self_pay && !showInsOverSelfPay
                                ? "normal-case"
                                : "uppercase"
                        }
                    >
                        {row.original.client.self_pay && !showInsOverSelfPay
                            ? "Self pay"
                            : (row.original.client.insurance_provider_name &&
                                  removeEnumUnderscore(
                                      row.original.client
                                          .insurance_provider_name || ""
                                  )) ||
                              "--"}
                    </span>
                ),
            },
            {
                accessorKey: "note_id",
                header: "",
                cell: ({ row }) =>
                    handleNoteHasMoreActions(row) && (
                        <Dropdown>
                            <DropdownTrigger asChild>
                                <MoreButton
                                    onPointerDown={() => {
                                        setCodeToConfirmId(
                                            () => row.original.note_id
                                        );
                                    }}
                                />
                            </DropdownTrigger>
                            <DropdownContent align="end" width="auto">
                                {noteTypesForExport.includes(
                                    row.original.note_type
                                ) &&
                                    isSuperAdmin &&
                                    row.original.status !==
                                        NoteStatus.DRAFT && (
                                        <DropdownItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleExportNotes(
                                                    row.original.note_id
                                                );
                                            }}
                                        >
                                            Export to pdf
                                        </DropdownItem>
                                    )}
                                {canArchiveNote() &&
                                    row.original.is_archived === false &&
                                    row.original.status !==
                                        NoteStatus.DRAFT && (
                                        <DropdownItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDialogInView(
                                                    "archive-note"
                                                );
                                            }}
                                        >
                                            Archive note
                                        </DropdownItem>
                                    )}
                                {canArchiveNote() && row.original.is_archived && (
                                    <DropdownItem
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleUnarchive(
                                                row.original.note_id
                                            );
                                        }}
                                    >
                                        Unarchive note
                                    </DropdownItem>
                                )}
                                {showFlagOption(row) && (
                                    <DropdownItem
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDialogInView("flag_note");
                                        }}
                                    >
                                        Flag for audit
                                    </DropdownItem>
                                )}
                                {showActions &&
                                    row.original.status ===
                                        NoteStatus.PENDING_CODE_REVIEW &&
                                    row.original.flagged_for_audit_status !==
                                        FlaggedNoteForAuditStatus.FLAGGED && (
                                        <>
                                            <DropdownItem
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleConfirmCodes(
                                                        row.original.note_id
                                                    );
                                                }}
                                                disabled={
                                                    confirmCodes.isLoading
                                                }
                                            >
                                                Confirm Code
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDialogInView(
                                                        "change-code"
                                                    );
                                                }}
                                                disabled={
                                                    confirmCodes.isLoading
                                                }
                                            >
                                                Change Code
                                            </DropdownItem>
                                        </>
                                    )}
                                {row.original.status ===
                                    NoteStatus.PENDING_SUPERVISOR_REVIEW &&
                                    canApproveNote && (
                                        <DropdownItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleApproveActionClicked(
                                                    row.original
                                                );
                                            }}
                                        >
                                            Approve and sign note
                                        </DropdownItem>
                                    )}
                                {row.original.status === NoteStatus.DRAFT && (
                                    <DropdownItem
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDialogInView(
                                                "delete-confirmation"
                                            );
                                        }}
                                        className="text-danger"
                                    >
                                        Delete Note
                                    </DropdownItem>
                                )}
                                {row.original.flagged_for_audit_status ===
                                    FlaggedNoteForAuditStatus.FLAGGED &&
                                    practice?.permissions?.includes(
                                        UserPermisions.CLIENT_INFO_NOTES_FLAG_FOR_AUDIT_WRITE
                                    ) && (
                                        <DropdownItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDialogInView(
                                                    "unflag_note"
                                                );
                                            }}
                                        >
                                            Unflag note
                                        </DropdownItem>
                                    )}
                            </DropdownContent>
                        </Dropdown>
                    ),
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    // Check if  mutation is currently running, returns 0 || 1
    const changeNoteIsMutating = useIsMutating(["change-code"]);
    const archiveNoteIsMutating = useIsMutating(["archive-note"]);
    const approveNoteIsMutating = useIsMutating(["approve"]);
    const flagNoteIsMutating = useIsMutating(["flag-note"]);

    // Check if selected notes(mobile) contain notes with note type of safety_plan
    const notesToApproveHaveSafetyPlanMobile = data?.data.filter(
        (note) =>
            selectedNotesId?.includes(note.note_id) &&
            note.note_type === NoteTypes.SAFETY_PLAN
    );

    // Check if selected notes(web) contain notes with note type of safety_plan
    const notesToApproveHaveSafetyPlanWeb = data?.data.filter(
        (note) =>
            selectedIds?.includes(note.note_id) &&
            note.note_type === NoteTypes.SAFETY_PLAN
    );

    // Check to determine whether to show warning alert in the approve note modal
    const doesSelectedNotesHaveSafetyPlan =
        (notesToApproveHaveSafetyPlanMobile &&
            notesToApproveHaveSafetyPlanMobile.length > 0) ||
        (notesToApproveHaveSafetyPlanWeb &&
            notesToApproveHaveSafetyPlanWeb.length > 0);

    const handleApproveActionClick = () => {
        if (
            (notesToApproveHaveSafetyPlanWeb?.length === 0 &&
                notesToApproveHaveSafetyPlanWeb &&
                notesToApproveHaveSafetyPlanWeb?.length > 0) ||
            (notesToApproveHaveSafetyPlanMobile?.length === 0 &&
                notesToApproveHaveSafetyPlanMobile &&
                notesToApproveHaveSafetyPlanMobile?.length > 0)
        ) {
            toast({
                mode: "warning",
                message:
                    "You've only selected safety plan note(s), please thoroughly review each by opening and going through its contents before giving your approval.",
            });
        } else {
            handleDialogInView("approve");
        }
    };

    React.useEffect(() => {
        if (setNotesCount) {
            if (error) setNotesCount("");
            else if (isLoading) setNotesCount("");
            else if (data && data?.total_count > 0) {
                setNotesCount(String(data.total_count));
            } else if (data && data.data.length === 0) {
                setNotesCount("");
            }
        }
    }, [data, error, isLoading, setNotesCount]);

    React.useEffect(() => {
        searchParams.set("sort_attr", sortConfig.key);
        searchParams.set("sort_order", sortConfig.direction);
        localStorage.setItem("notelist_filters", searchParams.toString());
        setSearchParams(searchParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortConfig]);

    React.useEffect(() => {
        if (
            statusFilter &&
            statusFilter.length === 1 &&
            /,+/.test(statusFilter[0])
        ) {
            searchParams.delete("status[]");
            statusFilter[0].split(",").forEach((status) => {
                searchParams.append("status[]", status);
            });

            setSearchParams(searchParams);
        }
    }, [searchParams, setSearchParams, statusFilter]);

    return (
        <>
            <Dialog open={dialogInView === "change-code"}>
                <DialogContent
                    title="Change code"
                    handleCloseDialog={() => handleDialogInView(null)}
                    showFooter
                    variant="center"
                    cancelText="Cancel"
                    saveText="Change code"
                    submitBtnFormValue="change-cpt-code"
                    isSubmitBtnDisabled={changeNoteIsMutating > 0}
                    isCancelBtnDisabled={changeNoteIsMutating > 0}
                >
                    <ChangeCode
                        noteId={noteDetails?.note_id as string}
                        cptCode={noteDetails?.cpt_code || ""}
                        onFormSubmit={() => {
                            handleDialogInView(null);
                            setRowSelection({});
                            setSelectedNotesId([]);
                        }}
                        showDetails={false}
                        notes={detailsArray}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "loader"}>
                <DialogContent
                    title="Export in progress"
                    variant="center"
                    handleCloseDialog={() => handleDialogInView(null)}
                    showFooter={false}
                >
                    <div className="loader">
                        <div className="loader-icon" />
                        <div className="loader-text">
                            Please wait while the export is in progress.
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "archive-note"}>
                <DialogContent
                    title="Archive Note"
                    variant="center"
                    showFooter
                    saveText="Archive Note"
                    submitBtnFormValue="archive-note"
                    handleCloseDialog={() => handleDialogInView(null)}
                    isSubmitBtnDisabled={archiveNoteIsMutating > 0}
                    isCancelBtnDisabled={archiveNoteIsMutating > 0}
                >
                    <ArchiveNote
                        onFormSubmit={() => {
                            handleDialogInView(null);
                            setRowSelection({});
                            setSelectedNotesId([]);
                        }}
                        noteId={noteDetails?.note_id as string}
                        selectedIds={selectedIds}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={
                    dialogInView === "flag_note" ||
                    dialogInView === "unflag_note"
                }
            >
                <DialogContent
                    title={
                        dialogInView === "flag_note"
                            ? "Flag for Audit"
                            : "Unflag Note"
                    }
                    variant="center"
                    showFooter
                    saveText={
                        dialogInView === "flag_note"
                            ? "Flag for Audit"
                            : "Unflag Note"
                    }
                    submitBtnFormValue="flag-note"
                    handleCloseDialog={() => {
                        handleDialogInView(null);
                        setRowSelection({});
                    }}
                    isSubmitBtnDisabled={flagNoteIsMutating > 0}
                    isCancelBtnDisabled={flagNoteIsMutating > 0}
                >
                    <FlagNoteForm
                        onFormSubmit={() => {
                            handleDialogInView(null);
                            setRowSelection({});
                            setSelectedNotesId([]);
                        }}
                        noteId={noteDetails?.note_id}
                        selectedIds={selectedIds}
                        action={
                            dialogInView === "flag_note" ? "flag" : "unflag"
                        }
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "approve"}>
                <DialogContent
                    title="Approve and sign note"
                    variant="center"
                    handleCloseDialog={() => handleDialogInView(null)}
                    saveText="Approve and sign note"
                    showFooter
                    submitBtnFormValue="note-approval"
                    isSubmitBtnDisabled={approveNoteIsMutating > 0}
                    isCancelBtnDisabled={approveNoteIsMutating > 0}
                >
                    <>
                        {doesSelectedNotesHaveSafetyPlan && (
                            <div className="mb-24">
                                <Alert
                                    type="warning"
                                    description="Please note that selected safety plan notes will be excluded from this action. Kindly thoroughly review each safety plan note by opening it and going through its contents before approving and signing them individually."
                                    style={{ width: "100%" }}
                                />
                            </div>
                        )}
                        <ApproveNote
                            noteId={noteDetails?.note_id as string}
                            selectedIds={selectedIds}
                            showDetails={false}
                            onFormSubmit={() => {
                                handleDialogInView(null);
                                setRowSelection({});
                                setSelectedNotesId([]);
                            }}
                        />
                    </>
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "delete-confirmation"}>
                <DialogContent
                    title="Delete Draft"
                    isDeleting
                    showFooter
                    variant="center"
                    saveText="Delete Draft"
                    handleCloseDialog={() => handleDialogInView(null)}
                    onSaveClick={handleDeleteNote}
                    isSubmitBtnDisabled={deleteNote.isLoading}
                    isCancelBtnDisabled={deleteNote.isLoading}
                >
                    <div>
                        <h3 className="font-medium text-xl mb-8">
                            Delete draft note
                        </h3>
                        <p className="text-gray font-normal text-base">
                            Are you sure you want to delete this draft note?
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
            <div>
                <div className="rounded-r8 bg-white py-16 px-12 mb-32">
                    <div className="flex flex-col gap-y-24">
                        <div className="flex items-center justify-between flex-wrap gap-12">
                            <SearchInput
                                defaultValue={clientFilter}
                                onChange={handleSetClientNameFilter}
                                containerClass="w-[400px] max-w-full"
                                placeholder="Search by client name or chart number"
                            />
                        </div>
                        <div className="flex items-center justify-between gap-x-12 flex-wrap gap-y-24">
                            <div className="flex items-center gap-x-12 flex-wrap gap-y-12">
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <FilterButton text="Note type" />
                                    </DropdownTrigger>
                                    <DropdownContent
                                        align="end"
                                        width="auto"
                                        maxHeight={216}
                                        className="overflow-y-auto"
                                    >
                                        {Object.values(NoteTypes).map(
                                            (type) => (
                                                <DropdownItem
                                                    key={type}
                                                    onClick={() =>
                                                        handleSetTypeFilter(
                                                            type
                                                        )
                                                    }
                                                    isMulti
                                                    itemValue={type}
                                                    values={noteTypeFilter}
                                                >
                                                    {makeStringFirstLetterCapital(
                                                        removeEnumUnderscore(
                                                            NoteLabels[type]
                                                        )
                                                    )}
                                                </DropdownItem>
                                            )
                                        )}
                                    </DropdownContent>
                                </Dropdown>
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <FilterButton text="Status" />
                                    </DropdownTrigger>
                                    <DropdownContent
                                        align="end"
                                        width="auto"
                                        maxHeight={216}
                                        className="overflow-y-auto"
                                    >
                                        {Object.values(NoteStatus).map(
                                            (status) => (
                                                <DropdownItem
                                                    key={status}
                                                    onClick={() =>
                                                        handleSetStatusFilter(
                                                            status
                                                        )
                                                    }
                                                    isMulti
                                                    itemValue={status}
                                                    values={statusFilter}
                                                    hidden={
                                                        !practice?.provider_id &&
                                                        status ===
                                                            NoteStatus.DRAFT
                                                    }
                                                >
                                                    {makeStringFirstLetterCapital(
                                                        removeEnumUnderscore(
                                                            status
                                                        )
                                                    )}
                                                </DropdownItem>
                                            )
                                        )}
                                    </DropdownContent>
                                </Dropdown>
                                {canViewProvidersFilter && (
                                    <Dropdown>
                                        <DropdownTrigger asChild>
                                            <Button
                                                size="auto"
                                                variant="normal"
                                                className={cn(
                                                    "px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize",
                                                    {
                                                        uppercase:
                                                            insuranceProviderFilter,
                                                    }
                                                )}
                                            >
                                                {insuranceProviders.isLoading &&
                                                    "Loading.."}
                                                {insuranceProviders.error &&
                                                    "Error loading providers"}
                                                {insuranceProviders?.data
                                                    ?.data && (
                                                    <>
                                                        {getInsuranceTextToDisplay
                                                            ? truncateString(
                                                                  removeEnumUnderscore(
                                                                      getInsuranceTextToDisplay
                                                                  )
                                                              )
                                                            : "Insurance provider"}
                                                        <ChevronDownIcon
                                                            stroke={
                                                                APP_COLORS.COLOR_BLACK
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Button>
                                        </DropdownTrigger>
                                        <DropdownContent
                                            align={
                                                isSmallScreen()
                                                    ? "start"
                                                    : "end"
                                            }
                                            width={
                                                isSmallScreen()
                                                    ? "250px"
                                                    : "auto"
                                            }
                                            maxHeight={216}
                                            className="overflow-y-auto"
                                        >
                                            <DropdownItem
                                                className={cn(
                                                    "uppercase text-xs font-medium flex items-center gap-x-8",
                                                    {
                                                        "text-primary":
                                                            !insuranceProviderFilter,
                                                    }
                                                )}
                                                onClick={() =>
                                                    handleSetInsuranceProviderFilter(
                                                        ""
                                                    )
                                                }
                                            >
                                                All
                                                {!insuranceProviderFilter ? (
                                                    <CheckPrimaryColorIcon />
                                                ) : null}
                                            </DropdownItem>
                                            {insuranceProviderOptionsForDropdown
                                                .sort((a, b) =>
                                                    a.name.localeCompare(b.name)
                                                )
                                                .map((provider) => (
                                                    <DropdownItem
                                                        className={cn(
                                                            "uppercase text-xs font-medium flex items-center gap-x-8",
                                                            {
                                                                "text-primary":
                                                                    provider.insurance_provider_id ===
                                                                    insuranceProviderFilter,
                                                            }
                                                        )}
                                                        onClick={() =>
                                                            handleSetInsuranceProviderFilter(
                                                                provider.insurance_provider_id
                                                            )
                                                        }
                                                        key={
                                                            provider.insurance_provider_id
                                                        }
                                                    >
                                                        {removeEnumUnderscore(
                                                            provider.name
                                                        ).toUpperCase()}
                                                        {insuranceProviderFilter ===
                                                        provider.insurance_provider_id ? (
                                                            <CheckPrimaryColorIcon />
                                                        ) : null}
                                                    </DropdownItem>
                                                ))}
                                        </DropdownContent>
                                    </Dropdown>
                                )}
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <FilterButton
                                            classNames="normal-case"
                                            text={cptCodeFilter || "CPT code"}
                                        />
                                    </DropdownTrigger>
                                    <DropdownContent
                                        maxHeight={216}
                                        className="overflow-y-auto"
                                    >
                                        <DropdownItem
                                            className={cn(
                                                "flex gap-x-8 items-center text-xs font-medium",
                                                {
                                                    "text-primary":
                                                        cptCodeFilter === "",
                                                }
                                            )}
                                            onClick={() =>
                                                handleSetCptCodeFilter("")
                                            }
                                        >
                                            All
                                            {!cptCodeFilter ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                        {CPT_CODES.map((code) => (
                                            <DropdownItem
                                                key={code}
                                                className={cn(
                                                    "flex gap-x-8 items-center text-xs font-medium",
                                                    {
                                                        "text-primary":
                                                            cptCodeFilter ===
                                                            code,
                                                    }
                                                )}
                                                onClick={() =>
                                                    handleSetCptCodeFilter(code)
                                                }
                                            >
                                                {code}
                                                {cptCodeFilter === code ? (
                                                    <CheckPrimaryColorIcon />
                                                ) : null}
                                            </DropdownItem>
                                        ))}
                                    </DropdownContent>
                                </Dropdown>
                                {canViewProvidersFilter && (
                                    <Popover
                                        open={showProviderListPopover}
                                        onOpenChange={(open) => {
                                            setShowProviderListPopover(open);
                                            handleResetProvidersSearchString();
                                        }}
                                    >
                                        <PopoverTrigger
                                            asChild
                                            onClick={() =>
                                                setShowProviderListPopover(true)
                                            }
                                        >
                                            <FilterButton
                                                classNames={
                                                    providerNameFilter
                                                        ? "capitalize"
                                                        : "normal-case"
                                                }
                                                text={
                                                    providerNameFilter
                                                        ? truncateString(
                                                              providerNameFilter
                                                          )
                                                        : "Provider"
                                                }
                                            />
                                        </PopoverTrigger>
                                        <PopoverContent
                                            className="px-4 pt-[3px] py-[3px] w-[316px] rounded-r8 border-none shadow-[0px_0px_0px_1px_#98a1b219,_0px_15px_35px_-5px_#11182626,_0px_5px_15px_0px_#00000014]"
                                            align="start"
                                        >
                                            <SearchInput
                                                placeholder="Search by provider name"
                                                containerClass="h-24"
                                                onChange={(e) =>
                                                    setProviderNameSearchString(
                                                        e.target.value
                                                    )
                                                }
                                            />

                                            <div className="flex flex-col items-start max-h-[230px] overflow-y-auto">
                                                {providers.isLoading && (
                                                    <div className="flex flex-col gap-y-4 items-stretch w-full">
                                                        {[1, 2, 3, 4, 5].map(
                                                            (item) => (
                                                                <Skeleton
                                                                    key={item}
                                                                    width="100%"
                                                                    height="24px"
                                                                    containerTestId="providers-loader"
                                                                />
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                                {providers.error && (
                                                    <ListState
                                                        isError
                                                        context="general"
                                                        stateHelperText="Please try again later"
                                                        errorMsg={
                                                            providers.error
                                                                ?.response?.data
                                                                ?.message ||
                                                            "Error loading providers"
                                                        }
                                                    />
                                                )}
                                                {providers.data?.data &&
                                                    providers.isSuccess && (
                                                        <>
                                                            <Button
                                                                size="auto"
                                                                variant="normal"
                                                                className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start !rounded-none"
                                                                onClick={() => {
                                                                    handleSetProviderNameFilter(
                                                                        ""
                                                                    );
                                                                    setShowProviderListPopover(
                                                                        false
                                                                    );
                                                                    handleResetProvidersSearchString();
                                                                }}
                                                            >
                                                                All
                                                            </Button>
                                                            {providers?.data?.data.map(
                                                                (provider) => (
                                                                    <Button
                                                                        key={
                                                                            provider.provider_id
                                                                        }
                                                                        size="auto"
                                                                        variant="normal"
                                                                        className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start gap-x-8 !rounded-none"
                                                                        onClick={() => {
                                                                            handleSetProviderNameFilter(
                                                                                `${provider.provider_name.toLowerCase()}`
                                                                            );
                                                                            setShowProviderListPopover(
                                                                                false
                                                                            );
                                                                            handleResetProvidersSearchString();
                                                                        }}
                                                                    >
                                                                        {
                                                                            provider.provider_name
                                                                        }
                                                                        {providerNameFilter.toLowerCase() ===
                                                                        provider.provider_name.toLowerCase() ? (
                                                                            <CheckPrimaryColorIcon />
                                                                        ) : null}
                                                                    </Button>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                            </div>
                                        </PopoverContent>
                                    </Popover>
                                )}
                                {canViewProvidersFilter && (
                                    <Popover
                                        open={showAuthorPopover}
                                        onOpenChange={(open) => {
                                            setShowAuthorPopover(open);
                                            handleResetProvidersSearchString();
                                        }}
                                    >
                                        <PopoverTrigger
                                            asChild
                                            onClick={() =>
                                                setShowAuthorPopover(true)
                                            }
                                        >
                                            <FilterButton
                                                classNames={
                                                    authorFilter
                                                        ? "capitalize"
                                                        : "normal-case"
                                                }
                                                text={
                                                    getAuthorTextToDisplay
                                                        ? truncateString(
                                                              getAuthorTextToDisplay
                                                          )
                                                        : "Author"
                                                }
                                            />
                                        </PopoverTrigger>
                                        <PopoverContent
                                            className="px-4 pt-[3px] py-[3px] w-[316px] rounded-r8 border-none shadow-[0px_0px_0px_1px_#98a1b219,_0px_15px_35px_-5px_#11182626,_0px_5px_15px_0px_#00000014]"
                                            align="start"
                                        >
                                            <SearchInput
                                                placeholder="Search by name"
                                                containerClass="h-24"
                                                onChange={(e) =>
                                                    setAuthorSearchString(
                                                        e.target.value
                                                    )
                                                }
                                            />

                                            <div className="flex flex-col items-start max-h-[230px] overflow-y-auto">
                                                {providers.isLoading && (
                                                    <div className="flex flex-col gap-y-4 items-stretch w-full">
                                                        {[1, 2, 3, 4, 5].map(
                                                            (item) => (
                                                                <Skeleton
                                                                    key={item}
                                                                    width="100%"
                                                                    height="24px"
                                                                    containerTestId="providers-loader"
                                                                />
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                                {providers.error && (
                                                    <ListState
                                                        isError
                                                        context="general"
                                                        stateHelperText="Please try again later"
                                                        errorMsg={
                                                            providers.error
                                                                ?.response?.data
                                                                ?.message ||
                                                            "Error loading authors"
                                                        }
                                                    />
                                                )}
                                                {providers.data?.data &&
                                                    providers.isSuccess && (
                                                        <>
                                                            <Button
                                                                size="auto"
                                                                variant="normal"
                                                                className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start !rounded-none"
                                                                onClick={() => {
                                                                    handleSetAuthorFilter(
                                                                        ""
                                                                    );
                                                                    setShowAuthorPopover(
                                                                        false
                                                                    );
                                                                    handleResetProvidersSearchString();
                                                                }}
                                                            >
                                                                All
                                                            </Button>
                                                            {providers?.data?.data.map(
                                                                (provider) => (
                                                                    <Button
                                                                        key={
                                                                            provider.provider_id
                                                                        }
                                                                        size="auto"
                                                                        variant="normal"
                                                                        className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start gap-x-8 !rounded-none"
                                                                        onClick={() => {
                                                                            handleSetAuthorFilter(
                                                                                provider.user_id
                                                                            );
                                                                            setShowAuthorPopover(
                                                                                false
                                                                            );
                                                                            handleResetProvidersSearchString();
                                                                        }}
                                                                    >
                                                                        {
                                                                            provider.provider_name
                                                                        }
                                                                        {authorFilter ===
                                                                        provider.user_id ? (
                                                                            <CheckPrimaryColorIcon />
                                                                        ) : null}
                                                                    </Button>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                            </div>
                                        </PopoverContent>
                                    </Popover>
                                )}
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <FilterButton
                                            classNames="normal-case"
                                            text={getArchivedNoteFilterToDisplay()}
                                        />
                                    </DropdownTrigger>
                                    <DropdownContent>
                                        <DropdownItem
                                            className={cn(
                                                "flex gap-x-8 items-center text-xs font-medium",
                                                {
                                                    "text-primary":
                                                        showArchivedFilter ===
                                                        "true",
                                                }
                                            )}
                                            onClick={() =>
                                                handleSetArchivedNotesFilter(
                                                    "true"
                                                )
                                            }
                                        >
                                            Archived notes
                                            {showArchivedFilter === "true" ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                        <DropdownItem
                                            className={cn(
                                                "flex gap-x-8 items-center text-xs font-medium",
                                                {
                                                    "text-primary":
                                                        showArchivedFilter ===
                                                        "false",
                                                }
                                            )}
                                            onClick={() =>
                                                handleSetArchivedNotesFilter(
                                                    "false"
                                                )
                                            }
                                        >
                                            Unarchived notes
                                            {showArchivedFilter === "false" ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                    </DropdownContent>
                                </Dropdown>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <FilterButton text="Date Created" />
                                    </PopoverTrigger>
                                    <PopoverContent
                                        align="end"
                                        className="p-16 gap-y-12"
                                    >
                                        <DatePicker
                                            label="From"
                                            placeholderText="MM/DD/YYYY"
                                            selected={
                                                fromDateCreatedFilterToDate
                                            }
                                            onChange={
                                                handleFromDateCreatedFilterChange
                                            }
                                            maxDate={
                                                toDateCreatedFilterToDate ||
                                                new Date(Date.now())
                                            }
                                            isClearable
                                        />
                                        <DatePicker
                                            label="To"
                                            placeholderText="MM/DD/YYYY"
                                            selected={toDateCreatedFilterToDate}
                                            onChange={
                                                handleToDateCreatedFilterChange
                                            }
                                            minDate={
                                                fromDateCreatedFilterToDate
                                            }
                                            isClearable
                                        />
                                    </PopoverContent>
                                </Popover>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <FilterButton text="Date Of Service" />
                                    </PopoverTrigger>
                                    <PopoverContent
                                        align="end"
                                        className="p-16 gap-y-12"
                                    >
                                        <DatePicker
                                            label="From"
                                            placeholderText="MM/DD/YYYY"
                                            selected={fromDateFilterToDate}
                                            onChange={
                                                handleFromDateFilterChange
                                            }
                                            maxDate={
                                                toDateFilterToDate ||
                                                new Date(Date.now())
                                            }
                                            isClearable
                                        />
                                        <DatePicker
                                            label="To"
                                            placeholderText="MM/DD/YYYY"
                                            selected={toDateFilterToDate}
                                            onChange={handleToDateFilterChange}
                                            minDate={fromDateFilterToDate}
                                            isClearable
                                        />
                                    </PopoverContent>
                                </Popover>
                            </div>
                            <div className="w-full min-[500px]:w-auto">
                                {hideCreateButtonWithRole() && canWriteNote && (
                                    <Dropdown>
                                        <DropdownTrigger asChild>
                                            <FilterButton
                                                classNames="bg-primary normal-case text-white *:stroke-white w-full"
                                                text="Create note"
                                                stroke={APP_COLORS.COLOR_WHITE}
                                            />
                                        </DropdownTrigger>
                                        <DropdownContent
                                            align="end"
                                            width={
                                                screenIsGreaterThan500
                                                    ? "auto"
                                                    : undefined
                                            }
                                            maxHeight={
                                                screenIsGreaterThan500
                                                    ? 216
                                                    : "auto"
                                            }
                                            className="overflow-y-auto"
                                        >
                                            {Object.values(NoteTypes)
                                                .sort((a, b) =>
                                                    a.localeCompare(b)
                                                )
                                                .map((noteType) => (
                                                    <DropdownItem
                                                        key={noteType}
                                                    >
                                                        <Link
                                                            to={`/add-note?note_type=${noteType}`}
                                                        >
                                                            {
                                                                NoteLabels[
                                                                    noteType
                                                                ]
                                                            }
                                                        </Link>
                                                    </DropdownItem>
                                                ))}
                                        </DropdownContent>
                                    </Dropdown>
                                )}
                            </div>
                        </div>
                    </div>
                    <TableFiltersDisplay
                        appliedFilters={[
                            {
                                key: "status[]",
                                values: statusFilter,
                            },
                            {
                                key: "type[]",
                                values: noteTypeFilter.map(
                                    (type) => NoteLabels[type]
                                ),
                            },
                        ]}
                        wrapperClass="mt-12"
                    />
                </div>

                {isLoading ? (
                    <Skeleton
                        type="table"
                        containerTestId="list-loader"
                        count={8}
                    />
                ) : null}
                {error && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            error?.response?.data.message ||
                            `Cannot display notes list at this time please try again later`
                        }
                    />
                )}
                {data && isSuccess && data.data.length === 0 && (
                    <ListState
                        stateHelperText="Notes list will appear here once they are added"
                        isError={false}
                        emptyMessage="No note added yet"
                    />
                )}
                {isSuccess && data && data.data.length > 0 && (
                    <>
                        {getTotalAmountOfTableRowsSelected(rowSelection)
                            .amount > 0 && (
                            <div className={styles.selected}>
                                <div className={styles.selected_count}>
                                    {
                                        getTotalAmountOfTableRowsSelected(
                                            rowSelection
                                        ).amountString
                                    }
                                    :
                                </div>

                                {detailsArray?.every((note: INote) =>
                                    noteTypesForExport.includes(note?.note_type)
                                ) &&
                                    detailsArray?.every(
                                        (note: INote) =>
                                            note?.status !== NoteStatus.DRAFT
                                    ) &&
                                    isSuperAdmin && (
                                        <Button
                                            variant="secondary"
                                            onClick={() => handleExportNotes()}
                                        >
                                            Export to pdf
                                        </Button>
                                    )}

                                {showActions &&
                                    detailsArray?.every(
                                        (note: INote) =>
                                            note?.status ===
                                                NoteStatus.PENDING_CODE_REVIEW &&
                                            note?.note_type !==
                                                NoteTypes.CANCELLATION_NOTE
                                    ) && (
                                        <Button
                                            variant="secondary"
                                            onClick={() =>
                                                handleDialogInView(
                                                    "change-code"
                                                )
                                            }
                                            disabled={confirmCodes.isLoading}
                                        >
                                            Change code
                                        </Button>
                                    )}

                                {showActions &&
                                    detailsArray?.every(
                                        (note: INote) =>
                                            note?.status ===
                                            NoteStatus.PENDING_CODE_REVIEW
                                    ) && (
                                        <Button
                                            variant="secondary"
                                            onClick={() => handleConfirmCodes()}
                                            disabled={confirmCodes.isLoading}
                                        >
                                            Confirm code
                                        </Button>
                                    )}
                                {detailsArray?.every(
                                    (note: INote) =>
                                        note?.status ===
                                        NoteStatus.PENDING_SUPERVISOR_REVIEW
                                ) &&
                                    canApproveNote &&
                                    practice?.provider_id && (
                                        <Button
                                            variant="secondary"
                                            onClick={handleApproveActionClick}
                                        >
                                            Approve and sign
                                        </Button>
                                    )}

                                {canArchiveNote() &&
                                    detailsArray?.every(
                                        (note: INote) =>
                                            note?.is_archived === false
                                    ) &&
                                    detailsArray?.every(
                                        (note: INote) =>
                                            note?.status !== NoteStatus.DRAFT
                                    ) && (
                                        <Button
                                            variant="secondary"
                                            onClick={() =>
                                                handleDialogInView(
                                                    "archive-note"
                                                )
                                            }
                                        >
                                            Archive note
                                        </Button>
                                    )}

                                {canArchiveNote() &&
                                    detailsArray?.every(
                                        (note: INote) => note?.is_archived
                                    ) && (
                                        <Button
                                            variant="secondary"
                                            onClick={() => handleUnarchive()}
                                        >
                                            Unarchive note
                                        </Button>
                                    )}

                                {showBulkFlagNotesBtn && (
                                    <Button
                                        variant="secondary"
                                        onClick={() =>
                                            handleDialogInView("flag_note")
                                        }
                                    >
                                        Flag for audit
                                    </Button>
                                )}
                                {practice?.permissions?.includes(
                                    UserPermisions.CLIENT_INFO_NOTES_FLAG_FOR_AUDIT_WRITE
                                ) &&
                                    detailsArray?.every(
                                        (note: INote) =>
                                            note?.flagged_for_audit_status ===
                                            FlaggedNoteForAuditStatus.FLAGGED
                                    ) && (
                                        <Button
                                            variant="secondary"
                                            onClick={() =>
                                                handleDialogInView(
                                                    "unflag_note"
                                                )
                                            }
                                        >
                                            Unflag note
                                        </Button>
                                    )}

                                {detailsArray?.every(
                                    (note: INote) =>
                                        note?.status === NoteStatus.DRAFT
                                ) && (
                                    <Button
                                        className="bg-danger text-white hover:bg-danger"
                                        onClick={() =>
                                            handleDialogInView(
                                                "delete-confirmation"
                                            )
                                        }
                                    >
                                        Delete note
                                    </Button>
                                )}
                            </div>
                        )}
                        {selectedNotesId.length > 0 && (
                            <div className="mb-16 flex items-center gap-x-8 text-sm">
                                <div>
                                    {`${selectedNotesId.length}  selected`} :
                                </div>
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <FilterButton
                                            classNames="bg-primary normal-case text-white *:stroke-white"
                                            text="Actions"
                                            stroke={APP_COLORS.COLOR_WHITE}
                                        />
                                    </DropdownTrigger>
                                    <DropdownContent width="auto">
                                        <div
                                            className={
                                                styles.action_dropdown_items
                                            }
                                        >
                                            {detailsArray?.every(
                                                (note: INote) =>
                                                    noteTypesForExport.includes(
                                                        note?.note_type
                                                    )
                                            ) &&
                                                isSuperAdmin && (
                                                    <DropdownItem
                                                        onClick={() =>
                                                            handleExportNotes()
                                                        }
                                                    >
                                                        Export to pdf
                                                    </DropdownItem>
                                                )}
                                            {detailsArray?.every(
                                                (note: INote) =>
                                                    note?.status ===
                                                    NoteStatus.DRAFT
                                            ) && (
                                                <DropdownItem
                                                    onClick={() =>
                                                        handleDialogInView(
                                                            "delete-confirmation"
                                                        )
                                                    }
                                                >
                                                    Delete note
                                                </DropdownItem>
                                            )}
                                            {showActions &&
                                                detailsArray.every(
                                                    (note: INote) =>
                                                        note.status ===
                                                        NoteStatus.PENDING_CODE_REVIEW
                                                ) && (
                                                    <>
                                                        <DropdownItem
                                                            onClick={() => {
                                                                handleConfirmCodes();
                                                            }}
                                                            disabled={
                                                                confirmCodes.isLoading
                                                            }
                                                        >
                                                            Confirm Code
                                                        </DropdownItem>
                                                        {detailsArray.every(
                                                            (note: INote) =>
                                                                note?.note_type !==
                                                                NoteTypes.CANCELLATION_NOTE
                                                        ) && (
                                                            <DropdownItem
                                                                onClick={() => {
                                                                    handleDialogInView(
                                                                        "change-code"
                                                                    );
                                                                }}
                                                                disabled={
                                                                    confirmCodes.isLoading
                                                                }
                                                            >
                                                                Change Code
                                                            </DropdownItem>
                                                        )}
                                                    </>
                                                )}
                                            {detailsArray?.every(
                                                (note: INote) =>
                                                    note?.status ===
                                                    NoteStatus.PENDING_SUPERVISOR_REVIEW
                                            ) &&
                                                canApproveNote &&
                                                practice?.provider_id && (
                                                    <DropdownItem
                                                        onClick={
                                                            handleApproveActionClick
                                                        }
                                                    >
                                                        Approve and sign
                                                    </DropdownItem>
                                                )}
                                            {canArchiveNote() &&
                                                detailsArray?.every(
                                                    (note: INote) =>
                                                        note?.is_archived ===
                                                        false
                                                ) &&
                                                detailsArray?.every(
                                                    (note: INote) =>
                                                        note?.status !==
                                                        NoteStatus.DRAFT
                                                ) && (
                                                    <DropdownItem
                                                        onClick={() =>
                                                            handleDialogInView(
                                                                "archive-note"
                                                            )
                                                        }
                                                    >
                                                        Archive note
                                                    </DropdownItem>
                                                )}
                                            {showBulkFlagNotesBtn && (
                                                <DropdownItem
                                                    onClick={() =>
                                                        handleDialogInView(
                                                            "flag_note"
                                                        )
                                                    }
                                                >
                                                    Flag for audit
                                                </DropdownItem>
                                            )}
                                            {practice?.permissions?.includes(
                                                UserPermisions.CLIENT_INFO_NOTES_FLAG_FOR_AUDIT_WRITE
                                            ) &&
                                                detailsArray?.every(
                                                    (note: INote) =>
                                                        note?.flagged_for_audit_status ===
                                                        FlaggedNoteForAuditStatus.FLAGGED
                                                ) && (
                                                    <DropdownItem
                                                        onClick={() =>
                                                            handleDialogInView(
                                                                "unflag_note"
                                                            )
                                                        }
                                                    >
                                                        Unflag note
                                                    </DropdownItem>
                                                )}
                                            {canArchiveNote() &&
                                                detailsArray?.every(
                                                    (note: INote) =>
                                                        note?.is_archived
                                                ) && (
                                                    <DropdownItem
                                                        onClick={() =>
                                                            handleUnarchive()
                                                        }
                                                    >
                                                        Unarchive note
                                                    </DropdownItem>
                                                )}
                                        </div>
                                    </DropdownContent>
                                </Dropdown>
                            </div>
                        )}
                        <div className={styles.list_web}>
                            <Table
                                columns={columns}
                                data={data.data}
                                rowSelection={rowSelection}
                                setRowSelection={setRowSelection}
                                handleRowClick={handleRowClick}
                                isColumnVisible={{
                                    provider_name: !(
                                        practice?.provider_id && !viewAll
                                    ),
                                    status: !(tabInView === "flagged_notes"),
                                }}
                                setGetSelectedData={setGetSelectedData}
                                className={styles.table}
                                setSortConfig={setSortConfig}
                                sortConfig={sortConfig}
                                sortableColumns={sortableColumns}
                                hasPagination={data.total_count > 20}
                                pagination={
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handleSetPageFilter}
                                        limit={limitFilter}
                                        onLimitChange={handleSetLimitFilter}
                                    />
                                }
                            />
                        </div>
                        <div
                            role="table"
                            ref={mobileListRef}
                            className="block md:hidden"
                        >
                            <div className="flex flex-col gap-y-12">
                                {data.data.map((note) => (
                                    <MobileListItem
                                        key={note.note_id}
                                        onClickCheckButton={() =>
                                            toggleNoteSelection(note.note_id)
                                        }
                                        isSelected={selectedNotesId.includes(
                                            note.note_id
                                        )}
                                        showCheckButton
                                        topChildren={
                                            <div className="flex items-center gap-x-8 text-base font-semibold capitalize">
                                                {`${note.client.first_name} ${note.client.last_name}`}
                                                {tabInView !==
                                                    "flagged_notes" && (
                                                    <span>
                                                        {getNoteStatusTag(
                                                            note.status
                                                        )}
                                                    </span>
                                                )}
                                            </div>
                                        }
                                        bottomChildren={
                                            <Button
                                                variant="normal"
                                                size="auto"
                                                className="flex flex-col gap-y-8 w-full items-start"
                                                onClick={() =>
                                                    handleMobileNoteClick(note)
                                                }
                                            >
                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <span className="basis-1/2 text-gray font-semibold text-xs">
                                                        {formatDate(
                                                            note.date_of_service
                                                        )}
                                                    </span>
                                                    <span className="basis-1/2 font-semibold text-xs">
                                                        {
                                                            NoteLabels[
                                                                note.note_type
                                                            ]
                                                        }
                                                    </span>
                                                </div>
                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <span className="w-1/2 text-gray font-semibold text-xs">
                                                        Provider:
                                                    </span>
                                                    <span className="w-1/2 font-semibold text-xs">
                                                        {`${note.provider?.first_name} ${note.provider?.last_name}`}
                                                    </span>
                                                </div>
                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <span className="w-1/2 text-gray font-semibold text-xs">
                                                        Created on:
                                                    </span>
                                                    <span className="w-1/2 font-semibold text-xs">
                                                        {formatDate(
                                                            note.created_at
                                                        )}
                                                    </span>
                                                </div>
                                                {!practice?.provider_id && (
                                                    <div className="flex items-center w-full text-left gap-x-4">
                                                        <span className="w-1/2 text-gray font-semibold text-xs">
                                                            Insurance Provider:
                                                        </span>
                                                        <span
                                                            className={
                                                                note.client
                                                                    .self_pay
                                                                    ? "normal-case w-1/2 font-semibold text-xs"
                                                                    : "uppercase w-1/2 font-semibold text-xs"
                                                            }
                                                        >
                                                            {note.client
                                                                .self_pay &&
                                                            !showInsOverSelfPay
                                                                ? "Self pay"
                                                                : (note.client
                                                                      .insurance_provider_name &&
                                                                      removeEnumUnderscore(
                                                                          note
                                                                              .client
                                                                              .insurance_provider_name ||
                                                                              ""
                                                                      )) ||
                                                                  "--"}
                                                        </span>
                                                    </div>
                                                )}
                                            </Button>
                                        }
                                    />
                                ))}
                            </div>
                            {data.total_count > 20 && (
                                <div className="bg-white p-12 rounded-r8 mt-16">
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handleSetPageFilter}
                                        limit={limitFilter}
                                        onLimitChange={handleSetLimitFilter}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

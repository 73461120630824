import React from "react";
import { SvgProps } from "../../utils/types";

export default function ArrowDownIcon({ className, fill }: SvgProps) {
    return (
        <svg
            width="8"
            height="5"
            viewBox="0 0 8 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M4 4.44444L0 0.277771H8L4 4.44444Z"
                fill={fill || "#0B132B"}
            />
        </svg>
    );
}

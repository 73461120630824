import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import axios from "../../../utils/axios";
import {
    IAddPayment,
    IAddPaymentToMatch,
    IBillingSummaryFilters,
    IBillingFilterTypes,
    IGetPayment,
    IRefreshPaymentStatus,
    IUpdateInvoice,
    IAddBulkInvoicePayment,
} from "../types/billing.types";

// Endpoint to fetch notes list
export async function fetchBillingQueue(filters?: IBillingFilterTypes) {
    const response = await axios.get(`/invoices`, {
        params: handleRemoveEmptyParamFromQueryParams({
            ...filters,
        }),
    });
    return response.data;
}

// Endpoint to update invoice status
export async function patchInvoiceStatus(payload: IUpdateInvoice) {
    const response = await axios.patch(`/invoice`, payload);

    return response.data;
}

// Endpoint to refresh payment status
export async function refreshPaymentStatus(payload: IRefreshPaymentStatus) {
    const response = await axios.patch(`/invoice/payment_update`, payload);

    return response.data;
}
// Endpoint to match payment
export async function matchPayment(payload: IAddPaymentToMatch) {
    const response = await axios.post(`/invoice/match_payments`, payload);

    return response.data;
}

// Endpoint to add invoice payment
export async function addInvoicePayment(
    invoiceId: string,
    payload: IAddPayment
) {
    const response = await axios.post(`/invoice/${invoiceId}/payment`, payload);
    return response.data;
}

// Endpoint to get invoice payment
export async function getInvoicePayment(
    invoiceId: string,
    payload: IGetPayment
) {
    const response = await axios.get(`/invoice/${invoiceId}/payments`, {
        params: payload,
    });

    return response.data;
}

// function to export aging report to spreadsheet
export async function exportBillingSummaryReport(
    filters: IBillingSummaryFilters
) {
    const response = await axios.get("/report/billing-summary", {
        params: handleRemoveEmptyParamFromQueryParams(filters),
        responseType: "blob",
    });

    return response.data;
}

export async function addBulkInvoicePayment(payload: IAddBulkInvoicePayment) {
    const response = await axios.post("/invoice/bulk-add-payments", payload);

    return response.data;
}

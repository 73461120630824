import axios from "../../../utils/axios";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    IProviderSessionStatsFilters,
    ISendStatementOfAccount,
    ISessionActivityFilters,
    IStatementOfAccountFilters,
} from "../types/reports.types";

// Endpoint to get the statement of account for clients
export async function getStatementOfAccount(
    filters: IStatementOfAccountFilters
) {
    const response = await axios.get(`report/client/account-statement`, {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });
    return response.data;
}

// Endpoint to send statement of account via email
export async function sendStatementOfAccount(
    payload: ISendStatementOfAccount,
    filters: IStatementOfAccountFilters
) {
    const response = await axios.post(
        `report/client/account-statement`,
        payload,
        {
            params: handleRemoveEmptyParamFromQueryParams(filters),
        }
    );
    return response.data;
}

// Endpoint to download the statement of account pdf
export async function downloadStatementOfAccount(
    pdfType: string,
    exportJobId: string
) {
    await axios
        .get(`/pdf/export/${pdfType}/${exportJobId}`, {
            responseType: "blob",
        })
        .then((res) => {
            const href = window.URL.createObjectURL(res.data);

            const anchorElement = document.createElement("a");
            anchorElement.href = href;

            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        });
}

// Endpoint to get provider session activity report
export async function fetchProviderSessionActivityReport(
    filters: ISessionActivityFilters
) {
    const response = await axios.get("/report/provider/session-activity", {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });
    return response.data;
}

export async function fetchProviderSessionStats(
    providerId: string,
    filters: IProviderSessionStatsFilters
) {
    const response = await axios.get(
        `/report/provider/${providerId}/session-activity`,
        {
            params: handleRemoveEmptyParamFromQueryParams(filters),
        }
    );

    return response.data;
}

// Endpoint to generate spreadsheet for provider session activity report
export async function exportProviderSessionActivityReport(
    filters: ISessionActivityFilters
) {
    const response = await axios.get(
        "/report/provider/session-activity/export",
        {
            params: handleRemoveEmptyParamFromQueryParams(filters),
            responseType: "blob",
        }
    );

    return response.data;
}

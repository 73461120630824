import * as React from "react";
import { Card } from "@jhool-io/fe-components";
import { Link, useSearchParams } from "react-router-dom";
import StatsCardVector from "../../../../components/Icons/StatsCardVector";
import { useFetchSessionSummary } from "../../hooks/dashboard.queries";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import usePractice from "../../../../hooks/usePractice";
import { UserPermisions } from "../../../../utils/types/user";
import { cn } from "../../../../utils/helpers";

export default function StatCards() {
    const [searchParams] = useSearchParams();

    const { practice } = usePractice();

    const { data, error, isLoading } = useFetchSessionSummary(
        practice?.provider_id as string,
        searchParams.get("date") || "this_week",
        Boolean(practice?.provider_id)
    );

    const getErrorAndLoadingStates = () => {
        if (error) {
            return "0";
        }

        if (isLoading) {
            return (
                <Skeleton
                    containerTestId="summary-loader"
                    height={20}
                    width={20}
                />
            );
        }

        return "";
    };

    const canSeeFlaggedNotes = practice?.permissions?.includes(
        UserPermisions.CLIENT_INFO_NOTES_FLAG_FOR_AUDIT_WRITE
    );

    return (
        <div
            className={cn(
                "grid grid-cols-[repeat(3,minmax(320px,1fr))] overflow-x-auto overflow-y-hidden gap-x-12",
                {
                    "grid-cols-[repeat(5,minmax(320px,1fr))]":
                        canSeeFlaggedNotes,
                }
            )}
        >
            {canSeeFlaggedNotes && (
                <Card classNames="bg-[#F3F9FE] h-[104px] relative p-24 border border-[#cce7ed]">
                    <div className="absolute h-[39px] w-[1.2px] -left-px top-2/4 -translate-y-2/4 bg-[#059]" />
                    <div className="flex justify-between items-start">
                        <Link
                            to="/notes/flagged-notes"
                            className="flex flex-col gap-y-8"
                        >
                            <div className="flex gap-x-8 font-medium capitalize text-xs">
                                NOTES FLAGGED FOR AUDIT
                            </div>
                            <div className="font-bold text-xxl">
                                {getErrorAndLoadingStates() ||
                                    data?.data.no_of_notes_flagged_for_audit}
                            </div>
                        </Link>
                        <StatsCardVector />
                    </div>
                </Card>
            )}

            {canSeeFlaggedNotes && (
                <Card classNames="bg-[[#ECFBFF] h-[104px] relative p-24 border border-[#cce7ed]">
                    <div className="absolute h-[39px] w-[1.2px] -left-px top-2/4 -translate-y-2/4 bg-[#1A738D]" />
                    <div className="flex justify-between items-start">
                        <Link
                            to="/notes/flagged-notes"
                            className="flex flex-col gap-y-8"
                        >
                            <div className="flex gap-x-8 font-medium capitalize text-xs whitespace-nowrap">
                                FLAGGED NOTES THAT REQUIRE ACTIONS
                            </div>
                            <div className="font-bold text-xxl">
                                {getErrorAndLoadingStates() ||
                                    data?.data
                                        .no_of_notes_flagged_for_audit_that_requires_action}
                            </div>
                        </Link>
                        <StatsCardVector />
                    </div>
                </Card>
            )}

            <Card classNames="bg-[#FFF6FB] h-[104px] relative p-24 border border-[#bc2c7c1e]">
                <div className="absolute h-[39px] w-[1.2px] -left-px top-2/4 -translate-y-2/4 bg-[#BC2C7C]" />
                <div className="flex justify-between items-start">
                    <div className="flex flex-col gap-y-8">
                        <div className="flex gap-x-8 font-medium capitalize text-xs">
                            SESSIONS SCHEDULED
                        </div>
                        <div className="font-bold text-xxl">
                            {getErrorAndLoadingStates() ||
                                data?.data.no_of_sessions_scheduled}
                        </div>
                    </div>
                    <StatsCardVector />
                </div>
            </Card>
            <Card classNames="bg-[#FFF5F4] h-[104px] relative p-24 border border-[#e782641e]">
                <div className="absolute h-[39px] w-[1.2px] -left-px top-2/4 -translate-y-2/4 bg-[#E78264]" />
                <div className="flex justify-between items-start">
                    <div className="flex flex-col gap-y-8">
                        <div className="flex gap-x-8 font-medium capitalize text-xs">
                            COMPLETED NOTES
                        </div>
                        <div className="font-bold text-xxl">
                            {getErrorAndLoadingStates() ||
                                data?.data.no_of_notes_completed}
                        </div>
                    </div>
                    <StatsCardVector />
                </div>
            </Card>
            <Card classNames="bg-[#EEF9FD] h-[104px] relative p-24 border border-[#0974461e]">
                <div className="absolute h-[39px] w-[1.2px] -left-px top-2/4 -translate-y-2/4 bg-[#1A738D]" />
                <div className="flex justify-between items-start">
                    <div className="flex flex-col gap-y-8">
                        <div className="flex gap-x-8 font-medium capitalize text-xs">
                            LATE NOTES
                        </div>
                        <div className="font-bold text-xxl">
                            {getErrorAndLoadingStates() ||
                                data?.data.no_of_late_notes}
                        </div>
                    </div>
                    <StatsCardVector />
                </div>
            </Card>
        </div>
    );
}

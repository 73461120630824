import * as React from "react";
import { useLocation } from "react-router-dom";
import {
    IPinnedClient,
    PinModule,
    PinnedClientsContext,
} from "./PinnedClientsContext";
import useToast from "../../hooks/useToast";
import { useAppSelector } from "../../hooks/useRedux";

interface PinnedClientsProviderProps {
    children: React.ReactNode;
}

export default function PinnedClientsProvider({
    children,
}: PinnedClientsProviderProps) {
    const { practice } = useAppSelector((state) => state.userPractice);

    const pinnedFromLocalStorageKey = `pinned_clients_${practice?.practice_id}`;

    const previousPins = localStorage.getItem(pinnedFromLocalStorageKey);

    const pinnedFromLocalStorage: IPinnedClient[] = previousPins
        ? JSON.parse(previousPins)
        : [];

    const [pinnedClients, setPinnedClients] = React.useState<IPinnedClient[]>(
        () => pinnedFromLocalStorage
    );

    const location = useLocation();

    const { toast } = useToast();

    const userCurrentLocation = `${location.pathname}${location.search || ""}`;

    const memoizedValues = React.useMemo(() => {
        const handlePinClient = (params: {
            clientId: string;
            clientName: string;
            pinModule: PinModule;
        }) => {
            const newPin = {
                client_name: params.clientName,
                client_id: params.clientId,
                pin_location: userCurrentLocation,
                pin_module: params.pinModule,
                pinned_practice_id: practice?.practice_id,
            };

            if (pinnedClients.length === 5) {
                toast({
                    mode: "error",
                    message:
                        "You can only pin up to 5 clients at any given time.",
                    title: "Pin limit reached",
                });
            } else {
                const pinnedArray = [newPin, ...pinnedClients];
                setPinnedClients(pinnedArray);
                localStorage.setItem(
                    pinnedFromLocalStorageKey,
                    JSON.stringify(pinnedArray)
                );
                toast({
                    mode: "success",
                    message: `${params.clientName} pinned successfully`,
                    title: "User pinned",
                });
            }
        };

        const isClientPinnedInLocation = (
            clientId: string,
            module: PinModule
        ) => {
            if (pinnedClients.length === 0) return false;

            return Boolean(
                pinnedClients.find((client: IPinnedClient) => {
                    return (
                        client.client_id === clientId &&
                        client.pin_module === module
                    );
                })
            );
        };

        const handleUnpinClient = (clientId: string, module: PinModule) => {
            const pinnedToRemove = pinnedClients.find(
                (client: IPinnedClient) =>
                    client.client_id === clientId &&
                    client.pin_module === module
            );

            const updatedPinnedArray = pinnedClients.filter(
                (client) =>
                    client.pin_module !== pinnedToRemove?.pin_module ||
                    client.client_id !== pinnedToRemove?.client_id
            );

            setPinnedClients(() => updatedPinnedArray);

            localStorage.setItem(
                pinnedFromLocalStorageKey,
                JSON.stringify(updatedPinnedArray)
            );
            toast({
                mode: "success",
                message: `${pinnedToRemove?.client_name} unpinned successfully`,
                title: "User unpinned",
            });
        };

        return {
            handlePinClient,
            isClientPinnedInLocation,
            handleUnpinClient,
            pinnedClients,
        };
    }, [
        pinnedClients,
        pinnedFromLocalStorageKey,
        practice?.practice_id,
        toast,
        userCurrentLocation,
    ]);

    return (
        <PinnedClientsContext.Provider value={memoizedValues}>
            {children}
        </PinnedClientsContext.Provider>
    );
}

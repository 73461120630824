import axios from "../../../utils/axios";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    IAddSettingChanges,
    SettingsFilters,
} from "../types/practice-settings";

// Endpoint to get practice settings
export async function fetchPracticeSettings(filters?: SettingsFilters) {
    const response = await axios.get(`/practice-settings`, {
        params: handleRemoveEmptyParamFromQueryParams(filters || {}),
    });

    return response.data;
}

export async function addSetting(payload: IAddSettingChanges) {
    const response = await axios.patch(`/practice-settings`, payload);

    return response.data;
}

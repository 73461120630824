import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IApiResponse } from "../../../utils/types/api-response";
import {
    addUser,
    changeUserStatus,
    deleteUsers,
    modifyUserRole,
    resetUserPassword,
} from "../services/user-management.services";
import {
    IChangeStatusPayload,
    IInternalUser,
    IModifyRolesPayload,
    INewUsers,
} from "../types/user-management.types";

export function useDeleteUsers() {
    interface ApiResponse {
        message: string;
    }

    return useMutation<ApiResponse, AxiosError<ApiResponse>, string[]>({
        mutationKey: ["delete-users"],
        mutationFn: (userIds: string[]) => deleteUsers(userIds),
    });
}

// Hook for adding new users
export function useAddUsers() {
    interface AddusersApiResponse<DataType> extends IApiResponse<DataType> {
        practice_domains: string[];
    }
    return useMutation<
        AddusersApiResponse<IInternalUser>,
        AxiosError<AddusersApiResponse<IInternalUser>>,
        INewUsers
    >({
        mutationKey: ["add-users"],
        mutationFn: (data: INewUsers) => addUser(data),
    });
}

export function useChangeUserStatus() {
    return useMutation<
        IApiResponse<IInternalUser>,
        AxiosError<IApiResponse<IInternalUser>>,
        IChangeStatusPayload
    >({
        mutationKey: ["update-status"],
        mutationFn: (data: IChangeStatusPayload) => changeUserStatus(data),
    });
}

export function useModifyUserRoles() {
    return useMutation<
        IApiResponse<IInternalUser>,
        AxiosError<IApiResponse<IInternalUser>>,
        IModifyRolesPayload
    >({
        mutationKey: ["modify-roles"],
        mutationFn: (data: IModifyRolesPayload) => modifyUserRole(data),
    });
}

export function useResetPassword() {
    interface ApiResponse {
        message: string;
    }
    return useMutation<void, AxiosError<ApiResponse>, string[]>({
        mutationKey: ["reset-password"],
        mutationFn: (emails: string[]) => resetUserPassword(emails),
    });
}

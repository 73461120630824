import * as React from "react";
import NoteContentItem from "../NoteContentItem/NoteContentItem";
import { IAddendumDetails } from "../../../../utils/types/notes";

interface ViewSupervisorCheckInNoteProps {
    noteContent: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    };
    fieldAddendums: {
        [key: string]: IAddendumDetails[];
    } | null;
}
export default function ViewSupervisorCheckInNote({
    noteContent,
    fieldAddendums,
}: ViewSupervisorCheckInNoteProps) {
    return (
        <div data-testid="supervisor-check-in">
            <NoteContentItem
                addendums={fieldAddendums?.update}
                headerText="General update"
                fieldId="update"
                content={noteContent?.update || "--"}
            />
        </div>
    );
}

export default function ClipboardIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.55558 2.44434H4.66669C3.68447 2.44434 2.88892 3.23989 2.88892 4.22211V12.6666C2.88892 13.6488 3.68447 14.4443 4.66669 14.4443H11.3334C12.3156 14.4443 13.1111 13.6488 13.1111 12.6666V4.22211C13.1111 3.23989 12.3156 2.44434 11.3334 2.44434H10.4445"
                stroke="#212121"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.55493 1.11133H6.44382C5.9529 1.11133 5.55493 1.5093 5.55493 2.00022V2.88911C5.55493 3.38003 5.9529 3.77799 6.44382 3.77799H9.55493C10.0459 3.77799 10.4438 3.38003 10.4438 2.88911V2.00022C10.4438 1.5093 10.0459 1.11133 9.55493 1.11133Z"
                stroke="#212121"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import { parseISO } from "date-fns";
import {
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    FormCheckbox,
    Input,
    RadioInput,
    Select,
    TextArea,
    DatePicker,
} from "@jhool-io/fe-components";
import {
    IGetClientNotes,
    INewNoteAppoinmentDetails,
    NoteTypes,
} from "../../../../utils/types/notes";
import { AppointmentLocation } from "../../../../utils/types/session";
import {
    formatDate,
    getTimeDuration,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import { useFetchDiagnosisCodes } from "../../../../hooks/queries";
import NoteFormInfoBox from "../../NoteFormInfoBox/NoteFormInfoBox";
import { useFetchClientNotesList } from "../../../../hooks/queries/note";

import LoadPreviousSessionNotificationModal from "../../LoadPreviousSessionNotificatiModal/LoadPreviousSessionNotificationModal";
import { TREATMENT_REVIEW_NOTE_LABELS } from "../../../../utils/constants";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import { ICreateTreatmentReviewNotePayload } from "../../../../modules/notes/types/notes.types";
import { useCreateNote } from "../../../../modules/notes/hooks/create-note";
import AddNoteFooter from "../../../../modules/notes/components/NoteCreationFooters/AddNoteFooter/AddNoteFooter";

interface TreatmentPlanProps {
    appointmentDetails: INewNoteAppoinmentDetails;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formValues?: { [key: string]: any };
    currentAction?: string | null;
}

type Option = {
    label: React.ReactNode;
    value: string;
};

export default function TreatmentReview({
    appointmentDetails,
    formValues,
    currentAction,
}: TreatmentPlanProps) {
    // Local component state
    const [searchValue, setSearchValue] = React.useState("");
    const [showPrompt, setShowPrompt] = React.useState(false);
    const [selectedNote, setSelectedNote] = React.useState(
        {} as IGetClientNotes
    );
    const [localExpectedTerminationDate, setLocalExpectedTerminationDate] =
        React.useState<Date | null>(
            formValues?.expected_termination_date
                ? parseISO(formValues?.expected_termination_date)
                : null
        );
    // Fetch diagonis codes
    const { data, isLoading, error } = useFetchDiagnosisCodes();

    const { handleAutoSaveNote, handleSaveAsDraftNote, handleSignNote } =
        useCreateNote(appointmentDetails);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm<ICreateTreatmentReviewNotePayload>({
        resolver: yupResolver(
            yup.object({
                duration: yup.string(),
                appointment_location: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("Session location is required"),
                    otherwise: yup.string(),
                }),
                delivery_option: yup
                    .string()
                    .nullable()
                    .when(
                        "appointment_location",
                        (appointment_location, field) =>
                            appointment_location === "teletherapy" &&
                            currentAction === "with_signature"
                                ? field.required(
                                      "Client teletherapy location is required"
                                  )
                                : field
                    ),
                provider_location: yup
                    .string()
                    .nullable()
                    .when(
                        "appointment_location",
                        (appointment_location, field) =>
                            appointment_location === "teletherapy" &&
                            currentAction === "with_signature"
                                ? field.required(
                                      "Provider teletherapy location is required"
                                  )
                                : field
                    ),
                consent_was_received_for_the_teletherapy_session: yup.bool(),
                the_teletherapy_session_was_conducted_via_video: yup.bool(),
                other_delivery_option: yup
                    .string()
                    .when("delivery_option", (delivery_option, field) =>
                        delivery_option === "Other" &&
                        currentAction === "with_signature"
                            ? field.required(
                                  "Other client teletherapy location is required"
                              )
                            : field
                    ),
                other_provider_location: yup
                    .string()
                    .when("provider_location", (provider_location, field) =>
                        provider_location === "Other" &&
                        currentAction === "with_signature"
                            ? field.required(
                                  "Other provider teletherapy location is required"
                              )
                            : field
                    ),
                isClientPresent: yup.bool(),
                diagnosis_codes: yup.array().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .array()
                        .max(3, "You can only select 3 diagnosis")
                        .min(1, "Diagnosis is required")
                        .required("Diagnosis is required"),
                    otherwise: yup
                        .array()
                        .nullable()
                        .max(3, "You can only select 3 diagnosis"),
                }),
                what_are_the_problem_you_are_seeking_help_for: yup.string(),
                anxiety_frequency: yup.string(),
                anxiety_details: yup.string(),
                panic_attacks_freqency: yup.string(),
                panic_attacks_details: yup.string(),
                depression_freqency: yup.string(),
                depression_details: yup.string(),
                selected_symptoms: yup.array().nullable(),
                other_symptoms: yup.string(),
                physical_symptoms: yup.string(),
                medication_physical_psychiatric: yup.string(),
                sleeping_habits_and_concerns: yup.string(),
                difficulties_with_appetite_or_eating_patterns: yup.string(),
                been_so_distressed_you_seriously_wished_to_end_your_life: yup
                    .string()
                    .nullable(),
                has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live:
                    yup.string().nullable(),
                describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live:
                    yup.string(),
                a_specific_plan_of_how_you_would_kill_yourself: yup
                    .string()
                    .nullable(),
                access_to_weapons_or_means_of_hurting_self: yup
                    .string()
                    .nullable(),
                made_serious_suicide_attempts: yup.string().nullable(),
                purposely_done_something_to_hurt_yourself: yup
                    .string()
                    .nullable(),
                describe_done_something_to_hurt_yourself: yup
                    .string()
                    .nullable(),
                heard_voices_telling_you_to_hurt_yourself: yup
                    .string()
                    .nullable(),
                had_relatives_who_attempted_or_commited_sucide: yup
                    .string()
                    .nullable(),
                had_thoughts_of_killing_or_seriously_hurting_someone: yup
                    .string()
                    .nullable(),
                heard_voices_telling_you_to_hurt_others: yup
                    .string()
                    .nullable(),
                hurt_someone_or_destroyed_property_on_purpose: yup
                    .string()
                    .nullable(),
                slapped_kicked_punched_someone_with_intent_to_harm: yup
                    .string()
                    .nullable(),
                been_arrested_or_detained_for_violent_behavior: yup
                    .string()
                    .nullable(),
                been_to_jail_for_any_reason: yup.string().nullable(),
                been_on_probation_for_any_reason: yup.string().nullable(),
                do_you_have_access_to_guns: yup.string().nullable(),
                patients_ability_and_capability_to_respond_to_treatment: yup
                    .array()
                    .nullable(),
                details_for_diminished_poor_limited_quality_patient_oriented_evidence:
                    yup
                        .string()
                        .when(
                            "patients_ability_and_capability_to_respond_to_treatment",
                            (
                                patients_ability_and_capability_to_respond_to_treatment: string[],
                                field
                            ) =>
                                patients_ability_and_capability_to_respond_to_treatment &&
                                patients_ability_and_capability_to_respond_to_treatment.includes(
                                    "Diminished/poor or limited-quality patient-oriented evidence"
                                )
                                    ? field.required("Details is required")
                                    : field
                        ),
                people_present_at_session: yup.array().nullable(),
                other_person_present_at_session: yup.string(),
                type_of_treatment_recommended: yup.array().nullable(),
                treatment_modality_intervention: yup.array().nullable(),
                other_treatment_modality_intervention: yup.string(),
                treatment_goal_one: yup.string(),
                treatment_goal_two: yup.string(),
                treatment_goal_three: yup.string(),
                medication_updates: yup.string(),
                frequency_of_sessions: yup.string().nullable(),
                other_frequency_of_sessions: yup
                    .string()
                    .when(
                        "frequency_of_sessions",
                        (frequency_of_sessions, field) =>
                            frequency_of_sessions === "Other" &&
                            currentAction === "with_signature"
                                ? field.required(
                                      "Other frequency of sessions is required"
                                  )
                                : field
                    ),
                expected_termination_date: yup.date().nullable(),
                plan_of_care: yup.string(),
                prognosis: yup.string(),
                subjective_report: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup
                        .string()
                        .required("Subjective report is required"),
                    otherwise: yup.string(),
                }),
                summary: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("Summary is required"),
                    otherwise: yup.string(),
                }),
                anxiety: yup.bool(),
                panic_attacks: yup.bool(),
                depression: yup.bool(),
                signature: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("Signature is required"),
                    otherwise: yup.string(),
                }),
            })
        ),
        mode: "onChange",
        defaultValues: formValues || undefined,
    });

    const diagnosisCodes = watch("diagnosis_codes");

    // Get diagnosis codes for select
    const DiagnosisCodesForSelect = data?.data.map((diagnosis) => ({
        label: `${diagnosis.code} ${diagnosis.description}`,
        value: diagnosis.code,
    }));

    // Get frequency for select
    const FrequencyForSelect = ["daily", "weekly", "monthly", "yearly"].map(
        (item) => ({
            label: makeStringFirstLetterCapital(item),
            value: item,
        })
    );

    // Check for boolean values
    const anxiety = watch("anxiety", false);
    const panicAttacks = watch("panic_attacks", false);
    const depression = watch("depression", false);

    const clientNoteParams = {
        type: appointmentDetails?.note_type as string,
        load_previous_notes: true,
        provider_id: appointmentDetails?.provider_id as string,
    };

    const { data: notes } = useFetchClientNotesList(
        appointmentDetails?.client_id as string,
        clientNoteParams
    );

    // Get appointment location options
    const AppointmentLocationsForSelect = Object.values(
        AppointmentLocation
    ).map((location) => {
        return {
            label: removeEnumUnderscore(makeStringFirstLetterCapital(location)),
            value: location,
        };
    });

    const isCheckboxChecked = (checkboxValue: string, key: string) => {
        return Boolean(
            formValues?.[key]?.find(
                (value: string) => value.trim() === checkboxValue.trim()
            )
        );
    };

    // Get filter options for diagnosis list
    const filterDiagnosisOptions = (phrases: string[]) => {
        const filteredOptions = DiagnosisCodesForSelect?.filter((option) =>
            phrases.every(
                (phrase) =>
                    option &&
                    option.label.toLowerCase().includes(phrase.toLowerCase())
            )
        );
        return filteredOptions;
    };

    // Function to handle item click and set the selected note content
    const handleLoadPreviousSession = (note: IGetClientNotes) => {
        setValue(
            "duration",
            appointmentDetails
                ? getTimeDuration(
                      formatDate(
                          appointmentDetails?.session_start_time || "",
                          false,
                          "HH:mm"
                      ),
                      formatDate(
                          appointmentDetails?.session_end_time || "",
                          false,
                          "HH:mm"
                      )
                  )
                : ""
        );
        setValue(
            "appointment_location",
            note.note_content.appointment_location
        );
        setValue("delivery_option", note.note_content.delivery_option);
        setValue("provider_location", note.note_content.provider_location);
        setValue(
            "other_provider_location",
            note.note_content.other_provider_location
        );
        setValue(
            "consent_was_received_for_the_teletherapy_session",
            note.note_content.consent_was_received_for_the_teletherapy_session
        );
        setValue(
            "the_teletherapy_session_was_conducted_via_video",
            note.note_content.the_teletherapy_session_was_conducted_via_video
        );
        setValue(
            "other_delivery_option",
            note.note_content.other_delivery_option
        );
        setValue("diagnosis_codes", note.note_content.diagnosis_codes);
        setValue(
            "what_are_the_problem_you_are_seeking_help_for",
            note.note_content.what_are_the_problem_you_are_seeking_help_for
        );
        setValue("anxiety_frequency", note.note_content.anxiety_frequency);
        setValue("anxiety_details", note.note_content.anxiety_details);
        setValue(
            "panic_attacks_freqency",
            note.note_content.panic_attacks_freqency
        );
        setValue(
            "panic_attacks_details",
            note.note_content.panic_attacks_details
        );
        setValue("depression_freqency", note.note_content.depression_freqency);
        setValue("depression_details", note.note_content.depression_details);
        setValue("selected_symptoms", note.note_content.selected_symptoms);
        setValue("other_symptoms", note.note_content.other_symptoms);
        setValue("physical_symptoms", note.note_content.physical_symptoms);
        setValue(
            "medication_physical_psychiatric",
            note.note_content.medication_physical_psychiatric
        );
        setValue("isClientPresent", note.note_content.isClientPresent);
        setValue(
            "sleeping_habits_and_concerns",
            note.note_content.sleeping_habits_and_concerns
        );
        setValue(
            "difficulties_with_appetite_or_eating_patterns",
            note.note_content.difficulties_with_appetite_or_eating_patterns
        );
        setValue(
            "been_so_distressed_you_seriously_wished_to_end_your_life",
            note.note_content
                .been_so_distressed_you_seriously_wished_to_end_your_life
        );
        setValue(
            "has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live",
            note.note_content
                .has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
        );
        setValue(
            "describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live",
            note.note_content
                .describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
        );
        setValue(
            "a_specific_plan_of_how_you_would_kill_yourself",
            note.note_content.a_specific_plan_of_how_you_would_kill_yourself
        );
        setValue(
            "access_to_weapons_or_means_of_hurting_self",
            note.note_content.access_to_weapons_or_means_of_hurting_self
        );
        setValue(
            "made_serious_suicide_attempts",
            note.note_content.made_serious_suicide_attempts
        );
        setValue(
            "purposely_done_something_to_hurt_yourself",
            note.note_content.purposely_done_something_to_hurt_yourself
        );
        setValue(
            "describe_done_something_to_hurt_yourself",
            note.note_content.describe_done_something_to_hurt_yourself
        );
        setValue(
            "heard_voices_telling_you_to_hurt_yourself",
            note.note_content.heard_voices_telling_you_to_hurt_yourself
        );
        setValue(
            "had_relatives_who_attempted_or_commited_sucide",
            note.note_content.had_relatives_who_attempted_or_commited_sucide
        );
        setValue(
            "had_thoughts_of_killing_or_seriously_hurting_someone",
            note.note_content
                .had_thoughts_of_killing_or_seriously_hurting_someone
        );
        setValue(
            "heard_voices_telling_you_to_hurt_others",
            note.note_content.heard_voices_telling_you_to_hurt_others
        );
        setValue(
            "hurt_someone_or_destroyed_property_on_purpose",
            note.note_content.hurt_someone_or_destroyed_property_on_purpose
        );
        setValue(
            "slapped_kicked_punched_someone_with_intent_to_harm",
            note.note_content.slapped_kicked_punched_someone_with_intent_to_harm
        );
        setValue(
            "been_arrested_or_detained_for_violent_behavior",
            note.note_content.been_arrested_or_detained_for_violent_behavior
        );
        setValue(
            "been_to_jail_for_any_reason",
            note.note_content.been_to_jail_for_any_reason
        );
        setValue(
            "been_on_probation_for_any_reason",
            note.note_content.been_on_probation_for_any_reason
        );
        setValue(
            "do_you_have_access_to_guns",
            note.note_content.do_you_have_access_to_guns
        );
        setValue(
            "patients_ability_and_capability_to_respond_to_treatment",
            note.note_content
                .patients_ability_and_capability_to_respond_to_treatment
        );
        setValue(
            "details_for_diminished_poor_limited_quality_patient_oriented_evidence",
            note.note_content
                .details_for_diminished_poor_limited_quality_patient_oriented_evidence
        );
        setValue(
            "people_present_at_session",
            note.note_content.people_present_at_session
        );
        setValue(
            "other_person_present_at_session",
            note.note_content.other_person_present_at_session
        );
        setValue(
            "type_of_treatment_recommended",
            note.note_content.type_of_treatment_recommended
        );
        setValue(
            "treatment_modality_intervention",
            note.note_content.treatment_modality_intervention
        );
        setValue(
            "other_treatment_modality_intervention",
            note.note_content.other_treatment_modality_intervention
        );
        setValue("treatment_goal_one", note.note_content.treatment_goal_one);
        setValue("treatment_goal_two", note.note_content.treatment_goal_two);
        setValue(
            "treatment_goal_three",
            note.note_content.treatment_goal_three
        );
        setValue("medication_updates", note.note_content.medication_updates);
        setValue(
            "frequency_of_sessions",
            note.note_content.frequency_of_sessions
        );
        setValue(
            "other_frequency_of_sessions",
            note.note_content.other_frequency_of_sessions
        );
        setValue(
            "expected_termination_date",
            note.note_content.date_of_next_session
        );
        setValue("plan_of_care", note.note_content.plan_of_care);
        setValue("prognosis", note.note_content.prognosis);
        setValue("subjective_report", note.note_content.subjective_report);
        setValue("summary", note.note_content.summary);
        setValue("anxiety", note.note_content.anxiety);
        setValue("panic_attacks", note.note_content.panic_attacks);
        setValue("depression", note.note_content.depression);
        setShowPrompt(false);
    };
    return (
        <>
            <LoadPreviousSessionNotificationModal
                showPrompt={showPrompt}
                setShowPrompt={setShowPrompt}
                handleLoadPreviousSession={() =>
                    handleLoadPreviousSession(selectedNote)
                }
            />
            {notes && notes.data.length > 0 && (
                <div className="flex justify-end mb-24">
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <FilterButton text="Load previous session note" />
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            {notes &&
                                notes?.data?.map((note) => (
                                    <DropdownItem
                                        key={note.note_id}
                                        onClick={() => {
                                            setShowPrompt(true);
                                            setSelectedNote(note);
                                        }}
                                    >
                                        <div>
                                            {makeStringFirstLetterCapital(
                                                removeEnumUnderscore(
                                                    note.note_type
                                                )
                                            )}{" "}
                                            - Note content Details -{" "}
                                            {formatDate(note.date_of_service)}
                                        </div>
                                    </DropdownItem>
                                ))}
                        </DropdownContent>
                    </Dropdown>
                </div>
            )}
            <form
                onSubmit={handleSubmit(handleSignNote)}
                className="pb-[60px] md:pb-[150px]"
                aria-label="treatment review form"
                id="create-note-form"
            >
                <div className="mb-24">
                    <NoteFormInfoBox />
                </div>
                <section id="step-one" className="mb-40">
                    {appointmentDetails?.note_type ===
                        NoteTypes.FAMILY_TREATMENT_REVIEW && (
                        <div className="fg">
                            <FormCheckbox
                                {...register("isClientPresent")}
                                label={
                                    TREATMENT_REVIEW_NOTE_LABELS.isClientPresent
                                }
                                defaultChecked={formValues?.isClientPresent}
                                onBlur={handleSubmit(handleAutoSaveNote)}
                            />
                        </div>
                    )}

                    <div className="fg">
                        <Controller
                            name="appointment_location"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    isRequired
                                    label={
                                        TREATMENT_REVIEW_NOTE_LABELS.appointment_location
                                    }
                                    placeholder={
                                        TREATMENT_REVIEW_NOTE_LABELS.appointment_location
                                    }
                                    onChange={(val) =>
                                        field.onChange((val as Option).value)
                                    }
                                    value={AppointmentLocationsForSelect.find(
                                        (location) =>
                                            location.value === field.value
                                    )}
                                    options={AppointmentLocationsForSelect}
                                    hasError={!!errors.appointment_location}
                                    errorText={
                                        errors.appointment_location?.message
                                    }
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                />
                            )}
                        />
                    </div>
                    {watch("appointment_location") ===
                        AppointmentLocation.TELETHERAPY && (
                        <>
                            <p className="note-label">
                                {TREATMENT_REVIEW_NOTE_LABELS.delivery_option}
                            </p>
                            <div className="fg fg-space-between three flex">
                                <RadioInput
                                    {...register("delivery_option")}
                                    label="Home"
                                    value="Home"
                                    defaultChecked={
                                        formValues?.delivery_option === "Home"
                                    }
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                />

                                <RadioInput
                                    {...register("delivery_option")}
                                    label="Office"
                                    value="Office"
                                    defaultChecked={
                                        formValues?.delivery_option === "Office"
                                    }
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                />

                                <RadioInput
                                    {...register("delivery_option")}
                                    label="Other"
                                    value="Other"
                                    defaultChecked={
                                        formValues?.delivery_option === "Other"
                                    }
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                />
                            </div>
                            {errors.delivery_option ? (
                                <div className="note-error">
                                    <p>
                                        Client teletherapy location is required
                                    </p>
                                </div>
                            ) : null}
                            {watch("delivery_option") === "Other" ||
                            formValues?.delivery_option === "Other" ? (
                                <div className="fg">
                                    <Input
                                        {...register("other_delivery_option")}
                                        label={
                                            TREATMENT_REVIEW_NOTE_LABELS.other_delivery_option
                                        }
                                        placeholder={
                                            TREATMENT_REVIEW_NOTE_LABELS.other_delivery_option
                                        }
                                        hasError={
                                            !!errors.other_delivery_option
                                        }
                                        errorText={
                                            errors.other_delivery_option
                                                ?.message
                                        }
                                        defaultValue={
                                            formValues?.other_delivery_option
                                        }
                                        onBlur={handleSubmit(
                                            handleAutoSaveNote
                                        )}
                                    />
                                </div>
                            ) : null}
                        </>
                    )}

                    {watch("appointment_location") ===
                        AppointmentLocation.TELETHERAPY && (
                        <>
                            <p className="note-label">
                                {TREATMENT_REVIEW_NOTE_LABELS.provider_location}
                            </p>
                            <div className="fg fg-space-between three flex">
                                <RadioInput
                                    {...register("provider_location")}
                                    label="Home"
                                    value="Home"
                                    defaultChecked={
                                        formValues?.provider_location === "Home"
                                    }
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                />

                                <RadioInput
                                    {...register("provider_location")}
                                    label="Office"
                                    value="Office"
                                    defaultChecked={
                                        formValues?.provider_location ===
                                        "Office"
                                    }
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                />

                                <RadioInput
                                    {...register("provider_location")}
                                    label="Other"
                                    value="Other"
                                    defaultChecked={
                                        formValues?.provider_location ===
                                        "Other"
                                    }
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                />
                            </div>
                            {errors.provider_location ? (
                                <div className="note-error">
                                    <p>
                                        Provider teletherapy location is
                                        required
                                    </p>
                                </div>
                            ) : null}
                            {watch("provider_location") === "Other" ||
                            formValues?.provider_location === "Other" ? (
                                <div className="fg">
                                    <Input
                                        {...register("other_provider_location")}
                                        label={
                                            TREATMENT_REVIEW_NOTE_LABELS.other_provider_location
                                        }
                                        placeholder={
                                            TREATMENT_REVIEW_NOTE_LABELS.other_provider_location
                                        }
                                        hasError={
                                            !!errors.other_provider_location
                                        }
                                        errorText={
                                            errors.other_provider_location
                                                ?.message
                                        }
                                        defaultValue={
                                            formValues?.other_provider_location
                                        }
                                        onBlur={handleSubmit(
                                            handleAutoSaveNote
                                        )}
                                    />
                                </div>
                            ) : null}
                        </>
                    )}

                    {watch("appointment_location") ===
                    AppointmentLocation.TELETHERAPY ? (
                        <div className="note-checkboxes">
                            <FormCheckbox
                                {...register(
                                    "consent_was_received_for_the_teletherapy_session"
                                )}
                                label={
                                    TREATMENT_REVIEW_NOTE_LABELS.consent_was_received_for_the_teletherapy_session
                                }
                                defaultChecked={
                                    formValues?.consent_was_received_for_the_teletherapy_session
                                }
                                onBlur={handleSubmit(handleAutoSaveNote)}
                            />
                            <FormCheckbox
                                {...register(
                                    "the_teletherapy_session_was_conducted_via_video"
                                )}
                                label={
                                    TREATMENT_REVIEW_NOTE_LABELS.the_teletherapy_session_was_conducted_via_video
                                }
                                defaultChecked={
                                    formValues?.the_teletherapy_session_was_conducted_via_video
                                }
                                onBlur={handleSubmit(handleAutoSaveNote)}
                            />
                        </div>
                    ) : null}
                    <div className="fg">
                        <Input
                            {...register("duration")}
                            label={TREATMENT_REVIEW_NOTE_LABELS.duration}
                            placeholder={TREATMENT_REVIEW_NOTE_LABELS.duration}
                            disabled
                            defaultValue={
                                appointmentDetails
                                    ? getTimeDuration(
                                          formatDate(
                                              appointmentDetails?.session_start_time ||
                                                  "",
                                              false,
                                              "HH:mm"
                                          ),
                                          formatDate(
                                              appointmentDetails?.session_end_time ||
                                                  "",
                                              false,
                                              "HH:mm"
                                          )
                                      )
                                    : undefined
                            }
                            value={formValues?.duration}
                        />
                    </div>
                    <div className="fg">
                        <Controller
                            name="diagnosis_codes"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    isRequired
                                    label={
                                        TREATMENT_REVIEW_NOTE_LABELS.diagnosis_codes
                                    }
                                    placeholder={
                                        (!data && isLoading && "Loading...") ||
                                        (error &&
                                            !isLoading &&
                                            "Error loading diagnosis") ||
                                        (data &&
                                            !isLoading &&
                                            TREATMENT_REVIEW_NOTE_LABELS.diagnosis_codes)
                                    }
                                    value={
                                        field.value &&
                                        DiagnosisCodesForSelect?.filter(
                                            (diagnosis) =>
                                                field.value?.includes(
                                                    diagnosis.value
                                                )
                                        )
                                    }
                                    isSearchable
                                    onInputChange={(value) =>
                                        setSearchValue(value)
                                    }
                                    filterOption={() => true} // Disable default filtering
                                    onChange={(val) => {
                                        field.onChange(
                                            (val as Option[]).map(
                                                (code) => code.value
                                            )
                                        );
                                    }}
                                    options={filterDiagnosisOptions(
                                        searchValue.split(" ")
                                    )}
                                    isDisabled={isLoading || Boolean(error)}
                                    hasError={!!errors.diagnosis_codes}
                                    errorText={
                                        errors.diagnosis_codes?.type ===
                                        "typeError"
                                            ? "Diagnosis codes are required"
                                            : errors.diagnosis_codes?.message
                                    }
                                    isMulti
                                    multiHasValues={
                                        diagnosisCodes &&
                                        diagnosisCodes.length > 0
                                    }
                                    hideSelectedOptions
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                />
                            )}
                        />
                    </div>
                    <h3 className="note-header">Presenting Concerns</h3>
                    <p className="note-label">
                        {
                            TREATMENT_REVIEW_NOTE_LABELS.what_are_the_problem_you_are_seeking_help_for
                        }
                    </p>
                    <div className="fg">
                        <TextArea
                            {...register(
                                "what_are_the_problem_you_are_seeking_help_for"
                            )}
                            defaultValue={
                                formValues?.what_are_the_problem_you_are_seeking_help_for
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label">Symptoms</p>
                    <div className="mb-20">
                        <FormCheckbox
                            {...register("anxiety")}
                            label={TREATMENT_REVIEW_NOTE_LABELS.anxiety}
                            defaultChecked={formValues?.anxiety}
                        />
                    </div>
                    {anxiety || formValues?.anxiety ? (
                        <div className="fg fg-space-between two flex">
                            <Controller
                                name="anxiety_frequency"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        label={
                                            TREATMENT_REVIEW_NOTE_LABELS.anxiety_frequency
                                        }
                                        placeholder={
                                            TREATMENT_REVIEW_NOTE_LABELS.anxiety_frequency
                                        }
                                        onChange={(val) =>
                                            field.onChange(
                                                (val as Option).value
                                            )
                                        }
                                        value={FrequencyForSelect.find(
                                            (frequency) =>
                                                frequency.value === field.value
                                        )}
                                        options={FrequencyForSelect}
                                        onBlur={handleSubmit(
                                            handleAutoSaveNote
                                        )}
                                    />
                                )}
                            />
                            <TextArea
                                {...register("anxiety_details")}
                                placeholder={
                                    TREATMENT_REVIEW_NOTE_LABELS.anxiety_details
                                }
                                label={
                                    TREATMENT_REVIEW_NOTE_LABELS.anxiety_details
                                }
                                defaultValue={formValues?.anxiety_details}
                                onBlur={handleSubmit(handleAutoSaveNote)}
                            />
                        </div>
                    ) : null}
                    <div className="mb-20">
                        <FormCheckbox
                            {...register("panic_attacks")}
                            label={TREATMENT_REVIEW_NOTE_LABELS.panic_attacks}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                            defaultChecked={formValues?.panic_attacks}
                        />
                    </div>
                    {panicAttacks || formValues?.panic_attacks ? (
                        <div className="fg fg-space-between two flex">
                            <Controller
                                name="panic_attacks_freqency"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        label={
                                            TREATMENT_REVIEW_NOTE_LABELS.panic_attacks_freqency
                                        }
                                        placeholder={
                                            TREATMENT_REVIEW_NOTE_LABELS.panic_attacks_freqency
                                        }
                                        onChange={(val) =>
                                            field.onChange(
                                                (val as Option).value
                                            )
                                        }
                                        value={FrequencyForSelect.find(
                                            (frequency) =>
                                                frequency.value === field.value
                                        )}
                                        options={FrequencyForSelect}
                                        onBlur={handleSubmit(
                                            handleAutoSaveNote
                                        )}
                                    />
                                )}
                            />
                            <TextArea
                                {...register("panic_attacks_details")}
                                placeholder={
                                    TREATMENT_REVIEW_NOTE_LABELS.panic_attacks_details
                                }
                                label={
                                    TREATMENT_REVIEW_NOTE_LABELS.panic_attacks_details
                                }
                                defaultValue={formValues?.panic_attacks_details}
                                onBlur={handleSubmit(handleAutoSaveNote)}
                            />
                        </div>
                    ) : null}
                    <div className="mb-20">
                        <FormCheckbox
                            {...register("depression")}
                            label={TREATMENT_REVIEW_NOTE_LABELS.depression}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                            defaultChecked={formValues?.depression}
                        />
                    </div>
                    {depression || formValues?.depression ? (
                        <div className="fg fg-space-between two flex">
                            <Controller
                                name="depression_freqency"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        label={
                                            TREATMENT_REVIEW_NOTE_LABELS.depression_freqency
                                        }
                                        placeholder={
                                            TREATMENT_REVIEW_NOTE_LABELS.depression_freqency
                                        }
                                        onChange={(val) =>
                                            field.onChange(
                                                (val as Option).value
                                            )
                                        }
                                        value={FrequencyForSelect.find(
                                            (frequency) =>
                                                frequency.value === field.value
                                        )}
                                        options={FrequencyForSelect}
                                        onBlur={handleSubmit(
                                            handleAutoSaveNote
                                        )}
                                    />
                                )}
                            />
                            <TextArea
                                {...register("depression_details")}
                                placeholder={
                                    TREATMENT_REVIEW_NOTE_LABELS.depression_details
                                }
                                label={
                                    TREATMENT_REVIEW_NOTE_LABELS.depression_details
                                }
                                defaultValue={formValues?.depression_details}
                                onBlur={handleSubmit(handleAutoSaveNote)}
                            />
                        </div>
                    ) : null}
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Easily distracted"
                            value="Easily distracted"
                            defaultChecked={isCheckboxChecked(
                                "Easily distracted",
                                formValues?.selected_symptoms
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Impulsive"
                            value="Impulsive"
                            defaultChecked={isCheckboxChecked(
                                "Impulsive",
                                formValues?.selected_symptoms
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Paranoia"
                            value="Paranoia"
                            defaultChecked={isCheckboxChecked(
                                "Paranoia",
                                formValues?.selected_symptoms
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Alcohol"
                            value="Alcohol"
                            defaultChecked={isCheckboxChecked(
                                "Alcohol",
                                formValues?.selected_symptoms
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Anger outburst"
                            value="Anger outburst"
                            defaultChecked={isCheckboxChecked(
                                "Anger outburst",
                                formValues?.selected_symptoms
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Unable to feel pleasure"
                            value="Unable to feel pleasure"
                            defaultChecked={isCheckboxChecked(
                                "Unable to feel pleasure",
                                formValues?.selected_symptoms
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Excessive energy"
                            value="Excessive energy"
                            defaultChecked={isCheckboxChecked(
                                "Excessive energy",
                                formValues?.selected_symptoms
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Recreational drug use"
                            value="Recreational drug use"
                            defaultChecked={isCheckboxChecked(
                                "Recreational drug use",
                                formValues?.selected_symptoms
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Tobacco"
                            value="Tobacco"
                            defaultChecked={isCheckboxChecked(
                                "Tobacco",
                                formValues?.selected_symptoms
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("selected_symptoms")}
                            label="Racing thoughts"
                            value="Racing thoughts"
                            defaultChecked={isCheckboxChecked(
                                "Racing thoughts",
                                formValues?.selected_symptoms
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register("other_symptoms")}
                            placeholder={
                                TREATMENT_REVIEW_NOTE_LABELS.other_symptoms
                            }
                            label={TREATMENT_REVIEW_NOTE_LABELS.other_symptoms}
                            defaultValue={formValues?.other_symptoms}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register("physical_symptoms")}
                            defaultValue={formValues?.physical_symptoms}
                            placeholder={
                                TREATMENT_REVIEW_NOTE_LABELS.physical_symptoms
                            }
                            label={
                                TREATMENT_REVIEW_NOTE_LABELS.physical_symptoms
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register("medication_physical_psychiatric")}
                            defaultValue={
                                formValues?.medication_physical_psychiatric
                            }
                            placeholder={
                                TREATMENT_REVIEW_NOTE_LABELS.medication_physical_psychiatric
                            }
                            label={
                                TREATMENT_REVIEW_NOTE_LABELS.medication_physical_psychiatric
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register("sleeping_habits_and_concerns")}
                            placeholder={
                                TREATMENT_REVIEW_NOTE_LABELS.sleeping_habits_and_concerns
                            }
                            label={
                                TREATMENT_REVIEW_NOTE_LABELS.sleeping_habits_and_concerns
                            }
                            defaultValue={
                                formValues?.sleeping_habits_and_concerns
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register(
                                "difficulties_with_appetite_or_eating_patterns"
                            )}
                            placeholder={
                                TREATMENT_REVIEW_NOTE_LABELS.difficulties_with_appetite_or_eating_patterns
                            }
                            label={
                                TREATMENT_REVIEW_NOTE_LABELS.difficulties_with_appetite_or_eating_patterns
                            }
                            defaultValue={
                                formValues?.difficulties_with_appetite_or_eating_patterns
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                </section>
                <section id="step-two" className="mb-40">
                    <p className="note-header">Suicide risk assessment</p>
                    <p className="note-label">
                        {`1. ${TREATMENT_REVIEW_NOTE_LABELS.been_so_distressed_you_seriously_wished_to_end_your_life}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "been_so_distressed_you_seriously_wished_to_end_your_life"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.been_so_distressed_you_seriously_wished_to_end_your_life ===
                                "No"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register(
                                "been_so_distressed_you_seriously_wished_to_end_your_life"
                            )}
                            label="Today"
                            value="Today"
                            defaultChecked={
                                formValues?.been_so_distressed_you_seriously_wished_to_end_your_life ===
                                "Today"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register(
                                "been_so_distressed_you_seriously_wished_to_end_your_life"
                            )}
                            label="Recently"
                            value="Recently"
                            defaultChecked={
                                formValues?.been_so_distressed_you_seriously_wished_to_end_your_life ===
                                "Recently"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label">
                        {`2. ${TREATMENT_REVIEW_NOTE_LABELS.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live ===
                                "No"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register(
                                "has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live ===
                                "Yes"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register(
                                "describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live"
                            )}
                            placeholder={
                                TREATMENT_REVIEW_NOTE_LABELS.describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
                            }
                            label={
                                TREATMENT_REVIEW_NOTE_LABELS.describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
                            }
                            defaultValue={
                                formValues?.describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-header">Have you had/Do you have -</p>
                    <p className="note-label">
                        {`3. ${TREATMENT_REVIEW_NOTE_LABELS.a_specific_plan_of_how_you_would_kill_yourself}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "a_specific_plan_of_how_you_would_kill_yourself"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.a_specific_plan_of_how_you_would_kill_yourself ===
                                "No"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register(
                                "a_specific_plan_of_how_you_would_kill_yourself"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.a_specific_plan_of_how_you_would_kill_yourself ===
                                "Yes"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label">
                        {`4. ${TREATMENT_REVIEW_NOTE_LABELS.access_to_weapons_or_means_of_hurting_self}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "access_to_weapons_or_means_of_hurting_self"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.access_to_weapons_or_means_of_hurting_self ===
                                "No"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register(
                                "access_to_weapons_or_means_of_hurting_self"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.access_to_weapons_or_means_of_hurting_self ===
                                "Yes"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label">
                        {`5. ${TREATMENT_REVIEW_NOTE_LABELS.made_serious_suicide_attempts}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register("made_serious_suicide_attempts")}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.made_serious_suicide_attempts ===
                                "No"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register("made_serious_suicide_attempts")}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.made_serious_suicide_attempts ===
                                "Yes"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label">
                        {`6. ${TREATMENT_REVIEW_NOTE_LABELS.purposely_done_something_to_hurt_yourself}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "purposely_done_something_to_hurt_yourself"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.purposely_done_something_to_hurt_yourself ===
                                "No"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register(
                                "purposely_done_something_to_hurt_yourself"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.purposely_done_something_to_hurt_yourself ===
                                "Yes"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register(
                                "describe_done_something_to_hurt_yourself"
                            )}
                            placeholder={
                                TREATMENT_REVIEW_NOTE_LABELS.describe_done_something_to_hurt_yourself
                            }
                            label={
                                TREATMENT_REVIEW_NOTE_LABELS.describe_done_something_to_hurt_yourself
                            }
                            defaultValue={
                                formValues?.describe_done_something_to_hurt_yourself
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label">
                        {`7. ${TREATMENT_REVIEW_NOTE_LABELS.heard_voices_telling_you_to_hurt_yourself}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "heard_voices_telling_you_to_hurt_yourself"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.heard_voices_telling_you_to_hurt_yourself ===
                                "No"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register(
                                "heard_voices_telling_you_to_hurt_yourself"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.heard_voices_telling_you_to_hurt_yourself ===
                                "Yes"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label">
                        {`8. ${TREATMENT_REVIEW_NOTE_LABELS.had_relatives_who_attempted_or_commited_sucide}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "had_relatives_who_attempted_or_commited_sucide"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.had_relatives_who_attempted_or_commited_sucide ===
                                "No"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register(
                                "had_relatives_who_attempted_or_commited_sucide"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.had_relatives_who_attempted_or_commited_sucide ===
                                "Yes"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label">
                        {`9. ${TREATMENT_REVIEW_NOTE_LABELS.had_thoughts_of_killing_or_seriously_hurting_someone}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "had_thoughts_of_killing_or_seriously_hurting_someone"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.had_thoughts_of_killing_or_seriously_hurting_someone ===
                                "No"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register(
                                "had_thoughts_of_killing_or_seriously_hurting_someone"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.had_thoughts_of_killing_or_seriously_hurting_someone ===
                                "Yes"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label">
                        {`10. ${TREATMENT_REVIEW_NOTE_LABELS.heard_voices_telling_you_to_hurt_others}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "heard_voices_telling_you_to_hurt_others"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.heard_voices_telling_you_to_hurt_others ===
                                "No"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register(
                                "heard_voices_telling_you_to_hurt_others"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.heard_voices_telling_you_to_hurt_others ===
                                "Yes"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label">
                        {`11. ${TREATMENT_REVIEW_NOTE_LABELS.hurt_someone_or_destroyed_property_on_purpose}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "hurt_someone_or_destroyed_property_on_purpose"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.hurt_someone_or_destroyed_property_on_purpose ===
                                "No"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register(
                                "hurt_someone_or_destroyed_property_on_purpose"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.hurt_someone_or_destroyed_property_on_purpose ===
                                "Yes"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label">
                        {`12. ${TREATMENT_REVIEW_NOTE_LABELS.slapped_kicked_punched_someone_with_intent_to_harm}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "slapped_kicked_punched_someone_with_intent_to_harm"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.slapped_kicked_punched_someone_with_intent_to_harm ===
                                "No"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register(
                                "slapped_kicked_punched_someone_with_intent_to_harm"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.slapped_kicked_punched_someone_with_intent_to_harm ===
                                "Yes"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label">
                        {`13. ${TREATMENT_REVIEW_NOTE_LABELS.been_arrested_or_detained_for_violent_behavior}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register(
                                "been_arrested_or_detained_for_violent_behavior"
                            )}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.been_arrested_or_detained_for_violent_behavior ===
                                "No"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register(
                                "been_arrested_or_detained_for_violent_behavior"
                            )}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.been_arrested_or_detained_for_violent_behavior ===
                                "Yes"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label">
                        {`14. ${TREATMENT_REVIEW_NOTE_LABELS.been_to_jail_for_any_reason}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register("been_to_jail_for_any_reason")}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.been_to_jail_for_any_reason === "No"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register("been_to_jail_for_any_reason")}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.been_to_jail_for_any_reason ===
                                "Yes"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label">
                        {`15. ${TREATMENT_REVIEW_NOTE_LABELS.been_on_probation_for_any_reason}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register("been_on_probation_for_any_reason")}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.been_on_probation_for_any_reason ===
                                "No"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register("been_on_probation_for_any_reason")}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.been_on_probation_for_any_reason ===
                                "Yes"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label">
                        {`16. ${TREATMENT_REVIEW_NOTE_LABELS.do_you_have_access_to_guns}`}
                    </p>
                    <div className="note-radios">
                        <RadioInput
                            {...register("do_you_have_access_to_guns")}
                            label="No"
                            value="No"
                            defaultChecked={
                                formValues?.do_you_have_access_to_guns === "No"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register("do_you_have_access_to_guns")}
                            label="Yes"
                            value="Yes"
                            defaultChecked={
                                formValues?.do_you_have_access_to_guns === "Yes"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <div>
                        <h3 className="note-label">
                            {
                                TREATMENT_REVIEW_NOTE_LABELS.patients_ability_and_capability_to_respond_to_treatment
                            }
                        </h3>
                        <div className="note-checkboxes">
                            <FormCheckbox
                                {...register(
                                    `patients_ability_and_capability_to_respond_to_treatment`
                                )}
                                label="Consistent, good-quality patient-oriented evidence"
                                value="Consistent, good-quality patient-oriented evidence"
                                defaultChecked={isCheckboxChecked(
                                    "Consistent, good-quality patient-oriented evidence",
                                    formValues?.patients_ability_and_capability_to_respond_to_treatment
                                )}
                                onBlur={handleSubmit(handleAutoSaveNote)}
                            />
                            <FormCheckbox
                                {...register(
                                    `patients_ability_and_capability_to_respond_to_treatment`
                                )}
                                label="Diminished/poor or limited-quality patient-oriented evidence"
                                value="Diminished/poor or limited-quality patient-oriented evidence"
                                defaultChecked={isCheckboxChecked(
                                    "Diminished/poor or limited-quality patient-oriented evidence",
                                    formValues?.patients_ability_and_capability_to_respond_to_treatment
                                )}
                                onBlur={handleSubmit(handleAutoSaveNote)}
                            />
                            {(
                                watch(
                                    `patients_ability_and_capability_to_respond_to_treatment`
                                ) || []
                            )?.includes(
                                "Diminished/poor or limited-quality patient-oriented evidence"
                            ) && (
                                <>
                                    <p className="note-label-req">
                                        {
                                            TREATMENT_REVIEW_NOTE_LABELS.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                                        }
                                    </p>
                                    <TextArea
                                        {...register(
                                            `details_for_diminished_poor_limited_quality_patient_oriented_evidence`
                                        )}
                                        defaultValue={
                                            formValues?.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                                        }
                                        hasError={
                                            !!errors.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                                        }
                                        errorText={
                                            errors
                                                .details_for_diminished_poor_limited_quality_patient_oriented_evidence
                                                ?.message
                                        }
                                        onBlur={handleSubmit(
                                            handleAutoSaveNote
                                        )}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </section>
                <section id="step-three" className="mb-40">
                    <h3 className="note-header">Summary</h3>
                    <p className="note-label">
                        {TREATMENT_REVIEW_NOTE_LABELS.people_present_at_session}
                    </p>
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register("people_present_at_session")}
                            label="Patient"
                            value="Patient"
                            defaultChecked={isCheckboxChecked(
                                "Patient",
                                formValues?.people_present_at_session
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("people_present_at_session")}
                            label="Mother"
                            value="Mother"
                            defaultChecked={isCheckboxChecked(
                                "Mother",
                                formValues?.people_present_at_session
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("people_present_at_session")}
                            label="Stepfather"
                            value="Stepfather"
                            defaultChecked={isCheckboxChecked(
                                "Stepfather",
                                formValues?.people_present_at_session
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("people_present_at_session")}
                            label="Spouse"
                            value="Spouse"
                            defaultChecked={isCheckboxChecked(
                                "Spouse",
                                formValues?.people_present_at_session
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("people_present_at_session")}
                            label="Father"
                            value="Father"
                            defaultChecked={isCheckboxChecked(
                                "Father",
                                formValues?.people_present_at_session
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("people_present_at_session")}
                            label="Stepmother"
                            value="Stepmother"
                            defaultChecked={isCheckboxChecked(
                                "Stepmother",
                                formValues?.people_present_at_session
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("people_present_at_session")}
                            label="Partner"
                            value="Partner"
                            defaultChecked={isCheckboxChecked(
                                "Partner",
                                formValues?.people_present_at_session
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("people_present_at_session")}
                            label="Guardian"
                            value="Guardian"
                            defaultChecked={isCheckboxChecked(
                                "Guardian",
                                formValues?.people_present_at_session
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("people_present_at_session")}
                            label="Other"
                            value="Other"
                            defaultChecked={isCheckboxChecked(
                                "Other",
                                formValues?.people_present_at_session
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <div className="fg">
                        <Input
                            {...register("other_person_present_at_session")}
                            label={
                                TREATMENT_REVIEW_NOTE_LABELS.other_person_present_at_session
                            }
                            placeholder={
                                TREATMENT_REVIEW_NOTE_LABELS.other_person_present_at_session
                            }
                            defaultValue={
                                formValues?.other_person_present_at_session
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label">
                        {
                            TREATMENT_REVIEW_NOTE_LABELS.type_of_treatment_recommended
                        }
                    </p>
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register("type_of_treatment_recommended")}
                            label="Individual"
                            value="Individual"
                            defaultChecked={isCheckboxChecked(
                                "Individual",
                                formValues?.type_of_treatment_recommended
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("type_of_treatment_recommended")}
                            label="Family"
                            value="Family"
                            defaultChecked={isCheckboxChecked(
                                "Family",
                                formValues?.type_of_treatment_recommended
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("type_of_treatment_recommended")}
                            label="Group"
                            value="Group"
                            defaultChecked={isCheckboxChecked(
                                "Group",
                                formValues?.type_of_treatment_recommended
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("type_of_treatment_recommended")}
                            label="Collateral/Parenting"
                            value="Collateral/Parenting"
                            defaultChecked={isCheckboxChecked(
                                "Collateral/Parenting",
                                formValues?.type_of_treatment_recommended
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label">
                        {
                            TREATMENT_REVIEW_NOTE_LABELS.treatment_modality_intervention
                        }
                    </p>
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Cognitive/Behavioral"
                            value="Cognitive/Behavioral"
                            defaultChecked={isCheckboxChecked(
                                "Cognitive/Behavioral",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Behavioral Modification"
                            value="Behavioral Modification"
                            defaultChecked={isCheckboxChecked(
                                "Behavioral Modification",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Supportive"
                            value="Supportive"
                            defaultChecked={isCheckboxChecked(
                                "Supportive",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Marital/Couples Therapy"
                            value="Marital/Couples Therapy"
                            defaultChecked={isCheckboxChecked(
                                "Marital/Couples Therapy ",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Family Therapy"
                            value="Family Therapy"
                            defaultChecked={isCheckboxChecked(
                                "Family Therapy",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Stress Management"
                            value="Stress Management"
                            defaultChecked={isCheckboxChecked(
                                "Stress Management",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Psychodynamic"
                            value="Psychodynamic"
                            defaultChecked={isCheckboxChecked(
                                "Psychodynamic",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Parent Training"
                            value="Parent Training"
                            defaultChecked={isCheckboxChecked(
                                "Parent Training",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Crisis Intervention"
                            value="Crisis Intervention"
                            defaultChecked={isCheckboxChecked(
                                "Crisis Intervention",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("treatment_modality_intervention")}
                            label="Other"
                            value="Other"
                            defaultChecked={isCheckboxChecked(
                                "Other",
                                formValues?.treatment_modality_intervention
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <div className="fg">
                        <Input
                            {...register(
                                "other_treatment_modality_intervention"
                            )}
                            label={
                                TREATMENT_REVIEW_NOTE_LABELS.other_treatment_modality_intervention
                            }
                            placeholder={
                                TREATMENT_REVIEW_NOTE_LABELS.other_treatment_modality_intervention
                            }
                            defaultValue={
                                formValues?.other_treatment_modality_intervention
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label">Treatment goals</p>
                    <div className="flex flex-col gap-y-20 mb-32">
                        <Input
                            {...register("treatment_goal_one")}
                            label={
                                TREATMENT_REVIEW_NOTE_LABELS.treatment_goal_one
                            }
                            placeholder={
                                TREATMENT_REVIEW_NOTE_LABELS.treatment_goal_one
                            }
                            defaultValue={formValues?.treatment_goal_one}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <Input
                            {...register("treatment_goal_two")}
                            label={
                                TREATMENT_REVIEW_NOTE_LABELS.treatment_goal_two
                            }
                            placeholder={
                                TREATMENT_REVIEW_NOTE_LABELS.treatment_goal_two
                            }
                            defaultValue={formValues?.treatment_goal_two}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <Input
                            {...register("treatment_goal_three")}
                            label={
                                TREATMENT_REVIEW_NOTE_LABELS.treatment_goal_three
                            }
                            placeholder={
                                TREATMENT_REVIEW_NOTE_LABELS.treatment_goal_three
                            }
                            defaultValue={formValues?.treatment_goal_three}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            label={
                                TREATMENT_REVIEW_NOTE_LABELS.medication_updates
                            }
                            placeholder={
                                TREATMENT_REVIEW_NOTE_LABELS.medication_updates
                            }
                            {...register("medication_updates")}
                            defaultValue={formValues?.medication_updates}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <h3 className="note-label">
                        {TREATMENT_REVIEW_NOTE_LABELS.frequency_of_sessions}
                    </h3>
                    <div className="note-radios">
                        <RadioInput
                            {...register("frequency_of_sessions")}
                            label="Weekly"
                            value="Weekly"
                            defaultChecked={
                                formValues?.frequency_of_sessions === "Weekly"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register("frequency_of_sessions")}
                            label="Bi-Weekly"
                            value="Bi-Weekly"
                            defaultChecked={
                                formValues?.frequency_of_sessions ===
                                "Bi-Weekly"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register("frequency_of_sessions")}
                            label="Monthly"
                            value="Monthly"
                            defaultChecked={
                                formValues?.frequency_of_sessions === "Monthly"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register("frequency_of_sessions")}
                            label="Other"
                            value="Other"
                            defaultChecked={
                                formValues?.frequency_of_sessions === "Other"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    {watch("frequency_of_sessions") === "Other" && (
                        <div className="fg">
                            <Input
                                {...register("other_frequency_of_sessions")}
                                label={
                                    TREATMENT_REVIEW_NOTE_LABELS.other_frequency_of_sessions
                                }
                                placeholder={
                                    TREATMENT_REVIEW_NOTE_LABELS.other_frequency_of_sessions
                                }
                                hasError={!!errors.other_frequency_of_sessions}
                                errorText={
                                    errors.other_frequency_of_sessions?.message
                                }
                                defaultValue={
                                    formValues?.other_frequency_of_sessions
                                }
                                onBlur={handleSubmit(handleAutoSaveNote)}
                            />
                        </div>
                    )}
                    <div className="fg">
                        <Controller
                            name="expected_termination_date"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    label={
                                        TREATMENT_REVIEW_NOTE_LABELS.expected_termination_date
                                    }
                                    onChange={(date) => {
                                        field.onChange(date);
                                        setLocalExpectedTerminationDate(date);
                                    }}
                                    selected={localExpectedTerminationDate}
                                    value={
                                        field.value
                                            ? formatDate(
                                                  field.value,
                                                  true,
                                                  "MM/dd/yyyy"
                                              )
                                            : undefined
                                    }
                                    hasError={
                                        !!errors.expected_termination_date
                                    }
                                    errorText={
                                        errors.expected_termination_date
                                            ?.type === "typeError"
                                            ? "Invalid date value"
                                            : errors.expected_termination_date
                                                  ?.message
                                    }
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                    shouldCloseOnSelect
                                    onCalendarClose={handleSubmit(
                                        handleAutoSaveNote
                                    )}
                                />
                            )}
                        />
                    </div>
                    <p className="note-label">
                        {TREATMENT_REVIEW_NOTE_LABELS.plan_of_care}{" "}
                        <span>
                            (Will patient continue treatment? How many visits
                            and for how long if known?)
                        </span>
                    </p>
                    <div className="fg">
                        <Input
                            label=""
                            {...register("plan_of_care")}
                            defaultValue={formValues?.plan_of_care}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label">
                        {TREATMENT_REVIEW_NOTE_LABELS.prognosis}
                    </p>
                    <div className="fg">
                        <Input
                            label=""
                            {...register("prognosis")}
                            defaultValue={formValues?.prognosis}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label-req">
                        {TREATMENT_REVIEW_NOTE_LABELS.subjective_report}
                    </p>
                    <p className="note-label text-gray">
                        Please make sure to only include what the client has
                        reported. This should only tie back to what the client
                        reported in the session, re; symptoms, mood/affect,
                        ideations, interpersonal conflicts, etc. Do not include
                        your assessment or interpretation of the clients
                        symptoms here.
                    </p>
                    <div className="fg">
                        <TextArea
                            {...register("subjective_report")}
                            defaultValue={formValues?.subjective_report}
                            hasError={!!errors.subjective_report}
                            errorText={errors.subjective_report?.message}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label-req">
                        {TREATMENT_REVIEW_NOTE_LABELS.summary}
                    </p>
                    <div className="fg">
                        <TextArea
                            {...register("summary")}
                            defaultValue={formValues?.summary}
                            hasError={!!errors.summary}
                            errorText={errors.summary?.message}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                </section>

                <div className="fg-info fg-line">
                    <p>Sign note here</p>
                    <div className="fg">
                        <Input
                            {...register("signature")}
                            hasError={!!errors.signature}
                            errorText={errors.signature?.message}
                            label="Provider's Initials"
                            placeholder="Provider's Initials"
                            autoComplete="off"
                            isRequired
                        />
                    </div>
                </div>
                <div>
                    <AddNoteFooter
                        onDraftButtonClick={handleSubmit(handleSaveAsDraftNote)}
                        noteDOS={appointmentDetails.date_of_service}
                    />
                </div>
            </form>
        </>
    );
}

import Navbar from "../../../../components/Navbar/Navbar";
import EditNoteFlow from "../../../../components/Notes/EditNote/EditNote";

export default function EditNote() {
    return (
        <>
            <Navbar title="Edit Note" />
            <div className="page">
                <EditNoteFlow />
            </div>
        </>
    );
}

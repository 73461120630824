import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DatePicker, Select } from "@jhool-io/fe-components";
import { Controller, useForm } from "react-hook-form";
import { FormatOptionLabelMeta } from "react-select";
import { useDropzone } from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import { parseISO } from "date-fns";
import { useQueryClient } from "@tanstack/react-query";
import {
    CPT_CODES,
    NoteLabels,
    NoteTypes,
    SessionServiceType,
} from "../../../notes/types/notes.types";
import {
    cn,
    formatDate,
    handleFormatDatePickerValue,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    truncateString,
} from "../../../../utils/helpers";
import {
    IProviderSession,
    ProviderStatus,
    SessionPlaceOfServiceCode,
} from "../../../../utils/types/session";
import {
    useFetchDiagnosisCodes,
    useFetchProviderSessions,
} from "../../../../hooks/queries";
import { ModifierTypes } from "../../../../utils/types/notes";
import { CPT_CODES_DESCRIPTION } from "../../../../utils/constants";
import usePractice from "../../../../hooks/usePractice";
import FolderOpenIcon from "../../../../components/Icons/FolderOpen";
import { useEditManualClaim } from "../../hooks/claims.mutations";
import useToast from "../../../../hooks/useToast";
import { ClaimStatus, ICreateManualClaim } from "../../types/claims.types";
import TimesIcon from "../../../../components/Icons/Times";
import DotIcon from "../../../../components/Icons/DotIcon";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import { useGetClaim } from "../../hooks/claims.queries";

type Options = {
    label: string;
    value: string;
};

interface EditManualClaimFormProps {
    onFormSubmit: () => void;
}

const schema = yup.object().shape({
    pos_code: yup.string().required("POS code is required"),
    cpt_code: yup.string().required("CPT code is required"),
    session_id: yup.string().required("Session is required"),
    date_of_service: yup.date().required("Date of service is required"),
    note_type: yup.string().required("Note type is required"),
    appointment_type: yup.string().required("Session type is required"),
});

export default function EditManualClaimForm({
    onFormSubmit,
}: EditManualClaimFormProps) {
    const { clientId, noteId } = useParams();
    const noteDetails = useFetchSessionNote(
        clientId as string,
        noteId as string,
        Boolean(clientId) && Boolean(noteId)
    );

    const [searchValue, setSearchValue] = React.useState("");
    const [dateOfService, setDateOfService] = React.useState<Date | null>(
        parseISO(noteDetails.data?.data.date_of_service as string)
    );
    const [selectedFiles, setSelectedFiles] = React.useState<File[] | null>(
        null
    );

    const formData = new FormData();

    const { data: diagnosisCodes, isLoading, error } = useFetchDiagnosisCodes();

    const { practice } = usePractice();

    const { toast } = useToast();

    const queryClient = useQueryClient();

    const editManualClaim = useEditManualClaim(
        noteDetails.data?.data.claim_id || ""
    );

    const { data: claimsData } = useGetClaim(
        {
            claim_id: noteDetails.data?.data.claim_id,
        },
        Boolean(noteDetails.data?.data.claim_id)
    );

    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<ICreateManualClaim>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const navigate = useNavigate();

    // Fetch provider's sessions list
    const {
        data: providerSessions,
        isLoading: sessionsLoading,
        error: sessionsError,
    } = useFetchProviderSessions({ show_sessions_with_term_notes: true });

    const PlaceOfServiceOptions = [
        ...Object.values(SessionPlaceOfServiceCode).map((place) => ({
            value: place,
            label: place,
        })),
    ];

    const getSessionTypeSelectOptions = Object.values(SessionServiceType).map(
        (sessionType) => ({
            label: (
                <span style={{ textTransform: "capitalize" }}>
                    {removeEnumUnderscore(sessionType)}
                </span>
            ),
            value: sessionType,
        })
    );

    const getNoteTypeSelectOptions = [
        NoteTypes.CANCELLATION_NOTE,
        NoteTypes.FAMILY_SOAP_NOTE,
        NoteTypes.INDIVIDUAL_SOAP_NOTE,
        NoteTypes.RECORD_OF_DISCLOSURE,
        NoteTypes.TERMINATION_NOTE,
        NoteTypes.INDIVIDUAL_TREATMENT_REVIEW,
        NoteTypes.FAMILY_TREATMENT_REVIEW,
        NoteTypes.INDIVIDUAL_INTAKE_NOTE,
        NoteTypes.FAMILY_INTAKE_NOTE,
        NoteTypes.MINOR_INTAKE_NOTE,
    ]
        .sort((a, b) => a.localeCompare(b))
        .map((noteType) => ({
            label: NoteLabels[noteType],
            value: noteType,
        }));

    const DiagnosisCodesForSelect = diagnosisCodes?.data.map((diagnosis) => ({
        label: `${diagnosis.code} ${diagnosis.description}`,
        value: diagnosis.diagnosis_code_id,
        code: diagnosis.code,
    }));

    // Get filter options for diagnosis list
    const filterDiagnosisOptions = (phrases: string[]) => {
        const filteredOptions = DiagnosisCodesForSelect?.filter((option) =>
            phrases.every(
                (phrase) =>
                    option &&
                    option.label.toLowerCase().includes(phrase.toLowerCase())
            )
        );
        return filteredOptions;
    };

    const modifiersTypesForSelect: Options[] = [
        ...Object.values(ModifierTypes).map((item) => ({
            value: item,
            label: item,
        })),
    ];

    const makeLabelsAndValuesProviderSessionSelect = (
        session: IProviderSession
    ) => {
        return {
            label: (
                <div>
                    <div className="flex items-center">
                        <span>{`${makeStringFirstLetterCapital(
                            session.client.first_name
                        )} ${makeStringFirstLetterCapital(
                            session.client.last_name
                        )} - ${makeStringFirstLetterCapital(
                            removeEnumUnderscore(session.service)
                        )}`}</span>
                        <span className=" ml-1">
                            <DotIcon />{" "}
                            {makeStringFirstLetterCapital(
                                session.provider.first_name
                            )}{" "}
                            {session.provider.last_name}
                        </span>
                    </div>
                    <div className="text-gray text-xs">
                        {`${makeStringFirstLetterCapital(
                            session.session_day
                        )}, ${formatDate(
                            session.start_date_time,
                            false,
                            "h:mmaaa"
                        )} - ${formatDate(
                            session.end_date_time,
                            false,
                            "h:mmaaa"
                        )}`}
                        {" - "}
                        <span
                            className={cn(
                                "text-xs inline-block font-medium capitalize",
                                {
                                    "text-primary":
                                        session.status ===
                                        ProviderStatus.ACTIVE,
                                    "text-danger":
                                        session.status ===
                                        ProviderStatus.INACTIVE,
                                }
                            )}
                        >
                            {session.status === ProviderStatus.INACTIVE
                                ? "Terminated"
                                : session.status}
                        </span>
                    </div>
                </div>
            ),
            value: session.session_id,
        };
    };

    // Custom comparison function
    const compareItems = (a: IProviderSession, b: IProviderSession) => {
        if (
            !a.provider.provider_id &&
            practice?.provider_id &&
            b.provider.provider_id &&
            practice.provider_id
        ) {
            return -1;
        }
        if (
            a.provider.provider_id &&
            practice?.provider_id &&
            !b.provider.provider_id &&
            practice?.provider_id
        ) {
            return 1;
        }
        return 0;
    };

    // Get options for client name select input
    const getProviderSessionSelectOptions = providerSessions?.data
        .sort((a, b) => a.client.first_name.localeCompare(b.client.first_name))
        .sort(compareItems)
        .filter((session) => session)
        .map((session) => makeLabelsAndValuesProviderSessionSelect(session));

    // Get filter options for sessions list
    const filteredOptions = getProviderSessionSelectOptions?.filter(
        (option) => {
            return option.label.props.children[0].props.children[0].props.children
                .split(" ")
                .filter(Boolean)
                .join(" ")
                .toLowerCase();
        }
    );

    const CPTOptions = CPT_CODES.sort((a, b) => Number(a) - Number(b)).map(
        (code) => ({
            label: `${code} - ${CPT_CODES_DESCRIPTION[code]}`,
            value: code,
        })
    );

    // Function to call when file(s) are dropped in dropzone
    const onDrop = React.useCallback(
        (acceptedFiles: File[]) => {
            if (selectedFiles && selectedFiles.length > 0) {
                setSelectedFiles([...acceptedFiles, ...selectedFiles]);
            } else setSelectedFiles([...acceptedFiles]);
        },
        [selectedFiles]
    );

    // Initialize the useDropzone hook
    const { getRootProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: {
            "application/pdf": [".pdf"],
            "image/*": [".png", ".webp", ".jpeg", ".jpg", ".avif"],
        },
    });

    // Function for setting selected files
    const handleSetSelectedFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            if (selectedFiles && selectedFiles.length > 0) {
                setSelectedFiles([...e.target.files, ...selectedFiles]);
            } else setSelectedFiles([...e.target.files]);
        }
    };

    const handleRemoveSelectedFile = (filename: string) => {
        if (selectedFiles) {
            const filesArr = [...selectedFiles].filter(
                (file) => file.name !== filename
            );
            if (filesArr.length === 0) setSelectedFiles(null);
            else setSelectedFiles(filesArr);
        }
    };

    const onSubmit = (payload: ICreateManualClaim) => {
        formData.append("session_id", payload.session_id);
        formData.append("note_type", payload.note_type);
        formData.append("appointment_type", String(payload.appointment_type));
        formData.append("cpt_code", payload.cpt_code);
        formData.append("pos_code", payload.pos_code);
        formData.append(
            "date_of_service",
            handleFormatDatePickerValue(payload.date_of_service)
        );
        if (payload.diagnosis_codes && payload.diagnosis_codes.length > 0) {
            for (let i = 0; i < [...payload.diagnosis_codes].length; i += 1) {
                formData.append(
                    "diagnosis_codes",
                    JSON.stringify(payload.diagnosis_codes)
                );
            }
        }

        if (payload.modifier_codes && payload.modifier_codes.length > 0) {
            for (let i = 0; i < [...payload.modifier_codes].length; i += 1) {
                formData.append(
                    "modifier_codes",
                    JSON.stringify(payload.modifier_codes)
                );
            }
        }

        if (selectedFiles && selectedFiles.length > 0) {
            for (let i = 0; i < [...selectedFiles].length; i += 1) {
                formData.append("note_file", [...selectedFiles][i]);
            }
        }

        editManualClaim.mutate(formData, {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: [clientId, `session-note`, noteId],
                });
                toast({
                    mode: "success",
                    message:
                        res.message || "Manual claim updated successfully!",
                });
                onFormSubmit();
                navigate(
                    `/claims/${res.claim_id}/${res.data.claim.client_id}/${res.data.claim.note_id}`
                );
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not create claim at this time.",
                });
            },
        });
    };

    return (
        <form id="edit-manual-claim" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg">
                <Controller
                    name="session_id"
                    control={control}
                    defaultValue={noteDetails.data?.data.session_id}
                    render={() => (
                        <Select
                            label="Session"
                            options={
                                providerSessions ||
                                !sessionsError ||
                                !sessionsLoading
                                    ? filteredOptions
                                    : []
                            }
                            isDisabled
                            placeholder={
                                (sessionsLoading && "Loading...") ||
                                (sessionsError && "Error loading sessions") ||
                                (providerSessions &&
                                    !sessionsLoading &&
                                    "Select session")
                            }
                            defaultValue={filteredOptions?.find(
                                (session) =>
                                    session.value ===
                                    noteDetails.data?.data.session_id
                            )}
                            hasError={!!errors.session_id}
                            errorText={errors.session_id?.message}
                            multiHasValues
                        />
                    )}
                />
            </div>

            <div className="fg">
                <Controller
                    name="date_of_service"
                    control={control}
                    defaultValue={noteDetails.data?.data.date_of_service}
                    render={({ field }) => (
                        <DatePicker
                            label="Date of Service"
                            selected={dateOfService}
                            onChange={(date) => {
                                field.onChange(date);
                                setDateOfService(date);
                            }}
                            hasError={!!errors.date_of_service}
                            errorText={
                                errors.date_of_service?.type === "typeError"
                                    ? "invalid date value"
                                    : errors.date_of_service?.message
                            }
                            maxDate={new Date(Date.now())}
                            disabled={
                                claimsData?.data[0].claim_status !==
                                ClaimStatus.READY_FOR_SUBMISSION
                            }
                        />
                    )}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Controller
                    name="appointment_type"
                    control={control}
                    defaultValue={
                        getSessionTypeSelectOptions.find(
                            (option) =>
                                option.value ===
                                noteDetails.data?.data.appointment_type
                        )?.value
                    }
                    render={({ field }) => (
                        <Select
                            label="Session type"
                            placeholder="Select session type"
                            isSearchable
                            options={getSessionTypeSelectOptions}
                            isLongListInDialog
                            onChange={(val) => {
                                field.onChange((val as Options).value);
                            }}
                            value={getSessionTypeSelectOptions.find(
                                (option) =>
                                    option.value === watch("appointment_type")
                            )}
                            defaultValue={getSessionTypeSelectOptions.find(
                                (option) =>
                                    option.value ===
                                    noteDetails.data?.data.appointment_type
                            )}
                            hasError={!!errors.appointment_type}
                            errorText={errors.appointment_type?.message}
                            isDisabled={
                                claimsData?.data[0].claim_status !==
                                ClaimStatus.READY_FOR_SUBMISSION
                            }
                        />
                    )}
                />
                <Controller
                    name="pos_code"
                    control={control}
                    defaultValue={
                        PlaceOfServiceOptions.find(
                            (pos) =>
                                pos.value === noteDetails.data?.data.pos_code
                        )?.value
                    }
                    render={({ field }) => (
                        <Select
                            label="POS code"
                            placeholder="Select POS code"
                            options={PlaceOfServiceOptions}
                            isSearchable
                            isLongListInDialog
                            onChange={(val) =>
                                field.onChange((val as Options).value)
                            }
                            defaultValue={PlaceOfServiceOptions.find(
                                (pos) =>
                                    pos.value ===
                                    noteDetails.data?.data.pos_code
                            )}
                            hasError={!!errors.pos_code}
                            errorText={errors.pos_code?.message}
                            formatOptionLabel={(
                                selectData: unknown,
                                formatOptionLabelMeta: FormatOptionLabelMeta<unknown>
                            ) => {
                                return formatOptionLabelMeta.context === "menu"
                                    ? (selectData as Options).label
                                    : truncateString(
                                          (selectData as Options)
                                              .label as string,
                                          35
                                      );
                            }}
                        />
                    )}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Controller
                    name="cpt_code"
                    control={control}
                    defaultValue={
                        CPTOptions.find(
                            (code) =>
                                code.value === noteDetails.data?.data.cpt_code
                        )?.value
                    }
                    render={({ field }) => (
                        <Select
                            label="CPT Code"
                            placeholder="CPT Code"
                            options={CPTOptions}
                            onChange={(val) =>
                                field.onChange((val as Options).value)
                            }
                            hasError={!!errors.cpt_code}
                            errorText={errors.cpt_code?.message}
                            isSearchable
                            isLongListInDialog
                            defaultValue={CPTOptions.find(
                                (code) =>
                                    code.value ===
                                    noteDetails.data?.data.cpt_code
                            )}
                            formatOptionLabel={(
                                selectData: unknown,
                                formatOptionLabelMeta: FormatOptionLabelMeta<unknown>
                            ) => {
                                return formatOptionLabelMeta.context === "menu"
                                    ? (selectData as Options).label
                                    : truncateString(
                                          (selectData as Options)
                                              .label as string,
                                          35
                                      );
                            }}
                        />
                    )}
                />
                <Controller
                    name="note_type"
                    control={control}
                    defaultValue={
                        getNoteTypeSelectOptions.find(
                            (type) => type.value === noteDetails.data?.data.type
                        )?.value
                    }
                    render={({ field }) => (
                        <Select
                            label="Note type"
                            placeholder="Select note type"
                            isSearchable
                            options={getNoteTypeSelectOptions}
                            isLongListInDialog
                            onChange={(val) => {
                                field.onChange((val as Options).value);
                            }}
                            hasError={!!errors.note_type}
                            errorText={errors.note_type?.message}
                            defaultValue={getNoteTypeSelectOptions.find(
                                (type) =>
                                    type.value === noteDetails.data?.data.type
                            )}
                            isDisabled={
                                claimsData?.data[0].claim_status !==
                                ClaimStatus.READY_FOR_SUBMISSION
                            }
                        />
                    )}
                />
            </div>
            <div className="fg">
                <Controller
                    name="diagnosis_codes"
                    control={control}
                    defaultValue={DiagnosisCodesForSelect?.filter((code) =>
                        noteDetails.data?.data.manual_note_diagnosis_codes?.includes(
                            code.code
                        )
                    ).map((code) => code.value)}
                    render={({ field }) => (
                        <Select
                            label="Diagnosis code"
                            placeholder="Select diagosis code"
                            options={filterDiagnosisOptions(
                                searchValue.split(" ")
                            )}
                            isDisabled={isLoading || Boolean(error)}
                            onInputChange={(value) => setSearchValue(value)}
                            isSearchable
                            isLongListInDialog
                            filterOption={() => true}
                            onChange={(val) => {
                                field.onChange(
                                    (val as Options[]).map((code) => code.value)
                                );
                            }}
                            defaultValue={DiagnosisCodesForSelect?.filter(
                                (code) =>
                                    noteDetails.data?.data.manual_note_diagnosis_codes?.includes(
                                        code.code
                                    )
                            )}
                            isMulti
                            multiHasValues={
                                Boolean(watch("diagnosis_codes")) ||
                                Boolean(
                                    noteDetails.data?.data
                                        .manual_note_diagnosis_codes &&
                                        noteDetails.data?.data
                                            .manual_note_diagnosis_codes
                                            .length > 0
                                )
                            }
                        />
                    )}
                />
            </div>
            <div className="fg">
                <Controller
                    name="modifier_codes"
                    control={control}
                    defaultValue={noteDetails.data?.data.modifier_codes}
                    render={({ field }) => (
                        <Select
                            label="Modifier code"
                            placeholder="Modifier code"
                            options={modifiersTypesForSelect}
                            isSearchable
                            isLongListInDialog
                            isMulti
                            defaultValue={modifiersTypesForSelect?.filter(
                                (code) =>
                                    noteDetails.data?.data.modifier_codes?.includes(
                                        code.value
                                    )
                            )}
                            onChange={(val) => {
                                field.onChange(
                                    (
                                        val as {
                                            label: string;
                                            value: string;
                                        }[]
                                    ).map((code) => code.value)
                                );
                            }}
                            multiHasValues={
                                Boolean(watch("modifier_codes")) ||
                                Boolean(
                                    noteDetails.data?.data.modifier_codes &&
                                        noteDetails.data?.data.modifier_codes
                                            .length > 0
                                )
                            }
                        />
                    )}
                />
            </div>
            {claimsData?.data[0].claim_status ===
                ClaimStatus.READY_FOR_SUBMISSION && (
                <>
                    {" "}
                    <div
                        className={cn(
                            "flex w-full items-center gap-x-[13px] pt-[31px] pr-[92px] pb-[30px] pl-24 border border-dashed border-primary rounded-[16px]",
                            {
                                "opacity-20": selectedFiles?.length === 1,
                                "border-[2px] border-dashed border-primary-dark":
                                    isDragActive,
                            }
                        )}
                        {...getRootProps()}
                    >
                        <div className="px-[17px] py-16 border border-[#D3F7FA]  bg-primary-50 rounded-[9.667px]">
                            <FolderOpenIcon />
                        </div>
                        <div className="flex flex-col">
                            <div className="text-sm font-medium">
                                <label
                                    htmlFor="file-upload"
                                    className="font-semibold text-primary"
                                >
                                    Click to upload session note{" "}
                                    <input
                                        className="w-full absolute left-[-99999px]"
                                        id="file-upload"
                                        name="file-upload"
                                        type="file"
                                        onChange={handleSetSelectedFiles}
                                        disabled={selectedFiles?.length === 1}
                                        max="1"
                                    />
                                </label>
                                or drag your file here
                            </div>
                            <p className="text-xs font-normal">
                                Maximum file size of 50 MB: .png, .jpg, .jpeg
                            </p>
                        </div>
                    </div>
                    {editManualClaim.uploadProgress > 0 && (
                        <div className="mt-5 h-4 bg-[rgba(185_186_163_0.4)]">
                            <div
                                role="progressbar"
                                aria-label="file upload progress value"
                                aria-valuenow={editManualClaim.uploadProgress}
                                style={{
                                    width: `${editManualClaim.uploadProgress}%`,
                                }}
                                className="h-full rounded-r8 bg-primary"
                            />
                        </div>
                    )}
                    <div className="mt-16">
                        {selectedFiles &&
                            selectedFiles.map((file, i) => (
                                <div
                                    key={`${file.name} - ${i + 1}`}
                                    className="flex items-center justify-between w-full gap-x-16 mt-24"
                                >
                                    <div className=" border border-strokedark rounded-r6 w-full px-10 py-[6px] font-normal text-xs ">
                                        {file.name}
                                    </div>
                                    <Button
                                        variant="normal"
                                        aria-label="delete icon"
                                        className="flex bg-card-bg !rounded-full p-8"
                                        onClick={() =>
                                            handleRemoveSelectedFile(file.name)
                                        }
                                    >
                                        <TimesIcon stroke="#8E8E8E" />
                                    </Button>
                                </div>
                            ))}
                    </div>
                </>
            )}
        </form>
    );
}

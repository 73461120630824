import { configureStore } from "@reduxjs/toolkit";
import navReducer from "./features/nav/navSlice";
import practiceReducer from "./features/practice/practiceSlice";

export const store = configureStore({
    reducer: {
        nav: navReducer,
        userPractice: practiceReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

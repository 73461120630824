import axios from "../../../utils/axios";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";

// Endpoint to fetch clinicians sessions summary
export async function fetchSessionSummary(
    providerId: string,
    customDate?: string
) {
    const response = await axios.get(`/${providerId}/session-summary`, {
        params: handleRemoveEmptyParamFromQueryParams({
            custom_date: customDate,
        }),
    });
    return response.data;
}

// Endpoint to fetch clinicians calendar schedule
export async function fetchProviderCalendar(providerId: string) {
    const response = await axios.get(
        `/${providerId}/calendar?show_paused_clients=false`
    );
    return response.data;
}

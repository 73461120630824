export default function CalendarDayDarker() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.11108 2.44477V0.666992"
                stroke="#0B132B"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.8889 2.44477V0.666992"
                stroke="#0B132B"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.2222 2.44434H3.77778C2.79594 2.44434 2 3.24027 2 4.22211V11.7777C2 12.7595 2.79594 13.5554 3.77778 13.5554H12.2222C13.2041 13.5554 14 12.7595 14 11.7777V4.22211C14 3.24027 13.2041 2.44434 12.2222 2.44434Z"
                stroke="#0B132B"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2 5.55566H14"
                stroke="#0B132B"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.00022 7.33398C7.51044 7.33398 7.11133 7.7331 7.11133 8.22287C7.11133 8.71265 7.51044 9.11176 8.00022 9.11176C8.48999 9.11176 8.88911 8.71265 8.88911 8.22287C8.88911 7.7331 8.48999 7.33398 8.00022 7.33398Z"
                fill="#0B132B"
            />
            <path
                d="M11.1115 9.11176C11.6013 9.11176 12.0004 8.71265 12.0004 8.22287C12.0004 7.7331 11.6013 7.33398 11.1115 7.33398C10.6218 7.33398 10.2227 7.7331 10.2227 8.22287C10.2227 8.71265 10.6218 9.11176 11.1115 9.11176Z"
                fill="#0B132B"
            />
            <path
                d="M8.00022 10C7.51044 10 7.11133 10.3991 7.11133 10.8889C7.11133 11.3787 7.51044 11.7778 8.00022 11.7778C8.48999 11.7778 8.88911 11.3787 8.88911 10.8889C8.88911 10.3991 8.48999 10 8.00022 10Z"
                fill="#0B132B"
            />
            <path
                d="M4.88889 10C4.39911 10 4 10.3991 4 10.8889C4 11.3787 4.39911 11.7778 4.88889 11.7778C5.37867 11.7778 5.77778 11.3787 5.77778 10.8889C5.77778 10.3991 5.37867 10 4.88889 10Z"
                fill="#0B132B"
            />
            <path
                d="M11.1115 10C10.6218 10 10.2227 10.3991 10.2227 10.8889C10.2227 11.3787 10.6218 11.7778 11.1115 11.7778C11.6013 11.7778 12.0004 11.3787 12.0004 10.8889C12.0004 10.3991 11.6013 10 11.1115 10Z"
                fill="#0B132B"
            />
        </svg>
    );
}

export default function PhoneIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="phone 1">
                <g id="Group">
                    <path
                        id="Vector"
                        d="M12.6075 11.8256L11.3397 13.4101C9.37639 12.2556 7.73972 10.6201 6.58639 8.65674L8.17083 7.38896C8.55305 7.08341 8.68083 6.55785 8.48194 6.11119L7.0375 2.85896C6.82305 2.37674 6.29194 2.12119 5.78194 2.25341L3.03194 2.96674C2.50083 3.10563 2.1575 3.62007 2.23305 4.16341C3.22416 11.2234 8.77305 16.7723 15.8342 17.7645C16.3775 17.839 16.8919 17.4956 17.0297 16.9656L17.7431 14.2156C17.8753 13.7056 17.6197 13.1756 17.1386 12.9612L13.8864 11.5167C13.4397 11.3179 12.9153 11.4456 12.6086 11.8267L12.6075 11.8256Z"
                        stroke="#576A81"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </svg>
    );
}

import { Tag } from "@jhool-io/fe-components";
import { ProviderCredential } from "../../clients/types/clients.types";

export const getProviderCredenetialPillStyle = (
    credential: ProviderCredential
) => {
    let bgColor: string;
    let textColor: string;

    switch (credential) {
        case ProviderCredential.LMSW:
        case ProviderCredential.MHCLP:
            textColor = "#634D17";
            bgColor = "rgba(247, 229, 164, 0.50)";
            break;
        case ProviderCredential.LMSWA:
        case ProviderCredential.LMHCA:
        case ProviderCredential.PSYDA:
        case ProviderCredential.PHDA:
        case ProviderCredential.LCSWA:
            textColor = "#165574";
            bgColor = "rgba(172, 222, 250, 0.50)";
            break;
        case ProviderCredential.LMHCB:
        case ProviderCredential.PSYDB:
        case ProviderCredential.PHDB:
        case ProviderCredential.LCSWB:
            textColor = "#981F41";
            bgColor = "rgba(251, 199, 198, 0.50)";
            break;
        case ProviderCredential.LMHCC:
        case ProviderCredential.PSYDC:
        case ProviderCredential.PHDC:
        case ProviderCredential.LCSWC:
            textColor = "#4B0082";
            bgColor = "rgba(221, 160, 221, 0.50)";
            break;
        default:
            textColor = "#00563E";
            bgColor = "rgba(204, 250, 233, 0.50)";
            break;
    }

    return (
        <Tag
            title={credential}
            textColor={textColor}
            bgColor={bgColor}
            className="font-normal px-8 py-4 h-24 rounded-r4"
        />
    );
};

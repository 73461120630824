export default function LinkIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.7135 9.49356C12.5202 9.6869 12.2135 9.6869 12.0268 9.49356C11.8335 9.30023 11.8335 8.99356 12.0268 8.8069C13.3602 7.47356 13.3602 5.3069 12.0268 3.98023C10.6935 2.65356 8.52685 2.6469 7.20018 3.98023C5.87352 5.31356 5.86685 7.48023 7.20018 8.8069C7.39352 9.00023 7.39352 9.3069 7.20018 9.49356C7.00685 9.6869 6.70018 9.6869 6.51352 9.49356C4.80018 7.78023 4.80018 4.99356 6.51352 3.2869C8.22685 1.58023 11.0135 1.57356 12.7202 3.2869C14.4268 5.00023 14.4268 7.78023 12.7135 9.49356Z"
                fill="#0B132B"
            />
            <path
                d="M3.28681 6.50682C3.48014 6.31348 3.78681 6.31348 3.97348 6.50682C4.16681 6.70015 4.16681 7.00682 3.97348 7.19348C2.64014 8.52682 2.64014 10.6935 3.97348 12.0201C5.30681 13.3468 7.47348 13.3535 8.80014 12.0201C10.1268 10.6868 10.1335 8.52015 8.80014 7.19348C8.60681 7.00015 8.60681 6.69348 8.80014 6.50682C8.99348 6.31348 9.30014 6.31348 9.48681 6.50682C11.2001 8.22015 11.2001 11.0068 9.48681 12.7135C7.77348 14.4201 4.98681 14.4268 3.28014 12.7135C1.57348 11.0001 1.57348 8.22015 3.28681 6.50682Z"
                fill="#0B132B"
            />
        </svg>
    );
}

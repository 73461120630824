import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { auth } from "../../firebase";
import { fetchUserDetails } from "../../services/user";
import { IApiResponse } from "../../utils/types/api-response";
import { IUser } from "../../utils/types/user";
import useToast from "../useToast";
import useAuthContext from "../useAuthContext";
import { UserPractice } from "../../modules/user-management/types/user-management.types";
import { areArraysEqual } from "../../utils/helpers";
import { useAppDispatch, useAppSelector } from "../useRedux";
import { handleChangePractice } from "../../redux/features/practice/practiceSlice";

export function useFetchUserDetails() {
    const queryClient = useQueryClient();
    const { toast } = useToast();
    const { user } = useAuthContext();

    const { practice } = useAppSelector((state) => state.userPractice);

    const dispatch = useAppDispatch();

    return useQuery<IUser, AxiosError<IApiResponse<IUser>>>({
        queryKey: ["user-details"],
        queryFn: fetchUserDetails,
        onSuccess: (res) => {
            if (res.practices && res.practices.length > 0) {
                const currentPracticeObjFromLS: UserPractice | null =
                    localStorage.getItem("practice")
                        ? JSON.parse(localStorage.getItem("practice") || "{}")
                        : null;

                // check for current practice from user practices array
                const currentPracticeFromPracticesList = res.practices.find(
                    (userPractice) =>
                        userPractice.practice_id ===
                        (practice?.practice_id ||
                            currentPracticeObjFromLS?.practice_id)
                );

                if (
                    currentPracticeObjFromLS &&
                    res.practices
                        .map((userPractice) => userPractice.practice_id)
                        .includes(currentPracticeObjFromLS.practice_id) &&
                    currentPracticeObjFromLS?.roles &&
                    currentPracticeObjFromLS?.permissions &&
                    areArraysEqual(
                        currentPracticeObjFromLS?.roles,
                        currentPracticeFromPracticesList?.roles || []
                    )
                ) {
                    return;
                }

                localStorage.setItem(
                    "practice",
                    JSON.stringify(res.practices[0])
                );
                dispatch(
                    handleChangePractice({
                        practice: res.practices[0],
                        updateLocation: false,
                    })
                );
            }

            if (localStorage.practice_id) {
                // Will remove 1month from today
                // Ayobami - Fri 7 JUNE 2024
                localStorage.removeItem("practice_id");
            }
        },
        onError: (error) => {
            auth.signOut();
            queryClient.clear();
            toast({
                mode: "error",
                message:
                    error?.response?.data.message ||
                    "Could not log user in at this time",
            });
        },
        enabled: Boolean(user),
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
}

import * as React from "react";
import {
    Button,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SearchInput,
} from "@jhool-io/fe-components";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useDebounce } from "../../../../hooks/helpers";
import { useFetchUsersList } from "../../../user-management/hooks/user-management.queries";
import { useFetchClientList } from "../../../../hooks/queries/client";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import useToast from "../../../../hooks/useToast";
import { useAddUserRestriction } from "../../hooks/client-chart-restriction.mutations";
import { cn } from "../../../../utils/helpers";

interface AddRestrictionFormProps {
    onFormSubmit(): void;
}

export default function AddRestrictionForm({
    onFormSubmit,
}: AddRestrictionFormProps) {
    const [userToRestrictId, setUserToRestrictId] = React.useState<
        string | null
    >(null);
    const [userSearchString, setUserSearchString] = React.useState("");
    const [clientToRestrictId, setClientToRestrictId] = React.useState<
        string | null
    >(null);
    const [clientSearchString, setClientSearchString] = React.useState("");
    const [showUsersListPopover, setShowUsersListPopover] =
        React.useState(false);
    const [showClientsListPopover, setShowClientsListPopover] =
        React.useState(false);

    const usersListContainerRef = React.useRef<HTMLDivElement>(null);
    const clientsListContainerRef = React.useRef<HTMLDivElement>(null);

    const debounceUserSearchString = useDebounce(userSearchString);
    const debounceClientSearchString = useDebounce(clientSearchString);

    const { toast } = useToast();

    const { mutate } = useAddUserRestriction();

    const queryClient = useQueryClient();

    const { handleSubmit } = useForm({
        mode: "onChange",
    });

    const internalUsersList = useFetchUsersList({
        page: 1,
        limit: 20,
        search_string: debounceUserSearchString,
    });

    const clientsList = useFetchClientList({
        page: 1,
        limit: 20,
        search_string: debounceClientSearchString,
    });

    const selectedClientDetails = clientsList?.data?.data.find(
        (client) => client.client_id === clientToRestrictId
    );

    const selectedUserDetails = internalUsersList?.data?.data.find(
        (user) => user.user_id === userToRestrictId
    );

    const onSubmit = () => {
        if (!userToRestrictId || !clientToRestrictId) {
            toast({
                mode: "error",
                message: "Fields cannot be empty",
            });
            return;
        }

        const dataToSend = {
            client_ids: [clientToRestrictId],
            user_id: userToRestrictId,
        };

        mutate(dataToSend, {
            onSuccess: (res) => {
                queryClient.invalidateQueries({
                    queryKey: ["restricted-users"],
                });
                toast({
                    mode: "success",
                    message: res.message || "Restriction added successfully",
                });

                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not add restriction at this time",
                });
            },
        });
    };

    return (
        <form
            id="add-restriction-form"
            name="add-restriction-form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="fg" ref={clientsListContainerRef}>
                <Popover
                    open={showClientsListPopover}
                    onOpenChange={(state) => setShowClientsListPopover(state)}
                >
                    <PopoverTrigger
                        asChild
                        onClick={() => setShowClientsListPopover(true)}
                    >
                        <FilterButton
                            text={
                                selectedClientDetails
                                    ? `${selectedClientDetails.first_name} ${selectedClientDetails.last_name}`
                                    : "Client name"
                            }
                            classNames={cn(
                                "h-40 normal-case w-full justify-start justify-between",
                                {
                                    capitalize: selectedClientDetails,
                                }
                            )}
                        />
                    </PopoverTrigger>
                    <PopoverContent
                        className="px-4 pt-[3px] py-[3px] w-full rounded-r8 border-none shadow-[0px_0px_0px_1px_#98a1b219,_0px_15px_35px_-5px_#11182626,_0px_5px_15px_0px_#00000014] z-[40] popover-full  overscroll-y-auto"
                        align="start"
                        container={clientsListContainerRef.current}
                    >
                        <SearchInput
                            placeholder="Search for client"
                            containerClass="h-24"
                            onChange={(e) =>
                                setClientSearchString(e.target.value)
                            }
                        />

                        <div className="flex flex-col items-start max-h-[230px] overflow-y-auto">
                            {clientsList.isLoading && (
                                <div className="flex flex-col gap-y-4 items-stretch w-full">
                                    {[1, 2, 3, 4, 5].map((item) => (
                                        <Skeleton
                                            key={item}
                                            width="100%"
                                            height="24px"
                                            containerTestId="clients-loader"
                                        />
                                    ))}
                                </div>
                            )}
                            {clientsList.error && (
                                <ListState
                                    isError
                                    context="general"
                                    stateHelperText="Please try again later"
                                    errorMsg={
                                        clientsList.error?.response?.data
                                            ?.message || "Error loading clients"
                                    }
                                />
                            )}
                            {clientsList.data?.data && clientsList.isSuccess && (
                                <>
                                    {clientsList?.data?.data.map((client) => (
                                        <Button
                                            key={client.client_id}
                                            size="auto"
                                            variant="normal"
                                            className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start gap-x-8 !rounded-none"
                                            onClick={() => {
                                                setClientToRestrictId(
                                                    client.client_id
                                                );
                                                setClientSearchString("");
                                                setShowClientsListPopover(
                                                    false
                                                );
                                            }}
                                        >
                                            {`${client.first_name} ${client.last_name}`}
                                            {clientToRestrictId ===
                                            client.client_id ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </Button>
                                    ))}
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="text-xs px-[6px] py-4 font-medium capitalize w-full !rounded-none"
                                        onClick={() => {
                                            setClientToRestrictId("");
                                            setClientSearchString("");
                                            setShowClientsListPopover(false);
                                        }}
                                    >
                                        Clear
                                    </Button>
                                </>
                            )}
                        </div>
                    </PopoverContent>
                </Popover>
            </div>
            <div ref={usersListContainerRef}>
                <Popover
                    open={showUsersListPopover}
                    onOpenChange={(state) => setShowUsersListPopover(state)}
                >
                    <PopoverTrigger
                        asChild
                        onClick={() => setShowUsersListPopover(true)}
                    >
                        <FilterButton
                            text={
                                selectedUserDetails
                                    ? `${selectedUserDetails.first_name} ${selectedUserDetails.last_name}`
                                    : "Restrict user"
                            }
                            classNames={cn(
                                "h-40 normal-case w-full justify-start justify-between",
                                {
                                    capitalize: selectedUserDetails,
                                }
                            )}
                        />
                    </PopoverTrigger>
                    <PopoverContent
                        className="px-4 pt-[3px] py-[3px] popover-full overflow-y-auto  rounded-r8 border-none shadow-[0px_0px_0px_1px_#98a1b219,_0px_15px_35px_-5px_#11182626,_0px_5px_15px_0px_#00000014] z-[40]"
                        align="start"
                        container={usersListContainerRef.current}
                    >
                        <SearchInput
                            placeholder="Search for internal user"
                            containerClass="h-24"
                            onChange={(e) =>
                                setUserSearchString(e.target.value)
                            }
                        />

                        <div className="flex flex-col items-start max-h-[230px] overflow-y-auto">
                            {internalUsersList.isLoading && (
                                <div className="flex flex-col gap-y-4 items-stretch w-full">
                                    {[1, 2, 3, 4, 5].map((item) => (
                                        <Skeleton
                                            key={item}
                                            width="100%"
                                            height="24px"
                                            containerTestId="internalusers-loader"
                                        />
                                    ))}
                                </div>
                            )}
                            {internalUsersList.error && (
                                <ListState
                                    isError
                                    context="general"
                                    stateHelperText="Please try again later"
                                    errorMsg={
                                        internalUsersList.error?.response?.data
                                            ?.message ||
                                        "Error loading internal users"
                                    }
                                />
                            )}
                            {internalUsersList.data?.data &&
                                internalUsersList.isSuccess && (
                                    <>
                                        {internalUsersList?.data?.data.map(
                                            (user) => (
                                                <Button
                                                    key={user.user_id}
                                                    size="auto"
                                                    variant="normal"
                                                    className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start gap-x-8 !rounded-none"
                                                    onClick={() => {
                                                        setUserToRestrictId(
                                                            user.user_id
                                                        );
                                                        setUserSearchString("");
                                                        setShowUsersListPopover(
                                                            false
                                                        );
                                                    }}
                                                >
                                                    {`${user.first_name} ${user.last_name}`}
                                                    {userToRestrictId ===
                                                    user.user_id ? (
                                                        <CheckPrimaryColorIcon />
                                                    ) : null}
                                                </Button>
                                            )
                                        )}
                                        <Button
                                            size="auto"
                                            variant="normal"
                                            className="text-xs px-[6px] py-4 font-medium capitalize w-full !rounded-none items-center"
                                            onClick={() => {
                                                setUserToRestrictId("");
                                                setUserSearchString("");
                                                setShowUsersListPopover(false);
                                            }}
                                        >
                                            Clear
                                        </Button>
                                    </>
                                )}
                        </div>
                    </PopoverContent>
                </Popover>
            </div>
        </form>
    );
}

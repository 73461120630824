export default function BuildingIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.46733 12H2.76733C1.814 12 1.33398 11.52 1.33398 10.5666V2.76666C1.33398 1.81333 1.814 1.33331 2.76733 1.33331H5.63399C6.58733 1.33331 7.0673 1.81333 7.0673 2.76666V3.99998"
                stroke="#576A81"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.5801 5.61332V13.0533C11.5801 14.1267 11.0468 14.6667 9.97347 14.6667H6.08012C5.00679 14.6667 4.4668 14.1267 4.4668 13.0533V5.61332C4.4668 4.53999 5.00679 4 6.08012 4H9.97347C11.0468 4 11.5801 4.53999 11.5801 5.61332Z"
                stroke="#576A81"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.93359 3.99998V2.76666C8.93359 1.81333 9.41357 1.33331 10.3669 1.33331H13.2336C14.1869 1.33331 14.6669 1.81333 14.6669 2.76666V10.5666C14.6669 11.52 14.1869 12 13.2336 12H11.5802"
                stroke="#576A81"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.66602 7.33331H9.33268"
                stroke="#576A81"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.66602 9.33331H9.33268"
                stroke="#576A81"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 14.6667V12.6667"
                stroke="#576A81"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

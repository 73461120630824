export enum SettingsType {
    KEY_VALUE = "key_value",
    STRING = "string",
    BOOLEAN = "boolean",
    NUMBER = "number",
    ARRAY = "array",
}

export interface SettingsFilters {
    setting_name: string;
    category: string;
}
export interface SingleSetting {
    setting_id: string;
    setting_name: string;
    setting_label: string;
    category: string;
    current_value: string;
    default_value: string;
    setting_type: string;
    updated_at: string;
    keyValuesArray?: {
        key: string;
        value: string;
    }[];
}

export interface PracticeAdminResponse {
    settings: SingleSetting[];
}

export interface IAddSettingChanges {
    setting_id: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    current_value: any;
    setting_name?: string;
}

import * as React from "react";
import cn from "classnames";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { ContentState } from "draft-js";
import { Select } from "@jhool-io/fe-components";
import styles from "../ClientMemoList.module.scss";
import { useFetchUserDetails } from "../../../../../hooks/queries/user";
import useToast from "../../../../../hooks/useToast";
import { IPaginatedApiResponse } from "../../../../../utils/types/api-response";
import { UserRole } from "../../../../../utils/types/user";
import { AddTextEditor } from "../../../../../components/TextEditor/AddTextEditor/AddTextEditor";
import {
    ClientMemoCategories,
    IClientMemo,
    INewMemo,
} from "../../../types/clients.types";
import { useAddClientMemo } from "../../../hooks/clients.mutations";
import { useAppSelector } from "../../../../../hooks/useRedux";

interface AddMemoProps {
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
}

type Option = {
    label: string;
    value: boolean;
};

// Array of memo categories
const categories = [
    ClientMemoCategories.CLIENT_SERVICE,
    ClientMemoCategories.BILLING,
    ClientMemoCategories.NOTES,
    ClientMemoCategories.INTAKE,
    ClientMemoCategories.REPORTS,
    ClientMemoCategories.PHONE_NOTES,
];

// For form validation purposes
const schema = yup.object({
    category: yup.string().required("Category is required"),
});

export default function AddMemo({ onFormSubmit }: AddMemoProps) {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<INewMemo>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    // To initialize clientId constant
    const params = useParams();
    const clientId = params.clientId as string;
    const [additonalInformation, setAdditionalInformation] = React.useState("");
    const [isAdditionalInfoProvided, setIsAdditionalInfoProvided] =
        React.useState(true);

    const [getCurrentContent, setGetCurrentContent] =
        React.useState<ContentState>();

    const { data } = useFetchUserDetails();

    const { practice } = useAppSelector((state) => state.userPractice);

    const { mutate } = useAddClientMemo(clientId);

    // Toast for success and error states
    const { toast } = useToast();

    // Query client
    const queryClient = useQueryClient();

    // Category options for the Category select
    const getoptions = () => {
        if (
            practice?.roles?.length === 1 &&
            practice.roles?.[0] === UserRole.PROVIDER
        ) {
            return [
                {
                    label: (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span
                                className={cn(
                                    styles.category,
                                    styles.category_notes
                                )}
                            />
                            <p>Notes</p>
                        </div>
                    ),
                    value: ClientMemoCategories.NOTES,
                },
            ];
        }
        return categories.map((category) => {
            return {
                label: (
                    <div
                        key={category}
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <span
                            className={cn(
                                styles.category,
                                styles[`category_${category}`],
                                {
                                    [styles.category_client_services]:
                                        category ===
                                        ClientMemoCategories.CLIENT_SERVICE,
                                    [styles.category_phone_notes]:
                                        category ===
                                        ClientMemoCategories.PHONE_NOTES,
                                }
                            )}
                        />
                        <p>{category}</p>
                    </div>
                ),
                value: category,
            };
        });
    };

    // Onsubmit function for the form
    const onSubmit = (memoData: INewMemo) => {
        if (!getCurrentContent?.hasText()) {
            // Description is empty
            setIsAdditionalInfoProvided(false);
            return;
        }

        const dataToSend = {
            ...memoData,
            author: data?.user_id,
            description: additonalInformation,
        };
        mutate(dataToSend, {
            onSuccess: (res) => {
                queryClient.setQueryData<IPaginatedApiResponse<IClientMemo[]>>(
                    [clientId, "memos", { page: 1 }],
                    (prev) => {
                        if (!prev) {
                            return {
                                count: 1,
                                total_count: 1,
                                current_page: 1,
                                total_pages: 1,
                                message: "",
                                data: [res.data],
                            };
                        }
                        return {
                            ...prev,
                            count: prev.count + 1,
                            total_count: prev.total_count + 1,
                            message: "",
                            data: [res.data, ...prev.data],
                        };
                    }
                );
                queryClient.invalidateQueries({
                    queryKey: [clientId, "memos", { page: 1 }],
                });
                toast({
                    mode: "success",
                    message: res.message || "Memo created successfully!",
                });
                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could add memo at this time",
                });
            },
        });
    };

    return (
        <form id="add-memo" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg">
                <Controller
                    name="category"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Category"
                            options={getoptions()}
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                            hasError={!!errors.category}
                            errorText={errors.category?.message}
                            isLongListInDialog
                        />
                    )}
                />
            </div>
            <div className="fg">
                <AddTextEditor
                    title="Description"
                    onEditorTextChange={setAdditionalInformation}
                    setIsRequiredFieldProvided={setGetCurrentContent}
                    isRequiredFieldProvided={
                        isAdditionalInfoProvided || getCurrentContent?.hasText()
                    }
                />
                {!isAdditionalInfoProvided && !getCurrentContent?.hasText() && (
                    <div className={styles.error}>Description is required</div>
                )}
            </div>
        </form>
    );
}

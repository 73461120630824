import cn from "classnames";
import { useGetDiagnosisCodesDisplay } from "../../../../hooks/helpers";
import styles from "../../../../modules/notes/components/NoteContent/NoteContent.module.scss";
import CheckIcon from "../../../Icons/Check";
import { formatDate, removeEnumUnderscore } from "../../../../utils/helpers";
import {
    TREATMENT_REVIEW_NOTE_SECTIONS,
    TREATMENT_REVIEW_NOTE_LABELS,
} from "../../../../utils/constants";
import { IAddendumDetails } from "../../../../utils/types/notes";
import NoteContentItem from "../NoteContentItem/NoteContentItem";
import { AppointmentLocationTeletherapy } from "../../../../utils/types/session";
import useGetDiagnosisCodeToDisplay from "../../../../modules/notes/hooks";

interface ViewTreatmentReviewNoteProps {
    noteContent: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    };
    fieldAddendums: {
        [key: string]: IAddendumDetails[];
    } | null;
    /**
     * Is this view for family treatment review note
     */
    isFamilyTreatmentReview: boolean;
}

export default function ViewTreatmentReviewNote({
    noteContent,
    fieldAddendums,
    isFamilyTreatmentReview,
}: ViewTreatmentReviewNoteProps) {
    const diagonosisCodes = useGetDiagnosisCodesDisplay(
        noteContent.diagnosis_codes
    );

    const isCheckboxChecked = (checkboxValue: string, key: string) => {
        return Boolean(
            noteContent?.[key]?.find(
                (value: string) => value.trim() === checkboxValue.trim()
            )
        );
    };

    return (
        <>
            {isFamilyTreatmentReview ? (
                <NoteContentItem
                    headerText={TREATMENT_REVIEW_NOTE_LABELS.isClientPresent}
                    fieldId="isClientPresent"
                    addendums={fieldAddendums?.isClientPresent}
                    content={
                        <div className={styles.checkbox}>
                            <span
                                className={cn(styles.checkbox_wrap, {
                                    [styles.checked]:
                                        noteContent.isClientPresent,
                                })}
                            >
                                <CheckIcon />
                            </span>
                            {TREATMENT_REVIEW_NOTE_LABELS.isClientPresent}
                        </div>
                    }
                />
            ) : null}
            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.appointment_location}
                fieldId="appointment_location"
                addendums={fieldAddendums?.appointment_location}
                content={
                    removeEnumUnderscore(noteContent.appointment_location) ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.delivery_option}
                fieldId="delivery_option"
                addendums={fieldAddendums?.delivery_option}
                content={noteContent.delivery_option || "--"}
            />
            {noteContent.other_delivery_option &&
            noteContent.delivery_option ===
                AppointmentLocationTeletherapy.OTHER ? (
                <NoteContentItem
                    headerText={
                        TREATMENT_REVIEW_NOTE_LABELS.other_delivery_option
                    }
                    fieldId="other_delivery_option"
                    addendums={fieldAddendums?.other_delivery_option}
                    content={noteContent.other_delivery_option}
                />
            ) : null}
            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.provider_location}
                fieldId="provider_location"
                addendums={fieldAddendums?.provider_location}
                content={noteContent.provider_location || "--"}
            />
            {noteContent.other_provider_location &&
            noteContent.provider_location ===
                AppointmentLocationTeletherapy.OTHER ? (
                <NoteContentItem
                    headerText={
                        TREATMENT_REVIEW_NOTE_LABELS.other_provider_location
                    }
                    fieldId="other_provider_location"
                    addendums={fieldAddendums?.other_provider_location}
                    content={noteContent.other_provider_location}
                />
            ) : null}
            <NoteContentItem
                headerText={
                    TREATMENT_REVIEW_NOTE_LABELS.consent_was_received_for_the_teletherapy_session
                }
                fieldId="consent_was_received_for_the_teletherapy_session"
                addendums={
                    fieldAddendums?.consent_was_received_for_the_teletherapy_session
                }
                content={
                    <div className={styles.checkbox}>
                        <span
                            className={cn(styles.checkbox_wrap, {
                                [styles.checked]:
                                    noteContent.consent_was_received_for_the_teletherapy_session,
                            })}
                        >
                            {" "}
                            <CheckIcon />
                        </span>
                        {
                            TREATMENT_REVIEW_NOTE_LABELS.consent_was_received_for_the_teletherapy_session
                        }
                    </div>
                }
            />

            <NoteContentItem
                headerText={
                    TREATMENT_REVIEW_NOTE_LABELS.the_teletherapy_session_was_conducted_via_video
                }
                fieldId="the_teletherapy_session_was_conducted_via_video"
                addendums={
                    fieldAddendums?.the_teletherapy_session_was_conducted_via_video
                }
                content={
                    <div className={styles.checkbox}>
                        <span
                            className={cn(styles.checkbox_wrap, {
                                [styles.checked]:
                                    noteContent.the_teletherapy_session_was_conducted_via_video,
                            })}
                        >
                            {" "}
                            <CheckIcon />
                        </span>
                        {
                            TREATMENT_REVIEW_NOTE_LABELS.the_teletherapy_session_was_conducted_via_video
                        }
                    </div>
                }
            />
            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.diagnosis_codes}
                fieldId="diagnosis_codes"
                addendums={fieldAddendums?.diagnosis_codes}
                content={
                    diagonosisCodes && Array.isArray(diagonosisCodes) ? (
                        <div className={styles.checkboxes}>
                            {diagonosisCodes.map((code) => (
                                <div className={styles.checkbox} key={code}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: true,
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    {code}
                                </div>
                            ))}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.secondary_diagnosis}
                fieldId="secondary_diagnosis"
                addendums={fieldAddendums?.secondary_diagnosis}
                content={useGetDiagnosisCodeToDisplay(
                    noteContent.secondary_diagnosis
                )}
            />
            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.tertiary_diagnosis}
                fieldId="tertiary_diagnosis"
                addendums={fieldAddendums?.tertiary_diagnosis}
                content={useGetDiagnosisCodeToDisplay(
                    noteContent.tertiary_diagnosis
                )}
            />
            <NoteContentItem
                headerText={
                    TREATMENT_REVIEW_NOTE_LABELS.what_are_the_problem_you_are_seeking_help_for
                }
                fieldId="what_are_the_problem_you_are_seeking_help_for"
                addendums={
                    fieldAddendums?.what_are_the_problem_you_are_seeking_help_for
                }
                content={
                    noteContent.what_are_the_problem_you_are_seeking_help_for ||
                    "--"
                }
            />
            <p
                className={styles.sectionheader}
                id={TREATMENT_REVIEW_NOTE_SECTIONS.symptoms}
            >
                Symptoms
            </p>

            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.anxiety}
                fieldId="anxiety"
                addendums={fieldAddendums?.anxiety}
                content={
                    <div className={styles.items}>
                        <div className={styles.checkbox}>
                            <span
                                className={cn(styles.checkbox_wrap, {
                                    [styles.checked]: noteContent.anxiety,
                                })}
                            >
                                <CheckIcon />
                            </span>
                            {TREATMENT_REVIEW_NOTE_LABELS.anxiety}
                        </div>
                        {noteContent.anxiety_frequency && (
                            <p>{`Frequency : ${noteContent.anxiety_frequency}`}</p>
                        )}
                        {noteContent.anxiety_details && (
                            <p>{`Details : ${noteContent.anxiety_details}`}</p>
                        )}
                    </div>
                }
            />

            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.panic_attacks}
                fieldId="panic_attacks"
                addendums={fieldAddendums?.panic_attacks}
                content={
                    <div className={styles.items}>
                        <div className={styles.checkbox}>
                            <span
                                className={cn(styles.checkbox_wrap, {
                                    [styles.checked]: noteContent.panic_attacks,
                                })}
                            >
                                <CheckIcon />
                            </span>
                            {TREATMENT_REVIEW_NOTE_LABELS.panic_attacks}
                        </div>
                        {noteContent.panic_attacks_freqency && (
                            <p>{`Frequency : ${noteContent.panic_attacks_freqency}`}</p>
                        )}
                        {noteContent.panic_attacks_details && (
                            <p>{`Details : ${noteContent.panic_attacks_details}`}</p>
                        )}
                    </div>
                }
            />

            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.depression}
                fieldId="depression"
                addendums={fieldAddendums?.depression}
                content={
                    <div className={styles.items}>
                        <div className={styles.checkbox}>
                            <span
                                className={cn(styles.checkbox_wrap, {
                                    [styles.checked]: noteContent.depression,
                                })}
                            >
                                <CheckIcon />
                            </span>
                            {TREATMENT_REVIEW_NOTE_LABELS.depression}
                        </div>
                        {noteContent.depression_freqency && (
                            <p>{`Frequency : ${noteContent.depression_freqency}`}</p>
                        )}
                        {noteContent.depression_details && (
                            <p>{`Details : ${noteContent.depression_details}`}</p>
                        )}
                    </div>
                }
            />
            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.selected_symptoms}
                fieldId="selected_symptoms"
                addendums={fieldAddendums?.selected_symptoms}
                content={
                    noteContent?.selected_symptoms?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Easily distracted",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Easily distracted
                                </div>
                            )}

                            {isCheckboxChecked(
                                "Impulsive",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Impulsive
                                </div>
                            )}

                            {isCheckboxChecked(
                                "Paranoia",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Paranoia
                                </div>
                            )}

                            {isCheckboxChecked(
                                "Alcohol",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Alcohol
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Anger outburst",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Anger outburst
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Unable to feel pleasure",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Unable to feel pleasure
                                </div>
                            )}

                            {isCheckboxChecked(
                                "Excessive energy",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Excessive energy
                                </div>
                            )}

                            {isCheckboxChecked(
                                "Recreational drug use",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Recreational drug use
                                </div>
                            )}

                            {isCheckboxChecked(
                                "Tobacco",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Tobacco
                                </div>
                            )}

                            {isCheckboxChecked(
                                "Racing thoughts",
                                "selected_symptoms"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Racing thoughts
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />

            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.other_symptoms}
                fieldId="other_symptoms"
                addendums={fieldAddendums?.other_symptoms}
                content={noteContent.other_symptoms || "--"}
            />
            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.physical_symptoms}
                fieldId="physical_symptoms"
                addendums={fieldAddendums?.physical_symptoms}
                content={noteContent.physical_symptoms || "--"}
            />
            <NoteContentItem
                headerText={
                    TREATMENT_REVIEW_NOTE_LABELS.medication_physical_psychiatric
                }
                fieldId="medication_physical_psychiatric"
                addendums={fieldAddendums?.medication_physical_psychiatric}
                content={noteContent.medication_physical_psychiatric || "--"}
            />
            <NoteContentItem
                headerText={
                    TREATMENT_REVIEW_NOTE_LABELS.sleeping_habits_and_concerns
                }
                fieldId="sleeping_habits_and_concerns"
                addendums={fieldAddendums?.sleeping_habits_and_concerns}
                content={noteContent.sleeping_habits_and_concerns || "--"}
            />
            <NoteContentItem
                headerText={
                    TREATMENT_REVIEW_NOTE_LABELS.difficulties_with_appetite_or_eating_patterns
                }
                fieldId="difficulties_with_appetite_or_eating_patterns"
                addendums={
                    fieldAddendums?.difficulties_with_appetite_or_eating_patterns
                }
                content={
                    noteContent.difficulties_with_appetite_or_eating_patterns ||
                    "--"
                }
            />

            <p
                className={styles.sectionheader}
                id={TREATMENT_REVIEW_NOTE_SECTIONS["suicide risk assessment"]}
            >
                Suicide risk assessment
            </p>
            <NoteContentItem
                headerText={`1.${TREATMENT_REVIEW_NOTE_LABELS.been_so_distressed_you_seriously_wished_to_end_your_life}`}
                fieldId="been_so_distressed_you_seriously_wished_to_end_your_life"
                addendums={
                    fieldAddendums?.been_so_distressed_you_seriously_wished_to_end_your_life
                }
                content={
                    noteContent.been_so_distressed_you_seriously_wished_to_end_your_life ||
                    "--"
                }
            />

            <NoteContentItem
                headerText={`2.${TREATMENT_REVIEW_NOTE_LABELS.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live}`}
                fieldId="has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live"
                addendums={
                    fieldAddendums?.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
                }
                content={
                    noteContent.has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live ||
                    "--"
                }
            />

            <NoteContentItem
                headerText={
                    TREATMENT_REVIEW_NOTE_LABELS.describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
                }
                fieldId="describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live"
                addendums={
                    fieldAddendums?.describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live
                }
                content={
                    noteContent.describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live ||
                    "--"
                }
            />

            <NoteContentItem
                headerText={`3.${TREATMENT_REVIEW_NOTE_LABELS.a_specific_plan_of_how_you_would_kill_yourself}`}
                fieldId="a_specific_plan_of_how_you_would_kill_yourself"
                addendums={
                    fieldAddendums?.a_specific_plan_of_how_you_would_kill_yourself
                }
                content={
                    noteContent.a_specific_plan_of_how_you_would_kill_yourself ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={`4.${TREATMENT_REVIEW_NOTE_LABELS.access_to_weapons_or_means_of_hurting_self}`}
                fieldId="access_to_weapons_or_means_of_hurting_self"
                addendums={
                    fieldAddendums?.access_to_weapons_or_means_of_hurting_self
                }
                content={
                    noteContent.access_to_weapons_or_means_of_hurting_self ||
                    "--"
                }
            />

            <NoteContentItem
                headerText={`5.${TREATMENT_REVIEW_NOTE_LABELS.made_serious_suicide_attempts}`}
                fieldId="made_serious_suicide_attempts"
                addendums={fieldAddendums?.made_serious_suicide_attempts}
                content={noteContent.made_serious_suicide_attempts || "--"}
            />

            <NoteContentItem
                headerText={`6.${TREATMENT_REVIEW_NOTE_LABELS.purposely_done_something_to_hurt_yourself}`}
                fieldId="purposely_done_something_to_hurt_yourself"
                addendums={
                    fieldAddendums?.purposely_done_something_to_hurt_yourself
                }
                content={
                    noteContent.purposely_done_something_to_hurt_yourself ||
                    "--"
                }
            />

            <NoteContentItem
                headerText={
                    TREATMENT_REVIEW_NOTE_LABELS.describe_done_something_to_hurt_yourself
                }
                fieldId="describe_done_something_to_hurt_yourself"
                addendums={
                    fieldAddendums?.describe_done_something_to_hurt_yourself
                }
                content={
                    noteContent.describe_done_something_to_hurt_yourself || "--"
                }
            />

            <NoteContentItem
                headerText={`7.${TREATMENT_REVIEW_NOTE_LABELS.heard_voices_telling_you_to_hurt_yourself}`}
                fieldId="heard_voices_telling_you_to_hurt_yourself"
                addendums={
                    fieldAddendums?.heard_voices_telling_you_to_hurt_yourself
                }
                content={
                    noteContent.heard_voices_telling_you_to_hurt_yourself ||
                    "--"
                }
            />

            <NoteContentItem
                headerText={`8.${TREATMENT_REVIEW_NOTE_LABELS.had_relatives_who_attempted_or_commited_sucide}`}
                fieldId="had_relatives_who_attempted_or_commited_sucide"
                addendums={
                    fieldAddendums?.had_relatives_who_attempted_or_commited_sucide
                }
                content={
                    noteContent.had_relatives_who_attempted_or_commited_sucide ||
                    "--"
                }
            />

            <NoteContentItem
                headerText={`9.${TREATMENT_REVIEW_NOTE_LABELS.had_thoughts_of_killing_or_seriously_hurting_someone}`}
                fieldId="had_thoughts_of_killing_or_seriously_hurting_someone"
                addendums={
                    fieldAddendums?.had_thoughts_of_killing_or_seriously_hurting_someone
                }
                content={
                    noteContent.had_thoughts_of_killing_or_seriously_hurting_someone ||
                    "--"
                }
            />

            <NoteContentItem
                headerText={`10.${TREATMENT_REVIEW_NOTE_LABELS.heard_voices_telling_you_to_hurt_others}`}
                fieldId="heard_voices_telling_you_to_hurt_others"
                addendums={
                    fieldAddendums?.heard_voices_telling_you_to_hurt_others
                }
                content={
                    noteContent.heard_voices_telling_you_to_hurt_others || "--"
                }
            />

            <NoteContentItem
                headerText={`11.${TREATMENT_REVIEW_NOTE_LABELS.hurt_someone_or_destroyed_property_on_purpose}`}
                fieldId="hurt_someone_or_destroyed_property_on_purpose"
                addendums={
                    fieldAddendums?.hurt_someone_or_destroyed_property_on_purpose
                }
                content={
                    noteContent.hurt_someone_or_destroyed_property_on_purpose ||
                    "--"
                }
            />

            <NoteContentItem
                headerText={`12.${TREATMENT_REVIEW_NOTE_LABELS.slapped_kicked_punched_someone_with_intent_to_harm}`}
                fieldId="slapped_kicked_punched_someone_with_intent_to_harm"
                addendums={
                    fieldAddendums?.slapped_kicked_punched_someone_with_intent_to_harm
                }
                content={
                    noteContent.slapped_kicked_punched_someone_with_intent_to_harm ||
                    "--"
                }
            />

            <NoteContentItem
                headerText={`13.${TREATMENT_REVIEW_NOTE_LABELS.been_arrested_or_detained_for_violent_behavior}`}
                fieldId="been_arrested_or_detained_for_violent_behavior"
                addendums={
                    fieldAddendums?.been_arrested_or_detained_for_violent_behavior
                }
                content={
                    noteContent.been_arrested_or_detained_for_violent_behavior ||
                    "--"
                }
            />

            <NoteContentItem
                headerText={`14.${TREATMENT_REVIEW_NOTE_LABELS.been_to_jail_for_any_reason}`}
                fieldId="been_to_jail_for_any_reason"
                addendums={fieldAddendums?.been_to_jail_for_any_reason}
                content={noteContent.been_to_jail_for_any_reason || "--"}
            />

            <NoteContentItem
                headerText={`15.${TREATMENT_REVIEW_NOTE_LABELS.been_on_probation_for_any_reason}`}
                fieldId="been_on_probation_for_any_reason"
                addendums={fieldAddendums?.been_on_probation_for_any_reason}
                content={noteContent.been_on_probation_for_any_reason || "--"}
            />

            <NoteContentItem
                headerText={`16.${TREATMENT_REVIEW_NOTE_LABELS.do_you_have_access_to_guns}`}
                fieldId="do_you_have_access_to_guns"
                addendums={fieldAddendums?.do_you_have_access_to_guns}
                content={noteContent.do_you_have_access_to_guns || "--"}
            />

            <NoteContentItem
                headerText={
                    TREATMENT_REVIEW_NOTE_LABELS.patients_ability_and_capability_to_respond_to_treatment
                }
                fieldId="patients_ability_and_capability_to_respond_to_treatment"
                addendums={
                    fieldAddendums?.patients_ability_and_capability_to_respond_to_treatment
                }
                content={
                    noteContent
                        ?.patients_ability_and_capability_to_respond_to_treatment
                        ?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Consistent, good-quality patient-oriented evidence",
                                "patients_ability_and_capability_to_respond_to_treatment"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Consistent, good-quality patient-oriented
                                    evidence
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Diminished/poor or limited-quality patient-oriented evidence",
                                "patients_ability_and_capability_to_respond_to_treatment"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Diminished/poor or limited-quality
                                    patient-oriented evidence
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />

            <NoteContentItem
                headerText={
                    TREATMENT_REVIEW_NOTE_LABELS.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                }
                fieldId="details_for_diminished_poor_limited_quality_patient_oriented_evidence "
                addendums={
                    fieldAddendums?.details_for_diminished_poor_limited_quality_patient_oriented_evidence
                }
                content={
                    noteContent.details_for_diminished_poor_limited_quality_patient_oriented_evidence ||
                    "--"
                }
            />

            <p
                className={styles.sectionheader}
                id={TREATMENT_REVIEW_NOTE_SECTIONS.summary}
            >
                Summary
            </p>
            <NoteContentItem
                headerText={
                    TREATMENT_REVIEW_NOTE_LABELS.people_present_at_session
                }
                fieldId="people_present_at_session"
                addendums={fieldAddendums?.people_present_at_session}
                content={
                    noteContent?.people_present_at_session?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Patient",
                                "people_present_at_session"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Patient
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Mother",
                                "people_present_at_session"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Mother
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Stepfather",
                                "people_present_at_session"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Stepfather
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Spouse",
                                "people_present_at_session"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Spouse
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Father",
                                "people_present_at_session"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Father
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Stepmother",
                                "people_present_at_session"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Stepmother
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Partner",
                                "people_present_at_session"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Partner
                                </div>
                            )}

                            {isCheckboxChecked(
                                "Guardian",
                                "people_present_at_session"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Guardian
                                </div>
                            )}

                            {isCheckboxChecked(
                                "Other",
                                "people_present_at_session"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Other
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    TREATMENT_REVIEW_NOTE_LABELS.other_person_present_at_session
                }
                fieldId="other_person_present_at_session "
                addendums={fieldAddendums?.other_person_present_at_session}
                content={noteContent.other_person_present_at_session || "--"}
            />

            <NoteContentItem
                headerText={
                    TREATMENT_REVIEW_NOTE_LABELS.type_of_treatment_recommended
                }
                fieldId="type_of_treatment_recommended"
                addendums={fieldAddendums?.type_of_treatment_recommended}
                content={
                    noteContent?.type_of_treatment_recommended?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Individual",
                                "type_of_treatment_recommended"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Individual
                                </div>
                            )}

                            {isCheckboxChecked(
                                "Family",
                                "type_of_treatment_recommended"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Family
                                </div>
                            )}

                            {isCheckboxChecked(
                                "Group",
                                "type_of_treatment_recommended"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Group
                                </div>
                            )}

                            {isCheckboxChecked(
                                "Collateral/Parenting",
                                "type_of_treatment_recommended"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Collateral/Parenting
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />

            <NoteContentItem
                headerText={
                    TREATMENT_REVIEW_NOTE_LABELS.treatment_modality_intervention
                }
                fieldId="treatment_modality_intervention"
                addendums={fieldAddendums?.treatment_modality_intervention}
                content={
                    noteContent?.treatment_modality_intervention?.length > 0 ? (
                        <div className={styles.checkboxes}>
                            {isCheckboxChecked(
                                "Cognitive/Behavioral",
                                "treatment_modality_intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Cognitive/Behavioral
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Behavioral Modification",
                                "treatment_modality_intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Behavioral Modification
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Supportive",
                                "treatment_modality_intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Supportive
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Marital/Couples Therapy",
                                "treatment_modality_intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Marital/Couples Therapy
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Family Therapy",
                                "treatment_modality_intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Family Therapy
                                </div>
                            )}
                            {isCheckboxChecked(
                                "Stress Management",
                                "treatment_modality_intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Stress Management
                                </div>
                            )}

                            {isCheckboxChecked(
                                "Psychodynamic",
                                "treatment_modality_intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Psychodynamic
                                </div>
                            )}

                            {isCheckboxChecked(
                                "Parent Training",
                                "treatment_modality_intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Parent Training
                                </div>
                            )}

                            {isCheckboxChecked(
                                "Other",
                                "treatment_modality_intervention"
                            ) && (
                                <div className={styles.checkbox}>
                                    <span
                                        className={cn(
                                            styles.checkbox_wrap,
                                            styles.checked
                                        )}
                                    >
                                        <CheckIcon />
                                    </span>
                                    Other
                                </div>
                            )}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    TREATMENT_REVIEW_NOTE_LABELS.other_treatment_modality_intervention
                }
                fieldId="other_treatment_modality_intervention"
                addendums={
                    fieldAddendums?.other_treatment_modality_intervention
                }
                content={
                    noteContent.other_treatment_modality_intervention || "--"
                }
            />
            <p
                className={styles.sectionheader}
                id={TREATMENT_REVIEW_NOTE_SECTIONS["treatment goals"]}
            >
                Treatment goals
            </p>
            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.treatment_goal_one}
                fieldId="treatment_goal_one"
                addendums={fieldAddendums?.treatment_goal_one}
                content={noteContent.treatment_goal_one || "--"}
            />
            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.treatment_goal_two}
                fieldId="treatment_goal_two"
                addendums={fieldAddendums?.treatment_goal_two}
                content={noteContent.treatment_goal_two || "--"}
            />
            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.treatment_goal_three}
                fieldId="treatment_goal_three"
                addendums={fieldAddendums?.treatment_goal_three}
                content={noteContent.treatment_goal_three || "--"}
            />
            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.medication_updates}
                fieldId="medication_updates"
                addendums={fieldAddendums?.medication_updates}
                content={noteContent.medication_updates || "--"}
            />
            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.frequency_of_sessions}
                fieldId="frequency_of_sessions"
                addendums={fieldAddendums?.frequency_of_sessions}
                content={noteContent.frequency_of_sessions || "--"}
            />
            {noteContent.other_frequency_of_sessions ? (
                <NoteContentItem
                    headerText={
                        TREATMENT_REVIEW_NOTE_LABELS.other_frequency_of_sessions
                    }
                    fieldId="other_frequency_of_sessions"
                    addendums={fieldAddendums?.other_frequency_of_sessions}
                    content={noteContent.other_frequency_of_sessions || "--"}
                />
            ) : null}
            <NoteContentItem
                headerText={
                    TREATMENT_REVIEW_NOTE_LABELS.expected_termination_date
                }
                fieldId="expected_termination_date"
                addendums={fieldAddendums?.expected_termination_date}
                content={
                    noteContent.expected_termination_date
                        ? formatDate(
                              noteContent.expected_termination_date,
                              true
                          )
                        : "--"
                }
            />
            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.plan_of_care}
                fieldId="plan_of_care"
                addendums={fieldAddendums?.plan_of_care}
                content={noteContent.plan_of_care || "--"}
            />

            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.prognosis}
                fieldId="prognosis"
                addendums={fieldAddendums?.prognosis}
                content={noteContent.prognosis || "--"}
            />

            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.subjective_report}
                fieldId="subjective_report"
                addendums={fieldAddendums?.subjective_report}
                content={noteContent.subjective_report || "--"}
            />
            <NoteContentItem
                headerText={TREATMENT_REVIEW_NOTE_LABELS.summary}
                fieldId="summary"
                addendums={fieldAddendums?.summary}
                content={noteContent.summary || "--"}
            />
        </>
    );
}

export default function ScreenIcon() {
    return (
        <svg
            width="27"
            height="24"
            viewBox="0 0 27 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M4.5 4.5H22.5V15H4.5V4.5Z" fill="#576A81" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 2.25C0 1.65326 0.237053 1.08097 0.65901 0.65901C1.08097 0.237053 1.65326 0 2.25 0H24.75C25.3467 0 25.919 0.237053 26.341 0.65901C26.7629 1.08097 27 1.65326 27 2.25V17.25C27 17.8467 26.7629 18.419 26.341 18.841C25.919 19.2629 25.3467 19.5 24.75 19.5H16.5V21.75H21.375C21.6734 21.75 21.9595 21.8685 22.1705 22.0795C22.3815 22.2905 22.5 22.5766 22.5 22.875C22.5 23.1734 22.3815 23.4595 22.1705 23.6705C21.9595 23.8815 21.6734 24 21.375 24H5.625C5.32663 24 5.04048 23.8815 4.8295 23.6705C4.61853 23.4595 4.5 23.1734 4.5 22.875C4.5 22.5766 4.61853 22.2905 4.8295 22.0795C5.04048 21.8685 5.32663 21.75 5.625 21.75H10.5V19.5H2.25C1.65326 19.5 1.08097 19.2629 0.65901 18.841C0.237053 18.419 0 17.8467 0 17.25V2.25ZM24.75 2.25H2.25V17.25H24.75V2.25Z"
                fill="#BCC3CD"
            />
        </svg>
    );
}

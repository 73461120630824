export default function UserWithLaptopIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M8.88835 10.2786C11.1895 10.2786 13.055 8.41317 13.055 6.11198C13.055 3.81079 11.1895 1.94531 8.88835 1.94531C6.58716 1.94531 4.72168 3.81079 4.72168 6.11198C4.72168 8.41317 6.58716 10.2786 8.88835 10.2786Z"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.16992 16.6658C3.61214 14.4902 6.08325 13.0547 8.88881 13.0547"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.5828 18.0547H9.72168L11.7972 13.3847C11.8861 13.1836 12.085 13.0547 12.305 13.0547H18.3117C18.7139 13.0547 18.9828 13.468 18.8195 13.8358L17.0906 17.7247C17.0017 17.9258 16.8028 18.0547 16.5828 18.0547Z"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.72201 18.0547H6.38867"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

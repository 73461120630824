export default function ClockIcon() {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_574_3744)">
                <path
                    opacity="0.4"
                    d="M6 0.1875C2.78906 0.1875 0.1875 2.78906 0.1875 6C0.1875 9.21094 2.78906 11.8125 6 11.8125C9.21094 11.8125 11.8125 9.21094 11.8125 6C11.8125 2.78906 9.21094 0.1875 6 0.1875ZM8.16773 7.52344L7.69898 8.10938C7.66822 8.14783 7.63019 8.17986 7.58705 8.20362C7.54391 8.22738 7.49651 8.24241 7.44757 8.24785C7.39862 8.25329 7.34908 8.24903 7.30178 8.23533C7.25447 8.22162 7.21033 8.19873 7.17188 8.16797L5.60156 7.00266C5.49184 6.91481 5.40328 6.80342 5.34242 6.67672C5.28157 6.55002 5.24998 6.41126 5.25 6.2707V2.625C5.25 2.52554 5.28951 2.43016 5.35984 2.35984C5.43016 2.28951 5.52554 2.25 5.625 2.25H6.375C6.47446 2.25 6.56984 2.28951 6.64017 2.35984C6.71049 2.43016 6.75 2.52554 6.75 2.625V6L8.10938 6.99609C8.14785 7.02687 8.17988 7.06494 8.20364 7.1081C8.2274 7.15127 8.24242 7.1987 8.24784 7.24767C8.25326 7.29665 8.24897 7.34621 8.23523 7.39353C8.22148 7.44084 8.19855 7.48499 8.16773 7.52344Z"
                    fill="#0B7A75"
                />
                <path
                    d="M8.16773 7.52344L7.69898 8.10938C7.66822 8.14783 7.63019 8.17986 7.58705 8.20362C7.54391 8.22738 7.49651 8.24241 7.44757 8.24785C7.39862 8.25329 7.34908 8.24903 7.30178 8.23533C7.25447 8.22162 7.21033 8.19873 7.17188 8.16797L5.60156 7.00266C5.49184 6.91481 5.40328 6.80342 5.34242 6.67672C5.28157 6.55002 5.24998 6.41126 5.25 6.2707V2.625C5.25 2.52554 5.28951 2.43016 5.35984 2.35984C5.43016 2.28951 5.52554 2.25 5.625 2.25H6.375C6.47446 2.25 6.56984 2.28951 6.64017 2.35984C6.71049 2.43016 6.75 2.52554 6.75 2.625V6L8.10938 6.99609C8.14785 7.02687 8.17988 7.06494 8.20364 7.1081C8.2274 7.15127 8.24242 7.1987 8.24784 7.24767C8.25326 7.29665 8.24897 7.34621 8.23523 7.39353C8.22148 7.44084 8.19855 7.48499 8.16773 7.52344Z"
                    fill="#0B7A75"
                />
            </g>
            <defs>
                <clipPath id="clip0_574_3744">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

/* eslint-disable no-restricted-syntax */
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { DatePicker, Input, Select } from "@jhool-io/fe-components";
import { STATES } from "../../../../../../utils/helpers/us-states/us-states";
import useToast from "../../../../../../hooks/useToast";
import {
    NUMBER_REGEX,
    handleFormatDatePickerValue,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../../../utils/helpers";
import { APP_COLORS } from "../../../../../../utils/constants";
import { ProviderStatus } from "../../../../../../utils/types/session";
import { normalizePhoneNumber } from "../../../../../../utils/helpers/phonenumber/phonenumber";
import {
    IClientEditInsuranceDetails,
    IClientInsuranceDetails,
    InsuranceType,
    RelationshipToPrimaryInsured,
    UserSex,
} from "../../../../types/clients.types";
import { useFetchInsuranceProviders } from "../../../../hooks/clients.queries";
import { useEditClientInsuranceDetails } from "../../../../hooks/clients.mutations";

interface EditInsuranceDetailsProps {
    // Current insurance in view
    insuranceDetail: IClientInsuranceDetails;
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
}

type Option = {
    label: React.ReactNode;
    value: boolean;
};

type SelectOption = {
    label: React.ReactNode;
    value: string;
};

const options: Option[] = [
    {
        value: true,
        label: <span style={{ color: APP_COLORS.COLOR_PRIMARY }}>Yes</span>,
    },
    {
        value: false,
        label: <span style={{ color: APP_COLORS.COLOR_DANGER }}>No</span>,
    },
];

// Define schema validation for insurance form fields
const schema = yup.object({
    insurance_provider_id: yup.string().required("Provider's name is required"),
    is_cal_year: yup.boolean(),
    start_date: yup
        .date()
        .nullable()
        .when(["is_cal_year"], {
            is: (is_cal_year: boolean) =>
                is_cal_year === false && typeof is_cal_year !== undefined,
            then: yup.date().required("Start date is required"),
        }),
    end_date: yup
        .date()
        .nullable()
        .when(["is_cal_year"], {
            is: (is_cal_year: boolean) =>
                is_cal_year === false && typeof is_cal_year !== undefined,
            then: yup
                .date()
                .required("End date is required")
                .min(
                    yup.ref("start_date"),
                    "The end date cannot be earlier than the start date"
                ),
        }),
    employer_name: yup.string().required("Employer name is required"),
    insurance_member_id: yup
        .string()
        .required("Insurance member id is required"),
    primary_insured_address: yup.string(),
    last_verified: yup.date().nullable(),
    non_client_primary_insured_name: yup.string(),
    non_client_primary_insured_dob: yup
        .date()
        .nullable()
        .typeError("Invalid Date"),
    primary_insured_state: yup.string(),
    status: yup.string().required("Insurance status is required"),
    insurance_accepted: yup.boolean(),
    primary_insured_zipcode: yup.string().test({
        test: (val) => (val ? NUMBER_REGEX.test(val) : true),
        message: "Field should only contain numbers",
    }),
    relationship_to_primary_insured: yup.string(),
    primary_insured_city: yup.string(),
    primary_insured_phone_no: yup.string(),
    primary_insured_sex: yup.string(),
    primary_insured_dob: yup.date().nullable().typeError("Invalid Date"),
    insured_policy_group: yup.string(),
    insurance_type: yup.string(),
});

const getClientRelationshipToPrimaryInsuredOptions = [
    {
        label: "Relationship to primary insured",
        value: "",
    },
    ...Object.values(RelationshipToPrimaryInsured).map((item) => ({
        label: makeStringFirstLetterCapital(item),
        value: item,
    })),
];
const getClientPrimaryInsuredSexOptions = [
    {
        label: "Primary insured sex",
        value: "",
    },
    ...Object.values(UserSex).map((item) => ({
        label: makeStringFirstLetterCapital(item),
        value: item,
    })),
];
const getClientInsuranceTypeOptions = [
    {
        label: "Insurance type",
        value: "",
    },

    ...Object.values(InsuranceType).map((item) => ({
        label: makeStringFirstLetterCapital(removeEnumUnderscore(item)),
        value: item,
    })),
];

// Function to return select options for client state field
const getClientStateFieldSelectOptions = () => {
    return STATES.map((state) => {
        return {
            label: state.name,
            value: state.abbrv,
        };
    });
};

export default function EditInsuranceDetails({
    insuranceDetail,
    onFormSubmit,
}: EditInsuranceDetailsProps) {
    // Component local states
    const [nonClientPrimaryInsuredDOB, setNonClientPrimaryInsuredDOB] =
        React.useState<Date | null>(
            insuranceDetail.non_client_primary_insured_dob
                ? new Date(insuranceDetail.non_client_primary_insured_dob)
                : null
        );
    const [lastVerified, setLastVerified] = React.useState<Date | null>(
        insuranceDetail.last_verified
            ? new Date(insuranceDetail.last_verified)
            : null
    );
    const [startDate, setStartDate] = React.useState<Date | null>(
        insuranceDetail.start_date ? new Date(insuranceDetail.start_date) : null
    );
    const [endDate, setEndDate] = React.useState<Date | null>(
        insuranceDetail.end_date ? new Date(insuranceDetail.end_date) : null
    );

    const [localDateOfBirth, setLocalDateOfBirth] = React.useState<Date | null>(
        insuranceDetail.primary_insured_dob
            ? new Date(insuranceDetail.primary_insured_dob)
            : null
    );

    // Query client
    const queryClient = useQueryClient();

    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;

    // Hook for fetching insurance providers
    const insuranceProviders = useFetchInsuranceProviders();

    // Select options for insurance providers
    const insuranceProvidersSelectOptions = insuranceProviders.data?.data
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((provider) => ({
            label: removeEnumUnderscore(provider.name).toUpperCase(),
            value: provider.insurance_provider_id,
        }));

    const statusTypesForSelect: SelectOption[] = [
        ...Object.values(ProviderStatus).map((item) => ({
            value: item,
            label: makeStringFirstLetterCapital(item),
        })),
    ];

    // Hook for toast
    const { toast } = useToast();

    // Hook for editing current fee
    const { mutate } = useEditClientInsuranceDetails(
        clientId,
        insuranceDetail.insurance_id
    );

    // Initialize form with Yup validation
    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm<IClientEditInsuranceDetails>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    // Watch value of is_cal_year input field
    const isCalYear = watch("is_cal_year", insuranceDetail.is_cal_year);

    // Function to handle form submission
    const onSubmit = (payload: IClientEditInsuranceDetails) => {
        const dataToSend = {
            ...payload,
        };

        if (payload.last_verified) {
            dataToSend.last_verified = handleFormatDatePickerValue(
                payload.last_verified
            );
        }

        if (payload.end_date) {
            dataToSend.end_date = handleFormatDatePickerValue(payload.end_date);
        } else {
            dataToSend.end_date = undefined;
        }

        if (payload.start_date) {
            dataToSend.start_date = handleFormatDatePickerValue(
                payload.start_date
            );
        } else {
            dataToSend.start_date = undefined;
        }

        if (payload.non_client_primary_insured_dob) {
            dataToSend.non_client_primary_insured_dob =
                handleFormatDatePickerValue(
                    payload.non_client_primary_insured_dob
                );
        }

        if (payload.primary_insured_dob) {
            dataToSend.primary_insured_dob = handleFormatDatePickerValue(
                payload.primary_insured_dob
            );
        }

        if (!dataToSend.insurance_member_id)
            dataToSend.insurance_member_id = undefined;
        if (!dataToSend.last_verified) dataToSend.last_verified = undefined;
        if (!dataToSend.non_client_primary_insured_name)
            dataToSend.non_client_primary_insured_name = undefined;
        if (!dataToSend.non_client_primary_insured_dob)
            dataToSend.non_client_primary_insured_dob = undefined;
        if (!dataToSend.relationship_to_primary_insured)
            dataToSend.relationship_to_primary_insured = undefined;
        if (!dataToSend.primary_insured_city)
            dataToSend.primary_insured_city = undefined;
        if (!dataToSend.primary_insured_phone_no)
            dataToSend.primary_insured_phone_no = undefined;
        if (!dataToSend.primary_insured_sex)
            dataToSend.primary_insured_sex = undefined;
        if (!dataToSend.primary_insured_dob)
            dataToSend.primary_insured_dob = undefined;
        if (!dataToSend.insured_policy_group)
            dataToSend.insured_policy_group = undefined;
        if (!dataToSend.insurance_type) dataToSend.insurance_type = undefined;
        if (!dataToSend.primary_insured_address)
            dataToSend.primary_insured_address = undefined;
        if (!dataToSend.primary_insured_state)
            dataToSend.primary_insured_state = undefined;
        if (!dataToSend.primary_insured_zipcode)
            dataToSend.primary_insured_zipcode = undefined;

        mutate(dataToSend, {
            onSuccess: ({ message }) => {
                queryClient.invalidateQueries({
                    queryKey: [clientId, "insurance"],
                });
                toast({
                    mode: "success",
                    message: message || "Insurance details edited!",
                });
                onFormSubmit();
            },
            onError: (error) => {
                toast({
                    mode: "error",
                    message:
                        error.response?.data.message ||
                        "Could not edit client insurance details at this time",
                });
            },
        });
    };

    // Watch for when contact values in our form change
    const phoneNo = watch("primary_insured_phone_no");

    // Run helper to format contact values when it change
    React.useEffect(() => {
        if (phoneNo) {
            setValue("primary_insured_phone_no", normalizePhoneNumber(phoneNo));
        }
    }, [phoneNo, setValue]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} id="edit-insurance">
            <div>
                <div className="fg">
                    <Controller
                        name="insurance_provider_id"
                        control={control}
                        defaultValue={
                            insuranceProvidersSelectOptions?.find(
                                (option) =>
                                    option.label.toLowerCase() ===
                                    insuranceDetail?.name.toLowerCase()
                            )?.value
                        }
                        render={({ field }) => (
                            <Select
                                label="Insurance Provider"
                                defaultValue={insuranceProvidersSelectOptions?.find(
                                    (option) =>
                                        option.label.toLowerCase() ===
                                        insuranceDetail?.name.toLowerCase()
                                )}
                                options={insuranceProvidersSelectOptions}
                                onChange={(val) =>
                                    field.onChange((val as SelectOption).value)
                                }
                                hasError={!!errors.insurance_provider_id}
                                errorText={
                                    errors.insurance_provider_id?.message
                                }
                                isSearchable
                            />
                        )}
                    />
                </div>
                <div className="fg">
                    <Controller
                        name="is_cal_year"
                        control={control}
                        defaultValue={
                            options.find(
                                (option) =>
                                    option.value === insuranceDetail.is_cal_year
                            )?.value
                        }
                        render={({ field }) => (
                            <Select
                                defaultValue={options.find(
                                    (option) =>
                                        option.value ===
                                        insuranceDetail.is_cal_year
                                )}
                                label="Calendar Year"
                                placeholder="Calendar Year"
                                options={options}
                                onChange={(val) => {
                                    field.onChange((val as Option).value);
                                }}
                                hasError={!!errors.is_cal_year}
                                errorText={errors.is_cal_year?.message}
                            />
                        )}
                    />
                </div>
                <div className="fg">
                    {isCalYear === false && typeof isCalYear !== undefined && (
                        <div className="fg fg-space-between two flex">
                            <Controller
                                name="start_date"
                                control={control}
                                defaultValue={insuranceDetail.start_date}
                                render={({ field }) => (
                                    <DatePicker
                                        label="Start date"
                                        onChange={(date) => {
                                            field.onChange(date);
                                            setStartDate(date);
                                        }}
                                        selected={startDate}
                                        hasError={!!errors.start_date}
                                        errorText={
                                            errors.start_date?.type ===
                                            "typeError"
                                                ? "invalid date value"
                                                : errors.start_date?.message
                                        }
                                    />
                                )}
                            />
                            <Controller
                                name="end_date"
                                control={control}
                                defaultValue={insuranceDetail.end_date}
                                render={({ field }) => (
                                    <DatePicker
                                        label="End date"
                                        onChange={(date) => {
                                            field.onChange(date);
                                            setEndDate(date);
                                        }}
                                        selected={endDate}
                                        hasError={!!errors.end_date}
                                        errorText={
                                            errors.end_date?.type ===
                                            "typeError"
                                                ? "invalid date value"
                                                : errors.end_date?.message
                                        }
                                    />
                                )}
                            />
                        </div>
                    )}
                </div>
                <div className="fg">
                    <Controller
                        name="insurance_type"
                        control={control}
                        defaultValue={
                            getClientInsuranceTypeOptions.find(
                                (option) =>
                                    option.value ===
                                        insuranceDetail.insurance_type || ""
                            )?.value as InsuranceType
                        }
                        render={({ field }) => (
                            <Select
                                defaultValue={getClientInsuranceTypeOptions.find(
                                    (option) =>
                                        option.value ===
                                            insuranceDetail.insurance_type || ""
                                )}
                                label="Insurance type"
                                placeholder="Insurance type"
                                options={getClientInsuranceTypeOptions}
                                onChange={(val) =>
                                    field.onChange((val as Option).value)
                                }
                            />
                        )}
                    />
                </div>
                <div className="fg fg-space-between two flex">
                    <Input
                        {...register("employer_name")}
                        label="Employer name"
                        defaultValue={insuranceDetail.employer_name}
                        placeholder="Employer name"
                        hasError={!!errors.employer_name}
                        errorText={errors.employer_name?.message}
                    />
                    <Input
                        {...register("insurance_member_id")}
                        label="Insurance member ID"
                        defaultValue={insuranceDetail.insurance_member_id}
                        placeholder="Insurance member ID"
                        hasError={!!errors.insurance_member_id}
                        errorText={errors.insurance_member_id?.message}
                    />
                </div>
                <div className="fg fg-space-between two flex">
                    <Controller
                        name="last_verified"
                        control={control}
                        defaultValue={insuranceDetail.last_verified}
                        render={({ field }) => (
                            <DatePicker
                                label="Last Verified"
                                onChange={(date) => {
                                    field.onChange(date);
                                    setLastVerified(date);
                                }}
                                maxDate={new Date(Date.now())}
                                selected={lastVerified}
                                hasError={!!errors.last_verified}
                                errorText={
                                    errors.last_verified?.type === "typeError"
                                        ? "invalid date value"
                                        : errors.last_verified?.message
                                }
                            />
                        )}
                    />
                    <Input
                        {...register("non_client_primary_insured_name")}
                        label="Non-Client Primary Insured name"
                        defaultValue={
                            insuranceDetail.non_client_primary_insured_name
                        }
                        placeholder="Non-Client Primary Insured name"
                        hasError={!!errors.non_client_primary_insured_name}
                        errorText={
                            errors.non_client_primary_insured_name?.message
                        }
                    />
                </div>
                <div className="fg">
                    <Controller
                        name="non_client_primary_insured_dob"
                        control={control}
                        defaultValue={
                            insuranceDetail.non_client_primary_insured_dob
                        }
                        render={({ field }) => (
                            <DatePicker
                                label="Non-Client Primary Insured DOB"
                                onChange={(date) => {
                                    field.onChange(date);
                                    setNonClientPrimaryInsuredDOB(date);
                                }}
                                maxDate={new Date(Date.now())}
                                selected={nonClientPrimaryInsuredDOB}
                                hasError={
                                    !!errors.non_client_primary_insured_dob
                                }
                                errorText={
                                    errors.non_client_primary_insured_dob
                                        ?.type === "typeError"
                                        ? "invalid date value"
                                        : errors.non_client_primary_insured_dob
                                              ?.message
                                }
                            />
                        )}
                    />
                </div>
                <div className="fg fg-space-between two flex">
                    <Input
                        {...register("primary_insured_address")}
                        label="Primary Insured address"
                        defaultValue={insuranceDetail.primary_insured_address}
                        placeholder="Primary Insured address"
                        hasError={!!errors.primary_insured_address}
                        errorText={errors.primary_insured_address?.message}
                    />
                    <Controller
                        name="primary_insured_sex"
                        control={control}
                        defaultValue={
                            getClientPrimaryInsuredSexOptions.find(
                                (option) =>
                                    option.value ===
                                        insuranceDetail.primary_insured_sex ||
                                    ""
                            )?.value as UserSex
                        }
                        render={({ field }) => (
                            <Select
                                defaultValue={getClientPrimaryInsuredSexOptions.find(
                                    (option) =>
                                        option.value ===
                                            insuranceDetail.primary_insured_sex ||
                                        ""
                                )}
                                label="Primary insured sex"
                                placeholder="Primary insured sex"
                                options={getClientPrimaryInsuredSexOptions}
                                onChange={(val) =>
                                    field.onChange((val as Option).value)
                                }
                            />
                        )}
                    />
                </div>
                <div className="fg">
                    <Controller
                        name="relationship_to_primary_insured"
                        control={control}
                        defaultValue={
                            getClientRelationshipToPrimaryInsuredOptions.find(
                                (option) =>
                                    option.value ===
                                        insuranceDetail.relationship_to_primary_insured ||
                                    ""
                            )?.value as RelationshipToPrimaryInsured
                        }
                        render={({ field }) => (
                            <Select
                                defaultValue={getClientRelationshipToPrimaryInsuredOptions.find(
                                    (option) =>
                                        option.value ===
                                            insuranceDetail.relationship_to_primary_insured ||
                                        ""
                                )}
                                label="Relationship to primary insured"
                                placeholder="Relationship to primary insured"
                                options={
                                    getClientRelationshipToPrimaryInsuredOptions
                                }
                                onChange={(val) =>
                                    field.onChange((val as Option).value)
                                }
                            />
                        )}
                    />
                </div>
                <div className="fg fg-space-between two flex">
                    <Controller
                        name="primary_insured_state"
                        control={control}
                        defaultValue={
                            getClientStateFieldSelectOptions().find(
                                (option) =>
                                    option.value.toLowerCase() ===
                                        insuranceDetail?.primary_insured_state?.toLowerCase() ||
                                    option.label.toLowerCase() ===
                                        insuranceDetail?.primary_insured_state?.toLowerCase()
                            )?.value
                        }
                        render={({ field }) => (
                            <Select
                                defaultValue={getClientStateFieldSelectOptions().find(
                                    (option) =>
                                        option.value.toLowerCase() ===
                                            insuranceDetail?.primary_insured_state?.toLowerCase() ||
                                        option.label.toLowerCase() ===
                                            insuranceDetail?.primary_insured_state?.toLowerCase()
                                )}
                                label="State"
                                options={getClientStateFieldSelectOptions()}
                                onChange={(val) =>
                                    field.onChange((val as Option).value)
                                }
                                hasError={!!errors.primary_insured_state}
                                errorText={
                                    errors.primary_insured_state?.message
                                }
                                isSearchable
                            />
                        )}
                    />
                    <Input
                        {...register("primary_insured_zipcode")}
                        defaultValue={insuranceDetail.primary_insured_zipcode}
                        label="Zipcode"
                        placeholder="Zipcode"
                        hasError={!!errors.primary_insured_zipcode}
                        errorText={errors.primary_insured_zipcode?.message}
                    />
                </div>
                <div className="fg fg-space-between two flex">
                    <Controller
                        name="status"
                        control={control}
                        defaultValue={
                            statusTypesForSelect.find(
                                (option) =>
                                    option.value === insuranceDetail.status ||
                                    ""
                            )?.value === "inactive"
                                ? ProviderStatus.INACTIVE
                                : ProviderStatus.ACTIVE
                        }
                        render={({ field }) => (
                            <Select
                                defaultValue={statusTypesForSelect.find(
                                    (option) =>
                                        option.value ===
                                            insuranceDetail.status || ""
                                )}
                                label="Status"
                                options={statusTypesForSelect}
                                onChange={(val) =>
                                    field.onChange((val as Option).value)
                                }
                                hasError={!!errors.status}
                                errorText={errors.status?.message}
                            />
                        )}
                    />
                    <Controller
                        name="insurance_accepted"
                        control={control}
                        defaultValue={
                            options.find(
                                (option) =>
                                    option.value ===
                                        insuranceDetail.insurance_accepted || ""
                            )?.value
                        }
                        render={({ field }) => (
                            <Select
                                defaultValue={options.find(
                                    (option) =>
                                        option.value ===
                                            insuranceDetail.insurance_accepted ||
                                        ""
                                )}
                                label="Insurance Accepted"
                                placeholder="Insurance Accepted"
                                options={options}
                                onChange={(val) => {
                                    field.onChange((val as Option).value);
                                }}
                                hasError={!!errors.insurance_accepted}
                                errorText={errors.insurance_accepted?.message}
                            />
                        )}
                    />
                </div>
                <div className="fg fg-space-between two flex">
                    <Input
                        {...register("primary_insured_city")}
                        defaultValue={insuranceDetail.primary_insured_city}
                        label="Primary insured city"
                        placeholder="Primary insured city"
                    />
                    <Input
                        {...register("primary_insured_phone_no")}
                        defaultValue={insuranceDetail.primary_insured_phone_no}
                        label="Primary insured phone no"
                        placeholder="Primary insured phone no"
                    />
                </div>
                <div className="fg">
                    <Controller
                        name="primary_insured_dob"
                        control={control}
                        defaultValue={insuranceDetail.primary_insured_dob}
                        render={({ field }) => (
                            <DatePicker
                                label="Primary insured dob"
                                onChange={(date) => {
                                    field.onChange(date);
                                    setLocalDateOfBirth(date);
                                }}
                                selected={localDateOfBirth}
                                maxDate={new Date(Date.now())}
                            />
                        )}
                    />
                </div>
                <div className="fg">
                    <Input
                        {...register("insured_policy_group")}
                        label="Insured policy group"
                        placeholder="Insured policy group"
                        defaultValue={insuranceDetail.insured_policy_group}
                    />
                </div>
            </div>
        </form>
    );
}

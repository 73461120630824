import { TextArea } from "@jhool-io/fe-components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import useToast from "../../../../../../hooks/useToast";
import { useAddCardLabel } from "../../../../hooks/clients.mutations";

interface AddLableProps {
    onFormSubmit: () => void;
    cardPaymentID: string;
    cardLabel: string;
}

interface IAddCardLabel {
    card_label: string;
}
const schema = yup.object({
    card_label: yup.string().required("Label is required"),
});

export default function AddCardLabelForm({
    cardPaymentID,
    onFormSubmit,
    cardLabel,
}: AddLableProps) {
    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;
    const addCardLabel = useAddCardLabel(clientId, cardPaymentID || "");

    // Query client
    const queryClient = useQueryClient();

    const { toast } = useToast();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IAddCardLabel>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    // function to change claim status
    const handleAddCardLabel = (cardLabelData: IAddCardLabel) => {
        const addCardPayload = {
            card_label: cardLabelData.card_label,
        };
        addCardLabel.mutate(addCardPayload, {
            onSuccess(res) {
                queryClient.invalidateQueries({
                    queryKey: [clientId, `cards`],
                });
                toast({
                    mode: "success",
                    message: res.message || "Label successfully",
                });
                onFormSubmit();
            },
            onError(err) {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not add label at this time, please try again",
                });
            },
        });
    };

    return (
        <form id="add-card-label" onSubmit={handleSubmit(handleAddCardLabel)}>
            <TextArea
                label="Label"
                defaultValue={cardLabel || ""}
                {...register("card_label")}
                placeholder="Enter your texts here"
                hasError={!!errors.card_label}
                errorText={errors.card_label?.message}
            />
        </form>
    );
}

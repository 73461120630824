import * as React from "react";
import cn from "classnames";
import styles from "./Button.module.scss";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    /** Button mode in relation to app colors */
    mode: "primary" | "secondary" | "transparent";
    /** Button size */
    size: "large" | "medium" | "small" | "auto";
    /** Button child */
    children: React.ReactNode;
    /* for screen readers */
    ariaLabel?: string;
    /** Optional style object to add more styling */
    style?: React.CSSProperties;
    /** Button type */
    type: "button" | "reset" | "submit";
    /** Extra optional classname */
    className?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        { mode, size, children, ariaLabel, style, type, className, ...props },
        ref
    ) => {
        return (
            <button
                aria-label={ariaLabel}
                className={cn(className, styles.button, "fs-exclude", {
                    [styles.buttonPri]: mode === "primary",
                    [styles.buttonSec]: mode === "secondary",
                    [styles.buttonTrans]: mode === "transparent",
                    [styles[`button_${size}`]]: size,
                })}
                style={style}
                type={type}
                ref={ref}
                {...props}
            >
                {children}
            </button>
        );
    }
);

Button.displayName = "Button";
export default Button;

import { Tag } from "@jhool-io/fe-components";
import { TaskStatus } from "../../../utils/types/tasks";
import { removeEnumUnderscore } from "../../../utils/helpers";

export const getStatusTag = (status: TaskStatus) => {
    let bgColor: string;
    let textColor: string;

    switch (status) {
        case TaskStatus.NOT_STARTED:
            textColor = "#0b132b";
            bgColor = "rgba(240, 240, 229, 0.50)";
            break;

        case TaskStatus.IN_PROGRESS:
            textColor = "#634D17";
            bgColor = "rgba(247, 229, 164, 0.50)";
            break;

        case TaskStatus.COMPLETED:
            textColor = "#00563E";
            bgColor = "rgba(204, 250, 233, 0.50)";
            break;

        default:
            textColor = "#0b132b";
            bgColor = "rgba(240, 240, 229, 0.50)";
    }

    return (
        <Tag
            title={removeEnumUnderscore(status)}
            textColor={textColor}
            bgColor={bgColor}
            className="capitalize font-normal px-8 py-4 text-nowrap h-24 rounded-r4"
        />
    );
};

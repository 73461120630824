import {
    Dropdown,
    DropdownContent,
    DropdownTrigger,
    Button as FeButton,
} from "@jhool-io/fe-components";
import * as React from "react";
import { Link, NavLink, useSearchParams } from "react-router-dom";
import cn from "classnames";
import styles from "./SideNav.module.scss";
import SideNavLink from "./SideNavLink/SideNavLink";
import BillingIcon from "../Icons/SideNav/Billing";
import Avatar from "../Avatar/Avatar";
import { useFetchUserDetails } from "../../hooks/queries/user";
import Skeleton from "../Skeleton/Skeleton";
import {
    convertArrayToString,
    getTwoLetterAbbreviation,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    truncateString,
} from "../../utils/helpers";
import Button from "../Button/Button";
import UserMgtIcon from "../Icons/SideNav/UserMgt";
import { UserPermisions, UserRole } from "../../utils/types/user";
import ReportsIcon from "../Icons/SideNav/Reports";
import DashboardIcon from "../Icons/SideNav/Dashboard";
import { useDoesUserHavePermission } from "../../hooks/permissions";
import TasksIcon from "../Icons/SideNav/Tasks";
import NotesIcon from "../Icons/SideNav/Notes";
import AvailabilityIcon from "../Icons/SideNav/Availability";
import ClientsIcon from "../Icons/SideNav/Clients";
import CalendarDaysIcon from "../Icons/SideNav/CalendarDays";
import patternBg from "../../assets/images/patternbg.png";
import BuildingIcon from "../Icons/SideNav/Building";
import TimesIcon from "../Icons/Times";
import { APP_COLORS } from "../../utils/constants";
import PinIcon from "../Icons/Pin";
import { PinnedClientsContext } from "../../context/pinned-clients/PinnedClientsContext";
import {
    getFlaggedNoteListNavigateUrl,
    getNoteListNavigateUrl,
} from "../../modules/notes/helpers/notes.helpers";
import SelectorIcon from "../Icons/SideNav/Selector";
import HospitalIcon from "../Icons/SideNav/Hospital";
import { getClaimsListNavigateUrl } from "../../modules/claims/helpers/claims.helper";
import { getRemitsListNavigateUrl } from "../../modules/remits/helpers/helpers.remits";
import { getBillingSummaryNavigateUrl } from "../../modules/billing/helpers/billing.helpers";
import usePractice from "../../hooks/usePractice";
import useAuthContext from "../../hooks/useAuthContext";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { toggleNavState } from "../../redux/features/nav/navSlice";
import CalculatorIcon from "../Icons/Calculator";
import moment from "../../utils/moment";
import { getProvidersActivityNavigateUrl } from "../../modules/reports/helpers/reports.helper";

interface SideNavProps {
    userRoles?: string[];
}

type CanExpandList =
    | "reports"
    | "billing"
    | "notes"
    | "availability"
    | "settings"
    | "practice-administration"
    | "claims"
    | "tools";

export default function SideNav({ userRoles }: SideNavProps) {
    const [searchParams] = useSearchParams();
    const navLinkInUrl = searchParams.get("navLinkInUrl") as CanExpandList;
    const [expandedSideNavLink, setExpandedSideNavLink] =
        React.useState<CanExpandList | null>(null);

    const { isOpen } = useAppSelector((state) => state.nav);
    const dispatch = useAppDispatch();

    const { isLoading, data, isSuccess } = useFetchUserDetails();

    const { practice, handleChangeUserPractice } = usePractice();

    const pinnedClientsContext = React.useContext(PinnedClientsContext);

    const { logOutUser } = useAuthContext();

    const loggedInUser = useFetchUserDetails();

    const handleSetExpandedSideNavLink = (
        sideNavLink: CanExpandList | null
    ) => {
        if (sideNavLink === expandedSideNavLink) setExpandedSideNavLink(null);
        else setExpandedSideNavLink(sideNavLink);
    };

    const pinnedClients = pinnedClientsContext?.pinnedClients;

    const claimsListUrl = getClaimsListNavigateUrl();
    const remitsListUrl = getRemitsListNavigateUrl();
    const providersActivityUrl = getProvidersActivityNavigateUrl();

    const billingSummaryUrl = getBillingSummaryNavigateUrl();

    const currentWeek = moment().startOf("week");
    const startOfWeek = currentWeek.startOf("week").format("YYYY-MM-DD");
    const endOfWeek = currentWeek.endOf("week").format("YYYY-MM-DD");

    // this is shows the active item on the dropdown when you navigate from another page to a particular page with dropdown item
    React.useEffect(() => {
        if (navLinkInUrl) {
            setExpandedSideNavLink(navLinkInUrl);
        }
        if (!navLinkInUrl) {
            setExpandedSideNavLink(null);
        }
    }, [navLinkInUrl]);

    // Function to display user initials in the right format
    const userInitials = (firstName: string, lastName: string) => {
        return `${firstName.charAt(0).toUpperCase()}${lastName
            .charAt(0)
            .toUpperCase()}`;
    };

    const isAllowedToView = (allowedRolesOrPermissions: string[]) => {
        return allowedRolesOrPermissions.some(
            (role) =>
                userRoles?.includes(role) ||
                practice?.roles?.includes(role as UserRole) ||
                practice?.permissions?.includes(role as UserPermisions)
        );
    };

    let isSmallerScreen = false;

    if (window) {
        isSmallerScreen =
            window && window.matchMedia("(max-width: 1000px)").matches;
    }

    const handleSideNavClick = () => {
        setExpandedSideNavLink(null);
        if (isSmallerScreen) {
            dispatch(toggleNavState());
        }
    };

    const billsUrl = practice?.roles?.includes(UserRole.CLIENT_SUPPORT)
        ? "/billing-and-claims?invoice_status[]=pending_resolution"
        : "/billing-and-claims?invoice_status[]=open";

    return (
        <>
            {isOpen && (
                <div
                    className="block md:hidden fixed w-full h-full bg-black-main/70 z-[11] overflow-hidden"
                    onClick={() => dispatch(toggleNavState())}
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => dispatch(toggleNavState())}
                    aria-label="close sidebar"
                />
            )}
            <aside
                className={cn(styles.sidebar, "fs-exclude", {
                    [styles.sidebar_hide]: !isOpen,
                })}
            >
                <div className={styles.header}>
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <FeButton
                                variant="normal"
                                size="auto"
                                className="flex items-center py-8 pl-8 pr-12 rounded-r8 bg-white shadow-row border border-stroke-divider/35 w-[200px] relative z-[2] data-[state=open]:border-primary disabled:opacity-100"
                            >
                                <span className="size-32 flex items-center justify-center rounded-r8 text-white text-sm font-medium uppercase mr-8 bg-primary shrink-0">
                                    {getTwoLetterAbbreviation(
                                        practice?.practice_name || ""
                                    )}
                                </span>
                                <span className="text-sm font-medium mr-auto">
                                    {truncateString(
                                        practice?.practice_name || "",
                                        13
                                    )}
                                </span>
                                <SelectorIcon className="shrink-0" />
                            </FeButton>
                        </DropdownTrigger>
                        <DropdownContent
                            width="384px"
                            className="py-8 px-[6px]"
                        >
                            <div className="flex flex-col gap-y-8 items-start">
                                {(loggedInUser?.data?.practices || []).map(
                                    (userPractice) => (
                                        <FeButton
                                            variant="normal"
                                            size="auto"
                                            className="min-h-40 bg-white border-b border-b-stroke-divider py-4 pl-[6px] pr-12 !rounded-none text-xs font-semibold capitalize w-full hover:bg-primary-50/65 items-center justify-start"
                                            key={userPractice.practice_id}
                                            onClick={() =>
                                                handleChangeUserPractice?.(
                                                    userPractice,
                                                    true
                                                )
                                            }
                                        >
                                            <span className="mr-8 flex items-center justify-center size-24 rounded-r4 bg-[#FFF6FB]">
                                                <HospitalIcon />
                                            </span>
                                            <span className="mr-4">
                                                {userPractice.practice_name}
                                            </span>
                                            {practice?.practice_id ===
                                                userPractice.practice_id && (
                                                <svg
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="ml-auto"
                                                >
                                                    <path
                                                        d="M5.9993 13.3333H5.99752C5.79575 13.3333 5.60464 13.2409 5.4793 13.0836L1.92375 8.63911C1.69352 8.35111 1.73975 7.93245 2.02775 7.70222C2.31664 7.472 2.7353 7.51911 2.96464 7.80622L6.00286 11.6036L13.0366 2.91378C13.2686 2.62756 13.6873 2.58222 13.9744 2.81511C14.2606 3.04711 14.3051 3.46667 14.0731 3.75289L6.51752 13.0862C6.39041 13.2427 6.20019 13.3333 5.9993 13.3333Z"
                                                        fill="#1A738D"
                                                    />
                                                </svg>
                                            )}
                                        </FeButton>
                                    )
                                )}
                            </div>
                        </DropdownContent>
                    </Dropdown>
                    <img
                        src={patternBg}
                        alt="pattern background"
                        className="absolute right-[62px] w-[77px] h-[65px]"
                    />
                    <FeButton
                        size="auto"
                        variant="normal"
                        onClick={() => dispatch(toggleNavState())}
                        className="py-8 w-24 flex items-center justify-center rounded-r4 border border-card-bg hover:border-primary"
                    >
                        <svg
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M5 9.00024L1 5.00024L5 1.00024"
                                stroke="#1D596D"
                                strokeWidth="1.33333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </FeButton>
                </div>

                <div className={styles.others}>
                    <div className={styles.links}>
                        <ul className={styles.list}>
                            <SideNavLink
                                href="/"
                                title="Dashboard"
                                icon={<DashboardIcon />}
                                linkType="a"
                                isAllowedToView
                                onClick={handleSideNavClick}
                            />
                            <SideNavLink
                                href="/clients"
                                title="Clients"
                                icon={<ClientsIcon />}
                                linkType="a"
                                isAllowedToView={isAllowedToView([
                                    UserRole.SUPER_ADMIN,
                                    UserRole.PROVIDER,
                                    UserRole.BILLER,
                                    UserRole.BILLER_ADMIN,
                                    UserRole.AUDITOR,
                                    UserRole.CLIENT_SUPPORT,
                                    UserRole.USER_SUPPORT,
                                ])}
                                onClick={handleSideNavClick}
                            />

                            <SideNavLink
                                href={`/my-schedule?from_date=${startOfWeek}&to_date=${endOfWeek}`}
                                title="Schedule"
                                icon={<CalendarDaysIcon />}
                                linkType="a"
                                isAllowedToView={isAllowedToView([
                                    UserRole.PROVIDER,
                                    UserRole.BILLER,
                                    UserRole.BILLER_ADMIN,
                                    UserRole.SUPER_ADMIN,
                                ])}
                                onClick={handleSideNavClick}
                            />

                            <SideNavLink
                                title="Session Notes"
                                icon={<NotesIcon />}
                                linkType="button"
                                isAllowedToView={isAllowedToView([
                                    UserRole.SUPER_ADMIN,
                                    UserRole.PROVIDER,
                                    UserRole.BILLER,
                                    UserRole.BILLER_ADMIN,
                                    UserRole.CODER,
                                    UserRole.AUDITOR,
                                ])}
                                onClick={() => {
                                    handleSetExpandedSideNavLink("notes");
                                }}
                                isExpanded={expandedSideNavLink === "notes"}
                                dropdown={
                                    <>
                                        <NavLink
                                            end
                                            to={getNoteListNavigateUrl(
                                                practice?.roles || []
                                            )}
                                            onClick={
                                                isSmallerScreen
                                                    ? () =>
                                                          dispatch(
                                                              toggleNavState()
                                                          )
                                                    : undefined
                                            }
                                            className={({ isActive }) => {
                                                return isActive
                                                    ? styles.active_link
                                                    : undefined;
                                            }}
                                        >
                                            Notes
                                        </NavLink>
                                        {isAllowedToView([
                                            UserRole.AUDITOR,
                                            UserRole.BILLER,
                                            UserRole.BILLER_ADMIN,
                                            UserRole.CODER,
                                            UserRole.SUPER_ADMIN,
                                        ]) ? (
                                            <NavLink
                                                end
                                                to={getFlaggedNoteListNavigateUrl()}
                                                onClick={
                                                    isSmallerScreen
                                                        ? () =>
                                                              dispatch(
                                                                  toggleNavState()
                                                              )
                                                        : undefined
                                                }
                                                className={({ isActive }) => {
                                                    return isActive
                                                        ? styles.active_link
                                                        : undefined;
                                                }}
                                            >
                                                Flagged notes
                                            </NavLink>
                                        ) : null}
                                        {isAllowedToView([
                                            UserRole.AUDITOR,
                                            UserRole.SUPER_ADMIN,
                                        ]) ? (
                                            <NavLink
                                                end
                                                to="/notes/note-audit-dashboard?date=this_month"
                                                onClick={
                                                    isSmallerScreen
                                                        ? () =>
                                                              dispatch(
                                                                  toggleNavState()
                                                              )
                                                        : undefined
                                                }
                                                className={({ isActive }) => {
                                                    return isActive
                                                        ? styles.active_link
                                                        : undefined;
                                                }}
                                            >
                                                Note audit
                                            </NavLink>
                                        ) : null}
                                    </>
                                }
                            />
                            {/* {isDevInstance && (
                               <SideNavLink
                                   href="/request-office-space"
                                   title="Request Office Space"
                                   icon={<SearchIcon />}
                                   linkType="a"
                                   isAllowedToView={isAllowedToView([
                                       UserRole.PROVIDER,
                                   ])}
                                   onClick={handleSideNavClick}
                               />
                           )} */}
                            {/* {isDevInstance && (
                               <SideNavLink
                                   title="Provider Availability"
                                   icon={<AvailabilityIcon />}
                                   linkType="button"
                                   isAllowedToView={isAllowedToView([
                                       UserRole.PROVIDER,
                                   ])}
                                   onClick={() => {
                                       handleSetExpandedSideNavLink(
                                           "availability"
                                       );
                                       navigate("/caseload");
                                   }}
                                   isExpanded={
                                       expandedSideNavLink === "availability"
                                   }
                                   dropdown={
                                       <>
                                           <NavLink
                                               end
                                               to="/caseload"
                                               onClick={
                                                   isSmallerScreen
                                                       ? () => dispatch(toggleNavState())
                                                       : undefined
                                               }
                                               className={({ isActive }) => {
                                                   return isActive
                                                       ? styles.active_link
                                                       : undefined;
                                               }}
                                           >
                                               Caseload
                                           </NavLink>
                                           <NavLink
                                               end
                                               to="/availability"
                                               onClick={
                                                   isSmallerScreen
                                                       ? () => dispatch(toggleNavState())
                                                       : undefined
                                               }
                                               className={({ isActive }) => {
                                                   return isActive
                                                       ? styles.active_link
                                                       : undefined;
                                               }}
                                           >
                                               New Availability
                                           </NavLink>
                                           <NavLink
                                               end
                                               to="/reserved-time-window"
                                               onClick={
                                                   isSmallerScreen
                                                       ? () => dispatch(toggleNavState())
                                                       : undefined
                                               }
                                               className={({ isActive }) => {
                                                   return isActive
                                                       ? styles.active_link
                                                       : undefined;
                                               }}
                                           >
                                               Reserved Time Windows
                                           </NavLink>
                                           <NavLink
                                               end
                                               to="/time-off"
                                               onClick={
                                                   isSmallerScreen
                                                       ? () => dispatch(toggleNavState())
                                                       : undefined
                                               }
                                               className={({ isActive }) => {
                                                   return isActive
                                                       ? styles.active_link
                                                       : undefined;
                                               }}
                                           >
                                               Book Time Off
                                           </NavLink>
                                       </>
                                   }
                               />
                           )} */}
                            <SideNavLink
                                title="Billings"
                                icon={<BillingIcon />}
                                isAllowedToView={isAllowedToView([
                                    UserRole.SUPER_ADMIN,
                                    UserRole.BILLER,
                                    UserRole.BILLER_ADMIN,
                                    UserRole.CODER,
                                    UserRole.CLIENT_SUPPORT,
                                ])}
                                linkType="button"
                                onClick={() => {
                                    handleSetExpandedSideNavLink("billing");
                                }}
                                isExpanded={expandedSideNavLink === "billing"}
                                dropdown={
                                    <>
                                        <NavLink
                                            end
                                            to={billsUrl}
                                            onClick={
                                                isSmallerScreen
                                                    ? () =>
                                                          dispatch(
                                                              toggleNavState()
                                                          )
                                                    : undefined
                                            }
                                            className={({ isActive }) => {
                                                return isActive
                                                    ? styles.active_link
                                                    : undefined;
                                            }}
                                            hidden={
                                                !useDoesUserHavePermission([
                                                    UserPermisions.CLIENT_INFO_INVOICE_READ,
                                                ])
                                            }
                                        >
                                            Bills
                                        </NavLink>
                                        <NavLink
                                            end
                                            to="/billing-payments?invoice_status%5B%5D=open"
                                            onClick={
                                                isSmallerScreen
                                                    ? () =>
                                                          dispatch(
                                                              toggleNavState()
                                                          )
                                                    : undefined
                                            }
                                            className={({ isActive }) => {
                                                return isActive
                                                    ? styles.active_link
                                                    : undefined;
                                            }}
                                            hidden={
                                                !isAllowedToView([
                                                    UserPermisions.CLIENT_INFO_ALL_ADMIN,
                                                    UserPermisions.CLIENT_INFO_PAYMENTS_READ,
                                                ])
                                            }
                                        >
                                            Payments
                                        </NavLink>
                                        <NavLink
                                            end
                                            to={billingSummaryUrl}
                                            onClick={
                                                isSmallerScreen
                                                    ? () =>
                                                          dispatch(
                                                              toggleNavState()
                                                          )
                                                    : undefined
                                            }
                                            className={({ isActive }) => {
                                                return isActive
                                                    ? styles.active_link
                                                    : undefined;
                                            }}
                                            hidden={
                                                !useDoesUserHavePermission([
                                                    UserPermisions.BILLING_DASHBOARD_READ,
                                                ])
                                            }
                                        >
                                            Billing Summary
                                        </NavLink>
                                    </>
                                }
                            />
                            <SideNavLink
                                linkType="button"
                                title="Claims"
                                icon={<AvailabilityIcon />}
                                isAllowedToView={isAllowedToView([
                                    UserRole.SUPER_ADMIN,
                                    UserRole.BILLER,
                                    UserRole.BILLER_ADMIN,
                                    UserRole.CODER,
                                    UserRole.CLIENT_SUPPORT,
                                ])}
                                onClick={() => {
                                    handleSetExpandedSideNavLink("claims");
                                }}
                                isExpanded={expandedSideNavLink === "claims"}
                                dropdown={
                                    <>
                                        <NavLink
                                            end
                                            to={claimsListUrl}
                                            onClick={
                                                isSmallerScreen
                                                    ? () =>
                                                          dispatch(
                                                              toggleNavState()
                                                          )
                                                    : undefined
                                            }
                                            className={({ isActive }) => {
                                                return isActive
                                                    ? styles.active_link
                                                    : undefined;
                                            }}
                                        >
                                            Claims
                                        </NavLink>

                                        <NavLink
                                            end
                                            to={remitsListUrl}
                                            onClick={
                                                isSmallerScreen
                                                    ? () =>
                                                          dispatch(
                                                              toggleNavState()
                                                          )
                                                    : undefined
                                            }
                                            className={({ isActive }) => {
                                                return isActive
                                                    ? styles.active_link
                                                    : undefined;
                                            }}
                                        >
                                            Remits
                                        </NavLink>
                                    </>
                                }
                            />

                            <SideNavLink
                                href="/tasks?status[]=not_started&status[]=in_progress&category[]=user_created_todo"
                                title="Tasks"
                                linkType="a"
                                isAllowedToView={isAllowedToView([
                                    UserRole.SUPER_ADMIN,
                                    UserRole.PROVIDER,
                                    UserRole.BILLER,
                                    UserRole.BILLER_ADMIN,
                                    UserRole.CODER,
                                    UserRole.AUDITOR,
                                    UserRole.USER_SUPPORT,
                                ])}
                                icon={<TasksIcon />}
                                onClick={handleSideNavClick}
                            />
                            <SideNavLink
                                href="/providers"
                                title="Providers"
                                linkType="a"
                                icon={<ClientsIcon />}
                                isAllowedToView={Boolean(
                                    practice?.permissions?.includes(
                                        UserPermisions.PROVIDER_INFO_READ
                                    )
                                )}
                                onClick={handleSideNavClick}
                            />

                            <SideNavLink
                                linkType="button"
                                title="Reports"
                                icon={<ReportsIcon />}
                                isAllowedToView={useDoesUserHavePermission([
                                    UserPermisions.PROVIDER_REPORTS_READ,
                                ])}
                                onClick={() => {
                                    handleSetExpandedSideNavLink("reports");
                                }}
                                isExpanded={expandedSideNavLink === "reports"}
                                dropdown={
                                    <>
                                        <NavLink
                                            end
                                            to={providersActivityUrl}
                                            onClick={
                                                isSmallerScreen
                                                    ? () =>
                                                          dispatch(
                                                              toggleNavState()
                                                          )
                                                    : undefined
                                            }
                                            className={({ isActive }) => {
                                                return isActive
                                                    ? styles.active_link
                                                    : undefined;
                                            }}
                                        >
                                            Provider session activity
                                        </NavLink>
                                        <NavLink
                                            end
                                            to="/reports/billing-reports"
                                            onClick={
                                                isSmallerScreen
                                                    ? () =>
                                                          dispatch(
                                                              toggleNavState()
                                                          )
                                                    : undefined
                                            }
                                            className={({ isActive }) => {
                                                return isActive
                                                    ? styles.active_link
                                                    : undefined;
                                            }}
                                        >
                                            Billing reports
                                        </NavLink>
                                    </>
                                }
                            />
                            {/* <SideNavLink
                       href="/clients-services"
                       title="Client services"
                       icon={<ClientServicesIcon />}
                   />

                   <SideNavLink
                       href="/reports"
                       title="Reports"
                       icon={<ReportsIcon />}
                   />
                  */}
                            <SideNavLink
                                linkType="a"
                                href="/practices"
                                title="Practices"
                                isAllowedToView={isAllowedToView([
                                    UserRole.SUPER_ADMIN,
                                ])}
                                icon={<BuildingIcon />}
                            />

                            <SideNavLink
                                linkType="button"
                                title="Practice Administration"
                                icon={<UserMgtIcon />}
                                isAllowedToView={isAllowedToView([
                                    UserRole.AUDITOR,
                                    UserRole.BILLER,
                                    UserRole.BILLER_ADMIN,
                                    UserRole.CLIENT_SUPPORT,
                                    UserRole.CODER,
                                    UserRole.SUPER_ADMIN,
                                    UserRole.USER_SUPPORT,
                                ])}
                                onClick={() => {
                                    handleSetExpandedSideNavLink(
                                        "practice-administration"
                                    );
                                }}
                                isExpanded={
                                    expandedSideNavLink ===
                                    "practice-administration"
                                }
                                dropdown={
                                    <>
                                        {(practice?.permissions?.includes(
                                            UserPermisions.USER_INFO_ALL_WRITE
                                        ) as boolean) && (
                                            <NavLink
                                                end
                                                to="/user-management?status=active"
                                                onClick={
                                                    isSmallerScreen
                                                        ? () =>
                                                              dispatch(
                                                                  toggleNavState()
                                                              )
                                                        : undefined
                                                }
                                                className={({ isActive }) => {
                                                    return isActive
                                                        ? styles.active_link
                                                        : undefined;
                                                }}
                                            >
                                                User management
                                            </NavLink>
                                        )}

                                        {!practice?.provider_id && (
                                            <NavLink
                                                end
                                                to="/audit-log"
                                                onClick={
                                                    isSmallerScreen
                                                        ? () =>
                                                              dispatch(
                                                                  toggleNavState()
                                                              )
                                                        : undefined
                                                }
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? styles.active_link
                                                        : undefined
                                                }
                                            >
                                                Audit Log
                                            </NavLink>
                                        )}

                                        {useDoesUserHavePermission([
                                            UserPermisions.PRACTICE_SETTING_ADMIN,
                                            UserPermisions.PRACTICE_SETTING_WRITE,
                                        ]) && (
                                            <NavLink
                                                end
                                                to="/practice-settings"
                                                onClick={
                                                    isSmallerScreen
                                                        ? () =>
                                                              dispatch(
                                                                  toggleNavState()
                                                              )
                                                        : undefined
                                                }
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? styles.active_link
                                                        : undefined
                                                }
                                            >
                                                Practice Settings
                                            </NavLink>
                                        )}

                                        {isAllowedToView([
                                            UserRole.SUPER_ADMIN,
                                        ]) && (
                                            <NavLink
                                                end
                                                to="/client-chart-restrictions"
                                                onClick={
                                                    isSmallerScreen
                                                        ? () =>
                                                              dispatch(
                                                                  toggleNavState()
                                                              )
                                                        : undefined
                                                }
                                                className={({ isActive }) => {
                                                    return isActive
                                                        ? styles.active_link
                                                        : undefined;
                                                }}
                                            >
                                                Client chart restrictions
                                            </NavLink>
                                        )}

                                        {isAllowedToView([
                                            UserRole.SUPER_ADMIN,
                                            UserRole.BILLER_ADMIN,
                                            UserRole.CODER,
                                            UserRole.BILLER,
                                        ]) && (
                                            <NavLink
                                                end
                                                to="/payers"
                                                onClick={
                                                    isSmallerScreen
                                                        ? () =>
                                                              dispatch(
                                                                  toggleNavState()
                                                              )
                                                        : undefined
                                                }
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? styles.active_link
                                                        : undefined
                                                }
                                            >
                                                Payers
                                            </NavLink>
                                        )}
                                        {isAllowedToView([
                                            UserRole.SUPER_ADMIN,
                                        ]) && (
                                            <NavLink
                                                end
                                                to="/announcements"
                                                onClick={
                                                    isSmallerScreen
                                                        ? () =>
                                                              dispatch(
                                                                  toggleNavState()
                                                              )
                                                        : undefined
                                                }
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? styles.active_link
                                                        : undefined
                                                }
                                            >
                                                Announcements
                                            </NavLink>
                                        )}
                                        {isAllowedToView([
                                            UserRole.SUPER_ADMIN,
                                        ]) && (
                                            <NavLink
                                                end
                                                to="/thirty-days-providers"
                                                onClick={
                                                    isSmallerScreen
                                                        ? () =>
                                                              dispatch(
                                                                  toggleNavState()
                                                              )
                                                        : undefined
                                                }
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? styles.active_link
                                                        : undefined
                                                }
                                            >
                                                Thirty days Providers
                                            </NavLink>
                                        )}
                                    </>
                                }
                            />

                            <SideNavLink
                                linkType="button"
                                title="Tools"
                                icon={<CalculatorIcon />}
                                isAllowedToView={isAllowedToView([
                                    UserRole.SUPER_ADMIN,
                                    UserRole.BILLER,
                                    UserRole.CODER,
                                    UserRole.BILLER_ADMIN,
                                    UserRole.CLIENT_SUPPORT,
                                    UserRole.CLIENT,
                                    UserRole.USER_SUPPORT,
                                ])}
                                onClick={() => {
                                    handleSetExpandedSideNavLink("tools");
                                }}
                                isExpanded={expandedSideNavLink === "tools"}
                                dropdown={
                                    <NavLink
                                        end
                                        to="/tools/fees-calculator"
                                        onClick={
                                            isSmallerScreen
                                                ? () =>
                                                      dispatch(toggleNavState())
                                                : undefined
                                        }
                                        className={({ isActive }) => {
                                            return isActive
                                                ? styles.active_link
                                                : undefined;
                                        }}
                                    >
                                        ONN Fees Calculator
                                    </NavLink>
                                }
                            />
                        </ul>
                    </div>
                    {pinnedClients &&
                        pinnedClients.length > 0 &&
                        pinnedClients.length <= 5 && (
                            <div className="pl-24 pr-12">
                                <h4 className="uppercase text-gray text-xs font-medium pl-12">
                                    Pinned clients
                                </h4>
                                <div className="flex flex-col gap-y-12 mt-16">
                                    {pinnedClients.map((client) => (
                                        <Link
                                            key={`${client.client_id} ${client.pin_module}`}
                                            to={client.pin_location}
                                            className="group pt-[10px] pb-4 px-12 h-40 bg-white rounded-r8 hover:bg-card-bg text-sm text-primary font-medium capitalize flex items-center relative"
                                        >
                                            <PinIcon
                                                fill={APP_COLORS.COLOR_PRIMARY}
                                                className="absolute top-1/2 -translate-y-1/2 left-[12px]"
                                            />
                                            <span className="flex items-start justify-center flex-col gap-y-4 leading-none pl-12">
                                                <span className="pl-12">
                                                    {truncateString(
                                                        client.client_name,
                                                        16
                                                    )}
                                                </span>
                                                <span className="text-xss font-medium leading-none normal-case pl-12">
                                                    {makeStringFirstLetterCapital(
                                                        removeEnumUnderscore(
                                                            client.pin_module
                                                        )
                                                    )}
                                                </span>
                                            </span>

                                            <FeButton
                                                className="hidden group-hover:flex ml-auto size-24 absolute top-1/2 -translate-y-1/2 right-[12px]"
                                                size="auto"
                                                variant="normal"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    pinnedClientsContext.handleUnpinClient(
                                                        client.client_id,
                                                        client.pin_module
                                                    );
                                                }}
                                            >
                                                <TimesIcon
                                                    stroke={
                                                        APP_COLORS.COLOR_GRAY
                                                    }
                                                />
                                            </FeButton>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        )}

                    <div className="mt-20">
                        {isLoading && (
                            <div className={styles.skeletonwrap}>
                                <Skeleton count={3} height={20} width="100%" />
                            </div>
                        )}
                        {data && isSuccess && (
                            <div className={styles.mobileprofile}>
                                <div className={styles.profile_top}>
                                    <div className={styles.profile_avatar}>
                                        <Avatar
                                            label={userInitials(
                                                removeEnumUnderscore(
                                                    data.first_name
                                                ),
                                                removeEnumUnderscore(
                                                    data.last_name
                                                )
                                            )}
                                        />
                                    </div>
                                    <div className={styles.profile_topleft}>
                                        <span>
                                            {data.first_name} {data.last_name}
                                        </span>
                                        <span>
                                            {convertArrayToString(
                                                practice?.roles || [],
                                                ","
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.profile_bottom}>
                                    <Button
                                        className={styles.logoutbtn}
                                        mode="transparent"
                                        size="auto"
                                        type="button"
                                        onClick={logOutUser}
                                    >
                                        Logout
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                    <img
                        src={patternBg}
                        alt="pattern background"
                        className="absolute bottom-0 left-0 right-[62px] w-[194px] h-[165px] -z-[1]"
                    />
                </div>
            </aside>
        </>
    );
}

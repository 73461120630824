import * as React from "react";
import cn from "classnames";
import styles from "./RadioInput.module.scss";

interface RadioInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    /** Radio label text */
    label: string;
    /** Radio name for grouping radio inputs */
    name?: string;
    /** Radio id value */
    id?: string;
    /* additional styles */
    className?: string;
}

const RadioInput = React.forwardRef<HTMLInputElement, RadioInputProps>(
    ({ label, name, id, className, ...props }, ref) => (
        <div className={styles.radio}>
            <label htmlFor={id} className={styles.label}>
                <input
                    className={styles.input}
                    type="radio"
                    id={id}
                    name={name}
                    {...props}
                    ref={ref}
                />
                <span className={styles.custom_input} />
                <span className={cn(styles.text, className, "fs-exclude")}>
                    {label}
                </span>
            </label>
        </div>
    )
);

RadioInput.displayName = "RadioInput";

export default RadioInput;

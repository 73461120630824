import Navbar from "../../../components/Navbar/Navbar";
import FeesCalculator from "../components/FeesCalculator/FeesCalculator";

export default function ONNFeesCalculatorView() {
    return (
        <>
            <Navbar title="Fees Calculator" />
            <div className="page">
                <FeesCalculator />
            </div>
        </>
    );
}

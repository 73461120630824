import * as React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Input, RadioInput } from "@jhool-io/fe-components";
import {
    EditClientBasicInfo,
    IClientPersonalInfo,
    IClientRelation,
} from "../../../../../utils/types/client";
import {
    PHONE_REGEX,
    normalizePhoneNumber,
} from "../../../../../utils/helpers/phonenumber/phonenumber";
import { useEditClientBasicInfo } from "../../../../../hooks/mutations/client";
import useToast from "../../../../../hooks/useToast";
import { IApiResponse } from "../../../../../utils/types/api-response";
import Accordion from "../../../../Accordion/Accordion";
import AddRelationInfo from "../../AddRelationInfo/AddRelationInfo";

interface EditPersonalInfoProps {
    // Client that is being editing in the modal details
    clientPersonalInfo: IClientPersonalInfo;
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
}

const schema = yup.object().shape({
    primary_contact: yup
        .string()
        .nullable()
        .when("relation_info", (relation_info: IClientRelation[], field) => {
            if (!relation_info) return field;

            const filteredRelationInfo = relation_info.filter(
                (item) => item.first_name !== ""
            );
            if (filteredRelationInfo.length > 0) return field.required();
            return field;
        }),
    relation_info: yup.array().of(
        yup.object().shape(
            {
                relation_id: yup
                    .string()
                    .when(["first_name", "last_name", "contact_phone_no"], {
                        is: (
                            first_name: string,
                            last_name: string,
                            contact_phone_no: string
                        ) =>
                            Boolean(first_name) ||
                            Boolean(last_name) ||
                            Boolean(contact_phone_no),
                        then: yup.string(),
                    }),
                relationship_type: yup
                    .string()
                    .when(["first_name", "last_name", "contact_phone_no"], {
                        is: (
                            first_name: string,
                            last_name: string,
                            contact_phone_no: string
                        ) =>
                            Boolean(first_name) ||
                            Boolean(last_name) ||
                            Boolean(contact_phone_no),
                        then: yup
                            .string()
                            .required("Relationship type is required"),
                    }),
                first_name: yup
                    .string()
                    .when(
                        ["relationship_type", "last_name", "contact_phone_no"],
                        {
                            is: (
                                relationship_type: string,
                                last_name: string,
                                contact_phone_no: string
                            ) =>
                                Boolean(relationship_type) ||
                                Boolean(last_name) ||
                                Boolean(contact_phone_no),
                            then: yup
                                .string()
                                .required("Relation's first name is required"),
                        }
                    ),
                last_name: yup
                    .string()
                    .when(
                        ["relationship_type", "first_name", "contact_phone_no"],
                        {
                            is: (
                                relationship_type: string,
                                first_name: string,
                                contact_phone_no: string
                            ) =>
                                Boolean(relationship_type) ||
                                Boolean(first_name) ||
                                Boolean(contact_phone_no),
                            then: yup
                                .string()
                                .required("Relation's last name is required"),
                        }
                    ),
                contact_phone_no: yup
                    .string()
                    .when(["relationship_type", "first_name", "last_name"], {
                        is: (
                            relationship_type: string,
                            first_name: string,
                            last_name: string
                        ) =>
                            Boolean(relationship_type) ||
                            Boolean(first_name) ||
                            Boolean(last_name),
                        then: yup
                            .string()
                            .required("Relation's contact is required")
                            .matches(
                                PHONE_REGEX,
                                "Field should only contain numbers"
                            ),
                    }),
            },
            [
                ["relationship_type", "first_name"],
                ["relationship_type", "last_name"],
                ["relationship_type", "contact_phone_no"],
                ["first_name", "last_name"],
                ["first_name", "contact_phone_no"],
                ["last_name", "contact_phone_no"],
            ]
        )
    ),
});

export default function EditPersonalInfo({
    clientPersonalInfo,
    onFormSubmit,
}: EditPersonalInfoProps) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<EditClientBasicInfo>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    // Query client
    const queryClient = useQueryClient();

    // To initialize clientId constant
    const params = useParams();
    const clientId = params.clientId as string;

    // Get the toast hook
    const { toast } = useToast();

    // Get the edit client basic info hook
    const { mutate, error } = useEditClientBasicInfo(clientId);

    const onSubmit = (data: EditClientBasicInfo) => {
        let relationInfo: IClientRelation[] | undefined;

        if (!data.relation_info) {
            relationInfo = [];
        } else if (data.relation_info) {
            relationInfo = data.relation_info.filter(
                (item) => item.first_name !== ""
            );
        }

        if (relationInfo && relationInfo.length !== 0) {
            if (relationInfo.length === 1) {
                relationInfo = relationInfo.map((item) => ({
                    ...item,
                    is_primary_contact: true,
                }));
            } else if (relationInfo.length > 1) {
                relationInfo = relationInfo.map((item, i) => {
                    if (Number(data.primary_contact) === i) {
                        return { ...item, is_primary_contact: true };
                    }
                    return { ...item, is_primary_contact: false };
                });
            }
        }

        const dataToSend = {
            ...data,
            primary_contact: undefined,
            relation_info: relationInfo,
        };

        mutate(dataToSend, {
            onSuccess: (result) => {
                queryClient.setQueryData<IApiResponse<IClientPersonalInfo>>(
                    [clientId, "basic-personal-info"],
                    (prev) => {
                        const prevRequired =
                            prev as IApiResponse<IClientPersonalInfo>;
                        return {
                            message: prevRequired.message,
                            data: {
                                ...prevRequired.data,
                                ...result.data,
                            },
                        };
                    }
                );

                queryClient.invalidateQueries({
                    queryKey: [clientId, "basic-personal-info"],
                });
                toast({
                    mode: "success",
                    message: result.message || "Changes saved!",
                });
                onFormSubmit();
            },
            onError: () => {
                toast({
                    mode: "error",
                    message:
                        error?.response?.data.message ||
                        "Couldn't save changes!",
                });
            },
        });
    };

    return (
        <>
            <form id="edit-personal-info" onSubmit={handleSubmit(onSubmit)}>
                {clientPersonalInfo &&
                    clientPersonalInfo.relation_info &&
                    clientPersonalInfo.relation_info.map((personalInfo, i) => (
                        <React.Fragment key={personalInfo.relation_id}>
                            <div className="mb-12 h-auto">
                                <RadioInput
                                    {...register("primary_contact")}
                                    label="Primary contact"
                                    value={i}
                                    defaultChecked={
                                        personalInfo.is_primary_contact
                                    }
                                />
                            </div>
                            <div
                                className="mb-24 h-auto"
                                aria-label="edit-parent-info"
                            >
                                <Input
                                    {...register(
                                        `relation_info.${i}.relationship_type`
                                    )}
                                    label="Relationship"
                                    defaultValue={
                                        personalInfo.relationship_type
                                    }
                                    placeholder="Relationship"
                                    hasError={
                                        !!errors?.relation_info?.[i]
                                            ?.relationship_type
                                    }
                                    errorText={
                                        errors?.relation_info?.[i]
                                            ?.relationship_type?.message
                                    }
                                />
                            </div>
                            <div
                                key={personalInfo.first_name}
                                className="mb-24 h-auto justify-between two flex"
                            >
                                <Input
                                    {...register(
                                        `relation_info.${i}.first_name`
                                    )}
                                    label="Relation's first name"
                                    defaultValue={personalInfo.first_name}
                                    placeholder="Relation's first name"
                                    hasError={
                                        !!errors?.relation_info?.[i]?.first_name
                                    }
                                    errorText={
                                        errors?.relation_info?.[i]?.first_name
                                            ?.message
                                    }
                                />
                                <Input
                                    {...register(
                                        `relation_info.${i}.last_name`
                                    )}
                                    label="Relation's last name"
                                    defaultValue={personalInfo.last_name}
                                    placeholder="Relation's last name"
                                    hasError={
                                        !!errors?.relation_info?.[i]?.last_name
                                    }
                                    errorText={
                                        errors?.relation_info?.[i]?.last_name
                                            ?.message
                                    }
                                />
                            </div>
                            <div
                                key={personalInfo.contact_phone_no}
                                className="mb-32 h-auto"
                            >
                                <Input
                                    {...register(
                                        `relation_info.${i}.contact_phone_no`
                                    )}
                                    label="Contact"
                                    defaultValue={personalInfo.contact_phone_no}
                                    placeholder="Contact"
                                    hasError={
                                        !!errors?.relation_info?.[i]
                                            ?.contact_phone_no
                                    }
                                    errorText={
                                        errors?.relation_info?.[i]
                                            ?.contact_phone_no?.message
                                    }
                                    onChange={(e) => {
                                        setValue(
                                            `relation_info.${i}.contact_phone_no`,
                                            normalizePhoneNumber(e.target.value)
                                        );
                                    }}
                                />
                            </div>
                            <div className="hidden">
                                <Input
                                    {...register(
                                        `relation_info.${i}.relation_id`
                                    )}
                                    label="Parent id here"
                                    defaultValue={personalInfo.relation_id}
                                    placeholder="Parent id"
                                />
                            </div>
                        </React.Fragment>
                    ))}
                {clientPersonalInfo.relation_info &&
                    clientPersonalInfo.relation_info.length > 0 && (
                        <div className="w-full my-32 border border-stroke-divider" />
                    )}
            </form>
            <div>
                <Accordion
                    header={
                        <h3 className="uppercase text-sm font-semibold">
                            Add relation info
                        </h3>
                    }
                    content={
                        <div className="mt-16">
                            <AddRelationInfo />
                        </div>
                    }
                    isAccordionMaximised
                />
            </div>
        </>
    );
}

export default function GoogleIcon() {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_12398_33886)">
                <path
                    d="M16.1644 8.50645C16.1792 7.85111 16.1355 7.37165 16.0288 6.87307L8.37 6.70117L8.3036 9.65908L12.7992 9.75999C12.6921 10.493 12.1778 11.5891 11.0734 12.3085L11.0559 12.4072L13.4364 14.2946L13.6038 14.3147C15.1758 12.9589 16.11 10.933 16.1644 8.50645Z"
                    fill="#4285F4"
                />
                <path
                    d="M8.15848 16.1241C10.3609 16.1735 12.2258 15.5065 13.6038 14.3147L11.0734 12.3085C10.374 12.7624 9.44216 13.0694 8.22766 13.0421C6.07051 12.9937 4.27087 11.5622 3.66135 9.62581L3.56551 9.6316L1.00478 11.4792L0.969848 11.5679C2.25282 14.2016 4.96804 16.0525 8.15848 16.1241Z"
                    fill="#34A853"
                />
                <path
                    d="M3.66135 9.62552C3.50027 9.12572 3.41254 8.59208 3.42486 8.04302C3.43719 7.49391 3.54877 6.9648 3.72305 6.47252L3.72086 6.3668L1.21474 4.37492L1.13045 4.41181C0.553342 5.47987 0.208876 6.68606 0.180054 7.97019C0.151231 9.25433 0.441232 10.4747 0.969842 11.5676L3.66135 9.62552Z"
                    fill="#FBBC05"
                />
                <path
                    d="M8.44718 3.265C9.97892 3.29938 10.9976 3.96907 11.5747 4.52255L13.9262 2.37789C12.5411 1.06202 10.7188 0.232528 8.51635 0.183094C5.32591 0.111484 2.53039 1.83856 1.13053 4.41213L3.72313 6.47285C4.42798 4.56592 6.29004 3.21659 8.44718 3.265Z"
                    fill="#EB4335"
                />
            </g>
            <defs>
                <clipPath id="clip0_12398_33886">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0.359009) rotate(1.28579)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default function NavArrowDownIcon() {
    return (
        <svg
            width="13"
            height="15"
            viewBox="0 0 13 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.4"
                d="M6.5029 8.33907L4.85207 10.0026L0.383322 5.48175C0.252727 5.34989 0.179359 5.17105 0.179359 4.98458C0.179359 4.79811 0.252727 4.61927 0.383322 4.48741L1.04058 3.82237C1.1708 3.69092 1.34741 3.61707 1.53156 3.61707C1.71571 3.61707 1.89232 3.69092 2.02254 3.82237L6.5029 8.33907Z"
                fill="#576A81"
            />
            <path
                d="M12.6228 5.48293L6.9933 11.1762C6.86304 11.3076 6.68645 11.3814 6.50232 11.3814C6.31819 11.3814 6.1416 11.3076 6.01134 11.1762L4.85062 10.0043L10.9833 3.82268C11.1135 3.69122 11.2901 3.61737 11.4742 3.61737C11.6584 3.61737 11.835 3.69122 11.9652 3.82268L12.6228 4.4886C12.7534 4.62046 12.8267 4.79929 12.8267 4.98576C12.8267 5.17224 12.7534 5.35107 12.6228 5.48293Z"
                fill="#576A81"
            />
        </svg>
    );
}

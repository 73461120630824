import { createSlice } from "@reduxjs/toolkit";

export interface NavState {
    isOpen: boolean;
}

const initialState: NavState = {
    isOpen: !window.matchMedia("(max-width: 1000px)").matches,
};

export const navSlice = createSlice({
    name: "nav",
    initialState,
    reducers: {
        toggleNavState: (state) => {
            state.isOpen = !state.isOpen;
        },
    },
});

export const { toggleNavState } = navSlice.actions;

export default navSlice.reducer;

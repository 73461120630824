import cn from "classnames";
import { useGetDiagnosisCodesDisplay } from "../../../../hooks/helpers";
import { RECORD_OF_DISCLOSURE_NOTE_LABELS } from "../../../../utils/constants";
import { IAddendumDetails } from "../../../../utils/types/notes";
import CheckIcon from "../../../Icons/Check";
import NoteContentItem from "../NoteContentItem/NoteContentItem";
import styles from "../../../../modules/notes/components/NoteContent/NoteContent.module.scss";
import useGetDiagnosisCodeToDisplay from "../../../../modules/notes/hooks";

interface ViewRecordOfDisclosureNoteProps {
    noteContent: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    };
    fieldAddendums: {
        [key: string]: IAddendumDetails[];
    } | null;
}

export default function ViewRecordOfDisclosureNote({
    noteContent,
    fieldAddendums,
}: ViewRecordOfDisclosureNoteProps) {
    const diagonosisCodes = useGetDiagnosisCodesDisplay(
        noteContent.diagnosis_codes
    );

    return (
        <>
            <NoteContentItem
                headerText={RECORD_OF_DISCLOSURE_NOTE_LABELS.diagnosis_codes}
                fieldId="diagnosis_codes"
                addendums={fieldAddendums?.diagnosis_codes}
                content={
                    diagonosisCodes && Array.isArray(diagonosisCodes) ? (
                        <div className={styles.checkboxes}>
                            {diagonosisCodes.map((code) => (
                                <div className={styles.checkbox} key={code}>
                                    <span
                                        className={cn(styles.checkbox_wrap, {
                                            [styles.checked]: true,
                                        })}
                                    >
                                        <CheckIcon />
                                    </span>
                                    {code}
                                </div>
                            ))}
                        </div>
                    ) : (
                        "--"
                    )
                }
            />
            <NoteContentItem
                headerText={
                    RECORD_OF_DISCLOSURE_NOTE_LABELS.secondary_diagnosis
                }
                fieldId="secondary_diagnosis"
                addendums={fieldAddendums?.secondary_diagnosis}
                content={useGetDiagnosisCodeToDisplay(
                    noteContent.secondary_diagnosis
                )}
            />
            <NoteContentItem
                headerText={RECORD_OF_DISCLOSURE_NOTE_LABELS.tertiary_diagnosis}
                fieldId="tertiary_diagnosis"
                addendums={fieldAddendums?.tertiary_diagnosis}
                content={useGetDiagnosisCodeToDisplay(
                    noteContent.tertiary_diagnosis
                )}
            />
            <NoteContentItem
                headerText={RECORD_OF_DISCLOSURE_NOTE_LABELS.disclosure_to_whom}
                fieldId="disclosure_to_whom"
                addendums={fieldAddendums?.disclosure_to_whom}
                content={noteContent.disclosure_to_whom || "--"}
            />
            <NoteContentItem
                headerText={RECORD_OF_DISCLOSURE_NOTE_LABELS.disclosure_type}
                fieldId="disclosure_type"
                addendums={fieldAddendums?.disclosure_type}
                content={noteContent.disclosure_type || "--"}
            />
            <NoteContentItem
                headerText={
                    RECORD_OF_DISCLOSURE_NOTE_LABELS.purpose_of_disclosure
                }
                fieldId="purpose_of_disclosure"
                addendums={fieldAddendums?.purpose_of_disclosure}
                content={noteContent.purpose_of_disclosure || "--"}
            />
            <NoteContentItem
                headerText={RECORD_OF_DISCLOSURE_NOTE_LABELS.type_disclosed}
                fieldId="type_disclosed"
                addendums={fieldAddendums?.type_disclosed}
                content={noteContent.type_disclosed || "--"}
            />
            <NoteContentItem
                headerText={
                    RECORD_OF_DISCLOSURE_NOTE_LABELS.method_of_disclosure
                }
                fieldId="method_of_disclosure"
                addendums={fieldAddendums?.method_of_disclosure}
                content={noteContent.method_of_disclosure || "--"}
            />
        </>
    );
}

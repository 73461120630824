/* eslint-disable react/no-array-index-key */
import cn from "classnames";
import * as React from "react";
import styles from "./Skeleton.module.scss";

interface SkeletonProps {
    /** Test id for selecting container in test environment */
    containerTestId?: string;
    /** Class for styling skeleton */
    className?: string;
    /**
     * Skeleton type
     *  Default type is the block loader
     * */
    type?: "table" | "document";
    /** Skeleton height */
    height?: string | number;
    /** Skeleton width */
    width?: string | number;
    /** Determines if skeleton should be rounded */
    isRounded?: boolean;
    /** Amount of skeleton shown
     * For table skeleton this is the amount of table data to show
     * For block skeleton (default) this will be the amount of block
     *   skeleton to show
     */
    count?: number;
}

export default function Skeleton({
    containerTestId,
    height,
    width,
    className,
    type,
    count,
    isRounded,
}: SkeletonProps) {
    switch (type) {
        case "table":
            return (
                <div
                    data-testid={containerTestId}
                    className={cn(styles.table, className)}
                >
                    <div className={styles.table_items}>
                        {[...new Array(count || 8)].map((item, i) => (
                            <div
                                key={i}
                                className={cn(styles.table_item, styles.pulse)}
                            />
                        ))}
                    </div>
                    <div className={styles.pagination}>
                        <span className={styles.pulse} />
                        <span className={styles.pulse} />
                    </div>
                </div>
            );

        default:
            return (
                <>
                    {[...new Array(count || 1)].map((item, i) => (
                        <span
                            key={i}
                            style={{ height, width }}
                            data-testid={containerTestId || ""}
                            className={cn(
                                styles.block,
                                styles.pulse,
                                className,
                                {
                                    [styles.block_rounded]: isRounded,
                                    [styles.block_count]: count,
                                }
                            )}
                        />
                    ))}
                </>
            );
    }
}

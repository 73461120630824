import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IApiResponse } from "../../../utils/types/api-response";
import {
    IDeleteUnmatchedPaymentPayload,
    IDeleteUnmatchedPaymentReponse,
    IMatchedPaymentResponse,
    IPaymentToMatchPayload,
    IRefreshPaymentStatusPayload,
    IRefreshedPaymentStatusResponse,
    IRematchPayment,
    ISplitPaymentPayload,
    ISplitPaymentResponse,
    IUpdateInvoicePayload,
    IUpdatedInvoiceResponse,
} from "../types/payments.types";
import {
    deleteUnmatchedPayment,
    matchPayment,
    refreshPaymentStatus,
    rematchPayment,
    splitPayment,
    updateInvoiceStatus,
} from "../services/payments.services";

export function useRefreshPaymentStatus() {
    return useMutation<
        IApiResponse<IRefreshedPaymentStatusResponse>,
        AxiosError<IApiResponse<IRefreshedPaymentStatusResponse>>,
        IRefreshPaymentStatusPayload
    >({
        mutationFn: (payload: IRefreshPaymentStatusPayload) =>
            refreshPaymentStatus(payload),
    });
}

export function useUpdateInvoiceStatus() {
    return useMutation<
        IApiResponse<IUpdatedInvoiceResponse>,
        AxiosError<IApiResponse<IUpdatedInvoiceResponse>>,
        IUpdateInvoicePayload
    >({
        mutationKey: ["update-invoice"],
        mutationFn: (payload: IUpdateInvoicePayload) =>
            updateInvoiceStatus(payload),
    });
}

export function useDeleteUnmatchedPayment() {
    return useMutation<
        IApiResponse<IDeleteUnmatchedPaymentReponse>,
        AxiosError<IApiResponse<IDeleteUnmatchedPaymentReponse>>,
        IDeleteUnmatchedPaymentPayload
    >({
        mutationKey: ["delete-payment"],
        mutationFn: (payload: IDeleteUnmatchedPaymentPayload) =>
            deleteUnmatchedPayment(payload),
    });
}

export function useMatchPayment() {
    return useMutation<
        IApiResponse<IMatchedPaymentResponse>,
        AxiosError<IApiResponse<IMatchedPaymentResponse>>,
        IPaymentToMatchPayload
    >({
        mutationKey: ["match-payment"],
        mutationFn: (payload: IPaymentToMatchPayload) => matchPayment(payload),
    });
}

export function useRematchPayment() {
    return useMutation<
        IApiResponse<{ message: string }>,
        AxiosError<IApiResponse<{ message: string }>>,
        IRematchPayment
    >({
        mutationKey: ["rematch-payment"],
        mutationFn: (payload: IRematchPayment) => rematchPayment(payload),
    });
}

export function useSplitPayment() {
    return useMutation<
        IApiResponse<ISplitPaymentResponse>,
        AxiosError<IApiResponse<ISplitPaymentResponse>>,
        ISplitPaymentPayload
    >({
        mutationKey: ["split-payment"],
        mutationFn: (payload: ISplitPaymentPayload) => splitPayment(payload),
    });
}

import * as React from "react";
import { Button, Dialog, DialogContent } from "@jhool-io/fe-components";
import { useParams } from "react-router-dom";
import { SupportedUploadMethod } from "../../../../../utils/types/filepicker";
import { formatDate } from "../../../../../utils/helpers";
import DocumentViewer from "../../../../../components/DocumentViewer/DocumentViewer";
import FilePicker from "../../../../../components/FilePicker/FilePicker";
import ArrowRightIcon from "../../../../../components/Icons/ArrowRight";
import ListState from "../../../../../components/ListState/ListState";
import Skeleton from "../../../../../components/Skeleton/Skeleton";
import {
    ClientDocumentType,
    ClientDocumentTypes,
    IClientDocument,
} from "../../../types/clients.types";
import { useFetchDocuments } from "../../../hooks/clients.queries";

type ModalTypes = "view-document" | "add-document";

export default function InsuranceCardImages() {
    const [modalInView, setModalInView] = React.useState<ModalTypes | null>(
        null
    );
    const [documentInViewDetails, setDocumentInViewDetails] =
        React.useState<IClientDocument | null>();

    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;

    // Fetch client documents
    const { data, isLoading, isSuccess, error } = useFetchDocuments({
        client_id: clientId,
        type: ClientDocumentType.INSURANCE_SCAN,
    });

    const handleSetModalInView = (
        modal: ModalTypes | null,
        document?: IClientDocument | null
    ) => {
        setModalInView(() => modal);
        if (document) {
            setDocumentInViewDetails(() => document);
        }
    };

    return (
        <>
            <Dialog open={modalInView === "add-document"}>
                <DialogContent
                    showFooter={false}
                    title="Add insurance card document"
                    variant="center"
                    handleCloseDialog={() => handleSetModalInView(null)}
                >
                    <FilePicker
                        name="filepicker"
                        uploadMethods={[
                            {
                                method: SupportedUploadMethod.manualupload,
                            },
                            {
                                method: SupportedUploadMethod.googledrive,
                            },
                            {
                                method: SupportedUploadMethod.dropbox,
                            },
                        ]}
                        uploadType="insurance-card"
                        successCallback={() => handleSetModalInView(null)}
                        documentToUploadType={
                            ClientDocumentTypes.INSURANCE_SCAN
                        }
                    />
                </DialogContent>
            </Dialog>
            {documentInViewDetails && (
                <Dialog
                    open={
                        modalInView === "view-document" &&
                        Boolean(documentInViewDetails)
                    }
                >
                    <DialogContent
                        showFooter={false}
                        handleCloseDialog={() => handleSetModalInView(null)}
                        wrapperClass="w-[900px]"
                    >
                        <DocumentViewer
                            document={documentInViewDetails}
                            file={documentInViewDetails.url}
                        />
                    </DialogContent>
                </Dialog>
            )}
            {isLoading && (
                <div className="grid grid-cols-[repeat(auto-fit,minmax(262px,1fr))] gap-8">
                    <Skeleton
                        containerTestId="documents-loader"
                        height="80px"
                        width="40%"
                    />
                    <Skeleton
                        containerTestId="documents-loader"
                        height="80px"
                        width="40%"
                    />
                    <Skeleton
                        containerTestId="documents-loader"
                        height="80px"
                        width="40%"
                    />
                </div>
            )}
            {error && error?.response?.status !== 404 && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display client insurance card documents at this time please try again later`
                    }
                />
            )}
            {data && isSuccess && data.data.length === 0 && (
                <ListState
                    listHeader="Insurance card documents"
                    stateHelperText="Client insurance card documents will appear here once added"
                    isError={false}
                    emptyMessage="Please add front and back view of the document"
                    wrapperClass=""
                    emptyBtnProps={{
                        showButton: true,
                        buttonText: "Add documents",
                        onButtonClick: () =>
                            handleSetModalInView("add-document"),
                    }}
                />
            )}
            {data && isSuccess && data.data.length > 0 && (
                <div className="w-full bg-white rounded-r8 border border-strokedark">
                    <div className="flex flex-col gap-y-12 items-start min-[500px]:flex-row min-[500px]:justify-between min-[500px]:items-center  px-24 py-[29px] border-b border-b-stroke-divider">
                        <h3 className="text-base font-bold">
                            Insurance card documents
                        </h3>
                        <Button
                            type="button"
                            onClick={() => handleSetModalInView("add-document")}
                            className="w-full min-[500px]:w-auto"
                        >
                            Add documents
                        </Button>
                    </div>
                    <div className="p-24 grid grid-cols-[repeat(auto-fit,minmax(262px,1fr))] gap-8 ">
                        {data.data.map((doc) => (
                            <div
                                className="flex flex-col gap-y-24 bg-card-bg rounded-r8 p-16"
                                key={doc.document_id}
                            >
                                <span className="text-sm text-black font-medium text-left">
                                    {doc.title}
                                </span>
                                <div className="flex justify-between items-center font-normal text-xs text-black">
                                    {formatDate(doc.uploaded_date)}
                                    <Button
                                        variant="normal"
                                        onClick={() =>
                                            handleSetModalInView(
                                                "view-document",
                                                doc
                                            )
                                        }
                                        className="p-0 text-xs font-semibold text-primary"
                                    >
                                        View doc
                                        <ArrowRightIcon stroke="#1A738D" />
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}

export const STATES: { name: string; abbrv: string }[] = [
    { name: "Alabama", abbrv: "AL" },
    { name: "Alaska", abbrv: "AK" },
    { name: "Arizona", abbrv: "AZ" },
    { name: "Arkansas", abbrv: "AR" },
    { name: "California", abbrv: "CA" },
    { name: "Colorado", abbrv: "CO" },
    { name: "Connecticut", abbrv: "CT" },
    { name: "DC", abbrv: "DC" },
    { name: "Delaware", abbrv: "DE" },
    { name: "Florida", abbrv: "FL" },
    { name: "Georgia", abbrv: "GA" },
    { name: "Hawaii", abbrv: "HI" },
    { name: "Idaho", abbrv: "ID" },
    { name: "Illinois", abbrv: "IL" },
    { name: "Indiana", abbrv: "IN" },
    { name: "Iowa", abbrv: "IA" },
    { name: "Kansas", abbrv: "KS" },
    { name: "Kentucky", abbrv: "KY" },
    { name: "Louisiana", abbrv: "LA" },
    { name: "Maine", abbrv: "ME" },
    { name: "Maryland", abbrv: "MD" },
    { name: "Massachusetts", abbrv: "MA" },
    { name: "Michigan", abbrv: "MI" },
    { name: "Minnesota", abbrv: "MN" },
    { name: "Mississippi", abbrv: "MS" },
    { name: "Missouri", abbrv: "MO" },
    { name: "Montana", abbrv: "MT" },
    { name: "Nebraska", abbrv: "NE" },
    { name: "Nevada", abbrv: "NV" },
    { name: "New Hampshire", abbrv: "NH" },
    { name: "New Jersey", abbrv: "NJ" },
    { name: "New Mexico", abbrv: "NM" },
    { name: "New York", abbrv: "NY" },
    { name: "North Carolina", abbrv: "NC" },
    { name: "North Dakota", abbrv: "ND" },
    { name: "Ohio", abbrv: "OH" },
    { name: "Oklahoma", abbrv: "OK" },
    { name: "Oregon", abbrv: "OR" },
    { name: "Pennsylvania", abbrv: "PA" },
    { name: "Rhode Island", abbrv: "RI" },
    { name: "South Carolina", abbrv: "SC" },
    { name: "South Dakota", abbrv: "SD" },
    { name: "Tennessee", abbrv: "TN" },
    { name: "Texas", abbrv: "TX" },
    { name: "Utah", abbrv: "UT" },
    { name: "Vermont", abbrv: "VT" },
    { name: "Virginia", abbrv: "VA" },
    { name: "Washington", abbrv: "WA" },
    { name: "West Virginia", abbrv: "WV" },
    { name: "Wisconsin", abbrv: "WI" },
    { name: "Wyoming", abbrv: "WY" },
];

export const getStateToDisplay = (state: string) => {
    if (!state) return "--";

    return (
        STATES.find(
            (val) =>
                val.abbrv.toLowerCase() === state.toLowerCase() ||
                val.name.toLowerCase() === state.toLowerCase()
        )?.name || "--"
    );
};

export const getStateInShortForm = (state: string | undefined | null) => {
    if (!state) return "--";

    return (
        STATES.find(
            (val) =>
                val.abbrv.toLowerCase() === state.toLowerCase() ||
                val.name.toLowerCase() === state.toLowerCase()
        )?.abbrv || "--"
    );
};

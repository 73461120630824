import * as React from "react";
import { useForm } from "react-hook-form";
import {
    Button,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SearchInput,
} from "@jhool-io/fe-components";
import { child, push, ref, update } from "firebase/database";
import { useSearchParams } from "react-router-dom";
import { ICreateThirtydaysProviderPayload } from "../../types/thirtydays-providers";
import useToast from "../../../../hooks/useToast";
import { rtdb } from "../../../../firebase";
import { useFetchProviders } from "../../../providers/hooks/providers.queries";
import { useDebounce } from "../../../../hooks/helpers";
import { cn } from "../../../../utils/helpers";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import { APP_COLORS } from "../../../../utils/constants";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";

interface NewThirtydaysProviderFormProps {
    onFormSubmit: () => void;
}

type ComboOption = {
    value: string;
    label: string;
};

export default function NewThirtydaysProviderForm({
    onFormSubmit,
}: NewThirtydaysProviderFormProps) {
    const [searchParams, setSearchParams] = useSearchParams();

    const [providerSearchString, setProviderSearchString] = React.useState("");
    const [selectedProvider, setSelectedProvider] =
        React.useState<ComboOption | null>(null);
    const [showProvidersListPopover, setShowProvidersListPopover] =
        React.useState(false);

    const debouncedProviderSearchString = useDebounce(
        providerSearchString,
        500
    );

    const providersListContainerRef = React.useRef<HTMLDivElement>(null);

    const { data, isLoading, error, isSuccess } = useFetchProviders({
        page: 1,
        limit: 20,
        search_string: debouncedProviderSearchString,
    });

    const { toast } = useToast();

    const { handleSubmit } = useForm<ICreateThirtydaysProviderPayload>({});

    const onSubmit = async () => {
        if (!selectedProvider) {
            toast({ mode: "error", message: "Provider field cannot be empty" });
            return;
        }
        searchParams.set("adding_provider", "true");
        setSearchParams(searchParams);

        const newProviderKey = push(
            child(ref(rtdb), "thirtydaysproviders")
        ).key;

        try {
            await update(ref(rtdb, `/thirtydaysproviders/${newProviderKey}`), {
                provider_name: selectedProvider?.label,
                provider_id: selectedProvider?.value,
                created_at: new Date().toISOString(),
            });

            toast({
                mode: "success",
                message: "Provider added successfully",
            });

            onFormSubmit();
        } catch {
            toast({
                mode: "error",
                message:
                    "Could not add provider at this time, please try again later",
            });
            searchParams.delete("adding_provider");
            setSearchParams(searchParams);
        }
    };

    return (
        <form id="add-provider" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex-1" ref={providersListContainerRef}>
                <Popover
                    open={showProvidersListPopover}
                    onOpenChange={setShowProvidersListPopover}
                >
                    <PopoverTrigger
                        asChild
                        onClick={() => setShowProvidersListPopover(true)}
                        className="w-full"
                    >
                        <Button
                            size="auto"
                            variant="normal"
                            className={cn(
                                "px-16 h-40 shadow-active gap-x-8 text-xs data-[state=open]:border-primary font-medium flex justify-between",
                                {
                                    capitalize: selectedProvider,
                                }
                            )}
                        >
                            {selectedProvider
                                ? selectedProvider.label
                                : "Select a provider"}
                            <ChevronDownIcon stroke={APP_COLORS.COLOR_BLACK} />
                        </Button>
                    </PopoverTrigger>

                    <PopoverContent
                        className="px-4 pt-[3px] py-[3px] w-[316px] rounded-r8 border-none shadow-[0px_0px_0px_1px_#98a1b219,_0px_15px_35px_-5px_#11182626,_0px_5px_15px_0px_#00000014] z-[40] popover-full"
                        align="start"
                        container={providersListContainerRef.current}
                    >
                        <SearchInput
                            placeholder="Search by provider name"
                            containerClass="h-24"
                            onChange={(e) =>
                                setProviderSearchString(e.target.value)
                            }
                        />

                        <div className="flex flex-col items-stretch max-h-[230px] overflow-y-auto">
                            {isLoading && (
                                <div className="flex flex-col gap-y-4 items-stretch w-full">
                                    {[1, 2, 3, 4, 5].map((item) => (
                                        <Skeleton
                                            key={item}
                                            width="100%"
                                            height="24px"
                                            containerTestId="providers-loader"
                                        />
                                    ))}
                                </div>
                            )}

                            {error && (
                                <ListState
                                    isError
                                    context="general"
                                    stateHelperText="Please try again later"
                                    errorMsg={
                                        error?.response?.data?.message ||
                                        "Error loading providers"
                                    }
                                />
                            )}

                            {data?.data && isSuccess && (
                                <>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start !rounded-none"
                                        onClick={() => {
                                            setProviderSearchString("");
                                            setSelectedProvider(null);
                                            setShowProvidersListPopover(false);
                                        }}
                                    >
                                        Clear selected
                                    </Button>
                                    {data?.data.map((provider) => (
                                        <Button
                                            key={provider.provider_id}
                                            size="auto"
                                            variant="normal"
                                            className="text-xs px-[6px] py-4 font-medium capitalize w-full justify-start !rounded-none"
                                            onClick={() => {
                                                setSelectedProvider({
                                                    label: provider.provider_name,
                                                    value: provider.provider_id,
                                                });
                                                setShowProvidersListPopover(
                                                    false
                                                );
                                            }}
                                        >
                                            {provider.provider_name}
                                        </Button>
                                    ))}
                                </>
                            )}
                        </div>
                    </PopoverContent>
                </Popover>
            </div>
        </form>
    );
}

import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { Input, Select } from "@jhool-io/fe-components";
import { useSearchParams } from "react-router-dom";
import { INewUserPayload, UserRole } from "../../types/user-management.types";
import { useAddUsers } from "../../hooks/user-management.mutations";
import { useFetchUsersList } from "../../hooks/user-management.queries";
import useToast from "../../../../hooks/useToast";
import Infobox from "../../../../components/Infobox/Infobox";
import InfoIcon from "../../../../components/Icons/InfoIcon";

type Options = {
    label: React.ReactNode;
    value: string;
};

interface ActionFormProps {
    onFormSubmit: () => void;
    selectedActionType: string;
}

const schema = yup.object().shape({
    email: yup
        .string()
        .email("Please enter a valid email")
        .required("Email is required"),
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    roles: yup.array().min(1, "Role is required").required("Role is required"),
});

const assignRoleForSelect: Options[] = [
    {
        value: UserRole.SUPER_ADMIN,
        label: "Super Admin",
    },
    {
        value: UserRole.AUDITOR,
        label: "Auditor",
    },
    {
        value: UserRole.BILLER,
        label: "Biller",
    },
    {
        value: UserRole.BILLER_ADMIN,
        label: "Biller Admin",
    },
    {
        value: UserRole.CLIENT_SUPPORT,
        label: "Client Support",
    },
    {
        value: UserRole.CODER,
        label: "Coder",
    },
    {
        value: UserRole.PROVIDER,
        label: "Provider",
    },
    {
        value: UserRole.USER_SUPPORT,
        label: "User Support",
    },
];

export default function AddUserForm({
    selectedActionType,
    onFormSubmit,
}: ActionFormProps) {
    const [searchParams] = useSearchParams();

    // Get filter values from url searchparams
    const pageFilter = Number(searchParams.get("page")) || 1;
    const searchFilter = searchParams.get("search") || "";
    const roleFilter = searchParams.get("role") || "";
    const statusFilter =
        searchParams.get("status") === null
            ? ""
            : searchParams.get("status") || "active";
    const limitFilter = Number(searchParams.get("limit")) || 20;

    const users = useFetchUsersList({
        page: pageFilter,
        limit: limitFilter,
        status: statusFilter,
        role: roleFilter || "",
        search_string: searchFilter,
    });

    // React hook form values
    const {
        control,
        handleSubmit,
        register,
        watch,
        formState: { errors },
    } = useForm<INewUserPayload>({
        resolver: yupResolver(schema),
    });

    const roles = watch("roles");

    const { mutate } = useAddUsers();

    const queryClient = useQueryClient();

    const { toast } = useToast();

    const onSubmitNewUser = (userData: INewUserPayload) => {
        const payload = {
            users: [
                {
                    first_name: userData.first_name,
                    last_name: userData.last_name,
                    email: userData.email,
                    roles: userData.roles,
                },
            ],
        };

        mutate(payload, {
            onSuccess: (res) => {
                queryClient.invalidateQueries({
                    queryKey: ["users"],
                });
                toast({
                    mode: "success",
                    message: res.message || "User added successfully!",
                });
                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not add user at this time",
                });
            },
        });
    };
    return (
        <div>
            {selectedActionType === "invite_user" && (
                <>
                    <div style={{ marginBottom: "2rem" }}>
                        <Infobox
                            icon={<InfoIcon />}
                            message={
                                users.data?.practice_domains
                                    ? `Only ${users.data?.practice_domains.join(
                                          ", "
                                      )} email addresses are accepted`
                                    : "Only users in this practice are accepted"
                            }
                            className="!text-primary"
                        />
                    </div>
                    <form
                        id="add-user"
                        name="invite_user"
                        aria-label="invite_user"
                        className="mt-24"
                        onSubmit={handleSubmit(onSubmitNewUser)}
                    >
                        <div className="fg fg-space-between two flex">
                            <Input
                                {...register("first_name")}
                                label="First name"
                                placeholder="First name"
                                hasError={!!errors.first_name}
                                errorText={errors.first_name?.message}
                            />
                            <Input
                                {...register("last_name")}
                                label="Last name"
                                placeholder="Last name"
                                hasError={!!errors.last_name}
                                errorText={errors.last_name?.message}
                            />
                        </div>
                        <div className="fg">
                            <Input
                                {...register("email")}
                                label="Email address"
                                placeholder="Email address"
                                type="text"
                                hasError={!!errors.email}
                                errorText={errors.email?.message}
                            />
                        </div>
                        <div className="fg">
                            <Controller
                                name="roles"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        label="Assign Role"
                                        placeholder="Assign Role"
                                        options={assignRoleForSelect}
                                        onChange={(val) => {
                                            field.onChange(
                                                (val as Options[]).map(
                                                    (role) => role.value
                                                )
                                            );
                                        }}
                                        hasError={!!errors?.roles}
                                        errorText={
                                            errors?.roles?.type === "typeError"
                                                ? "Roles are required"
                                                : errors?.roles?.message
                                        }
                                        multiHasValues={
                                            roles && roles.length > 0
                                        }
                                        isMulti
                                        hideSelectedOptions
                                        isSearchable
                                        isLongListInDialog
                                    />
                                )}
                            />
                        </div>
                    </form>
                </>
            )}
        </div>
    );
}

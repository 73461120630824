import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { IApiResponse } from "../../../utils/types/api-response";
import {
    ICreatePayerPayload,
    IDeletePayerPayload,
} from "../types/payers.types";
import {
    createPayer,
    deletePayer,
    editPayer,
} from "../services/payers.services";

export function useCreatePayer() {
    return useMutation<
        IApiResponse<object>,
        AxiosError<IApiResponse<object>>,
        ICreatePayerPayload
    >({
        mutationKey: ["create_payer"],
        mutationFn: (payload: ICreatePayerPayload) => createPayer(payload),
    });
}

export function useDeletePayer() {
    return useMutation<
        IApiResponse<object>,
        AxiosError<IApiResponse<object>>,
        IDeletePayerPayload
    >({
        mutationKey: ["delete_payer"],
        mutationFn: (payload: IDeletePayerPayload) => deletePayer(payload),
    });
}

export function useUpdatePayer() {
    return useMutation<
        IApiResponse<object>,
        AxiosError<IApiResponse<object>>,
        ICreatePayerPayload
    >({
        mutationKey: ["update_payer"],
        mutationFn: (payload: ICreatePayerPayload) => editPayer(payload),
    });
}

import { Dialog, DialogContent } from "@jhool-io/fe-components";

function LoadPreviousSessionNotificationModal({
    showPrompt,
    setShowPrompt,
    handleLoadPreviousSession,
}: {
    showPrompt: boolean;
    setShowPrompt: (value: React.SetStateAction<boolean>) => void;
    handleLoadPreviousSession: () => void;
}) {
    return (
        <div>
            {showPrompt && (
                <Dialog open={showPrompt}>
                    <DialogContent
                        variant="center"
                        showFooter
                        cancelText="Cancel"
                        onCancelClick={() => setShowPrompt(false)}
                        saveText="Yes, Load Previous Note"
                        onSaveClick={handleLoadPreviousSession}
                        handleCloseDialog={() => setShowPrompt(false)}
                        title="Load Previous"
                    >
                        <div>
                            <h3 className="font-medium text-xl mb-8">
                                Load previous note
                            </h3>
                            <p className="text-gray font-normal text-base">
                                Loading this note will overwrite your current
                                content. Are you sure you want to proceed?
                            </p>
                        </div>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    );
}
export default LoadPreviousSessionNotificationModal;

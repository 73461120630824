import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { Input } from "@jhool-io/fe-components";
import { IUpdateInvoiceCharge } from "../../../../utils/types/billing";
import { useUpdateInvoiceCharge } from "../../../../hooks/mutations/billing";
import useToast from "../../../../hooks/useToast";
import { makeStringFirstLetterCapital } from "../../../../utils/helpers";

interface UpdateChargeProps {
    /** function to call when the form's submit button is clicked */
    onFormSubmit(): void;
    /** the ID of the invoice */
    invoiceId: string;
    /** Current charge amount */
    amount: number;
    // the selected action type for the form.
    selectedChargeType: string;
}

export default function UpdateChargeForm({
    onFormSubmit,
    invoiceId,
    amount,
    selectedChargeType,
}: UpdateChargeProps) {
    const { toast } = useToast();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IUpdateInvoiceCharge>({
        resolver: yupResolver(
            yup.object().shape({
                amount: yup
                    .number()
                    .required("Charge amount is required")
                    .min(0),
            })
        ),
        mode: "onChange",
    });

    // Query client
    const queryClient = useQueryClient();

    const updateCharge = useUpdateInvoiceCharge();

    const onSubmit = (data: IUpdateInvoiceCharge) => {
        const payload = {
            ...data,
            invoice_id: invoiceId,
            charge_type: selectedChargeType,
        };

        updateCharge.mutate(payload, {
            onSuccess: (res) => {
                queryClient.invalidateQueries({
                    queryKey: ["get-payment"],
                });
                queryClient.invalidateQueries({
                    queryKey: ["invoices"],
                });

                toast({
                    mode: "success",
                    message:
                        makeStringFirstLetterCapital(res.message) ||
                        "Charge updated successfully",
                    duration: 6000,
                });

                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Error updating charge please try again later",
                    duration: 6000,
                });
            },
        });
    };

    return (
        <form id="update-charge" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg">
                <Input
                    {...register("amount")}
                    label="Confirm charge amount($)"
                    placeholder="Confirm charge amount($)"
                    hasError={!!errors.amount}
                    errorText={
                        errors.amount?.type === "typeError"
                            ? "This field is required and must be a number"
                            : "Amount must not be less than 1"
                    }
                    defaultValue={Math.abs(amount as number)}
                />
            </div>
        </form>
    );
}

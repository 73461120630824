import * as React from "react";

export default function VisaIcon() {
    return (
        <svg
            width="40"
            height="14"
            viewBox="0 0 40 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.91708 13.7085H6.48418L3.90993 3.5987C3.78774 3.13365 3.52831 2.72252 3.14669 2.52875C2.19431 2.0418 1.14486 1.65425 0 1.4588V1.06957H5.53013C6.29337 1.06957 6.8658 1.65425 6.96121 2.33329L8.29688 9.62582L11.7281 1.06957H15.0656L9.91708 13.7085ZM16.9737 13.7085H13.7316L16.4013 1.06957H19.6434L16.9737 13.7085ZM23.8378 4.57093C23.9332 3.89021 24.5057 3.50098 25.1735 3.50098C26.223 3.40325 27.3661 3.59871 28.3202 4.08398L28.8926 1.36276C27.9386 0.973532 26.8891 0.778076 25.9367 0.778076C22.7901 0.778076 20.5003 2.52876 20.5003 4.95847C20.5003 6.80688 22.1222 7.77742 23.2671 8.3621C24.5057 8.9451 24.9827 9.33433 24.8873 9.91733C24.8873 10.7918 23.9332 11.1811 22.9809 11.1811C21.836 11.1811 20.6911 10.8896 19.6434 10.4026L19.0709 13.1255C20.2158 13.6108 21.4544 13.8062 22.5992 13.8062C26.1276 13.9023 28.3202 12.1533 28.3202 9.5281C28.3202 6.2222 23.8378 6.02843 23.8378 4.57093ZM39.6667 13.7085L37.0924 1.06957H34.3273C33.7549 1.06957 33.1825 1.4588 32.9917 2.0418L28.2248 13.7085H31.5623L32.2284 11.8618H36.3292L36.7108 13.7085H39.6667ZM34.8044 4.47321L35.7567 9.23661H33.0871L34.8044 4.47321Z"
                fill="#172B85"
            />
        </svg>
    );
}

import Navbar from "../../../components/Navbar/Navbar";
import UserListsWrapper from "../components/UserListsWrapper/UserListsWrapper";

export default function UsersManagementPage() {
    return (
        <>
            <Navbar title="User Management" />
            <div className="page">
                <UserListsWrapper />
            </div>
        </>
    );
}

import * as React from "react";
import { useParams } from "react-router-dom";
import { Accept, useDropzone } from "react-dropzone";
import useDrivePicker from "react-google-drive-picker";
import cn from "classnames";
import { Button, Select } from "@jhool-io/fe-components";
import { useFetchClientBasicAndPersonalInfo } from "../../hooks/queries/client";
import useFileUpload from "../../hooks/useFileUpload/useFileUpload";
import {
    ClientDocumentTypes,
    DocumentUploadType,
} from "../../utils/types/client";
import {
    IThirdPartyDocument,
    SupportedUploadMethod,
    ThirdPartyType,
} from "../../utils/types/filepicker";
import {
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../utils/helpers";
import FolderOpenIcon from "../Icons/FolderOpen";
import LinkIcon from "../Icons/Link";
import TimesIcon from "../Icons/Times";
import PlusIcon from "../Icons/Plus";

interface FilePickerProps extends React.InputHTMLAttributes<HTMLInputElement> {
    /** Allow multiple uploads */
    allowMultiple?: boolean;
    /** Detemines upload methods to show */
    uploadMethods: {
        method: SupportedUploadMethod;
    }[];
    /** Type of file to be uploaded */
    uploadType: DocumentUploadType;
    /** Insurance id if uploading insurance document */
    insuranceId?: string;
    /** Callback to call after successful upload */
    successCallback: () => void;
    /** A default document type value  */
    documentToUploadType?: ClientDocumentTypes;
    /** RemitId id if uploading remit eob */
    remitId?: string;
    /** claimId id if uploading claims attachment */
    claimId?: string;
    /** Name on remit file */
    remitName?: string;
    /** Text for the submit action * */
    actionText?: string;
    /** Show cancel button * */
    showCancelButton?: boolean;
    /** callback for when the user clicks on cancel button * */
    cancelBtnClick?: () => void;
    /** Document types accepted text */
    acceptedTypes?: string;
    /** dropzone accepted file types */
    dropzoneAcceptedTypes?: Accept;
    /** Google drive accepted file types
     * check https://www.npmjs.com/package/react-google-drive-picker viewID options
     * for allowed values
     */
    googleDriveViewIdOption?:
        | "DOCS"
        | "DOCS_IMAGES"
        | "DOCS_IMAGES_AND_VIDEOS"
        | "DOCS_VIDEOS"
        | "DOCUMENTS"
        | "DRAWINGS"
        | "FOLDERS"
        | "FORMS"
        | "PDFS"
        | "SPREADSHEETS"
        | "PRESENTATIONS";
}

// Select options type
type SelectOption = {
    label: string;
    value: ClientDocumentTypes;
};

//  Select options for document type select input
const selectOptionsForDocumentType: SelectOption[] = [
    ...Object.values(ClientDocumentTypes).map((item) => ({
        value: item,
        label: makeStringFirstLetterCapital(removeEnumUnderscore(item)),
    })),
];

export default function FilePicker({
    allowMultiple = true,
    uploadMethods,
    uploadType,
    insuranceId,
    successCallback,
    documentToUploadType,
    remitId,
    claimId,
    remitName,
    actionText = "Upload",
    showCancelButton,
    cancelBtnClick,
    acceptedTypes,
    dropzoneAcceptedTypes,
    googleDriveViewIdOption,
    ...props
}: FilePickerProps) {
    // Local component states
    const [documentType, setDocumentType] =
        React.useState<ClientDocumentTypes | null>(
            documentToUploadType || null
        );
    const [selectedFiles, setSelectedFiles] = React.useState<File[] | null>(
        null
    );
    const [googleDocFiles, setGoogleDocFiles] = React.useState<
        IThirdPartyDocument[] | null
    >(null);

    // Initialize the google drive picker hook
    const [openPicker] = useDrivePicker();

    // Function to call when google drive option is clicked
    const handleOpenGooglePicker = () => {
        openPicker({
            clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
            developerKey: import.meta.env.VITE_GOOGLE_DEVELOPER_KEY,
            viewId: googleDriveViewIdOption || "DOCS",
            showUploadView: true,
            showUploadFolders: true,
            supportDrives: true,
            multiselect: true,
            callbackFunction: (data) => {
                // Do not perform any action if user closes the popup
                if (data.action === "cancel") return;
                // Clear selected files from normal input element
                if (
                    data?.docs &&
                    data.docs.length > 0 &&
                    selectedFiles &&
                    selectedFiles.length > 0
                ) {
                    setSelectedFiles(null);
                }
                /** Check if list is empty or not and decide whether to 
                   append new files or not
                */
                const formatData: IThirdPartyDocument[] = data.docs.map(
                    (file) => ({
                        document_url: file.url,
                        third_party_id: file.id,
                        document_name: file.name,
                        third_party_type: ThirdPartyType.GOOGLE_DRIVE,
                    })
                );
                if (googleDocFiles && googleDocFiles.length > 0) {
                    setGoogleDocFiles([...formatData, ...googleDocFiles]);
                } else {
                    setGoogleDocFiles([...formatData]);
                }
            },
        });
    };

    // Function to call when file(s) are dropped in dropzone
    const onDrop = React.useCallback(
        (acceptedFiles: File[]) => {
            if (selectedFiles && selectedFiles.length > 0) {
                setSelectedFiles([...acceptedFiles, ...selectedFiles]);
            } else setSelectedFiles([...acceptedFiles]);
        },
        [selectedFiles]
    );

    // Initialize the useDropzone hook
    const { getRootProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: uploadType === "insurance-card" ? 2 : 0,
        accept: dropzoneAcceptedTypes || {
            "application/pdf": [".pdf"],
            "image/*": [".png", ".webp", ".jpeg", ".jpg", ".avif", ".svg"],
        },
    });

    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;

    // Hook for fetching client basic and personal info
    const { data } = useFetchClientBasicAndPersonalInfo(clientId);

    // Initialize the file upload hook
    const {
        mutation,
        uploadProgress,
        thirdPartyMutation,
        remitFileMutation,
        claimsMutation,
    } = useFileUpload({
        uploadType,
        files: selectedFiles as File[],
        client: data?.data,
        clientId,
        type: documentType || undefined,
        insuranceId: insuranceId || "",
        successCallback,
        documents: googleDocFiles || [],
        remitId,
        claimId,
        remitName,
    });

    // Function to determine the upload methods to show
    const handleCheckIfUploadMethodIsPresent = (
        method: SupportedUploadMethod
    ) => {
        const methodIndex = uploadMethods.findIndex(
            (item) => item.method === method
        );

        return methodIndex === -1 ? null : uploadMethods[methodIndex];
    };

    // Function to remove a selected file
    const handleRemoveSelectedFile = (filename: string) => {
        if (selectedFiles) {
            const filesArr = [...selectedFiles].filter(
                (file) => file.name !== filename
            );
            if (filesArr.length === 0) setSelectedFiles(null);
            else setSelectedFiles(filesArr);
        }
    };

    // Function for removing google third party selected file
    const handleRemoveGooglePickerFile = (filename: string) => {
        if (googleDocFiles) {
            const filesArr = googleDocFiles.filter(
                (file) => file.document_name !== filename
            );
            if (googleDocFiles.length === 0) setGoogleDocFiles(null);
            else setGoogleDocFiles(filesArr);
        }
    };

    // Function for setting selected files
    const handleSetSelectedFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            // Clear selected files from third party picker

            if (
                googleDocFiles &&
                googleDocFiles.length > 0 &&
                e.target.files.length > 0
            ) {
                setGoogleDocFiles(null);
            }
            if (selectedFiles && selectedFiles.length > 0) {
                setSelectedFiles([...e.target.files, ...selectedFiles]);
            } else setSelectedFiles([...e.target.files]);
        }
    };

    // Function for uploading document(s)
    const handleSaveButtonClicked = () => {
        mutation.mutate();
    };

    // Function for uploading document(s) from third party
    const handleSubmitThirdPartyUpload = () => {
        thirdPartyMutation.mutate();
    };

    const handleRemitFileUpload = () => {
        remitFileMutation.mutate();
    };

    const handleClaimsFileUpload = () => {
        claimsMutation.mutate();
    };

    const handleUpload = () => {
        if (remitId) {
            handleRemitFileUpload();
        } else if (claimId) {
            handleClaimsFileUpload();
        } else if (googleDocFiles) {
            handleSubmitThirdPartyUpload();
        } else {
            handleSaveButtonClicked();
        }
    };

    return (
        <div className="relative">
            <div className="pb-[60px] overflow-y-auto h-auto">
                <p className="text-base font-medium mb-4">Add new documents</p>
                <p className="text-xs font-normal mb-16">
                    You can upload multiple files at the same time
                </p>
                {handleCheckIfUploadMethodIsPresent(
                    SupportedUploadMethod.manualupload
                ) && (
                    <>
                        {uploadType === "documents" && (
                            <div className="mb-12">
                                <Select
                                    placeholder="Document type"
                                    options={selectOptionsForDocumentType}
                                    onChange={(value) =>
                                        setDocumentType(
                                            (value as SelectOption).value
                                        )
                                    }
                                    defaultValue={documentType}
                                />
                            </div>
                        )}
                        <div
                            className={cn(
                                "flex w-full items-center gap-x-[13px] pt-[31px] pr-[92px] pb-[30px] pl-24 border border-dashed border-primary rounded-[16px]",
                                {
                                    "border-[2px] border-dashed border-primary-dark":
                                        isDragActive,
                                }
                            )}
                            {...getRootProps()}
                        >
                            <div className="px-[17px] py-16 border border-[#D3F7FA]  bg-primary-50 rounded-[9.667px]">
                                <FolderOpenIcon />
                            </div>
                            <div className="flex flex-col">
                                <div className="text-sm font-medium">
                                    <label
                                        htmlFor="file-upload"
                                        className="font-semibold text-primary"
                                    >
                                        Click to upload{" "}
                                        <input
                                            className="w-full absolute left-[-99999px]"
                                            id="file-upload"
                                            name="file-upload"
                                            type="file"
                                            multiple={allowMultiple}
                                            onChange={handleSetSelectedFiles}
                                            max="2"
                                            {...props}
                                        />
                                    </label>
                                    or drag your file here
                                </div>
                                <p className="text-xs font-normal">
                                    {`Maximum file size of 50 MB: ${
                                        acceptedTypes ||
                                        ".png, .jpg, .jpeg, .svg"
                                    }`}
                                </p>
                            </div>
                        </div>

                        {uploadProgress > 0 && (
                            <div className="mt-5 h-4 bg-[rgba(185_186_163_0.4)]">
                                <div
                                    role="progressbar"
                                    aria-label="file upload progress value"
                                    aria-valuenow={uploadProgress}
                                    style={{ width: `${uploadProgress}%` }}
                                    className="h-full rounded-r8 bg-primary"
                                />
                            </div>
                        )}
                    </>
                )}
                {(handleCheckIfUploadMethodIsPresent(
                    SupportedUploadMethod.googledrive
                ) ||
                    handleCheckIfUploadMethodIsPresent(
                        SupportedUploadMethod.dropbox
                    )) && (
                    <div>
                        {handleCheckIfUploadMethodIsPresent(
                            SupportedUploadMethod.googledrive
                        ) && (
                            <Button
                                variant="normal"
                                aria-label="upload with googledrive"
                                onClick={handleOpenGooglePicker}
                                className="text-xs font-medium mt-16 p-0 gap-x-8"
                            >
                                <PlusIcon stroke="#212121" />
                                Or upload from google drive
                            </Button>
                        )}
                    </div>
                )}

                <div>
                    <div className="flex items-center gap-x-8 text-xs font-semibold">
                        <LinkIcon />
                        {selectedFiles?.length ||
                            googleDocFiles?.length ||
                            0}{" "}
                        files added
                    </div>
                    {googleDocFiles &&
                        googleDocFiles.map((file, i) => (
                            <div
                                key={`${file.document_name} - ${i + 1}`}
                                className="border-b border-strokedark last:border-b-0"
                            >
                                <div className="flex items-center justify-between w-full border border-strokedark rounded-r6 px-10 py-[6px] my-16">
                                    <div className="flex flex-col">
                                        <span className="text-sm font-normal">
                                            {`Client - ${
                                                documentType
                                                    ? documentType.replaceAll(
                                                          "-",
                                                          " "
                                                      )
                                                    : "document"
                                            }`}
                                        </span>
                                        <span className="font-normal text-xs text-gray">
                                            {file.document_name}
                                        </span>
                                    </div>
                                    <Button
                                        variant="normal"
                                        aria-label="delete icon"
                                        onClick={() =>
                                            handleRemoveGooglePickerFile(
                                                file.document_name
                                            )
                                        }
                                    >
                                        <TimesIcon />
                                    </Button>
                                </div>
                            </div>
                        ))}
                    {selectedFiles &&
                        selectedFiles.map((file, i) => (
                            <div
                                key={`${file.name} - ${i + 1}`}
                                className="border-b border-strokedark last:border-b-0 mb-12"
                            >
                                <div className="flex items-center justify-between w-full border border-strokedark rounded-r6 px-10 py-[6px] my-16">
                                    <div className="flex flex-col">
                                        <span className="text-sm font-normal">
                                            {`Client - ${
                                                documentType
                                                    ? removeEnumUnderscore(
                                                          documentType
                                                      )
                                                    : "document"
                                            }`}
                                        </span>
                                        <span className="font-normal text-xs text-gray">
                                            {file.name}
                                        </span>
                                    </div>
                                    <Button
                                        variant="normal"
                                        aria-label="delete icon"
                                        className="flex bg-card-bg !rounded-full p-8"
                                        onClick={() =>
                                            handleRemoveSelectedFile(file.name)
                                        }
                                    >
                                        <TimesIcon stroke="#8E8E8E" />
                                    </Button>
                                </div>
                            </div>
                        ))}
                </div>
            </div>

            <div className="fixed bottom-0 left-0 w-full bg-white p-4 shadow-md flex justify-end py-16 pr-24 rounded-b-r8 border border-stroke-divider">
                {showCancelButton && (
                    <Button
                        variant="secondary"
                        className="bg-[#F1F1ED]"
                        onClick={cancelBtnClick}
                    >
                        Cancel
                    </Button>
                )}
                <Button
                    disabled={
                        uploadType === "insurance-file"
                            ? (!selectedFiles && !googleDocFiles) ||
                              mutation.isLoading ||
                              thirdPartyMutation.isLoading
                            : (!selectedFiles && !googleDocFiles) ||
                              !documentType ||
                              mutation.isLoading ||
                              thirdPartyMutation.isLoading ||
                              claimsMutation.isLoading
                    }
                    onClick={handleUpload}
                >
                    {actionText}
                </Button>
            </div>
        </div>
    );
}

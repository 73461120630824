import * as React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Select } from "@jhool-io/fe-components";
import useToast from "../../../../hooks/useToast";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import { useUpdateInvoiceStatus } from "../../../payments/hooks/payments.mutations";
import { InvoiceIssueCategory } from "../../../../utils/types/billing";
import { IUpdatedInvoiceResponse } from "../../../payments/types/payments.types";
import { IPaginatedApiResponse } from "../../../../utils/types/api-response";
import { buildSuccessMessage } from "../../../../utils/helpers";
import { AddTextEditor } from "../../../../components/TextEditor/AddTextEditor/AddTextEditor";

export interface PendingResolutionMetaData {
    noteId?: string;
    invoiceId?: string;
    clientId?: string;
    invoiceIds?: string[];
}

interface PendingResolutionProps {
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
    /**
     * Additional information from charge client flow
     */
    additionalInfo?: string;
    /** optional invoice id prop */
    invoiceID?: string;
    /** use this object to pass values to the pending resolution form
     * which cannot be gotten from the url
     */
    metaProps?: PendingResolutionMetaData;
}

const schema = yup.object({
    issue_category: yup.string().required("Issue category is required"),
});

interface IPendingResolutionPayload {
    issue_category: string;
    issue_notes?: string;
}

type Option = {
    label: React.ReactNode;
    value: boolean;
};

export default function PendingResolutionForm({
    onFormSubmit,
    additionalInfo,
    invoiceID,
    metaProps,
}: PendingResolutionProps) {
    const [issueNotes, setIssueNotes] = React.useState("");

    const [searchParams] = useSearchParams();

    const cfModalValue = searchParams.get("cf_modal");

    // Get id from url params
    const params = useParams();

    const location = useLocation();

    // Toast for success and error states
    const { toast } = useToast();

    const clientId = params.clientId as string;

    const noteIdFromParams = params.noteId as string;

    const { data } = useFetchSessionNote(
        clientId || metaProps?.clientId || "",
        noteIdFromParams || metaProps?.noteId || "",
        Boolean(clientId || metaProps?.clientId) &&
            Boolean(noteIdFromParams || metaProps?.noteId)
    );

    // Query client
    const queryClient = useQueryClient();

    // hook for updating invoice status
    const patchInvoiceStatus = useUpdateInvoiceStatus();

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IPendingResolutionPayload>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const issueCategoriesForSelect = [
        {
            label: "Card Payment Declined",
            value: InvoiceIssueCategory.CARD_PAYMENT_FAILED,
        },
        {
            label: "No Matched Payment",
            value: InvoiceIssueCategory.NO_MATCHED_PAYMENT,
        },
        {
            label: "Other",
            value: InvoiceIssueCategory.OTHER,
        },
    ];

    const onSubmit = (payload: IPendingResolutionPayload) => {
        const dataToSend = {
            invoice_ids: metaProps?.invoiceIds || [
                data?.data.invoice_id ||
                    invoiceID ||
                    metaProps?.invoiceId ||
                    "",
            ],
            status: "pending_resolution",
            issue_category: payload.issue_category,
            issue_notes: issueNotes || undefined,
        };

        patchInvoiceStatus.mutate(dataToSend, {
            onSuccess: (response) => {
                if (noteIdFromParams || metaProps?.noteId) {
                    queryClient.setQueryData<
                        IPaginatedApiResponse<IUpdatedInvoiceResponse>
                    >(
                        [
                            clientId,
                            `session-note`,
                            noteIdFromParams || metaProps?.noteId,
                        ],
                        (prev) => {
                            const prevRequired =
                                prev as IPaginatedApiResponse<IUpdatedInvoiceResponse>;
                            return {
                                ...prevRequired,
                                invoice_status: response.status,
                            };
                        }
                    );
                }

                queryClient.invalidateQueries({
                    queryKey: [
                        clientId,
                        `session-note`,
                        noteIdFromParams || metaProps?.noteId,
                    ],
                });

                if (location.pathname.includes("/billing-payments")) {
                    queryClient.invalidateQueries({
                        queryKey: ["matched"],
                    });
                }

                if (
                    location.pathname.includes("/clients") ||
                    location.pathname.includes("/billing-and-claims")
                ) {
                    queryClient.invalidateQueries({
                        queryKey: ["invoices"],
                    });
                }

                toast({
                    mode: "success",
                    message: `${buildSuccessMessage(
                        response.data.updated_invoices,
                        response.data.unupdated_invoices
                    )}`,
                });
                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Cannot mark bill as pending resolution at this time",
                });
            },
        });
    };

    const parsedNotes = JSON.parse(data?.data.issue_notes || "{}");

    let prevNotesStr = "";

    if (parsedNotes && Object.keys(parsedNotes).length > 0) {
        for (let i = 0; i < parsedNotes.blocks.length; i += 1) {
            if (i === 0) prevNotesStr += parsedNotes.blocks[i].text;
            else prevNotesStr += `\n${parsedNotes.blocks[i].text}`;
        }
        prevNotesStr += prevNotesStr.length ? "\n\n" : "";
    }

    return (
        <form id="pending-resolution" onSubmit={handleSubmit(onSubmit)}>
            <p className="text-sm mb-[30px] font-medium">
                Could you please provide more details regarding the issue with
                this client&apos;s bill? Select an issue category and enter
                additional information to help others understand the situation
            </p>
            <div className="fg">
                <Controller
                    name="issue_category"
                    control={control}
                    defaultValue={
                        issueCategoriesForSelect.find(
                            (issue) =>
                                issue.value === data?.data?.issue_category
                        )?.value
                    }
                    render={({ field }) => (
                        <Select
                            label="Issue category"
                            options={issueCategoriesForSelect}
                            onChange={(val) =>
                                field.onChange((val as Option).value)
                            }
                            hasError={!!errors.issue_category}
                            errorText={errors.issue_category?.message}
                            defaultValue={issueCategoriesForSelect.find(
                                (issue) =>
                                    issue.value === data?.data?.issue_category
                            )}
                        />
                    )}
                />
            </div>
            <div className="fg">
                <AddTextEditor
                    title="Add additional Information"
                    onEditorTextChange={setIssueNotes}
                    isRequiredFieldProvided
                    titleClass="!text-xs !text-black !pb-[8px]"
                    editorClass="!text-xs"
                    defaultValue={
                        (cfModalValue === "pr" &&
                            `${prevNotesStr} ${additionalInfo}`) ||
                        data?.data.issue_notes ||
                        ""
                    }
                    // defaultValue={data?.data.issue_notes || ""}
                />
            </div>
        </form>
    );
}

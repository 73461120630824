import { SvgProps } from "../../utils/types";

export default function ErrorIcon({ className }: SvgProps) {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle cx="12" cy="12.2454" r="12" fill="#F71735" />
            <g clipPath="url(#clip0_1331_9586)">
                <path
                    d="M14.2745 11.9727L17.686 8.56118C18.1047 8.14254 18.1047 7.46379 17.686 7.04482L16.9278 6.28663C16.5092 5.868 15.8305 5.868 15.4115 6.28663L12 9.69811L8.58852 6.28663C8.16989 5.868 7.49114 5.868 7.07216 6.28663L6.31398 7.04482C5.89534 7.46345 5.89534 8.1422 6.31398 8.56118L9.72545 11.9727L6.31398 15.3841C5.89534 15.8028 5.89534 16.4815 6.31398 16.9005L7.07216 17.6587C7.4908 18.0773 8.16989 18.0773 8.58852 17.6587L12 14.2472L15.4115 17.6587C15.8301 18.0773 16.5092 18.0773 16.9278 17.6587L17.686 16.9005C18.1047 16.4819 18.1047 15.8031 17.686 15.3841L14.2745 11.9727Z"
                    fill="#F8F8F6"
                />
            </g>
            <defs>
                <clipPath id="clip0_1331_9586">
                    <rect
                        width="12"
                        height="17.4545"
                        fill="white"
                        transform="translate(6 3.24536)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

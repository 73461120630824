import * as React from "react";
import cn from "classnames";
import { useIsMutating } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import {
    CreateNoteSteps,
    INewNoteAppoinmentDetails,
    NoteTypes,
} from "../../../../utils/types/notes";
import AppointmentDetails from "../../AppointmentDetails/AppointmentDetails";
import AddNoteMetadata from "../AddNoteMetadata/AddNoteMetadata";
import NoteCancellation from "../Cancellation/NoteCancellation";
import RecordOfDisclosure from "../RecordOfDisclosure/RecordOfDisclosure";
import SoapNote from "../SoapNote/SoapNote";
import Supervision from "../Supervision/Supervision";
import Termination from "../Termination/Termination";
import styles from "./CreateNoteFormsWrapper.module.scss";
import SafetyPlan from "../SafetyPlan/SafetyPlan";
import TreatmentReview from "../TreatmentReview/TreatmentReview";
import IndividualIntake from "../IndividualIntake/IndividualIntake";
import FamilyIntake from "../FamilyIntake/FamilyIntake";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import InfoIcon from "../../../Icons/InfoIcon";
import GeneralUpdateNote from "../GeneralUpdateNote/GeneralUpdateNote";
import SupervisorCheckInNote from "../SupervisorCheckInNote/SupervisorCheckInNote";
import { useAppSelector } from "../../../../hooks/useRedux";
import PauseNotification from "../PauseNotification/PauseNotification";

interface CreateNoteFormWrapperProps {
    currentCreateNoteStepInView?: CreateNoteSteps;
    currentNoteAppointmentDetails?: INewNoteAppoinmentDetails;
}

export default function CreateNoteFormWrapper({
    currentCreateNoteStepInView,
    currentNoteAppointmentDetails,
}: CreateNoteFormWrapperProps) {
    const [noteAppointmentDetails, setNoteAppointmentDetails] =
        React.useState<INewNoteAppoinmentDetails | null>(
            currentNoteAppointmentDetails || null
        );
    const [createNoteStepInView, setCreateNoteStepInView] =
        React.useState<CreateNoteSteps>(
            currentCreateNoteStepInView || "appointment-details"
        );

    const [searchParams] = useSearchParams();

    const timeLastSaved = searchParams.get("lastSaved") || "";

    const { isOpen } = useAppSelector((state) => state.nav);

    const currentAction = searchParams.get("action");

    const noteId = searchParams.get("note_id") as string;

    const isEditDraftNoteMutating = useIsMutating(["edit-note"]);

    // Function for setting createNoteInView state
    const handleCreateNoteStepInView = (step: CreateNoteSteps) => {
        setCreateNoteStepInView(() => step);
        window.scrollTo(0, 0);
    };

    // Function for setting noteAppointmentDetails state
    const handleSetAppointmentDetailsInView = (
        appointmentDetails: INewNoteAppoinmentDetails | null
    ) => {
        setNoteAppointmentDetails(() => appointmentDetails);
    };

    const noteDetails = useFetchSessionNote(
        noteAppointmentDetails?.client_id as string,
        noteId,
        Boolean(noteId && noteAppointmentDetails?.client_id)
    );

    // Decide createnoteform to display
    const handleCreateNoteFormToDisplay = (
        noteAppointmentDetailsForForms: INewNoteAppoinmentDetails
    ) => {
        switch (
            currentNoteAppointmentDetails?.note_type ||
            noteAppointmentDetails?.note_type
        ) {
            case NoteTypes.CANCELLATION_NOTE:
                return (
                    <NoteCancellation
                        currentAction={currentAction}
                        appointmentDetails={noteAppointmentDetailsForForms}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.RECORD_OF_DISCLOSURE:
                return (
                    <RecordOfDisclosure
                        currentAction={currentAction}
                        appointmentDetails={noteAppointmentDetailsForForms}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.SUPERVISION_NOTE:
                return (
                    <Supervision
                        currentAction={currentAction}
                        appointmentDetails={noteAppointmentDetailsForForms}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.FAMILY_SOAP_NOTE:
                return (
                    <SoapNote
                        appointmentDetails={noteAppointmentDetailsForForms}
                        currentAction={currentAction}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.INDIVIDUAL_SOAP_NOTE:
                return (
                    <SoapNote
                        appointmentDetails={noteAppointmentDetailsForForms}
                        currentAction={currentAction}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.TERMINATION_NOTE:
                return (
                    <Termination
                        appointmentDetails={noteAppointmentDetailsForForms}
                        currentAction={currentAction}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.SAFETY_PLAN:
                return (
                    <SafetyPlan
                        appointmentDetails={noteAppointmentDetailsForForms}
                        currentAction={currentAction}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.INDIVIDUAL_TREATMENT_REVIEW:
                return (
                    <TreatmentReview
                        appointmentDetails={noteAppointmentDetailsForForms}
                        currentAction={currentAction}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.FAMILY_TREATMENT_REVIEW:
                return (
                    <TreatmentReview
                        appointmentDetails={noteAppointmentDetailsForForms}
                        currentAction={currentAction}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.INDIVIDUAL_INTAKE_NOTE:
                return (
                    <IndividualIntake
                        appointmentDetails={noteAppointmentDetailsForForms}
                        currentAction={currentAction}
                        formValues={noteDetails.data?.data.note_content}
                        isMinor={false}
                    />
                );
            case NoteTypes.MINOR_INTAKE_NOTE:
                return (
                    <IndividualIntake
                        appointmentDetails={noteAppointmentDetailsForForms}
                        currentAction={currentAction}
                        formValues={noteDetails.data?.data.note_content}
                        isMinor
                    />
                );
            case NoteTypes.FAMILY_INTAKE_NOTE:
                return (
                    <FamilyIntake
                        appointmentDetails={noteAppointmentDetailsForForms}
                        currentAction={currentAction}
                        formValues={noteDetails.data?.data.note_content}
                    />
                );
            case NoteTypes.GENERAL_UPDATE_NOTE:
                return (
                    <GeneralUpdateNote
                        appointmentDetails={noteAppointmentDetailsForForms}
                        formValues={noteDetails.data?.data.note_content}
                        currentAction={currentAction}
                    />
                );
            case NoteTypes.SUPERVISOR_CHECK_IN:
                return (
                    <SupervisorCheckInNote
                        appointmentDetails={noteAppointmentDetailsForForms}
                        formValues={noteDetails.data?.data.note_content}
                        currentAction={currentAction}
                    />
                );
            case NoteTypes.PAUSE_NOTIFICATION_NOTE:
                return (
                    <PauseNotification
                        appointmentDetails={noteAppointmentDetailsForForms}
                        formValues={noteDetails.data?.data.note_content}
                        currentAction={currentAction}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className={styles.wrapper}>
            {isEditDraftNoteMutating > 0 || timeLastSaved ? (
                <div
                    className={cn(styles.autosave, {
                        [styles.autosave_full]: !isOpen,
                    })}
                >
                    {isEditDraftNoteMutating > 0 ? (
                        <span className={styles.saving}>Autosaving...</span>
                    ) : (
                        <span className={styles.saved}>
                            <InfoIcon /> Autosaved at {timeLastSaved}
                        </span>
                    )}
                </div>
            ) : null}
            {createNoteStepInView === "appointment-details" && (
                <AddNoteMetadata
                    noteAppointmentDetails={noteAppointmentDetails}
                    handleCreateNoteStepInView={handleCreateNoteStepInView}
                    handleSetAppointmentDetailsInView={
                        handleSetAppointmentDetailsInView
                    }
                />
            )}
            {createNoteStepInView === "add-note" && noteAppointmentDetails && (
                <div className={styles.wrapform}>
                    <div className={styles.appointment}>
                        <AppointmentDetails
                            details={noteAppointmentDetails}
                            noteType={noteAppointmentDetails.note_type}
                            showEditButton
                            editButtonClicked={() =>
                                handleCreateNoteStepInView(
                                    "appointment-details"
                                )
                            }
                        />
                    </div>

                    <div className={styles.forms}>
                        {noteAppointmentDetails
                            ? handleCreateNoteFormToDisplay(
                                  noteAppointmentDetails
                              )
                            : null}
                    </div>
                </div>
            )}
        </div>
    );
}

import * as React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/auth/AuthContext";
import { auth } from "../firebase";
import { useAppDispatch } from "./useRedux";
import { handleChangePractice } from "../redux/features/practice/practiceSlice";

export default function useAuthContext() {
    const user = React.useContext(AuthContext);

    const dispatch = useAppDispatch();

    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const logOutUser = async () => {
        await auth.signOut();
        queryClient.clear();
        localStorage.removeItem("practice");
        localStorage.removeItem("from");
        dispatch(
            handleChangePractice({ practice: null, updateLocation: false })
        );
        navigate("/login", { replace: true });
    };

    return { user, logOutUser };
}

import { Tabs } from "@jhool-io/fe-components";
import { useSearchParams } from "react-router-dom";
import ProviderCaseload from "../ProviderCaseload/ProviderCaseload";
import SuperviseeCaseload from "../SuperviseeCaseload/SuperviseeCaseload";

interface CaseloadProps {
    /** Determines whether clinician is a supervisor or not */
    isSupervisor?: boolean;
}

export default function CaseloadWrapper({ isSupervisor }: CaseloadProps) {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleSetTabInUrl = (tab: string) => {
        searchParams.set("tab", tab);
        setSearchParams(searchParams);
    };

    const tabInUrl = searchParams.get("tab");

    const tabItems = [
        {
            key: "provider-caseload",
            label: "Provider Caseload",
            children: <ProviderCaseload />,
            onClick: () => handleSetTabInUrl("provider-caseload"),
        },
        {
            key: "supervisee-caseload",
            label: "Supervisees",
            children: <SuperviseeCaseload />,
            onClick: () => handleSetTabInUrl("supervisee-caseload"),
        },
    ];

    return (
        <>
            {isSupervisor && (
                <Tabs
                    items={tabItems}
                    className="!w-[100%] !items-end"
                    defaultActiveKey={tabInUrl || "provider-caseload"}
                />
            )}
            {!isSupervisor && <ProviderCaseload />}
        </>
    );
}

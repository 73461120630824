import { Button, Card, Tooltip } from "@jhool-io/fe-components";
import InfoCircleIcon from "../../../../components/Icons/InfoCircle";

interface BillingSummayCardProps {
    /** Card background color */
    bgColor: string;
    /** Card title */
    title: string;
    /** Tooltip information text */
    tooltipDescription: string;
    /** Card description */
    description: string;
    /** Count of items in count */
    count: string | number;
    /** Small count text */
    smallCount?: string | number;
    /** Card list */
    list?: React.ReactElement;
    /** Should card list be hidden */
    showList?: boolean;
    /** Should card be hidden */
    hide?: boolean;
}

export default function BillingSummaryCard({
    bgColor,
    title,
    tooltipDescription,
    description,
    count,
    smallCount,
    list,
    hide,
    showList = true,
}: BillingSummayCardProps) {
    if (hide) return null;

    return (
        <Card classNames="p-0">
            <div
                className="px-12 md:px-16 py-16 md:py-24 flex flex-col max-w-full overflow-y-auto text-nowrap h-full overflow-x-auto"
                style={{ backgroundColor: bgColor }}
            >
                <div className="flex items-center gap-x-8 mb-12 md:mb-16">
                    <h3 className="text-sm md:text-base font-bold">{title}</h3>
                    <Tooltip content={tooltipDescription}>
                        <Button size="auto" variant="normal">
                            <InfoCircleIcon stroke="#0B132B" fill="#0B132B" />
                        </Button>
                    </Tooltip>
                </div>
                <span className="text-graydark font-medium mb-8 text-xs">
                    {description}
                </span>
                <span className="text-[28px] md:text-xlg font-extrabold">
                    {count}
                    {smallCount && (
                        <span className="text-xs font-medium whitespace-nowrap">{` / ${smallCount}`}</span>
                    )}
                </span>
                {showList && <div className="mt-12 md:mt-24">{list}</div>}
            </div>
        </Card>
    );
}

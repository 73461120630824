import * as React from "react";
import NoteContentItem from "../NoteContentItem/NoteContentItem";
import { IAddendumDetails } from "../../../../utils/types/notes";

interface ViewGeneralNotesProps {
    noteContent: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    };
    fieldAddendums: {
        [key: string]: IAddendumDetails[];
    } | null;
}
export default function ViewGeneralNote({
    noteContent,
    fieldAddendums,
}: ViewGeneralNotesProps) {
    return (
        <div data-testid="general-update">
            <NoteContentItem
                addendums={fieldAddendums?.update_note_content}
                headerText="Update"
                fieldId="general_update"
                content={noteContent?.update_note_content || "--"}
            />
        </div>
    );
}

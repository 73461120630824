import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    IApiResponse,
    IPaginatedApiResponse,
} from "../../../utils/types/api-response";
import {
    IProvider,
    IProviderCaseload,
    IProviderCaseloadFilters,
    IProvidersFilterTypes,
    ISuperviseeCaseload,
} from "../types/providers.types";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    fetchProviderCaseload,
    fetchProviders,
    fetchSuperviseesCaseload,
} from "../services/providers.services";
import { useDoesUserHavePermission } from "../../../hooks/permissions";
import { UserPermisions } from "../../../utils/types/user";

// Fetch provider's caseload
export function useFetchProvidersCaseload(params: IProviderCaseloadFilters) {
    return useQuery<
        IPaginatedApiResponse<IProviderCaseload[]>,
        AxiosError<IApiResponse<IProviderCaseload[]>>
    >({
        queryKey: [
            params.providerId,
            `caseload`,
            handleRemoveEmptyParamFromQueryParams({
                ...params,
            }),
        ],
        queryFn: () => fetchProviderCaseload(params),
        enabled: params.enabled,
    });
}

// Fetch supervisees caseload
export function useFetchSuperviseesCaseload(
    providerId: string,
    search_string?: string
) {
    return useQuery<
        IPaginatedApiResponse<ISuperviseeCaseload[]>,
        AxiosError<IApiResponse<ISuperviseeCaseload[]>>
    >({
        queryKey: [providerId, `supervisees-caseload`, search_string || ""],
        queryFn: () => fetchSuperviseesCaseload(providerId, search_string),
    });
}

export function useFetchProviders(
    filters: IProvidersFilterTypes,
    enabled?: boolean
) {
    const canViewProvidersList = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_PROVIDER_READ,
        UserPermisions.CLIENT_INFO_PROVIDER_WRITE,
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
        UserPermisions.PROVIDER_INFO_READ,
    ]);

    const isEnabled =
        enabled !== undefined
            ? canViewProvidersList && enabled
            : canViewProvidersList;

    return useQuery<
        IPaginatedApiResponse<IProvider[]>,
        AxiosError<IApiResponse<IProvider[]>>
    >({
        queryKey: ["providers", handleRemoveEmptyParamFromQueryParams(filters)],
        queryFn: () => fetchProviders(filters),
        enabled: isEnabled,
    });
}

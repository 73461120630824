import { SvgProps } from "../../utils/types";

export default function EditIcon({ fill, className }: SvgProps) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g clipPath="url(#clip0_4509_564)">
                <path
                    opacity="0.4"
                    d="M15.6833 1.88101L14.3278 0.356013C14.1242 0.127857 13.8485 -0.000244141 13.5611 -0.000244141C13.2737 -0.000244141 12.9981 0.127857 12.7944 0.356013L11.8111 1.46226C11.7604 1.51954 11.732 1.59707 11.732 1.67789C11.732 1.75871 11.7604 1.83624 11.8111 1.89351L14.3167 4.71226C14.3676 4.76928 14.4365 4.80129 14.5083 4.80129C14.5802 4.80129 14.6491 4.76928 14.7 4.71226L15.6833 3.60601C15.8861 3.37695 16 3.06682 16 2.74351C16 2.42021 15.8861 2.11008 15.6833 1.88101ZM11.875 9.29976L10.7639 10.5498C10.7022 10.6208 10.6673 10.716 10.6667 10.8154V13.9966H1.77778V3.99664H8.16111C8.24942 3.99588 8.33404 3.95668 8.39722 3.88726L9.50833 2.63726C9.55512 2.58481 9.587 2.51791 9.59993 2.44505C9.61285 2.37218 9.60625 2.29664 9.58096 2.22801C9.55566 2.15937 9.51281 2.10074 9.45784 2.05954C9.40287 2.01835 9.33827 1.99646 9.27222 1.99664H1.33333C0.979711 1.99664 0.640573 2.15467 0.390524 2.43598C0.140476 2.71728 0 3.09881 0 3.49664L0 14.4966C0 14.8945 0.140476 15.276 0.390524 15.5573C0.640573 15.8386 0.979711 15.9966 1.33333 15.9966H11.1111C11.4647 15.9966 11.8039 15.8386 12.0539 15.5573C12.304 15.276 12.4444 14.8945 12.4444 14.4966V9.56539C12.4446 9.49109 12.4251 9.4184 12.3885 9.35657C12.3519 9.29473 12.2998 9.24652 12.2388 9.21806C12.1778 9.1896 12.1106 9.18218 12.0459 9.19672C11.9811 9.21127 11.9216 9.24713 11.875 9.29976Z"
                    fill={fill || "#576A81"}
                />
                <path
                    d="M13.6884 5.41559C13.7391 5.47287 13.7675 5.5504 13.7675 5.63122C13.7675 5.71204 13.7391 5.78957 13.6884 5.84684L7.62174 12.6718L5.04396 12.9937C4.96333 13.0039 4.88169 12.9935 4.80511 12.9634C4.72853 12.9332 4.65898 12.884 4.6016 12.8195C4.54423 12.755 4.50052 12.6767 4.47371 12.5905C4.4469 12.5044 4.43768 12.4126 4.44674 12.3218L4.73285 9.42184L10.7995 2.59684C10.8504 2.53982 10.9193 2.50781 10.9912 2.50781C11.063 2.50781 11.1319 2.53982 11.1828 2.59684L13.6884 5.41559Z"
                    fill={fill || "#576A81"}
                />
            </g>
            <defs>
                <clipPath id="clip0_4509_564">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

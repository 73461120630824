import * as React from "react";
import { AnnouncementContext } from "../context/announcement/AnnouncementContext";

export default function useAnnouncement() {
    const alertBannerContext = React.useContext(AnnouncementContext);

    const announcements = alertBannerContext?.announcements;
    const clearedBannerAt = alertBannerContext?.clearedBannerAt;
    const handleClearedAtValue = alertBannerContext?.handleClearedAtValue;

    return {
        announcements,
        clearedBannerAt,
        handleClearedAtValue,
    };
}

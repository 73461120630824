import {
    Button,
    Card,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Input,
    Select,
    SelectElement,
    Tooltip,
} from "@jhool-io/fe-components";
import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useIsMutating } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import {
    cn,
    convertArrayToString,
    isSmallScreen,
    removeEnumUnderscore,
    showMoneyInAppFormat,
    truncateString,
} from "../../../../utils/helpers";
import {
    CalculateFeesCustomerStatus,
    CalculateFeesInsuranceProviders,
    CalculateFeesYearList,
    ICalculateClientFee,
    ICalculateFeeResponse,
    ISubmitCalculateClientFee,
} from "../../types/tools.types";
import useToast from "../../../../hooks/useToast";
import { useCalculateClientFees } from "../../hooks/mutations/tools.mutations";
import { useFetchClientList } from "../../../../hooks/queries/client";
import { useDebounce } from "../../../../hooks/helpers";
import usePractice from "../../../../hooks/usePractice";
import {
    useFetchClientFees,
    useFetchClientInsuranceHistory,
} from "../../../clients/hooks/clients.queries";
import { copyToClipboard } from "../../../../utils/helpers/copy";
import CopyIcon from "../../../../components/Icons/Copy";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";

type Options = {
    label: string;
    value: string;
};

// Select options for customer status
const customerStatusForSelectOptions = Object.values(
    CalculateFeesCustomerStatus
).map((status) => ({
    label: status,
    value: status,
}));

// Select options for insurance providers
const insuranceProvidersSelectOptions = Object.values(
    CalculateFeesInsuranceProviders
)
    .sort((a, b) => a.localeCompare(b))
    .map((provider) => ({
        label: provider,
        value: provider,
    }));

const yearSelectOptions = Object.values(CalculateFeesYearList).map((year) => ({
    label: year,
    value: year,
}));

// finding insurance providers that match
const findMatchingOption = (
    selectedProvider: string,
    options: Options[]
): string | undefined => {
    const lowerCasedSelectedProvider = selectedProvider?.toLowerCase();

    // Try to find an exact match first
    const exactMatch = options?.find(
        (option) => option?.label?.toLowerCase() === lowerCasedSelectedProvider
    );

    if (exactMatch) return exactMatch.label;

    // Go back to partial word match
    const foundOption = options?.find((option) =>
        lowerCasedSelectedProvider
            ?.split(" ")
            ?.some((word) => option?.label?.toLowerCase()?.includes(word))
    );

    return foundOption?.label;
};

const schema = yup.object({
    customer_status: yup.string(),
    insurance: yup.string(),
    deductible: yup.string(),
    deductible_met: yup.string(),
    inn_coinsurance: yup.string(),
    inn_copay: yup.string(),
    oon_coinsurance: yup.string(),
    oon_copay: yup.string(),
    ucr: yup.string(),
});

export default function FeesCalculator() {
    const [feeResponse, setFeeResponse] = React.useState<
        ICalculateFeeResponse[]
    >([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [showOptions, setShowOptions] = React.useState(false);
    const [selectedClient, setSelectedClient] = React.useState<Options | null>(
        null
    );
    const [selectedYear, setSelectedYear] = React.useState<string>(
        new Date().getFullYear().toString()
    );
    const [feeCopied, setFeeCopied] = React.useState<
        "doctoral" | "masters" | null
    >(null);
    const [selectedInsurance, setSelectedInsurance] = React.useState(false);
    const [selectedInsuranceProvider, setSelectedInsuranceProvider] =
        React.useState("");

    const selectRef = React.useRef<SelectElement | null>(null);
    const isCalculateMutating = useIsMutating(["calculate-fee"]);

    // Toast for success and error states
    const { toast } = useToast();

    const debouncedValue = useDebounce(searchValue, 500);

    const { practice } = usePractice();

    const { data, isLoading } = useFetchClientList(
        {
            page: 1,
            search_string: debouncedValue.toLowerCase(),
            provider_id: practice?.provider_id || "",
            sort_attribute: "client_name",
            sort_order: "ASC",
        },
        showOptions && searchValue.length > 0
    );

    // Fetch client insurance history
    const { data: insuranceProviders } = useFetchClientInsuranceHistory(
        selectedClient?.value || "",
        1,
        50,
        Boolean(selectedClient)
    );

    const { data: clientFees } = useFetchClientFees(
        selectedClient?.value as string,
        3,
        Boolean(selectedClient)
    );
    // Hook for adding client fee
    const { mutate } = useCalculateClientFees();

    const ClientNamesForSelect = data?.data.map((client) => ({
        label: `${client.first_name} ${client.last_name}`,
        value: client.client_id,
    }));

    // Get filtered options for clients list
    const filteredClients = ClientNamesForSelect?.filter((option) =>
        option.label.toLowerCase().includes(searchValue.toLowerCase())
    );

    const userCurrentProvider = insuranceProviders?.data.filter(
        (item) => item.status === "active"
    );

    const {
        register,
        handleSubmit,
        watch,
        control,
        setValue,
        formState: { errors },
    } = useForm<ICalculateClientFee>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const currentCustomerStatus = watch("customer_status");
    const currentPractice = practice?.practice_name as string;
    const currentFee = clientFees?.data?.[0];

    const deductibleToUse =
        currentPractice === "Park-Hill-Staging" ||
        currentPractice === "Park Hill"
            ? currentFee?.innded
            : currentFee?.oonded || "";

    // check to know if calculate fees button should be disabled
    const shouldDisableButton = () => {
        const deductible = watch("deductible");
        const deductibleMet = watch("deductible_met");
        const innCoinsurance = watch("inn_coinsurance");
        const innCopay = watch("inn_copay");
        const insurance = watch("insurance");
        const oonCoinsurance = watch("oon_coinsurance");
        const ucr = watch("ucr");

        // Check if all fields are empty or if insurance is empty
        const allFieldsEmpty =
            !deductible &&
            !deductibleMet &&
            !innCoinsurance &&
            !innCopay &&
            !insurance &&
            !oonCoinsurance &&
            !ucr;
        const insuranceEmpty = !selectedInsuranceProvider;

        // Return true if all fields are empty or if only insurance is empty
        return allFieldsEmpty || insuranceEmpty;
    };

    const getInsuranceTextToDisplay = findMatchingOption(
        selectedInsuranceProvider,
        insuranceProvidersSelectOptions
    );

    React.useEffect(() => {
        if (!selectedInsurance) {
            setSelectedInsuranceProvider(
                userCurrentProvider?.[0]?.name?.toUpperCase() as string
            );
        }
    }, [selectedClient, selectedInsurance, userCurrentProvider]);

    const clearFields = () => {
        setValue("customer_status", CalculateFeesCustomerStatus.NEW);
        setValue("deductible", "");
        setValue("deductible_met", "");
        setValue("inn_coinsurance", "");
        setValue("inn_copay", "");
        setValue("oon_coinsurance", "");
        setValue("oon_copay", "");
        setValue("ucr", "");
        setSelectedInsuranceProvider("");
        setSelectedYear(new Date().getFullYear().toString());
        setSelectedClient(null);
        setFeeResponse([]);
    };

    // Update form values when currentFee is available
    React.useEffect(() => {
        if (selectedClient && currentFee) {
            setValue("deductible", deductibleToUse || "");
            setValue("inn_coinsurance", currentFee?.inncoins || "");
            setValue("inn_copay", currentFee?.innco || "");
            setValue("oon_coinsurance", currentFee?.ooncoins || "");
            setValue("oon_copay", currentFee?.oonco || "");
            setValue("ucr", currentFee?.ucr || "");
        }
    }, [currentFee, setValue, selectedClient, deductibleToUse]);

    // Function for copying FEES
    const handleFeeCopied = (feeType: "masters" | "doctoral") => {
        if (feeType === "masters") {
            const feeToCopy =
                feeResponse?.[0]?.masters ?? showMoneyInAppFormat(0);

            const copiedText = convertArrayToString([`${feeToCopy}`]);
            copyToClipboard(copiedText);
            setFeeCopied("masters");
        } else {
            const feeToCopy =
                feeResponse?.[0]?.doctoral ?? showMoneyInAppFormat(0);
            const copiedText = convertArrayToString([`${feeToCopy}`]);
            copyToClipboard(copiedText);
            setFeeCopied("doctoral");
        }

        setTimeout(() => {
            setFeeCopied(null);
        }, 2000);
    };

    const onSubmit = (payload: ICalculateClientFee) => {
        // making sure the correct matched insurance provider is sent to the backend
        const matchedOption = selectedInsuranceProvider
            ? findMatchingOption(
                  selectedInsuranceProvider,
                  insuranceProvidersSelectOptions
              )
            : null;

        const dataToSend: ISubmitCalculateClientFee = {
            year: selectedYear,
            fee_params: [
                {
                    ...payload,
                    customer_status: payload?.customer_status?.toLowerCase(),
                    insurance: matchedOption || "",
                },
            ],
        };

        mutate(dataToSend, {
            onSuccess: (res) => {
                toast({
                    mode: "success",
                    message: res.message || "Fees calculated successfully",
                });
                setFeeResponse(res.data);
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not calculate fees at this time",
                });
            },
        });
    };

    return (
        <div className="flex justify-center items-center">
            <Card classNames="p-0">
                <div className="flex justify-between px-32 py-20 items-center border-b border-strokedark">
                    <p className="text-lg font-semibold">ONN Fees Calculator</p>
                    <div className="flex gap-x-12 items-center">
                        <Dropdown>
                            <DropdownTrigger asChild>
                                <FilterButton
                                    text={
                                        truncateString(selectedYear, 4) ||
                                        "Year"
                                    }
                                />
                            </DropdownTrigger>
                            <DropdownContent
                                align={isSmallScreen() ? "start" : "end"}
                                width={isSmallScreen() ? "250px" : "auto"}
                                maxHeight={216}
                                className="overflow-y-auto"
                            >
                                {yearSelectOptions.map((year) => (
                                    <DropdownItem
                                        className={cn(
                                            "uppercase text-xs font-medium flex items-center gap-x-8",
                                            {
                                                "text-primary":
                                                    year.label === selectedYear,
                                            }
                                        )}
                                        onClick={() => {
                                            setSelectedYear(year.label);
                                        }}
                                        key={year.label}
                                    >
                                        {year.label}
                                        {selectedYear === year.label ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                ))}
                            </DropdownContent>
                        </Dropdown>

                        <Dropdown>
                            <DropdownTrigger asChild>
                                <FilterButton
                                    text={
                                        getInsuranceTextToDisplay
                                            ? truncateString(
                                                  removeEnumUnderscore(
                                                      getInsuranceTextToDisplay
                                                  ),
                                                  5
                                              )
                                            : "Insurance"
                                    }
                                />
                            </DropdownTrigger>
                            <DropdownContent
                                align={isSmallScreen() ? "start" : "end"}
                                width={isSmallScreen() ? "250px" : "auto"}
                                maxHeight={216}
                                className="overflow-y-auto"
                            >
                                {insuranceProvidersSelectOptions
                                    .sort((a, b) =>
                                        a.label.localeCompare(b.label)
                                    )
                                    .map((provider) => (
                                        <DropdownItem
                                            className={cn(
                                                "uppercase text-xs font-medium flex items-center gap-x-8",
                                                {
                                                    "text-primary":
                                                        provider.label ===
                                                        selectedInsuranceProvider,
                                                }
                                            )}
                                            onClick={() => {
                                                setSelectedInsuranceProvider(
                                                    provider.label
                                                );
                                                setSelectedInsurance(true);
                                            }}
                                            key={provider.label}
                                        >
                                            {removeEnumUnderscore(
                                                provider.label
                                            ).toUpperCase()}
                                            {selectedInsuranceProvider ===
                                            provider.label ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                    ))}
                            </DropdownContent>
                        </Dropdown>

                        <Button variant="secondary" onClick={clearFields}>
                            Clear
                        </Button>
                        <Link
                            to="/tools/fees-calculator"
                            aria-label="Open in new page"
                            target="_blank"
                        >
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    opacity="0.4"
                                    d="M15.375 16.5L2.625 16.5"
                                    stroke="#292D32"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M14.25 2.625L3.75 13.125"
                                    stroke="#292D32"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M14.25 10.3275L14.25 2.625L6.5475 2.625"
                                    stroke="#292D32"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </Link>
                    </div>
                </div>
                <div className="p-16">
                    <Card classNames="p-16 bg-card-bg">
                        <form
                            id="calculate-fees"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div>
                                <div className="fg fg-space-between two flex">
                                    <Controller
                                        name="customer_status"
                                        control={control}
                                        defaultValue={
                                            CalculateFeesCustomerStatus.NEW
                                        }
                                        render={({ field }) => (
                                            <Select
                                                label="Customer Status"
                                                options={
                                                    customerStatusForSelectOptions
                                                }
                                                value={customerStatusForSelectOptions.find(
                                                    (option) =>
                                                        option.value ===
                                                        field.value
                                                )}
                                                onChange={(val) =>
                                                    field.onChange(
                                                        (val as Options).value
                                                    )
                                                }
                                                hasError={
                                                    !!errors.customer_status
                                                }
                                                errorText={
                                                    errors.customer_status
                                                        ?.message
                                                }
                                            />
                                        )}
                                    />
                                    {currentCustomerStatus === "Existing" ? (
                                        <Select
                                            ref={selectRef}
                                            isSearchable
                                            filterOption={() => true}
                                            placeholder="Enter Client name"
                                            value={undefined}
                                            aria-label="Client name"
                                            label="Search by Client name"
                                            options={filteredClients}
                                            onChange={(val) => {
                                                setSelectedClient(
                                                    val as Options
                                                );
                                                setSelectedInsurance(false);
                                            }}
                                            onInputChange={(value) => {
                                                setShowOptions(true);
                                                setSearchValue(value);
                                            }}
                                            onBlur={() => {
                                                setShowOptions(false);
                                            }}
                                            defaultValue={undefined}
                                            openMenuOnClick={false}
                                            menuIsOpen={searchValue.length > 0}
                                            noOptionsMessage={({
                                                inputValue,
                                            }) =>
                                                isLoading && inputValue
                                                    ? "Fetching clients..."
                                                    : "No options"
                                            }
                                        />
                                    ) : null}
                                </div>
                                <div className="fg fg-space-between two flex">
                                    <Input
                                        {...register("deductible")}
                                        label="Deductible"
                                        placeholder={showMoneyInAppFormat(0)}
                                        hasError={!!errors.deductible}
                                        errorText={errors.deductible?.message}
                                    />
                                    <Input
                                        {...register("deductible_met")}
                                        label="Deductible met"
                                        placeholder={showMoneyInAppFormat(0)}
                                        hasError={!!errors.deductible_met}
                                        errorText={
                                            errors.deductible_met?.message
                                        }
                                    />
                                </div>
                                <div className="fg fg-space-between two flex">
                                    <Input
                                        {...register("inn_coinsurance")}
                                        label="INNCOINS"
                                        placeholder={showMoneyInAppFormat(0)}
                                        hasError={!!errors.inn_coinsurance}
                                        errorText={
                                            errors.inn_coinsurance?.message
                                        }
                                    />
                                    <Input
                                        {...register("inn_copay")}
                                        label="INNCOPAY"
                                        placeholder={showMoneyInAppFormat(0)}
                                        hasError={!!errors.inn_copay}
                                        errorText={errors.inn_copay?.message}
                                    />
                                </div>
                                <div className="fg fg-space-between two flex">
                                    <Input
                                        {...register("oon_coinsurance")}
                                        label="OONCOINS"
                                        placeholder={showMoneyInAppFormat(0)}
                                        hasError={!!errors.oon_coinsurance}
                                        errorText={
                                            errors.oon_coinsurance?.message
                                        }
                                    />
                                    <Input
                                        {...register("oon_copay")}
                                        label="ONNCOPAY"
                                        placeholder={showMoneyInAppFormat(0)}
                                        hasError={!!errors.oon_copay}
                                        errorText={errors.oon_copay?.message}
                                    />
                                </div>
                                <div className="fg fg-space-between two flex">
                                    <Input
                                        {...register("ucr")}
                                        label="UCR"
                                        placeholder={showMoneyInAppFormat(0)}
                                        hasError={!!errors.ucr}
                                        errorText={errors.ucr?.message}
                                    />
                                </div>

                                <div className="mx-16">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="499"
                                        height="4"
                                        viewBox="0 0 499 4"
                                        fill="none"
                                    >
                                        <path
                                            d="M0 2L499 1.99996"
                                            stroke="#E7E7E7"
                                            strokeWidth="4"
                                            strokeDasharray="2 2"
                                        />
                                    </svg>
                                </div>
                                <div className="flex mt-16 justify-between items-center">
                                    <p className="text-gray text-base font-semibold">
                                        Masters Fee
                                    </p>
                                    <div className="flex gap-x-12">
                                        <div className="text-base font-semibold ml">
                                            {feeResponse?.[0]?.masters
                                                ? feeResponse?.[0]?.masters
                                                : showMoneyInAppFormat(0)}
                                        </div>
                                        <div>
                                            <Tooltip
                                                content={
                                                    feeCopied === "masters"
                                                        ? "Masters fee copied!"
                                                        : "Copy Masters fee result"
                                                }
                                            >
                                                <Button
                                                    onClick={() =>
                                                        handleFeeCopied(
                                                            "masters"
                                                        )
                                                    }
                                                    aria-label="copyInnBtn"
                                                    variant="normal"
                                                    size="auto"
                                                    className="self-center"
                                                >
                                                    <CopyIcon />
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-between mt-16 items-center">
                                    <p className="text-gray text-base font-semibold">
                                        Doctoral Fee
                                    </p>
                                    <div className="flex gap-x-12">
                                        <p className="text-base font-semibold">
                                            {feeResponse?.[0]?.doctoral
                                                ? feeResponse?.[0]?.doctoral
                                                : showMoneyInAppFormat(0)}
                                        </p>
                                        <Tooltip
                                            content={
                                                feeCopied === "doctoral"
                                                    ? "Doctoral fee copied!"
                                                    : "Copy Doctoral fee result"
                                            }
                                        >
                                            <Button
                                                onClick={() =>
                                                    handleFeeCopied("doctoral")
                                                }
                                                aria-label="copyInnBtn"
                                                variant="normal"
                                                size="auto"
                                                className="self-center"
                                            >
                                                <CopyIcon />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-end mt-24">
                                <Button
                                    className="disabled:bg-primary"
                                    disabled={
                                        isCalculateMutating > 0 ||
                                        shouldDisableButton()
                                    }
                                    type="submit"
                                >
                                    Calculate fees
                                </Button>
                            </div>
                        </form>
                    </Card>
                </div>
            </Card>
        </div>
    );
}

import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IApiResponse } from "../../../../utils/types/api-response";
import { sendStatementOfAccount } from "../../services/reports.services";
import {
    IStatementOfAccountFilters,
    IStatementOfAccountPreviewResponse,
    ISendStatementOfAccount,
} from "../../types/reports.types";

// Hook to send clients statement of account
export function useSendStatementOfAccount(filters: IStatementOfAccountFilters) {
    return useMutation<
        IApiResponse<IStatementOfAccountPreviewResponse>,
        AxiosError<IApiResponse<IStatementOfAccountPreviewResponse>>,
        ISendStatementOfAccount
    >({
        mutationKey: ["send-statement"],
        mutationFn: (payload: ISendStatementOfAccount) =>
            sendStatementOfAccount(payload, filters),
    });
}

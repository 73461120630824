import * as React from "react";
import {
    useReactTable,
    getCoreRowModel,
    flexRender,
    ColumnDef,
    RowSelectionState,
    OnChangeFn,
    Row,
    VisibilityState,
    getExpandedRowModel,
    ExpandedState,
} from "@tanstack/react-table";
import cn from "classnames";
import { useSearchParams } from "react-router-dom";
import { AppSortOrder, ISortTable } from "../../utils/types";
import Card from "../Card/Card";
import styles from "./Table.module.scss";

export interface ExtendedColumn<TData> {
    id: string;
    header: string;
    cell: ({ row }: { row: Row<TData> }) => JSX.Element | null;
}

interface SortConfigProps {
    key: string;
    direction: string;
}

interface TableProps<TData> {
    /** Table data */
    data: TData[];
    /** Defines table columns and their values */
    columns: ColumnDef<TData>[];
    extendedColumns?: ExtendedColumn<TData>[];
    hasExtendedColumns?: (row: Row<TData>) => boolean;
    /** Table title */
    title?: string;
    /** Custimizable table header */
    tableHeader?: React.ReactNode;
    /** Additional classname for styling */
    className?: string;
    /** Table card className */
    cardClass?: string;
    /** Row selected state */
    rowSelection?: RowSelectionState;
    // row expanded state returns a boolean object containing the index of the rows expanded.
    expandedRows?: ExpandedState;
    /** Set selected rows state */
    setRowSelection?: OnChangeFn<RowSelectionState> | undefined;
    /** Set expanded rows state */
    setExpandedRows?: OnChangeFn<ExpandedState> | undefined;
    /** Click handler for each table row */
    handleRowClick?: (row: Row<TData>) => void;
    /**  Function that handles adding styling to each table row */
    handleRowStyle?: (row: Row<TData>) => Record<string, string>;
    /** Determines visibility of a column */
    isColumnVisible?: VisibilityState;
    setGetSelectedData?: (row: Row<TData>[]) => void;
    // this is a component that contains the columns to be rendered when a row is expanded
    renderRowSubComponent?: (props: { row: Row<TData> }) => JSX.Element;
    /** Is the table header fixed */
    isHeaderFixed?: boolean;
    setSortConfig?: (value: React.SetStateAction<SortConfigProps>) => void;
    sortConfig?: { key: string; direction: string };
    sortableColumns?: string[];
    sortInstance?: string;
    /** Should a row be selectable */
    enableRowSelection?: boolean | ((row: Row<TData>) => boolean);
}

export default function Table<TData extends ISortTable>({
    data,
    columns,
    extendedColumns = [],
    tableHeader,
    title,
    className,
    cardClass,
    rowSelection,
    setRowSelection,
    handleRowClick,
    handleRowStyle,
    isColumnVisible,
    setGetSelectedData,
    expandedRows,
    setExpandedRows,
    renderRowSubComponent,
    isHeaderFixed,
    hasExtendedColumns,
    setSortConfig,
    sortConfig,
    sortableColumns,
    sortInstance,
    enableRowSelection = true,
}: TableProps<TData>) {
    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection: rowSelection || {},
            columnVisibility: isColumnVisible || {},
            expanded: expandedRows,
        },
        onExpandedChange: setExpandedRows,
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        enableHiding: true,
        enableRowSelection,
        enableSubRowSelection: true,
    });

    const [urlParams, setUrlParams] = useSearchParams();

    const toggleSortAttribute = (sortAttr: string) => {
        urlParams.set("sort_attr", sortAttr);
        setUrlParams(urlParams);
    };

    const toggleSortOrder = (sortOrder: string) => {
        urlParams.set("sort_order", sortOrder);
        setUrlParams(urlParams);
    };

    const handleRowClickLocal = (row: Row<TData>) => {
        const selection = window.getSelection();
        if (selection && selection.toString().length === 0 && handleRowClick) {
            handleRowClick(row);
        }
        return undefined;
    };

    React.useEffect(() => {
        if (setGetSelectedData) {
            const ids = table
                .getSelectedRowModel()
                .flatRows.filter((row) => row.original);
            setGetSelectedData(ids);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowSelection]);
    const handleSort = (key: string) => {
        let direction: AppSortOrder = AppSortOrder.ASC;

        if (sortConfig?.key === key && sortableColumns?.includes(key)) {
            direction =
                sortConfig?.direction === AppSortOrder.ASC
                    ? AppSortOrder.DESC
                    : AppSortOrder.ASC;
        }

        toggleSortAttribute(key);
        toggleSortOrder(direction);

        if (setSortConfig) {
            setSortConfig({ key, direction });
        }

        if (sortInstance) {
            localStorage.setItem(
                `sort_order_${sortInstance}`,
                urlParams.get("sort_order") as string
            );

            localStorage.setItem(
                `sort_attr_${sortInstance}`,
                urlParams.get("sort_attr") as string
            );
        }
    };

    return (
        <Card type="dashboard" className={cn(cardClass)}>
            {title ? (
                <span className={cn(styles.title, "fs-exclude")}>{title}</span>
            ) : null}
            {tableHeader || null}
            <div
                className={cn(styles.wrapper, {
                    [styles.wrapper_fixed]: isHeaderFixed,
                })}
            >
                <table
                    role="table"
                    className={cn(className, styles.table, {
                        [styles.table_fixed]: isHeaderFixed,
                        [styles.table_hasExtendedColumns]: hasExtendedColumns,
                    })}
                >
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        className={cn("fs-exclude", {
                                            [styles.sortable]:
                                                sortableColumns?.includes(
                                                    header.column.id
                                                ),
                                        })}
                                        onClick={() => {
                                            if (
                                                sortableColumns?.includes(
                                                    header.column.id
                                                )
                                            ) {
                                                handleSort(header.column.id);
                                            }
                                        }}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef
                                                      .header,
                                                  header.getContext()
                                              )}

                                        <span className={styles.sorter}>
                                            {sortConfig?.key ===
                                                header.column.id &&
                                                sortableColumns?.includes(
                                                    sortConfig.key
                                                ) && (
                                                    <span
                                                        className={
                                                            styles.sortArrow
                                                        }
                                                    >
                                                        {sortConfig?.direction ===
                                                        AppSortOrder.ASC
                                                            ? "▲"
                                                            : "▼"}
                                                    </span>
                                                )}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => (
                            <>
                                <tr
                                    key={row.id}
                                    onClick={() => handleRowClickLocal(row)}
                                    style={
                                        handleRowStyle && handleRowStyle(row)
                                    }
                                    className={cn({
                                        [styles.hasExtendedColumns]:
                                            hasExtendedColumns &&
                                            hasExtendedColumns(row),
                                        [styles.canhover]: handleRowClick,
                                    })}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    ))}
                                </tr>
                                {hasExtendedColumns && hasExtendedColumns(row)
                                    ? extendedColumns.map((extCol) => (
                                          <tr
                                              key={extCol.id}
                                              className={cn(
                                                  styles.extendedRow,
                                                  {
                                                      [styles.canhover]:
                                                          handleRowClick,
                                                  }
                                              )}
                                              onClick={() =>
                                                  handleRowClickLocal(row)
                                              }
                                          >
                                              <td
                                                  key={extCol.id}
                                                  colSpan={
                                                      row.getVisibleCells()
                                                          .length
                                                  }
                                              >
                                                  {flexRender(extCol.cell, {
                                                      row,
                                                  })}
                                              </td>
                                          </tr>
                                      ))
                                    : null}
                                {row.getIsExpanded() && (
                                    <tr>
                                        <td
                                            colSpan={
                                                row.getVisibleCells().length
                                            }
                                            className={styles.custom_margin}
                                        >
                                            {renderRowSubComponent &&
                                                renderRowSubComponent({
                                                    row,
                                                })}
                                        </td>
                                    </tr>
                                )}
                            </>
                        ))}
                    </tbody>
                </table>
            </div>
        </Card>
    );
}

import { useSearchParams } from "react-router-dom";
import { Tabs } from "@jhool-io/fe-components";
import InternalUsersList from "../InternalUsersList/InternalUsersList";
import { cn } from "../../../../utils/helpers";

export default function UserListsWrapper() {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleSetTabInUrl = (tab: string) => {
        searchParams.set("tab", tab);
        setSearchParams(searchParams);
    };

    const tabItems = [
        {
            key: "internal_users",
            label: "Internal Users",
            children: <InternalUsersList />,
            onClick: () => handleSetTabInUrl("internal_users"),
        },
        {
            key: "clients",
            label: "Clients",
            children: <div>Internal users</div>,
            onClick: () => handleSetTabInUrl("clients"),
            disabled: true,
        },
    ];

    return (
        <Tabs
            items={tabItems}
            className={cn("!w-[100%] !items-end")}
            defaultActiveKey="internal_users"
            size="medium"
        />
    );
}

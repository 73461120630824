export default function DashboardIcon() {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.8587 15.6693H4.1387C2.31203 15.6693 0.832031 14.1826 0.832031 12.3559V7.41592C0.832031 6.50925 1.39203 5.36925 2.11203 4.80925L5.70536 2.00925C6.78536 1.16925 8.51203 1.12925 9.63203 1.91592L13.752 4.80259C14.5454 5.35592 15.1654 6.54259 15.1654 7.50925V12.3626C15.1654 14.1826 13.6854 15.6693 11.8587 15.6693ZM6.3187 2.79592L2.72536 5.59592C2.25203 5.96925 1.83203 6.81592 1.83203 7.41592V12.3559C1.83203 13.6293 2.86536 14.6693 4.1387 14.6693H11.8587C13.132 14.6693 14.1654 13.6359 14.1654 12.3626V7.50925C14.1654 6.86925 13.7054 5.98259 13.1787 5.62259L9.0587 2.73592C8.2987 2.20259 7.04536 2.22925 6.3187 2.79592Z"
                fill="#576A81"
            />
            <path
                d="M8 13C7.72667 13 7.5 12.7733 7.5 12.5V10.5C7.5 10.2267 7.72667 10 8 10C8.27333 10 8.5 10.2267 8.5 10.5V12.5C8.5 12.7733 8.27333 13 8 13Z"
                fill="#576A81"
            />
        </svg>
    );
}

import * as React from "react";

export default function JCBIcon() {
    return (
        <svg
            width="27"
            height="21"
            viewBox="0 0 27 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.6897 10.7621C20.398 10.7775 21.1104 10.7308 21.8159 10.7863C22.5304 10.9196 22.7026 12.0012 22.0678 12.3555C21.6347 12.5889 21.1202 12.4424 20.6507 12.4836H19.6897V10.7621ZM22.2255 8.81353C22.3829 9.36903 21.8474 9.86778 21.3124 9.79128H19.6897C19.7011 9.26743 19.6674 8.69885 19.7062 8.20258C20.3562 8.22088 21.0123 8.16524 21.6585 8.23168C21.9362 8.30145 22.1686 8.52973 22.2255 8.81353ZM26.1307 0.575722C26.1609 1.63658 26.1351 2.7535 26.1437 3.83587C26.1417 8.2364 26.148 12.6368 26.1403 17.0376C26.1118 18.6868 24.6502 20.1198 23.0124 20.1527C21.373 20.1594 19.7334 20.1538 18.0938 20.1556V13.5029C19.8802 13.4937 21.6676 13.5216 23.4533 13.489C24.2817 13.437 25.189 12.8904 25.2276 11.9788C25.3251 11.0632 24.4619 10.4299 23.6424 10.3299C23.3274 10.3217 23.3366 10.2381 23.6424 10.2016C24.4241 10.0327 25.0379 9.22367 24.8079 8.41346C24.6119 7.56131 23.67 7.2315 22.8866 7.23314C21.2893 7.22228 19.6916 7.23162 18.0942 7.22847C18.1045 5.98649 18.0727 4.74318 18.1113 3.50212C18.2379 1.88268 19.7363 0.547172 21.351 0.576146C22.9445 0.575904 24.5377 0.575904 26.1307 0.575843V0.575722Z"
                fill="url(#paint0_linear_7397_19068)"
            />
            <path
                d="M0.0424888 3.6699C0.0832839 2.02331 1.55112 0.602027 3.1868 0.577962C4.82012 0.572931 6.45367 0.577235 8.08699 0.57578C8.08251 6.08493 8.09584 11.5947 8.08026 17.1035C8.01734 18.7301 6.56551 20.1243 4.94765 20.1531C3.31124 20.159 1.67465 20.1539 0.0381851 20.1556V13.2786C1.62774 13.6541 3.29464 13.814 4.91625 13.5649C5.88558 13.4089 6.94613 12.9329 7.2743 11.9273C7.5158 11.0671 7.37984 10.1618 7.41578 9.27889V7.22853H4.60941C4.5968 8.58458 4.63523 9.943 4.58911 11.2974C4.51346 12.1299 3.68901 12.6589 2.90384 12.6306C1.93009 12.6409 0.000480929 11.9249 0.000480929 11.9249C-0.00436841 9.38424 0.0287288 6.20241 0.0424888 3.67014V3.6699Z"
                fill="url(#paint1_linear_7397_19068)"
            />
            <path
                d="M9.13362 8.0556C8.9859 8.08694 9.10392 7.55243 9.06616 7.34966C9.07622 6.06762 9.04513 4.78418 9.08331 3.50305C9.20952 1.87676 10.7194 0.53792 12.3407 0.575988H17.1153C17.1109 6.08514 17.1241 11.5949 17.1086 17.1037C17.0456 18.7303 15.5937 20.1245 13.9758 20.1533C12.3393 20.1595 10.7027 20.1543 9.0661 20.1559V12.6212C10.1838 13.5383 11.7029 13.681 13.0954 13.6835C14.1451 13.6831 15.1888 13.5213 16.2082 13.2792V11.8988C15.0594 12.4714 13.7088 12.8351 12.4353 12.5061C11.5469 12.285 10.902 11.4264 10.9164 10.5096C10.8134 9.55617 11.3723 8.54957 12.3093 8.26613C13.4725 7.90194 14.7404 8.18048 15.8308 8.65395C16.0644 8.77628 16.3016 8.928 16.2082 8.53751V7.45253C14.3845 7.01863 12.4436 6.85897 10.6115 7.33099C10.0811 7.4806 9.56442 7.70748 9.13362 8.05573V8.0556Z"
                fill="url(#paint2_linear_7397_19068)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_7397_19068"
                    x1="18.0389"
                    y1="9.94702"
                    x2="26.093"
                    y2="9.94702"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58B03A" />
                    <stop offset="1" stopColor="#55B330" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_7397_19068"
                    x1="-0.133064"
                    y1="10.5532"
                    x2="7.95502"
                    y2="10.5532"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0F6EB6" />
                    <stop offset="1" stopColor="#006DBA" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_7397_19068"
                    x1="9.05429"
                    y1="10.137"
                    x2="17.1138"
                    y2="10.137"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DE0D3D" />
                    <stop offset="1" stopColor="#E30138" />
                </linearGradient>
            </defs>
        </svg>
    );
}

import { ColumnDef } from "@tanstack/react-table";
import { useParams, useSearchParams } from "react-router-dom";
import { Pagination, Table, Tag } from "@jhool-io/fe-components";
import { IClientProviderHistory } from "../../types/clients.types";
import { APP_COLORS, LISTS_DEFAULT_LIMIT } from "../../../../utils/constants";

import {
    convertTimeTo12HoursFormat,
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import { ProviderStatus } from "../../../../utils/types/session";
import { useFetchClientProviderHistory } from "../../hooks/clients.queries";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import MobileListItem from "../../../../shared-ui/MobileListItem/MobileListItem";
import { getProviderCredenetialPillStyle } from "../../../providers/helpers/providers.helper";

const columns: ColumnDef<IClientProviderHistory>[] = [
    {
        accessorKey: "provider_name",
        header: "PROVIDER NAME",
        cell: ({ row }) => {
            return (
                <span className="flex flex-col gap-y-4">
                    <span className="flex items-center gap-x-4 capitalize">
                        {row.original.provider_name}
                        {row.original.is_current ? (
                            <>
                                <svg
                                    width="2"
                                    height="2"
                                    viewBox="0 0 2 2"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle
                                        cx="1"
                                        cy="1"
                                        r="1"
                                        fill={APP_COLORS.COLOR_PRIMARY}
                                    />
                                </svg>
                                <span className="text-primary">Current</span>
                            </>
                        ) : null}
                    </span>
                    {row.original.credential && (
                        <span>
                            {getProviderCredenetialPillStyle(
                                row.original.credential
                            )}
                        </span>
                    )}
                </span>
            );
        },
    },
    {
        accessorKey: "service",
        header: "service",
        cell: ({ row }) =>
            makeStringFirstLetterCapital(
                removeEnumUnderscore(row.original.service)
            ),
    },

    {
        accessorKey: "no_of_sessions",
        header: "NO. OF SESSIONS",
        cell: ({ row }) => row.original.no_of_sessions || "--",
    },
    {
        accessorKey: "first_session_date",
        header: "FIRST SESSION DATE",
        cell: ({ row }) => formatDate(row.original.first_session_date),
    },
    {
        accessorKey: "last_seen",
        header: "LAST SEEN",
        cell: ({ row }) => formatDate(row.original.last_seen),
    },
    {
        accessorKey: "appointment",
        header: "APPOINTMENT",
        cell: ({ row }) =>
            `${
                row.original.appointment.session_day
            } ${convertTimeTo12HoursFormat(
                `${row.original.appointment.session_start_hr}:${row.original.appointment.session_start_min}`
            )}, ${makeStringFirstLetterCapital(
                row.original.appointment.recurrence_rule
            )}`,
    },
    {
        accessorKey: "status",
        header: "SESSION STATUS",
        cell: ({ row }) => {
            return row.original.is_current ? (
                <Tag
                    title={ProviderStatus.ACTIVE}
                    textColor="#00563E"
                    bgColor="rgba(204, 250, 233, 0.50)"
                    className="capitalize font-normal px-8 py-4 h-24 rounded-r4"
                />
            ) : (
                <Tag
                    title="TERMINATED"
                    textColor="#981F41"
                    bgColor="rgba(251, 199, 198, 0.50)"
                    className="capitalize font-normal px-8 py-4 h-24 rounded-r4"
                />
            );
        },
    },
];

export default function ClientProvidersHistoryList() {
    const [searchParams, setSearchParams] = useSearchParams();

    const params = useParams();
    const clientId = params.clientId as string;

    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;
    const pageFilter = Number(searchParams.get("page")) || 1;

    const { isLoading, data, error, isSuccess } = useFetchClientProviderHistory(
        clientId,
        { limit: limitFilter, page: pageFilter }
    );

    const handleSetPageFilter = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const handleSetLimitFilter = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    return (
        <div>
            <div className="rounded-r8 bg-white border border-stroke-divider px-12 py-24 md:p-24">
                <h3 className="text-base font-bold leading-normal">
                    Providers History
                </h3>
            </div>
            <div className="mt-16">
                {isLoading && (
                    <Skeleton
                        type="table"
                        containerTestId="providerslist-loader"
                    />
                )}
                {error && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            error.response?.data.message ||
                            `Cannot display client providers list at this time please try again later`
                        }
                    />
                )}
                {data && isSuccess && data.data.length === 0 && (
                    <ListState
                        emptyMessage="No provider history yet"
                        isError={false}
                        stateHelperText="Client provider history will appear here when added"
                    />
                )}
                {data && isSuccess && data.data.length > 0 && (
                    <>
                        <div className="hidden md:block">
                            <Table
                                data={data.data}
                                columns={columns}
                                hasPagination={data.total_count > 20}
                                pagination={
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handleSetPageFilter}
                                        limit={limitFilter}
                                        onLimitChange={handleSetLimitFilter}
                                    />
                                }
                            />
                        </div>
                        <div className="block md:hidden" role="table">
                            <div className="flex flex-col gap-y-12">
                                {data.data.map((provider) => (
                                    <MobileListItem
                                        key={provider.provider_id}
                                        showCheckButton={false}
                                        topChildren={
                                            <div className="flex flex-col gap-y-4">
                                                <div className="flex items-center gap-x-4 capitalize font-semibold text-base">
                                                    {provider.provider_name}
                                                    {provider.is_current ? (
                                                        <>
                                                            <svg
                                                                width="2"
                                                                height="2"
                                                                viewBox="0 0 2 2"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <circle
                                                                    cx="1"
                                                                    cy="1"
                                                                    r="1"
                                                                    fill={
                                                                        APP_COLORS.COLOR_PRIMARY
                                                                    }
                                                                />
                                                            </svg>
                                                            <span className="text-primary">
                                                                Current
                                                            </span>
                                                        </>
                                                    ) : null}
                                                </div>
                                                <span>
                                                    {getProviderCredenetialPillStyle(
                                                        provider.credential
                                                    )}
                                                </span>
                                            </div>
                                        }
                                        bottomChildren={
                                            <div className="flex flex-col gap-y-8 w-full items-start">
                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <div className="basis-1/2">
                                                        <span className="block font-medium text-xss uppercase">
                                                            service
                                                        </span>
                                                        <span className="block text-gray font-semibold text-xs">
                                                            {makeStringFirstLetterCapital(
                                                                removeEnumUnderscore(
                                                                    provider.service
                                                                )
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className="basis-1/2">
                                                        <span className="block font-medium text-xss uppercase">
                                                            No of sessions
                                                        </span>
                                                        <span className="block text-gray font-semibold text-xs">
                                                            {
                                                                provider.no_of_sessions
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <div className="basis-1/2">
                                                        <span className="block font-medium text-xss uppercase">
                                                            first session date
                                                        </span>
                                                        <span className="block text-gray font-semibold text-xs">
                                                            {formatDate(
                                                                provider.first_session_date
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className="basis-1/2">
                                                        <span className="block font-medium text-xss uppercase">
                                                            last seen
                                                        </span>
                                                        <span className="block text-gray font-semibold text-xs">
                                                            {formatDate(
                                                                provider.last_seen
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="flex items-center w-full text-left gap-x-4">
                                                    <div className="basis-1/2">
                                                        <span className="block font-medium text-xss uppercase">
                                                            appointment
                                                        </span>
                                                        <span className="block text-gray font-semibold text-xs">
                                                            {`${
                                                                provider
                                                                    .appointment
                                                                    .session_day
                                                            } ${convertTimeTo12HoursFormat(
                                                                `${provider.appointment.session_start_hr}:${provider.appointment.session_start_min}`
                                                            )}, ${makeStringFirstLetterCapital(
                                                                provider
                                                                    .appointment
                                                                    .recurrence_rule
                                                            )}`}
                                                        </span>
                                                    </div>
                                                    <div className="basis-1/2">
                                                        <span className="block font-medium text-xss uppercase">
                                                            session status
                                                        </span>
                                                        <span className="block text-gray font-semibold text-xs">
                                                            {provider.is_current ? (
                                                                <Tag
                                                                    title={
                                                                        ProviderStatus.ACTIVE
                                                                    }
                                                                    textColor="#00563E"
                                                                    bgColor="rgba(204, 250, 233, 0.50)"
                                                                    className="capitalize font-normal px-8 py-4 h-24 rounded-r4"
                                                                />
                                                            ) : (
                                                                <Tag
                                                                    title="TERMINATED"
                                                                    textColor="#981F41"
                                                                    bgColor="rgba(251, 199, 198, 0.50)"
                                                                    className="capitalize font-normal px-8 py-4 h-24 rounded-r4"
                                                                />
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    />
                                ))}
                            </div>
                            {data.total_count > 20 && (
                                <div className="bg-white rounded-r8 p-12 mt-16">
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handleSetPageFilter}
                                        limit={limitFilter}
                                        onLimitChange={handleSetLimitFilter}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

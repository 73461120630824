import * as React from "react";
import { useParams } from "react-router-dom";
import { Document, Page } from "react-pdf";
import { Button, Pagination } from "@jhool-io/fe-components";
import { useFetchSingleRemitEOB } from "../../hooks/remits.queries";
import ListState from "../../../../components/ListState/ListState";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import Navbar from "../../../../components/Navbar/Navbar";
import ZoomOutIcon from "../../../../components/Icons/ZoomOut";
import ZoomInIcon from "../../../../components/Icons/ZoomIn";

export default function SingleRemitEOB() {
    // Get id from url params
    const params = useParams();
    const remitId = params.remitId || "";

    const [EOBpageNumber, setEOBPageNumber] = React.useState(1);
    const [EOBNumPages, setEOBNumPages] = React.useState<null | number>(null);
    const [scale, setScale] = React.useState(3);

    const zoomIn = () => {
        if (scale < 3) {
            setScale((prevScale: number) => prevScale + 0.5);
        }
    };

    const zoomOut = () => {
        if (scale > 1) {
            setScale((prevScale: number) => prevScale - 0.5);
        }
    };

    // get remitEOB
    const singleRemitEOB = useFetchSingleRemitEOB(remitId);

    return (
        <>
            <Navbar title="Remit EOB" />
            <div className="page">
                {singleRemitEOB.isLoading && (
                    <Skeleton
                        className="w-full h-[500px]"
                        containerTestId="remit-eob-loader"
                    />
                )}
                {singleRemitEOB.error && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg="Cannot display remit EOB at this time. Please try again later"
                        cardClass="mt-32"
                    />
                )}
                {singleRemitEOB.data?.data && (
                    <div className="p-8 bg-secondary-light rounded-r12 border border-[rgba(231,_231,_231,_0.4)] flex justify-center">
                        <Document
                            error={
                                <span className="text-danger text-sm flex items-center justify-center font-medium  h-12">
                                    Could not load pdf at this time
                                </span>
                            }
                            loading={
                                <span className=" text-sm flex items-center justify-center font-medium h-12">
                                    Loading pdf..
                                </span>
                            }
                            onLoadSuccess={({ numPages: nextNumPages }) => {
                                setEOBNumPages(nextNumPages);
                                setEOBPageNumber(1);
                            }}
                            onItemClick={({ pageNumber: itemPageNumber }) => {
                                setEOBPageNumber(Number(itemPageNumber));
                            }}
                            file={`data:image/png;base64,${singleRemitEOB.data?.data}`}
                        >
                            <Page
                                pageNumber={EOBpageNumber}
                                height={550}
                                scale={scale}
                            />
                            <div className="flex justify-center items-center mt-6">
                                <Button
                                    variant="normal"
                                    onClick={zoomIn}
                                    disabled={scale === 3}
                                >
                                    <ZoomInIcon />
                                </Button>

                                <Button
                                    variant="normal"
                                    onClick={zoomOut}
                                    disabled={scale === 1}
                                >
                                    <ZoomOutIcon />
                                </Button>
                            </div>

                            {EOBNumPages && EOBNumPages > 1 && (
                                <Pagination
                                    totalCount={EOBNumPages}
                                    totalPages={EOBNumPages}
                                    currentPage={EOBpageNumber}
                                    onPageChange={(page) =>
                                        setEOBPageNumber(page)
                                    }
                                    pageSize={1}
                                    isCentered
                                />
                            )}
                        </Document>
                    </div>
                )}
            </div>
        </>
    );
}

/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { ColumnDef, Row } from "@tanstack/react-table";
import {
    Button,
    DatePicker,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Table,
    Tag,
    Dialog,
    DialogContent,
    Checkbox,
    SearchInput,
} from "@jhool-io/fe-components";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import {
    IBatchRemit,
    RemitSource,
    RemitStatus,
} from "../../types/remits.types";
import {
    cn,
    convertDateFilterStringToDate,
    formatDate,
    getTotalAmountOfTableRowsSelected,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    showMoneyInAppFormat,
    truncateString,
} from "../../../../utils/helpers";
import { useFetchInsuranceProviders } from "../../../../hooks/queries/client";
import { useFetchBatchRemits } from "../../hooks/remits.queries";
import ListState from "../../../../components/ListState/ListState";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import Navbar from "../../../../components/Navbar/Navbar";
import ManualRemitForm from "../ManualRemitForm/ManualRemitForm";
import useToast from "../../../../hooks/useToast";
import {
    useDeleteManualBatchRemit,
    useUpdateRemitStatus,
} from "../../hooks/remits.mutations";
import MoreButton from "../../../../shared-ui/Buttons/MoreButton/MoreButton";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import { LISTS_DEFAULT_LIMIT } from "../../../../utils/constants";
import TimesIcon from "../../../../components/Icons/Times";
import { getRemitClaimsListNavigateUrl } from "../../helpers/helpers.remits";
import EditManualRemitForm from "../EditManualRemitForm/EditManualRemitForm";

type SubmitTypes = "save" | "save-continue";

type Dialogs = "add_remit" | "delete_remit" | "edit_remit";

export default function RemitsList() {
    const navigate = useNavigate();
    const location = useLocation();

    const [searchParams, setSearchParams] = useSearchParams();
    const [rowSelection, setRowSelection] = React.useState({});
    const [getSelectedRemitsData, setGetSelectedRemitsData] = React.useState<
        Row<IBatchRemit>[]
    >([]);
    const [submitType, setSubmitType] = React.useState<SubmitTypes | null>(
        null
    );
    const [dialogInView, setDialogInView] = React.useState<Dialogs | null>(
        null
    );
    const [selectedManualRemitId, setSelectedManualRemitId] = React.useState<
        string | null
    >(null);

    const insuranceProviders = useFetchInsuranceProviders();

    const pageFilter = Number(searchParams.get("page") || 1);

    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;
    const fromDateFilter = searchParams.get("from_date");
    const toDateFilter = searchParams.get("to_date");
    const insuranceProviderFilter =
        searchParams.get("insurance_provider_name") || "";
    const statusFilter = searchParams.get("status") || RemitStatus.UN_POSTED;
    const hasPaymentsFilter = searchParams.get("has_payments");
    const hasClaimsFilter = searchParams.get("has_claims") || "yes";
    const remitSourceFilter = searchParams.get("source") || "";
    const searchString = searchParams.get("search") || "";
    const dosFromDateFilter = searchParams.get("dos_from_date");
    const dosToDateFilter = searchParams.get("dos_to_date");

    const fromDateFilterToDate = convertDateFilterStringToDate(fromDateFilter);
    const toDateFilterToDate = convertDateFilterStringToDate(toDateFilter);
    const dateOfServiceFromDateFilterToDate =
        convertDateFilterStringToDate(dosFromDateFilter);
    const dateOfServiceToDateFilterToDate =
        convertDateFilterStringToDate(dosToDateFilter);

    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: searchParams.get("sort_attr") || "",
        direction: searchParams.get("sort_order") || "",
    });
    const remitStatusOptions = ["posted", "unposted"];
    const sortableColumns = [
        "remit_received_date",
        "no_of_matched_payments",
        "no_of_unmatched_payments",
        "remit_amount",
        "remit_batch_status",
        "no_of_claims_payments",
        "insurance_provider_name",
        "remit_batch_ref_id",
    ];

    const handleDefaultHasPaymentsFilter = () => {
        if (hasPaymentsFilter === "yes") {
            return "true";
        }

        if (hasPaymentsFilter === "no") {
            return "false";
        }

        return "";
    };

    const handleDefaultHasClaimsFilter = () => {
        if (hasClaimsFilter === "yes") {
            return "true";
        }

        if (hasClaimsFilter === "no") {
            return "false";
        }

        return "";
    };

    const handleDefaultStatusFilter = () => {
        if (statusFilter === "all") {
            return "";
        }
        return statusFilter;
    };

    const handleConfigAttribute = () => {
        if (sortableColumns.includes(sortConfig.key)) {
            return sortConfig.key;
        }

        return undefined;
    };

    const { data, error, isLoading, isSuccess } = useFetchBatchRemits({
        batch_remit_status: handleDefaultStatusFilter(),
        insurance_provider_name: insuranceProviderFilter,
        from_date: fromDateFilter,
        to_date: toDateFilter,
        page: pageFilter,
        limit: limitFilter,
        sort_attribute:
            searchParams.get("sort_attr") || handleConfigAttribute(),
        sort_order: searchParams.get("sort_order") || sortConfig.direction,
        has_payments: handleDefaultHasPaymentsFilter(),
        has_claims: handleDefaultHasClaimsFilter(),
        remit_source: remitSourceFilter,
        dos_from_date: dosFromDateFilter,
        dos_to_date: dosToDateFilter,
        search_string: searchString,
    });

    const handleLimitChange = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    // To determine if mutation is currently running, returns 0 || 1
    const isMutating = useIsMutating(["update-remit-status"]);
    // To determine if mutation is currently running, returns 0 || 1
    const isAddManualRemitMutating = useIsMutating(["add-manual-remit"]);
    const queryClient = useQueryClient();

    // Toast for success and error states
    const { toast } = useToast();

    const updateRemitStatus = useUpdateRemitStatus();

    const deleteManualRemit = useDeleteManualBatchRemit();

    const remitSourceOptions = ["Manual", "Electronic"];

    const showOpenRemitsBtn =
        getSelectedRemitsData.length > 0 &&
        getSelectedRemitsData.length ===
            getSelectedRemitsData.filter(
                (remit) =>
                    remit.original.remit_batch_status === RemitStatus.POSTED
            ).length;

    const showCloseRemitsBtn =
        getSelectedRemitsData.length > 0 &&
        getSelectedRemitsData.length ===
            getSelectedRemitsData.filter(
                (remit) =>
                    remit.original.remit_batch_status === RemitStatus.UN_POSTED
            ).length;

    const showDeleteManualRemitsBtn =
        getSelectedRemitsData.length > 0 &&
        getSelectedRemitsData.length ===
            getSelectedRemitsData.filter(
                (remit) => remit.original.remit_source === RemitSource.MANUAL
            ).length;

    const selectedRemitsIds = getSelectedRemitsData.map(
        (item) => item.original.remittance_batch_id
    );

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem("remitslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem("remitslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleInsuranceProviderChange = (value: string) => {
        if (value === "") searchParams.delete("insurance_provider_name");
        else searchParams.set("insurance_provider_name", value);
        searchParams.set("page", "1");
        localStorage.setItem("remitslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleStatusChange = (value: string) => {
        searchParams.set("status", value);
        searchParams.set("page", "1");
        localStorage.setItem("remitslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleSourceChange = (value: string) => {
        searchParams.set("source", value);
        searchParams.set("page", "1");
        localStorage.setItem("remitslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleHasPaymentsFilterChange = (value: string) => {
        if (value === "") searchParams.delete("has_payments");
        else searchParams.set("has_payments", value);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleHasClaimsFilterChange = (value: string) => {
        searchParams.set("has_claims", value);
        searchParams.set("page", "1");
        localStorage.setItem("remitslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleDosFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("dos_from_date");
        else searchParams.set("dos_from_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem("remitslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleDosToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("dos_to_date");
        else searchParams.set("dos_to_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        localStorage.setItem("remitslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleRowClick = (row: Row<IBatchRemit>) => {
        navigate(
            `${getRemitClaimsListNavigateUrl(row.original.remittance_batch_id)}`
        );
    };

    // onChange handler for search input
    const handleSetSearchString = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") searchParams.delete("search");
        else searchParams.set("search", e.target.value);
        localStorage.setItem("remitslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    // onChange handler for page
    const handlePageChange = (page: number) => {
        searchParams.set("page", String(page));
        localStorage.setItem("remitslist_filters", searchParams.toString());
        setSearchParams(searchParams);
    };

    const handleClearFilters = () => {
        localStorage.removeItem("remitslist_filters");
        navigate(location.pathname);
    };

    // Get remits count
    let remitsCount;
    if (error) remitsCount = "";
    else if (isLoading) remitsCount = "";
    else if (data?.data && data?.data.length === 0) remitsCount = 0;
    else if (data?.data && data?.data.length > 0)
        remitsCount = data.total_count;

    const getInsuranceTextToDisplay = React.useMemo(
        () =>
            insuranceProviders?.data?.data.find(
                (provider) =>
                    provider.alias.toLowerCase() ===
                    insuranceProviderFilter.toLowerCase()
            )?.alias,
        [insuranceProviderFilter, insuranceProviders?.data?.data]
    );

    const getHasPaymentsLabel = () => {
        if (hasPaymentsFilter === "yes") {
            return "Show remits with payments";
        }
        if (hasPaymentsFilter === "no") {
            return "Show remits without payments";
        }

        return "Show all remits";
    };

    const getHasClaimsLabel = () => {
        if (hasClaimsFilter === "yes") {
            return "Show remits with claims";
        }
        if (hasClaimsFilter === "no") {
            return "Show remits without claims";
        }

        return "Show all remits";
    };

    // function to change remit status
    const handleChangeStatus = (ids: string[], remitStatus: string) => {
        const changeStatusPayload = {
            remittance_batch_ids: ids,
            status: remitStatus,
        };
        updateRemitStatus.mutate(changeStatusPayload, {
            onSuccess: (res) => {
                queryClient.invalidateQueries(["batch-remits"]);
                toast({
                    mode: "success",
                    message:
                        res.message || "Remit status updated successfully.",
                });
                setRowSelection({});
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not update remit status.",
                });
            },
        });
    };

    const handleDeleteManualRemits = () => {
        const dataToSend = {
            batch_remit_ids: selectedManualRemitId
                ? [selectedManualRemitId]
                : selectedRemitsIds,
        };

        deleteManualRemit.mutate(dataToSend, {
            onSuccess: (res) => {
                queryClient.invalidateQueries(["batch-remits"]);
                toast({
                    mode: "success",
                    message:
                        res.message || "Manual remit(s) deleted successfully.",
                });
                setDialogInView(null);
                if (selectedManualRemitId) {
                    setSelectedManualRemitId(null);
                }
                if (getSelectedRemitsData.length > 0) {
                    setRowSelection({});
                }
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not delete manual remit(s) at this tme",
                });
            },
        });
    };

    const columns: ColumnDef<IBatchRemit>[] = [
        {
            id: "select",
            header: ({ table }) => (
                <Checkbox
                    handleChange={table.getToggleAllRowsSelectedHandler()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={table.getIsAllRowsSelected()}
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    handleChange={row.getToggleSelectedHandler()}
                    indeterminate={row.getIsSomeSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={row.getIsSelected()}
                />
            ),
        },
        {
            accessorKey: "remit_received_date",
            header: "REMIT RECEIVED DATE",
            cell: ({ row }) =>
                row.original.remit_received_date
                    ? formatDate(
                          row.original.remit_received_date.replace(
                              "+00:00",
                              ""
                          ),
                          true
                      )
                    : "--",
        },
        {
            accessorKey: "remit_batch_ref_id",
            header: "REMIT ID",
        },
        {
            accessorKey: "remit_batch_payment_ref",
            header: "Ref Number",
            cell: ({ row }) => row.original.remit_batch_payment_ref || "--",
        },
        {
            accessorKey: "insurance_provider_name",
            header: "INSURANCE",
            cell: ({ row }) => (
                <span className="uppercase">
                    {row.original.insurance_provider_name}
                </span>
            ),
        },
        {
            accessorKey: "remit_batch_status",
            header: "STATUS",
            cell: ({ row }) => {
                let bgColor: string;
                let textColor: string;

                switch (row.original.remit_batch_status) {
                    case RemitStatus.UN_POSTED:
                        bgColor = "rgba(245, 245, 245, 0.50)";
                        textColor = "#0B132B";
                        break;
                    case RemitStatus.POSTED:
                        bgColor = "rgba(204, 250, 233, 0.50)";
                        textColor = "#00563E";
                        break;
                    default:
                        bgColor = "rgba(229, 229, 229, 0.50)";
                        textColor = "#0B132B";
                }

                return (
                    <Tag
                        title={removeEnumUnderscore(
                            row.original.remit_batch_status
                        )}
                        textColor={textColor}
                        bgColor={bgColor}
                        className="capitalize font-normal px-8 py-4 h-24 rounded-r4"
                    />
                );
            },
        },
        {
            accessorKey: "remit_source",
            header: "REMIT SOURCE",
        },
        {
            accessorKey: "remit_amount",
            header: "REMIT AMOUNT",
            cell: ({ row }) => showMoneyInAppFormat(row.original.remit_amount),
        },
        {
            accessorKey: "amount_of_unmatched_payments",
            header: "UNMATCHED AMOUNT",
            cell: ({ row }) =>
                showMoneyInAppFormat(row.original.amount_of_unmatched_payments),
        },
        {
            accessorKey: "no_of_claims",
            header: "NO OF CLAIMS",
        },
        {
            accessorKey: "no_of_unmatched_payments",
            header: "UNMATCHED",
        },
        {
            accessorKey: "no_of_matched_payments",
            header: "MATCHED",
        },
        {
            accessorKey: "remittance_batch_id",
            header: "",
            cell: ({ row }) => (
                <Dropdown>
                    <DropdownTrigger asChild>
                        <MoreButton />
                    </DropdownTrigger>
                    <DropdownContent
                        width="auto"
                        className="text-gray"
                        align="end"
                    >
                        {row.original.remit_source === RemitSource.MANUAL && (
                            <DropdownItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDialogInView("edit_remit");
                                    setSelectedManualRemitId(
                                        row.original.remittance_batch_id
                                    );
                                }}
                            >
                                Edit remit
                            </DropdownItem>
                        )}
                        {row.original.remit_source === RemitSource.MANUAL && (
                            <DropdownItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDialogInView("delete_remit");
                                    setSelectedManualRemitId(
                                        row.original.remittance_batch_id
                                    );
                                }}
                            >
                                Delete remit
                            </DropdownItem>
                        )}
                        {row.original.remit_batch_status ===
                            RemitStatus.UN_POSTED && (
                            <DropdownItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleChangeStatus(
                                        [row.original.remittance_batch_id],
                                        RemitStatus.POSTED
                                    );
                                }}
                                disabled={isMutating > 0}
                            >
                                Mark as Posted
                            </DropdownItem>
                        )}
                        {row.original.remit_batch_status ===
                            RemitStatus.POSTED && (
                            <DropdownItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleChangeStatus(
                                        [row.original.remittance_batch_id],
                                        RemitStatus.UN_POSTED
                                    );
                                }}
                                disabled={isMutating > 0}
                            >
                                Mark as Unposted
                            </DropdownItem>
                        )}
                    </DropdownContent>
                </Dropdown>
            ),
        },
    ];

    React.useEffect(() => {
        searchParams.set("sort_attr", sortConfig.key);
        searchParams.set("sort_order", sortConfig.direction);
        localStorage.setItem("remitslist_filters", searchParams.toString());
        setSearchParams(searchParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortConfig]);

    return (
        <>
            <Dialog open={dialogInView === "delete_remit"}>
                <DialogContent
                    title="Delete Manual Remit"
                    variant="center"
                    handleCloseDialog={() => {
                        setDialogInView(null);
                        setSelectedManualRemitId(null);
                    }}
                    isDeleting
                    saveText="Delete manual remit"
                    onSaveClick={handleDeleteManualRemits}
                    isSubmitBtnDisabled={deleteManualRemit.isLoading}
                    isCancelBtnDisabled={deleteManualRemit.isLoading}
                >
                    <div className="px-8">
                        <h3 className="text-xl font-medium mb-[8px]">
                            Are you sure?
                        </h3>
                        <p className="text-base">
                            This action will delete all individual remits and
                            payments attached to this remit
                        </p>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={dialogInView === "add_remit"}>
                <DialogContent
                    variant="center"
                    title="Add Remit"
                    cancelBtnFormValue="add-remit-form"
                    canCancelButtonSubmit
                    submitBtnFormValue="add-remit-form"
                    handleCloseDialog={() => setDialogInView(null)}
                    cancelText="Save progress"
                    saveText="Continue"
                    onSaveClick={() => {
                        setSubmitType("save-continue");
                    }}
                    isCancelBtnDisabled={isAddManualRemitMutating > 0}
                    isSubmitBtnDisabled={isAddManualRemitMutating > 0}
                    onCancelClick={() => setSubmitType("save")}
                >
                    <ManualRemitForm
                        submitType={submitType}
                        onFormSubmit={() => setDialogInView(null)}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={
                    dialogInView === "edit_remit" &&
                    Boolean(selectedManualRemitId)
                }
            >
                <DialogContent
                    variant="center"
                    handleCloseDialog={() => setDialogInView(null)}
                    wrapperClass="w-[850px]"
                    classNames="px-0"
                    submitBtnFormValue="edit-remit-form"
                    saveText="Save changes"
                    title="Edit Manual Remit"
                >
                    <EditManualRemitForm
                        onFormSubmit={() => setDialogInView(null)}
                        remitId={selectedManualRemitId || ""}
                    />
                </DialogContent>
            </Dialog>
            <Navbar title={`Remits List (${remitsCount})`} />
            <div className="page">
                <div
                    className={cn(
                        "flex flex-col gap-y-24 mb-32 bg-white p-16 rounded-r8",
                        {
                            "mb-8": searchParams.size > 0,
                        }
                    )}
                >
                    <SearchInput
                        containerClass="w-[400px] lg:w-2/5 max-w-full"
                        placeholder="Search by client, remit id and ref number"
                        onChange={handleSetSearchString}
                        defaultValue={searchString}
                    />
                    <div className="flex items-center gap-x-12 gap-y-24 flex-wrap justify-between">
                        <div className="flex gap-x-12 gap-y-24 flex-wrap">
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <FilterButton
                                        text={
                                            statusFilter
                                                ? truncateString(
                                                      removeEnumUnderscore(
                                                          statusFilter
                                                      )
                                                  )
                                                : "Status"
                                        }
                                    />
                                </DropdownTrigger>
                                <DropdownContent
                                    align="end"
                                    maxHeight={216}
                                    className="overflow-y-auto"
                                    width="auto"
                                >
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            {
                                                "text-primary":
                                                    statusFilter === "all",
                                            }
                                        )}
                                        onClick={() =>
                                            handleStatusChange("all")
                                        }
                                    >
                                        All
                                        {statusFilter === "all" ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                    {remitStatusOptions.map((status) => (
                                        <DropdownItem
                                            className={cn(
                                                "flex gap-x-8 items-center capitalize text-xs font-medium",
                                                {
                                                    "text-primary":
                                                        statusFilter === status,
                                                }
                                            )}
                                            key={status}
                                            onClick={() =>
                                                handleStatusChange(status)
                                            }
                                        >
                                            {makeStringFirstLetterCapital(
                                                status
                                            )}
                                            {statusFilter === status ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                    ))}
                                </DropdownContent>
                            </Dropdown>

                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <FilterButton
                                        text={
                                            remitSourceFilter
                                                ? `${truncateString(
                                                      removeEnumUnderscore(
                                                          remitSourceFilter
                                                      )
                                                  )} remits`
                                                : "Source"
                                        }
                                    />
                                </DropdownTrigger>

                                <DropdownContent
                                    align="end"
                                    maxHeight={216}
                                    className="overflow-y-auto"
                                    width="auto"
                                >
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            {
                                                "text-primary":
                                                    remitSourceFilter === "",
                                            }
                                        )}
                                        onClick={() => handleSourceChange("")}
                                    >
                                        Show all remits
                                        {remitSourceFilter === "" ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>

                                    {remitSourceOptions.map((source) => (
                                        <DropdownItem
                                            className={cn(
                                                "flex gap-x-8 items-center capitalize text-xs font-medium",
                                                {
                                                    "text-primary":
                                                        remitSourceFilter ===
                                                        source,
                                                }
                                            )}
                                            key={source}
                                            onClick={() =>
                                                handleSourceChange(source)
                                            }
                                        >
                                            {`Show ${makeStringFirstLetterCapital(
                                                source
                                            )} remits`}
                                        </DropdownItem>
                                    ))}
                                </DropdownContent>
                            </Dropdown>

                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <FilterButton
                                        text={getHasPaymentsLabel()}
                                    />
                                </DropdownTrigger>
                                <DropdownContent
                                    align="end"
                                    width="auto"
                                    maxHeight={216}
                                    className="overflow-y-auto"
                                >
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            {
                                                "text-primary":
                                                    !hasPaymentsFilter,
                                            }
                                        )}
                                        onClick={() =>
                                            handleHasPaymentsFilterChange("")
                                        }
                                    >
                                        Show all remits
                                        {!hasPaymentsFilter ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            {
                                                "text-primary":
                                                    hasPaymentsFilter === "yes",
                                            }
                                        )}
                                        onClick={() =>
                                            handleHasPaymentsFilterChange("yes")
                                        }
                                    >
                                        Show remits with payments
                                        {hasPaymentsFilter === "yes" ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            {
                                                "text-primary":
                                                    hasPaymentsFilter === "no",
                                            }
                                        )}
                                        onClick={() =>
                                            handleHasPaymentsFilterChange("no")
                                        }
                                    >
                                        Show remits without payments
                                        {hasPaymentsFilter === "no" ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                </DropdownContent>
                            </Dropdown>

                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <FilterButton text={getHasClaimsLabel()} />
                                </DropdownTrigger>
                                <DropdownContent
                                    align="end"
                                    width="auto"
                                    maxHeight={216}
                                    className="overflow-y-auto"
                                >
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            {
                                                "text-primary":
                                                    hasClaimsFilter === "all",
                                            }
                                        )}
                                        onClick={() =>
                                            handleHasClaimsFilterChange("all")
                                        }
                                    >
                                        Show all remits
                                        {hasClaimsFilter === "all" ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            {
                                                "text-primary":
                                                    hasClaimsFilter === "yes",
                                            }
                                        )}
                                        onClick={() =>
                                            handleHasClaimsFilterChange("yes")
                                        }
                                    >
                                        Show remits with claims
                                        {hasClaimsFilter === "yes" ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            {
                                                "text-primary":
                                                    hasClaimsFilter === "no",
                                            }
                                        )}
                                        onClick={() =>
                                            handleHasClaimsFilterChange("no")
                                        }
                                    >
                                        Show remits without claims
                                        {hasClaimsFilter === "no" ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                </DropdownContent>
                            </Dropdown>

                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <FilterButton
                                        classNames={
                                            getInsuranceTextToDisplay
                                                ? "uppercase"
                                                : "normal-case"
                                        }
                                        text={
                                            (insuranceProviders.isLoading &&
                                                "Loading..") ||
                                            (insuranceProviders.error &&
                                                "Error loading providers") ||
                                            (insuranceProviders?.data?.data &&
                                            getInsuranceTextToDisplay
                                                ? truncateString(
                                                      removeEnumUnderscore(
                                                          getInsuranceTextToDisplay ||
                                                              ""
                                                      )
                                                  )
                                                : "Insurance")
                                        }
                                    />
                                </DropdownTrigger>
                                <DropdownContent
                                    align="end"
                                    width="auto"
                                    maxHeight={216}
                                    className="overflow-y-auto"
                                >
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            {
                                                "text-primary":
                                                    insuranceProviderFilter ===
                                                    "",
                                            }
                                        )}
                                        onClick={() =>
                                            handleInsuranceProviderChange("")
                                        }
                                    >
                                        All
                                        {insuranceProviderFilter === "" ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                    {insuranceProviders?.data?.data
                                        .sort((a, b) =>
                                            a.alias.localeCompare(b.alias)
                                        )
                                        .map((provider) => (
                                            <DropdownItem
                                                className={cn(
                                                    "flex gap-x-8 items-center uppercase text-xs font-medium",
                                                    {
                                                        "text-primary":
                                                            insuranceProviderFilter ===
                                                            provider.name,
                                                    }
                                                )}
                                                key={
                                                    provider.insurance_provider_id
                                                }
                                                onClick={() =>
                                                    handleInsuranceProviderChange(
                                                        provider.name
                                                    )
                                                }
                                            >
                                                {provider.alias}
                                                {insuranceProviderFilter ===
                                                provider.name ? (
                                                    <CheckPrimaryColorIcon />
                                                ) : null}
                                            </DropdownItem>
                                        ))}
                                </DropdownContent>
                            </Dropdown>

                            <Popover>
                                <PopoverTrigger asChild>
                                    <FilterButton text="Received Date" />
                                </PopoverTrigger>
                                <PopoverContent
                                    align="end"
                                    className="p-16 gap-y-12"
                                >
                                    <DatePicker
                                        label="From"
                                        placeholderText="MM/DD/YYYY"
                                        selected={fromDateFilterToDate}
                                        onChange={handleFromDateFilterChange}
                                        maxDate={
                                            toDateFilterToDate ||
                                            new Date(Date.now())
                                        }
                                        isClearable
                                    />

                                    <DatePicker
                                        label="To"
                                        placeholderText="MM/DD/YYYY"
                                        selected={toDateFilterToDate}
                                        onChange={handleToDateFilterChange}
                                        minDate={fromDateFilterToDate}
                                        isClearable
                                    />
                                </PopoverContent>
                            </Popover>

                            <Popover>
                                <PopoverTrigger asChild>
                                    <FilterButton text="Date of service" />
                                </PopoverTrigger>
                                <PopoverContent
                                    align="end"
                                    className="p-16 gap-y-12"
                                >
                                    <DatePicker
                                        label="From"
                                        placeholderText="MM/DD/YYYY"
                                        selected={
                                            dateOfServiceFromDateFilterToDate
                                        }
                                        onChange={handleDosFromDateFilterChange}
                                        maxDate={
                                            dateOfServiceToDateFilterToDate ||
                                            new Date(Date.now())
                                        }
                                        isClearable
                                    />

                                    <DatePicker
                                        label="To"
                                        placeholderText="MM/DD/YYYY"
                                        selected={
                                            dateOfServiceToDateFilterToDate
                                        }
                                        onChange={handleDosToDateFilterChange}
                                        minDate={
                                            dateOfServiceFromDateFilterToDate
                                        }
                                        isClearable
                                    />
                                </PopoverContent>
                            </Popover>
                        </div>

                        <div className="w-full min-[500px]:w-auto">
                            <Button
                                onClick={() => setDialogInView("add_remit")}
                                className="w-full"
                            >
                                Add remit
                            </Button>
                        </div>
                    </div>
                </div>
                {searchParams.size > 0 ? (
                    <div className="mb-32">
                        <Button
                            size="auto"
                            variant="normal"
                            className="gap-x-4"
                            onClick={handleClearFilters}
                        >
                            <TimesIcon /> Clear filters
                        </Button>
                    </div>
                ) : null}

                {error && error?.response?.status !== 404 && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            error.response?.data.message ||
                            "Cannot display remits at this time please try again later"
                        }
                    />
                )}
                {isLoading && (
                    <Skeleton
                        containerTestId="batch-remits-loader"
                        type="table"
                        count={6}
                    />
                )}

                {data && isSuccess && (
                    <>
                        {data.data.length === 0 && (
                            <ListState
                                stateHelperText="Remits will appear here once available "
                                emptyMessage="No remits yet"
                                isError={false}
                            />
                        )}

                        {data.data.length > 0 && (
                            <>
                                {(showCloseRemitsBtn ||
                                    showOpenRemitsBtn ||
                                    showDeleteManualRemitsBtn) &&
                                    getTotalAmountOfTableRowsSelected(
                                        rowSelection
                                    ).amount > 0 && (
                                        <div className="flex items-center mb-6 gap-x-3">
                                            <div className="font-medium text-base">
                                                {
                                                    getTotalAmountOfTableRowsSelected(
                                                        rowSelection
                                                    ).amountString
                                                }
                                                :
                                            </div>
                                            {showOpenRemitsBtn && (
                                                <Button
                                                    variant="secondary"
                                                    onClick={() =>
                                                        handleChangeStatus(
                                                            selectedRemitsIds,
                                                            RemitStatus.UN_POSTED
                                                        )
                                                    }
                                                >
                                                    Mark as Unposted
                                                </Button>
                                            )}
                                            {showCloseRemitsBtn && (
                                                <Button
                                                    variant="secondary"
                                                    onClick={() =>
                                                        handleChangeStatus(
                                                            selectedRemitsIds,
                                                            RemitStatus.POSTED
                                                        )
                                                    }
                                                >
                                                    Mark as Posted
                                                </Button>
                                            )}
                                            {showDeleteManualRemitsBtn && (
                                                <Button
                                                    onClick={() =>
                                                        setDialogInView(
                                                            "delete_remit"
                                                        )
                                                    }
                                                    className="bg-danger hover:bg-danger"
                                                >
                                                    Delete remits
                                                </Button>
                                            )}
                                        </div>
                                    )}
                                <Table
                                    columns={columns}
                                    data={data.data}
                                    handleRowClick={handleRowClick}
                                    setRowSelection={setRowSelection}
                                    rowSelection={rowSelection}
                                    setGetSelectedData={
                                        setGetSelectedRemitsData
                                    }
                                    hasPagination={data.total_count > 100}
                                    isHeaderFixed
                                    sortableColumns={sortableColumns}
                                    sortConfig={sortConfig}
                                    setSortConfig={setSortConfig}
                                    pagination={
                                        <Pagination
                                            totalCount={data.total_count}
                                            totalPages={data.total_pages}
                                            currentPage={pageFilter}
                                            onPageChange={handlePageChange}
                                            limit={limitFilter}
                                            onLimitChange={handleLimitChange}
                                        />
                                    }
                                    className="bg-white"
                                />
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    IApiResponse,
    IPaginatedApiResponse,
} from "../../utils/types/api-response";
import {
    INoteActivities,
    INotesFilterTypes,
    INote,
    ISessionNote,
    IAddendumDetails,
    ISuperviseeNote,
    INoteAuditDahboardResponse,
    IGetClientNotes,
    ClientNoteParams,
} from "../../utils/types/notes";
import {
    fetchNoteActivities,
    fetchAddendums,
    fetchSessionNote,
    fetchNotesList,
    fetchSuperviseeNotesList,
    fetchNoteAuditDashboardList,
    fetchClientsNotes,
} from "../../services/note";
import { handleRemoveEmptyParamFromQueryParams } from "../../utils/helpers";

import { ISuperviseesListFilters } from "../../utils/types/client";

// Fetch notes list hook
export function useFetchNotesList(
    filters: INotesFilterTypes,
    providerId: string,
    enabled?: boolean
) {
    return useQuery<
        IPaginatedApiResponse<INote[]>,
        AxiosError<IApiResponse<INote[]>>
    >({
        queryKey: ["notes", handleRemoveEmptyParamFromQueryParams(filters)],
        queryFn: () => fetchNotesList(filters, providerId),
        enabled,
    });
}

// Function to fetch note messages
export function useFetchClientNotesList(
    clientId: string,
    data: ClientNoteParams
) {
    return useQuery<
        IApiResponse<IGetClientNotes[]>,
        AxiosError<IApiResponse<IGetClientNotes[]>>
    >({
        queryKey: [clientId, `client-notes`, data],
        queryFn: () => fetchClientsNotes(clientId, data),
    });
}

// Fetch supervisee note list hook
export function useFetchSuperviseeNotesList(
    providerId: string,
    filters: ISuperviseesListFilters,
    enabled?: boolean
) {
    return useQuery<
        IPaginatedApiResponse<ISuperviseeNote[]>,
        AxiosError<IApiResponse<ISuperviseeNote[]>>
    >({
        queryKey: [
            "supervisee",
            providerId,
            handleRemoveEmptyParamFromQueryParams(filters),
        ],
        queryFn: () => fetchSuperviseeNotesList(providerId, filters),
        enabled,
    });
}

// Function to fetch note activities
export function useFetchNoteActivities(noteId: string) {
    return useQuery<
        IApiResponse<INoteActivities>,
        AxiosError<IApiResponse<INoteActivities>>
    >({
        queryKey: [noteId, `activity`],
        queryFn: () => fetchNoteActivities(noteId),
    });
}

// Fetch session note
export function useFetchSessionNote(
    clientId: string,
    noteId: string,
    isEnabled: boolean
) {
    return useQuery<
        IApiResponse<ISessionNote>,
        AxiosError<IApiResponse<ISessionNote>>
    >({
        queryKey: [clientId, `session-note`, noteId],
        queryFn: () => fetchSessionNote(clientId, noteId),
        enabled: isEnabled,
    });
}

// Fetch addendum list hook
export function useFetchAddendums(noteId: string, limit: number) {
    return useQuery<
        IPaginatedApiResponse<IAddendumDetails[]>,
        AxiosError<IApiResponse<IAddendumDetails[]>>
    >({
        queryKey: [noteId, `addendum`, limit],
        queryFn: () => fetchAddendums(noteId, limit),
    });
}

// Fetch supervisees notes list hook
export function useFetchSuperviseesNotes(
    supervisorId: string,
    filters: ISuperviseesListFilters
) {
    return useQuery<
        IPaginatedApiResponse<INote[]>,
        AxiosError<IApiResponse<INote[]>>
    >({
        queryKey: ["notes", handleRemoveEmptyParamFromQueryParams(filters)],
        queryFn: () => fetchSuperviseeNotesList(supervisorId, filters),
    });
}

// Hook for getting notes audit dashboard list
export function useFetchNoteAuditDashboardList(
    custom_date: string,
    sort_attribute?: string,
    sort_order?: string
) {
    return useQuery<
        IApiResponse<INoteAuditDahboardResponse>,
        AxiosError<IApiResponse<INoteAuditDahboardResponse[]>>
    >({
        queryKey: [
            "notes-audit-dashboard",
            custom_date,
            sort_attribute,
            sort_order,
        ],
        queryFn: () =>
            fetchNoteAuditDashboardList(
                custom_date,
                sort_attribute,
                sort_order
            ),
    });
}

import * as React from "react";
import { DatePicker, Input, Select } from "@jhool-io/fe-components";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { IAddManualRemit } from "../../types/remits.types";
import { useFetchInsuranceProviders } from "../../../../hooks/queries/client";
import {
    handleFormatDatePickerValue,
    makeStringFirstLetterCapital,
} from "../../../../utils/helpers";
import { useAddManualRemit } from "../../hooks/remits.mutations";
import useToast from "../../../../hooks/useToast";

type SubmitTypes = "save" | "save-continue" | null;

interface Props {
    onFormSubmit: () => void;
    submitType: SubmitTypes;
}

type Options = {
    label: React.ReactNode;
    value: string;
};

const schema = yup.object({
    amount: yup.number().required("Please enter a valid amount"),
    no_of_claims_payments: yup.number().required("Enter number of claims"),
    remit_date: yup.date().required("Remit date is required"),
    insurance_provider_id: yup
        .string()
        .required("Insurance provider is required"),
    remit_batch_payment_ref: yup
        .string()
        .required("Remit payment ref is required"),
});

export default function ManualRemitForm({ onFormSubmit, submitType }: Props) {
    const insuranceProviders = useFetchInsuranceProviders();
    const [remitDate, setRemitDate] = React.useState<Date | null>(null);
    const addManualRemit = useAddManualRemit();

    const queryClient = useQueryClient();
    const navigate = useNavigate();
    // Toast for success and error states
    const { toast } = useToast();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IAddManualRemit>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const formData = new FormData();

    const onSubmit = (data: IAddManualRemit) => {
        formData.append(
            "remit_batch_payment_ref",
            data.remit_batch_payment_ref
        );
        formData.append("insurance_provider_id", data.insurance_provider_id);
        formData.append(
            "no_of_claims_payments",
            String(data.no_of_claims_payments)
        );
        formData.append(
            "remit_date",
            handleFormatDatePickerValue(data.remit_date)
        );
        formData.append("amount", String(data.amount));

        addManualRemit.mutate(formData, {
            onSuccess: (res) => {
                queryClient.invalidateQueries({ queryKey: ["batch-remits"] });
                toast({
                    mode: "success",
                    message: res.message || "Remit added successfully.",
                });
                if (submitType === "save-continue") {
                    navigate(`/remits/${res.remittance_batch_id}/claims`);
                }
                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message || "Could not add remit.",
                });
            },
        });
    };

    const insuranceProvidersForSelect: Options[] = insuranceProviders.data
        ? [
              {
                  value: "",
                  label: <>Choose Insurance</>,
              },
              ...insuranceProviders.data.data
                  .sort((a, b) => a.alias.localeCompare(b.alias))
                  .map((provider) => ({
                      value: provider.insurance_provider_id,
                      label: (
                          <>{makeStringFirstLetterCapital(provider.alias)} </>
                      ),
                  })),
          ]
        : [];

    return (
        <form id="add-remit-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-24 py-12">
                <div>
                    <Input
                        label="Remit Payment Ref"
                        {...register("remit_batch_payment_ref")}
                        hasError={!!errors.remit_batch_payment_ref}
                        errorText={errors.remit_batch_payment_ref?.message}
                        placeholder="Enter remit payment ref"
                    />
                </div>

                <div className="flex flex-col md:flex-row items-center gap-16">
                    <Input
                        label="Remit Amount"
                        {...register("amount")}
                        hasError={!!errors.amount}
                        errorText={
                            errors.amount?.type === "typeError"
                                ? "This field is required and must be a number"
                                : "Amount must be greater than 0"
                        }
                        showCurrency
                        placeholder="$ USD 00"
                    />

                    <div className="basis-[100%] min-w-[100%] md:min-w-max">
                        <Controller
                            name="insurance_provider_id"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    label="Insurance"
                                    wrapperClass="select"
                                    options={insuranceProvidersForSelect}
                                    isDisabled={insuranceProviders.isLoading}
                                    onChange={(val) => {
                                        field.onChange((val as Options).value);
                                    }}
                                    hasError={!!errors.insurance_provider_id}
                                    errorText={
                                        errors.insurance_provider_id?.message
                                    }
                                    isSearchable
                                />
                            )}
                        />
                    </div>
                </div>

                <Input
                    label="No of claims payment"
                    {...register("no_of_claims_payments")}
                    hasError={!!errors?.no_of_claims_payments}
                    placeholder="Enter number of claims payment"
                    type="number"
                    errorText={
                        errors.amount?.type === "typeError"
                            ? "This field is required and must be a number"
                            : "This field is required"
                    }
                />

                <Controller
                    name="remit_date"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            label="Remit received date"
                            placeholderText="MM/DD/YYYY"
                            isClearable
                            onChange={(date) => {
                                field.onChange(date);
                                setRemitDate(date);
                            }}
                            selected={remitDate}
                            hasError={!!errors.remit_date}
                            errorText={
                                errors.remit_date?.type === "typeError"
                                    ? "invalid date value"
                                    : errors.remit_date?.message
                            }
                        />
                    )}
                />
            </div>
        </form>
    );
}

import { SvgProps } from "../../utils/types";

export default function PlusIcon({ stroke }: SvgProps) {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7 2.53125V11.4757"
                stroke={stroke || "white"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.52734 7H11.4718"
                stroke={stroke || "white"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

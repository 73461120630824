import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { Input } from "@jhool-io/fe-components";
import { useSearchParams } from "react-router-dom";
import { IArchiveRemitPayload, IRemit } from "../../types/remits.types";
import { useArchiveRemit } from "../../hooks/remits.mutations";
import useToast from "../../../../hooks/useToast";

interface ArchiveRemitFormProps {
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
    remit: IRemit;
}

// For form validation purposes
const schema = yup.object({
    archive_notes: yup.string(),
});

export default function ArchiveRemitForm({
    onFormSubmit,
    remit,
}: ArchiveRemitFormProps) {
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IArchiveRemitPayload>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const archiveMutation = useArchiveRemit();

    const { toast } = useToast();

    // Query client
    const queryClient = useQueryClient();

    const onSubmit = (payload: IArchiveRemitPayload) => {
        const dataToSend = {
            archive_notes: payload.archive_notes || undefined,
            archive_status: !remit.is_archived,
            remit_ids_to_archive: [remit.remittance_id],
        };

        archiveMutation.mutate(dataToSend, {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: [`remit-claims`],
                });

                searchParams.delete("remit_in_view");

                setSearchParams(searchParams);

                toast({
                    mode: "success",
                    message: res.message || "Remit archive status updated",
                });

                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not update archive status at this time",
                });
            },
        });
    };

    return (
        <form id="archive-remit" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg">
                <Input
                    {...register("archive_notes")}
                    label="Reason"
                    hasError={!!errors.archive_notes}
                    errorText={errors.archive_notes?.message}
                />
            </div>
        </form>
    );
}

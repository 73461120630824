import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IApiResponse } from "../../../../utils/types/api-response";
import {
    ICalculateFeeResponse,
    ISubmitCalculateClientFee,
} from "../../types/tools.types";
import {
    calculateONNClientFees,
    calculatePsychClientFees,
} from "../../services/tools.services";

// Hook for adding new client fees
export function useONNCalculateClientFees() {
    return useMutation<
        IApiResponse<ICalculateFeeResponse[]>,
        AxiosError<IApiResponse<ICalculateFeeResponse[]>>,
        ISubmitCalculateClientFee
    >({
        mutationKey: ["calculate-fee"],
        mutationFn: (data: ISubmitCalculateClientFee) =>
            calculateONNClientFees(data),
    });
}

export function usePsychCalculateClientFees() {
    return useMutation<
        IApiResponse<ICalculateFeeResponse[]>,
        AxiosError<IApiResponse<ICalculateFeeResponse[]>>,
        ISubmitCalculateClientFee
    >({
        mutationKey: ["calculate-fee"],
        mutationFn: (data: ISubmitCalculateClientFee) =>
            calculatePsychClientFees(data),
    });
}

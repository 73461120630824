import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IWeeklySchedule, ScheduleFilters } from "../types/schedule.types";
import { IApiResponse } from "../../../utils/types/api-response";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import { fetchWeeklySchedule } from "../services/schedule.services";

export function useFetchWeeklySchedule(
    params: ScheduleFilters,
    enabled?: boolean
) {
    return useQuery<
        IApiResponse<IWeeklySchedule[]>,
        AxiosError<IApiResponse<IWeeklySchedule[]>>
    >({
        queryKey: [
            `weekly-schedule`,
            handleRemoveEmptyParamFromQueryParams({
                ...params,
            }),
        ],
        queryFn: () => fetchWeeklySchedule(params),
        enabled,
    });
}

export default function EditLightIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <path
                d="M12.6593 1.84131L14.6593 3.84131L13.1347 5.36664L11.1347 3.36664L12.6593 1.84131ZM5.33398 11.1666H7.33398L12.192 6.30864L10.192 4.30864L5.33398 9.16664V11.1666Z"
                fill="#1A738D"
            />
            <path
                d="M12.6667 13.1667H5.43867C5.42133 13.1667 5.40333 13.1733 5.386 13.1733C5.364 13.1733 5.342 13.1673 5.31933 13.1667H3.33333V3.83333H7.898L9.23133 2.5H3.33333C2.598 2.5 2 3.09733 2 3.83333V13.1667C2 13.9027 2.598 14.5 3.33333 14.5H12.6667C13.0203 14.5 13.3594 14.3595 13.6095 14.1095C13.8595 13.8594 14 13.5203 14 13.1667V7.388L12.6667 8.72133V13.1667Z"
                fill="#1A738D"
            />
        </svg>
    );
}

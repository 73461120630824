import { Button } from "@jhool-io/fe-components";
import CheckIcon from "../../components/Icons/Check";
import { cn } from "../../utils/helpers";

interface MobileListItemProps {
    topChildren: React.ReactNode;
    bottomChildren: React.ReactNode;
    showCheckButton?: boolean;
    isSelected?: boolean;
    onClickCheckButton?(): void;
    cardClass?: string;
}

export default function MobileListItem({
    topChildren,
    bottomChildren,
    showCheckButton,
    isSelected,
    onClickCheckButton,
    cardClass,
}: MobileListItemProps) {
    return (
        <div
            className={cn(
                "rounded-r8 p-12 border border-strokelight bg-white",
                cardClass
            )}
        >
            <div className="flex justify-between items-center gap-x-8">
                {topChildren}
                {showCheckButton && (
                    <Button
                        variant="normal"
                        size="auto"
                        onClick={onClickCheckButton}
                        className={cn(
                            "size-20 shrink-0 rounded-[4px] border border-strokelight bg-white flex items-center justify-center",
                            {
                                "bg-primary": isSelected,
                            }
                        )}
                    >
                        <CheckIcon className="size-[10px]" />
                    </Button>
                )}
            </div>
            <div className="rounded-r8 bg-card-bg p-12 mt-16">
                {bottomChildren}
            </div>
        </div>
    );
}

import {
    OfficeRequestStatus,
    RecurrenceRule,
} from "../../modules/schedule/types/schedule.types";

/**
 * Setting Default practice ids for mantle and humantold
 * We should use this in parts of the app where we need to make sure adding new practices
 * does not break what we currently have (e.g pinned clients flow). Locally and on staging the default is mantle's practice id
 * but on prod we default to humantold practice id
 */
export const DEFAULT_PRACTICE_ID =
    import.meta.env.VITE_NODE_ENV === "production"
        ? "501ae593-0c7b-4c7a-89d9-65f4d5e09243"
        : "51f9c194-cc32-48ad-8794-2962ceed8f4b";

export const APP_COLORS = {
    COLOR_PRIMARY: "#1a738d",
    COLOR_PRIMARY_DARK: "#06615d",
    COLOR_SECONDARY: "#e3eaed",
    COLOR_SECONDARY_ALT: "#d6d5c9",
    COLOR_SECONDARY_LIGHT: "#f8f8f6",
    COLOR_TERTIARY: "#4b4e51",
    COLOR_BLACK: "#0b132b",
    COLOR_WHITE: "#ffffff",
    COLOR_GRAY: "#576a81",
    COLOR_DANGER: "#f71735",
    COLOR_WARNING: "#fcb400",
};

export const UNIQUE_CLIENT_EMAIL_REGEX = /^\d{8}__/;
export const UNIQUE_CLIENT_EMAIL_SEPARATOR = "__";

export const LISTS_DEFAULT_LIMIT = 100;
export const LISTS_DEFAULT_PAGE = 1;

export const CPT_CODES = [
    "90847",
    "99449",
    "90837",
    "90834",
    "90846",
    "90832",
    "90791",
    "99448",
];

interface ICptCodesDescription {
    [key: string]: string;
}

export const CPT_CODES_DESCRIPTION: ICptCodesDescription = {
    "90837": "Psychotherapy, 60 minutes (Individual psychotherapy)",
    "90834": " Psychotherapy, 45 minutes (Individual psychotherapy)",
    "90832": "Psychotherapy, 30 minutes (Individual psychotherapy)",
    "90846": "Family psychotherapy without the patient present",
    "90847": "Family psychotherapy with the patient present",
    "90791": "Psychiatric diagnostic evaluation (Initial evaluation)",
    "99449": "Interprofessional consult: 11-20 min telehealth consult",
    "99448": "Interprofessional consult: 5-10 min telehealth consult",
};

export const RECURRENCE_COLORS: { [key: string]: string } = {
    [RecurrenceRule.WEEKLY.toLowerCase()]: "#5F9EA0",
    [RecurrenceRule.BIWEEKLY.toLowerCase()]: "#B8860B",
    "2x/weekly": "#93AACC",
    [RecurrenceRule.TRIWEEKLY.toLowerCase()]: "#F08080",
    [RecurrenceRule.MONTHLY.toLowerCase()]: "#708090",
    [RecurrenceRule.ONE_OFF.toLowerCase()]: "#198988",
    [RecurrenceRule.TWO_MONTHS.toLowerCase()]: "#2867b5",
    [RecurrenceRule.THREE_MONTHS.toLowerCase()]: "#39209e",
};

export const OFFICE_REQUEST_STATUS_COLORS = {
    [OfficeRequestStatus.APPROVED]: "#33cc33",
    [OfficeRequestStatus.REJECTED]: "#cc0000",
    [OfficeRequestStatus.PENDING]: "#669999",
    [OfficeRequestStatus.APPROVED_WITH_CHANGES]: "#708090",
};

export const getColorStylesForCalendarId = (recurrenceRule: string) => {
    switch (recurrenceRule) {
        case RecurrenceRule.WEEKLY:
            return {
                backgroundColor:
                    "linear-gradient(246deg, rgba(172, 222, 250, 0.43) -2.89%, rgba(172, 222, 250, 0.35) 36.64%, rgba(172, 222, 250, 0.20) 64.37%, rgba(172, 222, 250, 0.00) 101.31%)",
                tagBgColor: "#ACDEFA",
                textColor: "#165574",
                color: "#0F96DA",
            };
        case RecurrenceRule.BIWEEKLY:
            return {
                backgroundColor:
                    "linear-gradient(246deg, rgba(199, 250, 167, 0.43) -2.89%, rgba(199, 250, 167, 0.35) 36.64%, rgba(199, 250, 167, 0.20) 73.18%, rgba(199, 250, 167, 0.00) 101.31%)",
                tagBgColor: "#C0F3A1",
                textColor: "#2F6217",
                color: "#6DA951",
            };
        case RecurrenceRule.MONTHLY:
            return {
                backgroundColor:
                    "linear-gradient(246deg, rgba(252, 198, 234, 0.47) -2.89%, rgba(252, 198, 234, 0.35) 36.64%, rgba(252, 180, 228, 0.20) 73.18%, rgba(252, 180, 228, 0.00) 101.31%)",
                tagBgColor: "#F9A4DD",
                textColor: "#62174C",
                color: "#D34CAB",
            };
        case RecurrenceRule.ONE_OFF:
            return {
                backgroundColor:
                    "linear-gradient(246deg, rgba(230, 230, 250, 0.74) -2.89%, rgba(230, 230, 250, 0.74) 36.64%, rgba(230, 230, 250, 0.20) 81.29%, rgba(230, 230, 250, 0.00) 101.31%)",
                tagBgColor: "#D1D5F8",
                textColor: "#2B2B86",
                color: "#7676E6",
            };
        // update background colors when design provides that
        case RecurrenceRule.TRIWEEKLY:
            return {
                backgroundColor: "rgba(35, 209, 96, 0.7)",
                tagBgColor: "#B4B4FA",
                textColor: "#485199",
            };

        case RecurrenceRule.TWO_MONTHS:
            return {
                backgroundColor: "rgba(35, 209, 96, 0.7)",
                tagBgColor: "#D1D5F8",
                textColor: "#485199",
            };
        case RecurrenceRule.THREE_MONTHS:
            return {
                backgroundColor: "rgba(35, 209, 96, 0.7)",
                tagBgColor: "#D1D5F8",
                textColor: "#485199",
            };

        default:
            return {
                backgroundColor:
                    "linear-gradient(246deg, rgba(172, 222, 250, 0.43) -2.89%, rgba(172, 222, 250, 0.35) 36.64%, rgba(172, 222, 250, 0.20) 64.37%, rgba(172, 222, 250, 0.00) 101.31%)",
                tagBgColor: "#ACDEFA",
                textColor: "#165574",
                color: "#0F96DA",
            };
    }
};

/**
 * Note content labels
 * PLEASE DO NOT EDIT UNLESS NECESSARY2
 */

// CANCELLATION NOTES LABELS
export const CANCELLATION_NOTE_LABELS = {
    reason: "Reason Provided By Client for Cancelling",
};

// SUPERVISION NOTES LABELS
export const SUPERVISION_NOTE_LABELS = {
    supervision_note: "Supervision note",
    client_progress: "Client progress",
};

// SUPERVISION NOTES LABELS
export const PAUSE_NOTIFICATION_NOTE_LABELS = {
    pause_reason:
        "Please indicate reason client is currently on pause for treatment",
};

// TERMINATION NOTES LABELS
export const TERMINATION_NOTE_LABELS = {
    appointment_location: "Appointment location",
    delivery_option: "Client Teletherapy Location",
    other_delivery_option: "Specify other client teletherapy location",
    provider_location: "Provider Teletherapy Location",
    other_provider_location: "Specify other provider teletherapy location",
    consent_was_received_for_the_teletherapy_session:
        "Consent was received for the teletherapy session",
    the_teletherapy_session_was_conducted_via_video:
        "The teletherapy session was conducted via video",
    duration: "Duration",
    first_session_date: "First session date",
    last_session_date: "Last session date",
    total_number_of_sessions: "Total number of sessions",
    diagnosis_codes: "Diagnosis",
    presenting_problems: "Presenting problems",
    planned_treatment_and_goals: "Planned treatment and goals",
    course_of_treatment: "Course of treatment",
    patient_final_condition: "Patient final condition",
    prognosis: "Prognosis",
    reason_for_termination: "Reason for termination",
    discharge_plan_and_follow_up: "Discharge plan and follow-up",
};

// TREATMENT REVIEW LABELS
export const TREATMENT_REVIEW_NOTE_LABELS = {
    isClientPresent: "Is client present",
    appointment_location: "Appointment location",
    delivery_option: "Client Teletherapy Location",
    other_delivery_option: "Specify other client teletherapy location",
    provider_location: "Provider Teletherapy Location",
    other_provider_location: "Specify other provider teletherapy location",
    consent_was_received_for_the_teletherapy_session:
        "Consent was received for the teletherapy session",
    the_teletherapy_session_was_conducted_via_video:
        "The teletherapy session was conducted via video",
    duration: "Duration",
    diagnosis_codes: "Diagnosis",
    what_are_the_problem_you_are_seeking_help_for:
        "What are the problem(s) you are seeking help for?",
    anxiety: "Anxiety",
    anxiety_frequency: "Frequency",
    anxiety_details: "Details",
    panic_attacks: "Panic attacks",
    panic_attacks_freqency: "Frequency",
    panic_attacks_details: "Details",
    depression: "Depression",
    depression_freqency: "Frequency",
    depression_details: "Details",
    other_symptoms: "Other symptoms",
    physical_symptoms: "Physical symptoms",
    medication_physical_psychiatric: "Medication physical/psychiatric",
    sleeping_habits_and_concerns: "Sleeping habits and concerns",
    difficulties_with_appetite_or_eating_patterns:
        "Difficulties with appetite or eating patterns",
    been_so_distressed_you_seriously_wished_to_end_your_life:
        "Been so distressed you seriously wished to end your life?",
    has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live:
        "Has anything happened recently to make you feel you don’t want to live?",
    describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live:
        "Describe the situation",
    a_specific_plan_of_how_you_would_kill_yourself:
        "A specific plan of how you would kill yourself?",
    access_to_weapons_or_means_of_hurting_self:
        "Access to weapons or means of hurting self?",
    made_serious_suicide_attempts: "Made serious suicide attempts",
    purposely_done_something_to_hurt_yourself:
        "Purposely done something to hurt yourself?",
    describe_done_something_to_hurt_yourself: "Describe the situation",
    heard_voices_telling_you_to_hurt_yourself:
        "Heard voices telling you to hurt yourself?",
    had_relatives_who_attempted_or_commited_sucide:
        "Had relatives who attempted or commited sucide?",
    had_thoughts_of_killing_or_seriously_hurting_someone:
        "Had thoughts of killing or seriously hurting someone?",
    heard_voices_telling_you_to_hurt_others:
        "Heard voices telling you to hurt others?",
    hurt_someone_or_destroyed_property_on_purpose:
        "Hurt someone or destroyed property on purpose?",
    slapped_kicked_punched_someone_with_intent_to_harm:
        "Slapped, kicked, punched someone with intent to harm?",
    been_arrested_or_detained_for_violent_behavior:
        "Been arrested or detained for violent behavior?",
    been_to_jail_for_any_reason: "Been to jail for any reason?",
    been_on_probation_for_any_reason: "Been on probation for any reason?",
    do_you_have_access_to_guns: "Do you have access to guns?",
    patients_ability_and_capability_to_respond_to_treatment:
        "Patient's ability and capability to respond to treatment",
    details_for_diminished_poor_limited_quality_patient_oriented_evidence:
        "Please provide details for diminished/poor quality patient-orientation",
    people_present_at_session: "Present at session",
    other_person_present_at_session: "Specify other person present",
    type_of_treatment_recommended: "Type of treatment recommended",
    treatment_modality_intervention: "Treatment Modality/Intervention(s)",
    other_treatment_modality_intervention: "Specify Other",
    treatment_goal_one: "1.",
    treatment_goal_two: "2.",
    treatment_goal_three: "3.",
    medication_updates: "Medication updates",
    frequency_of_sessions: "Frequency of sessions",
    other_frequency_of_sessions: "Please specify if other",
    expected_termination_date: "Expected termination date",
    plan_of_care: "Plan of Care",
    prognosis: "Prognosis",
    subjective_report: "Subjective report",
    summary: "Summary",
    selected_symptoms: "Selected Symptoms",
};

// RECORD OF DISCLOSURE LABELS
export const RECORD_OF_DISCLOSURE_NOTE_LABELS = {
    duration: "Duration",
    diagnosis_codes: "Diagnosis",
    disclosure_to_whom: "Disclosure to whom(Address or Fax number)",
    disclosure_type: "Select type",
    purpose_of_disclosure: "Purpose of disclosure",
    type_disclosed: "Type disclosed",
    method_of_disclosure: "Method of disclosure",
};

// SAFETY PLAN LABELS
export const SAFETY_PLAN_NOTE_LABELS = {
    duration: "Duration",
    warning_signs:
        "Warning signs (thoughts, images, mood, situation,behavior) that a crisis may be developing",
    internal_coping_strategies:
        "Internal coping strategies - things I can do to take my mind off my problems without contacting another person (relaxation technique, physical activity)",
    people_and_social_settings_that_provide_distraction:
        "People and social settings that provide distraction",
    people_whom_i_can_ask_for_help: "People whom I can ask for help",
    professionals_or_agencies_i_can_contact_during_a_crisis:
        "Professionals or agencies I can contact during a crisis",
    making_the_environment_safe:
        "Making the environment safe (asking clients which means they would consider during a suicidal crisis and collaboratively identify ways to secure or limit access to lethal means)",
    the_one_thing_that_is_important_to_me_and_worth_living_for_is:
        "The one thing that is important to me and worth living for is",
    how_likely_is_the_client_to_use_this_safety_plan:
        "In the event of an emergency/crisis, how likely is the client to use this safety plan?",
    how_likely_is_the_client_to_adhere_to_this_safety_plan:
        "How likely is the client to adhere to this safety plan?",
    detail_hloc_options_and_next_steps:
        "If client is unlikely to adhere, detail HLOC options and next steps",
    where_will_the_client_keep_the_safety_plan_and_how_will_it_be_located_during_a_crisis:
        "Where will the client keep the safety plan and how will it be located during a crisis?",
    is_the_format_appropriate_for_client_capacity_and_circumstances:
        "Is the format appropriate for client’s capacity and circumstances?",
    when_should_follow_up_be_done_for_this_client:
        "Client review timeline (when should follow up be done for this client)?",
    frequency_of_sessions: "Frequency of sessions",
    other_frequency_of_sessions: "Please specify if other",
    date_of_next_session: "Date of next session",
};

// SOAP PLAN LABELS
export const SOAP_PLAN_NOTE_LABELS = {
    isClientPresent: "Is client present",
    appointment_location: "Appointment location",
    delivery_option: "Client Teletherapy Location",
    other_delivery_option: "Specify other client teletherapy location",
    provider_location: "Provider Teletherapy Location",
    other_provider_location: "Specify other provider teletherapy location",
    consent_was_received_for_the_teletherapy_session:
        "Consent was received for the teletherapy session",
    the_teletherapy_session_was_conducted_via_video:
        "The teletherapy session was conducted via video",
    duration: "Duration",
    diagnosis_codes: "Diagnosis",
    emotional_behavioural_symptoms_demonstrated:
        "Emotional / Behavioural symptoms demonstrated",
    causing: "Causing",
    intention_for_session: "Intention for Session",
    subjective: "Subjective",
    objective: "Objective",
    assessment: "Assessment",
    cognitive_behavioral_therapies: "Cognitive-Behavioral therapies",
    relationship_based_interventions: "Relationship based Interventions",
    other: "Other",
    plan: "Plan",
    frequency_of_sessions: "Frequency of sessions",
    other_frequency_of_sessions: "Please specify if other",
    date_of_next_session: "Date of next session",
    prognosis: "Prognosis",
    client_response: "Client Response",
    goal_one: "Goal one",
    progress_one: "Progress one",
    goal_two: "Goal two",
    progress_two: "Progress two",
    goal_three: "Goal three",
    progress_three: "Progress three",
};

export const INDIVIDUAL_INTAKE_NOTE_DETAILS = {
    appointment_location: "Appointment location",
    delivery_option: "Client Teletherapy Location",
    other_delivery_option: "Specify other client teletherapy location",
    provider_location: "Provider Teletherapy Location",
    other_provider_location: "Specify other provider teletherapy location",
    consent_was_received_for_the_teletherapy_session:
        "Consent was received for the teletherapy session",
    the_teletherapy_session_was_conducted_via_video:
        "The teletherapy session was conducted via video",
    duration: "Duration",
    diagnosis_codes: "Diagnosis",
    gender: "Gender",
    religion: "Religion",
    education: "Education",
    race: "Race",
    ethinicity: "Ethnicity",
    sexual_orientation: "Sexual orientation",
    current_employment: "Current employment",
    current_employment_details: "Current employment details",
    relationship_status: "Relationship status",
    name_of_partner: "Name of partner",
    emergency_contact_information: "Emergency contact information",
    legal_problems: "Legal problems",
    what_are_the_problem_you_are_seeking_help_for:
        "What are the problem(s) you are seeking help for?",
    anxiety: "Anxiety",
    anxiety_frequency: "Frequency",
    anxiety_details: "Details",
    panic_attacks: "Panic attacks",
    panic_attacks_freqency: "Frequency",
    panic_attacks_details: "Details",
    depression: "Depression",
    depression_freqency: "Frequency",
    depression_details: "Details",
    other_symptoms: "Other symptoms",
    selected_symptoms: "Selected symptoms",
    physical_symptoms: "Physical symptoms",
    medication_physical_psychiatric: "Medication physical/psychiatric",
    sleeping_habits_and_concerns: "Sleeping habits and concerns",
    difficulties_with_appetite_or_eating_patterns:
        "Difficulties with appetite or eating patterns",
    parents_stepparents_history: "Parents/Stepparents",
    siblings_history: "Siblings",
    grandparents_history: "Grandparents",
    children_history: "Children",
    other_family_members_history: "Other family members",
    family_members_alcohol_abuse: "Alcohol abuse",
    family_members_anxiety: "Anxiety",
    family_members_bipolar_disorder: "Bipolar Disorder",
    family_members_depression: "Depression",
    family_members_ptsd: "PTSD",
    family_members_schizophrenia: "Schizophrenia",
    family_members_substance_abuse: "Substance use",
    family_members_substance_abuse_substance: "Specify substance",
    trauma_abuse_history: "Trauma/abuse history",
    previous_mental_health_treatment_oupatient:
        "Previous Mental Health Treatment outpatient",
    impatient_psychiatric_hospitalization:
        "Impatient/Psychiatric Hospitalization",
    relevant_medical_history: "Relevant Medical History",
    been_so_distressed_you_seriously_wished_to_end_your_life:
        "Been so distressed you seriously wished to end your life?",
    has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live:
        "Has anything happened recently to make you feel you don’t want to live?",
    describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live:
        "Describe the situation",
    a_specific_plan_of_how_you_would_kill_yourself:
        "A specific plan of how you would kill yourself?",
    access_to_weapons_or_means_of_hurting_self:
        "Access to weapons or means of hurting self?",
    made_serious_suicide_attempts: "Made serious suicide attempts",
    purposely_done_something_to_hurt_yourself:
        "Purposely done something to hurt yourself?",
    describe_done_something_to_hurt_yourself: "Describe the situation",
    heard_voices_telling_you_to_hurt_yourself:
        "Heard voices telling you to hurt yourself?",
    had_relatives_who_attempted_or_commited_sucide:
        "Had relatives who attempted or commited sucide?",
    had_thoughts_of_killing_or_seriously_hurting_someone:
        "Had thoughts of killing or seriously hurting someone?",
    heard_voices_telling_you_to_hurt_others:
        "Heard voices telling you to hurt others?",
    hurt_someone_or_destroyed_property_on_purpose:
        "Hurt someone or destroyed property on purpose?",
    slapped_kicked_punched_someone_with_intent_to_harm:
        "Slapped, kicked, punched someone with intent to harm?",
    been_arrested_or_detained_for_violent_behavior:
        "Been arrested or detained for violent behavior?",
    been_to_jail_for_any_reason: "Been to jail for any reason?",
    been_on_probation_for_any_reason: "Been on probation for any reason?",
    do_you_have_access_to_guns: "Do you have access to guns?",
    mental_health_appearance: "Appearance",
    mental_health_movement: "Movement",
    mental_health_speech_behaviour: "Speech Behavior",
    mental_health_affect_mood: "Affect/Mood",
    mental_health_attention_concentration_effort:
        "Attention/Concentration/effort",
    mental_health_presentation_of_self: "Presentation of self",
    mental_health_cognition:
        "Cognition (decision making, insight, memory, etc)",
    patients_ability_and_capability_to_respond_to_treatment:
        " Patient's ability and capability to respond to treatment",
    details_for_diminished_poor_limited_quality_patient_oriented_evidence:
        "Please provide details for diminished/poor quality patient-orientation",
    people_present_at_session: "Present at session",
    other_person_present_at_session: "Specify Other",
    type_of_treatment_recommended: "Type of treatment recommended",
    treatment_modality_intervention: "Treatment Modality/Intervention(s)",
    other_treatment_modality_intervention: "Specify other treatment/modality",
    frequency_of_sessions: "Frequency of sessions",
    other_frequency_of_sessions: "Please specify if other",
    date_of_next_session: "Date of next session",
    treatment_goal_one: "Treatment goal one",
    treatment_goal_two: "Treatment goal two",
    treatment_goal_three: "Treatment goal three",
    subjective_report: "Subjective report",
    summary: "Summary",
    prognosis: "Prognosis",
    communication_factors_impacting_treatment:
        "Communication Factors impacting treatment",
    details_around_communication_factors_impacting_treatment:
        "Details around communication factors impacting treatment",
    contact_information_for_both_parents:
        "Contact information for both parents",
    name_address_of_personal_physician: "Name/Address of personal physician",
    date_of_last_exam: "Date of last exam",
};

export const FAMILY_INTAKE_NOTE_DETAILS = {
    appointment_location: "Appointment location",
    delivery_option: "Client Teletherapy Location",
    other_delivery_option: "Specify other client teletherapy location",
    provider_location: "Provider Teletherapy Location",
    other_provider_location: "Specify other provider teletherapy location",
    consent_was_received_for_the_teletherapy_session:
        "Consent was received for the teletherapy session",
    the_teletherapy_session_was_conducted_via_video:
        "The teletherapy session was conducted via video",
    duration: "Duration",
    diagnosis_codes: "Diagnosis",

    what_are_the_problem_you_are_seeking_help_for:
        "What are the problem(s) you are seeking help for?",
    people_present_at_session: "Present at session",
    other_person_present_at_session: "Specify Other",
    type_of_treatment_recommended: "Type of treatment recommended",
    treatment_modality_intervention: "Treatment Modality/Intervention(s)",
    other_treatment_modality_intervention: "Specify other treatment/modality",
    treatment_goal_one: "Treatment goal one",
    treatment_goal_two: "Treatment goal two",
    treatment_goal_three: "Treatment goal three",
    subjective_report: "Subjective report",
    summary: "Summary",
    prognosis: "Prognosis",
    communication_factors_impacting_treatment:
        "Communication Factors impacting treatment",
    details_around_communication_factors_impacting_treatment:
        "Details around communication factors impacting treatment",
    frequency_of_sessions: "Frequency of sessions",
    other_frequency_of_sessions: "Please specify if other",
    date_of_next_session: "Date of next session",
};

export const FAMILY_INTAKE_FAMILY_NOTE_DETAILS = {
    name: "Name",
    gender: "Gender",
    religion: "Religion",
    education: "Education",
    race: "Race",
    ethinicity: "Ethnicity",
    sexual_orientation: "Sexual orientation",
    current_employment: "Current employment",
    current_employment_details: "Current employment details",
    relationship_status: "Relationship status",
    name_of_partner: "Name of partner",
    emergency_contact_information: "Emergency contact information",
    legal_problems: "Legal problems",
    anxiety: "Anxiety",
    anxiety_frequency: "Frequency",
    anxiety_details: "Details",
    panic_attacks: "Panic attacks",
    panic_attacks_freqency: "Frequency",
    panic_attacks_details: "Details",
    depression: "Depression",
    depression_freqency: "Frequency",
    depression_details: "Details",
    other_symptoms: "Other symptoms",
    selected_symptoms: "Selected symptoms",
    physical_symptoms: "Physical symptoms",
    medication_physical_psychiatric: "Medication physical/psychiatric",
    sleeping_habits_and_concerns: "Sleeping habits and concerns",
    difficulties_with_appetite_or_eating_patterns:
        "Difficulties with appetite or eating patterns",
    parents_stepparents_history: "Parents/Stepparents",
    siblings_history: "Siblings",
    grandparents_history: "Grandparents",
    children_history: "Children",
    other_family_members_history: "Other family members",
    family_members_alcohol_abuse: "Alcohol abuse",
    family_members_anxiety: "Anxiety",
    family_members_bipolar_disorder: "Bipolar Disorder",
    family_members_depression: "Depression",
    family_members_ptsd: "PTSD",
    family_members_schizophrenia: "Schizophrenia",
    family_members_substance_abuse: "Substance use",
    family_members_substance_abuse_substance: "Specify substance",
    trauma_abuse_history: "Trauma/abuse history",
    previous_mental_health_treatment_oupatient:
        "Previous Mental Health Treatment outpatient",
    impatient_psychiatric_hospitalization:
        "Impatient/Psychiatric Hospitalization",
    relevant_medical_history: "Relevant Medical History",
    been_so_distressed_you_seriously_wished_to_end_your_life:
        "Been so distressed you seriously wished to end your life?",
    has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live:
        "Has anything happened recently to make you feel you don’t want to live?",
    describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live:
        "Describe the situation",
    a_specific_plan_of_how_you_would_kill_yourself:
        "A specific plan of how you would kill yourself?",
    access_to_weapons_or_means_of_hurting_self:
        "Access to weapons or means of hurting self?",
    made_serious_suicide_attempts: "Made serious suicide attempts",
    purposely_done_something_to_hurt_yourself:
        "Purposely done something to hurt yourself?",
    describe_done_something_to_hurt_yourself: "Describe the situation",
    heard_voices_telling_you_to_hurt_yourself:
        "Heard voices telling you to hurt yourself?",
    had_relatives_who_attempted_or_commited_sucide:
        "Had relatives who attempted or commited sucide?",
    had_thoughts_of_killing_or_seriously_hurting_someone:
        "Had thoughts of killing or seriously hurting someone?",
    heard_voices_telling_you_to_hurt_others:
        "Heard voices telling you to hurt others?",
    hurt_someone_or_destroyed_property_on_purpose:
        "Hurt someone or destroyed property on purpose?",
    slapped_kicked_punched_someone_with_intent_to_harm:
        "Slapped, kicked, punched someone with intent to harm?",
    been_arrested_or_detained_for_violent_behavior:
        "Been arrested or detained for violent behavior?",
    been_to_jail_for_any_reason: "Been to jail for any reason?",
    been_on_probation_for_any_reason: "Been on probation for any reason?",
    do_you_have_access_to_guns: "Do you have access to guns?",
    mental_health_appearance: "Appearance",
    mental_health_movement: "Movement",
    mental_health_speech_behaviour: "Speech Behavior",
    mental_health_affect_mood: "Affect/Mood",
    mental_health_attention_concentration_effort:
        "Attention/Concentration/effort",
    mental_health_presentation_of_self: "Presentation of self",
    mental_health_cognition:
        "Cognition (decision making, insight, memory, etc)",
    patients_ability_and_capability_to_respond_to_treatment:
        " Patient's ability and capability to respond to treatment",
    details_for_diminished_poor_limited_quality_patient_oriented_evidence:
        "Please provide details for diminished/poor quality patient-orientation",
};

/**
 * SECTIONS IDS FOR DIFFERENT NOTE TYPES
 */

export const INTAKE_SECTIONS = {
    identity: "identity",
    "presenting concerns": "presenting_concerns",
    symptoms: "symptoms",
    "family history": "family_history",
    "family psychiatric history": "family_psychiatric_history",
    "mental health history": "mental_health_history",
    "suicide risk assessment": "suicide_risk_assessment",
    "mental status exam": "mental_status_exam",
    summary: "summary",
    "treatment goals": "treatment_goals",
};

export const SOAP_NOTE_SECTIONS = {
    "session note": "session_note",
    "therapy intervention techniques": "therapy_intervention_techniques",
    "treatment plan/ progress": "treatment_plan_progress",
};

export const TREATMENT_REVIEW_NOTE_SECTIONS = {
    symptoms: "symptoms",
    "suicide risk assessment": "suicide_risk_assessment",
    summary: "summary",
    "treatment goals": "treatment_goals",
};

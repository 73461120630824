import { Navigate } from "react-router-dom";
import * as React from "react";
import useAuthContext from "../hooks/useAuthContext";

export default function CheckAuth({ children }: { children: JSX.Element }) {
    const { user } = useAuthContext();

    if (!user) {
        return children;
    }

    return <Navigate to={localStorage.getItem("from") || "/"} replace />;
}

import { PAUSE_NOTIFICATION_NOTE_LABELS } from "../../../../utils/constants";
import { IAddendumDetails } from "../../../../utils/types/notes";
import NoteContentItem from "../NoteContentItem/NoteContentItem";

interface ViewPauseNotificationNoteProps {
    noteContent: { [key: string]: string };
    fieldAddendums: {
        [key: string]: IAddendumDetails[];
    } | null;
}

export default function ViewPauseNotificationNote({
    noteContent,
    fieldAddendums,
}: ViewPauseNotificationNoteProps) {
    return (
        <NoteContentItem
            headerText={PAUSE_NOTIFICATION_NOTE_LABELS.pause_reason}
            fieldId="pause_reason"
            content={noteContent.pause_reason || "--"}
            addendums={fieldAddendums?.pause_reason}
        />
    );
}

export default function MoreIcon() {
    return (
        <svg
            width="8"
            height="2"
            viewBox="0 0 8 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.44379 1.55556C1.00824 1.55556 0.666016 1.20556 0.666016 0.777778C0.666016 0.35 1.01602 0 1.44379 0C1.87157 0 2.22157 0.35 2.22157 0.777778C2.22157 1.20556 1.87935 1.55556 1.44379 1.55556Z"
                fill="#0B132B"
            />
            <path
                d="M4.16645 1.55556C3.73089 1.55556 3.38867 1.20556 3.38867 0.777778C3.38867 0.35 3.73867 0 4.16645 0C4.59423 0 4.94423 0.35 4.94423 0.777778C4.94423 1.20556 4.60201 1.55556 4.16645 1.55556Z"
                fill="#0B132B"
            />
            <path
                d="M6.88813 1.55556C6.45257 1.55556 6.11035 1.20556 6.11035 0.777778C6.11035 0.35 6.46035 0 6.88813 0C7.31591 0 7.66591 0.35 7.66591 0.777778C7.66591 1.20556 7.32368 1.55556 6.88813 1.55556Z"
                fill="#0B132B"
            />
        </svg>
    );
}

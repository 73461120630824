import Navbar from "../../../components/Navbar/Navbar";
import BillingSummaryWrapper from "../components/BillingSummaryWrapper/BillingSummaryWrapper";

export default function BillingSummary() {
    return (
        <>
            <Navbar title="Billing Summary" />
            <div className="page">
                <BillingSummaryWrapper />
            </div>
        </>
    );
}

import { SUPERVISION_NOTE_LABELS } from "../../../../utils/constants";
import { IAddendumDetails } from "../../../../utils/types/notes";
import NoteContentItem from "../NoteContentItem/NoteContentItem";

interface ViewSupervisionNoteProps {
    noteContent: { [key: string]: string };
    fieldAddendums: {
        [key: string]: IAddendumDetails[];
    } | null;
}

export default function ViewSupervisionNote({
    noteContent,
    fieldAddendums,
}: ViewSupervisionNoteProps) {
    return (
        <>
            <NoteContentItem
                headerText={SUPERVISION_NOTE_LABELS.supervision_note}
                fieldId="supervision_note"
                content={noteContent.supervision_note || "--"}
                addendums={fieldAddendums?.supervision_note}
            />
            <NoteContentItem
                headerText={SUPERVISION_NOTE_LABELS.client_progress}
                fieldId="client_progress"
                content={noteContent.client_progress || "--"}
                addendums={fieldAddendums?.client_progress}
            />
        </>
    );
}

import SendIcon from "../../../../components/Icons/Send";
import { APP_COLORS } from "../../../../utils/constants";

interface AuthWrapperProps {
    children: React.ReactNode;
}

export default function AuthWrapper({ children }: AuthWrapperProps) {
    return (
        <>
            <div className="md:top-[40px] md:absolute hidden md:block md:right-[40px]">
                <a
                    href="https://docs.google.com/document/d/1BDxPqNJQbg4O8ZOuQ6mM4Q2yj-iUijIBXYC6-6XHjhc/edit?usp=sharing"
                    target="_blank"
                    rel="noreferrer"
                    className="text-sm font-medium flex gap-8 items-center border text-gray border-strokedark rounded-r8 py-12 px-16 h-32"
                >
                    Frequently Asked Questions <SendIcon />
                </a>
            </div>
            <a
                href="https://docs.google.com/document/d/1BDxPqNJQbg4O8ZOuQ6mM4Q2yj-iUijIBXYC6-6XHjhc/edit?usp=sharing"
                target="_blank"
                rel="noreferrer"
                className="md:hidden flex bottom-0 rounded-tl-r8 rounded-tr-r8 bg-white items-center justify-center shadow-[1px_4px_20px_0px_#0b132b14] h-40 w-[79px] right-[12px] absolute text-sm font-semibold text-primary gap-x-8"
            >
                FAQ <SendIcon stroke={APP_COLORS.COLOR_PRIMARY} />
            </a>
            <div className="flex items-center justify-center h-dvh">
                <article className="bg-white shadow-[0px_24px_56px_-4px_#585c5f28] w-[603px] max-w-[calc(100%-24px)] max-h-dvh overflow-y-auto px-16 pt-[56px] pb-[36px] md:p-[64px] rounded-r12">
                    <h1 className="text-primary font-bold text-center text-xl md:text-xlg">
                        MANTLE
                    </h1>
                    <div className="mt-24 md:mt-16">{children}</div>
                </article>
            </div>
        </>
    );
}

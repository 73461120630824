import * as Sentry from "@sentry/react";

const TBD_FEE_REGEX = /[0-9]+(?:\.[0-9]+)?TBD/i;
const TEMP_FEE_REGEX = /[0-9]+(?:\.[0-9]+)?x[0-9]+/i;
const SINGLE_FEE_REGEX = /\b[0-9]+(?:\.[0-9]+)?\b/;

/** Allowed fee formats
   150
   $150TBD
   120x7; $90
   80x5; 15TBD
 */
export const FEE_VALUE_FORMAT_REGEX = /^[A-Z0-9x;$ ]*$/;

type FeeCalculation = {
    singleFee: string;
    tempFee: string;
    tempNoOfSessions: string;
    tbdFee: string;
};

export function calculateFees(fee: string): FeeCalculation | null {
    // Formats:
    // 1. 80x5; $15 TBD
    // 2. 120TBD
    // 3. 25
    // 4. 120x7; $90
    const rawFee = `${fee}`;
    let tbdFee = "";
    let tempNoOfSessions = "";
    let tempFee = "";
    let singleFee = "";
    if (!rawFee) {
        return null;
    }
    try {
        const unspacedFee = rawFee
            .split(" ")
            .map((f) => f.trim())
            .join("");

        let remainingFee = unspacedFee;

        const tbdFees = TBD_FEE_REGEX.exec(remainingFee);
        if (tbdFees) {
            tbdFee = tbdFees[0].replace(/TBD/i, "").replace("$", "");
            remainingFee = remainingFee.replace(tbdFees[0], "");
        }

        const tempFees = TEMP_FEE_REGEX.exec(remainingFee);
        if (tempFees) {
            const [tfee, noOfSessions] = tempFees[0].split(/x/i);
            tempFee = tfee.replace("$", "");
            tempNoOfSessions = noOfSessions;
            remainingFee = remainingFee.replace(tempFees[0], "");
        }

        const singleFees = SINGLE_FEE_REGEX.exec(remainingFee);
        if (singleFees) {
            const [sfee] = singleFees;
            singleFee = sfee.replace("$", "");
        }

        const hasFee = Boolean(singleFee || tempFee || tbdFee);
        if (hasFee) {
            return { singleFee, tempFee, tempNoOfSessions, tbdFee };
        }
        return null;
    } catch (err) {
        Sentry.captureException(err);
    }
    return null;
}

export const getMessageForFees = (inputFee = "") => {
    const fees = calculateFees(inputFee);
    let message = "";
    if (fees) {
        const hasTempFee = fees.tempFee && fees.tempNoOfSessions;
        if (hasTempFee) {
            message += `Client pays $${fees.tempFee}/session for the first ${fees.tempNoOfSessions} sessions `;
        }

        if (fees.singleFee) {
            message += `${hasTempFee ? "then" : "Client pays"} $${
                fees.singleFee
            }/session `;
        }

        if (fees.tbdFee) {
            message += `${hasTempFee ? "then" : "Client pays"} $${
                fees.tbdFee
            }/session until their insurance company responds to our first claim `;
        }
    }

    return message;
};

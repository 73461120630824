import * as React from "react";
import { IAnnouncement } from "../../modules/announcements/types/announcement";

interface AnnouncementContextProps {
    announcements: IAnnouncement[];
    clearedBannerAt: string | null;
    handleClearedAtValue: () => void;
}

export const AnnouncementContext =
    React.createContext<AnnouncementContextProps | null>(null);

import * as React from "react";
import {
    ContentState,
    convertFromRaw,
    convertToRaw,
    EditorState,
    RawDraftEntity,
    RawDraftContentBlock,
} from "draft-js";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import cn from "classnames";
import styles from "./AddTextEditor.module.scss";
import { convertToTextEditorCustomJSON } from "../../../utils/helpers";

interface AddTextEditorProps {
    title?: string;
    titleClass?: string;
    editorClass?: string;
    onEditorTextChange?: (value: string) => void;
    setIsRequiredFieldProvided?: (value: ContentState) => void;
    isRequiredFieldProvided?: boolean | undefined;
    defaultValue?: string;
    reduceToolbaroptions?: boolean;
}

export function AddTextEditor({
    title,
    titleClass,
    editorClass,
    onEditorTextChange,
    setIsRequiredFieldProvided,
    isRequiredFieldProvided,
    defaultValue,
    reduceToolbaroptions,
}: AddTextEditorProps) {
    const defaultContentState = convertToTextEditorCustomJSON(
        defaultValue || ""
    );

    const defaultContentDetails = JSON.parse(defaultContentState);
    const finalDefaultContent = convertFromRaw({
        blocks: defaultContentDetails.blocks as RawDraftContentBlock[],
        entityMap: defaultContentDetails as {
            [key: string]: RawDraftEntity;
        },
    });
    const [editorState, setEditorState] = useState(() =>
        EditorState.createWithContent(finalDefaultContent)
    );

    const [isFocused, setisFocused] = React.useState(false);

    const onSetEditorState = (newState: EditorState) => {
        setEditorState(newState);
    };

    useEffect(() => {
        const data = editorState.getCurrentContent();

        if (onEditorTextChange) {
            onEditorTextChange(JSON.stringify(convertToRaw(data)));
        }
        if (setIsRequiredFieldProvided) {
            setIsRequiredFieldProvided(editorState.getCurrentContent());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorState]);

    return (
        <div>
            {title && (
                <div
                    className={cn(styles.label, titleClass)}
                    aria-label="title"
                >
                    {title}
                </div>
            )}

            <Editor
                toolbarOnFocus
                onFocus={() => setisFocused(true)}
                onBlur={() => setisFocused(true)}
                editorState={editorState}
                wrapperClassName={styles.wrapper_class}
                editorClassName={cn(
                    styles.editor_class,
                    isRequiredFieldProvided
                        ? styles.editor_class
                        : styles.editor_class_not_provided,
                    editorClass
                )}
                toolbarHidden={!isFocused}
                onEditorStateChange={onSetEditorState}
                toolbar={
                    reduceToolbaroptions
                        ? {
                              options: ["inline"],
                              inline: {
                                  options: ["bold", "italic", "underline"],
                              },
                          }
                        : { options: ["inline", "list", "textAlign"] }
                }
            />
        </div>
    );
}

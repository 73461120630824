import { useFetchUserDetails } from "../queries/user";
import { UserPermisions, UserRole } from "../../utils/types/user";
import { useAppSelector } from "../useRedux";

// FUNCTION TO CHECK IF LOGGED IN USER HAS ROLE
export const useDoesUserHaveRole = (roles: UserRole[]) => {
    const loggedInUser = useFetchUserDetails();
    const { practice } = useAppSelector((state) => state.userPractice);

    if (!loggedInUser || !loggedInUser.data || !practice?.roles) {
        // If loggedInUser or loggedInUser.data or loggedInUser.data.roles is not available, return false
        return false;
    }
    // Check if any of the roles passed as an argument is included in the loggedInUser.data.roles
    return roles?.some((role) => practice?.roles?.includes(role));
};

// FUNCTION TO CHECK IF LOGGED IN USER HAS PERMISSION
export const useDoesUserHavePermission = (permissions: UserPermisions[]) => {
    const loggedInUser = useFetchUserDetails();
    const { practice } = useAppSelector((state) => state.userPractice);

    if (!loggedInUser || !loggedInUser.data || !practice?.permissions) {
        // If loggedInUser or loggedInUser.data or loggedInUser.data.permissions is not available, return false
        return false;
    }
    // Check if any of the roles passed as an argument is included in the loggedInUser.data.roles
    return permissions?.some((permission) =>
        practice?.permissions?.includes(permission)
    );
};

export const getRemitsListNavigateUrl = () => {
    const remitsListFiltersFromLS = localStorage.getItem("remitslist_filters");

    if (remitsListFiltersFromLS) {
        return `/remits?${remitsListFiltersFromLS}`;
    }
    return `/remits`;
};

export const getRemitClaimsListNavigateUrl = (remitId: string) => {
    const remitClaimsFiltersFromLS = localStorage.getItem(
        "remitclaimslist_filters"
    );

    if (remitClaimsFiltersFromLS) {
        return `/remits/${remitId}/claims?${remitClaimsFiltersFromLS}`;
    }

    return `/remits/${remitId}/claims`;
};

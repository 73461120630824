export function normalizePhoneNumber(phoneNo: string): string {
    const cleaned = `${phoneNo}`.replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = match[1] ? "+1 " : "";
        const formattedPhoneNo = [
            intlCode,
            "(",
            match[2],
            ") ",
            match[3],
            "-",
            match[4],
        ].join("");
        return formattedPhoneNo;
    }
    return phoneNo;
}

export const PHONE_REGEX = /^[0-9*#()\- +\s]+$/;

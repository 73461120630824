export default function CalendarDaysIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <g clipPath="url(#clip0_31448_17204)">
                <path
                    d="M5.10938 2.94184V1.16406"
                    stroke="#576A81"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.8906 2.94184V1.16406"
                    stroke="#576A81"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.2222 2.94531H3.77778C2.79594 2.94531 2 3.74125 2 4.72309V12.2786C2 13.2605 2.79594 14.0564 3.77778 14.0564H12.2222C13.2041 14.0564 14 13.2605 14 12.2786V4.72309C14 3.74125 13.2041 2.94531 12.2222 2.94531Z"
                    stroke="#576A81"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2 6.05469H14"
                    stroke="#576A81"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.99826 7.82812C7.50849 7.82812 7.10938 8.22724 7.10938 8.71701C7.10938 9.20679 7.50849 9.6059 7.99826 9.6059C8.48804 9.6059 8.88715 9.20679 8.88715 8.71701C8.88715 8.22724 8.48804 7.82812 7.99826 7.82812Z"
                    fill="#576A81"
                />
                <path
                    d="M11.1115 9.6059C11.6013 9.6059 12.0004 9.20679 12.0004 8.71701C12.0004 8.22724 11.6013 7.82812 11.1115 7.82812C10.6218 7.82812 10.2227 8.22724 10.2227 8.71701C10.2227 9.20679 10.6218 9.6059 11.1115 9.6059Z"
                    fill="#576A81"
                />
                <path
                    d="M7.99826 10.5C7.50849 10.5 7.10938 10.8991 7.10938 11.3889C7.10938 11.8787 7.50849 12.2778 7.99826 12.2778C8.48804 12.2778 8.88715 11.8787 8.88715 11.3889C8.88715 10.8991 8.48804 10.5 7.99826 10.5Z"
                    fill="#576A81"
                />
                <path
                    d="M4.88889 10.5C4.39911 10.5 4 10.8991 4 11.3889C4 11.8787 4.39911 12.2778 4.88889 12.2778C5.37867 12.2778 5.77778 11.8787 5.77778 11.3889C5.77778 10.8991 5.37867 10.5 4.88889 10.5Z"
                    fill="#576A81"
                />
                <path
                    d="M11.1115 10.5C10.6218 10.5 10.2227 10.8991 10.2227 11.3889C10.2227 11.8787 10.6218 12.2778 11.1115 12.2778C11.6013 12.2778 12.0004 11.8787 12.0004 11.3889C12.0004 10.8991 11.6013 10.5 11.1115 10.5Z"
                    fill="#576A81"
                />
            </g>
            <defs>
                <clipPath id="clip0_31448_17204">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

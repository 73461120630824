export enum SupportedUploadMethod {
    "dropbox",
    "googledrive",
    "manualupload",
}

export enum ThirdPartyType {
    DROPBOX = "dropbox",
    GOOGLE_DRIVE = "google_drive",
}

export interface IThirdPartyDocument {
    document_url: string;
    third_party_id: string;
    document_name: string;
    third_party_type: ThirdPartyType;
}

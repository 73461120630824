export default function UserMgtIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.99885 15.0846C7.55219 15.0846 7.09885 14.9846 6.74552 14.7779L3.07885 12.6646C1.58552 11.6579 1.49219 11.5046 1.49219 9.92458V6.07125C1.49219 4.49125 1.57885 4.33792 3.04552 3.34458L6.73885 1.21125C7.43885 0.804583 8.53885 0.804583 9.23885 1.21125L12.9189 3.33125C14.4122 4.33792 14.5055 4.49125 14.5055 6.07125V9.91792C14.5055 11.4979 14.4189 11.6513 12.9522 12.6446L9.25885 14.7779C8.89885 14.9846 8.44552 15.0846 7.99885 15.0846ZM7.99885 1.91125C7.71885 1.91125 7.44552 1.96458 7.25219 2.07792L3.58552 4.19792C2.49885 4.93125 2.49885 4.93125 2.49885 6.07125V9.91792C2.49885 11.0579 2.49885 11.0579 3.61219 11.8113L7.25219 13.9113C7.64552 14.1379 8.35885 14.1379 8.75219 13.9113L12.4189 11.7913C13.4989 11.0579 13.4989 11.0579 13.4989 9.91792V6.07125C13.4989 4.93125 13.4989 4.93125 12.3855 4.17792L8.74552 2.07792C8.55219 1.96458 8.27885 1.91125 7.99885 1.91125Z"
                fill="#576A81"
            />
            <path
                d="M8 10.5C6.62 10.5 5.5 9.38 5.5 8C5.5 6.62 6.62 5.5 8 5.5C9.38 5.5 10.5 6.62 10.5 8C10.5 9.38 9.38 10.5 8 10.5ZM8 6.5C7.17333 6.5 6.5 7.17333 6.5 8C6.5 8.82667 7.17333 9.5 8 9.5C8.82667 9.5 9.5 8.82667 9.5 8C9.5 7.17333 8.82667 6.5 8 6.5Z"
                fill="#576A81"
            />
        </svg>
    );
}

import Navbar from "../../../components/Navbar/Navbar";
import ThirtydaysProvidersList from "../components/ThirtydaysProvidersList/ThirtydaysProvidersList";

export default function ThirtydaysProviders() {
    return (
        <>
            <Navbar title="Thirty days Providers" />
            <div className="page">
                <ThirtydaysProvidersList />
            </div>
        </>
    );
}

import { SvgProps } from "../../utils/types";

export default function FileExportIcon({ className }: SvgProps) {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g clipPath="url(#clip0_5552_2141)">
                <path
                    opacity="0.4"
                    d="M11.3333 11.0016V15.2538C11.3331 15.352 11.3146 15.4492 11.2789 15.5399C11.2432 15.6306 11.1909 15.7129 11.1251 15.7822C11.0594 15.8515 10.9813 15.9064 10.8955 15.9438C10.8097 15.9811 10.7178 16.0002 10.625 16H0.704794C0.612007 15.9998 0.520174 15.9802 0.434539 15.9423C0.348903 15.9045 0.271143 15.8492 0.205697 15.7796C0.140251 15.7099 0.088401 15.6273 0.0531078 15.5364C0.0178147 15.4456 -0.000230616 15.3482 2.22484e-06 15.25V0.746252C0.000234483 0.648007 0.0187403 0.550772 0.0544632 0.4601C0.090186 0.369427 0.142426 0.287092 0.208201 0.217797C0.273976 0.148501 0.351997 0.093601 0.437811 0.0562318C0.523624 0.0188626 0.615548 -0.000244182 0.708336 2.35572e-06H7.55556V3.5C7.55556 3.63261 7.60531 3.75979 7.69387 3.85356C7.78243 3.94732 7.90254 4 8.02778 4H11.3333V9.00156H6.13889C6.01365 9.00156 5.89354 9.05424 5.80498 9.14801C5.71642 9.24178 5.66667 9.36896 5.66667 9.50156V10.5016C5.66667 10.6342 5.71642 10.7614 5.80498 10.8551C5.89354 10.9489 6.01365 11.0016 6.13889 11.0016H11.3333Z"
                    fill="#576A81"
                />
                <path
                    d="M8.0281 4H11.3337V3.80938C11.3337 3.7113 11.3154 3.61417 11.28 3.52356C11.2445 3.43294 11.1926 3.35061 11.1271 3.28125L8.23765 0.218752C8.1717 0.149165 8.09344 0.0940266 8.00735 0.0564914C7.92126 0.0189562 7.82902 -0.00023949 7.73592 2.25522e-06H7.55588V3.5C7.55588 3.63261 7.60563 3.75979 7.69419 3.85356C7.78275 3.94732 7.90286 4 8.0281 4ZM16.8528 9.62656L14.0309 6.61094C13.7328 6.29531 13.222 6.51719 13.222 6.96407V9.00156H6.13921C6.01397 9.00156 5.89386 9.05424 5.8053 9.14801C5.71674 9.24178 5.66699 9.36896 5.66699 9.50156V10.5016C5.66699 10.6342 5.71674 10.7613 5.8053 10.8551C5.89386 10.9489 6.01397 11.0016 6.13921 11.0016H13.2205V13.0359C13.2205 13.4828 13.7314 13.7047 14.0295 13.3891L16.8542 10.3766C16.9478 10.2768 17.0001 10.1418 16.9998 10.0012C16.9996 9.86066 16.9467 9.72593 16.8528 9.62656Z"
                    fill="#576A81"
                />
            </g>
            <defs>
                <clipPath id="clip0_5552_2141">
                    <rect width="17" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

import * as React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import { UserRole } from "../../user-management/types/user-management.types";
import ClinicianViewWrapper from "../components/ClinicianSchedule/ClinicianViewWrapper/ClinicianViewWrapper";
import BillerScheduleWrapper from "../components/BillerSchedule/BillerScheduleWrapper/BillerScheduleWrapper";
import { useAppSelector } from "../../../hooks/useRedux";

export default function Schedule() {
    const { practice } = useAppSelector((state) => state.userPractice);
    return (
        <>
            <Navbar title="Schedule" />
            <div className="page">
                <div>
                    {(practice?.roles?.includes(UserRole.BILLER) ||
                        practice?.roles?.includes(UserRole.BILLER_ADMIN) ||
                        practice?.roles?.includes(UserRole.SUPER_ADMIN)) && (
                        <BillerScheduleWrapper />
                    )}
                    {practice?.roles?.includes(UserRole.PROVIDER) && (
                        <ClinicianViewWrapper />
                    )}
                </div>
            </div>
        </>
    );
}

import { ProviderCredential } from "../../clients/types/clients.types";
import { SessionServiceType } from "../../notes/types/notes.types";
import { RecurrenceRule } from "../../schedule/types/schedule.types";

export enum ProviderStatus {
    ACTIVE = "active",
    INACTIVE = "inactive",
}

export enum ProviderRole {
    SUPERVISOR = "supervisor",
    SUPERVISEE = "supervisee",
}

export interface IProviderCaseload {
    client: {
        first_name: string;
        last_name: string;
        pronouns: string;
        age: number;
        email: string;
        preferred_name: string;
        contact: string;
        client_id: string;
        insurance_provider_name?: string;
        self_pay?: boolean;
        is_paused: boolean;
        pending_task: boolean;
    };
    appointments: {
        session_day: string;
        session_start_hr: number;
        session_start_min: number;
        session_end_hr: number;
        session_end_min: number;
        recurrence_rule: RecurrenceRule;
        session_type: SessionServiceType;
        session_history_id?: string;
    }[];
}

export interface IProviderCaseloadFilters {
    providerId: string;
    search_string?: string;
    show_paused_client?: string;
    show_clients_with_pending_tasks?: string;
    enabled?: boolean;
}

export interface ISuperviseeCaseload {
    provider_id: string;
    first_name: string;
    last_name: string;
    pronouns: string;
    credential: ProviderCredential;
    email: string;
    preferred_name: string;
    contact: string;
    appointments: number;
    active_clients: number;
    paused_clients: number;
    delivery_option: string[];
    pending_notes: number;
    npi?: string;
}
export interface IProvider {
    provider_id: string;
    supervisor_id: string;
    email: string;
    provider_contact_number: string;
    no_of_active_clients: number;
    no_of_supervisees: number;
    is_supervisor: boolean;
    provider_name: string;
    status: ProviderStatus;
    credential: ProviderCredential;
    started_practicing: string | null;
    supervisor_name: string | null;
    user_id: string;
    npi?: string;
}

export interface IProvidersFilterTypes {
    page?: number;
    search_string?: string;
    status?: string;
    credential?: string;
    role?: string;
    limit?: number;
    provider_id?: string;
    sort_attribute?: string;
    sort_order?: string;
}

export interface IEditProviderInfo {
    npi?: string;
}

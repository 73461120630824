import * as React from "react";
import {
    Button,
    DatePicker,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Tag,
} from "@jhool-io/fe-components";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import InboxIcon from "../../../../components/Icons/Inbox";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import { APP_COLORS } from "../../../../utils/constants";
import { useFetchNotesList } from "../../../../hooks/queries/note";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import {
    NoteLabels,
    NoteStatus,
    NoteTypes,
    noteTypesForExport,
} from "../../../../utils/types/notes";
import {
    convertDateFilterStringToDate,
    formatDate,
    isSmallScreen,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    truncateString,
} from "../../../../utils/helpers";
import ListState from "../../../../components/ListState/ListState";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import { useExportNotes } from "../../../../hooks/mutations/note";
import useToast from "../../../../hooks/useToast";
import { useExportPdfs } from "../../../../hooks/queries";
import usePractice from "../../../../hooks/usePractice";

interface SessionNotesProps {
    clientId: string;
}

export default function SessionNotes({ clientId }: SessionNotesProps) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [jobId, setJobId] = React.useState<string | null>(null);
    const [showLoader, setShowLoader] = React.useState(false);

    const { practice } = usePractice();

    const noteTypeFilter = searchParams.get("note_type") || "";
    const archiveFilter = searchParams.get("archive") || "false";
    const fromDateFilter = searchParams.get("from_date");
    const toDateFilter = searchParams.get("to_date");

    const { data, isLoading, error, isSuccess } = useFetchNotesList(
        {
            client_id: clientId,
            type: noteTypeFilter ? [noteTypeFilter] : undefined,
            show_archived: archiveFilter,
            from_date: fromDateFilter,
            to_date: toDateFilter,
            status: [
                NoteStatus.CHANGES_REQUESTED,
                NoteStatus.CODED,
                NoteStatus.DRAFT,
                NoteStatus.PENDING_CODE_REVIEW,
                NoteStatus.PENDING_SUPERVISOR_REVIEW,
            ],
        },
        practice?.provider_id || ""
    );

    const fromDateFilterToDate = convertDateFilterStringToDate(fromDateFilter);
    const toDateFilterToDate = convertDateFilterStringToDate(toDateFilter);

    const exportNotes = useExportNotes();

    const { toast } = useToast();

    const handleExportNotes = (id: string) => {
        const dataToSend = {
            session_note_ids: [id],
        };

        exportNotes.mutate(dataToSend, {
            onSuccess: async (res) => {
                setShowLoader(true);
                await new Promise<void>((resolve) => {
                    const timer = setTimeout(() => {
                        clearTimeout(timer);
                        setJobId(res.data.export_job_id);
                        resolve();
                    }, 8000);
                });
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.message || "Could not export notes at this time",
                });
            },
        });
    };

    const exportNote = useExportPdfs(
        {
            export_job_id: jobId as string,
            pdf_type: "session_note",
            expected_pdf_count: 1,
        },
        Boolean(jobId)
    );

    if (jobId && !exportNote.isLoading) {
        setJobId(null);
        setShowLoader(false);
    }

    const noteTypeOptions = [
        {
            value: "",
            label: "All",
        },
        ...Object.values(NoteTypes).map((type) => ({
            value: type,
            label: NoteLabels[type],
        })),
    ];

    const handleNoteTypeFilter = (type: string) => {
        if (type === "") searchParams.delete("note_type");
        else searchParams.set("note_type", type);
        setSearchParams(searchParams);
    };

    const handleArchiveTypeFilter = (value: string) => {
        searchParams.set("archive", value);
        setSearchParams(searchParams);
    };

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const getNoteTagStyle = (status: NoteStatus) => {
        let bgColor: string;
        let textColor: string;

        switch (status) {
            case NoteStatus.CODED:
                bgColor = "#CCFAE9";
                textColor = "#00563E";
                break;
            case NoteStatus.DRAFT:
                bgColor = "#F0F0E5";
                textColor = "#212121";
                break;
            case NoteStatus.CHANGES_REQUESTED:
                bgColor = "#ACDEFA";
                textColor = "#165574";
                break;
            case NoteStatus.PENDING_CODE_REVIEW:
                bgColor = "#F7E5A4";
                textColor = "#634D17";
                break;
            case NoteStatus.PENDING_SUPERVISOR_REVIEW:
                bgColor = "#F7E5A4";
                textColor = "#634D17";
                break;
            default:
                bgColor = "white";
                textColor = "1px solid black";
        }

        return (
            <Tag
                textColor={textColor}
                bgColor={bgColor}
                title={makeStringFirstLetterCapital(
                    removeEnumUnderscore(status)
                )}
                className="px-8 py-4 text-xs font-regular"
            />
        );
    };

    return (
        <>
            <Dialog open={showLoader}>
                <DialogContent
                    title="Exporting note"
                    variant="center"
                    showFooter={false}
                >
                    <div className="loader">
                        <div className="loader-icon" />
                        <div className="loader-text">
                            Please wait while the export is in progress.
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <div className="border-b border-b-strokedark flex items-center justify-end py-16 mb-32">
                <div className="flex gap-x-12 w-auto">
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <Button
                                size="auto"
                                variant="normal"
                                disabled={Boolean(error) || isLoading}
                                className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium"
                            >
                                {isLoading && "Loading.."}
                                {error && "Error loading providers"}
                                {data?.data && (
                                    <>
                                        {noteTypeFilter
                                            ? makeStringFirstLetterCapital(
                                                  truncateString(
                                                      removeEnumUnderscore(
                                                          noteTypeFilter
                                                      )
                                                  )
                                              )
                                            : "Note type"}
                                        <ChevronDownIcon
                                            stroke={APP_COLORS.COLOR_BLACK}
                                        />
                                    </>
                                )}
                            </Button>
                        </DropdownTrigger>
                        <DropdownContent
                            align={isSmallScreen() ? "start" : "end"}
                            width={200}
                            maxHeight={216}
                            className="overflow-y-auto"
                        >
                            {noteTypeOptions.map((type) => (
                                <DropdownItem
                                    className="text-xs font-medium flex items-center gap-x-8"
                                    key={type.value}
                                    onClick={() =>
                                        handleNoteTypeFilter(type.value)
                                    }
                                >
                                    {type.label}
                                    {noteTypeFilter === type.value ? (
                                        <CheckPrimaryColorIcon />
                                    ) : null}
                                </DropdownItem>
                            ))}
                        </DropdownContent>
                    </Dropdown>
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <Button
                                size="auto"
                                variant="normal"
                                disabled={Boolean(error) || isLoading}
                                className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium"
                            >
                                {archiveFilter === "true"
                                    ? "Archived"
                                    : "Unarchived"}
                                <ChevronDownIcon
                                    stroke={APP_COLORS.COLOR_BLACK}
                                />
                            </Button>
                        </DropdownTrigger>
                        <DropdownContent
                            align={isSmallScreen() ? "start" : "end"}
                            width={200}
                            maxHeight={216}
                            className="overflow-y-auto"
                        >
                            <DropdownItem
                                className="text-xs font-medium flex items-center gap-x-8"
                                onClick={() => handleArchiveTypeFilter("true")}
                            >
                                Archived notes
                                {archiveFilter === "true" ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                            <DropdownItem
                                className="text-xs font-medium flex items-center gap-x-8"
                                onClick={() => handleArchiveTypeFilter("false")}
                            >
                                Unarchived notes
                                {archiveFilter === "false" ? (
                                    <CheckPrimaryColorIcon />
                                ) : null}
                            </DropdownItem>
                        </DropdownContent>
                    </Dropdown>
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant="normal"
                                size="auto"
                                className="px-16 shadow-active border-[0.2px] border-strokedark gap-x-8 text-sm data-[state=open]:border-primary"
                            >
                                Date
                                <ChevronDownIcon
                                    stroke={APP_COLORS.COLOR_BLACK}
                                />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent
                            align="end"
                            className="h-[169px] p-16 z-[50]"
                        >
                            <div className="flex flex-col gap-y-[14px]">
                                <DatePicker
                                    label="From"
                                    placeholderText="MM/DD/YYYY"
                                    selected={fromDateFilterToDate}
                                    onChange={handleFromDateFilterChange}
                                    maxDate={
                                        toDateFilterToDate ||
                                        new Date(Date.now())
                                    }
                                    isClearable
                                />
                                <DatePicker
                                    label="To"
                                    placeholderText="MM/DD/YYYY"
                                    selected={toDateFilterToDate}
                                    onChange={handleToDateFilterChange}
                                    minDate={fromDateFilterToDate}
                                    isClearable
                                />
                            </div>
                        </PopoverContent>
                    </Popover>
                </div>
            </div>
            {isLoading &&
                [1, 2, 3, 4].map((item) => (
                    <div
                        key={item}
                        className="flex flex-col gap-y-12 mb-32 last:mb-0"
                    >
                        <div className="flex items-center justify-between">
                            <Skeleton
                                containerTestId="loader"
                                height={20}
                                width="15%"
                            />
                            <Skeleton
                                containerTestId="loader"
                                height={20}
                                width="10%"
                            />
                        </div>
                        <Skeleton
                            containerTestId="loader"
                            height={20}
                            width="25%"
                        />
                        <Skeleton
                            containerTestId="loader"
                            height={30}
                            width="10%"
                        />
                    </div>
                ))}
            {error && error?.response?.status !== 404 && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display notes list at this time please try again later`
                    }
                />
            )}
            {data && isSuccess && data.data.length === 0 && (
                <ListState
                    stateHelperText="Notes list will appear here once they are added"
                    isError={false}
                    emptyMessage="No note added yet"
                />
            )}
            {isSuccess &&
                data &&
                data.data.length > 0 &&
                data.data.map((note) => {
                    return (
                        <div
                            key={note.note_id}
                            className="flex items-start justify-between pb-24"
                        >
                            <div className="flex flex-col w-1/2 relative items-start before:content-[''] before:size-8 before:rounded-full before:bg-gray after:content-[''] before:absolute before:top-2 before:left-0 after:bg-strokelight after:w-px after:h-full after:absolute after:left-[3px] after:top-[13px] pl-24">
                                <span className="text-sm font-bold mb-12">
                                    {makeStringFirstLetterCapital(
                                        removeEnumUnderscore(note.note_type)
                                    )}
                                </span>
                                <span className="mb-8 text-gray font-normal text-xs">
                                    {`Date of service: ${formatDate(
                                        note.date_of_service
                                    )}`}
                                </span>
                                {getNoteTagStyle(note.status)}
                            </div>
                            {noteTypesForExport.includes(note.note_type) &&
                            note.status !== NoteStatus.DRAFT ? (
                                <Button
                                    variant="normal"
                                    size="auto"
                                    className="mt-[9px] justify-end flex gap-x-8 items-center"
                                    onClick={() =>
                                        handleExportNotes(note.note_id)
                                    }
                                    disabled={exportNotes.isLoading}
                                >
                                    <InboxIcon />
                                    Export to PDF
                                </Button>
                            ) : null}
                        </div>
                    );
                })}
        </>
    );
}

import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import {
    Button,
    Card,
    Dialog,
    DialogContent,
    Tag,
} from "@jhool-io/fe-components";

import {
    useFetchClientBasicAndPersonalInfo,
    useFetchClientCreditCards,
} from "../../../../../hooks/queries/client";
import useCreditCardInput from "../../../../../hooks/useCreditCard";
import { copyToClipboard } from "../../../../../utils/helpers/copy";
import AddCardDetails from "../AddCardDetails/AddCardDetails";
import { UserPermisions } from "../../../../../utils/types/user";
import { useDoesUserHavePermission } from "../../../../../hooks/permissions";
import CardTransactionHistory from "./CardTransactionHistory/CardTransactionHistory";
import useToast from "../../../../../hooks/useToast";
import AddCardLabelForm from "./AddCardLabelForm/AddCardLabelForm";
import Tooltip from "../../../../../components/Tooltip/Tooltip";
import InfoCircleIcon from "../../../../../components/Icons/InfoCircle";
import AddIcon from "../../../../../components/Icons/AddIcon";
import DocumentCopyIcon from "../../../../../components/Icons/DocumentCopy";
import EditLightIcon from "../../../../../components/Icons/EditLight";
import ListState from "../../../../../components/ListState/ListState";
import Skeleton from "../../../../../components/Skeleton/Skeleton";
import { useValidateCard } from "../../../hooks/clients.mutations";
import {
    ClientCardTypes,
    PaymentCardStatus,
} from "../../../types/clients.types";

export default function CreditCards() {
    // Component local states
    const [showAddCardModal, setShowAddCardModal] = React.useState(false);
    const [cardToValidate, setCardToValidate] = React.useState<string | null>(
        null
    );
    const [searchParams, setSearchParams] = useSearchParams();
    const [cardPaymentID, setCardPaymentID] = React.useState("");
    const [showAddLabel, setShowAddLabel] = React.useState(false);
    const [isIDCopied, setIsIDCopied] = React.useState(false);

    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;

    // Fetch client credit cards
    const { data, isLoading, error, isSuccess } =
        useFetchClientCreditCards(clientId);

    const defaultFormValue = data?.data.find(
        (card) => card.payment_card_id === cardPaymentID
    )?.card_label;
    // fetch client merchant Id

    // check if logged in user can add payment cards
    const canAddCard = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
        UserPermisions.CLIENT_INFO_ALL_WRITE,
        UserPermisions.CLIENT_INFO_PAYMENTS_WRITE,
        UserPermisions.CLIENT_INFO_FEES_WRITE,
    ]);

    const clientPersonalInfo = useFetchClientBasicAndPersonalInfo(clientId);

    const isMutating = useIsMutating(["add-card", clientId]);

    const isAddLabelMutating = useIsMutating(["add-card-label", cardPaymentID]);

    const { cardImages } = useCreditCardInput();

    const validateCard = useValidateCard(clientId, cardToValidate || "");

    const { toast } = useToast();

    // Function for toggling opening and closing modal
    const handleToggleShowAddCardModal = () => {
        setShowAddCardModal((state) => !state);
    };

    const handleCardToValidate = (id: string) => {
        setCardToValidate(id);
    };

    const [showTransactionHistory, setShowTransactionHistory] =
        React.useState(false);

    const handlePolicyModalToggle = () => {
        setShowTransactionHistory((state) => !state);
        searchParams.delete("transaction_limit");
        searchParams.delete("transaction_page");
        setSearchParams(searchParams);
    };

    // Query client
    const queryClient = useQueryClient();

    React.useEffect(() => {
        if (cardToValidate !== null) {
            validateCard.mutate(null, {
                onSuccess(res) {
                    queryClient.invalidateQueries({
                        queryKey: [clientId, `cards`],
                    });
                    toast({
                        mode: "success",
                        message: res.message || "Card validated successfully",
                    });
                },
                onError(err) {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not validate card at this time, please try again",
                    });
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardToValidate]);

    // Function to determine credit card provider icon to display
    const getCreditCardIconToDisplay = (cardType: string) => {
        switch (cardType) {
            case ClientCardTypes.AMERICAN_EXPRESS:
                return cardImages.amex;

            case ClientCardTypes.MASTERCARD:
                return cardImages.mastercard;

            case ClientCardTypes.DISCOVER_FINANCIAL:
                return cardImages.discover;

            case ClientCardTypes.CHASE:
                return cardImages.chase;

            case ClientCardTypes.JCB:
                return cardImages.jcb;

            case ClientCardTypes.CAPITAL_ONE:
                return cardImages.capitalone;

            case ClientCardTypes.VISA:
                return cardImages.visa;

            case ClientCardTypes.DINERSCLUB:
                return cardImages.dinersclub;

            default:
                return cardImages.capitalone;
        }
    };

    const listHeader = (
        <div>
            <div className="flex justify-between p-24">
                <div className="flex items-center gap-x-24  max-[768px]:flex-col max-[768px]:items-start">
                    <h3 className="text-base font-bold max-[768px]:pb-8">
                        Credit Card Info
                    </h3>
                    <div className="flex items-center max-[768px]:flex-col max-[768px]:items-start">
                        <p className="text-sm font-medium  max-[768px]:pb-8">
                            {" "}
                            Authorize.net Customer ID -{" "}
                        </p>
                        <div className="flex gap-x-8">
                            <span className="text-primary text-sm font-medium pl-[4px] max-[768px]:pl-0">
                                {" "}
                                {
                                    clientPersonalInfo?.data?.data
                                        ?.merchant_client_id
                                }
                            </span>
                            <Tooltip
                                showArrow
                                trigger="hover"
                                className="w-[25rem] bg-[#0B132B] text-white text-xs font-normal p-10"
                                content={
                                    <>
                                        <p>
                                            Use this ID to link the Customer
                                            profile on Authorize.net with the
                                            Client chart on Mantle
                                        </p>
                                        <div className=" text-sm text-primary mt-[1rem] underline">
                                            <a
                                                href="https://docs.google.com/document/d/16Sl2vNO7U4Mzk8HGCbN1mDf--awHw0vT_pkxNBcfr3U/edit?usp=sharing"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Learn More
                                            </a>
                                        </div>
                                    </>
                                }
                            >
                                <Button size="auto" variant="normal">
                                    <InfoCircleIcon
                                        stroke="#0B132B"
                                        fill="#0B132B"
                                    />
                                </Button>
                            </Tooltip>
                            <Button
                                onClick={() => {
                                    copyToClipboard(
                                        clientPersonalInfo?.data?.data
                                            ?.merchant_client_id || ""
                                    );
                                    setIsIDCopied(!isIDCopied);
                                    toast({
                                        mode: "success",
                                        message: "ID copied!",
                                        duration: 4000,
                                    });
                                }}
                                size="auto"
                                variant="normal"
                                type="button"
                            >
                                <DocumentCopyIcon className="h-[1.3rem] w-[1.2rem]" />
                            </Button>
                        </div>
                    </div>
                </div>

                <div>
                    {!error &&
                        canAddCard &&
                        !(isSuccess && data.data.length === 0) && (
                            <>
                                <Button
                                    className="hidden sm:block"
                                    variant="primary"
                                    size="small"
                                    type="button"
                                    aria-label="add card button"
                                    onClick={handleToggleShowAddCardModal}
                                >
                                    Add card
                                </Button>
                                <Button
                                    variant="normal"
                                    type="button"
                                    size="auto"
                                    aria-label="add card icon"
                                    className=" flex sm:hidden !rounded-full bg-[rgba(87,_106,_129,_0.05)] h-32 w-32"
                                    onClick={handleToggleShowAddCardModal}
                                >
                                    <AddIcon className=" w-6 h-6" />
                                </Button>
                            </>
                        )}
                </div>
            </div>
        </div>
    );

    const handleViewTransactionHistory = (paymentId: string) => {
        handlePolicyModalToggle();
        setCardPaymentID(paymentId);
        searchParams.set("payment_id", paymentId);
        setSearchParams(searchParams);
    };

    return (
        <>
            <Dialog open={showAddCardModal}>
                <DialogContent
                    variant="center"
                    saveText="Add card"
                    cancelText="Cancel"
                    title="Add card"
                    handleCloseDialog={handleToggleShowAddCardModal}
                    submitBtnFormValue="add-card"
                    isSubmitBtnDisabled={isMutating > 0}
                    isCancelBtnDisabled={isMutating > 0}
                >
                    <AddCardDetails
                        onFormSubmit={handleToggleShowAddCardModal}
                    />
                </DialogContent>
            </Dialog>

            <Dialog open={showAddLabel}>
                <DialogContent
                    handleCloseDialog={() => {
                        setShowAddLabel(false);
                    }}
                    showFooter
                    title="Edit Label"
                    variant="center"
                    classNames="w-auto"
                    submitBtnFormValue="add-card-label"
                    wrapperClass="w-[572px]"
                    isSubmitBtnDisabled={isAddLabelMutating > 0}
                    isCancelBtnDisabled={isAddLabelMutating > 0}
                >
                    <AddCardLabelForm
                        onFormSubmit={() => setShowAddLabel(false)}
                        cardPaymentID={cardPaymentID}
                        cardLabel={defaultFormValue || ""}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={showTransactionHistory}>
                <DialogContent
                    handleCloseDialog={handlePolicyModalToggle}
                    showFooter={false}
                    classNames="w-auto"
                    wrapperClass="w-[1186px]"
                >
                    <CardTransactionHistory />
                </DialogContent>
            </Dialog>
            {isLoading && (
                <Card>
                    <div>
                        <div className="flex justify-between items-center mb-[1rem] basis-[10%]">
                            <Skeleton
                                containerTestId="cards-loader"
                                height="20px"
                            />{" "}
                            <Skeleton
                                containerTestId="cards-loader"
                                height="40px"
                            />
                        </div>
                        <div className="flex flex-col w-[70%]">
                            <Skeleton
                                containerTestId="cards-loader"
                                height="40px"
                                count={2}
                            />
                        </div>
                    </div>
                </Card>
            )}
            {error && error?.response?.status !== 404 && (
                <ListState
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display client credit cards at this time please try again later`
                    }
                    stateHelperText="Try reloading this page to solve this issue"
                    isError
                    listHeader={listHeader}
                />
            )}
            {data && isSuccess && data.data.length === 0 && (
                <ListState
                    stateHelperText="Client credit cards will appear here when added"
                    isError={false}
                    listHeader={listHeader}
                    emptyMessage="No credit cards yet"
                    emptyBtnProps={{
                        showButton: canAddCard,
                        buttonText: "Add card",
                        onButtonClick: handleToggleShowAddCardModal,
                    }}
                />
            )}
            {isSuccess && data && data.data.length > 0 && (
                <Card classNames=" border-strokedark p-0 bg-white sm:py-32 sm:px-24">
                    <div>
                        <div className="border-b border-b-strokedark w-full mb-24">
                            {listHeader}
                        </div>
                        {data.data.map((card, index) => (
                            <>
                                <div
                                    className="flex px-24 pb-8 gap-x-8 justify-between items-center max-[1072px]:flex-col max-[1072px]:items-start"
                                    key={`${index + 1}`}
                                >
                                    <div className="flex bg-[#F7FAF5] rounded-r8 px-12 py-10 justify-between w-[30rem] max-[1072px]:w-full max-[1072px]:mb-[1.2rem]">
                                        <div>
                                            <p className="mb-8 text-gray text-xs font-semibold">
                                                CARD TYPE
                                            </p>
                                            <div className="flex items-center">
                                                <span>
                                                    {getCreditCardIconToDisplay(
                                                        card.card_type
                                                    )}
                                                </span>
                                                <span className=" text-xss font-semibold mr-[2.4rem] ml-[0.4rem]">
                                                    {card.card_type}
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <p className="mb-8 text-gray text-xs font-semibold">
                                                CARD NUMBER
                                            </p>
                                            <span className="text-sm font-medium">
                                                {card.masked_card_number}
                                            </span>
                                        </div>
                                    </div>
                                    {card.is_card_expired && (
                                        <div>
                                            <Tag
                                                textColor="#981F41"
                                                bgColor="rgba(251, 199, 198, 0.50)"
                                                title="Expired"
                                                className="capitalize font-normal px-8 py-4 h-24 rounded-r4"
                                            />
                                        </div>
                                    )}
                                    <div className="max-[1072px]:mb-[1.2rem]">
                                        <p className="mb-8 text-gray text-xs font-semibold max-[1072px]:mb-4">
                                            {" "}
                                            ZIPCODE
                                        </p>
                                        <span className="text-sm font-medium">
                                            {card.zip_code || "--"}
                                        </span>
                                    </div>
                                    <div className="max-[1072px]:mb-[1.2rem]">
                                        <p className="mb-8 text-gray text-xs font-semibold max-[1072px]:mb-4">
                                            {" "}
                                            BILLING ADDRESS
                                        </p>
                                        <span className="text-sm font-medium ">
                                            {card.billing_address}
                                        </span>
                                    </div>
                                    <div className="bg-[#FAFAFA] rounded-r6 w-[44.8rem] p-12 max-[1072px]:w-full max-[1072px]:mt-10">
                                        <div className="mb-8 flex items-center gap-x-8">
                                            <p className="text-gray text-xs font-semibold ">
                                                {" "}
                                                LABEL
                                            </p>{" "}
                                            <Button
                                                variant="normal"
                                                className="p-0"
                                                onClick={() => {
                                                    setShowAddLabel(true);
                                                    setCardPaymentID(
                                                        card.payment_card_id
                                                    );
                                                }}
                                            >
                                                <EditLightIcon />
                                            </Button>
                                        </div>
                                        <span className="text-sm font-medium">
                                            {card.card_label || "--"}
                                        </span>
                                    </div>
                                    {card.card_status !==
                                        PaymentCardStatus.VALIDATED && (
                                        <Button
                                            variant="secondary"
                                            type="button"
                                            onClick={() =>
                                                handleCardToValidate(
                                                    card.payment_card_id
                                                )
                                            }
                                        >
                                            Validate
                                        </Button>
                                    )}
                                </div>
                                <div className="mb-24 pb-24 border-b border-strokedark mx-24">
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="font-medium text-primary text-sm"
                                        onClick={() =>
                                            handleViewTransactionHistory(
                                                card.payment_card_id
                                            )
                                        }
                                    >
                                        View transaction history
                                    </Button>
                                </div>
                            </>
                        ))}
                    </div>
                </Card>
            )}
        </>
    );
}

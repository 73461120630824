import * as React from "react";

export default function AmexIcon() {
    return (
        <svg
            width="34"
            height="25"
            viewBox="0 0 34 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="1.0752"
                width="33"
                height="23"
                rx="3.5"
                fill="#1F72CD"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.09542 9.0752L2.91431 16.3219H6.72254L7.19465 15.1665H8.27379L8.7459 16.3219H12.9377V15.4401L13.3112 16.3219H15.4795L15.8531 15.4214V16.3219H24.5709L25.6309 15.1965L26.6235 16.3219L31.1011 16.3313L27.91 12.7188L31.1011 9.0752H26.6929L25.661 10.1798L24.6997 9.0752H15.2159L14.4015 10.9456L13.568 9.0752H9.76774V9.92705L9.34498 9.0752H6.09542ZM6.83229 10.1042H8.6886L10.7986 15.0183V10.1042H12.8322L14.4619 13.6276L15.9639 10.1042H17.9873V15.3043H16.7561L16.746 11.2296L14.9511 15.3043H13.8498L12.0448 11.2296V15.3043H9.51203L9.03187 14.1385H6.43769L5.95851 15.3032H4.60147L6.83229 10.1042ZM24.1198 10.1042H19.1137V15.3012H24.0423L25.6309 13.5788L27.1621 15.3012H28.7627L26.4362 12.7178L28.7627 10.1042H27.2315L25.651 11.8068L24.1198 10.1042ZM7.73532 10.9841L6.88064 13.0608H8.58901L7.73532 10.9841ZM20.3499 12.1302V11.1809V11.18H23.4736L24.8366 12.6981L23.4132 14.2245H20.3499V13.1882H23.081V12.1302H20.3499Z"
                fill="white"
            />
            <rect
                x="0.5"
                y="1.0752"
                width="33"
                height="23"
                rx="3.5"
                stroke="#F9F7F6"
            />
        </svg>
    );
}

import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    IProvider,
    IProvidersFilterTypes,
    ISuperviseeCaseload,
} from "../../utils/types/providers";
import {
    IApiResponse,
    IPaginatedApiResponse,
} from "../../utils/types/api-response";
import {
    fetchProviders,
    fetchSuperviseesCaseload,
} from "../../services/provider";
import { handleRemoveEmptyParamFromQueryParams } from "../../utils/helpers";
import { useDoesUserHavePermission } from "../permissions";
import { UserPermisions } from "../../utils/types/user";

// Fetch supervisees caseload
export function useFetchSuperviseesCaseload(
    providerId: string,
    search_string?: string
) {
    return useQuery<
        IPaginatedApiResponse<ISuperviseeCaseload[]>,
        AxiosError<IApiResponse<ISuperviseeCaseload[]>>
    >({
        queryKey: [providerId, `supervisees-caseload`, search_string || ""],
        queryFn: () => fetchSuperviseesCaseload(providerId, search_string),
    });
}

export function useFetchProviders(filters: IProvidersFilterTypes) {
    const canViewProvidersList = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_PROVIDER_READ,
        UserPermisions.CLIENT_INFO_PROVIDER_WRITE,
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
        UserPermisions.PROVIDER_INFO_READ,
    ]);

    return useQuery<
        IPaginatedApiResponse<IProvider[]>,
        AxiosError<IApiResponse<IProvider[]>>
    >({
        queryKey: ["providers", handleRemoveEmptyParamFromQueryParams(filters)],
        queryFn: () => fetchProviders(filters),
        enabled: canViewProvidersList,
    });
}

export default function TasksIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.24609 5.92188H11.7461"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.25391 5.92188L4.75391 6.42188L6.25391 4.92188"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.24609 10.5859H11.7461"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.25391 10.5859L4.75391 11.0859L6.25391 9.58594"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.9987 14.6693H9.9987C13.332 14.6693 14.6654 13.3359 14.6654 10.0026V6.0026C14.6654 2.66927 13.332 1.33594 9.9987 1.33594H5.9987C2.66536 1.33594 1.33203 2.66927 1.33203 6.0026V10.0026C1.33203 13.3359 2.66536 14.6693 5.9987 14.6693Z"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { ColumnDef, Row } from "@tanstack/react-table";
import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    Table,
} from "@jhool-io/fe-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import {
    IClaim,
    ClaimStatus,
    ClaimsHasRemit,
} from "../../../types/claims.types";
import {
    formatDate,
    getTotalAmountOfTableRowsSelected,
    showMoneyInAppFormat,
} from "../../../../../utils/helpers";
import { getStatusTag } from "../../../helpers/claims.helper";
import ListState from "../../../../../components/ListState/ListState";
import Skeleton from "../../../../../components/Skeleton/Skeleton";
import { useFetchClaims } from "../../../hooks/claims.queries";
import { IAppCustomDates } from "../../../../../utils/types";
import MoreIcon from "../../../../../components/Icons/More";
import {
    useChangeClaimVoidStatus,
    useUpdateClaimStatus,
} from "../../../hooks/claims.mutations";
import useToast from "../../../../../hooks/useToast";
import BulkUpdateClaimForm from "../BulkUpdateClaimsForm/BulkUpdateClaimsForm";
import { LISTS_DEFAULT_LIMIT } from "../../../../../utils/constants";

interface RemitReceivedClaimsListProps {
    searchString: string;
}

export default function RemitReceivedlClaimsList({
    searchString,
}: RemitReceivedClaimsListProps) {
    const [rowSelection, setRowSelection] = React.useState({});
    const [getSelectedClaimsData, setGetSelectedClaimsData] = React.useState<
        Row<IClaim>[]
    >([]);
    const [showDialog, setShowDialog] = React.useState(false);
    const [statusToSubmit, setStatusToSubmit] =
        React.useState<ClaimStatus | null>(null);

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const pageFilter = Number(searchParams.get("remit_received_page") || 1);
    const limitFilter =
        Number(searchParams.get("remit_received_limit")) || LISTS_DEFAULT_LIMIT;
    const fromDateFilter = searchParams.get("note_dos_from");
    const toDateFilter = searchParams.get("note_dos_to");
    const insuranceProviderFilter =
        searchParams.get("insurance_provider") || "";
    const customDateFilter =
        (searchParams.get("date_range") as IAppCustomDates) ||
        IAppCustomDates.LAST_90_DAYS;
    const claimsStatusFilter = searchParams.get("status") as ClaimStatus;
    const hasRemitStatusFilter =
        searchParams.get("has_remits") || ClaimsHasRemit.SHOW_CLAIMS_WITH_REMIT;
    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: "",
        direction: "",
    });
    const showVoidedFilter = searchParams.get("show_voided") || "false";

    const sortableColumns = [
        "generated_on",
        "date_of_service",
        "submitted_on",
        "insurance_name",
        "claim_status",
        "client_name",
        "cpt_code",
        "note_author",
    ];

    const getStatusFilter = () => {
        if (claimsStatusFilter) {
            if (
                [
                    ClaimStatus.PAID,
                    ClaimStatus.PATIENT_PAYMENT_COLLECTION,
                    ClaimStatus.PENDING,
                ].includes(claimsStatusFilter)
            ) {
                return [claimsStatusFilter];
            }

            return [""];
        }

        return [
            ClaimStatus.PAID,
            ClaimStatus.PATIENT_PAYMENT_COLLECTION,
            ClaimStatus.PENDING,
        ];
    };

    const handleConfigAttribute = () => {
        if (sortableColumns.includes(sortConfig.key)) {
            return sortConfig.key;
        }

        return undefined;
    };

    const getHasRemitFilter = () => {
        if (hasRemitStatusFilter === ClaimsHasRemit.SHOW_CLAIMS_WITH_REMIT) {
            return "true";
        }

        if (hasRemitStatusFilter === ClaimsHasRemit.HIDE_CLAIMS_WITH_REMIT) {
            return "false";
        }
        return "";
    };

    const claimSourceFilter = searchParams.get("source") || "";

    const { data, error, isLoading, isSuccess } = useFetchClaims({
        claim_statuses: getStatusFilter(),
        custom_date: customDateFilter,
        search_string: searchString,
        note_dos_from: fromDateFilter || undefined,
        note_dos_to: toDateFilter || undefined,
        insurance_provider_id: insuranceProviderFilter,
        page: pageFilter,
        limit: limitFilter,
        has_remits: getHasRemitFilter(),
        sort_attribute:
            searchParams.get("closed_claims_sort_attr") ||
            handleConfigAttribute(),
        sort_order:
            searchParams.get("closed_claims_sort_order") ||
            sortConfig.direction,
        tab_name: "received_remit",
        claim_source: claimSourceFilter,
        show_voided: showVoidedFilter,
    });

    const patchClaimStatus = useUpdateClaimStatus();

    // hook for voiding/unvoiding claim
    const changeVoidStatus = useChangeClaimVoidStatus();

    const { toast } = useToast();

    const queryClient = useQueryClient();

    const isMutating = useIsMutating(["bulk-update-claims"]);

    // Array of selected claims ids
    const selectedClaimsIds =
        getSelectedClaimsData &&
        getSelectedClaimsData.map(
            (item: Row<IClaim>) => item.original.claim_id
        );

    const claimsDetails =
        selectedClaimsIds &&
        selectedClaimsIds.map((id: string) =>
            data?.data.find((claim) => claim.claim_id === id)
        );

    const showPendingClaimsActions =
        claimsDetails &&
        claimsDetails.length > 0 &&
        claimsDetails.length ===
            claimsDetails.filter(
                (claim) => claim && claim.claim_status === ClaimStatus.PENDING
            ).length;

    const showUnvoidClaimsButton =
        claimsDetails &&
        claimsDetails.length > 0 &&
        claimsDetails.length ===
            claimsDetails.filter((claim) => claim && claim.is_voided).length;

    const showVoidClaimsButton =
        claimsDetails &&
        claimsDetails.length > 0 &&
        claimsDetails.length ===
            claimsDetails.filter((claim) => claim && !claim.is_voided).length;

    const handleDisplayDialog = (status: ClaimStatus) => {
        setStatusToSubmit(status);
        setShowDialog(true);
    };

    const handleChangeStatus = (id: string, status: ClaimStatus) => {
        const changeStatusPayload = {
            claim_id: id,
            claim_status: status,
        };
        patchClaimStatus.mutate(changeStatusPayload, {
            onSuccess: async (response) => {
                await queryClient.invalidateQueries(["claims"]);
                toast({
                    mode: "success",
                    message:
                        response?.message ||
                        "Claim status updated successfully",
                });
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not update claim status at this time",
                });
            },
        });
    };

    const handleChangeVoidStatus = (status: boolean, id?: string) => {
        const payload = {
            claim_ids: id ? [id] : selectedClaimsIds,
            void_status: status,
        };

        changeVoidStatus.mutate(payload, {
            onSuccess: (response) => {
                queryClient.invalidateQueries(["claims"]);
                toast({
                    mode: "success",
                    message:
                        response?.message ||
                        `Claim ${
                            status === true ? "voided" : "unvoided"
                        } successfully`,
                });
                setRowSelection({});
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        `Could not ${
                            status === true ? "void" : "unvoid"
                        } claim at this time`,
                });
            },
        });
    };

    const columns: ColumnDef<IClaim>[] = [
        {
            id: "select",
            header: ({ table }) => (
                <Checkbox
                    handleChange={table.getToggleAllRowsSelectedHandler()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={table.getIsAllRowsSelected()}
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    handleChange={row.getToggleSelectedHandler()}
                    indeterminate={row.getIsSomeSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={row.getIsSelected()}
                />
            ),
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "client_name",
                    header: "CLIENT NAME",
                    cell: ({ row }) => (
                        <span className="capitalize">
                            {row.original.client_name}
                        </span>
                    ),
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "date_of_service",
                    header: "DATE OF SERVICE",
                    cell: ({ row }) => formatDate(row.original.date_of_service),
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "claim_status",
                    header: "STATUS",
                    cell: ({ row }) => getStatusTag(row.original.claim_status),
                },
            ],
        },

        {
            accessorKey: "claim_source",
            header: "CLAIM SOURCE",
            cell: ({ row }) => row.original.claim_source || "--",
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "insurance_name",
                    header: "INSURANCE",
                    cell: ({ row }) => (
                        <span className="uppercase">
                            {row.original.insurance_name}
                        </span>
                    ),
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "note_author",
                    header: "CLINICIAN",
                    cell: ({ row }) =>
                        row.original.note_author
                            ? `${row.original.note_author?.first_name} ${row.original.note_author?.last_name}`
                            : "--",
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "cpt_code",
                    header: "CPT CODE",
                },
            ],
        },
        {
            header: " ",
            columns: [
                {
                    accessorKey: "charge_amount",
                    header: "CHARGE",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(row.original.charge_amount),
                },
            ],
        },
        {
            header: "OUTSTANDING",
            columns: [
                {
                    accessorKey: "insurance_amount_outstanding",
                    header: "INSURANCE",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(
                            row.original.insurance_amount_outstanding
                        ),
                },
                {
                    accessorKey: "coinsurance_amount_outstanding",
                    header: "CO-INSURANCE",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(
                            row.original.coinsurance_amount_outstanding
                        ),
                },
            ],
        },
        {
            accessorKey: "has_remits",
            header: "HAS REMITS",
            cell: ({ row }) => (
                <span
                    className={
                        row.original.has_remits ? "text-primary" : "text-danger"
                    }
                >
                    {row.original.has_remits ? "Yes" : "No"}
                </span>
            ),
        },
        {
            accessorKey: "accepts_assignment",
            header: "ACCEPTS ASSIGNMENT",
            cell: ({ row }) => (
                <span
                    className={
                        row.original.accepts_assignment
                            ? "text-primary"
                            : "text-danger"
                    }
                >
                    {row.original.accepts_assignment ? "Yes" : "No"}
                </span>
            ),
        },
        {
            accessorKey: "has_claim_attachments",
            header: "Attached doc",
            cell: ({ row }) => (
                <span
                    className={
                        row.original.has_claim_attachments
                            ? "text-primary"
                            : "text-danger"
                    }
                >
                    {row.original.has_claim_attachments ? "Yes" : "No"}
                </span>
            ),
        },

        {
            accessorKey: "claim_id",
            header: "",
            cell: ({ row }) => (
                <Dropdown>
                    <DropdownTrigger asChild>
                        <Button
                            size="auto"
                            className="w-16 h-16 rounded-[16px] shadow-morebtn relative z-[1] data-[state=open]:border-primary-800 mt-1"
                            variant="normal"
                        >
                            <MoreIcon />
                        </Button>
                    </DropdownTrigger>

                    <DropdownContent width="auto" align="end">
                        <DropdownItem
                            onClick={(e) => {
                                e.stopPropagation();
                                handleChangeStatus(
                                    row.original.claim_id,
                                    ClaimStatus.READY_FOR_SUBMISSION
                                );
                            }}
                        >
                            Mark as Ready for submission
                        </DropdownItem>
                        {row.original.claim_status === ClaimStatus.PENDING && (
                            <DropdownItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleChangeStatus(
                                        row.original.claim_id,
                                        ClaimStatus.CLOSED
                                    );
                                }}
                            >
                                Mark as Closed
                            </DropdownItem>
                        )}
                        {row.original.is_voided ? (
                            <DropdownItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleChangeVoidStatus(
                                        false,
                                        row.original.claim_id
                                    );
                                }}
                            >
                                Unvoid Claim
                            </DropdownItem>
                        ) : (
                            <DropdownItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleChangeVoidStatus(
                                        true,
                                        row.original.claim_id
                                    );
                                }}
                            >
                                Void Claim
                            </DropdownItem>
                        )}
                    </DropdownContent>
                </Dropdown>
            ),
        },
    ];

    const handleRowClick = (row: Row<IClaim>) => {
        navigate(
            `/claims/${row.original.claim_id}/${row.original.client_id}/${row.original.note_id}?key=claims&claims_view=view&viewFrom=claims&tab=remit_received&claims_sub_tab=claims&active_claim_id=${row.original.claim_id}`
        );
    };

    // onChange handler for page
    const handlePageChange = (page: number) => {
        searchParams.set("remit_received_page", String(page));
        setSearchParams(searchParams);
    };

    const handleLimitChange = (limit: number) => {
        searchParams.set("remit_received_limit", String(limit));
        setSearchParams(searchParams);
    };

    return (
        <>
            {statusToSubmit && selectedClaimsIds && (
                <Dialog open={showDialog}>
                    <DialogContent
                        title="Update Claims status"
                        showFooter
                        saveText="Save changes"
                        cancelText="Cancel"
                        variant="center"
                        handleCloseDialog={() => setShowDialog(false)}
                        submitBtnFormValue="bulk-update-claims"
                        isSubmitBtnDisabled={isMutating > 0}
                        isCancelBtnDisabled={isMutating > 0}
                    >
                        <BulkUpdateClaimForm
                            claimStatus={statusToSubmit}
                            claimsId={selectedClaimsIds}
                            onFormSubmit={() => {
                                setRowSelection({});
                                setShowDialog(false);
                            }}
                        />
                    </DialogContent>
                </Dialog>
            )}
            {error && error?.response?.status !== 404 && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg="Cannot display claims at this time please try again later"
                />
            )}
            {isLoading && (
                <Skeleton
                    containerTestId="remit-received-claims-loader"
                    type="table"
                    count={6}
                />
            )}
            {data && isSuccess && (
                <>
                    {data.data.length === 0 && (
                        <ListState
                            stateHelperText="Remit receieved claims will appear here once available "
                            emptyMessage="No claims yet"
                            isError={false}
                        />
                    )}

                    {data.data.length > 0 && (
                        <>
                            {getTotalAmountOfTableRowsSelected(rowSelection)
                                .amount > 0 && (
                                <div className="flex items-center mb-6">
                                    <div className="font-medium text-base">
                                        {
                                            getTotalAmountOfTableRowsSelected(
                                                rowSelection
                                            ).amountString
                                        }
                                        :
                                    </div>
                                    <Button
                                        variant="secondary"
                                        className="ml-12"
                                        onClick={() =>
                                            handleDisplayDialog(
                                                ClaimStatus.READY_FOR_SUBMISSION
                                            )
                                        }
                                    >
                                        Mark as Ready for submission
                                    </Button>
                                    {showPendingClaimsActions && (
                                        <>
                                            <Button
                                                variant="secondary"
                                                className="ml-12"
                                                onClick={() =>
                                                    handleDisplayDialog(
                                                        ClaimStatus.PAID
                                                    )
                                                }
                                            >
                                                Mark as Paid
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                className="ml-12"
                                                onClick={() =>
                                                    handleDisplayDialog(
                                                        ClaimStatus.DENIED
                                                    )
                                                }
                                            >
                                                Mark as Denied
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                className="ml-12"
                                                onClick={() =>
                                                    handleDisplayDialog(
                                                        ClaimStatus.CLOSED
                                                    )
                                                }
                                            >
                                                Mark as Closed
                                            </Button>
                                        </>
                                    )}
                                    {showUnvoidClaimsButton && (
                                        <Button
                                            variant="secondary"
                                            className="ml-12"
                                            onClick={() =>
                                                handleChangeVoidStatus(false)
                                            }
                                        >
                                            Unvoid Claims
                                        </Button>
                                    )}

                                    {showVoidClaimsButton && (
                                        <Button
                                            variant="secondary"
                                            className="ml-12"
                                            onClick={() =>
                                                handleChangeVoidStatus(true)
                                            }
                                        >
                                            Void Claims
                                        </Button>
                                    )}
                                </div>
                            )}
                            <Table
                                columns={columns}
                                data={data.data}
                                handleRowClick={handleRowClick}
                                setRowSelection={setRowSelection}
                                rowSelection={rowSelection}
                                setGetSelectedData={setGetSelectedClaimsData}
                                hasPagination={data.total_count > 20}
                                isHeaderFixed
                                setSortConfig={setSortConfig}
                                sortConfig={sortConfig}
                                sortableColumns={sortableColumns}
                                pagination={
                                    <Pagination
                                        totalCount={data.total_count}
                                        totalPages={data.total_pages}
                                        currentPage={pageFilter}
                                        onPageChange={handlePageChange}
                                        limit={limitFilter}
                                        onLimitChange={handleLimitChange}
                                    />
                                }
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
}

import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import { useQueryClient, useIsMutating } from "@tanstack/react-query";
import React from "react";
import { ContentState } from "draft-js";
import { useForm } from "react-hook-form";
import {
    Dropdown,
    DropdownTrigger,
    DropdownContent,
    DropdownItem,
    Button,
    Dialog,
    DialogContent,
} from "@jhool-io/fe-components";

import { useFetchTasks } from "../../../../hooks/queries/tasks";
import { IUpdateTask, TaskStatus } from "../../../../utils/types/tasks";
import { ViewEditorText } from "../../../../components/TextEditor/ViewEditorText/ViewEditorText";
import EditIcon from "../../../../components/Icons/Edit";
import {
    formatDate,
    makeStringFirstLetterCapital,
} from "../../../../utils/helpers";
import { AddTextEditor } from "../../../../components/TextEditor/AddTextEditor/AddTextEditor";
import ListState from "../../../../components/ListState/ListState";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import {
    useUpdateTask,
    useDeleteTasks,
} from "../../../../hooks/mutations/tasks";
import useToast from "../../../../hooks/useToast";
import { UserPermisions } from "../../../../utils/types/user";
import { useFetchUserDetails } from "../../../../hooks/queries/user";
import ReassignTask from "../ReassignTask/ReassignTask";

import { getStatusTag } from "../../helpers/tasks.helper";
import WhiteArrowDownIcon from "../../../../components/Icons/WhiteArrowDown";
import CalendarDaysIcon from "../../../../components/Icons/SideNav/CalendarDays";
import UserStrokeIcon from "../../../../components/Icons/UserStroke";
import UserWithLaptopIcon from "../../../../components/Icons/UserWithLaptop";
import PenWritingIcon from "../../../../components/Icons/PenWriting";
import styles from "./TaskDetails.module.scss";
import { useAppSelector } from "../../../../hooks/useRedux";

interface TaskDetailsProps {
    showEditor: boolean;
    setShowEditor: (value: boolean) => void;
    setHideModal: () => void;
}

// Dialog for actions on Task Details modal
type ModalContent = "delete-task" | "reassign-task" | "forward-task";

export default function TaskDetails({
    showEditor,
    setShowEditor,
    setHideModal,
}: TaskDetailsProps) {
    const [searchParams] = useSearchParams();
    // States for Editor
    const [getCurrentContent, setGetCurrentContent] =
        React.useState<ContentState>();
    const [isTaskDescriptionProvided, setIsTaskDescriptionProvided] =
        React.useState(true);
    const [dialogInView, setDialogInView] = React.useState<ModalContent | null>(
        null
    );

    const loggedInUser = useFetchUserDetails();

    const { practice } = useAppSelector((state) => state.userPractice);

    const { data, error, isLoading, isSuccess } = useFetchTasks({
        status: [
            TaskStatus.COMPLETED,
            TaskStatus.IN_PROGRESS,
            TaskStatus.NOT_STARTED,
        ],
        todo_id: searchParams.get("task_id") || "",
    });

    // React hook form values
    const { handleSubmit } = useForm<IUpdateTask>({
        mode: "onChange",
    });

    // state to set the defaultValue in editor to task description
    const [taskDescription, setTaskDescription] = React.useState(
        data?.data[0]?.description
    );

    const toggleEditor = () => {
        setShowEditor(!showEditor);
    };

    const update = useUpdateTask();

    const deleteTasks = useDeleteTasks();

    const queryClient = useQueryClient();

    const { toast } = useToast();

    const location = useLocation();

    const navigate = useNavigate();

    // Check if any of the mutations are occuring
    const isUpdatingTodo = useIsMutating(["update-todo"]);
    const isDeletingTodo = useIsMutating(["delete-tasks"]);

    const completeTask = () => {
        const payload = {
            todos_to_update: [
                {
                    todo_id: data?.data[0].todo_id as string,
                    status: TaskStatus.COMPLETED,
                    title: data?.data[0].title as string,
                    description: data?.data[0]?.description as string,
                },
            ],
        };

        update.mutate(payload, {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: ["todos"],
                });
                toast({
                    mode: "success",
                    message: "Task completed successfully!",
                });
                navigate(
                    searchParams.get("tab")
                        ? `${location.pathname}?tab=${searchParams.get("tab")}`
                        : location.pathname
                );
                setHideModal();
            },

            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not mark task as complete",
                });
            },
        });
    };

    const editTask = () => {
        if (!getCurrentContent?.hasText()) {
            // Additional info is empty
            setIsTaskDescriptionProvided(false);
            return;
        }

        setIsTaskDescriptionProvided(true);

        const payload = {
            todos_to_update: [
                {
                    todo_id: data?.data[0].todo_id as string,
                    status: data?.data[0].status as TaskStatus,
                    title: data?.data[0].title as string,
                    description: taskDescription as string,
                },
            ],
        };

        update.mutate(payload, {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: ["todos"],
                });
                toast({
                    mode: "success",
                    message: "Task updated successfully!",
                });
                setShowEditor(false);
            },

            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not update the task",
                });
            },
        });
    };

    const handleDeleteTasks = () => {
        deleteTasks.mutate([data?.data[0].todo_id as string], {
            onSuccess() {
                queryClient.invalidateQueries({
                    queryKey: [`todos`],
                });
                toast({
                    mode: "success",
                    message: "Tasks deleted successfully",
                });
                navigate(
                    searchParams.get("tab")
                        ? `${location.pathname}?tab=${searchParams.get("tab")}`
                        : location.pathname
                );
                setDialogInView(null);
                setHideModal();
            },
            onError(err) {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not delete tasks, please try again",
                });
            },
        });
    };

    const canDeleteTask = (id: string) => {
        if (practice?.permissions?.includes(UserPermisions.TODO_ALL_DELETE)) {
            return true;
        }
        if (loggedInUser.data?.user_id === id) {
            return true;
        }

        return false;
    };

    const getModalHeaderText = () => {
        switch (dialogInView) {
            case "delete-task":
                return "Delete task";

            case "forward-task":
                return "Forward task";

            case "reassign-task":
                return "Re-assign task";

            default:
                return "";
        }
    };

    const getModalOkText = () => {
        switch (dialogInView) {
            case "delete-task":
                return "Delete task";

            case "forward-task":
                return "Forward task";

            case "reassign-task":
                return "Assign task";

            default:
                return "";
        }
    };

    return (
        <>
            <Dialog open={Boolean(dialogInView)}>
                <DialogContent
                    title={getModalHeaderText()}
                    onSaveClick={() => {
                        if (dialogInView === "delete-task") {
                            handleDeleteTasks();
                        }
                    }}
                    onCancelClick={() => setDialogInView(null)}
                    handleCloseDialog={() => setDialogInView(null)}
                    cancelText="Cancel"
                    variant="center"
                    saveText={getModalOkText()}
                    showFooter
                    isDeleting={dialogInView === "delete-task"}
                    submitBtnFormValue="reassign-task"
                    isCancelBtnDisabled={
                        isUpdatingTodo > 0 || isDeletingTodo > 0
                    }
                    isSubmitBtnDisabled={
                        isUpdatingTodo > 0 || isDeletingTodo > 0
                    }
                >
                    <>
                        {dialogInView === "delete-task" && (
                            <div className="px-8">
                                <h3 className="text-xl font-medium mb-[8px]">
                                    Are you sure you want to delete this task?
                                </h3>
                                <p className="text-base">
                                    Are you sure you want to delete this task?
                                    Please note that this action cannot be
                                    undone.
                                </p>
                            </div>
                        )}
                        {dialogInView === "reassign-task" ||
                        dialogInView === "forward-task" ? (
                            <ReassignTask
                                onFormSubmit={() => {
                                    setDialogInView(null);
                                    setHideModal();
                                }}
                                action={
                                    dialogInView === "forward-task"
                                        ? "forward"
                                        : "reassign"
                                }
                            />
                        ) : null}
                    </>
                </DialogContent>
            </Dialog>

            {isLoading && (
                <div className="p-32">
                    <Skeleton
                        containerTestId="task-loader"
                        className="h-20 mb-40"
                        type="document"
                        width="100%"
                    />

                    <div className="flex flex-col">
                        <Skeleton
                            containerTestId="task-loader"
                            className="h-20 mb-20"
                        />
                        <Skeleton
                            containerTestId="task-loader"
                            className="h-20 mb-20"
                        />
                        <Skeleton
                            containerTestId="task-loader"
                            className="h-20 mb-20"
                        />
                        <Skeleton
                            containerTestId="task-loader"
                            className="h-20 mb-20"
                        />

                        <Skeleton
                            containerTestId="task-loader"
                            className="h-20 mb-20"
                        />

                        <Skeleton
                            containerTestId="task-loader"
                            className="h-20 mb-20"
                        />
                    </div>
                </div>
            )}

            {error && error?.response?.status !== 404 && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error.response?.data.message ||
                        `Cannot display this task at this time please try again later`
                    }
                />
            )}

            {data && isSuccess && (
                <>
                    <div className="flex justify-between items-center px-32 py-20 border-b-[1px] border-b-strokedark">
                        <div>
                            <h2 className="text-xxl font-bold mb-8">
                                {data.data[0]?.title &&
                                    makeStringFirstLetterCapital(
                                        data.data[0]?.title as string
                                    )}
                            </h2>

                            {getStatusTag(data.data[0].status)}
                        </div>

                        {data.data[0]?.status !== TaskStatus.COMPLETED && (
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <Button className="rounded-[16px] shadow-morebtn flex gap-8 relative z-[1] data-[state=open]:border-primary-800 mt-1">
                                        Actions
                                        <WhiteArrowDownIcon />
                                    </Button>
                                </DropdownTrigger>

                                <DropdownContent
                                    width={240}
                                    className="px-8 py-16 text-gray"
                                    align="end"
                                >
                                    <DropdownItem
                                        onClick={() => completeTask()}
                                    >
                                        Mark as Completed
                                    </DropdownItem>

                                    <DropdownItem
                                        onClick={() =>
                                            setDialogInView("reassign-task")
                                        }
                                    >
                                        Re-assign
                                    </DropdownItem>

                                    <DropdownItem
                                        onClick={() =>
                                            setDialogInView("forward-task")
                                        }
                                    >
                                        Forward task
                                    </DropdownItem>

                                    {canDeleteTask(
                                        data.data[0]?.creator.user_id
                                    ) && (
                                        <DropdownItem
                                            onClick={() =>
                                                setDialogInView("delete-task")
                                            }
                                            className="text-danger"
                                        >
                                            Delete Task
                                        </DropdownItem>
                                    )}
                                </DropdownContent>
                            </Dropdown>
                        )}
                    </div>

                    <div className="p-32 border-b-[1px] border-b-strokedark">
                        <div className="flex gap-x-[56px] gap-y-32 flex-wrap mb-24">
                            <div className="flex flex-col gap-16 lg:min-w-[120px]">
                                <CalendarDaysIcon />

                                <div className="flex flex-col gap-1">
                                    <span className="text-xs font-medium text-gray uppercase">
                                        Due Date
                                    </span>

                                    <p className="text-sm ">
                                        {formatDate(data.data[0]?.due_date)}
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col gap-16 lg:min-w-[120px]">
                                <UserStrokeIcon />

                                <div className="flex flex-col gap-1">
                                    <span className="text-xs font-medium text-gray uppercase">
                                        Created by
                                    </span>
                                    <span className="text-sm">
                                        {data.data[0]?.creator.user_id
                                            ? `${data.data[0].creator.first_name} ${data.data[0].creator.last_name}`
                                            : "Mantle"}
                                    </span>
                                </div>
                            </div>

                            <div className="flex flex-col gap-16 lg:min-w-[120px]">
                                <UserWithLaptopIcon />

                                <div className="flex flex-col gap-1">
                                    <span className="text-xs font-medium text-gray uppercase">
                                        Assigned to
                                    </span>

                                    <span className="text-sm">
                                        {data.data[0]?.assignee?.user_id
                                            ? `${data.data[0].assignee.first_name} ${data.data[0].assignee.last_name}`
                                            : "--"}
                                    </span>
                                </div>
                            </div>

                            <div className="flex flex-col gap-16 lg:min-w-[120px]">
                                <UserStrokeIcon />

                                <div className="flex flex-col gap-1">
                                    <span className="text-xs font-medium text-gray uppercase">
                                        Client Name
                                    </span>

                                    {data.data[0]?.client &&
                                    data.data[0].client?.client_id ? (
                                        <Link
                                            className="text-sm underline text-primary"
                                            to={
                                                data.data[0].client?.client_id
                                                    ? `/clients/${data.data[0].client?.client_id}`
                                                    : ""
                                            }
                                        >
                                            {data.data[0].client?.first_name}{" "}
                                            {data.data[0].client?.last_name}
                                        </Link>
                                    ) : (
                                        "----"
                                    )}
                                </div>
                            </div>
                        </div>

                        {data.data[0]?.description && (
                            <div className="flex flex-col gap-16 max-w-[320px]">
                                <PenWritingIcon />

                                <div className="flex flex-col gap-1">
                                    <h3 className="text-xs font-medium text-gray uppercase">
                                        Description
                                    </h3>
                                    <div className="text-sm">
                                        <ViewEditorText
                                            text={data.data[0]?.description}
                                        />

                                        {loggedInUser.data?.user_id ===
                                            data.data[0]?.creator.user_id &&
                                            data.data[0]?.status !==
                                                TaskStatus.COMPLETED && (
                                                <Button
                                                    type="button"
                                                    variant="normal"
                                                    size="auto"
                                                    aria-label="edit-btn"
                                                    onClick={() =>
                                                        toggleEditor()
                                                    }
                                                    className=""
                                                >
                                                    <EditIcon />
                                                </Button>
                                            )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {showEditor && (
                        <form
                            id="edit-task"
                            onSubmit={handleSubmit(editTask)}
                            className="mt-24 px-32"
                        >
                            <div className="fg">
                                <AddTextEditor
                                    title="Write Task Description..."
                                    defaultValue={data.data[0].description}
                                    onEditorTextChange={setTaskDescription}
                                    setIsRequiredFieldProvided={
                                        setGetCurrentContent
                                    }
                                    isRequiredFieldProvided={
                                        isTaskDescriptionProvided ||
                                        getCurrentContent?.hasText()
                                    }
                                    titleClass={styles.descriptionLabel}
                                    editorClass={styles.descriptionWrapper}
                                />
                            </div>
                        </form>
                    )}
                </>
            )}
        </>
    );
}

export default function BillingIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.66536 10.7969H6.33203C6.0587 10.7969 5.83203 10.5702 5.83203 10.2969C5.83203 10.0235 6.0587 9.79688 6.33203 9.79688H9.66536C9.9387 9.79688 10.1654 10.0235 10.1654 10.2969C10.1654 10.5702 9.9387 10.7969 9.66536 10.7969Z"
                fill="#576A81"
            />
            <path
                d="M8 12.4974C7.72667 12.4974 7.5 12.2707 7.5 11.9974V8.66406C7.5 8.39073 7.72667 8.16406 8 8.16406C8.27333 8.16406 8.5 8.39073 8.5 8.66406V11.9974C8.5 12.2707 8.27333 12.4974 8 12.4974Z"
                fill="#576A81"
            />
            <path
                d="M3.46777 6.97586C3.3411 6.97586 3.21443 6.92252 3.11443 6.82919C2.97443 6.68919 2.92777 6.46919 3.00777 6.28252L4.24777 3.32252C4.27443 3.26252 4.28777 3.22252 4.30777 3.18919C5.29443 0.915856 6.55443 0.362522 8.7811 1.21586C8.90777 1.26252 9.00777 1.36252 9.0611 1.48919C9.11443 1.61586 9.11443 1.75586 9.0611 1.88252L7.10777 6.41585C7.02777 6.59586 6.84777 6.71586 6.64777 6.71586H4.74777C4.36777 6.71586 4.00777 6.78919 3.6611 6.93585C3.6011 6.96252 3.53443 6.97586 3.46777 6.97586ZM7.07443 1.83586C6.24777 1.83586 5.7411 2.37586 5.21443 3.60252C5.20777 3.62252 5.19443 3.64252 5.18777 3.66252L4.31443 5.73586C4.4611 5.72252 4.6011 5.71586 4.74777 5.71586H6.31443L7.9211 1.98252C7.60777 1.88252 7.32777 1.83586 7.07443 1.83586Z"
                fill="#576A81"
            />
            <path
                d="M12.1948 6.84831C12.1481 6.84831 12.0948 6.84164 12.0481 6.82831C11.8015 6.75498 11.5281 6.71498 11.2481 6.71498H6.64812C6.48145 6.71498 6.32145 6.62831 6.22812 6.48831C6.14145 6.34831 6.12145 6.16831 6.18812 6.01498L8.12145 1.52831C8.22145 1.28831 8.51479 1.12831 8.76145 1.20831C8.84145 1.23498 8.91479 1.26831 8.99479 1.30164L10.5681 1.96164C11.4881 2.34164 12.1015 2.74164 12.5015 3.22164C12.5815 3.31498 12.6481 3.41498 12.7081 3.51498C12.7815 3.62831 12.8481 3.76831 12.8948 3.90831C12.9148 3.95498 12.9481 4.04164 12.9681 4.13498C13.1548 4.76164 13.0615 5.54164 12.6615 6.54164C12.5815 6.72831 12.3948 6.84831 12.1948 6.84831ZM7.40812 5.71498H11.2548C11.4681 5.71498 11.6748 5.73498 11.8815 5.76831C12.0681 5.18831 12.1081 4.74164 12.0015 4.38164C11.9881 4.32164 11.9748 4.29498 11.9681 4.26831C11.9281 4.16164 11.9015 4.10164 11.8681 4.04831C11.8215 3.97498 11.7881 3.91498 11.7348 3.85498C11.4481 3.50831 10.9415 3.18831 10.1881 2.88164L8.86812 2.32831L7.40812 5.71498Z"
                fill="#576A81"
            />
            <path
                d="M10.6 15.1643H5.4C5.21333 15.1643 5.04 15.1509 4.86667 15.1309C2.52667 14.9709 1.19333 13.6376 1.03333 11.2709C1.01333 11.1243 1 10.9443 1 10.7643V9.46427C1 7.96427 1.89333 6.61094 3.27333 6.01094C3.74 5.81094 4.24 5.71094 4.75333 5.71094H11.26C11.64 5.71094 12.0067 5.76427 12.3467 5.87094C13.9133 6.34427 15.0133 7.82427 15.0133 9.46427V10.7643C15.0133 10.9109 15.0067 11.0509 15 11.1843C14.8533 13.7909 13.3333 15.1643 10.6 15.1643ZM4.74667 6.71094C4.36667 6.71094 4.00667 6.78427 3.66 6.93094C2.64667 7.37094 1.99333 8.36427 1.99333 9.46427V10.7643C1.99333 10.9043 2.00667 11.0443 2.02 11.1776C2.14667 13.0776 3.08 14.0109 4.95333 14.1376C5.12 14.1576 5.25333 14.1709 5.39333 14.1709H10.5933C12.7933 14.1709 13.8733 13.2043 13.98 11.1376C13.9867 11.0176 13.9933 10.8976 13.9933 10.7643V9.46427C13.9933 8.2576 13.1867 7.1776 12.04 6.82427C11.7933 6.75094 11.52 6.71094 11.24 6.71094H4.74667Z"
                fill="#576A81"
            />
            <path
                d="M1.49219 9.97044C1.21885 9.97044 0.992188 9.74377 0.992188 9.47044V7.51711C0.992188 5.41711 2.47885 3.60377 4.53219 3.20377C4.71219 3.17044 4.89885 3.23711 5.01885 3.37711C5.13219 3.51711 5.16552 3.71711 5.09219 3.88377L3.92552 6.67044C3.87219 6.79044 3.77885 6.88377 3.66552 6.93711C2.65219 7.37711 1.99885 8.37044 1.99885 9.47044C1.99219 9.74377 1.77219 9.97044 1.49219 9.97044ZM3.73219 4.55044C2.87885 5.03044 2.25885 5.87044 2.06552 6.85044C2.35885 6.55044 2.69885 6.29711 3.08552 6.11044L3.73219 4.55044Z"
                fill="#576A81"
            />
            <path
                d="M14.5048 9.96652C14.2315 9.96652 14.0048 9.73985 14.0048 9.46652C14.0048 8.25985 13.1981 7.17985 12.0515 6.82652C11.9181 6.78652 11.8048 6.69319 11.7448 6.56652C11.6848 6.43985 11.6781 6.29319 11.7315 6.16652C12.0448 5.38652 12.1248 4.81985 11.9981 4.37985C11.9848 4.31985 11.9715 4.29319 11.9648 4.26652C11.8781 4.07319 11.9248 3.84652 12.0781 3.69985C12.2315 3.55319 12.4648 3.51985 12.6515 3.61985C14.1048 4.37985 15.0048 5.87319 15.0048 7.51319V9.46652C15.0048 9.73985 14.7781 9.96652 14.5048 9.96652ZM12.8315 6.05985C13.2515 6.25319 13.6248 6.52652 13.9381 6.85319C13.8115 6.19985 13.4981 5.60652 13.0381 5.13985C13.0048 5.41985 12.9381 5.72652 12.8315 6.05985Z"
                fill="#576A81"
            />
        </svg>
    );
}

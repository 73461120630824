import * as React from "react";
import { User } from "firebase/auth";
import { AuthContext } from "./AuthContext";
import { auth } from "../../firebase";
import axiosInstance from "../../utils/axios";

interface AuthProviderProps {
    children: React.ReactNode;
}

export default function AuthProvider({ children }: AuthProviderProps) {
    const [user, setUser] = React.useState<User | null | undefined>(undefined);

    React.useEffect(() => {
        const subscribeToAuthChange = auth.onAuthStateChanged(
            async (firebaseUser) => {
                if (firebaseUser) {
                    try {
                        const token = await firebaseUser?.getIdToken();
                        axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
                        setUser(firebaseUser);
                    } catch (error) {
                        auth.signOut();
                        axiosInstance.defaults.headers.common.Authorization =
                            undefined;
                    }
                } else {
                    axiosInstance.defaults.headers.common.Authorization =
                        undefined;
                    setUser(null);
                }
            }
        );

        return subscribeToAuthChange;
    }, []);

    if (user === undefined) {
        return null;
    }

    return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
}

import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Input } from "@jhool-io/fe-components";
import {
    normalizePhoneNumber,
    PHONE_REGEX,
} from "../../../../utils/helpers/phonenumber/phonenumber";
import {
    IAddRelationInfo,
    IClientPersonalInfo,
    IClientRelation,
} from "../../../../utils/types/client";
import useToast from "../../../../hooks/useToast";
import { useAddRelationInfo } from "../../../../hooks/mutations/client";
import { IApiResponse } from "../../../../utils/types/api-response";
import FormCheckbox from "../../../FormCheckbox/FormCheckbox";

const schema = yup.object({
    relationship_type: yup.string().required("Relationship type is required"),
    first_name: yup.string().required("Relation first name is required"),
    last_name: yup.string().required("Relation last name is required"),
    contact_phone_no: yup
        .string()
        .required("Relation contact is required")
        .matches(PHONE_REGEX, "Field should only contain numbers"),
    is_primary_contact: yup.boolean(),
});

export default function AddRelationInfo() {
    const { mutate, isLoading, error } = useAddRelationInfo();

    // Query client
    const queryClient = useQueryClient();

    // To initialize clientId constant
    const params = useParams();
    const clientId = params.clientId as string;

    // Toast for success and error states
    const { toast } = useToast();

    // React hook form values
    const {
        handleSubmit,
        register,
        formState: { errors },
        watch,
        reset,
        setValue,
    } = useForm<IClientRelation>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    // Watch for when contact values in our form change
    const relationContact = watch("contact_phone_no");

    // Onsubmit function for the form
    const onSubmit = (payload: IClientRelation) => {
        const dataToSend: IAddRelationInfo = {
            client_id: clientId,
            relation_info: [payload],
        };

        mutate(dataToSend, {
            onSuccess: (res) => {
                queryClient.setQueryData<IApiResponse<IClientPersonalInfo>>(
                    [clientId, "basic-personal-info"],
                    (prev) => {
                        const prevRequired =
                            prev as IApiResponse<IClientPersonalInfo>;

                        if (res.data.relation_info[0].is_primary_contact) {
                            return {
                                message: prevRequired.message,
                                data: {
                                    ...prevRequired.data,
                                    relation_info:
                                        prevRequired.data.relation_info
                                            ?.length === 0
                                            ? res.data.relation_info
                                            : prevRequired.data.relation_info
                                                  ?.map((relation) => ({
                                                      ...relation,
                                                      is_primary_contact: false,
                                                  }))
                                                  ?.concat(
                                                      res.data.relation_info
                                                  ),
                                },
                            };
                        }

                        return {
                            message: prevRequired.message,
                            data: {
                                ...prevRequired.data,
                                relation_info:
                                    prevRequired.data.relation_info?.length ===
                                    0
                                        ? res.data.relation_info
                                        : prevRequired.data.relation_info?.concat(
                                              res.data.relation_info
                                          ),
                            },
                        };
                    }
                );

                queryClient.invalidateQueries({
                    queryKey: [clientId, "basic-personal-info"],
                });
                toast({
                    mode: "success",
                    message: res.message || "Relation info added successfully",
                });
                reset();
            },
            onError: () => {
                toast({
                    mode: "error",
                    message:
                        error?.response?.data.message ||
                        "Could not add relation info at this time!",
                });
            },
        });
    };

    React.useEffect(() => {
        if (relationContact) {
            setValue("contact_phone_no", normalizePhoneNumber(relationContact));
        }
    }, [relationContact, setValue]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="px-[1px]">
            <div className="mb-16">
                <FormCheckbox
                    {...register("is_primary_contact")}
                    label="Primary contact"
                    labelClass="text-sm"
                />
            </div>
            <div className="mb-24 h-auto">
                <Input
                    {...register(`relationship_type`)}
                    label="Relationship"
                    placeholder="Relationship"
                    hasError={!!errors?.relationship_type}
                    errorText={errors?.relationship_type?.message}
                />
            </div>
            <div className="flex justify-between mb-24 two">
                <Input
                    {...register(`first_name`)}
                    label="Relation's first name"
                    placeholder="Relation's first name"
                    hasError={!!errors.first_name}
                    errorText={errors.first_name?.message}
                />
                <Input
                    {...register(`last_name`)}
                    label="Relation's last name"
                    placeholder="Relation's last name"
                    hasError={!!errors.last_name}
                    errorText={errors.last_name?.message}
                />
            </div>
            <div className="mb-24">
                <Input
                    {...register(`contact_phone_no`)}
                    label="Contact"
                    placeholder="Contact"
                    hasError={!!errors.contact_phone_no}
                    errorText={errors.contact_phone_no?.message}
                />
            </div>
            <div className="flex justify-end">
                <Button disabled={isLoading} type="submit">
                    Submit
                </Button>
            </div>
        </form>
    );
}

import * as React from "react";
import { useParams } from "react-router-dom";
import { Alert, DatePicker, Input, Select } from "@jhool-io/fe-components";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { parseISO } from "date-fns";
import { useQueryClient } from "@tanstack/react-query";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import {
    NoteLabels,
    NoteTypes,
    SessionServiceType,
} from "../../types/notes.types";
import {
    formatDate,
    formatZonedTimeToUtc,
    getDurationInMinutes,
    handleFormatDatePickerValue,
    hideNoteField,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import {
    notesWithOptionalDOS,
    notesWithoutDOS,
} from "../../../../components/Notes/CreateNote/CreateDraftNote/CreateDraftNote";
import { useEditDraftNote } from "../../../../hooks/mutations/note";
import { IEditDraftNote } from "../../../../utils/types/notes";
import useThirtydaysProviders from "../../../../hooks/useThirtydaysProviders";
import useToast from "../../../../hooks/useToast";
import { useAppSelector } from "../../../../hooks/useRedux";

type Option = {
    label: React.ReactNode;
    value: string;
};

interface EditNoteAppointmentDetailsFormProps {
    onFormSubmit(): void;
}

export default function EditNoteAppointmentDetailsForm({
    onFormSubmit,
}: EditNoteAppointmentDetailsFormProps) {
    const params = useParams();
    const noteId = params.noteId as string;
    const clientId = params.clientId as string;

    const { data, isSuccess } = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    const [localDateOfService, setLocalDateOfService] =
        React.useState<Date | null>(
            data?.data.date_of_service
                ? parseISO(data?.data.date_of_service)
                : null
        );

    const editDraftNote = useEditDraftNote();

    const { practice } = useAppSelector((state) => state.userPractice);

    const { providers } = useThirtydaysProviders();

    const queryClient = useQueryClient();

    const providersThatCanWriteNoteAfterThirtyDays = providers.map(
        (item) => item.provider_id
    );

    const shouldShowDOSAlert = data?.data.type
        ? notesWithOptionalDOS.includes(data.data.type)
        : false;

    const {
        handleSubmit,
        control,
        watch,
        register,
        formState: { errors },
    } = useForm<IEditDraftNote>({
        resolver: yupResolver(
            yup.object({
                note_type: yup.string().required("Note type is required"),
                appointment_type: yup
                    .string()
                    .when("note_type", (note_type, field) =>
                        note_type !== NoteTypes.SUPERVISION_NOTE
                            ? field.required("Appointment type is required")
                            : field
                    )
                    .notOneOf(
                        ["Individual", "individual"],
                        "Invalid or empty appointment type"
                    ),
                date_of_service: yup.date().when("note_type", {
                    is: (note_type: NoteTypes) =>
                        ![...notesWithOptionalDOS, ...notesWithoutDOS].includes(
                            note_type
                        ),
                    then: yup
                        .date()
                        .required("Date of service is required")
                        .test(
                            "date-range",
                            "You can only write a note for this session within 30 days of its occurrence",
                            (dateOfService) => {
                                const todaysDate = new Date(Date.now());

                                // Allow providers with ids in the provider providersThatCanWriteNoteAfterThirtyDays to
                                // pass this check
                                if (
                                    providersThatCanWriteNoteAfterThirtyDays.includes(
                                        practice?.provider_id || ""
                                    )
                                )
                                    return true;

                                if (dateOfService) {
                                    const diff = Math.abs(
                                        dateOfService.getTime() -
                                            todaysDate.getTime()
                                    );
                                    const diffDays = Math.ceil(
                                        diff / (1000 * 60 * 60 * 24)
                                    );

                                    return diffDays <= 30;
                                }
                                return true;
                            }
                        ),
                    otherwise: yup.date().nullable(),
                }),
                session_start_time: yup
                    .string()
                    .when("note_type", (note_type, field) =>
                        note_type !== NoteTypes.SUPERVISION_NOTE
                            ? field.required("Session start time is required")
                            : field
                    ),
                session_end_time: yup
                    .string()
                    .when("note_type", (note_type, field) =>
                        note_type !== NoteTypes.SUPERVISION_NOTE
                            ? field
                                  .required("Session end time is required")
                                  .test({
                                      test: (
                                          value: string,
                                          context: {
                                              parent: {
                                                  session_start_time: string;
                                              };
                                          }
                                      ) => {
                                          const {
                                              session_start_time:
                                                  sessionStartTime,
                                          } = context.parent;
                                          if (!sessionStartTime || !value) {
                                              return true;
                                          }
                                          const start = new Date(
                                              `1970-01-01T${sessionStartTime}:00`
                                          );
                                          const end = new Date(
                                              `1970-01-01T${value}:00`
                                          );
                                          return end > start;
                                      },
                                      message:
                                          "Session end time should be later than the start time",
                                  })
                            : field
                    ),
            })
        ),
        mode: "onChange",
        defaultValues: {
            note_type: data?.data.type || "",
            appointment_type: data?.data.appointment_type || undefined,
            date_of_service: data?.data?.date_of_service
                ? formatDate(data.data.date_of_service)
                : null,
            session_start_time: data?.data?.session_start_time
                ? formatDate(data?.data.session_start_time, false, "HH:mm")
                : undefined,
            session_end_time: data?.data?.session_end_time
                ? formatDate(data?.data.session_end_time, false, "HH:mm")
                : undefined,
        },
    });

    const dateOfServiceFromForm = watch("date_of_service");
    const sessionEndTimeFromForm = watch("session_end_time");
    const startTime = watch("session_start_time");
    const endTime = watch("session_end_time");

    const { toast } = useToast();

    const shouldShowLateNoteAlert = () => {
        if (data?.data.type) {
            if (notesWithoutDOS.includes(data.data.type)) return false;

            const currentDateTime = new Date();

            if (dateOfServiceFromForm) {
                const formattedDateOfService = `${handleFormatDatePickerValue(
                    dateOfServiceFromForm as string
                )}T${sessionEndTimeFromForm}:00.000`;

                if (new Date(formattedDateOfService) > currentDateTime) {
                    return false;
                }

                const differenceInMs = Math.abs(
                    parseISO(formattedDateOfService).getTime() -
                        new Date(Date.now()).getTime()
                );

                const hoursDifference = differenceInMs / (1000 * 60 * 60);

                return hoursDifference > 24;
            }
            return false;
        }

        return false;
    };

    const getSessionTypeSelectOptions = Object.values(SessionServiceType).map(
        (sessionType) => ({
            label: (
                <span style={{ textTransform: "capitalize" }}>
                    {removeEnumUnderscore(sessionType)}
                </span>
            ),
            value: sessionType,
        })
    );

    const shouldShowFutureNoteAlert = () => {
        if (dateOfServiceFromForm) {
            const formattedDateOfService = handleFormatDatePickerValue(
                dateOfServiceFromForm as string
            );
            const today = new Date();
            const formattedCurrentDate = today.toISOString().split("T")[0];
            if (formattedDateOfService > formattedCurrentDate) {
                return true;
            }
        }
        return false;
    };

    const isSessionShort = () => {
        if (startTime && endTime) {
            const durationInMinutes = getDurationInMinutes(startTime, endTime);

            return durationInMinutes < 55;
        }
        return false;
    };

    if (!data && !isSuccess) {
        return null;
    }

    const onSubmit = (payload: IEditDraftNote) => {
        const formattedDateOfService = localDateOfService
            ? `${handleFormatDatePickerValue(localDateOfService)}T${
                  payload?.session_start_time?.split(":")[0]
              }:${payload?.session_start_time?.split(":")[1]}:00.000Z`
            : null;

        const dataToSend = {
            ...payload,
            date_of_service:
                !notesWithoutDOS.includes(payload.note_type as NoteTypes) &&
                formattedDateOfService
                    ? formatZonedTimeToUtc(formattedDateOfService)
                    : null,
            session_start_time: formattedDateOfService
                ? formatZonedTimeToUtc(
                      `${handleFormatDatePickerValue(
                          formattedDateOfService as string
                      )}T${payload.session_start_time?.split(":")[0]}:${
                          payload.session_start_time?.split(":")[1]
                      }:00.000Z`
                  )
                : formatZonedTimeToUtc(
                      `${handleFormatDatePickerValue(
                          data?.data.session_start_time as string
                      )}T${payload.session_start_time?.split(":")[0]}:${
                          payload.session_start_time?.split(":")[1]
                      }:00.000Z`
                  ),
            session_end_time: formattedDateOfService
                ? formatZonedTimeToUtc(
                      `${handleFormatDatePickerValue(
                          formattedDateOfService as string
                      )}T${payload.session_end_time?.split(":")[0]}:${
                          payload.session_end_time?.split(":")[1]
                      }:00.000Z`
                  )
                : formatZonedTimeToUtc(
                      `${handleFormatDatePickerValue(
                          data?.data.session_start_time as string
                      )}T${payload.session_end_time?.split(":")[0]}:${
                          payload.session_end_time?.split(":")[1]
                      }:00.000Z`
                  ),
            reason_for_short_duration:
                payload.reason_for_short_duration || undefined,
        };

        editDraftNote.mutate(
            { noteId: data.data.note_id, data: dataToSend },
            {
                onSuccess: async (res) => {
                    await queryClient.invalidateQueries({
                        queryKey: [clientId, `session-note`, noteId],
                    });

                    toast({
                        mode: "success",
                        message:
                            res.message ||
                            "Note appointment details updated successfully",
                    });

                    onFormSubmit();
                },
                onError: (err) => {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not edit note appointment details note at this time",
                    });
                },
            }
        );
    };

    return (
        <form id="edit-appointment-details" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg">
                <Input
                    value={NoteLabels[data.data.type]}
                    label="Note Type"
                    disabled
                    className="disabled:bg-secondary-light cursor-not-allowed"
                />
            </div>
            <div className="fg">
                <div
                    className="fg"
                    hidden={hideNoteField(data.data.type, "appointment_type")}
                >
                    <Controller
                        name="appointment_type"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label="Appointment type"
                                defaultValue={getSessionTypeSelectOptions.find(
                                    (option) =>
                                        option.value ===
                                        data.data.appointment_type
                                )}
                                isSearchable
                                options={getSessionTypeSelectOptions}
                                onChange={(val) => {
                                    field.onChange((val as Option).value);
                                }}
                                hasError={!!errors.appointment_type}
                                errorText={errors.appointment_type?.message}
                                isLongListInDialog
                            />
                        )}
                    />
                </div>
            </div>
            {shouldShowDOSAlert && data.data.type && (
                <Alert
                    title="Notes with Date of service"
                    description="Adding date of service to this note means the client will be charged"
                    type="info"
                    classNames="mb-32"
                />
            )}
            {shouldShowLateNoteAlert() && (
                <Alert
                    title="Warning: Late Note"
                    description="The date of service you entered is more than 24 hours ago. Notes created after this timeframe will be flagged as late for the Audit team"
                    type="warning"
                    classNames="mb-32"
                />
            )}
            <div
                className="fg"
                hidden={hideNoteField(data.data.type, "date_of_service")}
            >
                <Controller
                    name="date_of_service"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            label="Date of service"
                            onChange={(date) => {
                                field.onChange(date);
                                setLocalDateOfService(date);
                            }}
                            hasError={
                                !notesWithOptionalDOS.includes(
                                    data.data.type
                                ) && !!errors.date_of_service
                            }
                            errorText={
                                errors.date_of_service?.type === "typeError"
                                    ? "invalid date value"
                                    : errors.date_of_service?.message
                            }
                            selected={localDateOfService}
                            dateFormat="MMM d, yyyy"
                        />
                    )}
                />
            </div>
            {shouldShowFutureNoteAlert() && (
                <Alert
                    title="Warning: Future Note"
                    description="This note can only be saved as draft because the DOS is still in the future"
                    type="warning"
                    classNames="mb-32"
                />
            )}
            <div className="fg fg-space-between two flex">
                <Input
                    hidden={hideNoteField(data.data.type, "start_time")}
                    {...register("session_start_time")}
                    label="Start time"
                    placeholder="Start time"
                    hasError={!!errors.session_start_time}
                    errorText={errors.session_start_time?.message}
                    type="time"
                />

                <Input
                    hidden={hideNoteField(data.data.type, "end_time")}
                    {...register("session_end_time")}
                    label="End time"
                    placeholder="End time"
                    hasError={!!errors.session_end_time}
                    errorText={errors.session_end_time?.message}
                    type="time"
                />
            </div>
            {isSessionShort() && (
                <Input
                    {...register("reason_for_short_duration")}
                    label="Reason for short note duration"
                    placeholder="Reason for short note duration"
                    hasError={!!errors.reason_for_short_duration}
                    errorText={errors.reason_for_short_duration?.message}
                />
            )}
        </form>
    );
}

export default function CalculatorIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.66667 14.6654H9.33333C12.6667 14.6654 14 13.332 14 9.9987V5.9987C14 2.66536 12.6667 1.33203 9.33333 1.33203H6.66667C3.33333 1.33203 2 2.66536 2 5.9987V9.9987C2 13.332 3.33333 14.6654 6.66667 14.6654Z"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11 5.05469V5.72135C11 6.26802 10.5533 6.72135 10 6.72135H6C5.45333 6.72135 5 6.27469 5 5.72135V5.05469C5 4.50802 5.44667 4.05469 6 4.05469H10C10.5533 4.05469 11 4.50135 11 5.05469Z"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.42408 9.33333H5.43178"
                stroke="#576A81"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.99683 9.33333H8.00454"
                stroke="#576A81"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.5693 9.33203H10.577"
                stroke="#576A81"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.42432 11.668H5.43202"
                stroke="#576A81"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.99683 11.6654H8.00454"
                stroke="#576A81"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.5696 11.6654H10.5773"
                stroke="#576A81"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

import { Alert, Select } from "@jhool-io/fe-components";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useChangeInsuranceProvider } from "../../../hooks/claims.mutations";
import { IChangeInsurance } from "../../../types/claims.types";
import { useFetchInsuranceProviders } from "../../../../clients/hooks/clients.queries";
import { removeEnumUnderscore } from "../../../../../utils/helpers";
import useToast from "../../../../../hooks/useToast";
import { useFetchSessionNote } from "../../../../../hooks/queries/note";
import { NoteStatus } from "../../../../notes/types/notes.types";

type Options = {
    label: string;
    value: string;
};

interface ChangeInsuranceFormProps {
    onFormSubmit: () => void;
}

const schema = yup.object().shape({
    insurance_provider_id: yup
        .string()
        .required("Insurance provider is required"),
});

export default function ChangeInsuranceForm({
    onFormSubmit,
}: ChangeInsuranceFormProps) {
    const params = useParams();
    const noteId = params.noteId as string;
    const clientId = params.clientId as string;

    const { data: noteDetails } = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    // Hook for fetching insurance providers
    const insuranceProviders = useFetchInsuranceProviders();

    const { toast } = useToast();

    const queryClient = useQueryClient();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IChangeInsurance>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    // Select options for insurance providers
    const insuranceProvidersSelectOptions = insuranceProviders.data?.data
        .sort((a, b) => a.alias.localeCompare(b.alias))
        .map((provider) => ({
            label: removeEnumUnderscore(provider.alias).toUpperCase(),
            value: provider.insurance_provider_id,
        }));

    const changeInsurance = useChangeInsuranceProvider(noteId || "");

    const onSubmit = (data: IChangeInsurance) => {
        changeInsurance.mutate(data, {
            onSuccess: (res) => {
                queryClient.invalidateQueries({
                    queryKey: ["get-claim", noteId],
                });
                queryClient.invalidateQueries({
                    queryKey: [clientId, "session-note", noteId],
                });
                toast({
                    mode: "success",
                    message:
                        res.message ||
                        "Insurance provider updated successfully.",
                });
                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not update insurance provider at this time, please try again later.",
                });
            },
        });
    };

    return (
        <form id="change-insurance" onSubmit={handleSubmit(onSubmit)}>
            {noteDetails?.data &&
            noteDetails.data.note_status === NoteStatus.CODED ? (
                <Alert
                    type="info"
                    description="The attached claim will be updated with the new insurance provider."
                    classNames="mb-24"
                />
            ) : null}
            <Controller
                name="insurance_provider_id"
                control={control}
                defaultValue={
                    insuranceProvidersSelectOptions?.find(
                        (option) =>
                            option.label.toLowerCase() ===
                            noteDetails?.data.insurance_provider?.toLowerCase()
                    )?.value
                }
                render={({ field }) => (
                    <Select
                        label="Insurance provider"
                        placeholder="Select insurance provider"
                        isSearchable
                        isLongListInDialog
                        onChange={(val) => {
                            field.onChange((val as Options).value);
                        }}
                        hasError={!!errors.insurance_provider_id}
                        errorText={errors.insurance_provider_id?.message}
                        options={insuranceProvidersSelectOptions}
                        isDisabled={insuranceProviders.isLoading}
                        defaultValue={insuranceProvidersSelectOptions?.find(
                            (option) =>
                                option.label.toLowerCase() ===
                                noteDetails?.data.insurance_provider?.toLowerCase()
                        )}
                    />
                )}
            />
        </form>
    );
}

import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IApiResponse } from "../../../../utils/types/api-response";
import {
    PracticeAdminResponse,
    SettingsFilters,
} from "../../types/practice-settings";
import { fetchPracticeSettings } from "../../services/practice-settings";
import { handleRemoveEmptyParamFromQueryParams } from "../../../../utils/helpers";

export function useFetchPracticeSettings(filters?: SettingsFilters) {
    const { data, isLoading, isSuccess, error } = useQuery<
        IApiResponse<PracticeAdminResponse>,
        AxiosError<IApiResponse<PracticeAdminResponse>>
    >({
        queryKey: [
            `practice-admin`,
            handleRemoveEmptyParamFromQueryParams(filters || {}),
        ],
        queryFn: () => fetchPracticeSettings(filters),
    });

    return {
        data,
        isLoading,
        isSuccess,
        error,
    };
}

import * as React from "react";
import { DatePicker, Input, Select } from "@jhool-io/fe-components";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { parseISO } from "date-fns";
import { IAddManualRemit } from "../../types/remits.types";
import { useFetchInsuranceProviders } from "../../../../hooks/queries/client";
import {
    handleFormatDatePickerValue,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import { useEditManualRemit } from "../../hooks/remits.mutations";
import useToast from "../../../../hooks/useToast";
import { useFetchBatchRemits } from "../../hooks/remits.queries";

interface Props {
    onFormSubmit: () => void;
    // Selected batch remit id
    remitId?: string;
}

type Options = {
    label: React.ReactNode;
    value: string;
};

const schema = yup.object({
    amount: yup.number().required("Please enter a valid amount"),
    no_of_claims_payments: yup.number().required("Enter number of claims"),
    remit_date: yup.date().required("Remit date is required"),
    insurance_provider_id: yup
        .string()
        .required("Insurance provider is required"),
    remit_batch_payment_ref: yup
        .string()
        .required("Remit payment ref is required"),
});

export default function EditManualRemitForm({ onFormSubmit, remitId }: Props) {
    const insuranceProviders = useFetchInsuranceProviders();

    const params = useParams();

    const batchRemitId = params.remitId || "";

    const batchRemitData = useFetchBatchRemits(
        {
            batch_remit_id: params.remitId || remitId,
        },
        Boolean(params.remitId) || Boolean(remitId)
    );

    const [remitDate, setRemitDate] = React.useState<Date | null>(
        batchRemitData.data?.data[0].remit_received_date
            ? parseISO(batchRemitData.data?.data[0].remit_received_date)
            : null
    );

    const editManualRemit = useEditManualRemit(batchRemitId || remitId || "");

    const queryClient = useQueryClient();

    // Toast for success and error states
    const { toast } = useToast();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IAddManualRemit>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const onSubmit = (data: IAddManualRemit) => {
        const dataToSend = {
            ...data,
            remit_date: handleFormatDatePickerValue(data.remit_date),
        };

        editManualRemit.mutate(dataToSend, {
            onSuccess: (res) => {
                queryClient.invalidateQueries({
                    queryKey: [
                        "batch-remits",
                        { batch_remit_id: batchRemitId },
                    ],
                });
                queryClient.invalidateQueries({
                    queryKey: ["batch-remits"],
                });
                toast({
                    mode: "success",
                    message: res.message || "Remit added successfully.",
                });
                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message || "Could not add remit.",
                });
            },
        });
    };

    const insuranceProvidersForSelect = insuranceProviders.data?.data
        .sort((a, b) => a.alias.localeCompare(b.alias))
        .map((provider) => ({
            label: removeEnumUnderscore(provider.alias).toUpperCase(),
            value: provider.insurance_provider_id,
        }));

    return (
        <form id="edit-remit-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg">
                <Input
                    label="Remit Payment Ref"
                    {...register("remit_batch_payment_ref")}
                    hasError={!!errors.remit_batch_payment_ref}
                    errorText={errors.remit_batch_payment_ref?.message}
                    placeholder="Enter remit payment ref"
                    defaultValue={
                        batchRemitData.data?.data[0].remit_batch_payment_ref
                    }
                />
            </div>

            <div className="fg fg-space-between two flex">
                <Input
                    label="Remit Amount"
                    {...register("amount")}
                    hasError={!!errors.amount}
                    errorText={
                        errors.amount?.type === "typeError"
                            ? "This field is required and must be a number"
                            : "Amount must be greater than 0"
                    }
                    showCurrency
                    placeholder="$ USD 00"
                    defaultValue={batchRemitData.data?.data[0].remit_amount}
                />

                <Controller
                    name="insurance_provider_id"
                    control={control}
                    defaultValue={
                        insuranceProvidersForSelect?.find(
                            (option) =>
                                option.label.toLowerCase() ===
                                batchRemitData.data?.data[0].insurance_provider_name.toLowerCase()
                        )?.value
                    }
                    render={({ field }) => (
                        <Select
                            label="Insurance"
                            wrapperClass="select"
                            options={insuranceProvidersForSelect}
                            isDisabled={insuranceProviders.isLoading}
                            onChange={(val) => {
                                field.onChange((val as Options).value);
                            }}
                            hasError={!!errors.insurance_provider_id}
                            errorText={errors.insurance_provider_id?.message}
                            isSearchable
                            defaultValue={insuranceProvidersForSelect?.find(
                                (option) =>
                                    option.label.toLowerCase() ===
                                    batchRemitData.data?.data[0].insurance_provider_name.toLowerCase()
                            )}
                        />
                    )}
                />
            </div>

            <div className="fg">
                <Input
                    label="Maximum number of claims payments"
                    {...register("no_of_claims_payments")}
                    hasError={!!errors?.no_of_claims_payments}
                    placeholder="Enter maximum number of claims payments"
                    type="number"
                    errorText={
                        errors.amount?.type === "typeError"
                            ? "This field is required and must be a number"
                            : "This field is required"
                    }
                    defaultValue={
                        batchRemitData.data?.data[0]
                            .no_of_claims_payments_from_file
                    }
                />
            </div>

            <div className="fg">
                <Controller
                    name="remit_date"
                    control={control}
                    defaultValue={
                        batchRemitData.data?.data[0].remit_received_date
                    }
                    render={({ field }) => (
                        <DatePicker
                            label="Remit received date"
                            placeholderText="MM/DD/YYYY"
                            isClearable
                            onChange={(date) => {
                                field.onChange(date);
                                setRemitDate(date);
                            }}
                            selected={remitDate}
                            hasError={!!errors.remit_date}
                            errorText={
                                errors.remit_date?.type === "typeError"
                                    ? "invalid date value"
                                    : errors.remit_date?.message
                            }
                        />
                    )}
                />
            </div>
        </form>
    );
}

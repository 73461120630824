export default function TimesTwoIcon() {
    return (
        <svg
            width="24"
            height="35"
            viewBox="0 0 24 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_8982_17717)">
                <path
                    d="M16.5491 17.4545L23.372 10.6316C24.2093 9.7943 24.2093 8.4368 23.372 7.59885L21.8557 6.08248C21.0184 5.24521 19.6609 5.24521 18.823 6.08248L12 12.9054L5.17705 6.08248C4.33977 5.24521 2.98227 5.24521 2.14432 6.08248L0.627955 7.59885C-0.209318 8.43612 -0.209318 9.79362 0.627955 10.6316L7.45091 17.4545L0.627955 24.2775C-0.209318 25.1148 -0.209318 26.4723 0.627955 27.3102L2.14432 28.8266C2.98159 29.6638 4.33977 29.6638 5.17705 28.8266L12 22.0036L18.823 28.8266C19.6602 29.6638 21.0184 29.6638 21.8557 28.8266L23.372 27.3102C24.2093 26.4729 24.2093 25.1154 23.372 24.2775L16.5491 17.4545Z"
                    fill="#F71735"
                />
            </g>
            <defs>
                <clipPath id="clip0_8982_17717">
                    <rect width="24" height="34.9091" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { Button, Card } from "@jhool-io/fe-components";
import { useParams, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";

import { useGetClaim } from "../../hooks/claims.queries";
import { useUpdateClaimContent } from "../../hooks/claims.mutations";
import { IClaimDetailsContent } from "../../types/claims.types";
import PersonalInfoForm from "./PersonalInfoForm/PersonalInfoForm";
import DiagnosisInfoForm from "./DiagnosisInfoForm/DiagnosisInfoForm";
import ServicesInfoForm from "./ServicesInfoForm/ServicesInfoForm";
import useToast from "../../../../hooks/useToast";
import ListState from "../../../../components/ListState/ListState";
import Skeleton from "../../../../components/Skeleton/Skeleton";

interface EditClaimsProps {
    claimToEditId: string;
}

export default function EditClaims({ claimToEditId }: EditClaimsProps) {
    const [searchParams, setSearchParams] = useSearchParams();

    const params = useParams();
    const { noteId } = params;

    const updateClaimContent = useUpdateClaimContent();

    const { data, isLoading, error, isSuccess } = useGetClaim(
        { claim_id: claimToEditId },
        Boolean(claimToEditId)
    );

    const schema = yup.object().shape({
        // personal info
        insuranceType: yup.string(),
        subscriberId: yup.string(),
        subscriberName: yup.string(),
        subscriberAddress: yup.string(),
        subscriberCity: yup.string(),
        subscriberState: yup.string(),
        subscriberPhone: yup.string(),
        subscriberZip: yup.string(),
        subscriberPolicyGroup: yup.string(),
        insuranceGroupName: yup.string(),
        subscriberBirthDate: yup.string(),
        subscriberSex: yup.string(),
        patientName: yup.string(),
        patientAddress: yup.string(),
        patientCity: yup.string(),
        patientState: yup.string(),
        patientPhone: yup.string(),
        patientZip: yup.string(),
        patientBirthDate: yup.string(),
        patientSex: yup.string(),
        patientRelationshipToSubscriber: yup.string(),
        isConditionRelatedToEmployment: yup.string(),
        isConditionAutoAccidentRelated: yup.string(),
        autoAccidentState: yup.string(),
        isConditionOtherAccidentRelated: yup.string(),
        otherInsurancePlanProgram: yup.string(),
        otherSubscriberName: yup.string(),
        otherSubscriberId: yup.string(),
        otherInsurancePlanName: yup.string(),
        patientSignature: yup.string(),
        patientSignatureDate: yup.string(),
        subscriberSignature: yup.string(),

        // diagnosis info
        outsideLabCharge: yup.string(),
        diagnosisCode1: yup.string(),
        diagnosisCode2: yup.string(),
        diagnosisCode3: yup.string(),
        diagnosisCode4: yup.string(),
        diagnosisCode5: yup.string(),
        diagnosisCode6: yup.string(),
        diagnosisCode7: yup.string(),
        diagnosisCode8: yup.string(),
        diagnosisCode9: yup.string(),
        diagnosisCode10: yup.string(),
        diagnosisCode11: yup.string(),
        diagnosisCode12: yup.string(),

        // services info
        federalTaxIdType: yup.string(),
        totalCharge: yup.number(),
        amountPaid: yup.number(),
        federalTaxId: yup.string(),
        claimReferenceOrPatientAcctNo: yup.string(),
        acceptAssignment: yup.string(),
        renderingProviderFirstName: yup.string(),
        renderingProviderSignatureDate: yup.string(),
        billingProviderNPI: yup.string(),
        billingProviderName: yup.string(),
        billingProviderAddress1: yup.string(),
        billingProviderCity: yup.string(),
        billingProviderState: yup.string(),
        billingProviderPhone: yup.string(),
        billingProviderZipCode: yup.string(),
    });

    const { toast } = useToast();

    const queryClient = useQueryClient();
    const { register, setValue, formState, watch, control, handleSubmit } =
        useForm<IClaimDetailsContent>({
            resolver: yupResolver(schema),
            mode: "onChange",
        });

    const onSubmit = (payload: IClaimDetailsContent) => {
        const updateClaimContentPayload = {
            claim_id: claimToEditId,
            claim_content_to_update: payload,
        };

        updateClaimContent.mutate(updateClaimContentPayload, {
            onSuccess: (res) => {
                queryClient.invalidateQueries([
                    "get-claim",
                    { claim_id: claimToEditId },
                ]);

                queryClient.invalidateQueries([
                    "get-claim",
                    { note_id: noteId },
                ]);

                toast({
                    mode: "success",
                    message:
                        res.message || "Claim content updated successfully",
                });

                searchParams.set("claims_view", "view");
                setSearchParams(searchParams);
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.message ||
                        "Could not update claim content at this time. Please try again.",
                });
            },
        });
    };

    const onCancel = () => {
        searchParams.set("claims_view", "view");
        searchParams.delete("claim_id");
        setSearchParams(searchParams);
    };

    return (
        <>
            {isLoading && (
                <Skeleton
                    type="document"
                    containerTestId="edit-claim-loader"
                    count={12}
                />
            )}

            {error && error?.response?.status !== 404 && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot edit claim at this time. Please try again later`
                    }
                />
            )}

            {isSuccess && data?.data ? (
                <div className="mt-32">
                    <Card classNames="px-12 md:p-0">
                        <form
                            className="bg-white rounded-r6"
                            id="update-claim-content"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="px-8 md:px-24 py-20 border-b border-b-strokedark flex gap-16 md:gap-0 flex-col md:flex-row justify-between md:items-center sticky  top-0 z-[4] bg-white">
                                <h3 className="text-base font-bold">
                                    Edit Claims Info
                                </h3>

                                <div className="flex items-center gap-12">
                                    <Button
                                        variant="secondary"
                                        onClick={onCancel}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit">Save changes</Button>
                                </div>
                            </div>

                            <PersonalInfoForm
                                claimDetails={data.data[0]}
                                register={register}
                                formState={formState}
                                setValue={setValue}
                                watch={watch}
                                control={control}
                            />

                            <DiagnosisInfoForm
                                claimDetails={data.data[0]}
                                register={register}
                                formState={formState}
                                setValue={setValue}
                                watch={watch}
                            />

                            <ServicesInfoForm
                                claimDetails={data.data[0]}
                                register={register}
                                formState={formState}
                                setValue={setValue}
                                control={control}
                            />
                        </form>
                    </Card>
                </div>
            ) : null}
        </>
    );
}

import Navbar from "../../../components/Navbar/Navbar";
import AnnouncementsList from "../components/AnnouncementsList/AnnouncementsList";

export default function Announcements() {
    return (
        <>
            <Navbar title="Announcements" />
            <div className="page">
                <AnnouncementsList />
            </div>
        </>
    );
}

import { format, set } from "date-fns";
import { twMerge } from "tailwind-merge";
import { clsx, ClassValue } from "clsx";
import {
    formatInTimeZone as dateFnsFormatInTimeZone,
    zonedTimeToUtc as dateFnsZonedTimeToUtc,
} from "date-fns-tz";
import moment from "moment";
import { NoteTypes } from "../types/notes";
import { UNIQUE_CLIENT_EMAIL_REGEX } from "../constants";

/** Check if user is using small screen */
export const isSmallScreen = () => {
    return window.matchMedia("(max-width: 768px)").matches;
};

/** Helps with
 * - Avoiding tailwind classes conflict
 * - Add classes based on different conditions
 */
export const cn = (...args: ClassValue[]) => {
    return twMerge(clsx(args));
};

/** GET AMOUNT OF ROW SELECTED IN A TABLE */
export const getTotalAmountOfTableRowsSelected = (
    selectedState: Record<number, boolean>
) => {
    const totalRowsSelected = Object.keys(selectedState).length;

    return {
        amountString: `${totalRowsSelected} selected`,
        amount: totalRowsSelected,
    };
};

// FUNCTION TO GET STATE FROM ADDRESS
export const getStateFromAddress = (state: string) => {
    const byComma = state.split(",");
    const bySpace = state.split(" ");
    const toUse = byComma.length > 1 ? byComma : bySpace;
    return toUse?.slice(-1).join(" ").replace(",", "").trim();
};

// FUNCTION TO ADD COMMA TO INPUT AMOUNT
export function numberWithCommas(value: string) {
    const formattedValue = value
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedValue;
}

/** CAPITALIZE THE FIRST LETTER OF A STRING */
export const makeStringFirstLetterCapital = (str: string) => {
    if (!str) return "";
    const words = str.split(/(?=[A-Z])/); // Split string at capital letters
    if (words.length > 1) {
        // If there are multiple words, capitalize the first letter of each word
        const capitalizedWords = words.map((word) => {
            const stringFirstLetter = word[0];
            const capitalizedStringFirstLetter =
                stringFirstLetter?.toUpperCase();
            const remainingStringLetters = word?.slice(1);
            return `${capitalizedStringFirstLetter}${remainingStringLetters}`;
        });

        return capitalizedWords.join(" ");
    }
    // If there is only one word, capitalize the first letter
    const stringFirstLetter = str[0];
    const capitalizedStringFirstLetter = stringFirstLetter?.toUpperCase();
    const remainingStringLetters = str?.slice(1);

    return `${capitalizedStringFirstLetter}${remainingStringLetters}`;
};

/** TURN ARRAY TO STRING */
export const convertArrayToString = (array: string[], separator?: string) => {
    return array.join(separator || " ");
};

/** FORMAT MONEY VALUES TO APP CURRENCY DISPLAY  */
export const showMoneyInAppFormat = (amount: string | number) => {
    // Convert amount to valid number
    const convertedAmount = Number(amount);

    // Check if converted value is a valid number
    const isValidNumber =
        !Number.isNaN(convertedAmount) && Number.isFinite(convertedAmount);

    // Format amount to desired style
    if (isValidNumber) {
        return new Intl.NumberFormat("us-EN", {
            style: "currency",
            currency: "USD",
        })
            .format(convertedAmount)
            .replace("US", "");
    }
    return "--";
};

export const NUMBER_REGEX = /^\d+$/;

/** GET IF A FILTER CHECKBOX IS CHECKED */
export const handleIsCheckboxIsChecked = <T extends { value: string }>(
    value: string,
    selectedData: T[]
) => {
    const valueObject = selectedData.find((note) => note.value.includes(value));
    return Boolean(valueObject);
};

/** REMOVE EMPTY QUERY PARAMS FROM PARAMS OBJECT */
export const handleRemoveEmptyParamFromQueryParams = <
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    T extends Record<string, any>
>(
    paramsObj: T
) => {
    return Object.fromEntries(
        Object.entries(paramsObj).filter(([, v]) => {
            if (typeof v === "string") return v !== "";
            if (Array.isArray(v)) return v.length !== 0;
            return Boolean(v) !== false;
        })
    );
};

/** FORMAT DATE PICKER VALUE TO REQUIRED FORMAT - returns string `year-month-day` */
export const handleFormatDatePickerValue = (inputValue: string | Date) => {
    const date = new Date(inputValue);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
};

// GET DATE IN THE FORMAT "2020-10-05" from an isostring
export const getDateFromIsoString = (isostring: string) => {
    return isostring.split("T")[0];
};
export const getCorrectTimeValueFromIsoString = (isostring: string) => {
    if (!Date.parse(isostring)) return "--";

    const hour = isostring.split("T")[1]?.split(":")[0];
    const minute = isostring.split("T")[1]?.split(":")[1];

    return `${hour}:${minute}`;
};

// GET CORRECT TIME VALUE FROM ISO STRING
export const getCorrectTimeValueFromIsoStringInNewYorkTimeZone = (
    isostring: string
) => {
    if (!Date.parse(isostring)) return "--";

    const date = new Date(isostring);

    const timeOptions: Intl.DateTimeFormatOptions = {
        timeZone: "America/New_York",
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
        hourCycle: "h12", // This specifies 12-hour clock cycle
    };

    const timeString = date.toLocaleTimeString("en-US", timeOptions);
    const meridiem = timeString.slice(-2).toLocaleLowerCase(); // Get the last two characters (AM/PM) and convert to lowercase

    return timeString.replace(/AM|PM/, meridiem); // Replace AM/PM with lowercase meridiem
};
// FUNCTION GET DAY OF THE WEEK FROM ISO STRING
export const getDayOfWeekFromIsoString = (isostring: string) => {
    if (!Date.parse(isostring)) return "--";

    const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];
    const date = new Date(isostring);

    const dayIndex = date.getDay();
    const dayOfWeek = daysOfWeek[dayIndex];

    return dayOfWeek;
};

/** GET NEW DATE FROM TIME VALUE */
export const setDateFromTimeValue = (hr: number, min: number, date: string) => {
    return set(new Date(date), {
        hours: hr,
        minutes: min,
    }).toISOString();
};

export const createDateTimeWithTimezone = (
    dateString: string,
    timeString: string,
    timezone = "America/New_York"
): Date => {
    const dateTimeString = `${dateString}T${timeString}`;
    const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone: timezone,
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
    });

    const [formattedDateTime] = formatter
        .formatToParts(new Date(dateTimeString))
        .map(({ value }) => value);

    return new Date(formattedDateTime);
};

/** GET ARRAY OF VALUES FROM RANGE */
export const range = (start: number, end: number) => {
    const length = end - start + 1;
    /* Create an array of certain length and set the elements within from
     start value to end value. */
    return Array.from({ length }, (_, idx) => idx + start);
};

/** REMOVES UNDERSCORE FROM ENUM VALUES */
export const removeEnumUnderscore = (value: string) => {
    return value?.replace(/_/g, " ");
};

export const hideNoteField = (
    noteType: NoteTypes,
    fieldName: string,
    context: "create-note" | "view-note" = "create-note"
) => {
    if (context === "create-note") {
        switch (noteType) {
            case NoteTypes.TERMINATION_NOTE:
                if (
                    fieldName === "provider_name" ||
                    fieldName === "provider_signature" ||
                    fieldName === "supervisor_name" ||
                    fieldName === "update_note_content"
                )
                    return true;
                return false;
            case NoteTypes.SUPERVISION_NOTE:
                if (
                    fieldName === "client_name" ||
                    fieldName === "provider_name" ||
                    fieldName === "note_type"
                )
                    return false;
                return true;
            case NoteTypes.RECORD_OF_DISCLOSURE:
                if (
                    fieldName === "provider_name" ||
                    fieldName === "provider_signature" ||
                    fieldName === "supervisor_name" ||
                    fieldName === "update_note_content"
                )
                    return true;
                return false;
            case NoteTypes.INDIVIDUAL_INTAKE_NOTE:
                if (
                    fieldName === "provider_name" ||
                    fieldName === "provider_signature" ||
                    fieldName === "supervisor_name" ||
                    fieldName === "update_note_content"
                )
                    return true;
                return false;
            case NoteTypes.MINOR_INTAKE_NOTE:
                if (
                    fieldName === "provider_name" ||
                    fieldName === "provider_signature" ||
                    fieldName === "supervisor_name" ||
                    fieldName === "update_note_content"
                )
                    return true;
                return false;
            case NoteTypes.FAMILY_INTAKE_NOTE:
                if (
                    fieldName === "provider_name" ||
                    fieldName === "provider_signature" ||
                    fieldName === "supervisor_name" ||
                    fieldName === "update_note_content"
                )
                    return true;
                return false;
            case NoteTypes.CANCELLATION_NOTE:
                if (
                    fieldName === "provider_name" ||
                    fieldName === "provider_signature" ||
                    fieldName === "supervisor_name" ||
                    fieldName === "update_note_content"
                )
                    return true;
                return false;
            case NoteTypes.FAMILY_SOAP_NOTE:
                if (
                    fieldName === "provider_name" ||
                    fieldName === "provider_signature" ||
                    fieldName === "supervisor_name" ||
                    fieldName === "update_note_content"
                )
                    return true;
                return false;
            case NoteTypes.INDIVIDUAL_SOAP_NOTE:
                if (
                    fieldName === "provider_name" ||
                    fieldName === "provider_signature" ||
                    fieldName === "supervisor_name" ||
                    fieldName === "update_note_content"
                )
                    return true;
                return false;
            case NoteTypes.INDIVIDUAL_TREATMENT_REVIEW:
                if (
                    fieldName === "provider_name" ||
                    fieldName === "provider_signature" ||
                    fieldName === "supervisor_name" ||
                    fieldName === "update_note_content"
                )
                    return true;
                return false;
            case NoteTypes.FAMILY_TREATMENT_REVIEW:
                if (
                    fieldName === "provider_name" ||
                    fieldName === "provider_signature" ||
                    fieldName === "supervisor_name" ||
                    fieldName === "update_note_content"
                )
                    return true;
                return false;
            case NoteTypes.SAFETY_PLAN:
                if (
                    fieldName === "provider_name" ||
                    fieldName === "provider_signature" ||
                    fieldName === "supervisor_name" ||
                    fieldName === "update_note_content"
                )
                    return true;
                return false;
            case NoteTypes.GENERAL_UPDATE_NOTE:
            case NoteTypes.SUPERVISOR_CHECK_IN:
            case NoteTypes.PAUSE_NOTIFICATION_NOTE:
                if (
                    fieldName === "date_of_service" ||
                    fieldName === "start_time" ||
                    fieldName === "end_time" ||
                    fieldName === "appointment_type" ||
                    fieldName === "provider_name"
                )
                    return true;
                return false;

            default:
                return false;
        }
    } else {
        switch (noteType) {
            case NoteTypes.SUPERVISION_NOTE:
                if (
                    fieldName === "client_name" ||
                    fieldName === "provider_name" ||
                    fieldName === "supervisor_name" ||
                    fieldName === "supervisor_signature"
                )
                    return false;
                return true;
            case NoteTypes.TERMINATION_NOTE:
            case NoteTypes.INDIVIDUAL_SOAP_NOTE:
            case NoteTypes.FAMILY_SOAP_NOTE:
            case NoteTypes.FAMILY_INTAKE_NOTE:
            case NoteTypes.INDIVIDUAL_INTAKE_NOTE:
            case NoteTypes.INDIVIDUAL_TREATMENT_REVIEW:
            case NoteTypes.FAMILY_TREATMENT_REVIEW:
                if (
                    fieldName === "supervisor_name" ||
                    fieldName === "supervisor_signature"
                )
                    return true;
                return false;
            case NoteTypes.RECORD_OF_DISCLOSURE:
                if (
                    fieldName === "appointment_location" ||
                    fieldName === "supervisor_name" ||
                    fieldName === "supervisor_signature"
                )
                    return true;
                return false;
            case NoteTypes.CANCELLATION_NOTE:
                if (
                    fieldName === "date_of_service" ||
                    fieldName === "appointment_type" ||
                    fieldName === "provider_name" ||
                    fieldName === "provider_signature"
                )
                    return false;
                return true;
            case NoteTypes.SAFETY_PLAN:
                if (
                    fieldName === "appointment_type" ||
                    fieldName === "provider_name" ||
                    fieldName === "provider_signature" ||
                    fieldName === "client_name"
                )
                    return false;
                return true;
            default:
                return false;
        }
    }
};

/** CONVERT TIME STRING FROM 24HOURS TO  12HOURS */
export const convertTimeTo12HoursFormat = (timeString: string) => {
    let hours = timeString.split(":")[0];
    let minutes = timeString.split(":")[1];
    let period = "am";

    if (Number(hours) >= 12) {
        period = "pm";
        hours = String(Number(hours) - 12);
    }

    if (Number(hours) === 0) {
        hours = "12";
    }

    if (Number(minutes) === 0) {
        minutes = "00";
    }

    return `${Number(hours)}:${minutes}${period}`;
};

/** GET DURATION BETWEEN TWO TIMESTRING */
export function getTimeDuration(start: string, end: string): string {
    const startDate = new Date(`1970-01-01T${start}Z`);
    const endDate = new Date(`1970-01-01T${end}Z`);
    const diff = Math.abs(endDate.getTime() - startDate.getTime());

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    let duration = "";

    if (hours > 0) {
        duration = `${hours}${hours > 1 ? "hrs" : "hr"}`;
    }

    if (minutes % 60 > 0) {
        duration = `${duration} ${minutes % 60}${
            minutes % 60 > 1 ? "mins" : "min"
        }`;
    }

    if (seconds % 60 > 0) {
        duration = `${duration} ${seconds % 60}${
            seconds % 60 > 1 ? "secs" : "sec"
        }`;
    }

    return duration.trim();
}

/** FUNCTION TO ASCERTAIN THAT TIME DIFFERENCE IS WITHIN AN HOUR  */
export function checkTimeDifferenceWithinHour(
    timeString1: string,
    timeString2: string
): boolean {
    const time1 = new Date(`1970-01-01T${timeString1}:00`);
    const time2 = new Date(`1970-01-01T${timeString2}:00`);
    const diff = Math.abs(time1.getTime() - time2.getTime()) / 36e5; // divide by milliseconds in an hour

    return diff <= 1;
}

// FUNCTION TO BUILD SUCCESS MESSAGE
export const buildSuccessMessage = (
    updatedInvoices: string[],
    unUpdatedInvoices: string[]
) => {
    const updatedInvoiceMessage =
        updatedInvoices.length > 0
            ? `${updatedInvoices.length} Bill updated`
            : "";
    const unUpdatedInvoiceMessage =
        unUpdatedInvoices.length > 0
            ? `${unUpdatedInvoices.length} Bill not updated`
            : "";
    const message = `Update complete: ${[
        updatedInvoiceMessage,
        unUpdatedInvoiceMessage,
    ]
        .filter(Boolean)
        .join(", ")}`;
    return message;
};

// FUNCTION TO BUILD SUCCESS MESSAGE FOR MATCHED PAYMENTS
export const buildSuccessMessageForMatchedPayments = (
    matchedPayments: string[],
    unmatchedPayments: string[]
) => {
    const matchedPaymentsMessage =
        matchedPayments.length > 0
            ? `${matchedPayments.length} Payments matched`
            : "";
    const unmatchedPaymentMessage =
        unmatchedPayments.length > 0
            ? `${unmatchedPayments.length} Payments were not matched`
            : "";
    const message = `Update complete: ${[
        matchedPaymentsMessage,
        unmatchedPaymentMessage,
    ]
        .filter(Boolean)
        .join(", ")}`;
    return message;
};

/** FORMAT DATETIME IN THE RIGHT TIMEZONE */
// Fomat utc time to "America/New_York" timezone
export const formatDate = (
    isoDate: string,
    doNotConvertToTz = false,
    dateFormat = "MM/dd/yyyy",
    timeZone = "America/New_York"
) => {
    if (!Date.parse(isoDate)) return "--";

    if (doNotConvertToTz) {
        if (typeof isoDate === "string" && isoDate?.includes("Z")) {
            return format(
                new Date(isoDate.replace("Z", "")),
                dateFormat || "MM/dd/yyyy"
            );
        }
        return format(new Date(isoDate), dateFormat || "MM/dd/yyyy");
    }

    // Create a new Date object without adjusting the time
    const date = new Date(isoDate);

    return dateFnsFormatInTimeZone(date, timeZone, dateFormat || "MM/dd/yyyy");
};

/** RETURNS A DATE FILTER STRING VALUE (YYYY-MM-DD) AS A DATE SO WE CAN USE IT
 * IN THE DATE PICKER COMPONENT AND DISPLAY THE RIGHT DATE
 */
export const convertDateFilterStringToDate = (
    dateFilterValue: string | null
) => {
    if (dateFilterValue) {
        return new Date(`${dateFilterValue}T00:00:00`);
    }
    return null;
};

/** FORMAT DATETIME TO UTC DATETIME  */
//  Format "America/New_York" to utc time
export const formatZonedTimeToUtc = (
    isoDate: string | Date,
    timeZone = "America/New_York"
) => {
    if (typeof isoDate === "string") {
        if (!Date.parse(isoDate)) return "--";

        // Create a new Date object without adjusting the time
        const date = new Date(isoDate.replace("Z", ""));

        return dateFnsZonedTimeToUtc(date, timeZone).toISOString();
    }

    return dateFnsZonedTimeToUtc(isoDate, timeZone).toISOString();
};

// Convert time in seconds to the suitable equivalent in hours,minutes
// days, months or years
export function convertTimeInSecondsToOtherTimeUnits(seconds: number): string {
    const units = [
        { label: "year", seconds: 31536000 },
        { label: "month", seconds: 2592000 },
        { label: "day", seconds: 86400 },
        { label: "hr", seconds: 3600 },
        { label: "min", seconds: 60 },
        { label: "sec", seconds: 1 },
    ];

    let remainder = seconds;
    let result = "";
    let count = 0;

    units.forEach((unit) => {
        if (count >= 2) return;
        const quotient = Math.floor(remainder / unit.seconds);
        if (quotient > 0) {
            result += `${quotient} ${unit.label}${quotient !== 1 ? "s" : ""}, `;
            remainder %= unit.seconds;
            count += 1;
        }
    });
    return result ? result.slice(0, -2) : "0"; // remove trailing comma and space
}

export const showUserLocalTime = () => {
    return new Date().toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
};

// remove items with null or empty string from the search string
export function cleanSearchParams(searchParam: string) {
    // Parse the existing searchParamsString into a URLSearchParams object
    const searchParamsString = new URLSearchParams(searchParam);
    const cleanedParams = new URLSearchParams();
    // Create an object to store multi-value parameters as arrays
    const multiValueParams: Record<string, string[]> = {};
    // Iterate over the searchParamsString entries
    Array.from(searchParamsString.entries()).forEach(([key, value]) => {
        // Exclude null and empty string values
        if (value !== null && value !== "" && value !== "null") {
            // Check if this is a multi-value parameter
            if (key.endsWith("[]")) {
                const paramName = key.slice(0, -2);
                if (!multiValueParams[paramName]) {
                    multiValueParams[paramName] = [];
                }
                multiValueParams[paramName].push(value);
            } else {
                // Append single-value parameters as they are
                cleanedParams.append(key, value);
            }
        }
    });
    // Append the cleaned single-value parameters
    Array.from(cleanedParams.entries()).forEach(([key, value]) => {
        cleanedParams.delete(key);
        cleanedParams.set(key, value);
    });

    // Append the multi-value parameters
    Object.entries(multiValueParams).forEach(([paramName, values]) => {
        values.forEach((value) => {
            cleanedParams.append(`${paramName}[]`, value);
        });
    });

    return cleanedParams.toString();
}

export function convertToTextEditorCustomJSON(inputText: string) {
    try {
        // Try parsing the input to check if it's already in the desired format
        const parsedData = JSON.parse(inputText);
        if (parsedData && Array.isArray(parsedData.blocks)) {
            // If it's already in the desired format, return it as is
            return inputText;
        }
    } catch (error) {
        // Parsing failed, indicating it's not in the desired format
    }
    // Create a new JSON structure with the specified replacement text
    const jsonData = {
        blocks: [
            {
                key: "",
                text: inputText,
                type: "unstyled",
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
            },
        ],
        entityMap: {},
    };

    // Convert the JSON structure to a string with proper formatting
    const jsonString = JSON.stringify(jsonData, null, 0);
    return jsonString;
}

export function checkIfRichTextHasTextContent(text: string) {
    return Boolean(
        JSON.parse(text)?.blocks.some((item: { text: string }) =>
            Boolean(item.text)
        )
    );
}

export function convertUrlSearchParamsToObject(urlSearchParams: string) {
    // Split the string by '&' to get individual key-value pairs
    const pairs = urlSearchParams.split("&");

    // Create an empty object to store the key-value pairs
    const paramsObj: Record<string, string> = {};

    // Loop through each key-value pair and add it to the object
    for (let i = 0; i < pairs.length; i += 1) {
        const pair = pairs[i].split("=");

        // Decode the URI component to handle special characters
        const key = decodeURIComponent(pair[0]);
        const value = decodeURIComponent(pair[1]);

        // Add the key-value pair to the object
        paramsObj[key] = value;
    }

    return paramsObj;
}

export function getNextXWeeksMoment(
    currentMoment: moment.Moment,
    duration: number,
    day: number
) {
    const today = moment();
    const noOfWeeks = today.diff(currentMoment, "weeks") + 1;
    let nextWeekDay = currentMoment;
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < Math.round(noOfWeeks / duration); i++) {
        nextWeekDay = nextWeekDay.add(duration, "weeks");
    }
    if (nextWeekDay.isBefore(today)) {
        nextWeekDay = nextWeekDay.add(duration, "weeks");
    }
    nextWeekDay = nextWeekDay.day(day);
    return nextWeekDay;
}

export const addSpaceToString = (input: string) => {
    return input.replace(/([a-z])([A-Z])/g, "$1 $2");
};

export const handleDisplayClientEmailCorrectly = (email: string) => {
    // UNIQUE_CLIENT_EMAIL_REGEX  e.g 09092019__

    // Check if email starts with regex and remove the string
    if (UNIQUE_CLIENT_EMAIL_REGEX.test(email)) {
        return email.replace(UNIQUE_CLIENT_EMAIL_REGEX, "");
    }

    // If the email string does not start with the regex return the email the way it is
    return email;
};

// Function to display user initials in the right format
export const userInitials = (firstName: string, lastName: string) => {
    return `${firstName.charAt(0).toUpperCase()}${lastName
        .charAt(0)
        .toUpperCase()}`;
};

// function to truncate string length
export const truncateString = (str: string, length = 8) => {
    if (str.length === length || str.length < length) return str;

    return `${str.slice(0, length)}...`;
};

export const getTwoLetterAbbreviation = (input: string) => {
    // Split the input into words
    const words = input.trim().split(/\s+/);

    // Handle different cases based on word count
    if (words.length === 1) {
        // If it's a single word, return the first two letters (if available)
        return words[0].substring(0, 2).toUpperCase();
    }
    // If it's a phrase, take the first letter of the first two words
    return (words[0][0] + words[1][0]).toUpperCase();
};

export const areArraysEqual = <T>(arr1: T[], arr2: T[]): boolean => {
    if (!arr1 || !arr2) return false;
    if (arr1.length !== arr2.length) return false;
    const set1 = new Set(arr1);
    return arr2.every((value) => set1.has(value));
};

// function to return the time difference between two timestrings in minutes
export function getDurationInMinutes(start: string, end: string) {
    const startDate = new Date(`1970-01-01T${start}Z`);
    const endDate = new Date(`1970-01-01T${end}Z`);
    const diff = Math.abs(endDate.getTime() - startDate.getTime());

    const minutes = Math.floor(diff / (1000 * 60));
    return minutes;
}

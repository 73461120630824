import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DatePicker, Select } from "@jhool-io/fe-components";
import { Controller, useForm } from "react-hook-form";
import { FormatOptionLabelMeta } from "react-select";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import {
    CPT_CODES,
    NoteLabels,
    NoteTypes,
    SessionServiceType,
} from "../../../notes/types/notes.types";
import {
    cn,
    formatDate,
    handleFormatDatePickerValue,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    truncateString,
} from "../../../../utils/helpers";
import {
    IProviderSession,
    ProviderStatus,
    SessionPlaceOfServiceCode,
} from "../../../../utils/types/session";
import {
    useFetchDiagnosisCodes,
    useFetchProviderSessions,
} from "../../../../hooks/queries";
import { ModifierTypes } from "../../../../utils/types/notes";
import { CPT_CODES_DESCRIPTION } from "../../../../utils/constants";
import FolderOpenIcon from "../../../../components/Icons/FolderOpen";
import { useCreateManualClaim } from "../../hooks/claims.mutations";
import useToast from "../../../../hooks/useToast";
import { ICreateManualClaim } from "../../types/claims.types";
import TimesIcon from "../../../../components/Icons/Times";
import DotIcon from "../../../../components/Icons/DotIcon";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import { useAppSelector } from "../../../../hooks/useRedux";

type Options = {
    label: string;
    value: string;
};

interface CreateClaimProps {
    onFormSubmit: () => void;
}

const schema = yup.object().shape({
    pos_code: yup.string().required("POS code is required"),
    cpt_code: yup.string().required("CPT code is required"),
    primary_diagnosis: yup
        .string()
        .required("Primary diagnosis code is required"),
    secondary_diagnosis: yup.string(),
    tertiary_diagnosis: yup.string(),
    modifier_codes: yup.array().min(1).required("Modifier code is required"),
    session_id: yup.string().required("Session is required"),
    date_of_service: yup.date().required("Date of service is required"),
    note_type: yup.string().required("Note type is required"),
    appointment_type: yup.string().required("Session type is required"),
});

export default function CreateManualClaimForm({
    onFormSubmit,
}: CreateClaimProps) {
    const [searchValue, setSearchValue] = React.useState("");
    const [diagnosticSearchValue, setDiagnosticSearchValue] =
        React.useState("");
    const [dateOfService, setDateOfService] = React.useState<Date | null>(null);
    const [selectedFiles, setSelectedFiles] = React.useState<File[] | null>(
        null
    );

    // Fetch provider's sessions list
    const {
        data: providerSessions,
        isLoading: sessionsLoading,
        error: sessionsError,
    } = useFetchProviderSessions({
        search_string: searchValue,
        show_sessions_with_term_notes: true,
    });

    const { data: diagnosisCodes, isLoading, error } = useFetchDiagnosisCodes();

    const { practice } = useAppSelector((state) => state.userPractice);

    const createManualClaim = useCreateManualClaim();

    const { toast } = useToast();

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
    } = useForm<ICreateManualClaim>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const navigate = useNavigate();
    const PlaceOfServiceOptions = [
        ...Object.values(SessionPlaceOfServiceCode).map((place) => ({
            value: place,
            label: place,
        })),
    ];

    const getSessionTypeSelectOptions = Object.values(SessionServiceType).map(
        (sessionType) => ({
            label: (
                <span style={{ textTransform: "capitalize" }}>
                    {removeEnumUnderscore(sessionType)}
                </span>
            ),
            value: sessionType,
        })
    );

    const getNoteTypeSelectOptions = [
        NoteTypes.CANCELLATION_NOTE,
        NoteTypes.FAMILY_SOAP_NOTE,
        NoteTypes.INDIVIDUAL_SOAP_NOTE,
        NoteTypes.RECORD_OF_DISCLOSURE,
        NoteTypes.TERMINATION_NOTE,
        NoteTypes.INDIVIDUAL_TREATMENT_REVIEW,
        NoteTypes.FAMILY_TREATMENT_REVIEW,
        NoteTypes.INDIVIDUAL_INTAKE_NOTE,
        NoteTypes.FAMILY_INTAKE_NOTE,
        NoteTypes.MINOR_INTAKE_NOTE,
    ]
        .sort((a, b) => a.localeCompare(b))
        .map((noteType) => ({
            label: NoteLabels[noteType],
            value: noteType,
        }));

    const DiagnosisCodesForSelect = diagnosisCodes?.data.map((diagnosis) => ({
        label: `${diagnosis.code} ${diagnosis.description}`,
        value: diagnosis.diagnosis_code_id,
    }));

    // Get filter options for diagnosis list
    const filterDiagnosisOptions = (phrases: string[]) => {
        const filteredOptions = DiagnosisCodesForSelect?.filter((option) =>
            phrases.every(
                (phrase) =>
                    option &&
                    option.label.toLowerCase().includes(phrase.toLowerCase())
            )
        );
        return filteredOptions;
    };

    const modifiersTypesForSelect: Options[] = [
        ...Object.values(ModifierTypes).map((item) => ({
            value: item.replace(/[^0-9]/g, ""),
            label: item,
        })),
    ];

    const makeLabelsAndValuesProviderSessionSelect = (
        session: IProviderSession
    ) => {
        return {
            label: (
                <div>
                    <div className="flex items-center">
                        <span>{`${makeStringFirstLetterCapital(
                            session.client.first_name
                        )} ${makeStringFirstLetterCapital(
                            session.client.last_name
                        )} - ${makeStringFirstLetterCapital(
                            removeEnumUnderscore(session.service)
                        )}`}</span>
                        <span className=" ml-1">
                            <DotIcon />{" "}
                            {makeStringFirstLetterCapital(
                                session.provider.first_name
                            )}{" "}
                            {session.provider.last_name}
                        </span>
                    </div>
                    <div className="text-gray text-xs">
                        {`${makeStringFirstLetterCapital(
                            session.session_day
                        )}, ${formatDate(
                            session.start_date_time,
                            false,
                            "h:mmaaa"
                        )} - ${formatDate(
                            session.end_date_time,
                            false,
                            "h:mmaaa"
                        )}`}
                        {" - "}
                        <span
                            className={cn(
                                "text-xs inline-block font-medium capitalize",
                                {
                                    "text-primary":
                                        session.status ===
                                        ProviderStatus.ACTIVE,
                                    "text-danger":
                                        session.status ===
                                        ProviderStatus.INACTIVE,
                                }
                            )}
                        >
                            {session.status === ProviderStatus.INACTIVE
                                ? "Terminated"
                                : session.status}
                        </span>
                    </div>
                </div>
            ),
            value: session.session_id,
        };
    };

    const filterSessionsToCorrectShape = (sessions: IProviderSession[]) => {
        // Step 1: Add a key to each session
        const sessionsWithKey = sessions.map((session) => ({
            ...session,
            key: `${session.client.first_name} ${session.client.last_name} - ${session.provider.first_name} ${session.provider.last_name} - ${session.service}`,
        }));

        // Step 2: Group sessions by key
        const groupedSessions = sessionsWithKey.reduce((acc, session) => {
            if (!acc[session.key]) {
                // eslint-disable-next-line no-param-reassign
                acc[session.key] = [];
            }
            acc[session.key].push(session);
            return acc;
        }, {} as Record<string, typeof sessionsWithKey>);

        // Step 3: Filter groups to keep only non-cancelled sessions
        const filteredSessions = Object.values(groupedSessions).map((group) => {
            const nonCancelledSessions = group.filter(
                (session) => session.status === ProviderStatus.ACTIVE
            );
            return nonCancelledSessions.length > 0
                ? nonCancelledSessions[0]
                : group[0];
        });

        // Step 4: Remove the added 'key' property
        return filteredSessions.map(({ key, ...session }) => session);
    };

    // Custom comparison function
    const compareItems = (a: IProviderSession, b: IProviderSession) => {
        if (
            !a.provider.provider_id &&
            practice?.provider_id &&
            b.provider.provider_id &&
            practice.provider_id
        ) {
            return -1;
        }
        if (
            a.provider.provider_id &&
            practice?.provider_id &&
            !b.provider.provider_id &&
            practice?.provider_id
        ) {
            return 1;
        }
        return 0;
    };

    // Get options for client name select input
    const getProviderSessionSelectOptions = filterSessionsToCorrectShape(
        providerSessions?.data || []
    )
        .sort((a, b) => a.client.first_name.localeCompare(b.client.first_name))
        .sort(compareItems)
        .filter((session) => session)
        .map((session) => makeLabelsAndValuesProviderSessionSelect(session));

    // Get filter options for sessions list
    const filteredOptions = getProviderSessionSelectOptions?.filter(
        (option) => {
            return option.label.props.children[0].props.children[0].props.children
                .split(" ")
                .filter(Boolean)
                .join(" ")
                .toLowerCase();
        }
    );

    const setValuesFromSelectedSession = (sessionId: string) => {
        const selectedSession = providerSessions?.data.find(
            (session) => session.session_id === sessionId
        );

        setValue(
            "appointment_type",
            selectedSession?.service.toLowerCase() ===
                "Individual".toLowerCase()
                ? SessionServiceType.INDIVIDUAL_THERAPY
                : selectedSession?.service
        );

        setValue(
            "date_of_service",
            formatDate(selectedSession?.start_date_time as string)
        );

        setDateOfService(
            new Date(formatDate(selectedSession?.start_date_time as string))
        );

        setValue("session_id", selectedSession?.session_id as string);
    };

    const CPTOptions = CPT_CODES.sort((a, b) => Number(a) - Number(b)).map(
        (code) => ({
            label: `${code} - ${CPT_CODES_DESCRIPTION[code]}`,
            value: code,
        })
    );

    // Function to call when file(s) are dropped in dropzone
    const onDrop = React.useCallback(
        (acceptedFiles: File[]) => {
            if (selectedFiles && selectedFiles.length > 0) {
                setSelectedFiles([...acceptedFiles, ...selectedFiles]);
            } else setSelectedFiles([...acceptedFiles]);
        },
        [selectedFiles]
    );

    // Initialize the useDropzone hook
    const { getRootProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: {
            "application/pdf": [".pdf"],
            "image/*": [".png", ".webp", ".jpeg", ".jpg", ".avif"],
        },
    });

    const formData = new FormData();

    // Function for setting selected files
    const handleSetSelectedFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            if (selectedFiles && selectedFiles.length > 0) {
                setSelectedFiles([...e.target.files, ...selectedFiles]);
            } else setSelectedFiles([...e.target.files]);
        }
    };

    const handleRemoveSelectedFile = (filename: string) => {
        if (selectedFiles) {
            const filesArr = [...selectedFiles].filter(
                (file) => file.name !== filename
            );
            if (filesArr.length === 0) setSelectedFiles(null);
            else setSelectedFiles(filesArr);
        }
    };

    const onSubmit = (payload: ICreateManualClaim) => {
        let diagnosisToSend: { id: string; label: string }[] = [
            { id: payload.primary_diagnosis, label: "primary" },
        ];

        if (payload.secondary_diagnosis) {
            diagnosisToSend = [
                ...diagnosisToSend,
                {
                    id: payload.secondary_diagnosis,
                    label: "secondary",
                },
            ];
        }
        if (payload.tertiary_diagnosis) {
            diagnosisToSend = [
                ...diagnosisToSend,
                {
                    id: payload.tertiary_diagnosis,
                    label: "tertiary",
                },
            ];
        }

        formData.append("session_id", payload.session_id);
        formData.append("note_type", payload.note_type);
        formData.append("appointment_type", String(payload.appointment_type));
        formData.append("cpt_code", payload.cpt_code);
        formData.append("pos_code", payload.pos_code);
        formData.append(
            "date_of_service",
            handleFormatDatePickerValue(payload.date_of_service)
        );
        if (diagnosisToSend.length > 0) {
            for (let i = 0; i < diagnosisToSend.length; i += 1) {
                formData.append(
                    "diagnosis_codes",
                    JSON.stringify(diagnosisToSend)
                );
            }
        }
        if (payload.modifier_codes.length > 0) {
            for (let i = 0; i < [...payload.modifier_codes].length; i += 1) {
                formData.append(
                    "modifier_codes",
                    JSON.stringify(payload.modifier_codes)
                );
            }
        }

        if (selectedFiles && selectedFiles.length > 0) {
            for (let i = 0; i < [...selectedFiles].length; i += 1) {
                formData.append("note_file", [...selectedFiles][i]);
            }
        }

        createManualClaim.mutate(formData, {
            onSuccess: (res) => {
                toast({
                    mode: "success",
                    message:
                        res.message || "Manual claim created successfully!",
                });
                onFormSubmit();
                navigate(
                    `/claims/${res.claim_id}/${res.data.claim.client_id}/${res.data.claim.note_id}`
                );
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not create claim at this time.",
                });
            },
        });
    };

    return (
        <form id="create-claim" onSubmit={handleSubmit(onSubmit)}>
            <div className="fg">
                <Controller
                    name="session_id"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Session"
                            options={
                                providerSessions ||
                                !sessionsError ||
                                !sessionsLoading
                                    ? filteredOptions
                                    : []
                            }
                            isDisabled={Boolean(sessionsError)}
                            filterOption={() => true}
                            isSearchable
                            placeholder={
                                (!providerSessions &&
                                    sessionsLoading &&
                                    "Loading...") ||
                                (sessionsError &&
                                    !sessionsLoading &&
                                    "Error loading sessions") ||
                                (providerSessions &&
                                    !sessionsLoading &&
                                    "Select session")
                            }
                            onChange={(val) => {
                                field.onChange((val as Options).value);
                                setValuesFromSelectedSession(
                                    (val as Options).value
                                );
                            }}
                            onInputChange={(value) => {
                                setSearchValue(value);
                            }}
                            hasError={!!errors.session_id}
                            errorText={errors.session_id?.message}
                            multiHasValues={Boolean(watch("session_id"))}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            noOptionsMessage={() => {
                                if (sessionsLoading) {
                                    return <Skeleton width="100%" />;
                                }
                                return "No options";
                            }}
                        />
                    )}
                />
            </div>

            <div className="fg">
                <Controller
                    name="date_of_service"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            label="Date of Service"
                            selected={dateOfService}
                            onChange={(date) => {
                                field.onChange(date);
                                setDateOfService(date);
                            }}
                            hasError={!!errors.date_of_service}
                            errorText={
                                errors.date_of_service?.type === "typeError"
                                    ? "invalid date value"
                                    : errors.date_of_service?.message
                            }
                            maxDate={new Date(Date.now())}
                        />
                    )}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Controller
                    name="appointment_type"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Session type"
                            placeholder="Select session type"
                            isSearchable
                            options={getSessionTypeSelectOptions}
                            isLongListInDialog
                            onChange={(val) => {
                                field.onChange((val as Options).value);
                            }}
                            value={getSessionTypeSelectOptions.find(
                                (option) =>
                                    option.value === watch("appointment_type")
                            )}
                            hasError={!!errors.appointment_type}
                            errorText={errors.appointment_type?.message}
                        />
                    )}
                />
                <Controller
                    name="pos_code"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="POS code"
                            placeholder="Select POS code"
                            options={PlaceOfServiceOptions}
                            isSearchable
                            isLongListInDialog
                            onChange={(val) =>
                                field.onChange((val as Options).value)
                            }
                            hasError={!!errors.pos_code}
                            errorText={errors.pos_code?.message}
                            formatOptionLabel={(
                                selectData: unknown,
                                formatOptionLabelMeta: FormatOptionLabelMeta<unknown>
                            ) => {
                                return formatOptionLabelMeta.context === "menu"
                                    ? (selectData as Options).label
                                    : truncateString(
                                          (selectData as Options)
                                              .label as string,
                                          35
                                      );
                            }}
                        />
                    )}
                />
            </div>
            <div className="fg fg-space-between two flex">
                <Controller
                    name="cpt_code"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="CPT Code"
                            placeholder="CPT Code"
                            options={CPTOptions}
                            onChange={(val) =>
                                field.onChange((val as Options).value)
                            }
                            hasError={!!errors.cpt_code}
                            errorText={errors.cpt_code?.message}
                            isSearchable
                            isLongListInDialog
                            formatOptionLabel={(
                                selectData: unknown,
                                formatOptionLabelMeta: FormatOptionLabelMeta<unknown>
                            ) => {
                                return formatOptionLabelMeta.context === "menu"
                                    ? (selectData as Options).label
                                    : truncateString(
                                          (selectData as Options)
                                              .label as string,
                                          35
                                      );
                            }}
                        />
                    )}
                />
                <Controller
                    name="note_type"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Note type"
                            placeholder="Select note type"
                            isSearchable
                            options={getNoteTypeSelectOptions}
                            isLongListInDialog
                            onChange={(val) => {
                                field.onChange((val as Options).value);
                            }}
                            hasError={!!errors.note_type}
                            errorText={errors.note_type?.message}
                        />
                    )}
                />
            </div>
            <div className="fg">
                <Controller
                    name="primary_diagnosis"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Primary diagnosis"
                            placeholder="Primary diagnosis"
                            options={filterDiagnosisOptions(
                                diagnosticSearchValue.split(" ")
                            )}
                            isDisabled={isLoading || Boolean(error)}
                            isSearchable
                            isLongListInDialog
                            onChange={(val) =>
                                field.onChange((val as Options).value)
                            }
                            hasError={!!errors.primary_diagnosis}
                            errorText={errors.primary_diagnosis?.message}
                            onInputChange={(value) =>
                                setDiagnosticSearchValue(value)
                            }
                        />
                    )}
                />
            </div>
            <div className="fg">
                <Controller
                    name="secondary_diagnosis"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Secondary diagnosis"
                            placeholder="Secondary diagnosis"
                            options={filterDiagnosisOptions(
                                diagnosticSearchValue.split(" ")
                            )}
                            isDisabled={isLoading || Boolean(error)}
                            isSearchable
                            isLongListInDialog
                            onChange={(val) =>
                                field.onChange((val as Options).value)
                            }
                            onInputChange={(value) =>
                                setDiagnosticSearchValue(value)
                            }
                        />
                    )}
                />
            </div>
            <div className="fg">
                <Controller
                    name="tertiary_diagnosis"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Tertiary diagnosis"
                            placeholder="Tertiary diagnosis"
                            options={filterDiagnosisOptions(
                                diagnosticSearchValue.split(" ")
                            )}
                            isDisabled={isLoading || Boolean(error)}
                            isSearchable
                            isLongListInDialog
                            onChange={(val) =>
                                field.onChange((val as Options).value)
                            }
                            onInputChange={(value) =>
                                setDiagnosticSearchValue(value)
                            }
                        />
                    )}
                />
            </div>
            <div className="fg">
                <Controller
                    name="modifier_codes"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Modifier code"
                            placeholder="Modifier code"
                            options={modifiersTypesForSelect}
                            isSearchable
                            isLongListInDialog
                            isMulti
                            onChange={(val) => {
                                field.onChange(
                                    (
                                        val as {
                                            label: string;
                                            value: string;
                                        }[]
                                    ).map((code) => code.value)
                                );
                            }}
                            hasError={!!errors.modifier_codes}
                            errorText={
                                errors?.modifier_codes?.type === "min"
                                    ? "Modifier codes are required"
                                    : errors?.diagnosis_codes?.message
                            }
                            multiHasValues={Boolean(watch("modifier_codes"))}
                        />
                    )}
                />
            </div>
            <div
                className={cn(
                    "flex w-full items-center gap-x-[13px] pt-[31px] pr-[92px] pb-[30px] pl-24 border border-dashed border-primary rounded-[16px]",
                    {
                        "opacity-20": selectedFiles?.length === 1,
                        "border-[2px] border-dashed border-primary-dark":
                            isDragActive,
                    }
                )}
                {...getRootProps()}
            >
                <div className="px-[17px] py-16 border border-[#D3F7FA]  bg-primary-50 rounded-[9.667px]">
                    <FolderOpenIcon />
                </div>
                <div className="flex flex-col">
                    <div className="text-sm font-medium">
                        <label
                            htmlFor="file-upload"
                            className="font-semibold text-primary"
                        >
                            Click to upload session note{" "}
                            <input
                                className="w-full absolute left-[-99999px]"
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                onChange={handleSetSelectedFiles}
                                disabled={selectedFiles?.length === 1}
                                max="1"
                            />
                        </label>
                        or drag your file here
                    </div>
                    <p className="text-xs font-normal">
                        Maximum file size of 50 MB: .png, .jpg, .jpeg
                    </p>
                </div>
            </div>

            {createManualClaim.uploadProgress > 0 && (
                <div className="mt-5 h-4 bg-[rgba(185_186_163_0.4)]">
                    <div
                        role="progressbar"
                        aria-label="file upload progress value"
                        aria-valuenow={createManualClaim.uploadProgress}
                        style={{
                            width: `${createManualClaim.uploadProgress}%`,
                        }}
                        className="h-full rounded-r8 bg-primary"
                    />
                </div>
            )}

            <div className="mt-16">
                {selectedFiles &&
                    selectedFiles.map((file, i) => (
                        <div
                            key={`${file.name} - ${i + 1}`}
                            className="flex items-center justify-between w-full gap-x-16 mt-24"
                        >
                            <div className=" border border-strokedark rounded-r6 w-full px-10 py-[6px] font-normal text-xs ">
                                {file.name}
                            </div>
                            <Button
                                variant="normal"
                                aria-label="delete icon"
                                className="flex bg-card-bg !rounded-full p-8"
                                onClick={() =>
                                    handleRemoveSelectedFile(file.name)
                                }
                            >
                                <TimesIcon stroke="#8E8E8E" />
                            </Button>
                        </div>
                    ))}
            </div>
        </form>
    );
}

import { sortBy } from "lodash";
import {
    CalenderEvents,
    DateFormats,
    IAvailabilitySchedule,
    IClientBooking,
    IReservedSchedule,
    IWeeklySchedule,
    ProviderCaseloadApp,
    RecurrenceRule,
} from "../types/schedule.types";
import moment from "../../../utils/moment";
import { NoteStatus } from "../../notes/types/notes.types";
import { BillStatus } from "../../billing/types/billing.types";

export const sortCaseloads = (s: ProviderCaseloadApp[]) => {
    return sortBy(s, (d) => d.session_start_hr);
};

// function to sort schedules
export function sortSchedules(a: CalenderEvents[]) {
    return sortBy(a, (d) => moment(d.start as string).hour());
}

// function to determine if  client require consult call
export const doesClientRequireConsultCall = (schedule: CalenderEvents) => {
    const appointmentCheck = schedule.appointment_checks || {};
    const consultCallNeeded =
        appointmentCheck?.client_requested_call &&
        !appointmentCheck?.consult_call_completed;
    return consultCallNeeded;
};

export function getNumberOfWeeks(
    recurrence: string = RecurrenceRule.WEEKLY.toLowerCase()
): number {
    switch (recurrence.toLowerCase()) {
        case RecurrenceRule.BIWEEKLY.toLowerCase():
            return 2;
        case RecurrenceRule.WEEKLY.toLowerCase():
            return 1;
        case RecurrenceRule.TRIWEEKLY.toLowerCase():
            return 3;
        case RecurrenceRule.MONTHLY.toLowerCase():
            return 4;
        case RecurrenceRule.ONE_OFF.toLowerCase():
            return 0;
        case RecurrenceRule.TWO_MONTHS.toLowerCase():
            return 8;
        case RecurrenceRule.THREE_MONTHS.toLowerCase():
            return 12;
        default:
            return 1;
    }
}

export function getNextXWeeksMoment(
    currentMoment: moment.Moment,
    duration: number,
    day: number
) {
    const today = moment();
    const noOfWeeks = today.diff(currentMoment, "weeks") + 1;
    let nextWeekDay = currentMoment;
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < Math.round(noOfWeeks / duration); i++) {
        nextWeekDay = nextWeekDay.add(duration, "weeks");
    }
    if (nextWeekDay.isBefore(today)) {
        nextWeekDay = nextWeekDay.add(duration, "weeks");
    }
    nextWeekDay = nextWeekDay.day(day);
    return nextWeekDay;
}

// function to get description for next occurrence of schedules
export const getNextDateOfOccurrence = (
    schedule: IWeeklySchedule,
    day: number
) => {
    const startingMoment = moment(schedule.start_date);
    const numOfWeeks = getNumberOfWeeks(schedule.recurrence_rule);
    let dateDescLabel = "";

    dateDescLabel = `${dateDescLabel} Next on ${getNextXWeeksMoment(
        startingMoment,
        numOfWeeks,
        day
    ).format(DateFormats.DAY_MONTH_YEAR_FORMAT)}`;

    return dateDescLabel;
};

export const getNowEquivalent = (date: moment.Moment): moment.Moment => {
    return moment().day(date.day()).hour(date.hour()).minute(0).second(0);
};

export const getStringFromScheduleDate = (date?: string | Date): string => {
    if (!date) {
        return "";
    }
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (date as any).toUTCString();
    } catch {
        return date as string;
    }
};

export const findAvailabilityConflicts = (
    availabilitySchedules: IAvailabilitySchedule[],
    heldBookingSchedules: IAvailabilitySchedule[],
    confirmedBookingSchedules: IAvailabilitySchedule[],
    schedule: IAvailabilitySchedule | IReservedSchedule
) => {
    let potentialConflicts = [
        ...availabilitySchedules,
        ...heldBookingSchedules,
        ...confirmedBookingSchedules,
    ].filter((a) => {
        const availableWindowStart = getNowEquivalent(
            moment(getStringFromScheduleDate(a.start))
        );
        const scheduleWindowStart = getNowEquivalent(
            moment(getStringFromScheduleDate(schedule.start))
        );
        return (
            a.id &&
            schedule.id &&
            a.id !== schedule.id &&
            availableWindowStart.day() === scheduleWindowStart.day() &&
            availableWindowStart.hour() === scheduleWindowStart.hour() &&
            (schedule.recurrenceRule === RecurrenceRule.BIWEEKLY
                ? moment(a.start).format(DateFormats.DAY_MONTH_FORMAT) ===
                  moment(schedule.start).format(DateFormats.DAY_MONTH_FORMAT)
                : true)
        );
    });

    // Check for one-off appointments.
    const scheduleStartDate = schedule.starting_date || schedule.start;
    const scheduleStartDateMoment = moment(scheduleStartDate).startOf("day");
    potentialConflicts = potentialConflicts.filter((c) => {
        const conflictStartDate = c.starting_date || c.start;
        if (
            c.recurrenceRule === RecurrenceRule.ONE_OFF &&
            conflictStartDate &&
            scheduleStartDate
        ) {
            const conflictStartDateMoment =
                moment(conflictStartDate).startOf("day");
            if (schedule.recurrenceRule === RecurrenceRule.ONE_OFF) {
                return scheduleStartDateMoment.isSame(
                    conflictStartDateMoment,
                    "dates"
                );
            }
            return scheduleStartDateMoment.isSameOrBefore(
                conflictStartDateMoment,
                "dates"
            );
        }
        return true;
    });
    const hasConflicts = potentialConflicts.some(
        (c) => !(c as IClientBooking).is_paused
    );
    return hasConflicts;
};

export const getMatchingOfficeWindow = (
    officeWindowSchedules: IAvailabilitySchedule[],
    schedule: IAvailabilitySchedule | IReservedSchedule
) => {
    return officeWindowSchedules.find((w) => {
        const windowStart = moment(getStringFromScheduleDate(w.start));
        const windowEnd = moment(getStringFromScheduleDate(w.end));
        const relativeDateStart = getNowEquivalent(
            moment(getStringFromScheduleDate(schedule.start))
        );
        const relativeDateEnd = getNowEquivalent(
            moment(getStringFromScheduleDate(schedule.end))
        );
        const start = getNowEquivalent(windowStart);
        const end = getNowEquivalent(windowEnd);

        return (
            relativeDateStart.isBetween(start, end, "hours", "[]") &&
            relativeDateEnd.isBetween(start, end, "hours", "[]")
        );
    });
};

export const getNoteStatusTag = (status: NoteStatus) => {
    let bgColor: string;
    let textColor: string;

    switch (status) {
        case NoteStatus.CODED:
            textColor = "#00563E";
            bgColor = "rgba(204, 250, 233, 0.50)";
            break;
        case NoteStatus.DRAFT:
            bgColor = "rgba(240, 240, 229, 0.50)";
            textColor = "#0B132B";
            break;
        case NoteStatus.CHANGES_REQUESTED:
            textColor = "#165574";
            bgColor = "rgba(172, 222, 250, 0.50)";
            break;
        case NoteStatus.PENDING_CODE_REVIEW:
            textColor = "#634D17";
            bgColor = "rgba(247, 229, 164, 0.50)";
            break;
        case NoteStatus.PENDING_SUPERVISOR_REVIEW:
            textColor = "#634D17";
            bgColor = "rgba(247, 229, 164, 0.50)";
            break;
        default:
            bgColor = "rgba(240, 240, 229, 0.50)";
            textColor = "#0B132B";
    }

    return {
        bgColor,
        textColor,
    };
};

export const getBillStatusTag = (status: BillStatus) => {
    let bgColor: string;
    let textColor: string;

    switch (status) {
        case BillStatus.OPEN:
            textColor = "#686B1A";
            bgColor = "#E7E7DA";
            break;
        case BillStatus.CLOSED:
            textColor = "#0B132B";
            bgColor = "#F5F5F5";
            break;
        case BillStatus.PENDING_RESOLUTION:
            textColor = "#634D17";
            bgColor = "#F7E5A4";
            break;
        case BillStatus.PENDING_PATIENT_RESOLUTION:
            textColor = "#634D17";
            bgColor = "#F7E5A4";
            break;
        case BillStatus.PENDING_INSURANCE_RESOLUTION:
            textColor = "#634D17";
            bgColor = "#F7E5A4";
            break;
        default:
            textColor = "#0B132B";
            bgColor = "#F5F5F5";
    }

    return {
        bgColor,
        textColor,
    };
};

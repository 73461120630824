import { SvgProps } from "../../utils/types";

export default function CheckIcon({ className, fill }: SvgProps) {
    return (
        <svg
            width="12"
            height="10"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M4.23608 8.94028L0.661084 5.36528C0.446305 5.15051 0.446305 4.80227 0.661084 4.58746L1.43888 3.80964C1.65366 3.59484 2.00192 3.59484 2.2167 3.80964L4.62499 6.21791L9.78329 1.05964C9.99806 0.844865 10.3463 0.844865 10.5611 1.05964L11.3389 1.83746C11.5537 2.05224 11.5537 2.40048 11.3389 2.61528L5.01391 8.94031C4.7991 9.15509 4.45086 9.15509 4.23608 8.94028Z"
                fill={fill || "white"}
            />
        </svg>
    );
}

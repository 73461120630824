import * as React from "react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosProgressEvent } from "axios";
import { IApiResponse } from "../../../utils/types/api-response";
import {
    IBulkCreateClaim,
    IBulkCreateClaimResponse,
    IBulkUpdateClaims,
    IChangeClaimVoidStatus,
    IChangeInsurance,
    IClaim,
    ICreateDraft,
    ICreateDraftResponse,
    IDeleteClaimAttachment,
    IDeleteClaimsAttachmentsResponse,
    IUpdateClaimContent,
    IUpdateClaimStatus,
} from "../types/claims.types";
import {
    bulkCreateClaims,
    bulkUpdateClaims,
    changeClaimVoidStatus,
    changeInsuranceProvider,
    checkClaimStatus,
    createDraftClaim,
    createManualClaim,
    deleteClaims,
    deleteClaimsAttachments,
    editManualClaim,
    updateClaimContent,
    updateClaimStatus,
} from "../services/claims.services";

export function useUpdateClaimStatus() {
    return useMutation<
        IApiResponse<IUpdateClaimStatus>,
        AxiosError<IApiResponse<IUpdateClaimStatus>>,
        IUpdateClaimStatus
    >({
        mutationKey: ["update_claim_status"],
        mutationFn: (payload: IUpdateClaimStatus) => updateClaimStatus(payload),
    });
}

export function useCreateDraftClaim() {
    return useMutation<
        IApiResponse<ICreateDraftResponse>,
        AxiosError<IApiResponse<ICreateDraftResponse>>,
        ICreateDraft
    >({
        mutationKey: ["create_draft_claim"],
        mutationFn: (payload: ICreateDraft) => createDraftClaim(payload),
    });
}

export function useBulkCreateClaim() {
    return useMutation<
        IApiResponse<IBulkCreateClaimResponse>,
        AxiosError<IApiResponse<IBulkCreateClaimResponse>>,
        IBulkCreateClaim
    >({
        mutationKey: ["bulk_create_claim"],
        mutationFn: (payload: IBulkCreateClaim) => bulkCreateClaims(payload),
    });
}

export function useBulkUpdateClaims() {
    return useMutation<
        IApiResponse<IBulkUpdateClaims>,
        AxiosError<IApiResponse<IBulkUpdateClaims>>,
        IBulkUpdateClaims
    >({
        mutationKey: ["bulk-update-claims"],
        mutationFn: (payload: IBulkUpdateClaims) => bulkUpdateClaims(payload),
    });
}

export function useDeleteClaims() {
    interface IDeleteClaimsResponse {
        deleted_claim_ids: string[];
        undeleted_claim_ids: string[];
    }

    return useMutation<
        IApiResponse<IDeleteClaimsResponse>,
        AxiosError<IApiResponse<IDeleteClaimsResponse>>,
        string[]
    >({
        mutationKey: ["delete-claims"],
        mutationFn: (claimIds: string[]) => deleteClaims(claimIds),
    });
}

export function useDeleteClaimsAttachments() {
    return useMutation<
        IApiResponse<IDeleteClaimsAttachmentsResponse>,
        AxiosError<IApiResponse<IDeleteClaimsAttachmentsResponse>>,
        IDeleteClaimAttachment
    >({
        mutationKey: ["delete-claims-attachments"],
        mutationFn: (payload: IDeleteClaimAttachment) =>
            deleteClaimsAttachments(payload),
    });
}

export function useCheckClaimStatus() {
    return useMutation<
        IApiResponse<{ message: string }>,
        AxiosError<IApiResponse<{ message: string }>>,
        string[]
    >({
        mutationKey: ["check-claim-status"],
        mutationFn: (claimIds: string[]) => checkClaimStatus(claimIds),
    });
}

export function useUpdateClaimContent() {
    return useMutation<
        IApiResponse<{ message: string }>,
        AxiosError<IApiResponse<{ message: string }>>,
        IUpdateClaimContent
    >({
        mutationKey: ["update-claim-content"],
        mutationFn: (payload) => updateClaimContent(payload),
    });
}

export function useCreateManualClaim() {
    const [uploadProgress, setUploadProgress] = React.useState(0);
    interface ICreateClaimResponse extends IApiResponse<{ claim: IClaim }> {
        claim_id: string;
    }

    const mutation = useMutation<
        ICreateClaimResponse,
        AxiosError<ICreateClaimResponse>,
        FormData
    >({
        mutationKey: ["create-manual-claim"],
        mutationFn: (payload) =>
            createManualClaim(payload, (progressEvent: AxiosProgressEvent) => {
                if (progressEvent.total) {
                    setUploadProgress(
                        Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )
                    );
                }
            }),
        onSuccess: () => {
            setUploadProgress(0);
        },
        onError: () => {
            setUploadProgress(0);
        },
    });

    return { ...mutation, uploadProgress };
}

export function useEditManualClaim(claimId: string) {
    const [uploadProgress, setUploadProgress] = React.useState(0);
    interface IEditClaimResponse extends IApiResponse<{ claim: IClaim }> {
        claim_id: string;
    }

    const mutation = useMutation<
        IEditClaimResponse,
        AxiosError<IEditClaimResponse>,
        FormData
    >({
        mutationKey: ["edit-manual-claim"],
        mutationFn: (payload) =>
            editManualClaim(
                claimId,
                payload,
                (progressEvent: AxiosProgressEvent) => {
                    if (progressEvent.total) {
                        setUploadProgress(
                            Math.round(
                                (progressEvent.loaded * 100) /
                                    progressEvent.total
                            )
                        );
                    }
                }
            ),
        onSuccess: () => {
            setUploadProgress(0);
        },
        onError: () => {
            setUploadProgress(0);
        },
    });

    return { ...mutation, uploadProgress };
}

export function useChangeInsuranceProvider(noteId: string) {
    return useMutation<
        IApiResponse<{ message: string }>,
        AxiosError<IApiResponse<{ message: string }>>,
        IChangeInsurance
    >({
        mutationKey: ["change-insurance-provider"],
        mutationFn: (payload) => changeInsuranceProvider(noteId, payload),
    });
}

export function useChangeClaimVoidStatus() {
    interface IChangeVoidStatusResponse {
        voided_claims: string[];
        unvoided_claims: string[];
    }

    return useMutation<
        IApiResponse<IChangeVoidStatusResponse>,
        AxiosError<IApiResponse<IChangeVoidStatusResponse>>,
        IChangeClaimVoidStatus
    >({
        mutationKey: ["void-claim"],
        mutationFn: (data: IChangeClaimVoidStatus) =>
            changeClaimVoidStatus(data),
    });
}

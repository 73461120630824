import { Tabs } from "@jhool-io/fe-components";
import { useSearchParams } from "react-router-dom";
import CaseloadViewWrapper from "../CaseloadViewWrapper/CaseloadViewWrapper";
import {
    defaultClinician,
    IAppointmentChecks,
} from "../../../types/schedule.types";
import ScheduleViewWrapper from "../ScheduleView/ScheduleViewWrapper";
import moment from "../../../../../utils/moment";

export default function ClinicianViewWrapper() {
    const [searchParams, setSearchParams] = useSearchParams();
    const currentWeek = moment().startOf("week");
    const startOfWeek = currentWeek.startOf("week").format("YYYY-MM-DD");
    const endOfWeek = currentWeek.endOf("week").format("YYYY-MM-DD");

    const handleTabInView = (tab: string) => {
        return setSearchParams(`tab=${tab}`);
    };

    const tabInUrl = searchParams.get("tab");
    const tabItems = [
        {
            label: "My Caseload",
            key: "caseload",
            children: (
                <CaseloadViewWrapper
                    schedules={defaultClinician.confirmed.flatMap(
                        (confirmedAppointment) => {
                            const appointmentId = confirmedAppointment.id;
                            const appointmentCheck = (
                                defaultClinician.appointment_checks as IAppointmentChecks
                            )[appointmentId];
                            return {
                                ...confirmedAppointment,
                                appointment_check: appointmentCheck || null,
                            };
                        }
                    )}
                />
            ),
            onClick: () => handleTabInView("caseload"),
        },
        {
            label: "My Schedule",
            key: "schedule",
            children: <ScheduleViewWrapper />,
            onClick: () => {
                handleTabInView("schedule");
                searchParams.set("from_date", startOfWeek);
                searchParams.set("to_date", endOfWeek);
                setSearchParams(searchParams);
            },
        },
    ];

    return (
        <Tabs
            items={tabItems}
            size="medium"
            className="w-full rounded-none"
            defaultActiveKey={tabInUrl || tabItems[0]?.key}
        />
    );
}

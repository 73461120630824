import {
    Alert,
    Button,
    Card,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Tag,
    Tooltip,
} from "@jhool-io/fe-components";
import React from "react";
import {
    Link,
    useParams,
    useSearchParams,
    useLocation,
} from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import ChangeCode from "../../components/Notes/NoteContent/ChangeCode/ChangeCode";
import Avatar from "../../components/Avatar/Avatar";
import ChevronDownIcon from "../../components/Icons/ChevronDown";
import EditIcon from "../../components/Icons/Edit";
import {
    buildSuccessMessage,
    cn,
    convertDateFilterStringToDate,
    formatDate,
    getTimeDuration,
    handleDisplayClientEmailCorrectly,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    userInitials,
} from "../../utils/helpers";
import { ClaimStatus } from "../../modules/claims/types/claims.types";
import { useGetClaim } from "../../modules/claims/hooks/claims.queries";
import { useFetchSessionNote } from "../../hooks/queries/note";
import {
    FlaggedNoteForAuditStatus,
    ModifierTypes,
    NoteTypes,
    Source,
} from "../../utils/types/notes";
import { useFetchDiagnosisCodes } from "../../hooks/queries";
import Skeleton from "../../components/Skeleton/Skeleton";
import { useUpdateClaimStatus } from "../../modules/claims/hooks/claims.mutations";
import useToast from "../../hooks/useToast";
import {
    useDoesUserHavePermission,
    useDoesUserHaveRole,
} from "../../hooks/permissions";
import { UserPermisions } from "../../utils/types/user";
import { IPaginatedApiResponse } from "../../utils/types/api-response";
import { IUpdatedInvoiceResponse } from "../../utils/types/billing";
import { useUpdateInvoiceStatus } from "../../hooks/mutations/billing";
import { APP_COLORS } from "../../utils/constants";
import { useFetchRemitClaims } from "../../modules/remits/hooks/remits.queries";
import {
    BillStatus,
    BillStatusLabels,
} from "../../modules/billing/types/billing.types";
import { RemitStatus } from "../../modules/remits/types/remits.types";
import { PinnedClientsContext } from "../../context/pinned-clients/PinnedClientsContext";
import UnpinIcon from "../../components/Icons/Unpin";
import PinIcon from "../../components/Icons/Pin";
import {
    useFetchClientFlags,
    useFetchClientMemos,
} from "../../modules/clients/hooks/clients.queries";
import { ViewEditorText } from "../../components/TextEditor/ViewEditorText/ViewEditorText";
import {
    getFlaggedNoteStatusTag,
    getNoteStatusTag,
} from "../../modules/notes/helpers/notes.helpers";
import { NoteStatus } from "../../modules/notes/types/notes.types";
import EditNoteSessionTimeForm from "../../modules/notes/components/EditNoteSessionTimeForm/EditNoteSessionTimeForm";
import { UserRole } from "../../modules/user-management/types/user-management.types";
import PendingResolutionForm from "../../modules/billing/components/PendingResolutionForm/PendingResolutionForm";
import EditNoteDiagnosisCodesForm from "../../modules/notes/components/EditNoteDiagnosisCodesForm/EditNoteDiagnosisCodesForm";
import InfoIcon from "../../components/Icons/InfoIcon";
import useShowInsuranceOverSelfPay from "../../modules/practice-settings/hooks/useShowInsuranceOverSelfPay";
import EditManualClaimForm from "../../modules/claims/components/EditManualClaimForm/EditManualClaimForm";
import ChangeInsuranceForm from "../../modules/claims/components/EditClaims/ChangeInsuranceForm/ChangeInsuranceForm";
import { useDeleteClientFlag } from "../../modules/clients/hooks/clients.mutations";
import EditClientFlagForm from "../../modules/clients/components/EditClientFlagForm/EditClientFlagForm";
import useGetDiagnosisCodeToDisplay from "../../modules/notes/hooks";
import { useAppSelector } from "../../hooks/useRedux";

interface HarmonizedViewProps {
    topRightAction?: React.ReactNode;
}

type InvoiceStatus =
    | "closed"
    | "open"
    | "pending_resolution"
    | "pending_patient_resolution"
    | "pending_insurance_resolution";

type Modals =
    | "change-code"
    | "pending-resolution"
    | "session-time"
    | "diagnosis-codes"
    | "edit-manual-claim"
    | "change-insurance"
    | "edit-flag"
    | "delete-flag";

export default function HarmonizedView({
    topRightAction,
}: HarmonizedViewProps) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [modalInView, setModalInView] = React.useState<Modals | null>(null);
    const [selectedFlagId, setSelectedFlagId] = React.useState<string | null>(
        null
    );
    const params = useParams();
    const { claimId, noteId, clientId, remitId } = params;

    const viewFrom = searchParams.get("viewFrom") || "";

    const queryClient = useQueryClient();

    const activeClaimInUrlId = searchParams.get("active_claim_id");

    // Toast for success and error states
    const { toast } = useToast();

    const location = useLocation();

    const isSingleBillView = location.pathname.includes("billing-and-claims");

    const { practice } = useAppSelector((state) => state.userPractice);

    const showInsOverSelfPay = useShowInsuranceOverSelfPay();

    const userCanViewFlags = useDoesUserHaveRole([
        UserRole.BILLER,
        UserRole.SUPER_ADMIN,
        UserRole.BILLER_ADMIN,
        UserRole.CODER,
    ]);

    // Fetch client flags
    const clientFlags = useFetchClientFlags(
        clientId || "",
        userCanViewFlags && Boolean(clientId)
    );

    const deleteFlag = useDeleteClientFlag(
        clientId || "",
        selectedFlagId || ""
    );

    const isCurrentLocationSingleNoteView =
        location.pathname.includes("/notes");
    const isCurrentLocationSingleBillView = location.pathname.includes(
        "/billing-and-claims/"
    );

    const pinnedClientsContext = React.useContext(PinnedClientsContext);

    const isClientPinnedInLocation =
        pinnedClientsContext?.isClientPinnedInLocation(
            clientId || "",
            isCurrentLocationSingleNoteView ? "note" : "bill"
        );

    // Fetch claims when user is on single claim or single remit view
    const { data: claimsData } = useGetClaim(
        {
            claim_id: activeClaimInUrlId || claimId,
        },
        Boolean((activeClaimInUrlId || claimId) && !isSingleBillView)
    );

    // Fetch claims when user is on single bill view
    const { data: claimsDataFromSingleBillView } = useGetClaim(
        {
            note_id: noteId,
        },
        Boolean(noteId && isSingleBillView)
    );

    const claimsInView = isSingleBillView
        ? claimsDataFromSingleBillView?.data?.[0]
        : claimsData?.data?.[0];

    const { data: remitData } = useFetchRemitClaims(
        { remittance_id: remitId },
        Boolean(remitId)
    );

    const {
        data: noteData,
        isLoading: notesLoading,
        isSuccess: noteSuccess,
    } = useFetchSessionNote(
        clientId as string,
        noteId as string,
        Boolean(clientId) && Boolean(noteId)
    );

    const diagnosisCodes = useFetchDiagnosisCodes();

    const clientMemos = useFetchClientMemos(clientId || "", {
        type: "user",
        memo_status: "active",
    });

    // check if logged in user can mark an invoice as closed
    const canMarkInvoice = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_INVOICE_WRITE,
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
    ]);

    // check if logged in user can change modifiers code and cpt code
    const canEditCptAndModifiersCode = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_INVOICE_WRITE,
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
    ]);

    const canEditDiagnosisCodes = useDoesUserHaveRole([
        UserRole.BILLER,
        UserRole.CODER,
        UserRole.SUPER_ADMIN,
        UserRole.AUDITOR,
    ]);

    const canEditNoteSessionTime =
        (useDoesUserHaveRole([UserRole.AUDITOR, UserRole.SUPER_ADMIN]) ||
            noteData?.data.provider.provider_id === practice?.provider_id ||
            practice?.provider_id ===
                noteData?.data?.provider?.supervisor_id) &&
        [
            NoteStatus.PENDING_SUPERVISOR_REVIEW,
            NoteStatus.CHANGES_REQUESTED,
            NoteStatus.PENDING_CODE_REVIEW,
        ].includes(noteData?.data.note_status as NoteStatus);

    const canChangeInsurance = useDoesUserHaveRole([
        UserRole.BILLER,
        UserRole.CODER,
        UserRole.SUPER_ADMIN,
        UserRole.BILLER_ADMIN,
        UserRole.CLIENT_SUPPORT,
    ]);

    /**
     * we are checking if this user can view a client personal information such as email, contact,, dob, sex e.t.c
     * we should not be showing that when the user is on the single note page
     * - first check is checking if viewFrom is not session_note and we are currently not on the single note page
     * - second check is checking if viewFrom is session_note and we are not currently on the single note page
     * (There is currently an implementation where clicking on a coded note on with a bill on the note list navigates the user to the
     *  the single bill page with the viewFrom=session_note searchparams in the url which is why this is important
     * )
     */
    const isUserViewingNoteWithoutABill =
        (viewFrom !== "session_note" && !isCurrentLocationSingleNoteView) ||
        (viewFrom === "session_note" && !isCurrentLocationSingleNoteView);

    // get modifier descriptions
    const getModifierCodes = () => {
        if (
            noteData?.data?.modifier_codes &&
            noteData?.data?.modifier_codes?.length > 0
        ) {
            return Object.values(ModifierTypes).filter((modifier) =>
                noteData?.data.modifier_codes
                    ?.map((code) => code.split(" ")[0])
                    .includes(modifier.split(" ")[0])
            );
        }

        return undefined;
    };

    const noteContent = noteData?.data.note_content;

    const secondaryDiagnosis = useGetDiagnosisCodeToDisplay(
        noteData?.data.source === Source.MANUAL
            ? noteData?.data.manual_note_diagnosis_codes?.find(
                  (code) => code.label === "secondary"
              )?.code
            : noteContent?.secondary_diagnosis
    );

    const tertiaryDiagnosis = useGetDiagnosisCodeToDisplay(
        noteData?.data.source === Source.MANUAL
            ? noteData?.data.manual_note_diagnosis_codes?.find(
                  (code) => code.label === "tertiary"
              )?.code
            : noteContent?.tertiary_diagnosis
    );

    const primaryDiagnosisManual = useGetDiagnosisCodeToDisplay(
        noteData?.data.source === Source.MANUAL
            ? noteData?.data.manual_note_diagnosis_codes?.find(
                  (code) => code.label === "primary"
              )?.code
            : ""
    );

    // get diagnosis descriptions
    const getDiagnosisCodes = () => {
        if (
            noteContent &&
            noteData?.data.note_content.diagnosis_codes &&
            noteData.data.note_content.diagnosis_codes.length > 0
        ) {
            return diagnosisCodes?.data?.data?.filter((code) => {
                return noteData.data.note_content.diagnosis_codes.includes(
                    code.code
                );
            });
        }

        return undefined;
    };

    // hook for updating claim status
    const patchClaimStatus = useUpdateClaimStatus();

    // hook for updating invoice status
    const patchInvoiceStatus = useUpdateInvoiceStatus();

    const handleSetModalInView = (modal: Modals | null) => {
        setModalInView(modal);
    };

    // function to change claim status
    const handleChangeStatus = (status: ClaimStatus) => {
        if (claimsInView) {
            const changeStatusPayload = {
                claim_id: claimsInView.claim_id,
                claim_status: status,
            };
            patchClaimStatus.mutate(changeStatusPayload, {
                onSuccess: (response) => {
                    queryClient.invalidateQueries([
                        "get-claim",
                        { claim_id: claimsInView.claim_id },
                    ]);

                    queryClient.invalidateQueries([
                        "get-claim",
                        { note_id: noteId },
                    ]);

                    toast({
                        mode: "success",
                        message: `${response.message}`,
                    });
                    searchParams.set("status", status);
                    setSearchParams(searchParams);
                },
                onError: (err) => {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not update claim status at this time time",
                    });
                },
            });
        }
    };

    const handleChangeBillStatus = (status: InvoiceStatus) => {
        const changeCodePayload = {
            invoice_ids: [noteData?.data.invoice_id || ""],
            status,
        };
        patchInvoiceStatus.mutate(changeCodePayload, {
            onSuccess: (response) => {
                queryClient.setQueryData<
                    IPaginatedApiResponse<IUpdatedInvoiceResponse>
                >([clientId, `session-note`, noteId], (prev) => {
                    const prevRequired =
                        prev as IPaginatedApiResponse<IUpdatedInvoiceResponse>;
                    return {
                        ...prevRequired,
                        invoice_status: response.status,
                    };
                });
                queryClient.invalidateQueries({
                    queryKey: [clientId, `session-note`, noteId],
                });

                toast({
                    mode: "success",
                    message: `${buildSuccessMessage(
                        response.data.updated_invoices,
                        response.data.unupdated_invoices
                    )}`,
                });
            },
            onError: () => {
                toast({
                    mode: "error",
                    message:
                        patchInvoiceStatus.error?.response?.data.message ||
                        "Could not update invoice at this time",
                });
            },
        });
    };

    // Function to delete client flag
    const handleDeleteFlag = () => {
        deleteFlag.mutate(null, {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: [clientId, "client-flags"],
                });
                toast({
                    mode: "success",
                    message: res.message || "Flag added successfully",
                });
                setSelectedFlagId(null);
                handleSetModalInView(null);
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not add flag at this time.",
                });
            },
        });
    };

    const handleCodeModalToShow = () => {
        if (noteContent) {
            handleSetModalInView("change-code");
        } else {
            handleSetModalInView("edit-manual-claim");
        }
    };

    const getClaimStatusColors = (status: ClaimStatus) => {
        let className: string;
        let strokeColor: string;

        switch (status) {
            case ClaimStatus.ACKNOWLEDGED:
                className = "text-[#00563E] bg-[#ccfae97f]";
                strokeColor = "#00563E";
                break;
            case ClaimStatus.DENIED:
                className = "text-[#981F41] bg-[#fbc7c67f]";
                strokeColor = "#981F41";
                break;
            case ClaimStatus.PAID:
                className = "text-[#00563E] bg-[#ccfae97f]";
                strokeColor = "#00563E";
                break;
            case ClaimStatus.PENDING:
                className = "text-[#634D17] bg-[#f7e5a47f]";
                strokeColor = "#634D17";
                break;
            case ClaimStatus.RECEIVED:
                className = "text-[#00563E] bg-[#ccfae97f]";
                strokeColor = "#00563E";
                break;
            case ClaimStatus.PATIENT_PAYMENT_COLLECTION:
                className = "text-[#00563E] bg-[#ccfae97f]";
                strokeColor = "#00563E";
                break;
            case ClaimStatus.CLOSED:
                className = "text-black bg-[#e5e5e57f]";
                strokeColor = "#0B132B";
                break;
            case ClaimStatus.SUBMITTED:
                className = "text-[#00563E] bg-[#ccfae97f]";
                strokeColor = "#00563E";
                break;
            case ClaimStatus.APPEALED:
                className = "text-[#165574] bg-[#acdefa7f]";
                strokeColor = "#165574";
                break;
            case ClaimStatus.REJECTED:
                className = "text-[#981F41] bg-[#fbc7c67f]";
                strokeColor = "#981F41";
                break;
            case ClaimStatus.READY_FOR_SUBMISSION:
                className = "text-[#634D17] bg-[#f7e5a47f]";
                strokeColor = "#634D17";
                break;
            case ClaimStatus.REVERSED:
                className = "text-[#981F41] bg-[#fbc7c67f]";
                strokeColor = "#981F41";
                break;

            default:
                className = "text-[#212121] bg-[#F5F5F5]";
                strokeColor = "#212121";
        }

        return {
            className,
            strokeColor,
        };
    };

    // Check if mutations are running, returns 0 || 1
    const changeCodeIsMutating = useIsMutating(["change-code"]);
    const updateInVoiceIsMutating = useIsMutating(["update-invoice"]);
    const editNoteIsMutating = useIsMutating(["edit-note"]);
    const editManualClaimIsMutating = useIsMutating(["edit-manual-claim"]);
    const changeInsuranceIsMutating = useIsMutating([
        "change-insurance-provider",
    ]);
    const editFlagIsMutating = useIsMutating([
        "update-client-flag",
        selectedFlagId,
    ]);

    return (
        <div className="h-[100%]">
            <Dialog open={modalInView === "diagnosis-codes"}>
                <DialogContent
                    title="Edit Diagnosis Codes"
                    variant="center"
                    handleCloseDialog={() => handleSetModalInView(null)}
                    showFooter
                    submitBtnFormValue="edit-diagnosis-codes"
                    isSubmitBtnDisabled={editNoteIsMutating > 0}
                    isCancelBtnDisabled={editNoteIsMutating > 0}
                >
                    <EditNoteDiagnosisCodesForm
                        onFormSubmit={() => handleSetModalInView(null)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={modalInView === "session-time"}>
                <DialogContent
                    title="Edit Session Time"
                    variant="center"
                    handleCloseDialog={() => handleSetModalInView(null)}
                    showFooter
                    submitBtnFormValue="edit-session-time"
                    isSubmitBtnDisabled={editNoteIsMutating > 0}
                    isCancelBtnDisabled={editNoteIsMutating > 0}
                >
                    <EditNoteSessionTimeForm
                        onFormSubmit={() => handleSetModalInView(null)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={modalInView === "change-code"}>
                <DialogContent
                    title="Change code"
                    variant="center"
                    handleCloseDialog={() => handleSetModalInView(null)}
                    showFooter
                    cancelText="Cancel"
                    saveText="Change code"
                    submitBtnFormValue="change-cpt-code"
                    isSubmitBtnDisabled={changeCodeIsMutating > 0}
                    isCancelBtnDisabled={changeCodeIsMutating > 0}
                    classNames="pb-48"
                >
                    <ChangeCode
                        cptCode={noteData?.data.cpt_code || "--"}
                        onFormSubmit={() => handleSetModalInView(null)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={modalInView === "pending-resolution"}>
                <DialogContent
                    title="Pending session fee"
                    handleCloseDialog={() => setModalInView(null)}
                    variant="center"
                    showFooter
                    cancelText="Cancel"
                    saveText="Mark as Pending session fee"
                    submitBtnFormValue="pending-resolution"
                    isSubmitBtnDisabled={updateInVoiceIsMutating > 0}
                    isCancelBtnDisabled={updateInVoiceIsMutating > 0}
                >
                    <PendingResolutionForm
                        onFormSubmit={() => setModalInView(null)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={modalInView === "edit-manual-claim"}>
                <DialogContent
                    title="Edit manual claim"
                    variant="center"
                    handleCloseDialog={() => handleSetModalInView(null)}
                    showFooter
                    cancelText="Cancel"
                    saveText="Edit claim"
                    submitBtnFormValue="edit-manual-claim"
                    isSubmitBtnDisabled={editManualClaimIsMutating > 0}
                    isCancelBtnDisabled={editManualClaimIsMutating > 0}
                >
                    <EditManualClaimForm
                        onFormSubmit={() => handleSetModalInView(null)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={modalInView === "change-insurance"}>
                <DialogContent
                    title="Change insurance provider"
                    variant="center"
                    handleCloseDialog={() => handleSetModalInView(null)}
                    showFooter
                    cancelText="Cancel"
                    saveText="Change insurance"
                    submitBtnFormValue="change-insurance"
                    isSubmitBtnDisabled={changeInsuranceIsMutating > 0}
                    isCancelBtnDisabled={changeInsuranceIsMutating > 0}
                >
                    <ChangeInsuranceForm
                        onFormSubmit={() => handleSetModalInView(null)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={modalInView === "delete-flag" && Boolean(selectedFlagId)}
            >
                <DialogContent
                    title="Delete client flag"
                    handleCloseDialog={() => handleSetModalInView(null)}
                    variant="center"
                    saveText="Delete flag"
                    onSaveClick={handleDeleteFlag}
                    isCancelBtnDisabled={deleteFlag.isLoading}
                    isSubmitBtnDisabled={deleteFlag.isLoading}
                    isDeleting
                >
                    <div>
                        <h3 className="font-medium text-xl mb-8">
                            Are you sure you want to delete flag?
                        </h3>
                        <p className="text-gray font-normal text-base">
                            This will delete this flag from the client flags and
                            cannot be reversed
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                open={modalInView === "edit-flag" && Boolean(selectedFlagId)}
            >
                <DialogContent
                    title="Edit client flag"
                    handleCloseDialog={() => handleSetModalInView(null)}
                    variant="center"
                    saveText="Save changes"
                    submitBtnFormValue="edit-client-flag"
                    isCancelBtnDisabled={editFlagIsMutating > 0}
                    isSubmitBtnDisabled={editFlagIsMutating > 0}
                >
                    <EditClientFlagForm
                        onFormSubmit={() => handleSetModalInView(null)}
                        flagId={selectedFlagId || ""}
                    />
                </DialogContent>
            </Dialog>
            {notesLoading && (
                <Card classNames="flex flex-col gap-y-12 h-[300px]">
                    <Skeleton height={50} width="50%" />
                    <Skeleton height={50} width="100%" className="!mt-0" />
                    <div className="flex justify-between items-center">
                        <Skeleton className="h-[150px] w-[49%]" />
                        <Skeleton className="h-[150px] w-[49%] !mt-0" />
                    </div>
                </Card>
            )}
            {noteSuccess && noteData?.data && (
                <Card classNames="p-24 h-auto flex flex-col h-[auto] max-[617px]:h-auto">
                    <div className="flex items-start justify-between gap-x-8 gap-y-12">
                        <div className="flex max-[673px]:w-full gap-x-8 flex-wrap gap-y-12 w-full">
                            <div className="flex items-center gap-x-12">
                                <Avatar
                                    label={userInitials(
                                        noteData?.data?.client
                                            ?.first_name as string,
                                        noteData?.data?.client
                                            ?.last_name as string
                                    )}
                                    className="!w-[4.2rem] !h-[4.2rem] shrink-0"
                                />
                                <div className="flex flex-col">
                                    <p className="text-lg font-semibold">
                                        {`${makeStringFirstLetterCapital(
                                            noteData?.data.client.first_name
                                        )}'s`}{" "}
                                        <span>
                                            {makeStringFirstLetterCapital(
                                                removeEnumUnderscore(
                                                    noteData?.data
                                                        .type as NoteTypes
                                                )
                                            )}
                                        </span>
                                    </p>
                                    <span className="text-sm font-normal">
                                        --
                                    </span>
                                </div>
                            </div>

                            <div className="flex items-start gap-x-4 flex-wrap gap-y-12">
                                <span>
                                    {noteData.data.flagged_for_audit_status !==
                                        FlaggedNoteForAuditStatus.FLAGGED &&
                                    noteData.data.flagged_for_audit_status !==
                                        FlaggedNoteForAuditStatus.REQUIRES_ACTION
                                        ? getNoteStatusTag(
                                              noteData.data.note_status
                                          )
                                        : null}
                                </span>
                                {viewFrom !== "session_note" &&
                                    !isCurrentLocationSingleNoteView &&
                                    noteData.data?.invoice_status && (
                                        <>
                                            <Dropdown>
                                                <DropdownTrigger asChild>
                                                    <Button
                                                        variant="normal"
                                                        size="auto"
                                                        className={cn(
                                                            "capitalize !rounded-r4 px-8 py-4 h-24 text-[#634D17] bg-[#f7e5a47f] text-xs",
                                                            {
                                                                "bg-[#f5f5f57f] text-[#0B132B]":
                                                                    noteData
                                                                        .data
                                                                        .invoice_status ===
                                                                    "open",
                                                                "bg-[#e5e5e57f] text-black":
                                                                    noteData
                                                                        .data
                                                                        .invoice_status ===
                                                                    "closed",
                                                                "disabled disabled:opacity-100 cursor-text":
                                                                    !canMarkInvoice,
                                                            }
                                                        )}
                                                    >
                                                        {`Bill: ${removeEnumUnderscore(
                                                            BillStatusLabels[
                                                                noteData.data
                                                                    .invoice_status ||
                                                                    ""
                                                            ]
                                                        )}`}
                                                        {canMarkInvoice && (
                                                            <ChevronDownIcon
                                                                className="ml-4"
                                                                stroke={
                                                                    (noteData
                                                                        .data
                                                                        .invoice_status ===
                                                                        "open" &&
                                                                        APP_COLORS.COLOR_BLACK) ||
                                                                    (noteData
                                                                        .data
                                                                        .invoice_status ===
                                                                        "closed" &&
                                                                        APP_COLORS.COLOR_BLACK) ||
                                                                    "#634D17"
                                                                }
                                                            />
                                                        )}
                                                    </Button>
                                                </DropdownTrigger>
                                                <DropdownContent
                                                    width="auto"
                                                    align="end"
                                                >
                                                    <DropdownItem
                                                        onClick={() =>
                                                            handleChangeBillStatus(
                                                                BillStatus.OPEN
                                                            )
                                                        }
                                                        disabled={
                                                            patchInvoiceStatus.isLoading
                                                        }
                                                        hidden={
                                                            noteData.data
                                                                .invoice_status ===
                                                            BillStatus.OPEN
                                                        }
                                                    >
                                                        Open
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={() =>
                                                            handleChangeBillStatus(
                                                                BillStatus.CLOSED
                                                            )
                                                        }
                                                        disabled={
                                                            patchInvoiceStatus.isLoading
                                                        }
                                                        hidden={
                                                            noteData.data
                                                                .invoice_status ===
                                                            BillStatus.CLOSED
                                                        }
                                                    >
                                                        Close
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        disabled={
                                                            patchInvoiceStatus.isLoading
                                                        }
                                                        onClick={() => {
                                                            setModalInView?.(
                                                                "pending-resolution"
                                                            );
                                                        }}
                                                        hidden={
                                                            noteData.data
                                                                .invoice_status ===
                                                            BillStatus.PENDING_RESOLUTION
                                                        }
                                                    >
                                                        Pending session fee
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        disabled={
                                                            patchInvoiceStatus.isLoading
                                                        }
                                                        onClick={() =>
                                                            handleChangeBillStatus(
                                                                BillStatus.PENDING_PATIENT_RESOLUTION
                                                            )
                                                        }
                                                        hidden={
                                                            noteData.data
                                                                .invoice_status ===
                                                            BillStatus.PENDING_PATIENT_RESOLUTION
                                                        }
                                                    >
                                                        Pending patient
                                                        resolution
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        disabled={
                                                            patchInvoiceStatus.isLoading
                                                        }
                                                        onClick={() =>
                                                            handleChangeBillStatus(
                                                                BillStatus.PENDING_INSURANCE_RESOLUTION
                                                            )
                                                        }
                                                        hidden={
                                                            noteData.data
                                                                .invoice_status ===
                                                            BillStatus.PENDING_INSURANCE_RESOLUTION
                                                        }
                                                    >
                                                        Pending insurance
                                                        resolution
                                                    </DropdownItem>
                                                </DropdownContent>
                                            </Dropdown>

                                            {claimsInView ? (
                                                <Dropdown>
                                                    <DropdownTrigger asChild>
                                                        <Button
                                                            variant="normal"
                                                            size="auto"
                                                            className={cn(
                                                                `capitalize !rounded-r4 px-8 py-4 h-24 text- text-xs ${
                                                                    getClaimStatusColors(
                                                                        claimsInView?.claim_status as ClaimStatus
                                                                    ).className
                                                                }`
                                                            )}
                                                        >
                                                            {`Claims: ${removeEnumUnderscore(
                                                                claimsInView?.claim_status ||
                                                                    ""
                                                            )}`}
                                                            <ChevronDownIcon
                                                                className="ml-4"
                                                                stroke={
                                                                    getClaimStatusColors(
                                                                        claimsInView?.claim_status as ClaimStatus
                                                                    )
                                                                        .strokeColor
                                                                }
                                                            />
                                                        </Button>
                                                    </DropdownTrigger>

                                                    <DropdownContent
                                                        width="auto"
                                                        align="end"
                                                    >
                                                        {Object.values(
                                                            ClaimStatus
                                                        )
                                                            .filter(
                                                                (status) =>
                                                                    status !==
                                                                    claimsInView?.claim_status
                                                            )
                                                            .map((status) => (
                                                                <DropdownItem
                                                                    key={status}
                                                                    onClick={() => {
                                                                        handleChangeStatus(
                                                                            status
                                                                        );
                                                                    }}
                                                                >
                                                                    {makeStringFirstLetterCapital(
                                                                        removeEnumUnderscore(
                                                                            status
                                                                        )
                                                                    )}
                                                                </DropdownItem>
                                                            ))}
                                                        {/* {claimsInView?.claim_status ===
                                                            ClaimStatus.CLOSED && (
                                                            <>
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        handleChangeStatus(
                                                                            ClaimStatus.PENDING
                                                                        );
                                                                    }}
                                                                >
                                                                    Pending
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        handleChangeStatus(
                                                                            ClaimStatus.READY_FOR_SUBMISSION
                                                                        );
                                                                    }}
                                                                >
                                                                    Ready for
                                                                    submission
                                                                </DropdownItem>
                                                            </>
                                                        )}
                                                        {claimsInView?.claim_status ===
                                                            ClaimStatus.READY_FOR_SUBMISSION && (
                                                            <DropdownItem
                                                                onClick={() =>
                                                                    handleChangeStatus(
                                                                        ClaimStatus.SUBMITTED
                                                                    )
                                                                }
                                                            >
                                                                Submitted
                                                            </DropdownItem>
                                                        )}
                                                        {claimsInView?.claim_status ===
                                                            ClaimStatus.CODED && (
                                                            <DropdownItem
                                                                onClick={() => {
                                                                    handleChangeStatus(
                                                                        ClaimStatus.READY_FOR_SUBMISSION
                                                                    );
                                                                }}
                                                            >
                                                                Ready for
                                                                Submission
                                                            </DropdownItem>
                                                        )}
                                                        {claimsInView?.claim_status ===
                                                            ClaimStatus.SUBMITTED && (
                                                            <>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleChangeStatus(
                                                                            ClaimStatus.RECEIVED
                                                                        )
                                                                    }
                                                                >
                                                                    Received
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        handleChangeStatus(
                                                                            ClaimStatus.READY_FOR_SUBMISSION
                                                                        );
                                                                    }}
                                                                >
                                                                    Ready for
                                                                    submission
                                                                </DropdownItem>
                                                            </>
                                                        )}

                                                        {claimsInView?.claim_status ===
                                                            ClaimStatus.RECEIVED && (
                                                            <>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleChangeStatus(
                                                                            ClaimStatus.PENDING
                                                                        )
                                                                    }
                                                                >
                                                                    Pending
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleChangeStatus(
                                                                            ClaimStatus.REJECTED
                                                                        )
                                                                    }
                                                                >
                                                                    Rejected
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        handleChangeStatus(
                                                                            ClaimStatus.READY_FOR_SUBMISSION
                                                                        );
                                                                    }}
                                                                >
                                                                    Ready for
                                                                    submission
                                                                </DropdownItem>
                                                            </>
                                                        )}
                                                        {claimsInView?.claim_status ===
                                                            ClaimStatus.ACKNOWLEDGED && (
                                                            <>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleChangeStatus(
                                                                            ClaimStatus.PENDING
                                                                        )
                                                                    }
                                                                >
                                                                    Pending
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        handleChangeStatus(
                                                                            ClaimStatus.READY_FOR_SUBMISSION
                                                                        );
                                                                    }}
                                                                >
                                                                    Ready for
                                                                    submission
                                                                </DropdownItem>
                                                            </>
                                                        )}
                                                        {claimsInView?.claim_status ===
                                                            ClaimStatus.PENDING && (
                                                            <>
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        handleChangeStatus(
                                                                            ClaimStatus.READY_FOR_SUBMISSION
                                                                        );
                                                                    }}
                                                                >
                                                                    Ready for
                                                                    submission
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleChangeStatus(
                                                                            ClaimStatus.PAID
                                                                        )
                                                                    }
                                                                >
                                                                    Paid
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleChangeStatus(
                                                                            ClaimStatus.DENIED
                                                                        )
                                                                    }
                                                                >
                                                                    Denied
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleChangeStatus(
                                                                            ClaimStatus.CLOSED
                                                                        )
                                                                    }
                                                                >
                                                                    Closed
                                                                </DropdownItem>
                                                            </>
                                                        )}
                                                        {claimsInView?.claim_status ===
                                                            ClaimStatus.REJECTED && (
                                                            <>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleChangeStatus(
                                                                            ClaimStatus.CLOSED
                                                                        )
                                                                    }
                                                                >
                                                                    Closed
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleChangeStatus(
                                                                            ClaimStatus.READY_FOR_SUBMISSION
                                                                        )
                                                                    }
                                                                >
                                                                    Ready for
                                                                    submission
                                                                </DropdownItem>
                                                            </>
                                                        )}
                                                        {claimsInView?.claim_status ===
                                                            ClaimStatus.DENIED && (
                                                            <>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleChangeStatus(
                                                                            ClaimStatus.APPEALED
                                                                        )
                                                                    }
                                                                >
                                                                    Appealed
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleChangeStatus(
                                                                            ClaimStatus.CLOSED
                                                                        )
                                                                    }
                                                                >
                                                                    Closed
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleChangeStatus(
                                                                            ClaimStatus.PATIENT_PAYMENT_COLLECTION
                                                                        )
                                                                    }
                                                                >
                                                                    Patient
                                                                    payment
                                                                    collection
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        handleChangeStatus(
                                                                            ClaimStatus.READY_FOR_SUBMISSION
                                                                        );
                                                                    }}
                                                                >
                                                                    Ready for
                                                                    submission
                                                                </DropdownItem>
                                                            </>
                                                        )}
                                                        {claimsInView?.claim_status ===
                                                            ClaimStatus.PAID && (
                                                            <>
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        handleChangeStatus(
                                                                            ClaimStatus.READY_FOR_SUBMISSION
                                                                        );
                                                                    }}
                                                                >
                                                                    Ready for
                                                                    submission
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleChangeStatus(
                                                                            ClaimStatus.PATIENT_PAYMENT_COLLECTION
                                                                        )
                                                                    }
                                                                >
                                                                    Patient
                                                                    payment
                                                                    collection
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleChangeStatus(
                                                                            ClaimStatus.PENDING
                                                                        )
                                                                    }
                                                                >
                                                                    Pending
                                                                </DropdownItem>
                                                            </>
                                                        )}
                                                        {claimsInView?.claim_status ===
                                                            ClaimStatus.APPEALED && (
                                                            <>
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        handleChangeStatus(
                                                                            ClaimStatus.READY_FOR_SUBMISSION
                                                                        );
                                                                    }}
                                                                >
                                                                    Ready for
                                                                    submission
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleChangeStatus(
                                                                            ClaimStatus.PENDING
                                                                        )
                                                                    }
                                                                >
                                                                    Pending
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleChangeStatus(
                                                                            ClaimStatus.DENIED
                                                                        )
                                                                    }
                                                                >
                                                                    Denied
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleChangeStatus(
                                                                            ClaimStatus.PAID
                                                                        )
                                                                    }
                                                                >
                                                                    Paid
                                                                </DropdownItem>
                                                            </>
                                                        )}
                                                        {claimsInView?.claim_status ===
                                                            ClaimStatus.REVERSED && (
                                                            <>
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        handleChangeStatus(
                                                                            ClaimStatus.READY_FOR_SUBMISSION
                                                                        );
                                                                    }}
                                                                >
                                                                    Ready for
                                                                    submission
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleChangeStatus(
                                                                            ClaimStatus.PATIENT_PAYMENT_COLLECTION
                                                                        )
                                                                    }
                                                                >
                                                                    Patient
                                                                    payment
                                                                    collection
                                                                </DropdownItem>
                                                            </>
                                                        )}
                                                        {(claimsInView?.claim_status ===
                                                            ClaimStatus.REVERSED ||
                                                            claimsInView?.claim_status ===
                                                                ClaimStatus.PATIENT_PAYMENT_COLLECTION ||
                                                            claimsInView?.claim_status ===
                                                                ClaimStatus.PAID) && (
                                                            <DropdownItem
                                                                onClick={() =>
                                                                    handleChangeStatus(
                                                                        ClaimStatus.CLOSED
                                                                    )
                                                                }
                                                            >
                                                                Mark as Closed
                                                            </DropdownItem>
                                                        )} */}
                                                    </DropdownContent>
                                                </Dropdown>
                                            ) : null}
                                            {remitId && remitData && (
                                                <span
                                                    className={cn(
                                                        `capitalize !rounded-r4 px-8 py-4 h-24 text- text-xs flex items-center justify-center bg-[#f5f5f57f] text-black`,
                                                        {
                                                            "bg-[#ccfae97f] text-[#00563E]":
                                                                remitData
                                                                    ?.data?.[0]
                                                                    ?.remit_status ===
                                                                RemitStatus.POSTED,
                                                        }
                                                    )}
                                                >
                                                    {`Remit: ${removeEnumUnderscore(
                                                        remitData?.data?.[0]
                                                            ?.remit_status || ""
                                                    )}`}
                                                </span>
                                            )}
                                        </>
                                    )}
                                {claimsInView && claimsInView.is_voided && (
                                    <Tag
                                        title="Voided Claim"
                                        className="font-normal py-0 h-24 rounded-r4"
                                        bgColor="#fbc7c67f"
                                        textColor="#981F41"
                                    />
                                )}
                                {noteData?.data?.is_archived && (
                                    <div className="ml-10">
                                        {noteData.data.archive_notes ? (
                                            <Tooltip
                                                content={
                                                    noteData.data.archive_notes
                                                }
                                            >
                                                <Button
                                                    variant="normal"
                                                    size="auto"
                                                    className="font-normal px-8 py-4 h-24 text-xs !rounded-r4 bg-[#acdefa7f] text-[#165574]"
                                                >
                                                    Archived Note
                                                </Button>
                                            </Tooltip>
                                        ) : (
                                            <Tag
                                                title="Archived Note"
                                                className="font-normal px-8 py-4 h-24 rounded-r4"
                                                bgColor="rgba(172, 222, 250, 0.50)"
                                                textColor="#165574"
                                            />
                                        )}
                                    </div>
                                )}

                                {noteData?.data?.flagged_for_audit_status ===
                                    FlaggedNoteForAuditStatus.FLAGGED ||
                                noteData?.data?.flagged_for_audit_status ===
                                    FlaggedNoteForAuditStatus.REQUIRES_ACTION ? (
                                    <span>
                                        {getFlaggedNoteStatusTag(
                                            noteData?.data
                                                ?.flagged_for_audit_status
                                        )}
                                    </span>
                                ) : null}
                            </div>
                        </div>

                        {isCurrentLocationSingleNoteView ||
                        isCurrentLocationSingleBillView ? (
                            <div className="shrink-0">
                                {isClientPinnedInLocation ? (
                                    <Tooltip
                                        content="Unpin Client"
                                        classNames="px-8 py-4"
                                    >
                                        <Button
                                            onClick={() => {
                                                pinnedClientsContext?.handleUnpinClient(
                                                    clientId || "",
                                                    isCurrentLocationSingleNoteView
                                                        ? "note"
                                                        : "bill"
                                                );
                                            }}
                                        >
                                            <UnpinIcon />
                                        </Button>
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        content="Pin client"
                                        classNames="px-8 py-4"
                                    >
                                        <Button
                                            onClick={() =>
                                                pinnedClientsContext?.handlePinClient(
                                                    {
                                                        clientId:
                                                            clientId || "",
                                                        clientName: `${noteData?.data?.client.first_name} ${noteData?.data?.client.last_name}`,
                                                        pinModule:
                                                            isCurrentLocationSingleNoteView
                                                                ? "note"
                                                                : "bill",
                                                    }
                                                )
                                            }
                                        >
                                            <PinIcon />
                                        </Button>
                                    </Tooltip>
                                )}
                            </div>
                        ) : null}
                        {topRightAction}
                    </div>
                    <div className="pt-2 flex gap-x-2 gap-y-2 items-center flex-wrap">
                        {clientFlags.data &&
                            clientFlags.data.data.length > 0 &&
                            clientFlags.data.data.map((flag) => (
                                <Dropdown key={flag.client_flag_id}>
                                    <DropdownTrigger asChild>
                                        <Button
                                            variant="normal"
                                            size="auto"
                                            className="capitalize !rounded-r4 px-8 py-4 h-24 text- text-xs text-[#165574] bg-[#acdefa7f]"
                                        >
                                            {flag.content}
                                            <ChevronDownIcon
                                                className="ml-4"
                                                stroke="#165574"
                                            />
                                        </Button>
                                    </DropdownTrigger>
                                    <DropdownContent width="auto" align="end">
                                        <DropdownItem
                                            onClick={() => {
                                                setSelectedFlagId(
                                                    flag.client_flag_id
                                                );
                                                handleSetModalInView(
                                                    "edit-flag"
                                                );
                                            }}
                                        >
                                            Edit
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => {
                                                setSelectedFlagId(
                                                    flag.client_flag_id
                                                );
                                                handleSetModalInView(
                                                    "delete-flag"
                                                );
                                            }}
                                        >
                                            Delete
                                        </DropdownItem>
                                    </DropdownContent>
                                </Dropdown>
                            ))}
                    </div>
                    {!isCurrentLocationSingleNoteView &&
                        clientMemos?.data &&
                        clientMemos?.data.count > 0 &&
                        clientMemos?.data?.data[0].description && (
                            <div className="mt-8 px-8 pt-[2px] rounded-r4 bg-[#d3f7fa66] min-h-[20px]">
                                <div className="flex items-center text-xs gap-x-4">
                                    <span className="text-primary font-bold shrink-0">
                                        Memo note:
                                    </span>
                                    <div className="!text-gray *:!text-xs *:!font-normal">
                                        <ViewEditorText
                                            text={
                                                clientMemos?.data?.data[0]
                                                    .description || ""
                                            }
                                        />
                                    </div>
                                    <Link
                                        to={`/clients/${clientId}?tab=memo`}
                                        target="_blank"
                                        className="text-primary font-medium underline lowercase shrink-0"
                                    >
                                        view all memos...
                                    </Link>
                                </div>
                            </div>
                        )}
                    <div className="rounded-r8 border border-[#fafafa] bg-[#fafafa] p-12 mt-8">
                        <div
                            className={cn(
                                "grid grid-cols-3 gap-y-12 max-[1100px]:grid-cols-3 max-[600px]:grid-cols-2 gap-x-8 max-h-[164px] overflow-y-auto",
                                {
                                    "grid-cols-[repeat(auto-fill,minmax(200px,1fr))]":
                                        viewFrom === "session_note" &&
                                        isCurrentLocationSingleNoteView,
                                }
                            )}
                        >
                            <div className="text-xs font-semibold">
                                <p className="text-gray mb-12">PROVIDER</p>
                                <p className="text-sm font-medium capitalize">
                                    {`${noteData.data.provider.first_name} ${noteData.data.provider.last_name}`}
                                </p>
                            </div>
                            <div className=" text-xs font-semibold">
                                <p className="text-gray mb-12"> CLIENT NAME</p>
                                <p className="text-sm font-medium capitalize">
                                    <Link
                                        to={`/clients/${noteData.data.client.client_id}`}
                                        className="underline text-primary"
                                    >
                                        {`${noteData.data.client.first_name} ${noteData.data.client.last_name}`}
                                    </Link>
                                </p>
                            </div>
                            <div className=" text-xs font-semibold">
                                <p className="text-gray mb-12">
                                    APPOINTMENT TYPE
                                </p>
                                <p className="text-sm font-medium capitalize">
                                    {makeStringFirstLetterCapital(
                                        removeEnumUnderscore(
                                            noteData.data.appointment_type
                                        )
                                    )}
                                </p>
                            </div>
                            <div className=" text-xs font-semibold ">
                                <p className="text-gray mb-12"> DURATION</p>
                                <p className="text-sm font-medium capitalize flex items-center gap-x-4">
                                    {getTimeDuration(
                                        formatDate(
                                            noteData.data.session_start_time ||
                                                "",
                                            false,
                                            "HH:mm"
                                        ),
                                        formatDate(
                                            noteData.data.session_end_time ||
                                                "",
                                            false,
                                            "HH:mm"
                                        )
                                    )}
                                    {noteData.data
                                        .reason_for_short_duration && (
                                        <Tooltip
                                            content={`Reason for short session: ${noteData.data.reason_for_short_duration}`}
                                        >
                                            <Button
                                                variant="normal"
                                                size="auto"
                                            >
                                                <InfoIcon />
                                            </Button>
                                        </Tooltip>
                                    )}
                                </p>
                            </div>

                            <div className="text-xs font-semibold">
                                <p className="text-gray uppercase mb-12 fle">
                                    Insurance Provider
                                </p>
                                <div className="flex items-center gap-x-4 mb-12">
                                    <p className="text-sm font-medium">
                                        {(noteData.data.client.self_pay &&
                                        !showInsOverSelfPay
                                            ? "Self pay"
                                            : noteData.data
                                                  .insurance_provider &&
                                              removeEnumUnderscore(
                                                  noteData.data.insurance_provider.toUpperCase()
                                              )) || "--"}
                                    </p>
                                    {canChangeInsurance ? (
                                        <Button
                                            size="auto"
                                            variant="normal"
                                            onClick={() =>
                                                handleSetModalInView(
                                                    "change-insurance"
                                                )
                                            }
                                        >
                                            <EditIcon />
                                        </Button>
                                    ) : null}
                                </div>
                            </div>
                            <div
                                className=" text-xs font-semibold uppercase"
                                hidden={viewFrom === "session_note"}
                            >
                                <p className="text-gray mb-12">CLAIM ID</p>
                                <p className="text-sm font-medium capitalize">
                                    {claimsInView?.external_reference_id ||
                                        "--"}
                                </p>
                            </div>
                            <div
                                className=" text-xs font-semibold  uppercase"
                                hidden={viewFrom === "session_note"}
                            >
                                <p className="text-gray mb-12">
                                    CLAIM SUBMISSION DATE
                                </p>
                                <p className="text-sm font-medium capitalize">
                                    {claimsInView?.submitted_on
                                        ? `${formatDate(
                                              claimsInView.submitted_on,
                                              false,
                                              "MM/dd/yyyy HH:mmaa"
                                          )}`
                                        : "--"}
                                </p>
                            </div>

                            <div className=" text-xs font-semibold  uppercase">
                                <p className="text-gray mb-12">
                                    DATE OF SERVICE
                                </p>
                                <div className="text-sm font-medium capitalize flex flex-col">
                                    <div>
                                        {formatDate(
                                            noteData.data.date_of_service
                                        )}
                                    </div>
                                    <div className="flex items-center gap-x-4">
                                        {formatDate(
                                            noteData.data
                                                .session_start_time as string,
                                            false,
                                            "h:mm a"
                                        )}{" "}
                                        -{" "}
                                        {formatDate(
                                            noteData.data
                                                .session_end_time as string,
                                            false,
                                            "h:mm a"
                                        )}
                                        {canEditNoteSessionTime && (
                                            <Button
                                                size="auto"
                                                variant="normal"
                                                onClick={() =>
                                                    handleSetModalInView(
                                                        "session-time"
                                                    )
                                                }
                                            >
                                                <EditIcon />
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {noteData.data.type ===
                                NoteTypes.CANCELLATION_NOTE ||
                            noteData.data.type ===
                                NoteTypes.SUPERVISION_NOTE ? null : (
                                <div className=" text-xs font-semibold uppercase">
                                    <div className="flex items-center gap-x-4 mb-12">
                                        <p className="text-gray">POS CODE</p>
                                        <Button
                                            size="auto"
                                            variant="normal"
                                            onClick={handleCodeModalToShow}
                                        >
                                            {canEditCptAndModifiersCode ? (
                                                <EditIcon fill="#1A738D" />
                                            ) : null}
                                        </Button>
                                    </div>
                                    <p className="text-sm font-medium capitalize items-center">
                                        {noteData?.data.pos_code || "--"}
                                    </p>
                                </div>
                            )}

                            <div className=" text-xs font-semibold  uppercase">
                                <p className="text-gray mb-12">
                                    PROVIDERS SIGNATURE
                                </p>
                                <div className="text-sm font-medium capitalize flex flex-col ">
                                    <div className="section-value">
                                        <span
                                            style={{
                                                display: "inline-block",
                                                marginRight: "3px",
                                            }}
                                        >
                                            {noteData.data.note_signatures &&
                                            noteData.data.note_signatures
                                                .length > 0
                                                ? noteData.data.note_signatures.find(
                                                      (item) =>
                                                          item.role === "author"
                                                  )?.signature
                                                : "--"}
                                        </span>
                                    </div>
                                    <div>
                                        {noteData.data.note_signatures &&
                                        noteData.data.note_signatures.length >
                                            0 ? (
                                            <>
                                                (
                                                {formatDate(
                                                    noteData.data.note_signatures.find(
                                                        (item) =>
                                                            item.role ===
                                                            "author"
                                                    )
                                                        ?.signature_date_time as string
                                                )}
                                                ,
                                                <span
                                                    style={{
                                                        display: "inline-block",
                                                        marginLeft: "3px",
                                                    }}
                                                >
                                                    {formatDate(
                                                        noteData.data.note_signatures.find(
                                                            (item) =>
                                                                item.role ===
                                                                "author"
                                                        )
                                                            ?.signature_date_time as string,
                                                        false,
                                                        "h:mm a"
                                                    )}
                                                </span>
                                                )
                                            </>
                                        ) : (
                                            "--"
                                        )}
                                    </div>
                                </div>
                            </div>
                            {noteData.data.type ===
                                NoteTypes.CANCELLATION_NOTE ||
                            noteData.data.type ===
                                NoteTypes.SUPERVISION_NOTE ? null : (
                                <div className="text-xs font-semibold uppercase ">
                                    <div className="text-gray flex items-center gap-x-4 mb-12">
                                        CPT CODE
                                        <Button
                                            size="auto"
                                            variant="normal"
                                            onClick={handleCodeModalToShow}
                                        >
                                            {canEditCptAndModifiersCode ? (
                                                <EditIcon fill="#1A738D" />
                                            ) : null}
                                        </Button>
                                    </div>
                                    <p className="text-sm font-medium capitalize items-center">
                                        {noteData?.data.cpt_code || "--"}
                                    </p>
                                </div>
                            )}
                            {isUserViewingNoteWithoutABill ? (
                                <>
                                    <div className=" text-xs font-semibold uppercase ">
                                        <p className="text-gray mb-12"> SEX</p>
                                        <p className="text-sm font-medium capitalize">
                                            {noteData.data.client.sex || "--"}
                                        </p>
                                    </div>

                                    <div className="text-xs font-semibold  uppercase ">
                                        <p className="text-gray mb-12"> DOB</p>
                                        <p className="text-sm font-medium capitalize">
                                            {noteData?.data.client.date_of_birth
                                                ? format(
                                                      convertDateFilterStringToDate(
                                                          noteData?.data.client
                                                              .date_of_birth
                                                      ) as Date,
                                                      "MM/dd/yyyy"
                                                  )
                                                : "--"}
                                            {`(${noteData?.data.client.age} yrs)` ||
                                                "--"}
                                        </p>
                                    </div>
                                    <div className="text-xs font-semibold  uppercase ">
                                        <p className="text-gray mb-12">
                                            CONTACT
                                        </p>
                                        <p className="text-sm font-medium capitalize">
                                            {noteData?.data.client.contact ||
                                                "--"}
                                        </p>
                                    </div>
                                    <div className=" text-xs font-semibold">
                                        <p className="text-gray uppercase mb-12">
                                            Email
                                        </p>
                                        <p className="text-sm font-medium">
                                            {noteData.data.client.email
                                                ? handleDisplayClientEmailCorrectly(
                                                      noteData.data.client.email
                                                  )
                                                : "--"}
                                        </p>
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                    {noteData?.data.type === NoteTypes.CANCELLATION_NOTE ||
                    noteData?.data.type ===
                        NoteTypes.SUPERVISION_NOTE ? null : (
                        <div className="grid grid-cols-2 gap-x-12 flex-1 max-[600px]:grid-cols-1 mt-8">
                            <Card classNames="bg-[#FFF6FB] h-[120px] relative pl-0 pt-0 pb-24 overflow-auto pr-[6px]">
                                <div className="sticky h-[39px] w-[1.2px] left-0 top-2/4 -translate-y-2/4 bg-[#BC2C7C]" />
                                <div className="pl-24 pt-24 -mt-[39px]">
                                    <div className="text-xs font-semibold capitalize">
                                        <div className="flex items-center justify-between gap-x-8">
                                            <p className="text-gray font-medium text-sm">
                                                MODIFIER
                                            </p>
                                            {canEditCptAndModifiersCode ? (
                                                <div className="mr-[18px]">
                                                    <Button
                                                        size="auto"
                                                        variant="normal"
                                                        className="underline text-primary"
                                                        onClick={
                                                            handleCodeModalToShow
                                                        }
                                                    >
                                                        Edit
                                                    </Button>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="ml-auto font-medium text-sm flex flex-col pt-12 gap-y-[2px]">
                                            {noteData?.data.modifier_codes &&
                                            noteData?.data.modifier_codes
                                                ?.length > 0
                                                ? getModifierCodes()?.map(
                                                      (code) => (
                                                          <p key={code}>
                                                              {code}
                                                          </p>
                                                      )
                                                  )
                                                : "--"}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                            <Card classNames="bg-[#ECFBFF] h-[120px] relative pl-0 pt-0 pb-24 overflow-auto pr-[18px]">
                                <div className="sticky h-[39px] w-[1.2px] left-0 top-2/4 -translate-y-2/4 bg-[#1A738D]" />
                                <div className="pl-24 pt-24 -mt-[39px]">
                                    <div className="text-xs font-semibold capitalize">
                                        <div className="flex items-center justify-between gap-x-8">
                                            <p className="text-gray font-medium text-sm">
                                                DIAGNOSIS
                                            </p>

                                            {canEditDiagnosisCodes ? (
                                                <div>
                                                    <Button
                                                        size="auto"
                                                        variant="normal"
                                                        className="underline text-primary"
                                                        onClick={() => {
                                                            if (noteContent) {
                                                                handleSetModalInView(
                                                                    "diagnosis-codes"
                                                                );
                                                            } else {
                                                                handleSetModalInView(
                                                                    "edit-manual-claim"
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="ml-auto font-medium text-sm flex flex-col pt-12 gap-y-[2px]">
                                            {diagnosisCodes.isLoading ? (
                                                <>
                                                    <div className="flex gap-8 mb-8">
                                                        <Skeleton width="80px" />
                                                    </div>
                                                    <div>
                                                        <Skeleton width="20%" />
                                                        <Skeleton width="20%" />
                                                        <Skeleton width="20%" />
                                                    </div>
                                                </>
                                            ) : null}
                                            {diagnosisCodes.isError ? (
                                                <span className="block text-sm font-medium text-[red]">
                                                    Error fetching diagnosis
                                                    codes
                                                </span>
                                            ) : null}
                                            {diagnosisCodes.isSuccess &&
                                            diagnosisCodes.data.data ? (
                                                <div className="text-xs">
                                                    <span>
                                                        Primary diagnosis:{" "}
                                                    </span>
                                                    {noteData?.data.source ===
                                                    Source.MANUAL ? (
                                                        <span className="text-gray">
                                                            {
                                                                primaryDiagnosisManual
                                                            }
                                                        </span>
                                                    ) : (
                                                        getDiagnosisCodes()?.map(
                                                            (code, i) => (
                                                                <span
                                                                    key={
                                                                        code.code
                                                                    }
                                                                    className="text-gray"
                                                                >
                                                                    {`${code.code} ${code.description}`}
                                                                    {i !== 2
                                                                        ? ", "
                                                                        : ""}
                                                                </span>
                                                            )
                                                        ) || "--"
                                                    )}
                                                    <div className="mt-4">
                                                        Secondary diagnosis:{" "}
                                                        <span className="text-gray">
                                                            {secondaryDiagnosis}
                                                        </span>
                                                    </div>
                                                    <div className="mt-4">
                                                        Tertiary diagnosis:{" "}
                                                        <span className="text-gray">
                                                            {tertiaryDiagnosis}
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    )}
                    {noteData?.data?.is_flagged_for_audit &&
                        noteData?.data?.flagged_for_audit_notes && (
                            <div className="mt-24">
                                <Alert
                                    type="info"
                                    title="Reason for flagging note"
                                    description={
                                        noteData?.data?.flagged_for_audit_notes
                                    }
                                />
                            </div>
                        )}
                </Card>
            )}
        </div>
    );
}

import cn from "classnames";
import * as React from "react";

import { useSearchParams } from "react-router-dom";
import SearchIcon from "../Icons/SearchIcon";
import CancelIcon from "../Icons/Cancel";
import Input from "../Input/Input";
import Button from "../Button/Button";

import styles from "./SearchWithSuggestions.module.scss";

interface SearchWithSuggestionsProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    /** Placeholder text */
    placeholder?: string;
    /** Extra  class to style input container */
    className?: string;
    /** Extra class to style input */
    inputClass?: string;
    /** List of words to be suggested as recent searches */
    suggestions: string[];
    /** Optional callback to be invoked when a suggestion is clicked/selected */
    onSuggestionSelect?: (val: string) => void;
}

export default function SearchWithSuggestions({
    placeholder,
    className,
    inputClass,
    suggestions,
    onSuggestionSelect,
    ...props
}: SearchWithSuggestionsProps) {
    const [isFocused, setIsFocused] = React.useState(false);

    const [searchParams] = useSearchParams();

    const searchString = searchParams.get("search") || "";

    const wrapperRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    // handle user clicking / selecting one of the search suggestions
    function handleSuggestionSelection(suggestion: string) {
        setIsFocused(false);

        if (onSuggestionSelect) {
            onSuggestionSelect(suggestion);
        }
    }

    // handle closing the suggestions list on `esc` key press
    React.useEffect(() => {
        function handleKeydown(e: KeyboardEvent) {
            if (e.key === "Escape") {
                setIsFocused(false);
                inputRef.current?.blur();
            }
        }

        const currentRef = wrapperRef;
        if (currentRef && currentRef.current) {
            currentRef.current.addEventListener("keydown", handleKeydown);
        }

        return () => {
            if (currentRef && currentRef.current) {
                currentRef.current.removeEventListener(
                    "keydown",
                    handleKeydown
                );
            }
        };
    }, []);

    return (
        <div
            className={cn(className, styles.inputwrapper, "fs-exclude")}
            ref={wrapperRef}
        >
            <Button
                type="button"
                mode="transparent"
                size="auto"
                className={styles.inputwrapper_btn}
                aria-label="search-btn"
            >
                <SearchIcon />
            </Button>

            <Input
                className={cn(inputClass, styles.inputwrapper_input)}
                onFocus={() => setIsFocused(true)}
                placeholder={placeholder}
                label={placeholder}
                ref={inputRef}
                {...props}
            />

            {isFocused && !searchString.length ? (
                <Button
                    type="button"
                    mode="transparent"
                    size="auto"
                    aria-label="search-cancel-btn"
                    className={styles.inputwrapper_cancel_btn}
                    onClick={() => setIsFocused(false)}
                >
                    <CancelIcon />
                </Button>
            ) : null}

            {/* Only show list if input element is focussed and we have existing searches */}
            {isFocused && suggestions.length && searchString.length === 0 ? (
                <div className={styles.recentswrapper}>
                    <h4>Recent searches</h4>
                    <ul>
                        {suggestions.map((item) => (
                            <li key={item}>
                                <button
                                    onClick={() =>
                                        handleSuggestionSelection(item)
                                    }
                                >
                                    {item}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : null}

            {/* overlay button allowing users to close the suggestion list by clicking outside the search/select area */}
            {isFocused ? (
                <button
                    className={styles.overlay}
                    onClick={() => {
                        setIsFocused(false);
                        if (inputRef && inputRef.current) {
                            inputRef.current?.blur();
                        }
                    }}
                    onFocus={() => {
                        // reset focus to the input element
                        if (inputRef && inputRef.current) {
                            inputRef.current?.focus();
                        }
                    }}
                    aria-label="close-searchbox"
                >
                    {" "}
                </button>
            ) : null}
        </div>
    );
}

import * as React from "react";
import {
    Button,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Tooltip,
} from "@jhool-io/fe-components";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import MoneyIcon from "../../../../../components/Icons/Money";
import ChevronDownIcon from "../../../../../components/Icons/ChevronDown";
import {
    cn,
    formatDate,
    removeEnumUnderscore,
    makeStringFirstLetterCapital,
} from "../../../../../utils/helpers";
import { APP_COLORS } from "../../../../../utils/constants";
import {
    ClientFeeValues,
    IClientFee,
    ProviderCredential,
} from "../../../types/clients.types";
import { SessionServiceType } from "../../../../../utils/types/session";
import { useFetchPracticeSettings } from "../../../../practice-settings/hooks/queries/practice-settings";
import { useEditClientCurrentFee } from "../../../hooks/clients.mutations";
import useToast from "../../../../../hooks/useToast";
import { IPaginatedApiResponse } from "../../../../../utils/types/api-response";
import {
    calculateFees,
    getMessageForFees,
} from "../../../../../utils/helpers/fees/fees";
import InfoIcon from "../../../../../components/Icons/InfoIcon";
import useShowInsuranceOverSelfPay from "../../../../practice-settings/hooks/useShowInsuranceOverSelfPay";

interface IClientSingleFeeProps {
    fee: IClientFee;
    defaultIsMaximized: boolean;
    isCurrentFee: boolean;
}

export default function ClientSingleFee({
    fee,
    defaultIsMaximized,
    isCurrentFee,
}: IClientSingleFeeProps) {
    const [isMaximized, setIsMaximized] = React.useState(defaultIsMaximized);

    const { clientId } = useParams();

    const { data: practiceFeeSettings } = useFetchPracticeSettings({
        setting_name: "fee_type",
        category: "fees",
    });

    const editFee = useEditClientCurrentFee(clientId || "", fee.fee_id);

    const showInsOverSelfPay = useShowInsuranceOverSelfPay();

    const queryClient = useQueryClient();

    const { toast } = useToast();

    const practiceHasINNFee =
        practiceFeeSettings &&
        practiceFeeSettings?.data.settings.length > 0 &&
        practiceFeeSettings?.data.settings[0].current_value === "INN";

    const desiredFeeValuesOrder = [
        "drs fee",
        "lic_fee",
        "masters fee",
        "intern fee",
        "psych fee",
        "dr_fee_a",
        "dr_fee_b",
        "dr_fee_c",
        "lic_a_fee",
        "lic_b_fee",
        "lic_c_fee",
        "testing fee",
    ];

    if (practiceHasINNFee) {
        desiredFeeValuesOrder.push(ClientFeeValues.INN_MASTERS_FEE);
    }

    const desiredFeesOrderTop = desiredFeeValuesOrder.slice(0, 5);

    const desiredFeesOrderBottom = desiredFeeValuesOrder.slice(5);

    const getFeeValuesInDesiredFormat = (
        fees: {
            type: ClientFeeValues;
            value: string;
            fee_value_id: string;
        }[]
    ) => {
        const orderMap: { [key: string]: number } = {};
        desiredFeeValuesOrder.forEach((type, index) => {
            orderMap[type] = index;
        });

        return fees.sort((a, b) => {
            return orderMap[a.type] - orderMap[b.type];
        });
    };

    const feeValuesInDesiredFormat = getFeeValuesInDesiredFormat(
        fee.fee_values
    );

    const getTopDesiredValues = feeValuesInDesiredFormat.filter((value) =>
        desiredFeesOrderTop.includes(value.type)
    );

    const getBottomDesiredValues = feeValuesInDesiredFormat.filter((value) =>
        desiredFeesOrderBottom.includes(value.type)
    );

    const hasApplicableFee = (
        credentials: ProviderCredential[],
        requestedService?: SessionServiceType[]
    ) => {
        const fees: ClientFeeValues[] = [];

        const isPsychFee =
            requestedService?.includes(
                SessionServiceType.PSYCHIATRIC_COUNSELING
            ) ||
            requestedService?.includes(
                SessionServiceType.MEDICATION_MANAGEMENT
            );

        const isTestingFee = requestedService?.includes(
            SessionServiceType.MENTAL_HEALTH_ASSESSMENT
        );

        if (practiceHasINNFee) {
            fees.push(ClientFeeValues.INN_MASTERS_FEE);
        } else if (isPsychFee) {
            fees.push(ClientFeeValues.PSYCH_FEE);
        } else if (isTestingFee) {
            fees.push(ClientFeeValues.TESTING_FEE);
        } else {
            credentials.forEach((credential) => {
                switch (credential) {
                    case ProviderCredential.PSYD:
                    case ProviderCredential.PHD:
                    case ProviderCredential.MD:
                        fees.push(ClientFeeValues.DR_FEE);
                        break;
                    case ProviderCredential.PSYDA:
                    case ProviderCredential.PHDA:
                        if (ClientFeeValues.DR_FEE_A) {
                            fees.push(ClientFeeValues.DR_FEE_A);
                        } else {
                            fees.push(ClientFeeValues.DR_FEE);
                        }
                        break;
                    case ProviderCredential.PSYDB:
                    case ProviderCredential.PHDB:
                        if (ClientFeeValues.DR_FEE_B) {
                            fees.push(ClientFeeValues.DR_FEE_B);
                        } else {
                            fees.push(ClientFeeValues.DR_FEE);
                        }
                        break;
                    case ProviderCredential.PSYDC:
                    case ProviderCredential.PHDC:
                        if (ClientFeeValues.DR_FEE_C) {
                            fees.push(ClientFeeValues.DR_FEE_C);
                        } else {
                            fees.push(ClientFeeValues.DR_FEE);
                        }
                        break;
                    case ProviderCredential.INTERN:
                        fees.push(ClientFeeValues.INTERN_FEE);
                        break;
                    case ProviderCredential.LMHC:
                    case ProviderCredential.LCSW:
                        if (ClientFeeValues.LIC_FEE) {
                            fees.push(ClientFeeValues.LIC_FEE);
                        } else {
                            fees.push(ClientFeeValues.MASTERS_FEE);
                        }
                        break;
                    case ProviderCredential.LMHCA:
                    case ProviderCredential.LCSWA:
                        if (ClientFeeValues.LIC_A_FEE) {
                            fees.push(ClientFeeValues.LIC_A_FEE);
                        } else {
                            fees.push(ClientFeeValues.MASTERS_FEE);
                        }
                        break;
                    case ProviderCredential.LMHCB:
                    case ProviderCredential.LCSWB:
                        if (ClientFeeValues.LIC_B_FEE) {
                            fees.push(ClientFeeValues.LIC_B_FEE);
                        } else {
                            fees.push(ClientFeeValues.MASTERS_FEE);
                        }
                        break;
                    case ProviderCredential.LMHCC:
                    case ProviderCredential.LCSWC:
                        if (ClientFeeValues.LIC_C_FEE) {
                            fees.push(ClientFeeValues.LIC_C_FEE);
                        } else {
                            fees.push(ClientFeeValues.MASTERS_FEE);
                        }
                        break;
                    default:
                        return fees.push(ClientFeeValues.MASTERS_FEE);
                }
                return fees;
            });
        }
        return fees;
    };

    const handleEditClientFee = (payload: {
        self_pay?: boolean;
        accepts_assignment?: boolean;
        alt_email_sent?: boolean;
    }) => {
        const payloadToSend = {
            ...fee,
            reason_for_update: "",
            self_pay: payload.self_pay ?? fee.self_pay,
            accepts_assignment:
                payload.accepts_assignment ?? fee.accepts_assignment,
            alt_email_sent: payload.alt_email_sent ?? fee.alt_email_sent,
        };

        editFee.mutate(payloadToSend, {
            onSuccess: (res) => {
                queryClient.setQueriesData<IPaginatedApiResponse<IClientFee[]>>(
                    [clientId, `fees`, 200],
                    (prev) => {
                        const prevFees = prev as IPaginatedApiResponse<
                            IClientFee[]
                        >;
                        return {
                            ...prevFees,
                            data: prevFees?.data.map((singleFee) => {
                                if (fee.fee_id === singleFee.fee_id) {
                                    return {
                                        ...singleFee,
                                        self_pay:
                                            payload.self_pay ?? fee.self_pay,
                                        accepts_assignment:
                                            payload.accepts_assignment ??
                                            fee.accepts_assignment,
                                        alt_email_sent:
                                            payload.alt_email_sent ??
                                            fee.alt_email_sent,
                                    };
                                }
                                return singleFee;
                            }),
                        };
                    }
                );

                queryClient.invalidateQueries({
                    queryKey: [clientId, "fees"],
                });

                toast({
                    mode: "success",
                    message: res.message || "Fee updated successfully",
                });
            },
            onError: (error) => {
                toast({
                    mode: "error",
                    message:
                        error.response?.data.message ||
                        "Could not update fee at this time",
                });
            },
        });
    };

    const providersFees = hasApplicableFee(
        fee.providers.map((provider) => provider.credential),
        fee.providers.map((provider) => provider.service)
    );

    return (
        <article className="h-auto rounded-r8 bg-white border border-strokedark min-w-[1000px] max-w-full  overflow-x-auto">
            <div className="py-12 pl-16 pr-24 flex items-center justify-between gap-x-12">
                <div className="flex items-center gap-x-12">
                    <div className="flex items-center justify-center rounded-r8 bg-card-bg w-[47px] h-[43px]">
                        <MoneyIcon />
                    </div>
                    <div className="flex flex-col justify-center gap-y-4 text-sm">
                        <span className="font-semibold">
                            {fee.updated_at
                                ? formatDate(fee?.updated_at)
                                : "--"}
                        </span>
                        <span className="text-gray font-regular">
                            {`Updated by ${
                                fee?.updated_by?.first_name
                                    ? `${makeStringFirstLetterCapital(
                                          fee?.updated_by?.first_name
                                      )} ${makeStringFirstLetterCapital(
                                          fee.updated_by?.last_name || ""
                                      )}`
                                    : "--"
                            }`}
                        </span>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="flex items-center gap-x-8">
                        {getTopDesiredValues.map((item) => {
                            const feesCalculated = calculateFees(item.value);

                            const hideToolTip = Boolean(
                                feesCalculated &&
                                    feesCalculated.singleFee &&
                                    !feesCalculated.tbdFee &&
                                    !feesCalculated.tempFee &&
                                    !feesCalculated.tempNoOfSessions
                            );

                            const isApplicableFee = hasApplicableFee(
                                fee.providers.map(
                                    (provider) => provider.credential
                                ),
                                fee.providers.map(
                                    (provider) => provider.service
                                )
                            ).includes(item.type);

                            return hideToolTip ? (
                                <div
                                    className={cn(
                                        "flex px-8 py-4 flex-col justify-center gap-y-[5px] w-[125px] rounded-r8",
                                        {
                                            "border border-primary bg-[#eefdfda3]":
                                                isApplicableFee && item.value,
                                        }
                                    )}
                                    key={item.type}
                                >
                                    <span
                                        className={cn(
                                            "uppercase font-semibold text-xs leading-[18px]",
                                            {
                                                "after:content-['*'] after:text-xs after:font-semibold":
                                                    isApplicableFee &&
                                                    item.value,
                                            }
                                        )}
                                    >
                                        {removeEnumUnderscore(item.type)}
                                    </span>
                                    <span className="text-gray text-sm font-regular">
                                        {item.value || "--"}
                                    </span>
                                </div>
                            ) : (
                                <div
                                    className={cn(
                                        "flex px-8 py-4 flex-col justify-center gap-y-[5px] w-[125px] rounded-r8",
                                        {
                                            "border border-primary bg-[#eefdfda3]":
                                                isApplicableFee && item.value,
                                        }
                                    )}
                                >
                                    <span
                                        className={cn(
                                            "uppercase font-semibold text-xs leading-[18px]",
                                            {
                                                "after:content-['*'] after:text-xs after:font-semibold":
                                                    isApplicableFee &&
                                                    item.value,
                                            }
                                        )}
                                    >
                                        {removeEnumUnderscore(item.type)}
                                    </span>
                                    <span className="text-gray text-sm font-regular flex items-center gap-x-4">
                                        {item.value || "--"}
                                        {item.value ? (
                                            <Tooltip
                                                content={getMessageForFees(
                                                    item.value
                                                )}
                                            >
                                                <Button
                                                    variant="normal"
                                                    size="auto"
                                                >
                                                    <InfoIcon />
                                                </Button>
                                            </Tooltip>
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                    <div className="ml-24">
                        <Button
                            variant="normal"
                            size="auto"
                            onClick={() => setIsMaximized((state) => !state)}
                            className="flex items-center justify-center rounded-r6 border-[0.674px] border-stroke-divider h-[29px] w-[31px] bg-card-bg"
                        >
                            <ChevronDownIcon
                                className={cn(
                                    "rotate-0 transition-all ease-out duration-200",
                                    {
                                        "rotate-180": isMaximized,
                                    }
                                )}
                                stroke={APP_COLORS.COLOR_GRAY}
                            />
                        </Button>
                    </div>
                </div>
            </div>
            <div
                className={cn(
                    "h-0 overflow-y-hidden transition-all ease-out duration-200",
                    {
                        "h-auto": isMaximized,
                    }
                )}
            >
                <div className="grid grid-cols-9 gap-x-8 px-16 py-12 bg-card-bg border-t border-t-stroke-divider border-b border-b-stroke-divider font-semibold text-xs leading-[18px] text-gray uppercase">
                    <span>THERAPIST</span>
                    <span>SESSION TYPE</span>
                    <span>START DATE</span>
                    <span>FEE</span>
                    <span>SESSION COUNT</span>
                    <span>--</span>
                    <span>--</span>
                    <span>--</span>
                    <span>--</span>
                </div>
                <div className="p-16 bg-white flex flex-col gap-y-16 text-sm font-regular">
                    {fee.providers.map((provider, i) => {
                        const providerFeeType = providersFees[i];

                        return (
                            <div
                                className="grid grid-cols-9 gap-x-8 w-full"
                                key={provider.provider_id}
                            >
                                <span>{`${provider.first_name} ${provider.last_name}`}</span>
                                <span className="capitalize">
                                    {removeEnumUnderscore(provider.service)}
                                </span>
                                <span>
                                    {provider.start_date
                                        ? formatDate(provider.start_date, true)
                                        : null}
                                </span>
                                <span>
                                    {fee.fee_values.find(
                                        (feeValue) =>
                                            feeValue.type === providerFeeType
                                    )?.value || "--"}
                                </span>
                                <span>{provider.session_count ?? 0}</span>
                                <span>--</span>
                                <span>--</span>
                                <span>--</span>
                                <span>--</span>
                            </div>
                        );
                    })}
                </div>
                <div className="grid grid-cols-9 gap-x-8 px-16 py-12 bg-card-bg border-t border-t-stroke-divider border-b border-b-stroke-divider font-semibold text-xs leading-[18px] text-gray uppercase">
                    {getBottomDesiredValues.map((item) => (
                        <span key={item.type}>
                            {removeEnumUnderscore(item.type)}
                        </span>
                    ))}
                </div>
                <div className="px-16 py-[10px]">
                    <div className="grid grid-cols-9 gap-x-8 w-full text-sm font-regular items-center">
                        {getBottomDesiredValues.map((item) => {
                            const feesCalculated = calculateFees(item.value);

                            const hideToolTip = Boolean(
                                feesCalculated &&
                                    feesCalculated.singleFee &&
                                    !feesCalculated.tbdFee &&
                                    !feesCalculated.tempFee &&
                                    !feesCalculated.tempNoOfSessions
                            );

                            const isApplicableFee = hasApplicableFee(
                                fee.providers.map(
                                    (provider) => provider.credential
                                ),
                                fee.providers.map(
                                    (provider) => provider.service
                                )
                            ).includes(item.type);

                            return hideToolTip ? (
                                <div>
                                    <span
                                        className={cn(
                                            "rounded-r8 inline-block",
                                            {
                                                "bg-[#eefdfda3] border-primary border p-8":
                                                    isApplicableFee &&
                                                    item.value,
                                            }
                                        )}
                                    >
                                        {item.value || "--"}
                                    </span>
                                </div>
                            ) : (
                                <div>
                                    <span
                                        className={cn(
                                            "rounded-r8 inline-flex items-center gap-x-4",
                                            {
                                                "bg-[#eefdfda3] border-primary border p-8":
                                                    isApplicableFee &&
                                                    item.value,
                                            }
                                        )}
                                    >
                                        {item.value || "--"}
                                        {item.value ? (
                                            <Tooltip
                                                content={getMessageForFees(
                                                    item.value
                                                )}
                                            >
                                                <Button
                                                    variant="normal"
                                                    size="auto"
                                                >
                                                    <InfoIcon />
                                                </Button>
                                            </Tooltip>
                                        ) : null}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="grid grid-cols-9 gap-x-8 px-16 py-12 bg-card-bg border-t border-t-stroke-divider border-b border-b-stroke-divider font-semibold text-xs leading-[18px] text-gray uppercase">
                    <span>oonded</span>
                    <span>oonco</span>
                    <span>oonoop</span>
                    <span>ooncoins</span>
                    <span>innded</span>
                    <span>innco</span>
                    <span>INNOOP</span>
                    <span>INNCOINS</span>
                    <span>UCR</span>
                </div>
                <div className="px-16 py-[18px]">
                    <div className="grid grid-cols-9 gap-x-8 w-full text-sm font-regular items-center lowercase">
                        <span>{fee.oonded || "n/a"}</span>
                        <span>{fee.oonco || "n/a"}</span>
                        <span>{fee.oonoop || "n/a"}</span>
                        <span>{fee.ooncoins || "n/a"}</span>
                        <span>{fee.innded || "n/a"}</span>
                        <span>{fee.innco || "n/a"}</span>
                        <span>{fee.innoop || "n/a"}</span>
                        <span>{fee.inncoins || "n/a"}</span>
                        <span>{fee.ucr || "n/a"}</span>
                    </div>
                </div>
                <div className="grid grid-cols-9 gap-x-8 px-16 py-12 bg-card-bg border-t border-t-stroke-divider border-b border-b-stroke-divider font-semibold text-xs leading-[18px] text-gray uppercase">
                    {!showInsOverSelfPay ? <span>SELFPAY</span> : null}
                    <span>ACCEPT ASSIG.</span>
                    {!showInsOverSelfPay ? <span>ALT EMAIL SENT</span> : null}
                    <span className="col-span-6">REASON FOR UPDATE</span>
                </div>
                <div className="p-16">
                    <div className="grid grid-cols-9 gap-x-8 w-full text-sm font-regular items-center">
                        {!showInsOverSelfPay ? (
                            <div>
                                {isCurrentFee ? (
                                    <Dropdown>
                                        <DropdownTrigger asChild>
                                            <Button
                                                variant="normal"
                                                size="auto"
                                                className="flex items-center gap-x-8"
                                            >
                                                <span
                                                    className={cn(
                                                        "py-4 px-8 rounded-r4 text-xs text-[#981F41] bg-[#fbc7c67f] leading-normal",
                                                        {
                                                            "text-[#00563E] bg-[#CCFAE9]":
                                                                fee.self_pay,
                                                        }
                                                    )}
                                                >
                                                    {fee.self_pay
                                                        ? "Yes"
                                                        : "No"}
                                                </span>
                                                <ChevronDownIcon
                                                    stroke={
                                                        APP_COLORS.COLOR_GRAY
                                                    }
                                                />
                                            </Button>
                                        </DropdownTrigger>
                                        <DropdownContent>
                                            <DropdownItem
                                                onClick={() =>
                                                    handleEditClientFee({
                                                        self_pay: true,
                                                    })
                                                }
                                            >
                                                Yes
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() =>
                                                    handleEditClientFee({
                                                        self_pay: false,
                                                    })
                                                }
                                            >
                                                No
                                            </DropdownItem>
                                        </DropdownContent>
                                    </Dropdown>
                                ) : (
                                    <span
                                        className={cn(
                                            "py-4 px-8 rounded-r4 text-xs text-[#981F41] bg-[#fbc7c67f] leading-normal",
                                            {
                                                "text-[#00563E] bg-[#CCFAE9]":
                                                    fee.self_pay,
                                            }
                                        )}
                                    >
                                        {fee.self_pay ? "Yes" : "No"}
                                    </span>
                                )}
                            </div>
                        ) : null}
                        {isCurrentFee ? (
                            <div>
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <Button
                                            variant="normal"
                                            size="auto"
                                            className="flex items-center gap-x-8"
                                        >
                                            <span
                                                className={cn(
                                                    "py-4 px-8 rounded-r4 text-xs text-[#981F41] bg-[#fbc7c67f] leading-normal",
                                                    {
                                                        "text-[#00563E] bg-[#CCFAE9]":
                                                            fee.accepts_assignment,
                                                    }
                                                )}
                                            >
                                                {fee.accepts_assignment
                                                    ? "Yes"
                                                    : "No"}
                                            </span>
                                            <ChevronDownIcon
                                                stroke={APP_COLORS.COLOR_GRAY}
                                            />
                                        </Button>
                                    </DropdownTrigger>
                                    <DropdownContent>
                                        <DropdownItem
                                            onClick={() =>
                                                handleEditClientFee({
                                                    accepts_assignment: true,
                                                })
                                            }
                                        >
                                            Yes
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() =>
                                                handleEditClientFee({
                                                    accepts_assignment: false,
                                                })
                                            }
                                        >
                                            No
                                        </DropdownItem>
                                    </DropdownContent>
                                </Dropdown>
                            </div>
                        ) : (
                            <div>
                                <span
                                    className={cn(
                                        "py-4 px-8 rounded-r4 text-xs leading-normal",
                                        {
                                            "text-[#00563E] bg-[#CCFAE9]":
                                                fee.accepts_assignment,
                                        }
                                    )}
                                >
                                    {fee.accepts_assignment ? "Yes" : "No"}
                                </span>
                            </div>
                        )}
                        {!showInsOverSelfPay ? (
                            <div>
                                {isCurrentFee ? (
                                    <Dropdown>
                                        <DropdownTrigger asChild>
                                            <Button
                                                variant="normal"
                                                size="auto"
                                                className="flex items-center gap-x-8"
                                            >
                                                <span
                                                    className={cn(
                                                        "py-4 px-8 rounded-r4 text-xs text-[#981F41] bg-[#fbc7c67f] leading-normal",
                                                        {
                                                            "text-[#00563E] bg-[#CCFAE9]":
                                                                fee.alt_email_sent,
                                                        }
                                                    )}
                                                >
                                                    {fee.alt_email_sent
                                                        ? "Yes"
                                                        : "No"}
                                                </span>
                                                <ChevronDownIcon
                                                    stroke={
                                                        APP_COLORS.COLOR_GRAY
                                                    }
                                                />
                                            </Button>
                                        </DropdownTrigger>
                                        <DropdownContent>
                                            <DropdownItem
                                                onClick={() =>
                                                    handleEditClientFee({
                                                        alt_email_sent: true,
                                                    })
                                                }
                                            >
                                                Yes
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() =>
                                                    handleEditClientFee({
                                                        alt_email_sent: false,
                                                    })
                                                }
                                            >
                                                No
                                            </DropdownItem>
                                        </DropdownContent>
                                    </Dropdown>
                                ) : (
                                    <span
                                        className={cn(
                                            "py-4 px-8 rounded-r4 text-xs text-[#981F41] bg-[#fbc7c67f] leading-normal",
                                            {
                                                "text-[#00563E] bg-[#CCFAE9]":
                                                    fee.alt_email_sent,
                                            }
                                        )}
                                    >
                                        {fee.alt_email_sent ? "Yes" : "No"}
                                    </span>
                                )}
                            </div>
                        ) : null}
                        <span className="col-span-6">
                            {fee.reason_for_update || "--"}
                        </span>
                    </div>
                </div>
            </div>
        </article>
    );
}

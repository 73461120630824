export default function NavArrowUpIcon() {
    return (
        <svg
            width="13"
            height="15"
            viewBox="0 0 13 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.4"
                d="M2.02138 11.1765C1.89116 11.308 1.71455 11.3818 1.5304 11.3818C1.34625 11.3818 1.16964 11.308 1.03942 11.1765L0.382163 10.5115C0.251567 10.3796 0.1782 10.2008 0.1782 10.0143C0.1782 9.82783 0.251567 9.649 0.382163 9.51714L4.85207 4.99634L6.5029 6.65981L2.02138 11.1765Z"
                fill="#576A81"
            />
            <path
                d="M12.6228 10.5102L11.9655 11.1753C11.9011 11.2404 11.8245 11.2921 11.7403 11.3273C11.656 11.3626 11.5657 11.3807 11.4745 11.3807C11.3833 11.3807 11.293 11.3626 11.2088 11.3273C11.1245 11.2921 11.048 11.2404 10.9835 11.1753L4.85091 4.99364L6.01163 3.82176C6.14185 3.69031 6.31846 3.61646 6.50261 3.61646C6.68676 3.61646 6.86337 3.69031 6.99359 3.82176L12.6228 9.5159C12.7534 9.64776 12.8267 9.8266 12.8267 10.0131C12.8267 10.1995 12.7534 10.3784 12.6228 10.5102Z"
                fill="#576A81"
            />
        </svg>
    );
}

import { useNavigate } from "react-router-dom";

import FillableClaimsForm from "../components/FillableClaimsForm/FillableClaimsForm";
import Navbar from "../../../components/Navbar/Navbar";
import BackLink from "../../../components/BackLink/BackLink";

export default function PrintClaim() {
    const navigate = useNavigate();

    return (
        <>
            <Navbar title="Print Claim" />

            <div className="page">
                <div className="mb-32">
                    <BackLink onClick={() => navigate(-1)} />
                </div>
                <FillableClaimsForm />
            </div>
        </>
    );
}

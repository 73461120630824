import { useParams } from "react-router-dom";
import { useFetchBatchRemits } from "../../../hooks/remits.queries";
import { useFetchDocument } from "../../../../../hooks/queries";
import Navbar from "../../../../../components/Navbar/Navbar";

export default function BatchEOB() {
    // Get id from url params
    const params = useParams();
    const remitId = params.remitId || "";

    const batchRemitData = useFetchBatchRemits({
        batch_remit_id: remitId,
    });

    const batchRemitEOB = useFetchDocument(
        batchRemitData.data?.data[0].remit_batch_eob_url as string,
        Boolean(batchRemitData.data?.data[0].remit_batch_eob_url)
    );

    const decodedString = () => {
        if (batchRemitEOB.data?.data) {
            return Buffer.from(batchRemitEOB.data?.data, "base64").toString(
                "utf-8"
            );
        }
        return "";
    };

    return (
        <>
            <Navbar title="EOB" />
            <div className="page">
                <div className="whitespace-pre-wrap">
                    <pre>{decodedString()}</pre>
                </div>
            </div>
        </>
    );
}

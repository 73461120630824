export default function FolderOpenIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <circle cx="16" cy="16" r="16" fill="#F8F8F6" />
            <path
                d="M2.49902 8.61122V5.27789C2.49902 4.05011 3.49347 3.05566 4.72125 3.05566H6.88902C7.56347 3.05566 8.20013 3.36122 8.62236 3.88678L9.29236 4.72233H15.2768C16.5046 4.72233 17.499 5.71677 17.499 6.94455V8.61122"
                stroke="#1A738D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.00105 8.61133H16.9966C18.0922 8.61133 18.8888 9.64911 18.6066 10.7069L17.3833 15.2947C17.1244 16.268 16.2433 16.9447 15.2366 16.9447H4.76216C3.75549 16.9447 2.87438 16.268 2.61549 15.2947L1.39216 10.7069C1.10994 9.64911 1.90771 8.61133 3.00216 8.61133H3.00105Z"
                stroke="#1A738D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

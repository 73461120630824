/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import * as React from "react";
import cn from "classnames";
import CheckIcon from "../Icons/Check";
import styles from "./FormCheckbox.module.scss";

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    /** Checkbox label text */
    label?: string;
    /** Checkbox label text extra styling */
    labelClass?: string;
    /** Checkbox name for grouping checkbox inputs */
    name?: string;
    /** The id of the checkbox */
    id?: string;
}

const FormCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({ label, labelClass, id, name, ...props }, ref) => {
        return (
            <div className={styles.checkbox}>
                <label
                    role="button"
                    tabIndex={0}
                    htmlFor={id}
                    className={cn(styles.label, "fs-exclude")}
                >
                    <input
                        className={styles.input}
                        type="checkbox"
                        id={id}
                        name={name}
                        ref={ref}
                        {...props}
                    />
                    <span className={styles.custom_input}>
                        <CheckIcon />
                    </span>
                    {label && (
                        <span className={cn(labelClass, styles.text)}>
                            {label}
                        </span>
                    )}
                </label>
            </div>
        );
    }
);

FormCheckbox.displayName = "Input";

export default FormCheckbox;

import cn from "classnames";
import { Link, useParams } from "react-router-dom";
import { removeEnumUnderscore } from "../../../utils/helpers";
import { NoteLabels } from "../../../utils/types/notes";
import Card from "../../Card/Card";
import styles from "./NoteDetails.module.scss";
import { useFetchSessionNote } from "../../../hooks/queries/note";
import Skeleton from "../../Skeleton/Skeleton";

interface NoteDetailsProps {
    /** Show Provider name */
    showProvider?: boolean;
}

export default function NoteDetails({
    // details,
    showProvider,
}: NoteDetailsProps) {
    const params = useParams();
    const noteId = params.noteId as string;
    const clientId = params.clientId as string;

    const { data, error, isLoading } = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    if (isLoading) {
        return (
            <Card type="dashboard">
                <Skeleton height="70px" width="100%" />
            </Card>
        );
    }

    if (error) {
        return (
            <Card type="dashboard">
                <span className={styles.error}>
                    {error?.response?.data.message ||
                        "Cannot display note details at this time"}
                </span>
            </Card>
        );
    }

    return (
        <div className="rounded-r8 bg-white border border-stroke-divider p-16">
            <h2 className={styles.text}>NOTE DETAILS</h2>
            <div className={styles.details}>
                <div className={styles.detail}>
                    <span className="section-info fs-exclude">Client name</span>
                    <Link
                        to={`/clients/${data.data.client?.client_id}`}
                        className={cn("section-value", styles.clientname)}
                    >
                        {data.data.client?.first_name}{" "}
                        {data.data.client?.last_name}
                    </Link>
                </div>
                {showProvider && (
                    <div className={styles.detail}>
                        <span className="section-info fs-exclude">
                            Provider name
                        </span>
                        <span className="section-value">
                            {`${data.data.provider?.first_name} ${data.data.provider?.last_name}`}
                        </span>
                    </div>
                )}
                <div className={styles.detail}>
                    <span className="section-info fs-exclude">Note type</span>
                    <span className="section-value">
                        {NoteLabels[data.data.type]}
                    </span>
                </div>
                <div className={styles.detail}>
                    <span className="section-info fs-exclude">
                        Appointment Type
                    </span>
                    <span className="section-value">
                        {removeEnumUnderscore(
                            data.data.appointment_type || "--"
                        )}
                    </span>
                </div>
            </div>
        </div>
    );
}

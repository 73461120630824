import * as React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    Alert,
    Button,
    Checkbox,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Input,
} from "@jhool-io/fe-components";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useSplitPayment } from "../../../../hooks/mutations/billing";
import useToast from "../../../../hooks/useToast";
import { ISplitPaymentPayloadSingleBill } from "../../../../utils/types/billing";
import { useFetchInvoices } from "../../hooks/queries/billing.queries";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import {
    cn,
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    showMoneyInAppFormat,
} from "../../../../utils/helpers";
import { ISplitPaymentPayload } from "../../../payments/types/payments.types";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import { BillStatus, BillStatusLabels } from "../../types/billing.types";

interface SplitPaymentFormProps {
    paymentId: string;
    onFormSubmit(): void;
    defaultAmount: number;
    invoiceToMoveId: string;
    paymentDate: string;
}

export default function SplitPaymentForm({
    paymentId,
    onFormSubmit,
    defaultAmount,
    invoiceToMoveId,
    paymentDate,
}: SplitPaymentFormProps) {
    const [selectedInvoices, setSelectedInvoices] = React.useState<string[]>([
        invoiceToMoveId,
    ]);
    const [invoiceStatuses, setInvoiceStatuses] = React.useState<string[]>([]);

    const params = useParams();
    const clientId = params.clientId as string;

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        watch,
    } = useForm<ISplitPaymentPayloadSingleBill>({
        resolver: yupResolver(
            yup.object().shape({
                invoices: yup.array().of(
                    yup.object().shape({
                        amount: yup
                            .string()
                            .matches(
                                /^(?!^\.)(\d+(\.\d+)?|\.\d+)$/,
                                "Please enter a valid amount"
                            ),
                        invoice_id: yup
                            .string()
                            .required("Invoice ID is required"),
                    })
                ),
            })
        ),
        mode: "onChange",
    });

    // Mutation hook for splitting payment
    const splitPayment = useSplitPayment();

    const formInvoices = watch("invoices");

    const { toast } = useToast();

    const queryClient = useQueryClient();

    const { data: invoiceData, isLoading: invoiceIsLoading } = useFetchInvoices(
        {
            client_id: clientId,
            payment_view: true,
            invoice_status:
                invoiceStatuses.length === 0 ? undefined : invoiceStatuses,
        }
    );

    const toggleInvoiceSelection = (invoice: string) => {
        if (selectedInvoices.includes(invoice)) {
            setSelectedInvoices(
                selectedInvoices.filter((id) => id !== invoice)
            );
        } else {
            setSelectedInvoices([...selectedInvoices, invoice]);
        }
    };

    const total = formInvoices
        ? Number(
              formInvoices
                  .filter((invoice) => invoice.amount || 0)
                  .reduce(
                      (accumulator, invoice) =>
                          accumulator + Number(invoice.amount),
                      0
                  )
                  .toFixed(2)
          )
        : 0;

    const sortedInvoices = invoiceData?.data.sort((a, b) => {
        const aPriority = a.invoice_id === invoiceToMoveId ? -1 : 0;
        const bPriority = b.invoice_id === invoiceToMoveId ? -1 : 0;
        return aPriority - bPriority;
    });

    const handleSetInvoiceStatuses = (status: string) => {
        if (invoiceStatuses.includes(status)) {
            setInvoiceStatuses(() =>
                invoiceStatuses.filter((IStatus) => IStatus !== status)
            );
        } else {
            setInvoiceStatuses(() => [...invoiceStatuses, status]);
        }
    };

    // function to move payment
    const onSubmit = (payload: ISplitPaymentPayload) => {
        if (total !== defaultAmount) {
            toast({
                mode: "error",
                message: `The total amount entered must be equal to ${showMoneyInAppFormat(
                    defaultAmount
                )}`,
            });
            return;
        }

        const filteredInvoices = payload.invoices.filter(
            (invoice) => invoice.amount
        );

        const dataToSend = {
            payment_id: paymentId,
            invoices: filteredInvoices.map((invoice) => ({
                amount: Number(invoice.amount),
                invoice_id: invoice.invoice_id,
            })),
        };

        splitPayment.mutate(dataToSend, {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: ["invoices", { client_id: clientId }],
                });

                await queryClient.invalidateQueries({
                    queryKey: ["get-payment"],
                });
                toast({
                    mode: "success",
                    message: res.message || "Payment spliting successful",
                });

                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not split payment at this time, please try again later",
                });
            },
        });
    };

    return (
        <form id="move-payment" onSubmit={handleSubmit(onSubmit)}>
            <Alert
                type="info"
                title="Split payment on selected bills"
                description={`The total amount entered across all the bills must be equal to ${showMoneyInAppFormat(
                    defaultAmount
                )}`}
                classNames="mb-24"
            />

            <div className="mb-32 grid grid-cols-4 gap-x-29 bg-card-bg rounded-r8 border border-stroke py-16 px-24">
                <div className="flex flex-col gap-y-8 text-xs">
                    <span className="font-medium uppercase">Payment Date</span>
                    <span className="font-normal">
                        {formatDate(paymentDate)}
                    </span>
                </div>
                <div className="flex flex-col gap-y-8 text-xs">
                    <span className="font-medium uppercase">Total amount</span>
                    <span className="font-normal">
                        {showMoneyInAppFormat(defaultAmount)}
                    </span>
                </div>
                <div className="flex flex-col gap-y-8 text-xs">
                    <span className="font-medium uppercase">
                        Amount inputed
                    </span>
                    <span className="font-normal">
                        {showMoneyInAppFormat(total)}
                    </span>
                </div>
                <div className="flex flex-col gap-y-8 text-xs">
                    <span className="font-medium uppercase">Amount left</span>
                    <span className="font-normal">
                        {showMoneyInAppFormat(
                            Math.abs(defaultAmount) - Math.abs(total)
                        )}
                    </span>
                </div>
            </div>
            <div className="mb-8 flex items-center justify-end">
                {invoiceData && selectedInvoices.length > 0 && (
                    <div className="flex items-center gap-x-8 mr-auto">
                        <span className="text-xs font-medium text-black-main pr-8 border-r border-r-strokedark">
                            {selectedInvoices.length} of{" "}
                            {invoiceData?.total_count} sessions selected
                        </span>
                        <Button
                            size="auto"
                            variant="normal"
                            className="text-xs font-medium text-primary"
                            onClick={() => {
                                setSelectedInvoices([invoiceToMoveId]);
                                reset();
                            }}
                        >
                            Clear all
                        </Button>
                    </div>
                )}
                <Dropdown>
                    <DropdownTrigger asChild>
                        <FilterButton text="Bill Status" />
                    </DropdownTrigger>
                    <DropdownContent
                        align="end"
                        width="auto"
                        maxHeight={216}
                        className="overflow-y-auto"
                    >
                        {Object.values(BillStatus).map((status) => (
                            <DropdownItem
                                key={status}
                                onClick={() => handleSetInvoiceStatuses(status)}
                                isMulti
                                itemValue={status}
                                values={invoiceStatuses}
                            >
                                {makeStringFirstLetterCapital(
                                    removeEnumUnderscore(
                                        BillStatusLabels[status]
                                    )
                                )}
                            </DropdownItem>
                        ))}
                    </DropdownContent>
                </Dropdown>
            </div>

            <div className="p-12 rounded-r8  border-[0.2px] border-stroke max-h-[250px] overflow-y-auto">
                {invoiceIsLoading && (
                    <Skeleton
                        height={20}
                        width="100%"
                        count={6}
                        containerTestId="invoices-loader"
                    />
                )}
                {sortedInvoices?.map((invoice, i) => (
                    <div
                        key={invoice.invoice_id}
                        className="flex justify-between items-center pb-12"
                    >
                        <div className="flex gap-x-8 items-center text-sm">
                            <Checkbox
                                name="invoice"
                                handleChange={() => {
                                    toggleInvoiceSelection(
                                        invoice.invoice_id as string
                                    );
                                }}
                                value=""
                                isChecked={
                                    selectedInvoices.includes(
                                        invoice.invoice_id as string
                                    ) || invoiceToMoveId === invoice.invoice_id
                                }
                            />
                            <span>{formatDate(invoice.date_of_service)},</span>
                            <span>
                                CPT: {invoice.cpt_code},{" "}
                                <span
                                    className={cn({
                                        "text-danger":
                                            invoice.accounting_coinsurance > 0,
                                    })}
                                >
                                    CINS DUE:{" "}
                                    {invoice.accounting_coinsurance > 0
                                        ? `(${showMoneyInAppFormat(
                                              invoice.accounting_coinsurance
                                          )})`
                                        : showMoneyInAppFormat(
                                              Math.abs(
                                                  invoice.accounting_coinsurance
                                              )
                                          )}
                                </span>
                            </span>
                        </div>

                        <div>
                            <Input
                                {...register(`invoices.${i}.amount`)}
                                showCurrency
                                placeholder="Enter Amount"
                                disabled={
                                    invoiceToMoveId !== invoice.invoice_id &&
                                    !selectedInvoices.includes(
                                        invoice.invoice_id as string
                                    )
                                }
                                errorText={
                                    errors?.invoices?.[i]?.amount?.message
                                }
                                max={defaultAmount}
                                hasError={!!errors.invoices?.[i]?.amount}
                            />
                            <input
                                type="hidden"
                                value={invoice.invoice_id}
                                {...register(`invoices.${i}.invoice_id`)}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </form>
    );
}

import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { handleRemoveEmptyParamFromQueryParams } from "../../../../utils/helpers";
import { IApiResponse } from "../../../../utils/types/api-response";
import {
    exportBillingSummaryReport,
    fetchBillingQueue,
    getInvoicePayment,
} from "../../services/billing.services";
import {
    IBillingSummaryFilters,
    IBillingFilterTypes,
    IBillingResponse,
    IGetPayment,
    IGetPaymentResponse,
} from "../../types/billing.types";

// Fetch billing queue hook
export function useFetchInvoices(
    filters?: IBillingFilterTypes,
    enabled?: boolean
) {
    interface BillingApiResponse<DataType> {
        data: DataType;
        summary?: {
            total_coinsurance_amount_collected: number;
            total_coinsurance_amount_owed: number;
            total_insurance_amount_collected: number;
            total_insurance_amount_owed: number;
            total_coinsurance_amount_written_off: number;
            total_insurance_amount_written_off: number;
            total_unmatched_amount: number;
        };
        count: number;
        total_count: number;
        current_page: number;
        total_pages: number;
    }
    return useQuery<
        BillingApiResponse<IBillingResponse[]>,
        AxiosError<IApiResponse<IBillingResponse[]>>
    >({
        queryKey: [
            "invoices",
            handleRemoveEmptyParamFromQueryParams(filters || {}),
        ],
        queryFn: () => fetchBillingQueue(filters),
        enabled,
    });
}

//  get invoice payment
export function useGetInvoicePayment(
    invoiceId: string,
    payload: IGetPayment,
    enabled?: boolean
) {
    return useQuery<IGetPaymentResponse, AxiosError<IGetPaymentResponse>>({
        queryKey: [
            "get-payment",
            handleRemoveEmptyParamFromQueryParams(payload),
        ],
        queryFn: () => getInvoicePayment(invoiceId, payload),
        enabled,
    });
}

export function useExportBillingSummaryReport(
    filters: IBillingSummaryFilters,
    isEnabled: boolean
) {
    return useQuery<Blob, AxiosError<string>>({
        queryKey: [
            "billing-summary-report",
            handleRemoveEmptyParamFromQueryParams(filters),
        ],
        queryFn: () =>
            exportBillingSummaryReport(
                handleRemoveEmptyParamFromQueryParams(filters)
            ),
        enabled: isEnabled,
        onSuccess: (data) => {
            const href = window.URL.createObjectURL(data);

            const anchorElement = document.createElement("a");
            anchorElement.href = href;

            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        },
    });
}

import Navbar from "../../../components/Navbar/Navbar";
import PayersList from "../components/PayersList/PayersList";

export default function Payers() {
    return (
        <>
            <Navbar title="Payers" />
            <div className="page">
                <PayersList />
            </div>
        </>
    );
}

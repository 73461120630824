import cn from "classnames";
import styles from "./Infobox.module.scss";

interface InfoboxProps {
    /**  message */
    message: string;
    icon: React.ReactNode;
    /** Optional style object to add more styling */
    className?: string;
}

export default function Infobox({ message, className, icon }: InfoboxProps) {
    return (
        <div role="alert" className={cn(styles.info)}>
            {icon}
            <div className={cn(styles.text, className, "fs-exclude")}>
                {message}
            </div>
        </div>
    );
}

import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    IApiResponse,
    IPaginatedApiResponse,
} from "../../utils/types/api-response";
import { IInternalUser, IUsersFilterTypes } from "../../utils/types/user";
import { fetchUsersList } from "../../services/user-management";
import { handleRemoveEmptyParamFromQueryParams } from "../../utils/helpers";

export function useFetchUsersList(
    filters: IUsersFilterTypes,
    enabled?: boolean
) {
    interface ICustomPaginatedApiResponse<DataType>
        extends IPaginatedApiResponse<DataType> {
        practice_domains: string[];
    }
    return useQuery<
        ICustomPaginatedApiResponse<IInternalUser[]>,
        AxiosError<IApiResponse<IInternalUser[]>>
    >({
        queryKey: ["users", handleRemoveEmptyParamFromQueryParams(filters)],
        queryFn: () => fetchUsersList(filters),
        enabled,
    });
}

export default function TrendingUpIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.6693 4L12.1959 5.52667L8.9426 8.78L6.27594 6.11333L1.33594 11.06L2.27594 12L6.27594 8L8.9426 10.6667L13.1426 6.47333L14.6693 8V4H10.6693Z"
                fill="#00B69B"
            />
        </svg>
    );
}

import Navbar from "../../../components/Navbar/Navbar";
import ClaimsLists from "../components/ClaimsLists/ClaimsLists";

export default function Claims() {
    return (
        <>
            <Navbar title="Claims" />
            <div className="page">
                <ClaimsLists />
            </div>
        </>
    );
}

import {
    Button,
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@jhool-io/fe-components";
import * as React from "react";
import cn from "classnames";
import { Link, useLocation, useParams } from "react-router-dom";
import { useFetchUserDetails } from "../../hooks/queries/user";
import Avatar from "../Avatar/Avatar";
import ArrowDownIcon from "../Icons/ArrowDownIcon";
import BellIcon from "../Icons/BellIcon";
import MenuIcon from "../Icons/Menu";
import NotificationBox from "../NotificationBox/NotificationBox";
import Skeleton from "../Skeleton/Skeleton";
import styles from "./Navbar.module.scss";
import {
    removeEnumUnderscore,
    userInitials,
    cn as tailwindCn,
    formatDate,
} from "../../utils/helpers";
import { NotificationContext } from "../../context/notification/NotificationContext";
import ScreenIcon from "../Icons/Screen";
import { useFetchSessionNote } from "../../hooks/queries/note";
import { NoteStatus } from "../../utils/types/notes";
import SendIcon from "../Icons/Send";
import usePractice from "../../hooks/usePractice";
import useAuthContext from "../../hooks/useAuthContext";
import SearchClients from "../../modules/clients/components/SearchClients/SearchClients";
import TimesIcon from "../Icons/Times";
import useAnnouncement from "../../hooks/useAnnouncement";
import { useDoesUserHaveRole } from "../../hooks/permissions";
import moment from "../../utils/moment";
import { UserRole } from "../../modules/user-management/types/user-management.types";
import ArrowRightIcon from "../Icons/ArrowRight";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { toggleNavState } from "../../redux/features/nav/navSlice";

interface NavProps {
    title?: string;
    showBackIconInNav?: boolean;
}

export default function Navbar({ title, showBackIconInNav = false }: NavProps) {
    const location = useLocation();

    const { isOpen } = useAppSelector((state) => state.nav);
    const dispatch = useAppDispatch();

    // Get id from url params
    const params = useParams();
    const noteId = params.noteId as string;
    const clientId = params.clientId as string;

    const { practice } = usePractice();

    const { announcements, handleClearedAtValue, clearedBannerAt } =
        useAnnouncement();

    const { logOutUser } = useAuthContext();

    const noteContent = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    // Fetch user details
    const {
        data: loggedInUserData,
        isLoading,
        isSuccess,
    } = useFetchUserDetails();

    const userNotifications = React.useContext(NotificationContext);

    const isEditView = location.pathname.includes("edit");

    const isStaging = import.meta.env.VITE_NODE_ENV === "staging";

    /**
     * Determine whether to show the icon used to toggle between
     * edit and view mode
     */

    const shouldShowScreenIcon =
        noteContent.data?.data &&
        noteContent?.data.data.note_status === NoteStatus.DRAFT &&
        Boolean(clientId && noteId && location.pathname.includes("notes"));

    // Checks to decide if app notification banner should be shown
    const hasAnnoucements = announcements && announcements.length > 0;
    const practiceUsersCanSeeAnnouncement =
        (hasAnnoucements &&
            announcements[0]?.practice_ids.includes(
                practice?.practice_id || ""
            )) ||
        (hasAnnoucements && announcements[0]?.practice_ids.includes("all"));
    const isUserRoleInRolesAllowed = useDoesUserHaveRole(
        hasAnnoucements ? announcements?.[0]?.roles : []
    );
    const userRoleIsAllowedToView =
        (hasAnnoucements && isUserRoleInRolesAllowed) ||
        (hasAnnoucements &&
            announcements[0]?.roles.includes(
                "allusers" as unknown as UserRole
            ));

    const isAnnoucementDateGreaterThanClearedDate = () => {
        if (hasAnnoucements) {
            const clearedDate = clearedBannerAt
                ? moment(clearedBannerAt).toDate()
                : moment("2000-12-12").toDate();
            const announcementDate = new Date(
                formatDate(
                    announcements[0].created_at,
                    false,
                    "yyyy-MM-dd'T'HH:mm:ss"
                )
            );

            return announcementDate > clearedDate;
        }
        return false;
    };

    const showAnnouncement =
        announcements &&
        userRoleIsAllowedToView &&
        hasAnnoucements &&
        isAnnoucementDateGreaterThanClearedDate() &&
        practiceUsersCanSeeAnnouncement;

    return (
        <div className="h-auto">
            {showAnnouncement && (
                <div className="w-full h-auto py-12  text-[#00563E] bg-[#ccfae97f] text-sm font-medium flex items-center justify-center relative pl-20 pr-40">
                    {announcements[0].has_redirect ? (
                        <Link
                            to={announcements[0].redirect_url || ""}
                            className="flex items-center gap-x-1 hover:gap-x-2 underline"
                        >
                            {announcements[0].details}

                            <ArrowRightIcon stroke="#00563E" />
                        </Link>
                    ) : (
                        <span> {announcements[0].details}</span>
                    )}

                    <Button
                        size="auto"
                        variant="normal"
                        className="absolute right-[20px]"
                        onClick={() => handleClearedAtValue?.()}
                    >
                        <TimesIcon stroke="#00563E" />
                    </Button>
                </div>
            )}
            <nav className={styles.navbar}>
                {!isOpen && (
                    <div className="flex items-center gap-x-12">
                        <span className="font-bold text-primary uppercase text-lg">
                            Mantle
                        </span>
                        <Button
                            size="auto"
                            variant="normal"
                            onClick={() => dispatch(toggleNavState())}
                        >
                            <MenuIcon />
                        </Button>
                    </div>
                )}
                <h1
                    className={tailwindCn("text-lg font-semibold", {
                        hidden: !isOpen,
                    })}
                >
                    {title}
                </h1>
                {isLoading && (
                    <Skeleton
                        containerTestId="navbar-loader"
                        width={143}
                        height={32}
                    />
                )}
                <SearchClients />
                {isSuccess && loggedInUserData && (
                    <div className={styles.info}>
                        {shouldShowScreenIcon ? (
                            <Link
                                className={cn(styles.screenicon, {
                                    [styles.screenicon_edit]: isEditView,
                                })}
                                to={
                                    isEditView
                                        ? `/notes/${clientId}/${noteId}`
                                        : `/notes/${noteId}/${clientId}/edit`
                                }
                            >
                                <ScreenIcon />
                            </Link>
                        ) : null}
                        <a
                            href="https://docs.google.com/document/d/1BDxPqNJQbg4O8ZOuQ6mM4Q2yj-iUijIBXYC6-6XHjhc/edit?usp=sharing"
                            target="_blank"
                            rel="noreferrer"
                            className={tailwindCn(
                                "text-primary text-sm font-medium flex gap-8 items-center mr-32"
                            )}
                        >
                            <SendIcon stroke="#1A738D" /> FAQs
                        </a>
                        {!showBackIconInNav && (
                            <Popover>
                                <PopoverTrigger
                                    className={styles.notification}
                                    asChild
                                >
                                    <Button
                                        variant="normal"
                                        size="auto"
                                        className="relative"
                                    >
                                        <>
                                            <BellIcon />
                                            {userNotifications.some(
                                                (n) => !n.is_read
                                            ) && (
                                                <span className="absolute w-[15px] h-[15px] rounded-full bg-[#F3164E] text-white flex items-center justify-center text-[7px] font-bold top-0 -right-[3px]">
                                                    {
                                                        userNotifications.filter(
                                                            (n) => !n.is_read
                                                        ).length
                                                    }
                                                </span>
                                            )}
                                        </>
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                    className={
                                        (styles.notfi_pp, "!z-[10] !w-[350px]")
                                    }
                                    align="end"
                                >
                                    <NotificationBox
                                        data={userNotifications}
                                        type="not_reminder"
                                        isError={false}
                                        loading={false}
                                    />
                                </PopoverContent>
                            </Popover>
                        )}
                        {!showBackIconInNav && (
                            <div className="h-20 w-[1.5px] bg-strokedark mr-16 ml-32 hidden md:block" />
                        )}
                        <Popover>
                            <PopoverTrigger asChild>
                                <div className={styles.details}>
                                    <Avatar
                                        label={userInitials(
                                            loggedInUserData.first_name,
                                            loggedInUserData.last_name
                                        )}
                                    />

                                    <span className={styles.profile}>
                                        <span className={styles.user_name}>
                                            {loggedInUserData.first_name}
                                        </span>
                                        <ArrowDownIcon />
                                    </span>
                                </div>
                            </PopoverTrigger>
                            <PopoverContent
                                className={styles.profile_pp}
                                align="end"
                            >
                                <div className={styles.dropdown}>
                                    <div className={styles.dropdown_info}>
                                        <Avatar
                                            label={userInitials(
                                                loggedInUserData.first_name,
                                                loggedInUserData.last_name
                                            )}
                                        />
                                        <div
                                            className={
                                                styles.dropdown_info_main
                                            }
                                        >
                                            <span
                                                className={
                                                    styles.dropdown_info_main_name
                                                }
                                            >
                                                {`${removeEnumUnderscore(
                                                    loggedInUserData.first_name
                                                )} ${removeEnumUnderscore(
                                                    loggedInUserData.last_name
                                                )}`}
                                            </span>
                                            <span
                                                className={
                                                    styles.dropdown_info_main_title
                                                }
                                            >
                                                {(practice?.roles || []).join(
                                                    ", "
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        onClick={() => logOutUser()}
                                        className="text-sm text-danger font-medium my-12 justify-start"
                                        aria-label="Log out button"
                                    >
                                        Logout
                                    </Button>
                                </div>
                            </PopoverContent>
                        </Popover>
                    </div>
                )}
            </nav>
            {isStaging && (
                <div className="py-[2px] border-t border-t-[#981F41] border-b border-b-[#981F41] bg-[#fbc7c67f] text-[#981F41] font-semibold text-xs uppercase text-center">
                    Viewing test site
                </div>
            )}
        </div>
    );
}

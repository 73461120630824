import * as React from "react";
import {
    Alert,
    Button,
    Card,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Switch,
    Tooltip,
} from "@jhool-io/fe-components";
import { useParams, useSearchParams } from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import { useFetchRemitClaims } from "../../hooks/remits.queries";
import Remit from "../Remit/Remit";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import { cn, formatDate } from "../../../../utils/helpers";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import ArchiveRemitForm from "../ArchiveRemitForm/ArchiveRemitForm";
import { useArchiveRemit } from "../../hooks/remits.mutations";
import useToast from "../../../../hooks/useToast";
import InfoIcon from "../../../../components/Icons/InfoIcon";

interface SwitchOptions {
    title: React.ReactNode;
    key: string;
    onClick: () => void;
}

type DialogTypes = "archive" | "unarchive";

export default function RemitsWrapper() {
    const [dialogInView, setDialogInView] = React.useState<DialogTypes | null>(
        null
    );

    const [searchParams, setSearchParams] = useSearchParams();
    const { noteId } = useParams();

    const showArchivedRemits = searchParams.get("archived_remits") || "false";

    // fetch single remit data
    const { data, isLoading, error, isSuccess } = useFetchRemitClaims({
        note_id: noteId,
    });

    const archiveMutation = useArchiveRemit();

    const archiveRemitIsMutating = useIsMutating(["archive-remit"]);

    const { toast } = useToast();

    const queryClient = useQueryClient();

    const handleSwitchButtonClick = (remit: string) => {
        searchParams.set("remit_in_view", remit);
        setSearchParams(searchParams);
    };

    let switchOptions: SwitchOptions[] = [];
    let activeSwitch: string = searchParams.get("remit_in_view") || "";

    if (isLoading) {
        return (
            <>
                <Card classNames="mt-32 px-24 py-12 bg-white rounded-r8 border border-[#c0c7cf33] overflow-x-auto">
                    <div className="min-w-[700px] max-w-full overflow-x-auto">
                        <div className="text-sm font-bold flex items-center gap-x-12 ">
                            <Skeleton
                                className="h-[255px] rounded-r8 w-[100px]"
                                containerTestId="remit-loader"
                            />
                            <div className="ml-auto flex items-center gap-x-12 w-[350px]">
                                <Skeleton
                                    className="h-[255px] rounded-r8 flex-1"
                                    containerTestId="remit-loader"
                                />
                                <Skeleton
                                    className="h-[255px] rounded-r8 flex-1"
                                    containerTestId="remit-loader"
                                />
                            </div>
                        </div>
                    </div>
                </Card>
                <Card classNames="mb-32">
                    <Skeleton
                        className="w-full h-[200px]"
                        containerTestId="remit-loader"
                    />
                </Card>
                <Card>
                    <Skeleton
                        className="w-full h-[200px]"
                        containerTestId="remit-loader"
                    />
                </Card>
                <Card>
                    <Skeleton
                        className="w-full h-[200px]"
                        containerTestId="remit-loader"
                    />
                </Card>
                <Card>
                    <Skeleton
                        className="w-full h-[200px]"
                        containerTestId="remit-loader"
                    />
                </Card>
            </>
        );
    }

    if (error) {
        return (
            <ListState
                isError
                stateHelperText="Try reloading this page to solve this issue"
                errorMsg={
                    error?.response?.data.message ||
                    "Cannot display remit details at this time. Please try again later"
                }
                cardClass="mt-32"
            />
        );
    }

    if (data && data.data && isSuccess) {
        if (data.data.length > 0 && data.data.length !== 1) {
            const archivedFilterText = () => {
                if (showArchivedRemits === "true") return "Archived";
                return "Unarchived";
            };

            const filteredRemitsList = showArchivedRemits
                ? data.data.filter(
                      (remit) =>
                          String(remit.is_archived) === showArchivedRemits
                  )
                : data.data;

            switchOptions = filteredRemitsList.map((remit) => {
                const key = remit.remittance_id;
                return {
                    title: (
                        <span className="flex items-center gap-x-4">
                            Remit
                            {formatDate(
                                remit.remit_payment_date,
                                false,
                                "MM/dd/yyyy hh:mmaaa"
                            )}
                            <Tooltip
                                content={`Claim no: ${remit.claim_number} / Remit id: ${remit.remittance_id}`}
                            >
                                <Button variant="normal" size="auto">
                                    <InfoIcon />
                                </Button>
                            </Tooltip>
                        </span>
                    ),
                    key: remit.remittance_id,
                    onClick: () => handleSwitchButtonClick(key),
                };
            });

            if (!activeSwitch && switchOptions.length > 0) {
                activeSwitch = switchOptions[0].key;
            }

            const handleSetArchivedFilter = (archiveStatus: string) => {
                searchParams.set("archived_remits", archiveStatus);

                if (
                    data.data.filter(
                        (remit) => String(remit.is_archived) === archiveStatus
                    ).length > 0
                ) {
                    searchParams.set(
                        "remit_in_view",
                        data.data.filter(
                            (remit) =>
                                String(remit.is_archived) === archiveStatus
                        )[0].remittance_id
                    );
                } else searchParams.delete("remit_in_view");

                setSearchParams(searchParams);
            };

            const remitInView = filteredRemitsList.find(
                (remit) => remit.remittance_id === activeSwitch
            );

            const handleUnArchive = () => {
                const dataToSend = {
                    archive_status: false,
                    remit_ids_to_archive: [remitInView?.remittance_id || ""],
                };

                archiveMutation.mutate(dataToSend, {
                    onSuccess: async (res) => {
                        await queryClient.invalidateQueries({
                            queryKey: [`remit-claims`],
                        });

                        searchParams.delete("remit_in_view");
                        setSearchParams(searchParams);
                        setDialogInView(null);

                        toast({
                            mode: "success",
                            message:
                                res.message || "Remit archive status updated",
                        });
                    },
                    onError: (err) => {
                        toast({
                            mode: "error",
                            message:
                                err.response?.data.message ||
                                "Could not update archive status at this time",
                        });
                    },
                });
            };

            return (
                <>
                    {remitInView && (
                        <Dialog open={Boolean(dialogInView)}>
                            <DialogContent
                                handleCloseDialog={() => setDialogInView(null)}
                                variant="center"
                                title={
                                    remitInView?.is_archived
                                        ? "Unarchive Remit"
                                        : "Archive Remit"
                                }
                                saveText={
                                    remitInView?.is_archived
                                        ? "Unarchive Remit"
                                        : "Archive Remit"
                                }
                                submitBtnFormValue="archive-remit"
                                isSubmitBtnDisabled={archiveRemitIsMutating > 0}
                                isCancelBtnDisabled={archiveRemitIsMutating > 0}
                                onSaveClick={
                                    dialogInView === "unarchive"
                                        ? handleUnArchive
                                        : undefined
                                }
                            >
                                {dialogInView === "unarchive" ? (
                                    <div>
                                        <h3 className="font-medium text-xl mb-8">
                                            Unarchive remit
                                        </h3>
                                        <p className="text-gray font-normal text-base">
                                            Are you sure you want to unarchive
                                            this remit?
                                        </p>
                                    </div>
                                ) : (
                                    <ArchiveRemitForm
                                        onFormSubmit={() =>
                                            setDialogInView(null)
                                        }
                                        remit={remitInView}
                                    />
                                )}
                            </DialogContent>
                        </Dialog>
                    )}
                    <div
                        className={cn(
                            "mb-32 bg-white p-16 rounded-r8 flex flex-col flex-wrap gap-y-16"
                        )}
                    >
                        <div
                            className={cn(
                                "flex justify-between items-center gap-x-12 gap-y-16 flex-wrap w-full max-w-full overflow-x-auto",
                                {
                                    "justify-end":
                                        filteredRemitsList.length === 0,
                                }
                            )}
                        >
                            {filteredRemitsList.length > 0 && (
                                <div>
                                    <Switch
                                        switchOptions={switchOptions}
                                        activeSwitch={activeSwitch}
                                    />
                                </div>
                            )}
                            <div className="flex items-center gap-x-12">
                                {filteredRemitsList.length > 0 && (
                                    <Button
                                        onClick={() =>
                                            setDialogInView(
                                                remitInView?.is_archived
                                                    ? "unarchive"
                                                    : "archive"
                                            )
                                        }
                                    >
                                        {remitInView?.is_archived
                                            ? "Unarchive"
                                            : "Archive"}
                                    </Button>
                                )}
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <FilterButton
                                            text={archivedFilterText()}
                                        />
                                    </DropdownTrigger>
                                    <DropdownContent align="end">
                                        <DropdownItem
                                            className={cn(
                                                "flex gap-x-8 items-center text-xs font-medium",
                                                {
                                                    "text-primary":
                                                        showArchivedRemits ===
                                                        "true",
                                                }
                                            )}
                                            onClick={() =>
                                                handleSetArchivedFilter("true")
                                            }
                                        >
                                            Archived
                                            {showArchivedRemits === "true" ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                        <DropdownItem
                                            className={cn(
                                                "flex gap-x-8 items-center text-xs font-medium",
                                                {
                                                    "text-primary":
                                                        showArchivedRemits ===
                                                        "false",
                                                }
                                            )}
                                            onClick={() =>
                                                handleSetArchivedFilter("false")
                                            }
                                        >
                                            Unarchived
                                            {showArchivedRemits === "false" ? (
                                                <CheckPrimaryColorIcon />
                                            ) : null}
                                        </DropdownItem>
                                    </DropdownContent>
                                </Dropdown>
                            </div>
                        </div>
                        {remitInView?.is_archived &&
                            remitInView.archived_notes && (
                                <div>
                                    <Alert
                                        type="info"
                                        description={remitInView.archived_notes}
                                    />
                                </div>
                            )}
                    </div>

                    {filteredRemitsList.length > 0 &&
                        filteredRemitsList.map((singleRemitData) => {
                            const key = singleRemitData.remittance_id;
                            return (
                                activeSwitch === key && (
                                    <Remit
                                        key={key}
                                        remitData={singleRemitData}
                                    />
                                )
                            );
                        })}
                    {filteredRemitsList.length === 0 && (
                        <ListState
                            isError={false}
                            stateHelperText="No remit matched applied filter"
                            emptyMessage="No remit to display"
                        />
                    )}
                </>
            );
        }
        return (
            <div className="mt-32">
                <Remit remitData={data.data[0]} />
            </div>
        );
    }
}

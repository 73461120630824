import * as React from "react";
import { Button } from "@jhool-io/fe-components";
import MoreIcon from "../../../components/Icons/More";
import { cn } from "../../../utils/helpers";

interface MoreButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    // Extra classnames for styling
    classNames?: string;
}

const MoreButton = React.forwardRef<HTMLButtonElement, MoreButtonProps>(
    ({ classNames, ...props }, ref) => (
        <Button
            size="auto"
            className={cn(
                "w-16 h-16 rounded-[16px] shadow-morebtn relative z-[1] data-[state=open]:border-primary-800",
                classNames
            )}
            variant="normal"
            {...props}
            ref={ref}
            aria-label="more button"
        >
            <MoreIcon />
        </Button>
    )
);

MoreButton.displayName = "MoreButton";
export default MoreButton;

import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import axios from "../utils/axios";
import { handleRemoveEmptyParamFromQueryParams } from "../utils/helpers";
import {
    IChangeStatus,
    IModifyRoles,
    INewUsers,
    IUsersFilterTypes,
} from "../utils/types/user";

// Endpoint to fetch users
export async function fetchUsersList(filters: IUsersFilterTypes) {
    const response = await axios.get(`/users`, {
        params: handleRemoveEmptyParamFromQueryParams(filters),
    });
    return response.data;
}

// Endpoint to delete users
export async function deleteUsers(userIds: string[]) {
    const response = await axios.delete(`/users`, {
        data: { user_ids: userIds },
    });
    return response.data;
}

// Endpoint to add new users
export async function addUsers(data: INewUsers) {
    const response = await axios.post(`/users`, data);
    return response.data;
}

// Endpoint for activating/deactivating users
export async function changeUserStatus(data: IChangeStatus) {
    const response = await axios.patch(`/users/status`, data);
    return response.data;
}

// Endpoint for modifying user roles
export async function modifyUserRoles(data: IModifyRoles) {
    const response = await axios.patch(`/users/role`, data);
    return response.data;
}

export async function resetUserPassword(emails: string[]) {
    const auth = getAuth();

    const promises = emails.map((email) => {
        return sendPasswordResetEmail(auth, email);
    });

    await Promise.all(promises);
}

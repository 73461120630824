export default function InfoBlueIcon() {
    return (
        <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.5 5.5C6.36739 5.5 6.24022 5.55268 6.14645 5.64645C6.05268 5.74021 6 5.86739 6 6V8C6 8.13261 6.05268 8.25979 6.14645 8.35355C6.24022 8.44732 6.36739 8.5 6.5 8.5C6.63261 8.5 6.75979 8.44732 6.85356 8.35355C6.94732 8.25979 7 8.13261 7 8V6C7 5.86739 6.94732 5.74021 6.85356 5.64645C6.75979 5.55268 6.63261 5.5 6.5 5.5ZM6.69 3.54C6.56827 3.48999 6.43173 3.48999 6.31 3.54C6.24863 3.5638 6.19255 3.59948 6.145 3.645C6.10084 3.6936 6.06532 3.74941 6.04 3.81C6.01201 3.86934 5.99831 3.93441 6 4C5.99962 4.0658 6.01223 4.13103 6.03712 4.19195C6.062 4.25287 6.09866 4.30828 6.145 4.355C6.1936 4.39917 6.24941 4.43468 6.31 4.46C6.38575 4.49112 6.46798 4.50316 6.54948 4.49506C6.63097 4.48695 6.70922 4.45896 6.77736 4.41353C6.8455 4.36811 6.90144 4.30664 6.94026 4.23454C6.97908 4.16243 6.99959 4.08189 7 4C6.99816 3.86762 6.94637 3.74082 6.855 3.645C6.80745 3.59948 6.75138 3.5638 6.69 3.54ZM6.5 1C5.5111 1 4.5444 1.29324 3.72215 1.84265C2.89991 2.39206 2.25904 3.17295 1.8806 4.08658C1.50217 5.00021 1.40315 6.00555 1.59608 6.97545C1.789 7.94536 2.26521 8.83627 2.96447 9.53553C3.66373 10.2348 4.55465 10.711 5.52455 10.9039C6.49446 11.0969 7.49979 10.9978 8.41342 10.6194C9.32705 10.241 10.1079 9.6001 10.6573 8.77785C11.2068 7.95561 11.5 6.98891 11.5 6C11.5 5.34339 11.3707 4.69321 11.1194 4.08658C10.8681 3.47995 10.4998 2.92876 10.0355 2.46447C9.57124 2.00017 9.02005 1.63188 8.41342 1.3806C7.80679 1.12933 7.15661 1 6.5 1ZM6.5 10C5.70888 10 4.93552 9.7654 4.27772 9.32588C3.61992 8.88635 3.10723 8.26164 2.80448 7.53073C2.50173 6.79983 2.42252 5.99556 2.57686 5.21964C2.7312 4.44371 3.11216 3.73098 3.67158 3.17157C4.23099 2.61216 4.94372 2.2312 5.71964 2.07686C6.49557 1.92252 7.29983 2.00173 8.03074 2.30448C8.76164 2.60723 9.38635 3.11992 9.82588 3.77772C10.2654 4.43552 10.5 5.20887 10.5 6C10.5 7.06087 10.0786 8.07828 9.32843 8.82843C8.57828 9.57857 7.56087 10 6.5 10Z"
                fill="#005599"
            />
        </svg>
    );
}

import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    IApiResponse,
    IPaginatedApiResponse,
} from "../../../utils/types/api-response";
import { ITask, ITaskListFilters } from "../types/tasks.types";
import { fetchTasks } from "../services/tasks.services";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";

export function useFetchTasks(filters: ITaskListFilters) {
    return useQuery<
        IPaginatedApiResponse<ITask[]>,
        AxiosError<IApiResponse<ITask[]>>
    >({
        queryKey: [`todos`, handleRemoveEmptyParamFromQueryParams(filters)],
        queryFn: () => fetchTasks(filters),
    });
}

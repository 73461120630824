import { SvgProps } from "../../../utils/types";

export default function SelectorIcon({ className }: SvgProps) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M6.69687 6.25026L9.41354 3.53359C9.73437 3.21276 10.2594 3.21276 10.5802 3.53359L13.2969 6.25026"
                stroke="#576A81"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.3031 13.7497L10.5865 16.4664C10.2656 16.7872 9.74063 16.7872 9.41979 16.4664L6.70313 13.7497"
                stroke="#576A81"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

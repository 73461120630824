import { Navigate, useLocation } from "react-router-dom";
import * as FS from "@fullstory/browser";
import { auth } from "../firebase";
import useAuthContext from "../hooks/useAuthContext";

export function RequireAuth({ children }: { children: JSX.Element }) {
    const { user } = useAuthContext();

    const { currentUser } = auth;

    const location = useLocation();

    if (!user) {
        return <Navigate to="/login" replace />;
    }

    localStorage.setItem("from", `${location.pathname}${location.search}`);

    if (currentUser) {
        FS.identify(currentUser.uid, {
            userUid: currentUser.uid,
            email: currentUser.email as string,
            displayName: currentUser.displayName as string,
            reviewsWritten_int: 14,
        });
    }

    return children;
}

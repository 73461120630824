import Navbar from "../../../components/Navbar/Navbar";
import ProviderSessionActivity from "../components/ProviderSessionActivity/ProviderSessionActivity";

export default function ProviderSessionActivityPage() {
    return (
        <>
            <Navbar title="Provider Session Activity" />
            <div className="page">
                <ProviderSessionActivity />
            </div>
        </>
    );
}

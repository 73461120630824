import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    addClientFlag,
    addClientInsuranceDetails,
    addClientMemo,
    addPaymentCard,
    addPaymentCardLabel,
    deleteClientDocument,
    deleteClientFlags,
    deleteInsuranceAttachment,
    editClientCurrentFee,
    editClientInsuranceDetails,
    updateClientFlag,
    validatePaymentCard,
} from "../services/clients.services";
import {
    IAddClientFlag,
    IClientCreditCardDetails,
    IClientDocument,
    IClientEditInsuranceDetails,
    IClientFee,
    IClientInsuranceAttachment,
    IClientInsuranceDetails,
    IClientMemo,
    IClientNewInsuranceDetails,
    IEditClientFee,
    INewCreditCardDetails,
    INewMemo,
} from "../types/clients.types";
import { IApiResponse } from "../../../utils/types/api-response";

// Hook for deleting client document
export function useDeleteClientDocument(clientId: string, documentId: string) {
    interface IApiResponse {
        message: string;
        documents: IClientDocument[];
    }
    return useMutation<IApiResponse, AxiosError<IApiResponse>, null>({
        mutationKey: ["delete-document", clientId],
        mutationFn: () => deleteClientDocument(clientId, documentId),
    });
}

export function useAddPaymentCard(clientId: string) {
    return useMutation<
        IApiResponse<IClientCreditCardDetails>,
        AxiosError<IApiResponse<IClientCreditCardDetails>>,
        INewCreditCardDetails
    >({
        mutationKey: ["add-card", clientId],
        mutationFn: (data: INewCreditCardDetails) =>
            addPaymentCard(clientId, data),
    });
}

export function useAddClientInsurance(clientId: string) {
    return useMutation<
        IApiResponse<IClientInsuranceDetails>,
        AxiosError<IApiResponse<IClientInsuranceDetails>>,
        IClientNewInsuranceDetails
    >({
        mutationKey: ["add-insurance", clientId],
        mutationFn: (data: IClientNewInsuranceDetails) =>
            addClientInsuranceDetails(clientId, data),
    });
}

// Hook to add card labels
export function useAddCardLabel(clientId: string, paymentCardId: string) {
    interface ApiResponseData {
        message: string;
    }
    interface Payload {
        card_label: string;
    }
    return useMutation<
        IApiResponse<ApiResponseData>,
        AxiosError<IApiResponse<ApiResponseData>>,
        Payload
    >({
        mutationKey: ["add-card-label", paymentCardId],
        mutationFn: (payload: Payload) =>
            addPaymentCardLabel(clientId, paymentCardId, payload),
    });
}

// Hook to validate credit card
export function useValidateCard(clientId: string, paymentCardId: string) {
    interface ApiResponseData {
        message: string;
        data: Record<string, string>;
    }
    return useMutation<ApiResponseData, AxiosError<ApiResponseData>, null>({
        mutationFn: () => validatePaymentCard(clientId, paymentCardId),
        mutationKey: ["validate-card", paymentCardId],
    });
}

// Hook for editing client insurance details
export function useEditClientInsuranceDetails(
    clientId: string,
    insuranceId: string
) {
    return useMutation<
        IApiResponse<IClientInsuranceDetails>,
        AxiosError<IApiResponse<IClientInsuranceDetails>>,
        IClientEditInsuranceDetails
    >({
        mutationKey: ["edit-insurance", clientId],
        mutationFn: (data: IClientEditInsuranceDetails) =>
            editClientInsuranceDetails(clientId, insuranceId, data),
    });
}

// Hook for deleting client insurance attachment
export function useDeleteClientInsuranceAttachment(
    clientId: string,
    documentId: string
) {
    interface IApiResponse {
        message: string;
        attachments: IClientInsuranceAttachment[];
    }
    return useMutation<IApiResponse, AxiosError<IApiResponse>, null>({
        mutationKey: ["delete-document", clientId],
        mutationFn: () => deleteInsuranceAttachment(clientId, documentId),
    });
}

// Hook for adding new client memo
export function useAddClientMemo(clientId: string) {
    return useMutation<
        IApiResponse<IClientMemo>,
        AxiosError<IApiResponse<IClientMemo>>,
        INewMemo
    >({
        mutationKey: ["add-memo", clientId],
        mutationFn: (data: INewMemo) => addClientMemo(clientId, data),
    });
}

export function useEditClientCurrentFee(clientId: string, feeId: string) {
    return useMutation<
        IApiResponse<IClientFee>,
        AxiosError<IApiResponse<IClientFee>>,
        IEditClientFee
    >({
        mutationKey: ["edit-fee"],
        mutationFn: (data: IEditClientFee) =>
            editClientCurrentFee(clientId, feeId, data),
    });
}

// Hook for adding client flag
export function useAddClientFlag(clientId: string) {
    return useMutation<
        IApiResponse<{ message: string }>,
        AxiosError<IApiResponse<{ message: string }>>,
        IAddClientFlag
    >({
        mutationKey: ["add-client-flag", clientId],
        mutationFn: (payload: IAddClientFlag) =>
            addClientFlag(clientId, payload),
    });
}

// Hook to delete client flag
export function useDeleteClientFlag(clientId: string, flagId: string) {
    interface IEditFlagResponse {
        client_flag_id: string;
        client_id: string;
        content: string;
        author: string;
    }
    return useMutation<
        IApiResponse<IEditFlagResponse>,
        AxiosError<IApiResponse<IEditFlagResponse>>,
        null
    >({
        mutationKey: ["delete-client-flag"],
        mutationFn: () => deleteClientFlags(clientId, flagId),
    });
}

// Hook to update client flag
export function useUpdateClientFlag(clientId: string, flagId: string) {
    return useMutation<
        IApiResponse<{ message: string }>,
        AxiosError<IApiResponse<{ message: string }>>,
        IAddClientFlag
    >({
        mutationKey: ["update-client-flag", flagId],
        mutationFn: (payload: IAddClientFlag) =>
            updateClientFlag(clientId, flagId, payload),
    });
}

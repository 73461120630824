/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import {
    Button,
    Card,
    DatePicker,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Select,
    SelectElement,
    Table,
} from "@jhool-io/fe-components";
import { ColumnDef, Row } from "@tanstack/react-table";
import { useNavigate, useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import {
    cn,
    convertDateFilterStringToDate,
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    showMoneyInAppFormat,
} from "../../../../utils/helpers";
import { useFetchInsuranceProviders } from "../../../clients/hooks/clients.queries";
import { IAppCustomDates } from "../../../../utils/types";
import CalendarDaysIcon from "../../../../components/Icons/SideNav/CalendarDays";
import ListState from "../../../../components/ListState/ListState";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import { useFetchAgingReport } from "../../hooks/queries/reports.queries";
import { DayInterval, IAgingReportResponse } from "../../types/reports.types";
import useToast from "../../../../hooks/useToast";
import { useExportBillingSummaryReport } from "../../../billing/hooks/queries/billing.queries";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import { useFetchClientList } from "../../../../hooks/queries/client";
import { useDebounce } from "../../../../hooks/helpers";
import { useFetchProviders } from "../../../providers/hooks/providers.queries";
import usePractice from "../../../../hooks/usePractice";
import { LISTS_DEFAULT_LIMIT } from "../../../../utils/constants";
import TableFiltersDisplay from "../../../../shared-ui/TableFiltersDisplay/TableFiltersDisplay";
import {
    BillStatus,
    BillStatusLabels,
} from "../../../billing/types/billing.types";

type Options = {
    label: React.ReactNode;
    value: string;
};

export default function InsuranceAgingReport() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isExporting, setIsExporting] = React.useState(false);
    const [clientSearchValue, setClientSearchValue] = React.useState("");
    const [providerSearchValue, setProviderSearchValue] = React.useState("");
    const [showClientOptions, setShowClientOptions] = React.useState(false);
    const [showProviderOptions, setShowProviderOptions] = React.useState(false);
    const [showDefaultClients, setShowDefaultClients] = React.useState(false);
    const [showDefaultProviders, setShowDefaultProviders] =
        React.useState(false);

    const insuranceProvider = searchParams.get("insurance_provider") || "";
    const providersFilter = searchParams.getAll("providers[]");
    const clientsFilter = searchParams.getAll("clients[]");

    const customDateFilter =
        (searchParams.get("custom_date") as IAppCustomDates) || undefined;
    const dayIntervalFilter =
        (searchParams.get("day_interval") as DayInterval) || undefined;

    const fromDateFilter = searchParams.get("from_date");
    const toDateFilter = searchParams.get("to_date");
    const pageFilter = Number(searchParams.get("page") || 1);
    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;

    const fromDateFilterToDate = convertDateFilterStringToDate(fromDateFilter);
    const toDateFilterToDate = convertDateFilterStringToDate(toDateFilter);

    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: "",
        direction: "ASC",
    });

    const getInvoiceStatus = () => {
        const invoiceStatusFilter = searchParams.getAll("invoice_status[]");

        if (invoiceStatusFilter.length > 0) {
            if (invoiceStatusFilter.includes("all")) {
                return [];
            }
            return invoiceStatusFilter;
        }

        return [];
    };

    const getIntervals = () => {
        const intervalsFilter = searchParams.getAll("day_intervals[]");

        if (intervalsFilter.length > 0) {
            if (intervalsFilter.includes("all")) {
                return [];
            }
            return intervalsFilter;
        }

        return [];
    };

    const clientSelectRef = React.useRef<SelectElement | null>(null);
    const providerSelectRef = React.useRef<SelectElement | null>(null);

    const sortableColumns = [
        "date_of_service",
        "client_name",
        "collected",
        "dob",
        "cpt_code",
    ];

    const handleConfigAttribute = () => {
        if (sortableColumns.includes(sortConfig.key)) {
            return sortConfig.key as string;
        }

        return "client_name";
    };

    const shouldFetchReport =
        clientsFilter.length > 0 ||
        providersFilter.length > 0 ||
        Boolean(insuranceProvider) ||
        Boolean(customDateFilter) ||
        Boolean(dayIntervalFilter) ||
        Boolean(fromDateFilter) ||
        Boolean(toDateFilter) ||
        getInvoiceStatus().length > 0 ||
        getIntervals().length > 0;

    const { data, error, isLoading, isSuccess } = useFetchAgingReport(
        {
            type: "insurance",
            show_report_data: true,
            insurance_provider_id: insuranceProvider,
            client_names: clientsFilter,
            provider_names: providersFilter,
            custom_date: customDateFilter,
            from_date: fromDateFilter,
            to_date: toDateFilter,
            sort_attribute:
                searchParams.get("sort_attr") || handleConfigAttribute(),
            sort_order: searchParams.get("sort_order") || sortConfig.direction,
            page: pageFilter,
            limit: limitFilter,
            aging_day_intervals: getIntervals(),
            view: "amount_outstanding",
            invoice_status: getInvoiceStatus(),
        },
        shouldFetchReport
    );

    const { practice } = usePractice();

    const debouncedClientSearch = useDebounce(clientSearchValue, 500);

    const debouncedProviderSearch = useDebounce(providerSearchValue, 500);

    const toast = useToast();
    const navigate = useNavigate();

    const exportQuery = useExportBillingSummaryReport(
        {
            view: "amount_outstanding",
            type: "insurance",
            from_date: fromDateFilter,
            to_date: toDateFilter,
            search_strings: clientsFilter,
            provider_names: providersFilter,
            custom_date: customDateFilter,
            aging_day_intervals: getIntervals(),
            invoice_status: getInvoiceStatus(),
            sort_attribute:
                searchParams.get("sort_attr") || handleConfigAttribute(),
            sort_order: searchParams.get("sort_order") || sortConfig.direction,
            insurance_provider_id: insuranceProvider,
        },
        isExporting
    );

    // Hook for fetching insurance providers
    const insuranceProviders = useFetchInsuranceProviders();

    const { data: clients, isLoading: fetchingClients } = useFetchClientList(
        {
            search_string: debouncedClientSearch.toLowerCase(),
            provider_id: practice?.provider_id || "",
            sort_attribute: "client_name",
            sort_order: "ASC",
        },
        (showClientOptions && clientSearchValue.length > 0) ||
            clientsFilter.length > 0
    );

    const { data: providers } = useFetchProviders(
        {
            search_string: debouncedProviderSearch.toLowerCase(),
            sort_attribute: "provider_name",
            sort_order: "ASC",
        },
        (showProviderOptions && providerSearchValue.length > 0) ||
            providersFilter.length > 0
    );

    const insuranceOptions =
        insuranceProviders.data?.data.map((provider) => ({
            label: removeEnumUnderscore(provider.name).toUpperCase(),
            value: provider.insurance_provider_id,
        })) || [];

    const providersOptionsforSelect = [
        {
            label: "Self-pay",
            value: "self_pay",
        },
        ...insuranceOptions,
    ]?.sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();

        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }

        return 0;
    });

    const ClientNamesForSelect = clients?.data.map((client) => ({
        label: `${client.first_name} ${client.last_name}`,
        value: `${client.first_name} ${client.last_name}`,
    }));

    // Get filtered options for clients list
    const filteredClients = ClientNamesForSelect?.filter((option) =>
        option.label.toLowerCase().includes(clientSearchValue.toLowerCase())
    );

    const ProviderNamesForSelect = providers?.data.map((provider) => ({
        label: provider.provider_name,
        value: provider.provider_name,
    }));

    // Get filtered options for providers list
    const filteredProviders = ProviderNamesForSelect?.filter((option) =>
        option.label.toLowerCase().includes(providerSearchValue.toLowerCase())
    );

    const customDateForSelect = [
        ...Object.values(IAppCustomDates).map((item) => {
            return item;
        }),
    ];

    const dayIntervalForSelect = [
        ...Object.values(DayInterval).map((item) => {
            return item;
        }),
    ];

    const handleClientsChange = (values: unknown) => {
        // Clear array values so we don't have duplicates
        // or values that have been removed from the array

        searchParams.delete("clients[]");
        if ((values as Options[]).length === 0) {
            searchParams.delete("clients[]");
        } else {
            (values as Options[]).forEach((option) => {
                searchParams.append("clients[]", option.value);
            });
        }

        setSearchParams(searchParams);
    };

    const handleProviderChange = (values: unknown) => {
        // Clear array values so we don't have duplicates
        // or values that have been removed from the array

        searchParams.delete("providers[]");
        if ((values as Options[]).length === 0) {
            searchParams.delete("providers[]");
        } else {
            (values as Options[]).forEach((option) => {
                searchParams.append("providers[]", option.value);
            });
        }

        setSearchParams(searchParams);
    };

    // onChange handler for page
    const handlePageChange = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const handleLimitChange = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    const handleCustomDateChange = (value: IAppCustomDates) => {
        searchParams.set("custom_date", value);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleDayIntervalChange = (interval: DayInterval) => {
        if (getIntervals().includes(interval)) {
            searchParams.delete("day_intervals[]", interval);
        } else {
            searchParams.append("day_intervals[]", interval);
        }
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleStatusChange = (status: string) => {
        if (getInvoiceStatus().includes(status)) {
            searchParams.delete("invoice_status[]", status);
        } else {
            searchParams.append("invoice_status[]", status);
        }
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleInsuranceProviderChange = (value: unknown) => {
        // Clear value if no value was selected
        if ((value as Options).value === "")
            searchParams.delete("insurance_provider");
        else searchParams.set("insurance_provider", (value as Options).value);

        searchParams.set(
            "insurance_provider",
            (value as Options).value as IAppCustomDates
        );

        setSearchParams(searchParams);
    };

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));

        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));

        setSearchParams(searchParams);
    };

    const handleReset = () => {
        clientSelectRef.current?.clearValue();
        providerSelectRef.current?.clearValue();
        setSearchParams(() => "?report=aging_report&tab=insurance");
    };

    const handleExportClick = async () => {
        setIsExporting(true);
        await exportQuery.refetch();
        setIsExporting(false);
        if (exportQuery.error) {
            toast.toast({
                mode: "error",
                message: "Could not export report.",
            });
        }
    };

    const handleRowClick = (row: Row<IAgingReportResponse>) => {
        navigate(
            `/billing-and-claims/${row.original.client.client_id}/${row.original.note_id}`
        );
    };

    const columns: ColumnDef<IAgingReportResponse>[] = [
        {
            accessorKey: "date_of_service",
            header: "DATE OF SERVICE",
            cell: ({ row }) =>
                row.original.date_of_service
                    ? formatDate(row.original.date_of_service)
                    : "--",
        },
        {
            accessorKey: "client_name",
            header: "CLIENT",
            cell: ({ row }) =>
                `${row.original.client.first_name} ${row.original.client.last_name}`,
        },
        {
            id: "dob",
            accessorKey: "date_of_birth",
            header: "DOB",
            cell: ({ row }) =>
                formatDate(row.original.client.date_of_birth, true),
        },
        {
            id: "charge_amount",
            accessorKey: "charge_amount",
            header: "CLAIM CHARGE",
            cell: ({ row }) => showMoneyInAppFormat(row.original.charge_amount),
        },
        {
            accessorKey: "collected",
            header: "COLLECTED",
            cell: ({ row }) => showMoneyInAppFormat(row.original.collected),
        },
        {
            accessorKey: "cpt_code",
            header: "CPT CODE",
            cell: ({ row }) => row.original.cpt_code || "--",
        },
    ];

    React.useEffect(() => {
        if (exportQuery.isSuccess) {
            setIsExporting(false);
        }
    }, [exportQuery.isLoading, exportQuery.isSuccess]);

    return (
        <>
            <Card classNames="border border-strokedark p-0 mb-32">
                <div className="flex border-b border-b-strokedark p-24 justify-between items-center">
                    <p className="font-medium text-base">Set the details</p>

                    <Button
                        className="h-32 ml-12"
                        variant="secondary"
                        mode="filled"
                        type="button"
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                </div>
                <div className="p-24">
                    <form id="aging-insurance-preview">
                        <div className="fg">
                            <Select
                                ref={clientSelectRef}
                                isSearchable
                                filterOption={() => true}
                                isMulti
                                placeholder="Client name(Type multiple)"
                                value={
                                    showDefaultClients
                                        ? ClientNamesForSelect?.filter(
                                              (client) =>
                                                  searchParams
                                                      .getAll("clients[]")
                                                      .includes(client.value)
                                          ) || []
                                        : undefined
                                }
                                aria-label="Client name"
                                label="Client name"
                                options={filteredClients}
                                onChange={handleClientsChange}
                                onInputChange={(value) => {
                                    setShowClientOptions(true);
                                    setClientSearchValue(value);
                                    setShowDefaultClients(false);
                                }}
                                onBlur={() => {
                                    setShowClientOptions(false);
                                }}
                                defaultValue={
                                    !showDefaultClients
                                        ? ClientNamesForSelect?.filter(
                                              (client) =>
                                                  searchParams
                                                      .getAll("clients[]")
                                                      .includes(client.value)
                                          ) || []
                                        : undefined
                                }
                                openMenuOnClick={false}
                                menuIsOpen={clientSearchValue.length > 0}
                                noOptionsMessage={({ inputValue }) =>
                                    fetchingClients && inputValue
                                        ? "Fetching clients..."
                                        : "No options"
                                }
                            />
                        </div>

                        <div className="fg">
                            <Select
                                ref={providerSelectRef}
                                isSearchable
                                filterOption={() => true}
                                isMulti
                                placeholder="Provider name(Type multiple)"
                                value={
                                    showDefaultProviders
                                        ? ProviderNamesForSelect?.filter(
                                              (provider) =>
                                                  searchParams
                                                      .getAll("providers[]")
                                                      .includes(provider.value)
                                          ) || []
                                        : undefined
                                }
                                aria-label="Provider name"
                                label="Provider name"
                                options={filteredProviders}
                                onChange={handleProviderChange}
                                onInputChange={(value) => {
                                    setShowProviderOptions(true);
                                    setProviderSearchValue(value);
                                    setShowDefaultProviders(false);
                                }}
                                onBlur={() => {
                                    setShowProviderOptions(false);
                                }}
                                defaultValue={
                                    !showDefaultProviders
                                        ? ProviderNamesForSelect?.filter(
                                              (provider) =>
                                                  searchParams
                                                      .getAll("providers[]")
                                                      .includes(provider.value)
                                          ) || []
                                        : undefined
                                }
                                openMenuOnClick={false}
                                menuIsOpen={providerSearchValue.length > 0}
                            />
                        </div>

                        <div className="fg">
                            <Select
                                placeholder="Select insurance provider"
                                aria-label="insurance"
                                label="Insurance provider"
                                options={providersOptionsforSelect || []}
                                value={
                                    providersOptionsforSelect?.find(
                                        (provider) => {
                                            if (insuranceProvider)
                                                return (
                                                    provider.value ===
                                                    insuranceProvider
                                                );
                                            return undefined;
                                        }
                                    ) || null
                                }
                                hideSelectedOptions
                                onChange={handleInsuranceProviderChange}
                                isSearchable
                            />
                        </div>

                        <div className="fg fg-space-between two flex">
                            <div className="-mt-[6px]">
                                <p className="text-xs font-medium pb-[2px]">
                                    Date range
                                </p>
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <div className="flex justify-between h-[41px] border-[1.5px] border-[#E7E7E7] rounded-r8 items-center">
                                            <Button
                                                className="w-full text-xs justify-between items-center font-normal data-[state=open]:border-primary px-12 "
                                                variant="normal"
                                                mode="outline"
                                            >
                                                {customDateFilter ? (
                                                    makeStringFirstLetterCapital(
                                                        removeEnumUnderscore(
                                                            customDateFilter
                                                        )
                                                    )
                                                ) : (
                                                    <span className="text-gray">
                                                        Select date range
                                                    </span>
                                                )}
                                                <ChevronDownIcon stroke="#576a81" />
                                            </Button>
                                        </div>
                                    </DropdownTrigger>
                                    <DropdownContent
                                        align="center"
                                        className="h-auto w-96 px-16 pt-6 z-20 overflow-y-auto"
                                    >
                                        {customDateForSelect.map((range) => (
                                            <DropdownItem
                                                className={cn(
                                                    "flex gap-x-8 justify-between items-center capitalize text-xs text-gray cursor-pointer font-medium",
                                                    {
                                                        "text-primary":
                                                            customDateFilter ===
                                                            range,
                                                    }
                                                )}
                                                key={range}
                                                onClick={() =>
                                                    handleCustomDateChange(
                                                        range
                                                    )
                                                }
                                            >
                                                {removeEnumUnderscore(range)}{" "}
                                                {customDateFilter === range ? (
                                                    <CheckPrimaryColorIcon />
                                                ) : null}
                                            </DropdownItem>
                                        ))}
                                    </DropdownContent>
                                </Dropdown>
                            </div>

                            <div className="-mt-[6px]">
                                <p className="text-xs font-medium pb-[2px]">
                                    Date
                                </p>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <div className="flex justify-between h-[41px] border-[1.5px] border-[#E7E7E7] rounded-r8 items-center">
                                            <Button
                                                className="w-full text-xs justify-start font-normal text-gray data-[state=open]:border-primary "
                                                variant="normal"
                                                mode="outline"
                                                aria-label="request-changes"
                                                type="button"
                                            >
                                                {fromDateFilterToDate &&
                                                toDateFilterToDate
                                                    ? `${format(
                                                          fromDateFilterToDate,
                                                          "MM/dd/yyyy"
                                                      )} - ${format(
                                                          toDateFilterToDate,
                                                          "MM/dd/yyyy"
                                                      )}`
                                                    : "Select date"}
                                            </Button>
                                            <div className="pr-8">
                                                <CalendarDaysIcon />
                                            </div>
                                        </div>
                                    </PopoverTrigger>
                                    <PopoverContent
                                        align="start"
                                        className="p-16 gap-y-12"
                                    >
                                        <DatePicker
                                            label="From"
                                            placeholderText="MM/DD/YYYY"
                                            selected={fromDateFilterToDate}
                                            onChange={
                                                handleFromDateFilterChange
                                            }
                                            maxDate={
                                                toDateFilterToDate ||
                                                new Date(Date.now())
                                            }
                                            isClearable
                                        />
                                        <DatePicker
                                            label="To"
                                            placeholderText="MM/DD/YYYY"
                                            selected={toDateFilterToDate}
                                            onChange={handleToDateFilterChange}
                                            minDate={fromDateFilterToDate}
                                            isClearable
                                        />
                                    </PopoverContent>
                                </Popover>
                            </div>
                        </div>

                        <div className="fg fg-space-between two flex">
                            <div className="-mt-[6px]">
                                <p className="text-xs font-medium pb-[2px]">
                                    Day intervals
                                </p>
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <div className="flex justify-between h-[41px] border-[1.5px] border-[#E7E7E7] rounded-r8 items-center">
                                            <Button
                                                className="w-full text-xs justify-between items-center font-normal data-[state=open]:border-primary px-12 "
                                                variant="normal"
                                                mode="outline"
                                            >
                                                <span className="text-gray">
                                                    Day intervals
                                                </span>

                                                <ChevronDownIcon stroke="#576a81" />
                                            </Button>
                                        </div>
                                    </DropdownTrigger>
                                    <DropdownContent>
                                        {dayIntervalForSelect.map(
                                            (interval) => (
                                                <DropdownItem
                                                    key={interval}
                                                    isMulti
                                                    itemValue={interval}
                                                    values={getIntervals()}
                                                    onClick={() =>
                                                        handleDayIntervalChange(
                                                            interval
                                                        )
                                                    }
                                                >
                                                    {removeEnumUnderscore(
                                                        interval
                                                    )}
                                                </DropdownItem>
                                            )
                                        )}
                                    </DropdownContent>
                                </Dropdown>
                            </div>
                            <div className="-mt-[6px]">
                                <p className="text-xs font-medium pb-[2px]">
                                    Bill status
                                </p>
                                <Dropdown>
                                    <DropdownTrigger asChild>
                                        <div className="flex justify-between h-[41px] border-[1.5px] border-[#E7E7E7] rounded-r8 items-center">
                                            <Button
                                                className="w-full text-xs justify-between items-center font-normal data-[state=open]:border-primary px-12 "
                                                variant="normal"
                                                mode="outline"
                                            >
                                                <span className="text-gray">
                                                    Bill status
                                                </span>

                                                <ChevronDownIcon stroke="#576a81" />
                                            </Button>
                                        </div>
                                    </DropdownTrigger>
                                    <DropdownContent>
                                        {Object.values(BillStatus).map(
                                            (status) => (
                                                <DropdownItem
                                                    key={status}
                                                    onClick={() =>
                                                        handleStatusChange(
                                                            status
                                                        )
                                                    }
                                                    isMulti
                                                    itemValue={status}
                                                    values={getInvoiceStatus()}
                                                >
                                                    {makeStringFirstLetterCapital(
                                                        removeEnumUnderscore(
                                                            BillStatusLabels[
                                                                status
                                                            ]
                                                        )
                                                    )}
                                                </DropdownItem>
                                            )
                                        )}
                                    </DropdownContent>
                                </Dropdown>
                            </div>
                        </div>
                    </form>
                    <TableFiltersDisplay
                        appliedFilters={[
                            {
                                key: "invoice_status[]",
                                values: getInvoiceStatus(),
                            },
                            {
                                key: "day_intervals[]",
                                values: getIntervals(),
                            },
                        ]}
                        wrapperClass="mt-12"
                    />
                </div>
            </Card>

            {isLoading && shouldFetchReport && (
                <Skeleton
                    containerTestId="aging-insurance-loader"
                    count={5}
                    type="table"
                />
            )}
            {error && shouldFetchReport && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error.response?.data.message ||
                        "Cannot display breakdown at this time, please try again later"
                    }
                />
            )}

            {data && isSuccess && data.data.length === 0 && shouldFetchReport && (
                <div>
                    <ListState
                        stateHelperText="Billing breakdown list will be displayed here when available"
                        isError={false}
                        emptyMessage="No billing breakdown to display"
                    />
                </div>
            )}

            {data &&
                data?.data &&
                isSuccess &&
                data.data.length > 0 &&
                shouldFetchReport && (
                    <div className="flex flex-col gap-y-12">
                        <div>
                            <Button
                                variant="primary"
                                type="button"
                                onClick={handleExportClick}
                                disabled={isExporting}
                            >
                                Export
                            </Button>
                        </div>

                        <Table
                            data={data.data}
                            columns={columns}
                            hasPagination={data.total_count > 20}
                            setSortConfig={setSortConfig}
                            sortConfig={sortConfig}
                            sortableColumns={sortableColumns}
                            handleRowClick={handleRowClick}
                            pagination={
                                <Pagination
                                    totalCount={data.total_count}
                                    totalPages={data.total_pages}
                                    currentPage={pageFilter}
                                    onPageChange={handlePageChange}
                                    limit={limitFilter}
                                    onLimitChange={handleLimitChange}
                                />
                            }
                        />
                    </div>
                )}
        </>
    );
}

import { SvgProps } from "../../utils/types";

export default function AddIcon({ fill, className }: SvgProps) {
    return (
        <svg
            width="26px"
            height="30"
            viewBox="0 0 26 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                opacity="0.4"
                d="M10.2144 26.25C10.2144 26.7473 10.41 27.2242 10.7583 27.5758C11.1066 27.9275 11.579 28.125 12.0715 28.125H13.9286C14.4212 28.125 14.8936 27.9275 15.2418 27.5758C15.5901 27.2242 15.7858 26.7473 15.7858 26.25V17.8125H10.2144V26.25ZM13.9286 1.875H12.0715C11.579 1.875 11.1066 2.07254 10.7583 2.42417C10.41 2.77581 10.2144 3.25272 10.2144 3.75V12.1875H15.7858V3.75C15.7858 3.25272 15.5901 2.77581 15.2418 2.42417C14.8936 2.07254 14.4212 1.875 13.9286 1.875Z"
                fill={fill || "#0B7A75"}
            />
            <path
                d="M26 14.0625V15.9375C26 16.4348 25.8043 16.9117 25.4561 17.2633C25.1078 17.615 24.6354 17.8125 24.1429 17.8125H1.85714C1.3646 17.8125 0.892226 17.615 0.543945 17.2633C0.195663 16.9117 0 16.4348 0 15.9375V14.0625C0 13.5652 0.195663 13.0883 0.543945 12.7367C0.892226 12.385 1.3646 12.1875 1.85714 12.1875H24.1429C24.6354 12.1875 25.1078 12.385 25.4561 12.7367C25.8043 13.0883 26 13.5652 26 14.0625Z"
                fill={fill || "#0B7A75"}
            />
        </svg>
    );
}

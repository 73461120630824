import { UserPermisions } from "../../../utils/types/user";

export enum InternalUserStatus {
    ACTIVE = "active",
    DEACTIVATED = "deactivated",
}

export enum UserRole {
    SUPER_ADMIN = "superadmin",
    PROVIDER = "provider",
    BILLER = "biller",
    CODER = "coder",
    AUDITOR = "auditor",
    BILLER_ADMIN = "billeradmin",
    CLIENT_SUPPORT = "clientsupport",
    CLIENT = "client",
    USER_SUPPORT = "usersupport",
}

export interface UserPractice {
    practice_id: string;
    is_supervisor: boolean;
    practice_name: string;
    provider_id: string | null;
    roles: UserRole[];
    permissions: UserPermisions[];
}

export interface IInternalUser {
    user_id: string;
    first_name: string;
    last_name: string;
    roles: string[];
    email: string;
    status: string;
}

export interface IInternalUsersListFilterTypes {
    page: number;
    search_string: string;
    role?: string;
    status?: string;
    limit: number;
    sort_attribute?: string;
    sort_order?: string;
}

export interface INewUserPayload {
    email: string;
    first_name: string;
    last_name: string;
    roles: string[];
}

export interface INewUsers {
    users: INewUserPayload[];
}

export interface IChangeStatusPayload {
    user_ids: string[];
    status: InternalUserStatus;
}

export interface IModifyRolesPayload {
    user_ids: string[];
    roles: string[];
}

export default function NotesIcon() {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.4388 7.45642L13.7855 10.2431C13.2255 12.6498 12.1188 13.6231 10.0388 13.4231C9.70548 13.3964 9.34548 13.3364 8.95882 13.2431L7.83882 12.9764C5.05882 12.3164 4.19882 10.9431 4.85215 8.15643L5.50548 5.36309C5.63882 4.79642 5.79882 4.30309 5.99882 3.89642C6.77882 2.28309 8.10548 1.84976 10.3322 2.37642L11.4455 2.63642C14.2388 3.28976 15.0922 4.66976 14.4388 7.45642Z"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.0402 13.4251C9.62689 13.7051 9.10689 13.9384 8.47356 14.1451L7.42023 14.4918C4.77356 15.3451 3.38023 14.6318 2.52023 11.9851L1.66689 9.35177C0.813561 6.7051 1.52023 5.3051 4.16689 4.45177L5.22023 4.1051C5.49356 4.01844 5.75356 3.9451 6.00023 3.89844C5.80023 4.3051 5.64023 4.79844 5.50689 5.3651L4.85356 8.15844C4.20023 10.9451 5.06023 12.3184 7.84023 12.9784L8.96023 13.2451C9.34689 13.3384 9.70689 13.3984 10.0402 13.4251Z"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.42578 6.1875L11.6591 7.0075"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.77344 8.76562L9.70677 9.25896"
                stroke="#576A81"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

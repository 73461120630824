import Navbar from "../../../components/Navbar/Navbar";
import ViewRemit from "../components/ViewRemit/ViewRemit";

export default function ViewSingleRemit() {
    return (
        <>
            <Navbar title="Remit Info" />
            <div className="page">
                <ViewRemit />
            </div>
        </>
    );
}

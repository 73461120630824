import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Select } from "@jhool-io/fe-components";
import {
    IChangeCodeResponse,
    INote,
    ModifierTypes,
} from "../../../../utils/types/notes";
import Card from "../../../Card/Card";
import styles from "./ChangeCode.module.scss";
import { CPT_CODES, CPT_CODES_DESCRIPTION } from "../../../../utils/constants";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import useToast from "../../../../hooks/useToast";
import { IPaginatedApiResponse } from "../../../../utils/types/api-response";
import {
    AppointmentLocation,
    SessionPlaceOfServiceCode,
} from "../../../../utils/types/session";
import { removeEnumUnderscore } from "../../../../utils/helpers";
import { useChangeCptCode } from "../../../../hooks/mutations/note";

interface ChangeCodeProps {
    /**
     * Array of notes to pass the the change code mutate function
     */
    notes?: INote[];
    /**
     * Note to edit id, used when updating cpt code from notes list
     */
    noteId?: string;
    /**
     * Note to edit cpt code id, used when updating cpt code from notes list or billing card
     */
    cptCode?: string;
    // Determines whether the note details is visible or not
    showDetails?: boolean;
    // Function to call when form submit button is clicked
    onFormSubmit(): void;
}

interface ICreateChangeCodePayload {
    cpt_code: string;
    modifier_codes?: string[];
    pos_code?: string;
}

type Options = {
    label: string;
    value: string;
};

const modifiersTypesForSelect: Options[] = [
    ...Object.values(ModifierTypes).map((item) => ({
        value: item,
        label: item,
    })),
];

const CPTOptions = CPT_CODES.sort((a, b) => Number(a) - Number(b)).map(
    (code) => ({
        label: `${code} - ${CPT_CODES_DESCRIPTION[code]}`,
        value: code,
    })
);

const PlaceOfServiceOptions = [
    ...Object.values(SessionPlaceOfServiceCode).map((place) => ({
        value: place,
        label: place,
    })),
];

// For form validation purposes
const schema = yup.object({
    cpt_code: yup.string().required("Cpt code is required"),
});

export default function ChangeCode({
    showDetails = true,
    notes,
    noteId,
    cptCode,
    onFormSubmit,
}: ChangeCodeProps) {
    // Toast for success and error states
    const { toast } = useToast();
    // Change cpt code mutation hook
    const changeCptCode = useChangeCptCode();

    // Query client
    const queryClient = useQueryClient();

    // Get note id  and client id from url params
    const params = useParams();
    const noteToEditId = noteId || (params.noteId as string);
    const clientId = params.clientId as string;

    // Get note details from cache
    const { data } = useFetchSessionNote(
        clientId,
        noteToEditId,
        Boolean(clientId) && Boolean(noteId)
    );

    const noteToEditCptCode = cptCode || data?.data.cpt_code;

    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<ICreateChangeCodePayload>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            modifier_codes: data?.data.modifier_codes || [],
        },
    });

    const modifierCodesFromForm = watch("modifier_codes") || [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getAppointmentLocationToShow = (content: { [key: string]: any }) => {
        if (!content.appointment_location) return "--";

        if (AppointmentLocation.TELETHERAPY === content.appointment_location) {
            if (
                content.delivery_option &&
                content.delivery_option === "Other"
            ) {
                return content.other_delivery_option;
            }
            if (
                content.delivery_option &&
                content.delivery_option !== "Other"
            ) {
                return content.delivery_option;
            }
        }
        return removeEnumUnderscore(content.appointment_location);
    };

    const canEditPlaceOfService = !notes && data?.data.note_content;

    // Handle form submit
    const onSubmit = (payload: ICreateChangeCodePayload) => {
        interface Payload {
            [noteId: string]: {
                cpt_code: string;
                modifier_codes?: string[];
                pos_code?: string;
                confirm_code: boolean;
            };
        }

        const modifierCode =
            payload?.modifier_codes &&
            payload?.modifier_codes?.map((item) => item.replace(/[^0-9]/g, ""));

        const changeCodePayload = {
            [noteToEditId]: {
                ...payload,
                confirm_code: false,
                modifier_codes:
                    modifierCode && modifierCode.length > 0
                        ? modifierCode
                        : undefined,
            },
        };

        // Multi-selected notes
        const changeCodesPayload: Payload =
            notes && notes.length !== 0
                ? notes?.reduce((item: Payload, note: INote) => {
                      return {
                          ...item,
                          [note.note_id]: {
                              cpt_code: note.cpt_code || "",
                              confirm_code: false,
                              modifier_codes:
                                  modifierCode && modifierCode.length > 0
                                      ? modifierCode
                                      : undefined,
                          },
                      };
                  }, {})
                : {};

        changeCptCode.mutate(
            notes && notes?.length !== 0
                ? changeCodesPayload
                : changeCodePayload,
            {
                onSuccess: (response) => {
                    if (clientId && noteToEditId) {
                        queryClient.setQueryData<
                            IPaginatedApiResponse<IChangeCodeResponse>
                        >([clientId, `session-note`, noteToEditId], (prev) => {
                            const prevRequired =
                                prev as IPaginatedApiResponse<IChangeCodeResponse>;
                            return {
                                ...prevRequired,
                                note_status: response.status,
                            };
                        });
                    }
                    queryClient.invalidateQueries({
                        queryKey: [clientId, `session-note`, noteToEditId],
                    });
                    queryClient.invalidateQueries({
                        queryKey: ["notes"],
                    });
                    toast({
                        mode: "success",
                        message:
                            response.message || "Cpt code updated successfully",
                    });
                    onFormSubmit();
                },

                onError: (err) => {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not change code at this time",
                    });
                },
            }
        );
    };

    return (
        <>
            {showDetails && (
                <Card type="dashboard" className={styles.card}>
                    <h2 className={styles.heading}>NOTE DETAILS</h2>
                    <div className={styles.details}>
                        <div className={styles.detail}>
                            <p className="section-info fs-exclude"> CPT CODE</p>
                            <p className="section-value">
                                {noteToEditCptCode || "--"}
                            </p>
                        </div>

                        <div className={styles.detail}>
                            <p className="section-info fs-exclude">NOTE TYPE</p>
                            <p className="section-value capitalize">
                                {removeEnumUnderscore(data?.data.type || "--")}
                            </p>
                        </div>

                        <div className={styles.detail}>
                            <p className="section-info fs-exclude">
                                APPOINTMENT TYPE
                            </p>
                            <p className="section-value capitalize">
                                {removeEnumUnderscore(
                                    data?.data.appointment_type || "--"
                                )}
                            </p>
                        </div>
                        <div className={styles.detail}>
                            <p className="section-info fs-exclude">
                                PROVIDER NAME
                            </p>
                            <p className="section-value">
                                {data?.data.provider
                                    ? `${data.data.provider.first_name} ${data.data.provider.last_name}`
                                    : "--"}
                            </p>
                        </div>
                        {canEditPlaceOfService && (
                            <div className={styles.detail}>
                                <p className="section-info fs-exclude">
                                    Appointment location
                                </p>
                                <p className="section-value capitalize">
                                    {getAppointmentLocationToShow(
                                        data.data.note_content
                                    )}
                                </p>
                            </div>
                        )}
                    </div>
                </Card>
            )}

            <form
                id="change-cpt-code"
                aria-label="form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="fg">
                    <Controller
                        name="cpt_code"
                        control={control}
                        defaultValue={
                            CPTOptions.find(
                                (code) => code.value === noteToEditCptCode
                            )?.value
                        }
                        render={({ field }) => (
                            <Select
                                label="CPT Code"
                                placeholder="CPT Code"
                                options={CPTOptions}
                                onChange={(val) =>
                                    field.onChange((val as Options).value)
                                }
                                isSearchable
                                defaultValue={CPTOptions.find(
                                    (code) => code.value === noteToEditCptCode
                                )}
                                hasError={!!errors.cpt_code}
                                errorText={errors.cpt_code?.message}
                                isLongListInDialog
                            />
                        )}
                    />
                </div>
                <div className="fg">
                    <Controller
                        name="modifier_codes"
                        control={control}
                        defaultValue={data?.data.modifier_codes}
                        render={({ field }) => (
                            <Select
                                label="Modifier code"
                                placeholder="Modifier code"
                                options={modifiersTypesForSelect}
                                onChange={(val) => {
                                    field.onChange(
                                        (
                                            val as {
                                                label: string;
                                                value: string;
                                            }[]
                                        ).map((code) => code.value)
                                    );
                                }}
                                isSearchable
                                wrapperClass={styles.modcontainer}
                                defaultValue={modifiersTypesForSelect?.filter(
                                    (code) =>
                                        data?.data.modifier_codes?.includes(
                                            code.value.split(" ")[0]
                                        )
                                )}
                                isMulti
                                multiHasValues={
                                    modifierCodesFromForm &&
                                    modifierCodesFromForm.length > 0
                                }
                                isLongListInDialog
                                closeMenuOnSelect
                                hideSelectedOptions={false}
                                hasError={!!errors.modifier_codes}
                                errorText={errors.modifier_codes?.message}
                            />
                        )}
                    />
                </div>
                {canEditPlaceOfService && (
                    <Controller
                        name="pos_code"
                        control={control}
                        defaultValue={
                            PlaceOfServiceOptions.find(
                                (pos) => pos.value === data.data.pos_code
                            )?.value
                        }
                        render={({ field }) => (
                            <Select
                                label="POS code"
                                placeholder="POS code"
                                options={PlaceOfServiceOptions}
                                onChange={(val) =>
                                    field.onChange((val as Options).value)
                                }
                                defaultValue={PlaceOfServiceOptions.find(
                                    (pos) => pos.value === data.data.pos_code
                                )}
                                isSearchable
                                isLongListInDialog
                            />
                        )}
                    />
                )}
            </form>
        </>
    );
}

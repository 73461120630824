import { SvgProps } from "../../utils/types";

export default function ChevronRightIcon({ className }: SvgProps) {
    return (
        <svg
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M8.92126 7.46949L2.84784 1.39637C2.55503 1.10355 2.08003 1.10355 1.78722 1.39637L1.07878 2.1048C0.786282 2.3973 0.78597 2.87105 1.07753 3.16418L5.89096 7.9998L1.07784 12.8357C0.785969 13.1289 0.786594 13.6026 1.07909 13.8951L1.78753 14.6035C2.08034 14.8964 2.55534 14.8964 2.84815 14.6035L8.92126 8.53011C9.21407 8.2373 9.21407 7.7623 8.92126 7.46949Z"
                fill="#0B132B"
            />
        </svg>
    );
}

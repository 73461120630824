/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    addNoteAddendum,
    addSessionNote,
    approveSuperviseeNote,
    archiveNote,
    changeCptCode,
    deleteDraftNote,
    editDraftNote,
    exportNotes,
    fetchNoteAiAnalysis,
    requestNoteChanges,
    resolveNoteChange,
    signSessionNote,
} from "../../services/note";
import { IApiResponse } from "../../utils/types/api-response";
import {
    IAddendumDetails,
    IApproveNotePayload,
    IApproveNoteResponse,
    IChangeCode,
    IChangeCodeResponse,
    IEditDraftNote,
    IExportNotePayload,
    INewAddendum,
    INewNoteResponse,
    INewSessionNote,
    INoteAiAnalysisResponse,
    IRequestChangePayload,
    ISessionNote,
    ISignNewNotePayload,
    IChangeArchiveStatus,
} from "../../utils/types/notes";
import { IRequestDetails } from "../../modules/notes/types/notes.types";

// Function to add note addendum
export function useAddNewAddendum(noteId: string) {
    return useMutation<
        IApiResponse<IAddendumDetails>,
        AxiosError<IApiResponse<IAddendumDetails>>,
        INewAddendum
    >({
        mutationKey: ["add-addendum", noteId],
        mutationFn: (data: INewAddendum) => addNoteAddendum(noteId, data),
    });
}

// Hook for adding client session note
export function useAddSessionNote() {
    return useMutation<
        IApiResponse<INewNoteResponse>,
        AxiosError<IApiResponse<INewNoteResponse>>,
        INewSessionNote
    >({
        mutationFn: (data: INewSessionNote) => addSessionNote(data),
    });
}

// Hook for signing client session note
export function useSignSessionNote() {
    return useMutation<
        IApiResponse<INewNoteResponse>,
        AxiosError<IApiResponse<INewNoteResponse>>,
        { noteId: string; data: ISignNewNotePayload }
    >({
        mutationKey: ["sign-note"],
        mutationFn: (payload: { noteId: string; data: ISignNewNotePayload }) =>
            signSessionNote(payload),
    });
}

export function useDeleteDraftNote() {
    interface IDeleteNotesResponse {
        deleted_notes: string[];
        undeleted_notes: string[];
    }
    return useMutation<
        IApiResponse<IDeleteNotesResponse>,
        AxiosError<IApiResponse<IDeleteNotesResponse>>,
        string[]
    >({
        mutationKey: ["delete-draft-notes"],
        mutationFn: (noteIds: string[]) => deleteDraftNote(noteIds),
    });
}

export function useApproveNote() {
    return useMutation<
        IApiResponse<IApproveNoteResponse>,
        AxiosError<IApiResponse<IApproveNoteResponse>>,
        IApproveNotePayload
    >({
        mutationKey: ["approve-note"],
        mutationFn: (data: IApproveNotePayload) => approveSuperviseeNote(data),
    });
}

export function useRequestChange(noteId: string) {
    return useMutation<
        IApiResponse<IRequestDetails>,
        AxiosError<IApiResponse<IRequestDetails>>,
        IRequestChangePayload
    >({
        mutationKey: ["request-change", noteId],
        mutationFn: (data: IRequestChangePayload) =>
            requestNoteChanges(noteId, data),
    });
}

export function useEditDraftNote() {
    return useMutation<
        IApiResponse<ISessionNote>,
        AxiosError<IApiResponse<ISessionNote>>,
        {
            noteId: string;
            data: IEditDraftNote;
        }
    >({
        mutationKey: ["edit-note"],
        mutationFn: (payload: { noteId: string; data: IEditDraftNote }) =>
            editDraftNote(payload),
    });
}

// Hook for getting exported notes
export function useExportNotes() {
    interface IExportResponseData {
        export_job_id: string;
        expected_pdf_count: number;
    }
    return useMutation<
        IApiResponse<IExportResponseData>,
        AxiosError<IApiResponse<IExportResponseData>>,
        IExportNotePayload
    >({
        mutationFn: (data: IExportNotePayload) => exportNotes(data),
    });
}

// Hook for changing cpt code
export function useChangeCptCode() {
    return useMutation<
        IApiResponse<IChangeCodeResponse[]>,
        AxiosError<IApiResponse<IChangeCodeResponse>>,
        IChangeCode
    >({
        mutationKey: ["change-code"],
        mutationFn: (data: IChangeCode) => changeCptCode(data),
    });
}

export function useNoteAiAnalysis() {
    return useMutation<
        INoteAiAnalysisResponse,
        AxiosError<IApiResponse<INoteAiAnalysisResponse>>,
        string
    >({
        mutationFn: (noteId: string) => fetchNoteAiAnalysis(noteId),
    });
}

export function useChangeNoteArchiveStatus() {
    interface IChangeArchiveStatusResponse {
        archived_notes: string[];
        unarchived_notes: string[];
    }

    return useMutation<
        IApiResponse<IChangeArchiveStatusResponse>,
        AxiosError<IApiResponse<IChangeArchiveStatusResponse>>,
        IChangeArchiveStatus
    >({
        mutationKey: ["archive-note"],
        mutationFn: (data: IChangeArchiveStatus) => archiveNote(data),
    });
}

export function useResolveNoteChange() {
    return useMutation<
        IApiResponse<{ message: string }>,
        AxiosError<IApiResponse<{ message: string }>>,
        string
    >({
        mutationKey: ["resolve-change"],
        mutationFn: (noteId: string) => resolveNoteChange(noteId),
    });
}

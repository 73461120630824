import ListState from "../../../components/ListState/ListState";
import Skeleton from "../../../components/Skeleton/Skeleton";
import PracticeSettings from "../components/PracticeSettings";
import { useFetchPracticeSettings } from "../hooks/queries/practice-settings";

export default function PracticeSettingsView() {
    // Hook for fetching all practice setting
    const { data, isLoading, isSuccess, error } = useFetchPracticeSettings();

    if (data?.data?.settings) {
        const settingsArray = data.data.settings.map(
            (setting) => setting.setting_name
        );
        const categoriesArray = data.data.settings.map(
            (setting) => setting.category
        );
        // Save to localStorage
        localStorage.setItem(
            "settingsArray",
            JSON.stringify(settingsArray) || "[]"
        );
        localStorage.setItem(
            "categoriesArray",
            JSON.stringify(categoriesArray) || "[]"
        );
    }

    if (isLoading) {
        return (
            <div className="page">
                <Skeleton
                    type="table"
                    containerTestId="practice-settings-loader"
                    count={6}
                />
            </div>
        );
    }

    if (error && error?.response?.status !== 404) {
        return (
            <div className="page">
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display settings at this time please try again later`
                    }
                />
            </div>
        );
    }

    if (data && isSuccess && data.data.settings.length === 0) {
        return (
            <div className="page">
                <ListState
                    stateHelperText="Settings list will appear here"
                    isError={false}
                    emptyMessage="There are no settings yet"
                />
            </div>
        );
    }

    return <PracticeSettings />;
}

import { useFetchPracticeSettings } from "./queries/practice-settings";

export default function useShowInsuranceOverSelfPay() {
    const { data, isLoading, error } = useFetchPracticeSettings({
        category: "general",
        setting_name: "prioritize_insurance_over_self_pay",
    });

    if (isLoading || error) return false;

    const settingDetails = data?.data.settings.find(
        (setting) =>
            setting.setting_name === "prioritize_insurance_over_self_pay"
    );

    const value =
        settingDetails?.current_value ??
        settingDetails?.default_value ??
        "false";

    return value === "true";
}

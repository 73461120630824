import { ClaimStatus } from "../../claims/types/claims.types";

export interface IAuditResponse {
    audit_id: string;
    author: {
        user_id: string;
        first_name: string;
        last_name: string;
    };
    client: {
        client_id: string;
        first_name: string;
        last_name: string;
    };
    metadata: {
        amount?: number;
        client_id?: string;
        invoice_id?: string;
        payment_id?: string;
        note_id?: string;
        claim_id?: string;
        remittance_id?: string;
        claim_status?: ClaimStatus;
    };
    description: string;
    category: AuditCategories;
    created_at: string;
    type: string;
    date_of_service: string | null;
    claim_number: string | null;
}

export interface IAuditPayload {
    custom_date: string;
    from_date: string | Date | null;
    to_date: string | Date | null;
    author: string;
    category: string[];
    metadata: {
        client: string;
    };
}

export enum AuditCategories {
    PAYMENTS = "payment",
    PRACTICE_SETTING = "practice_setting",
    CLAIMS = "claims",
    INSURANCE_PROVIDER = "insurance_provider",
    REMITS = "remits",
    MEMO = "memo",
}

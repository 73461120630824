import * as React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Switch,
} from "@jhool-io/fe-components";
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import {
    FlaggedNoteForAuditStatus,
    NoteStatus,
    NoteTypes,
    noteTypesForExport,
} from "../../types/notes.types";
import {
    IChangeCodeResponse,
    IIndividualSoapNoteAnalysisResponse,
    Source,
} from "../../../../utils/types/notes";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import useToast from "../../../../hooks/useToast";
import {
    useChangeCptCode,
    useChangeNoteArchiveStatus,
    useDeleteDraftNote,
    useExportNotes,
    useNoteAiAnalysis,
    useResolveNoteChange,
} from "../../../../hooks/mutations/note";
import { UserRole } from "../../../user-management/types/user-management.types";
import { useDoesUserHaveRole } from "../../../../hooks/permissions";
import { UserPermisions } from "../../../../utils/types/user";
import { useExportPdfs } from "../../../../hooks/queries";
import { IPaginatedApiResponse } from "../../../../utils/types/api-response";
import ApproveNote from "../../../../components/Notes/ApproveNote/ApproveNote";
import RequestChanges from "../../../../components/Notes/RequestChanges/RequestChanges";
import ArchiveNote from "../../../../components/Notes/NoteContent/ArchiveNote/ArchiveNote";
import ChangeCode from "../../../../components/Notes/NoteContent/ChangeCode/ChangeCode";
import { cn } from "../../../../utils/helpers";
import NoteContent from "../NoteContent/NoteContent";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import AddAddendum from "../AddAddendumForm/AddAddendumForm";
import { getNoteListNavigateUrl } from "../../helpers/notes.helpers";
import usePractice from "../../../../hooks/usePractice";
import FlagNoteForm from "../FlagNoteForm/FlagNoteForm";
import EditManualClaimForm from "../../../claims/components/EditManualClaimForm/EditManualClaimForm";
import { useGetClaim } from "../../../claims/hooks/claims.queries";
import { ClaimStatus } from "../../../claims/types/claims.types";

type Dialogs =
    | "delete_draft"
    | "note_approval"
    | "request_change"
    | "archive_note"
    | "change_code"
    | "add_addendum"
    | "export_note"
    | "resolve_change"
    | "flag_note"
    | "edit_manual_claim"
    | "unflag_note";

export default function SingleNoteActions() {
    const [noteAnalysisResponse, setNoteAnalysisResponse] = React.useState<{
        analysisAvailable?: boolean;
        averageScore?: number;
        reviewResponse?: Partial<IIndividualSoapNoteAnalysisResponse>;
    }>({});
    const [
        requestChangeDialogContentInView,
        setRequestChangeDialogContentInView,
    ] = React.useState<"note_content" | "request_change_form" | "all">(() =>
        window.matchMedia("(max-width: 1000px)").matches
            ? "note_content"
            : "all"
    );
    const [jobId, setJobId] = React.useState<string | null>(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const dialogInView = searchParams.get("modal");

    const { practice } = usePractice();

    // Get id from url params
    const params = useParams();

    const noteId = params.noteId as string;
    const clientId = params.clientId as string;

    const { toast } = useToast();

    const navigate = useNavigate();
    const location = useLocation();
    const isSuperAdmin = practice?.roles.includes(UserRole.SUPER_ADMIN);

    const { data } = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    const queryClient = useQueryClient();

    const noteListNavigateUrl = getNoteListNavigateUrl(practice?.roles || []);

    const requestChangeSwitchOptions = [
        {
            title: "Note content",
            key: "note_content",
            onClick: () => setRequestChangeDialogContentInView("note_content"),
        },
        {
            title: "Request changes",
            key: "request_change_form",
            onClick: () =>
                setRequestChangeDialogContentInView("request_change_form"),
        },
    ];

    const runNoteAiAnalysis = useNoteAiAnalysis();
    const confirmCptCode = useChangeCptCode();
    const resolveChange = useResolveNoteChange();
    const deleteNote = useDeleteDraftNote();
    const unarchiveNote = useChangeNoteArchiveStatus();
    const exportNotes = useExportNotes();

    // To determine if mutation action is currently running, returns 0 || 1
    const requstChangeIsMutating = useIsMutating(["request-change", noteId]);
    const approveNoteIsMutating = useIsMutating(["approve-note"]);
    const changeCodeIsMutating = useIsMutating(["change-code"]);
    const deleteDraftIsMutating = useIsMutating(["delete-draft-notes"]);
    const addAddendumIsMutating = useIsMutating(["add-addendum"]);
    const archiveNoteIsMutating = useIsMutating(["archive-note"]);
    const flagNoteIsMutating = useIsMutating(["flag-note"]);
    const editManualClaimIsMutating = useIsMutating(["edit-manual-claim"]);

    const canUserResolveNoteChange =
        practice?.roles?.includes(UserRole.SUPER_ADMIN) ||
        practice?.roles?.includes(UserRole.AUDITOR) ||
        practice?.is_supervisor === data?.data.provider.provider_id;

    // check if logged in user has role to determine action
    const loggedInUserHasRole = useDoesUserHaveRole([
        UserRole.AUDITOR,
        UserRole.SUPER_ADMIN,
        UserRole.CODER,
        UserRole.BILLER_ADMIN,
    ]);

    // Function for open, closing and clearing dialogs
    const handleSetDialogInView = (dialog: Dialogs) => {
        searchParams.set("modal", dialog);
        setSearchParams(searchParams);
    };

    const handleClearModalInView = () => {
        searchParams.delete("modal");
        if (searchParams.get("field_id")) {
            searchParams.delete("field_id");
        }
        navigate(location.pathname, { replace: true });
    };

    const canWriteAddendum =
        data?.data.provider.provider_id === practice?.provider_id ||
        Boolean(
            practice?.provider_id &&
                practice?.provider_id === data?.data?.provider?.supervisor_id
        );

    const getProviderWithRole = (roleType: string) => {
        const roleAvailable = data?.data.note_signatures?.find(
            (item) => item.role === roleType
        );

        return roleAvailable;
    };

    const canArchiveNote = () => {
        if (
            practice?.permissions?.includes(
                UserPermisions.CLIENT_NOTES_ALL_READ
            ) ||
            practice?.permissions?.includes(
                UserPermisions.CLIENT_INFO_NOTES_WRITE
            ) ||
            practice?.provider_id ===
                getProviderWithRole("author")?.provider_id ||
            practice?.is_supervisor
        ) {
            return true;
        }

        return false;
    };

    const showActions = () => {
        if (
            noteTypesForExport.includes(data?.data.type as NoteTypes) ||
            (data?.data.note_status === NoteStatus.PENDING_SUPERVISOR_REVIEW &&
                (practice?.is_supervisor ||
                    practice?.roles?.includes(UserRole.SUPER_ADMIN) ||
                    practice?.roles?.includes(UserRole.BILLER_ADMIN))) ||
            data?.data.provider.provider_id === practice?.provider_id ||
            (data?.data.note_status === NoteStatus.PENDING_CODE_REVIEW &&
                loggedInUserHasRole) ||
            (NoteStatus.CODED && canArchiveNote()) ||
            practice?.roles?.includes(UserRole.AUDITOR) ||
            canUserResolveNoteChange
        ) {
            return true;
        }
        return false;
    };

    const exportNote = useExportPdfs(
        {
            export_job_id: jobId as string,
            pdf_type: "session_note",
        },
        Boolean(jobId)
    );

    const { data: claimsData } = useGetClaim(
        {
            note_id: data?.data.note_id,
        },
        Boolean(data?.data.note_id)
    );

    if (jobId && !exportNote.isLoading) {
        setJobId(null);
        handleClearModalInView();
    }

    const getDialogHeaderAndSaveText = (currentDialog: Dialogs) => {
        let dialogHeaderAndSaveText: string;
        switch (currentDialog) {
            case "delete_draft":
                dialogHeaderAndSaveText = "Delete Draft";
                break;
            case "note_approval":
                dialogHeaderAndSaveText = "Approve and sign Note";
                break;
            case "request_change":
                dialogHeaderAndSaveText = "Request Change";
                break;
            case "archive_note":
                dialogHeaderAndSaveText = "Archive Note";
                break;
            case "change_code":
                dialogHeaderAndSaveText = "Change code";
                break;
            case "add_addendum":
                dialogHeaderAndSaveText = "Add addendum";
                break;
            case "export_note":
                dialogHeaderAndSaveText = "Export in progress";
                break;
            case "flag_note":
                dialogHeaderAndSaveText = "Flag for Audit";
                break;
            case "unflag_note":
                dialogHeaderAndSaveText = "Unflag Note";
                break;
            default:
                dialogHeaderAndSaveText = "";
        }
        return dialogHeaderAndSaveText;
    };

    const getDialogFormsSubmitValue = (formValue: Dialogs) => {
        let submitValue: string;

        switch (formValue) {
            case "note_approval":
                submitValue = "note-approval";
                break;
            case "request_change":
                submitValue = "request-changes";
                break;
            case "archive_note":
                submitValue = "archive-note";
                break;
            case "change_code":
                submitValue = "change-cpt-code";
                break;
            case "add_addendum":
                submitValue = "add-addendum";
                break;
            case "flag_note":
                submitValue = "flag-note";
                break;
            case "unflag_note":
                submitValue = "flag-note";
                break;
            default:
                return "";
        }

        return submitValue;
    };

    const handleRunNoteAiAnalysis = () => {
        runNoteAiAnalysis.mutate(noteId, {
            onSuccess: async (successResponse) => {
                setNoteAnalysisResponse(successResponse.data);
            },
            onError: async (error) => {
                setNoteAnalysisResponse({});
                toast({
                    mode: "error",
                    message:
                        error.response?.data.message ||
                        "Unable to get Mantle AI's suggestions at the moment, please try again later. If the issue persists, please submit a feedback",
                });
            },
        });
    };

    const handleUnarchive = () => {
        const dataToSend = {
            note_ids_to_archive: [noteId],
            archive_status: false,
        };

        unarchiveNote.mutate(dataToSend, {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: [`notes`],
                });

                await queryClient.invalidateQueries({
                    queryKey: ["supervisee"],
                });

                await queryClient.invalidateQueries({
                    queryKey: [clientId, `session-note`, noteId],
                });

                toast({
                    mode: "success",
                    message: res.message || "Note unarchived successfully",
                });

                navigate(noteListNavigateUrl);
            },

            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not archive note at this time",
                });
            },
        });
    };

    const handleExportNote = () => {
        const dataToSend = {
            session_note_ids: [noteId],
        };

        exportNotes.mutate(dataToSend, {
            onSuccess: async (res) => {
                handleSetDialogInView("export_note");
                await new Promise<void>((resolve) => {
                    const timer = setTimeout(() => {
                        clearTimeout(timer);
                        setJobId(res.data.export_job_id);
                        resolve();
                    }, 8000);
                });
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.message || "Could not export notes at this time",
                });
            },
        });
    };

    const handleConfirmCode = () => {
        const changeCodePayload = {
            [noteId]: {
                cpt_code: data?.data?.cpt_code || "",
                modifier_codes: data?.data?.modifier_codes || [],
                confirm_code: true,
            },
        };
        confirmCptCode.mutate(changeCodePayload, {
            onSuccess: (response) => {
                queryClient.setQueryData<
                    IPaginatedApiResponse<IChangeCodeResponse>
                >([clientId, `session-note`, noteId], (prev) => {
                    const prevRequired =
                        prev as IPaginatedApiResponse<IChangeCodeResponse>;
                    return {
                        ...prevRequired,
                        note_status: response.status,
                    };
                });
                queryClient.invalidateQueries({
                    queryKey: [clientId, `session-note`, noteId],
                });
                toast({
                    mode: "success",
                    message:
                        response.message || "Cpt code updated successfully",
                });

                if (response?.data?.[0]?.status === NoteStatus.CODED) {
                    navigate(`/billing-and-claims/${clientId}/${noteId}`);
                }
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not change code at this time",
                });
            },
        });
    };

    const handleResolveNoteChange = () => {
        resolveChange.mutate(data?.data.note_id || "", {
            onSuccess: async (res) => {
                await queryClient.invalidateQueries({
                    queryKey: [`notes`],
                });

                await queryClient.invalidateQueries({
                    queryKey: ["supervisee"],
                });

                await queryClient.invalidateQueries({
                    queryKey: [clientId, `session-note`, noteId],
                });

                toast({
                    mode: "success",
                    message: res.message || "Note updated successfully",
                });
                handleClearModalInView();
            },

            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not update note at this time",
                });
            },
        });
    };

    const handleDeleteNote = () => {
        deleteNote.mutate([noteId], {
            async onSuccess(res) {
                await queryClient.invalidateQueries({
                    queryKey: [`notes`],
                });
                toast({
                    mode: "success",
                    message: res.message || "Draft note deleted successfully",
                });
                handleClearModalInView(); // Redirect to notes list
                navigate(`/notes`);
            },
            onError(err) {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Cannot delete note at this moment, please try again",
                });
            },
        });
    };

    const canRequestChange =
        useDoesUserHaveRole([
            UserRole.AUDITOR,
            UserRole.BILLER,
            UserRole.SUPER_ADMIN,
        ]) || practice?.is_supervisor;

    // Function to determine what content to render inside the actions dropdown
    const handleDropdownOptionsToShow = (
        status: NoteStatus,
        noteType: NoteTypes,
        roles: string[]
    ) => {
        const reviewNoteWithAiDropdownItemButton =
            practice?.permissions?.includes(
                UserPermisions.CLIENT_INFO_NOTES_AI_REVIEW
            ) &&
            !data?.data.is_archived &&
            noteType === NoteTypes.INDIVIDUAL_SOAP_NOTE ? (
                <DropdownItem onClick={handleRunNoteAiAnalysis}>
                    Review note with Mantle AI
                </DropdownItem>
            ) : null;
        switch (status) {
            case NoteStatus.DRAFT:
                return (
                    <>
                        <DropdownItem
                            onClick={() =>
                                navigate(
                                    `/notes/${data?.data.note_id}/${data?.data.client.client_id}/edit?note_type=${data?.data.type}&session_history_id=${data?.data.session_history_id}&appt_type=${data?.data.appointment_type}`
                                )
                            }
                        >
                            Edit note
                        </DropdownItem>
                        <DropdownItem
                            onClick={() =>
                                handleSetDialogInView("delete_draft")
                            }
                        >
                            Delete draft
                        </DropdownItem>
                    </>
                );
            case NoteStatus.PENDING_SUPERVISOR_REVIEW:
                return (
                    <>
                        {(practice?.is_supervisor ||
                            roles.includes(UserRole.SUPER_ADMIN) ||
                            (roles.includes(UserRole.AUDITOR) &&
                                practice?.provider_id)) &&
                        !data?.data.is_archived ? (
                            <DropdownItem
                                onClick={() =>
                                    handleSetDialogInView("note_approval")
                                }
                            >
                                Approve and sign note
                            </DropdownItem>
                        ) : null}
                        {canRequestChange &&
                            practice?.permissions?.includes(
                                UserPermisions.CLIENT_INFO_NOTES_CHANGE_REQUEST_WRITE
                            ) &&
                            !data?.data.is_archived && (
                                <DropdownItem
                                    onClick={() =>
                                        handleSetDialogInView("request_change")
                                    }
                                >
                                    Request changes
                                </DropdownItem>
                            )}
                        {canWriteAddendum && (
                            <DropdownItem
                                onClick={() =>
                                    handleSetDialogInView("add_addendum")
                                }
                            >
                                Add addendum
                            </DropdownItem>
                        )}
                        {reviewNoteWithAiDropdownItemButton}
                    </>
                );

            case NoteStatus.PENDING_CODE_REVIEW:
                return (
                    <div>
                        {(!data?.data.flagged_for_audit_status ||
                            data?.data.flagged_for_audit_status ===
                                FlaggedNoteForAuditStatus.UNFLAGGED) &&
                        (roles.includes("coder") ||
                            roles.includes("superadmin") ||
                            roles.includes("billeradmin")) ? (
                            <div>
                                {noteType === NoteTypes.CANCELLATION_NOTE ||
                                noteType === NoteTypes.SAFETY_PLAN ||
                                noteType === NoteTypes.SUPERVISION_NOTE ? (
                                    <DropdownItem onClick={handleConfirmCode}>
                                        Confirm code
                                    </DropdownItem>
                                ) : (
                                    <>
                                        <DropdownItem
                                            onClick={handleConfirmCode}
                                        >
                                            Confirm code
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() =>
                                                handleSetDialogInView(
                                                    "change_code"
                                                )
                                            }
                                        >
                                            Change code
                                        </DropdownItem>
                                    </>
                                )}
                            </div>
                        ) : null}
                        {canRequestChange &&
                            practice?.permissions?.includes(
                                UserPermisions.CLIENT_INFO_NOTES_CHANGE_REQUEST_WRITE
                            ) &&
                            practice?.provider_id !==
                                getProviderWithRole("author")?.provider_id &&
                            data?.data.flagged_for_audit_status !==
                                FlaggedNoteForAuditStatus.SENT_FOR_CHANGES && (
                                <DropdownItem
                                    onClick={() =>
                                        handleSetDialogInView("request_change")
                                    }
                                >
                                    Request changes
                                </DropdownItem>
                            )}
                        {(canWriteAddendum &&
                            !data?.data.flagged_for_audit_status) ||
                        data?.data.flagged_for_audit_status ===
                            FlaggedNoteForAuditStatus.UNFLAGGED ? (
                            <DropdownItem
                                onClick={() =>
                                    handleSetDialogInView("add_addendum")
                                }
                            >
                                Add addendum
                            </DropdownItem>
                        ) : null}
                        {practice?.permissions?.includes(
                            UserPermisions.CLIENT_INFO_NOTES_FLAG_FOR_AUDIT_WRITE
                        ) &&
                            (data?.data.flagged_for_audit_status ===
                                FlaggedNoteForAuditStatus.FLAGGED ||
                            data?.data.flagged_for_audit_status ===
                                FlaggedNoteForAuditStatus.SENT_FOR_CHANGES ||
                            data?.data.flagged_for_audit_status ===
                                FlaggedNoteForAuditStatus.REQUIRES_ACTION ? (
                                <DropdownItem
                                    onClick={() =>
                                        handleSetDialogInView("unflag_note")
                                    }
                                >
                                    Unflag note
                                </DropdownItem>
                            ) : (
                                <DropdownItem
                                    onClick={() =>
                                        handleSetDialogInView("flag_note")
                                    }
                                >
                                    Flag for audit
                                </DropdownItem>
                            ))}
                        {reviewNoteWithAiDropdownItemButton}
                    </div>
                );
            case NoteStatus.CODED:
                return (
                    <div>
                        {data?.data.note_content &&
                            canRequestChange &&
                            data?.data.flagged_for_audit_status !==
                                FlaggedNoteForAuditStatus.SENT_FOR_CHANGES && (
                                <DropdownItem
                                    onClick={() =>
                                        handleSetDialogInView("request_change")
                                    }
                                >
                                    Request changes
                                </DropdownItem>
                            )}
                        {data?.data.note_content &&
                        canWriteAddendum &&
                        (data?.data.flagged_for_audit_status ===
                            FlaggedNoteForAuditStatus.UNFLAGGED ||
                            !data?.data.flagged_for_audit_status) ? (
                            <DropdownItem
                                onClick={() =>
                                    handleSetDialogInView("add_addendum")
                                }
                            >
                                Add addendum
                            </DropdownItem>
                        ) : null}
                        {practice?.permissions?.includes(
                            UserPermisions.CLIENT_INFO_NOTES_FLAG_FOR_AUDIT_WRITE
                        ) && (
                            <>
                                {data?.data.flagged_for_audit_status !==
                                    FlaggedNoteForAuditStatus.UNFLAGGED ||
                                !data?.data.flagged_for_audit_status ? (
                                    <DropdownItem
                                        onClick={() =>
                                            handleSetDialogInView("unflag_note")
                                        }
                                    >
                                        Unflag note
                                    </DropdownItem>
                                ) : null}
                                {data?.data.source !== Source.MANUAL &&
                                (data?.data.flagged_for_audit_status ===
                                    FlaggedNoteForAuditStatus.UNFLAGGED ||
                                    !data?.data.flagged_for_audit_status) ? (
                                    <DropdownItem
                                        onClick={() =>
                                            handleSetDialogInView("flag_note")
                                        }
                                    >
                                        Flag for audit
                                    </DropdownItem>
                                ) : null}
                            </>
                        )}
                        {claimsData?.data &&
                            (data?.data.source === Source.MANUAL &&
                            claimsData?.data[0]?.claim_status ===
                                ClaimStatus.READY_FOR_SUBMISSION &&
                            (data?.data.flagged_for_audit_status ===
                                FlaggedNoteForAuditStatus.UNFLAGGED ||
                                !data?.data.flagged_for_audit_status) ? (
                                <DropdownItem
                                    onClick={() =>
                                        handleSetDialogInView(
                                            "edit_manual_claim"
                                        )
                                    }
                                >
                                    Edit note details
                                </DropdownItem>
                            ) : null)}
                        {reviewNoteWithAiDropdownItemButton}
                    </div>
                );
            case NoteStatus.CHANGES_REQUESTED:
                return (
                    <div>
                        {roles.includes(UserRole.AUDITOR) ||
                            (data?.data.flagged_for_audit_status !==
                                FlaggedNoteForAuditStatus.SENT_FOR_CHANGES && (
                                <DropdownItem
                                    onClick={() =>
                                        handleSetDialogInView("request_change")
                                    }
                                >
                                    Request changes
                                </DropdownItem>
                            ))}
                        {practice?.permissions?.includes(
                            UserPermisions.CLIENT_INFO_NOTES_FLAG_FOR_AUDIT_WRITE
                        ) &&
                            (data?.data.flagged_for_audit_status !==
                                FlaggedNoteForAuditStatus.UNFLAGGED ||
                            !data?.data.flagged_for_audit_status ? (
                                <DropdownItem
                                    onClick={() =>
                                        handleSetDialogInView("unflag_note")
                                    }
                                >
                                    Unflag note
                                </DropdownItem>
                            ) : null)}
                        {(canWriteAddendum &&
                            data?.data.flagged_for_audit_status ===
                                FlaggedNoteForAuditStatus.UNFLAGGED) ||
                        !data?.data.flagged_for_audit_status ? (
                            <DropdownItem
                                onClick={() =>
                                    handleSetDialogInView("add_addendum")
                                }
                            >
                                Add addendum
                            </DropdownItem>
                        ) : null}
                    </div>
                );
            default:
                return null;
        }
    };

    const handleDialogContentToDisplay = () => {
        switch (dialogInView) {
            case "delete_draft":
                return (
                    <div>
                        <h3 className="font-medium text-xl mb-8">
                            Delete draft note
                        </h3>
                        <p className="text-gray font-normal text-base">
                            Are you sure you want to delete this draft note?
                            This cannot be undone.
                        </p>
                    </div>
                );
            case "note_approval":
                return (
                    <ApproveNote
                        noteId={noteId}
                        onFormSubmit={handleClearModalInView}
                    />
                );
            case "request_change":
                return <RequestChanges onFormSubmit={handleClearModalInView} />;
            case "archive_note":
                return (
                    <ArchiveNote
                        onFormSubmit={handleClearModalInView}
                        noteId={noteId}
                    />
                );
            case "change_code":
                return <ChangeCode onFormSubmit={handleClearModalInView} />;
            case "add_addendum":
                return <AddAddendum onFormSubmit={handleClearModalInView} />;
            case "export_note":
                return (
                    <div className="loader">
                        <div className="loader-icon" />
                        <div className="loader-text">
                            Please wait while the export is in progress.
                        </div>
                    </div>
                );
            case "flag_note":
                return (
                    <FlagNoteForm
                        onFormSubmit={handleClearModalInView}
                        noteId={noteId}
                        action="flag"
                    />
                );
            case "unflag_note":
                return (
                    <FlagNoteForm
                        onFormSubmit={handleClearModalInView}
                        noteId={noteId}
                        action="unflag"
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Dialog open={dialogInView === "resolve_change"}>
                <DialogContent
                    title="Resolve Change"
                    handleCloseDialog={handleClearModalInView}
                    showFooter
                    saveText="Resolve change"
                    variant="center"
                    onSaveClick={handleResolveNoteChange}
                    cancelText="Cancel"
                    isSubmitBtnDisabled={resolveChange.isLoading}
                    isCancelBtnDisabled={resolveChange.isLoading}
                >
                    <div>
                        <h3 className="font-medium text-xl mb-8">
                            You are about to mark the change requested as
                            resolved
                        </h3>
                        <p className="text-gray font-normal text-base">
                            Do you wish to continue?
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                open={
                    Boolean(dialogInView) &&
                    dialogInView !== "resolve_change" &&
                    dialogInView !== "edit_manual_claim"
                }
            >
                <DialogContent
                    title={getDialogHeaderAndSaveText(dialogInView as Dialogs)}
                    handleCloseDialog={handleClearModalInView}
                    isDeleting={dialogInView === "delete_draft"}
                    showFooter={dialogInView !== "export_note"}
                    saveText={getDialogHeaderAndSaveText(
                        dialogInView as Dialogs
                    )}
                    variant={
                        dialogInView !== "request_change" ? "center" : "drawer"
                    }
                    onSaveClick={
                        dialogInView === "delete_draft"
                            ? handleDeleteNote
                            : undefined
                    }
                    cancelText="Cancel"
                    submitBtnFormValue={getDialogFormsSubmitValue(
                        dialogInView as Dialogs
                    )}
                    isSubmitBtnDisabled={
                        deleteNote.isLoading ||
                        requstChangeIsMutating > 0 ||
                        approveNoteIsMutating > 0 ||
                        deleteDraftIsMutating > 0 ||
                        changeCodeIsMutating > 0 ||
                        addAddendumIsMutating > 0 ||
                        archiveNoteIsMutating > 0 ||
                        flagNoteIsMutating > 0
                    }
                    isCancelBtnDisabled={
                        deleteNote.isLoading ||
                        requstChangeIsMutating > 0 ||
                        approveNoteIsMutating > 0 ||
                        deleteDraftIsMutating > 0 ||
                        changeCodeIsMutating > 0 ||
                        addAddendumIsMutating > 0 ||
                        archiveNoteIsMutating > 0 ||
                        flagNoteIsMutating > 0
                    }
                    wrapperClass={
                        dialogInView === "request_change"
                            ? "w-[3000px]"
                            : undefined
                    }
                >
                    {dialogInView === "request_change" ? (
                        <div className="relative">
                            {requestChangeDialogContentInView !== "all" && (
                                <div className="fixed top-[52px] left-[72px]">
                                    <Switch
                                        switchOptions={
                                            requestChangeSwitchOptions
                                        }
                                        activeSwitch={
                                            requestChangeDialogContentInView
                                        }
                                    />
                                </div>
                            )}

                            <div
                                className={cn(
                                    "block min-[1000px]:flex min-[1000px]:justify-between",
                                    {
                                        "mt-[48px]":
                                            requestChangeDialogContentInView !==
                                            "all",
                                    }
                                )}
                            >
                                {requestChangeDialogContentInView === "all" ||
                                requestChangeDialogContentInView ===
                                    "note_content" ? (
                                    <div className="h-auto basis-1/2 min-[1000px]:h-[75vh] overflow-auto pr-20">
                                        <NoteContent
                                            showSideNavigation={false}
                                            noteAnalysisResponse={
                                                noteAnalysisResponse.reviewResponse
                                            }
                                        />
                                    </div>
                                ) : null}
                                <div className="basis-[45%]">
                                    {requestChangeDialogContentInView ===
                                        "all" ||
                                    requestChangeDialogContentInView ===
                                        "request_change_form"
                                        ? handleDialogContentToDisplay()
                                        : null}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>{handleDialogContentToDisplay()}</>
                    )}
                </DialogContent>
            </Dialog>
            <Dialog open={dialogInView === "edit_manual_claim"}>
                <DialogContent
                    title="Edit manual claim"
                    variant="center"
                    handleCloseDialog={handleClearModalInView}
                    showFooter
                    cancelText="Cancel"
                    saveText="Edit claim"
                    submitBtnFormValue="edit-manual-claim"
                    isSubmitBtnDisabled={editManualClaimIsMutating > 0}
                    isCancelBtnDisabled={editManualClaimIsMutating > 0}
                >
                    <EditManualClaimForm
                        onFormSubmit={handleClearModalInView}
                    />
                </DialogContent>
            </Dialog>
            {showActions() && data ? (
                <Dropdown>
                    <DropdownTrigger>
                        <Button
                            aria-label="note-action-button"
                            variant="primary"
                            type="button"
                            iconRight={<ChevronDownIcon />}
                        >
                            Actions
                        </Button>
                    </DropdownTrigger>
                    <DropdownContent width="auto" align="end">
                        {!data?.data.is_archived
                            ? handleDropdownOptionsToShow(
                                  data?.data.note_status,
                                  data?.data.type,
                                  practice?.roles || []
                              )
                            : null}

                        {canArchiveNote() &&
                            !data?.data.is_archived &&
                            data?.data.note_status !== NoteStatus.DRAFT && (
                                <DropdownItem
                                    onClick={() =>
                                        handleSetDialogInView("archive_note")
                                    }
                                >
                                    Archive note
                                </DropdownItem>
                            )}

                        {canArchiveNote() && data.data.is_archived && (
                            <DropdownItem onClick={() => handleUnarchive()}>
                                Unarchive note
                            </DropdownItem>
                        )}

                        {noteTypesForExport.includes(data.data.type) &&
                            data.data.note_status !== NoteStatus.DRAFT &&
                            data.data.note_content &&
                            isSuperAdmin && (
                                <DropdownItem onClick={handleExportNote}>
                                    Export to pdf
                                </DropdownItem>
                            )}
                        {canUserResolveNoteChange &&
                        (data.data.note_status ===
                            NoteStatus.CHANGES_REQUESTED ||
                            data.data.flagged_for_audit_status ===
                                FlaggedNoteForAuditStatus.SENT_FOR_CHANGES) ? (
                            <DropdownItem
                                onClick={() =>
                                    handleSetDialogInView("resolve_change")
                                }
                            >
                                Resolve change
                            </DropdownItem>
                        ) : null}
                    </DropdownContent>
                </Dropdown>
            ) : null}
        </>
    );
}

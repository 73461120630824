export enum UserRole {
    SUPER_ADMIN = "superadmin",
    PROVIDER = "provider",
    BILLER = "biller",
    CODER = "coder",
    AUDITOR = "auditor",
    BILLER_ADMIN = "billeradmin",
    CLIENT_SUPPORT = "clientsupport",
    CLIENT = "client",
    USER_SUPPORT = "usersupport",
}

export enum UserPermisions {
    CLIENT_NOTES_ALL_READ = "client_notes_all_read",
    CLIENT_INFO_NOTES_READ = "clientinfo_notes_read",
    CLIENT_INFO_NOTES_WRITE = "clientinfo_notes_write",
    CLIENT_INFO_NOTES_CHANGE_REQUEST_WRITE = "clientinfo_notes_changerequest_write",
    CLIENT_INFO_NOTES_AI_REVIEW = "clientinfo_notes_ai_review",
    CLIENT_INFO_NOTES_APPROVAL = "clientinfo_notes_approval",
    CLIENT_INFO_NOTES_FLAG_FOR_AUDIT_WRITE = "clientinfo_flag_for_audit_write",

    CLIENT_INFO_ALL_ADMIN = "clientinfo_all_admin",

    CLIENT_INFO_DOCUMENT_READ = "client_info_document_read",
    CLIENT_INFO_DOCUMENT_WRITE = "client_info_document_write",

    CLIENT_INFO_MEMO_READ = "client_info_memo_read",
    CLIENT_INFO_MEMO_WRITE = "client_info_memo_write",

    CLIENT_INFO_PAYMENTS_READ = "client_info_payments_read",
    CLIENT_INFO_PAYMENTS_WRITE = "client_info_payments_write",

    CLIENT_INFO_FEES_READ = "client_info_fees_read",
    CLIENT_INFO_FEES_WRITE = "client_info_fees_write",

    CLIENT_INFO_INSURANCE_READ = "client_info_insurance_read",
    CLIENT_INFO_INSURANCE_WRITE = "client_info_insurance_write",

    CLIENT_INFO_PERSONAL_READ = "client_info_personal_read",
    CLIENT_INFO_PERSONAL_WRITE = "client_info_personal_write",

    CLIENT_INFO_PROVIDER_READ = "client_info_provider_read",
    CLIENT_INFO_PROVIDER_WRITE = "client_info_provider_write",

    CLIENT_INFO_SESSION_READ = "client_info_session_read",
    CLIENT_INFO_SESSION_WRITE = "client_info_session_write",

    CLIENT_INFO_ALL_READ = "client_info_all_read",
    CLIENT_INFO_ALL_WRITE = "client_info_all_write",

    CLIENT_INFO_INVOICE_READ = "client_info_invoice_read",
    CLIENT_INFO_INVOICE_WRITE = "client_info_invoice_write",

    USER_INFO_ALL_ADMIN = "user_info_all_admin",
    USER_INFO_ALL_READ = "user_info_all_read",
    USER_INFO_ALL_WRITE = "user_info_all_write",

    PROVIDER_REPORTS_READ = "provider_reports_read",
    PROVIDER_INFO_UPDATE = "provider_info_update",

    PROVIDER_INFO_READ = "provider_info_read",

    AUDIT_DASHBOARD_READ = "audit_dashboard_read",

    BILLING_DASHBOARD_READ = "billing_dashboard_read",

    TODO_ALL_READ = "todo_all_read",
    TODO_ALL_DELETE = "todo_all_delete",
    PRACTICE_SETTING_READ = "practice_setting_read",
    PRACTICE_SETTING_WRITE = "practice_setting_write",
    PRACTICE_SETTING_ADMIN = "practice_setting_admin",
}
export enum InternalUsersStatus {
    ACTIVE = "active",
    DEACTIVATED = "deactivated",
}

export enum UserRoleDescription {
    provider = "Provide essential medical care to clients",
    superadmin = "Has access to all pages and functions",
    supervisor = "Provider who is also in charge of supervising others",
    biller = "Preparing and submitting billing data and medical claims to insurance companies",
    coder = "Responsible for translating physicians’ reports into useful uniform medical codes",
    auditor = "Audits specified number of records per coder as defined in the system coding audit plan",
    billeradmin = "Has the rights to manage subscription and payment methods, and download receipts",
    client = "Practice client seeing one or more clinicians",
    clientsupport = "Point of contact for clients",
}

export interface IUser {
    user_id: string;
    user_uid: string;
    practices: {
        practice_id: string;
        provider_id: string | null;
        practice_name: string;
        roles: UserRole[];
        permissions: UserPermisions[];
        is_supervisor: boolean;
    }[];
    user_email: string;
    user_contact_phone_no: string;
    first_name: string;
    last_name: string;
    other_name: string;
    preferred_name: string;
    pronouns: string;
    date_of_birth: string;
}
export interface IInternalUser {
    user_id: string;
    first_name: string;
    last_name: string;
    roles: string[];
    email: string;
    status: string;
}

export interface IUsersFilterTypes {
    page: number;
    search_string: string;
    role?: string;
    status?: string;
    limit: number;
    sort_attribute?: string;
    sort_order?: string;
}

export interface INewUser {
    email: string;
    first_name: string;
    last_name: string;
    roles: string[];
}

export interface INewUsers {
    users: INewUser[];
}

export interface IChangeStatus {
    user_ids: string[];
    status: InternalUsersStatus;
}

export interface IModifyRoles {
    user_ids: string[];
    roles: string[];
}

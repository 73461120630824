import * as React from "react";
import { useLocation, useNavigationType } from "react-router-dom";

function ScrollToTop() {
    const { pathname } = useLocation();
    const navType = useNavigationType();

    React.useEffect(() => {
        const mainAppArea = document.querySelector("#main") as HTMLElement;

        /** Only scroll to top when the navigation type is not "POP"
         *  see https://reactrouter.com/en/main/hooks/use-navigation-type
         */
        if (navType !== "POP") {
            mainAppArea.scrollTo(0, 0);
        }
    }, [navType, pathname]);

    return null;
}
export default ScrollToTop;

export default function InboxIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path
                d="M11.5 5.75L9 8.25L6.5 5.75"
                stroke="#0B132B"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9 8.25V2.75"
                stroke="#0B132B"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.214 9.75H11.75V10.75C11.75 11.302 11.302 11.75 10.75 11.75H7.24999C6.69799 11.75 6.24999 11.302 6.24999 10.75V9.75H1.78699"
                stroke="#0B132B"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 2.75H12.137C12.959 2.75 13.698 3.253 13.999 4.019L16.112 9.398C16.204 9.631 16.25 9.879 16.25 10.129V13.25C16.25 14.355 15.355 15.25 14.25 15.25H3.75C2.645 15.25 1.75 14.355 1.75 13.25V10.129C1.75 9.879 1.797 9.631 1.888 9.398L4.001 4.019C4.302 3.254 5.04 2.75 5.863 2.75H6"
                stroke="#0B132B"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

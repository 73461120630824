import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    IPaginatedApiResponse,
    IApiResponse,
} from "../../../../utils/types/api-response";
import {
    getStatementOfAccount,
    downloadStatementOfAccount,
    fetchProviderSessionActivityReport,
    exportProviderSessionActivityReport,
} from "../../services/reports.services";
import {
    IAgingReportResponse,
    ISessionActivityFilters,
    ISessionActivityResponse,
    IStatementOfAccountFilters,
    IStatementOfAccountPreviewResponse,
} from "../../types/reports.types";
import { handleRemoveEmptyParamFromQueryParams } from "../../../../utils/helpers";
import { IBillingAdminDashboardFilters } from "../../../../utils/types/billing";
import { fetchBillingAdminDashboardList } from "../../../../services/billing";

// Hook to fetch clients statement of account
export function useFetchStatementOfAccount(
    filters: IStatementOfAccountFilters,
    isEnabled: boolean
) {
    return useQuery<
        IPaginatedApiResponse<IStatementOfAccountPreviewResponse[]>,
        AxiosError<IPaginatedApiResponse<IStatementOfAccountPreviewResponse[]>>
    >({
        queryKey: ["statement-of-account", filters],
        queryFn: () => getStatementOfAccount(filters),
        enabled: isEnabled,
    });
}

export function useGetDownloadJobId(
    filters: IStatementOfAccountFilters,
    isEnabled: boolean
) {
    interface IDownloadResponseData {
        export_job_id: string;
    }
    return useQuery<
        IApiResponse<IDownloadResponseData>,
        AxiosError<IApiResponse<IDownloadResponseData>>
    >({
        queryKey: ["statement-of-account-download-jobId", filters],
        queryFn: () => getStatementOfAccount(filters),
        enabled: isEnabled,
    });
}

export function useDownloadStatementOfAccount(
    pdfType: string,
    jobId: string,
    isEnabled: boolean
) {
    return useQuery<void, AxiosError<string>>({
        queryKey: ["download-statement-of-account", jobId],
        queryFn: () => downloadStatementOfAccount(pdfType, jobId),
        enabled: isEnabled,
    });
}

export function useFetchProviderSessionActivityReport(
    filters: ISessionActivityFilters
) {
    return useQuery<
        IApiResponse<ISessionActivityResponse>,
        AxiosError<IApiResponse<ISessionActivityResponse[]>>
    >({
        queryKey: ["provider-session-activity", filters],
        queryFn: () =>
            fetchProviderSessionActivityReport(
                handleRemoveEmptyParamFromQueryParams(filters)
            ),
    });
}

export function useExportProviderSessionActivityReport(
    filters: ISessionActivityFilters,
    isEnabled: boolean
) {
    return useQuery<Blob, AxiosError<string>>({
        queryKey: [
            "provider-session-activity-report",
            handleRemoveEmptyParamFromQueryParams(filters),
        ],
        queryFn: () =>
            exportProviderSessionActivityReport(
                handleRemoveEmptyParamFromQueryParams(filters)
            ),
        enabled: isEnabled,
        onSuccess: (data) => {
            const href = window.URL.createObjectURL(data);

            const anchorElement = document.createElement("a");
            anchorElement.href = href;

            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        },
    });
}

export function useFetchAgingReport(
    filters: IBillingAdminDashboardFilters,
    enabled: boolean
) {
    return useQuery<
        IPaginatedApiResponse<IAgingReportResponse[]>,
        AxiosError<IPaginatedApiResponse<IAgingReportResponse[]>>
    >({
        queryKey: [
            "billing-aging-report",
            handleRemoveEmptyParamFromQueryParams(filters),
        ],
        queryFn: () => fetchBillingAdminDashboardList(filters),
        enabled,
    });
}

import * as React from "react";
import { onValue, query, ref, off, DataSnapshot } from "firebase/database";
import { rtdb } from "../firebase";
import { IThirtydaysProvider } from "../modules/thirtydays-providers/types/thirtydays-providers";

export default function useThirtydaysProviders() {
    const [providers, setProviders] = React.useState<IThirtydaysProvider[]>([]);

    React.useEffect(() => {
        const providersRef = query(ref(rtdb, "/thirtydaysproviders"));

        const handleValueChange = (snapShot: DataSnapshot) => {
            const firebaseProviders = snapShot.val() as Record<
                string,
                Omit<IThirtydaysProvider, "id">
            > | null;
            if (firebaseProviders) {
                const newProviders: IThirtydaysProvider[] = Object.entries(
                    firebaseProviders
                ).map(
                    ([id, data]): IThirtydaysProvider => ({
                        ...data,
                        id,
                    })
                );

                setProviders(newProviders);
            } else {
                setProviders([]);
            }
        };

        onValue(providersRef, handleValueChange);

        return () => {
            off(providersRef, "value", handleValueChange);
        };
    }, []);

    return { providers };
}

import * as React from "react";
import { Button, Card, Dialog, DialogContent } from "@jhool-io/fe-components";
import { useIsMutating } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar";
import { useFetchUserDetails } from "../../../hooks/queries/user";
import PendingTasksWrapper from "../components/PendingTasksWrapper/PendingTasksWrapper";
import AddTask from "../../tasks/components/AddTask/AddTask";
import { useFetchTasks } from "../../../hooks/queries/tasks";
import {
    TaskCategory,
    TaskListSortAttribute,
    TaskStatus,
} from "../../../utils/types/tasks";
import { AppSortOrder } from "../../../utils/types";

export default function OtherUsersDashboard() {
    const [showAddTaskDialog, setShowAddTaskDialog] = React.useState(false);

    const [searchParams] = useSearchParams();

    const tabInUrl = searchParams.get("task_tab") || "my_tasks";

    const loggedInUser = useFetchUserDetails();

    const addTodoIsMutating = useIsMutating(["add-todo"]);

    const { data } = useFetchTasks({
        status: [TaskStatus.IN_PROGRESS, TaskStatus.NOT_STARTED],
        assignee_user_id: loggedInUser.data?.user_id || "",
        sort_attribute: TaskListSortAttribute.DUE_DATE,
        sort_order: AppSortOrder.ASC,
        category:
            tabInUrl === "supervisee_tasks"
                ? [
                      TaskCategory.REVIEW_SUPERVISEE_ADDENDUM,
                      TaskCategory.REVIEW_SUPERVISEE_NOTE,
                  ]
                : [
                      TaskCategory.WRITE_SESSION_NOTE,
                      TaskCategory.MAKE_REQUESTED_CHANGE,
                      TaskCategory.USER_CREATED,
                      TaskCategory.WRITE_FOLLOW_UP_SAFETY_NOTE,
                  ],
    });

    return (
        <>
            <Dialog open={showAddTaskDialog}>
                <DialogContent
                    handleCloseDialog={() => setShowAddTaskDialog(false)}
                    title="Add task"
                    saveText="Add Task"
                    isSubmitBtnDisabled={addTodoIsMutating > 0}
                    isCancelBtnDisabled={addTodoIsMutating > 0}
                    submitBtnFormValue="add-todo"
                    variant="center"
                >
                    <AddTask onFormSubmit={() => setShowAddTaskDialog(false)} />
                </DialogContent>
            </Dialog>
            <Navbar />
            <div className="page">
                <div className="flex flex-col items-start justify-start sm:flex-row sm:justify-between sm:items-center mb-24 gap-y-20">
                    <div className="flex flex-col gap-y-8">
                        <p className="text-xl font-medium capitalize">
                            👋🏽 Welcome {loggedInUser?.data?.first_name},
                        </p>
                        <p className="text-sm">
                            {data?.data.length === 0 ? (
                                `You have no `
                            ) : (
                                <>
                                    You have
                                    <span className="text-lg font-semibold inline-block mx-[2px]">
                                        {data?.total_count}
                                    </span>
                                </>
                            )}
                            pending{" "}
                            {data?.data.length === 0 || data?.data.length === 1
                                ? `task `
                                : `tasks `}
                            requiring your attention
                        </p>
                    </div>

                    <Button
                        onClick={() => setShowAddTaskDialog(true)}
                        className="w-full min-[400px]:w-auto"
                    >
                        Add Task
                    </Button>
                </div>
                <Card classNames="bg-white h-auto border border-strokedark px-24 py-32">
                    <PendingTasksWrapper />
                </Card>
            </div>
        </>
    );
}

import * as React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Tag,
} from "@jhool-io/fe-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import styles from "./CaseloadView.module.scss";
import { ProviderCaseloadApp } from "../../../types/schedule.types";
import { getColorStylesForCalendarId } from "../../../../../utils/constants";
import { useCancelSession } from "../../../../tasks/hooks/tasks.mutations";
import useShowInsuranceOverSelfPay from "../../../../practice-settings/hooks/useShowInsuranceOverSelfPay";
import useToast from "../../../../../hooks/useToast";
import {
    convertTimeTo12HoursFormat,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../../utils/helpers";
import MoreIcon from "../../../../../components/Icons/More";
import usePractice from "../../../../../hooks/usePractice";
import { useDebounce } from "../../../../../hooks/helpers";
import { sortCaseloads } from "../../../helpers/schedule.helpers";
import moment from "../../../../../utils/moment";
import Skeleton from "../../../../../components/Skeleton/Skeleton";
import ListState from "../../../../../components/ListState/ListState";
import { useFetchProvidersCaseload } from "../../../../providers/hooks/providers.queries";

// single event view

function SingleSchedule({ schedule }: { schedule: ProviderCaseloadApp }) {
    const { recurrence_rule: recurrenceRule } = schedule;
    const colorStyles = getColorStylesForCalendarId(recurrenceRule as string);
    const style = {
        background: colorStyles.backgroundColor,
        borderLeft: colorStyles.color,
    };

    const [showModal, setShowModal] = React.useState(false);
    const [sessionHistoryId, setSessionHistoryId] = React.useState<
        string | null
    >(null);

    const cancelSession = useCancelSession();

    const showInsOverSelfPay = useShowInsuranceOverSelfPay();

    // Query client
    const queryClient = useQueryClient();

    const { toast } = useToast();

    const navigate = useNavigate();

    const handleCancelSession = (shouldNavigate: boolean) => {
        if (!sessionHistoryId) {
            toast({
                mode: "error",
                message: "Could not cancel session at this time",
            });
        } else {
            const payload = {
                session_history_ids: [sessionHistoryId],
            };

            cancelSession.mutate(payload, {
                onSuccess: () => {
                    queryClient.invalidateQueries({
                        queryKey: ["calendar", schedule.client_id || ""],
                    });
                    toast({
                        mode: "success",
                        message: "Session cancelled successfully!",
                    });
                    if (shouldNavigate) {
                        navigate(`/add-note?note_type=cancellation_note`);
                    }
                    setShowModal(false);
                    setSessionHistoryId(null);
                },
                onError: () => {
                    toast({
                        mode: "error",
                        message: "Could not cancel session",
                    });
                },
            });
        }
    };

    return (
        <div
            className={styles["schedule-item"]}
            style={{ background: style.background }}
        >
            <Dialog open={showModal}>
                <DialogContent
                    title="Cancel session"
                    variant="center"
                    onSaveClick={() => handleCancelSession(true)}
                    handleCloseDialog={() => setShowModal(false)}
                    isCancelBtnDisabled={cancelSession.isLoading}
                    isSubmitBtnDisabled={cancelSession.isLoading}
                    classNames="pt-24 pb-48"
                    onCancelClick={() => handleCancelSession(false)}
                    cancelText="Yes,cancel"
                    saveText="No,create No show note"
                >
                    <div>
                        <h3 className="font-medium text-xl mb-8">
                            Report session as Cancelled
                        </h3>
                        <p className="text-gray font-normal text-base">
                            Did the client cancel the session at least 24 hours
                            before the start time
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
            <div
                style={{
                    backgroundColor: `${style.borderLeft}`,
                }}
                className="absolute -left-[1.5px] w-[2px] h-full top-0"
            />
            <div className="rbc-display_name">
                {`${schedule.first_name} ${schedule.last_name}`}
            </div>
            <div className="rbc-requested-service">
                {makeStringFirstLetterCapital(
                    removeEnumUnderscore(schedule.session_type)
                )}
            </div>
            {/* {schedule.insurance_provider || schedule.self_pay ? (
                <div className="text-black my-4 font-medium">
                    {schedule.insurance_provider
                        ? makeStringFirstLetterCapital(
                              removeEnumUnderscore(schedule.insurance_provider)
                          )
                        : schedule.self_pay || ""}
                </div>
            ) : null} */}
            <div className="rbc-wrapper">
                <Tag
                    bgColor={
                        getColorStylesForCalendarId(
                            schedule.recurrence_rule as string
                        ).tagBgColor
                    }
                    textColor={
                        getColorStylesForCalendarId(
                            schedule.recurrence_rule as string
                        ).textColor
                    }
                    title={schedule.recurrence_rule as string}
                    className="text-[8px] py-4 min-h-0"
                />
                {schedule.pending_task && (
                    <Tag
                        bgColor="#FFDAD9"
                        textColor="#981F41"
                        title="Pending Task"
                        className="text-[8px] py-4 min-h-0"
                    />
                )}
                {schedule.insurance_provider ||
                (schedule.self_pay && !showInsOverSelfPay) ? (
                    <Tag
                        bgColor="#ACECF5"
                        textColor="#1A738D"
                        title={
                            schedule.insurance_provider
                                ? removeEnumUnderscore(
                                      schedule.insurance_provider
                                  )
                                : "SELF PAY"
                        }
                        className="text-[8px] uppercase py-4 min-h-0"
                    />
                ) : null}
            </div>
            <div className="flex justify-between mt-auto">
                <div
                    className="flex items-center"
                    style={{ fontSize: "0.8rem" }}
                >
                    {`${convertTimeTo12HoursFormat(
                        `${schedule.session_start_hr}:${schedule.session_start_min}`
                    )} - ${convertTimeTo12HoursFormat(
                        `${schedule.session_end_hr}:${schedule.session_end_min}`
                    )}`}
                </div>
                {schedule.session_history_id && (
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <Button
                                size="auto"
                                variant="normal"
                                className="w-16 h-16 rounded-[16px] shadow-morebtn relative z-[1] data-[state=open]:border-primary-800"
                            >
                                <MoreIcon />
                            </Button>
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            {/* <DropdownItem>Confirm follow-up</DropdownItem>
                            <DropdownItem>
                                <div className="fg two flex">
                                    Pause/unpause client <ToggleSwitch />
                                </div>
                            </DropdownItem>
                            <DropdownItem>Change appointment</DropdownItem>
                            <DropdownItem>Mark as float</DropdownItem>
                            <DropdownItem>Remove from caseload</DropdownItem> */}
                            <DropdownItem
                                className="text-xs"
                                onClick={() => {
                                    setShowModal(true);
                                    setSessionHistoryId(
                                        schedule.session_history_id || ""
                                    );
                                }}
                            >
                                Cancel session
                            </DropdownItem>
                        </DropdownContent>
                    </Dropdown>
                )}
            </div>
        </div>
    );
}

export default function DashboardCaseloadView() {
    const [searchParams] = useSearchParams();

    const { practice } = usePractice();

    const clientFilter = searchParams.get("search_string") || "";
    const filter = searchParams.get("filter");

    const debouncedClientFilter = useDebounce(clientFilter);

    const { isLoading, error, data, isSuccess } = useFetchProvidersCaseload({
        providerId: practice?.provider_id || "",
        enabled: Boolean(practice?.provider_id),
        search_string: debouncedClientFilter,
        show_paused_client: "false",
        show_clients_with_pending_tasks:
            filter === "clients_with_pending_task" ? "true" : "",
    });

    let providerSchedule: ProviderCaseloadApp[] = [];

    data?.data?.forEach((caseload) => {
        const dataFromAppointments =
            caseload.appointments &&
            caseload.appointments.map((app) => ({
                session_type: app.session_type,
                recurrence_rule: app.recurrence_rule,
                session_day: app.session_day,
                session_start_hr: app.session_start_hr,
                session_start_min: app.session_start_min,
                session_end_hr: app.session_end_hr,
                session_end_min: app.session_end_min,
                first_name: caseload.client.first_name,
                last_name: caseload.client.last_name,
                pending_task: caseload.client.pending_task,
                is_paused: caseload.client.is_paused,
                self_pay: caseload.client.self_pay || false,
                insurance_provider:
                    caseload.client.insurance_provider_name || "",
                session_history_id: app.session_history_id,
                client_id: caseload.client.client_id,
            }));

        providerSchedule = providerSchedule.concat(dataFromAppointments);
    });

    const allSchedulesByDay: { [key: string]: ProviderCaseloadApp[] } = {
        sun: [],
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
    };

    // Add schedules for the rest of the month.
    providerSchedule?.forEach((s) => {
        try {
            allSchedulesByDay[s.session_day.toLowerCase()].push(s);
        } catch (e) {
            return [];
        }
        return null;
    });

    const getScheduleListGroupByWeekday = (weekDay: string) => {
        return (
            <div className={styles["schedule-grid-container"]}>
                {sortCaseloads(allSchedulesByDay[weekDay]).map((s) => {
                    return (
                        <div
                            key={`${s.session_start_hr} - ${s.session_start_min}`}
                            className="shadow-none"
                        >
                            <SingleSchedule schedule={s} />
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <>
            {isLoading && (
                <div className="pb-[72px]">
                    <Skeleton width="100%" height="200px" />
                </div>
            )}
            {error && (
                <ListState
                    isError
                    errorMsg={
                        error.response?.data.message ||
                        "Could not load user caseload at this time"
                    }
                    stateHelperText="Try reloading this page to solve this issue"
                    context="general"
                    cardClass="h-[200px]"
                />
            )}
            {data?.data &&
                isSuccess &&
                providerSchedule &&
                providerSchedule.length === 0 && (
                    <ListState
                        stateHelperText="Your caseload will appear here when available"
                        emptyMessage="No caseload to display"
                        context="general"
                        isError={false}
                        cardClass="h-[200px]"
                    />
                )}
            {data?.data &&
                isSuccess &&
                providerSchedule &&
                providerSchedule.length > 0 && (
                    <div className={styles["schedule-list-view"]}>
                        {Object.keys(allSchedulesByDay).map((day, dayIndex) => (
                            <div
                                key={`${dayIndex + 1}`}
                                className={styles["schedule-day"]}
                            >
                                <h5>{moment().day(dayIndex).format("dddd")}</h5>
                                {getScheduleListGroupByWeekday(day)}
                            </div>
                        ))}
                    </div>
                )}
        </>
    );
}

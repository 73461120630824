import { SvgProps } from "../../utils/types";

export default function SendIcon({ stroke }: SvgProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M12.666 3.33301L3.33268 12.6663"
                stroke={stroke || "#576A81"}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.666 10.1797V3.33301H5.81935"
                stroke={stroke || "#576A81"}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

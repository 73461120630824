import * as React from "react";
import { Button, Dialog, DialogContent } from "@jhool-io/fe-components";
import { useParams } from "react-router-dom";
import { useIsMutating } from "@tanstack/react-query";
import { useFetchClientFees } from "../../hooks/clients.queries";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import ClientSingleFee from "./ClientSingleFee/ClientSingleFee";
import EditClientFeeForm from "./EditClientFeeForm/EditClientFeeForm";

export default function ClientFeesList() {
    const [showEditDialog, setShowEditDialog] = React.useState(false);

    const { clientId } = useParams();

    const { isLoading, error, isSuccess, data } = useFetchClientFees(
        clientId || "",
        200
    );

    const isEditFeeMutating = useIsMutating(["edit-fee"]);

    return (
        <>
            {data?.data && data.data.length > 0 ? (
                <Dialog open={showEditDialog}>
                    <DialogContent
                        variant="center"
                        title="Edit fee"
                        classNames="!px-0 !pt-0"
                        wrapperClass="w-[790px]"
                        handleCloseDialog={() => setShowEditDialog(false)}
                        submitBtnFormValue="edit-fees"
                        isSubmitBtnDisabled={isEditFeeMutating > 0}
                        isCancelBtnDisabled={isEditFeeMutating > 0}
                    >
                        <EditClientFeeForm
                            onFormSubmit={() => setShowEditDialog(false)}
                            fee={data.data[0]}
                        />
                    </DialogContent>
                </Dialog>
            ) : null}
            <div>
                <div className="rounded-r8 bg-white border border-stroke-divider p-16 flex justify-between gap-x-8 items-center mb-24">
                    <h3 className="text-base font-bold leading-normal">
                        FEES HISTORY
                    </h3>
                    {data?.data && data.data.length > 0 ? (
                        <div>
                            <Button
                                disabled={isLoading || Boolean(error)}
                                onClick={() => setShowEditDialog(true)}
                            >
                                Edit fees
                            </Button>
                        </div>
                    ) : null}
                </div>
                {isLoading ? (
                    <div className="flex flex-col gap-y-16">
                        {[1, 2, 3, 4].map((item, i) => (
                            <Skeleton
                                width="100%"
                                height={i === 0 ? "400px" : "76px"}
                                key={item}
                                className="rounded-r8 border border-stroke-divider"
                            />
                        ))}
                    </div>
                ) : null}
                {error ? (
                    <ListState
                        isError
                        errorMsg={
                            error.response?.data.message ||
                            "Error fetching client fees"
                        }
                        context="general"
                        stateHelperText="Cannot display client fees at this time, please try again later"
                    />
                ) : null}
                {isSuccess && data?.data && data.data.length === 0 ? (
                    <ListState
                        isError={false}
                        emptyMessage="No client fee to display"
                        stateHelperText="Add fee for this client to start get started"
                    />
                ) : null}
                {isSuccess && data?.data && data.data.length > 0 ? (
                    <div className="max-w-full overflow-x-auto">
                        <div className="flex flex-col gap-y-16">
                            {data.data.map((fee, i) => (
                                <ClientSingleFee
                                    defaultIsMaximized={i === 0}
                                    key={fee.fee_id}
                                    fee={fee}
                                    isCurrentFee={i === 0}
                                />
                            ))}
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
}

import { Alert } from "@jhool-io/fe-components";
import { toast as reactHotToast } from "react-hot-toast";
import { cn } from "../utils/helpers";

interface ToastOptions {
    mode: "warning" | "error" | "success" | "loading" | "info";
    message: React.ReactNode;
    duration?: number;
    title?: string;
    className?: string;
    position?:
        | "bottom-center"
        | "bottom-left"
        | "bottom-right"
        | "top-center"
        | "top-left"
        | "top-right";
}

export default function useToast() {
    const customToast = ({
        mode,
        message,
        duration = 2000,
        title,
        className,
        position = "top-right",
    }: ToastOptions) =>
        reactHotToast.custom(
            <Alert
                type={mode}
                description={message}
                title={title}
                classNames={cn("w-[509px] max-w-full", className)}
                onCloseButtonClick={() => reactHotToast.remove()}
                showCloseButton
            />,
            {
                position: position || "top-right",
                duration,
            }
        );

    return {
        toast: customToast,
    };
}

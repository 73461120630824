export default function HospitalIcon() {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.498047 11H11.498"
                stroke="#0B132B"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.89062 11.005V8.77499"
                stroke="#0B132B"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.89883 5.44501C9.28883 5.44501 8.79883 5.93501 8.79883 6.54501V7.67999C8.79883 8.28999 9.28883 8.78 9.89883 8.78C10.5088 8.78 10.9988 8.28999 10.9988 7.67999V6.54501C10.9988 5.93501 10.5088 5.44501 9.89883 5.44501Z"
                stroke="#0B132B"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.04883 11V3.01501C1.04883 2.01001 1.54885 1.505 2.54385 1.505H5.65884C6.65384 1.505 7.14883 2.01001 7.14883 3.01501V11"
                stroke="#0B132B"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.90039 4.125H5.3754"
                stroke="#0B132B"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.90039 6H5.3754"
                stroke="#0B132B"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.125 11V9.125"
                stroke="#0B132B"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

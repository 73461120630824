import { NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./SideNavLink.module.scss";
import Button from "../../Button/Button";

interface SidebarLinkProps {
    linkType: "a" | "button";
    href?: string;
    title: string;
    icon: React.ReactElement;
    dropdown?: React.ReactNode;
    onClick?: () => void;
    isAllowedToView: boolean;
    isExpanded?: boolean;
}

export default function SidebarLink({
    linkType,
    href,
    title,
    icon,
    dropdown,
    onClick,
    isAllowedToView,
    isExpanded,
}: SidebarLinkProps) {
    const location = useLocation();

    const shouldIconStyleBeDifferent =
        location.pathname.includes("task") ||
        location.pathname.includes("audit-log") ||
        location.pathname.includes("my-schedule") ||
        location.pathname.includes("notes") ||
        location.pathname.includes("reports") ||
        location.pathname.includes("practices") ||
        location.pathname.includes("tools");

    if (!isAllowedToView) {
        return null;
    }

    if (linkType === "button") {
        return (
            <li
                className={cn(styles.list_item, styles.list_item_btn, {
                    [styles.list_item_btn_active]: isExpanded,
                })}
            >
                <Button
                    type="button"
                    size="auto"
                    mode="transparent"
                    onClick={onClick}
                    className={cn(styles.list_link, styles.list_button, {
                        [styles.list_link_dactive]:
                            isExpanded && !shouldIconStyleBeDifferent,
                        [styles.list_link_dactiveother]:
                            shouldIconStyleBeDifferent && isExpanded,
                    })}
                >
                    {icon}
                    {title}
                    <span
                        className={cn(styles.eicon, {
                            [styles.eicon_other]:
                                isExpanded && !shouldIconStyleBeDifferent,
                        })}
                    >
                        {isExpanded ? (
                            <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.69687 6.25026L4.41354 3.53359C4.73437 3.21276 5.25937 3.21276 5.58021 3.53359L8.29687 6.25026"
                                    stroke="white"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        ) : (
                            <svg
                                width="8"
                                height="5"
                                viewBox="0 0 8 5"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.30313 0.749739L4.58646 3.46641C4.26563 3.78724 3.74063 3.78724 3.41979 3.46641L0.703125 0.749739"
                                    stroke="#576A81"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        )}
                    </span>
                </Button>
                {isExpanded && (
                    <span className={styles.list_span}> {dropdown}</span>
                )}
            </li>
        );
    }

    return (
        <li className={styles.list_item}>
            <NavLink
                end
                to={href as string}
                className={({ isActive }) => {
                    return isActive
                        ? cn(`${styles.list_link}`, {
                              [styles.list_link_activeother]:
                                  shouldIconStyleBeDifferent,
                              [styles.list_link_active]:
                                  !shouldIconStyleBeDifferent,
                          })
                        : styles.list_link;
                }}
                onClick={onClick}
            >
                {icon}
                {title}
            </NavLink>
        </li>
    );
}

import { SvgProps } from "../../utils/types";

export default function ChevronDownIcon({ stroke, className }: SvgProps) {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M2.13715 5.0625L6.99826 9.92361L11.8594 5.0625"
                stroke={stroke || "white"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

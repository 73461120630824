import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IApiResponse } from "../../../utils/types/api-response";
import {
    IAddUserRestrictionPayload,
    IDeleteRestrictedUserPayload,
} from "../types/client-chart-restriction.types";
import {
    addUserRestriction,
    deleteUserRestriction,
} from "../services/client-chart-restriction.services";

export function useDeleteUserRestrictions() {
    return useMutation<
        IApiResponse<null>,
        AxiosError<IApiResponse<null>>,
        IDeleteRestrictedUserPayload[]
    >({
        mutationKey: ["delete-restrictions"],
        mutationFn: (restrictions) => deleteUserRestriction(restrictions),
    });
}

export function useAddUserRestriction() {
    interface ApiResponseData {
        user_id: string;
        client_ids: string[];
    }

    return useMutation<
        IApiResponse<ApiResponseData>,
        AxiosError<IApiResponse<ApiResponseData>>,
        IAddUserRestrictionPayload
    >({
        mutationKey: ["restrict-access"],
        mutationFn: (payload: IAddUserRestrictionPayload) =>
            addUserRestriction(payload),
    });
}

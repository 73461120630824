import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    IPaginatedApiResponse,
    IApiResponse,
} from "../../../utils/types/api-response";
import {
    fetchCardTransactionHistory,
    fetchClientCreditCards,
    fetchClientDocuments,
    fetchClientInsuranceHistory,
    fetchClientNotes,
    fetchClientMemos,
    fetchClientProviderHistory,
    fetchInsuranceProviders,
    fetchClientFees,
} from "../services/clients.services";
import {
    ICardFilterTypes,
    ICardHistory,
    IClientCreditCardDetails,
    IClientDocument,
    IClientDocumentsListFilters,
    IClientInsuranceDetails,
    IClientInsuranceProvider,
    IClientNote,
    IClientNotesListFilterTypes,
    IClientMemo,
    IClientMemoListFilterTypes,
    IClientProviderHistory,
    IClientProviderHistoryListFilters,
    IClientFee,
} from "../types/clients.types";
import { UserPermisions } from "../../../utils/types/user";
import { useDoesUserHavePermission } from "../../../hooks/permissions";

// Fetch card transaction history
export function useFetchCardTransactionHistory(
    clientId: string,
    paymentId: string,
    filters: ICardFilterTypes
) {
    return useQuery<
        IPaginatedApiResponse<ICardHistory[]>,
        AxiosError<IApiResponse<ICardHistory[]>>
    >({
        queryKey: [
            "card_history",
            handleRemoveEmptyParamFromQueryParams(filters),
            paymentId,
        ],
        queryFn: () =>
            fetchCardTransactionHistory(
                clientId,
                paymentId,
                handleRemoveEmptyParamFromQueryParams(filters)
            ),
    });
}

// Fetch client credit cards list hook
export function useFetchClientCreditCards(clientId: string) {
    return useQuery<
        IPaginatedApiResponse<IClientCreditCardDetails[]>,
        AxiosError<IApiResponse<IClientCreditCardDetails[]>>
    >({
        queryKey: [clientId, "cards"],
        queryFn: () => fetchClientCreditCards(clientId),
    });
}

// Fetch client documents list hook
export function useFetchDocuments(filters: IClientDocumentsListFilters) {
    return useQuery<
        IPaginatedApiResponse<IClientDocument[]>,
        AxiosError<IApiResponse<IClientDocument[]>>
    >({
        queryKey: [filters.client_id, `documents`, filters],
        queryFn: () => fetchClientDocuments(filters),
    });
}

// Fetch insurance providers list
export function useFetchInsuranceProviders() {
    const canViewInsuranceProvidersList = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_INSURANCE_READ,
        UserPermisions.CLIENT_INFO_INSURANCE_WRITE,
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
    ]);

    return useQuery<
        IApiResponse<IClientInsuranceProvider[]>,
        AxiosError<IApiResponse<IClientInsuranceProvider>>
    >({
        queryKey: ["insurance-providers"],
        queryFn: fetchInsuranceProviders,
        enabled: canViewInsuranceProvidersList,
    });
}

// Fetch client insurance history hook
export function useFetchClientInsuranceHistory(
    clientId: string,
    page: number,
    limit: number,
    enabled?: boolean
) {
    return useQuery<
        IPaginatedApiResponse<IClientInsuranceDetails[]>,
        AxiosError<IApiResponse<IClientInsuranceDetails[]>>
    >({
        queryKey: [clientId, `insurance`, page, limit],
        queryFn: () => fetchClientInsuranceHistory(clientId, page, limit),
        enabled,
    });
}

export function useFetchClientProviderHistory(
    clientId: string,
    filters: IClientProviderHistoryListFilters
) {
    return useQuery<
        IPaginatedApiResponse<IClientProviderHistory[]>,
        AxiosError<IApiResponse<IClientProviderHistory[]>>
    >({
        queryKey: [
            clientId,
            "provider-history",
            handleRemoveEmptyParamFromQueryParams({
                limit: filters.limit,
                page: filters.page,
            }),
        ],
        queryFn: () => fetchClientProviderHistory(clientId, filters),
    });
}

// Fetch client notes list hook
export function useFetchClientNotes(
    clientId: string,
    filters: IClientNotesListFilterTypes
) {
    return useQuery<
        IPaginatedApiResponse<IClientNote[]>,
        AxiosError<IApiResponse<IClientNote[]>>
    >({
        queryKey: [
            clientId,
            "notes",
            handleRemoveEmptyParamFromQueryParams(filters),
        ],
        queryFn: () => fetchClientNotes(clientId, filters),
    });
}

export function useFetchClientMemos(
    clientId: string,
    filters: IClientMemoListFilterTypes
) {
    return useQuery<
        IPaginatedApiResponse<IClientMemo[]>,
        AxiosError<IApiResponse<IClientMemo[]>>
    >({
        queryKey: [
            clientId,
            `memos`,
            handleRemoveEmptyParamFromQueryParams(filters),
        ],
        queryFn: () => fetchClientMemos(clientId, filters),
    });
}

export function useFetchClientFees(
    clientId: string,
    limit: number,
    enabled?: boolean
) {
    return useQuery<
        IPaginatedApiResponse<IClientFee[]>,
        AxiosError<IApiResponse<IClientFee[]>>
    >({
        queryKey: [clientId, `fees`, limit],
        queryFn: () => fetchClientFees(clientId, limit),
        enabled,
    });
}

import * as React from "react";
import {
    Button,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SearchInput,
} from "@jhool-io/fe-components";
import { Link, useNavigate } from "react-router-dom";
import SearchIcon from "../../../../components/Icons/SearchIcon";
import ListState from "../../../../components/ListState/ListState";
import TimesIcon from "../../../../components/Icons/Times";
import { useDebounce } from "../../../../hooks/helpers";
import { useFetchClientList } from "../../../../hooks/queries/client";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import { IClient } from "../../../../utils/types/client";
import { cn } from "../../../../utils/helpers";
import { useAppSelector } from "../../../../hooks/useRedux";

interface ClientSearchHistory {
    client_name: string;
    client_id: string;
}

export default function SearchClients() {
    const [showPopover, setShowPopover] = React.useState(false);
    const [searchInputValue, setSearchInputValue] = React.useState("");

    const { practice } = useAppSelector((state) => state.userPractice);

    const navigate = useNavigate();

    const debouncedSearchInputValue = useDebounce(searchInputValue);

    const { data, isLoading, error, isSuccess } = useFetchClientList(
        {
            search_string: debouncedSearchInputValue,
            limit: 20,
            provider_id: practice?.provider_id || "",
        },
        Boolean(searchInputValue.length > 2)
    );

    const clientsSearchHistoryLS = localStorage.getItem(
        `clients_search_history_${practice?.practice_id}`
    );

    const parsedClientSearchHistory: ClientSearchHistory[] =
        clientsSearchHistoryLS ? JSON.parse(clientsSearchHistoryLS) : [];

    const [searchHistory, setSearchHistory] = React.useState(
        () => parsedClientSearchHistory
    );

    const handleClientClick = (client: IClient) => {
        const newEntry: ClientSearchHistory = {
            client_name: `${client.first_name} ${client.last_name}`,
            client_id: client.client_id,
        };

        // Remove client if it is already present in the array
        const filteredParsedClientSearchHistory = searchHistory.filter(
            (cl) => cl.client_id !== newEntry.client_id
        );

        const newHistoryArray = [
            newEntry,
            ...filteredParsedClientSearchHistory,
        ].slice(0, 5);

        localStorage.setItem(
            `clients_search_history_${practice?.practice_id}`,
            JSON.stringify(newHistoryArray)
        );
        setSearchHistory(newHistoryArray);

        setShowPopover(false);

        navigate(`/clients/${client.client_id}`);
    };

    const handleRemoveClientFromHistory = (clientId: string) => {
        const updatedList = searchHistory.filter(
            (cl) => cl.client_id !== clientId
        );

        localStorage.setItem(
            `clients_search_history_${practice?.practice_id}`,
            JSON.stringify(updatedList)
        );
        setSearchHistory(updatedList);
    };

    const isScreen950Above = window.matchMedia("(min-width: 950px)").matches;

    return (
        <Popover open={showPopover} onOpenChange={setShowPopover}>
            <PopoverTrigger asChild>
                <Button
                    size="auto"
                    variant="normal"
                    className="flex w-auto min-[950px]:w-[40rem] rounded-r8 bg-white shadow-row h-32 justify-start px-4 min-[950px]:px-16 items-center gap-x-8"
                    onClick={() => setShowPopover(true)}
                    aria-label="search-btn"
                >
                    <SearchIcon />
                    <span className="text-gray hidden min-[950px]:inline text-sm font-normal">
                        Search by client name, chart number
                    </span>
                </Button>
            </PopoverTrigger>
            <PopoverContent
                className={cn(
                    "w-[32rem] max-w-full -mt-32 data-[state=open]:animate-[SlideTopIn_0.2s_ease-out] data-[state=closed]:animate-[SlideTopIn_0.2s_ease-out] max-h-[30rem] overflow-y-auto",
                    {
                        "popover-full": isScreen950Above,
                    }
                )}
                align={isScreen950Above ? "start" : "center"}
            >
                <SearchInput
                    placeholder=" Search by client name, chart number"
                    onChange={(e) => setSearchInputValue(e.target.value)}
                    value={searchInputValue}
                    containerClass="h-32 shrink-0"
                />
                <div className="py-8">
                    {searchInputValue.length > 2 && (
                        <div>
                            {isLoading && (
                                <div className="flex flex-col gap-y-8">
                                    <Skeleton width="100%" height="28px" />
                                    <Skeleton width="100%" height="28px" />
                                    <Skeleton width="100%" height="28px" />
                                    <Skeleton width="100%" height="28px" />
                                    <Skeleton width="100%" height="28px" />
                                </div>
                            )}
                            {error && (
                                <ListState
                                    isError
                                    errorMsg="Error fetching clients"
                                    stateHelperText="Please try again later"
                                    context="general"
                                />
                            )}
                            {data?.data && data.data.length === 0 && (
                                <ListState
                                    isError={false}
                                    emptyMessage="No client(s) found"
                                    stateHelperText="Please enter search word"
                                    context="general"
                                />
                            )}
                            {data?.data && isSuccess && (
                                <div className="flex flex-col gap-y-4">
                                    {data.data.map((client) => (
                                        <Button
                                            key={client.client_id}
                                            variant="normal"
                                            size="auto"
                                            className="py-4 font-medium capitalize px-[6px] text-xs hover:bg-secondary-light w-full justify-start"
                                            onClick={() =>
                                                handleClientClick(client)
                                            }
                                        >{`${client.first_name} ${client.last_name}`}</Button>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    {searchInputValue.length <= 2 && (
                        <div>
                            {searchHistory.length > 0 ? (
                                <div>
                                    <span className="font-medium uppercase text-xs mb-8 block">
                                        Recent searches
                                    </span>
                                    <div className="flex flex-col gap-y-4">
                                        {searchHistory.map((client) => (
                                            <div
                                                key={client.client_id}
                                                className="flex items-center gap-x-4 justify-between capitalize py-4 text-xs"
                                            >
                                                <Link
                                                    to={`/clients/${client.client_id}`}
                                                >
                                                    {client.client_name}
                                                </Link>
                                                <Button
                                                    size="auto"
                                                    variant="normal"
                                                    onClick={() =>
                                                        handleRemoveClientFromHistory(
                                                            client.client_id
                                                        )
                                                    }
                                                >
                                                    <TimesIcon />
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <ListState
                                    isError={false}
                                    context="general"
                                    emptyMessage="No recent search"
                                    stateHelperText="Your search history will appear here"
                                />
                            )}
                        </div>
                    )}
                </div>
            </PopoverContent>
        </Popover>
    );
}

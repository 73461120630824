import {
    FormState,
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch,
} from "react-hook-form";
import * as React from "react";
import { Button, Input, RadioInput } from "@jhool-io/fe-components";
import { IClaimDetailsContent, SingleClaim } from "../../../types/claims.types";

interface DiagnosisInfoFormProps {
    setValue: UseFormSetValue<IClaimDetailsContent>;
    register: UseFormRegister<IClaimDetailsContent>;
    formState: FormState<IClaimDetailsContent>;
    watch: UseFormWatch<IClaimDetailsContent>;
    claimDetails: SingleClaim;
}

export default function DiagnosisInfoForm({
    setValue,
    claimDetails,
    register,
    watch,
    formState: { errors },
}: DiagnosisInfoFormProps) {
    const [hasLabCharge, setHasLabCharge] = React.useState(false);

    const diagnosisCodesCount =
        (watch("diagnosisCode1") ? 1 : 0) +
        (watch("diagnosisCode2") ? 1 : 0) +
        (watch("diagnosisCode3") ? 1 : 0) +
        (watch("diagnosisCode4") ? 1 : 0) +
        (watch("diagnosisCode5") ? 1 : 0) +
        (watch("diagnosisCode6") ? 1 : 0) +
        (watch("diagnosisCode7") ? 1 : 0) +
        (watch("diagnosisCode8") ? 1 : 0) +
        (watch("diagnosisCode9") ? 1 : 0) +
        (watch("diagnosisCode10") ? 1 : 0) +
        (watch("diagnosisCode11") ? 1 : 0) +
        (watch("diagnosisCode12") ? 1 : 0);

    React.useEffect(() => {
        const claimContent = claimDetails.claim_content;

        setValue("outsideLabCharge", claimContent.outsideLabCharge);

        setValue("diagnosisCode1", claimContent.diagnosisCode1);
        setValue("diagnosisCode2", claimContent.diagnosisCode2);
        setValue("diagnosisCode3", claimContent.diagnosisCode3);
        setValue("diagnosisCode4", claimContent.diagnosisCode4);
        setValue("diagnosisCode5", claimContent.diagnosisCode5);
        setValue("diagnosisCode6", claimContent.diagnosisCode6);
        setValue("diagnosisCode7", claimContent.diagnosisCode7);
        setValue("diagnosisCode8", claimContent.diagnosisCode8);
        setValue("diagnosisCode9", claimContent.diagnosisCode9);
        setValue("diagnosisCode10", claimContent.diagnosisCode10);
        setValue("diagnosisCode11", claimContent.diagnosisCode11);
        setValue("diagnosisCode12", claimContent.diagnosisCode12);

        setValue(
            "referringProviderFirstName",
            `${claimContent.referringProviderFirstName ?? ""}`
        );
        setValue(
            "referringProviderMiddleName",
            `${claimContent.referringProviderMiddleName ?? ""}`
        );
        setValue(
            "referringProviderLastName",
            `${claimContent.referringProviderLastName ?? ""}`
        );

        setValue(
            "referringProviderNPI",
            claimContent.referringProviderNPI ?? ""
        );
    }, [claimDetails, setValue]);

    // set hasLabCharge to true if outsideLabCharge is truthy
    React.useEffect(() => {
        const isOutsideLabCharge = watch("outsideLabCharge");

        if (isOutsideLabCharge) {
            setHasLabCharge(true);
        }
    }, [watch]);

    return (
        <div className="py-48 md:px-24 border-b border-b-strokedark">
            <h4 className="text-sm font-semibold mb-24 uppercase md:sticky top-20 z-[4] text-primary">
                Diagnosis Info
            </h4>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Outside Lab?
                    </h5>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <RadioInput
                        label="Yes"
                        wrapperClass="min-w-[120px]"
                        checked={hasLabCharge}
                        onChange={() => setHasLabCharge(true)}
                    />

                    <RadioInput
                        label="No"
                        wrapperClass="min-w-[120px]"
                        checked={!hasLabCharge}
                        onChange={() => setHasLabCharge(false)}
                    />

                    {hasLabCharge ? (
                        <div className="w-full">
                            <h6 className="text-gray text-xs font-medium mb-4">
                                Dollar charges ($)
                            </h6>
                            <div className="flex md:max-w-[40%] gap-12">
                                <Input
                                    placeholder="00"
                                    {...register("outsideLabCharge")}
                                    hasError={!!errors.outsideLabCharge}
                                    errorText={errors.outsideLabCharge?.message}
                                    showCurrency
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Referring Provider Name
                    </h5>
                </div>

                <div className="flex-[3]">
                    <div className=" flex gap-12 md:max-w-[80%]">
                        <Input
                            placeholder="Last Name"
                            {...register("referringProviderLastName")}
                            hasError={!!errors.referringProviderLastName}
                            errorText={
                                errors.referringProviderLastName?.message
                            }
                        />

                        <Input
                            placeholder="First Name"
                            {...register("referringProviderFirstName")}
                            hasError={!!errors.referringProviderFirstName}
                            errorText={
                                errors.referringProviderFirstName?.message
                            }
                        />

                        <Input
                            placeholder="Middle Initial"
                            {...register("referringProviderMiddleName")}
                            hasError={!!errors.referringProviderMiddleName}
                            errorText={
                                errors.referringProviderMiddleName?.message
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Referring Provider NPI
                    </h5>
                </div>

                <div className="flex-[3] flex flex-wrap gap-12">
                    <Input
                        placeholder="NPI"
                        className="md:max-w-[40%]"
                        {...register("referringProviderNPI")}
                        hasError={!!errors.referringProviderNPI}
                        errorText={errors.referringProviderNPI?.message}
                    />
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-12 md:gap-32 mb-24">
                <div className="flex-1">
                    <h5 className="text-xs font-semibold leading-tight mb-2">
                        Diagnosis or Nature of Illness or Injury
                    </h5>
                </div>

                <div className="flex-[3] ">
                    <div className="flex flex-wrap gap-12 md:max-w-[80%]">
                        <div className="w-full flex flex-col md:flex-row md:items-center gap-12">
                            <Input
                                placeholder="F41.1  (Anxiety Disorder Unspecified)"
                                {...register("diagnosisCode1")}
                                hasError={!!errors.diagnosisCode1}
                                errorText={errors.diagnosisCode1?.message}
                            />
                            <Button
                                variant="normal"
                                disabled
                                className="flex items-center gap-6 border border-strokedark text-xs 
        hover:bg-strokedark"
                            >
                                <span>-</span> Remove
                            </Button>
                        </div>

                        {/* code 2 */}
                        {watch("diagnosisCode2") ? (
                            <div className="w-full flex flex-col md:flex-row md:items-center gap-12">
                                <Input
                                    placeholder="F90.9  (ADHD Unspecified type)"
                                    {...register("diagnosisCode2")}
                                    hasError={!!errors.diagnosisCode2}
                                    errorText={errors.diagnosisCode2?.message}
                                />
                                <Button
                                    variant="normal"
                                    className="flex items-center gap-6 border border-strokedark text-xs 
        hover:bg-strokedark focus:bg-strokedark
        "
                                    onClick={() =>
                                        setValue("diagnosisCode2", "")
                                    }
                                >
                                    <span>-</span> Remove
                                </Button>
                            </div>
                        ) : null}

                        {/* code 3 */}
                        {watch("diagnosisCode3") ? (
                            <div className="w-full flex flex-col md:flex-row md:items-center gap-12">
                                <Input
                                    placeholder="F90.9  (ADHD Unspecified type)"
                                    {...register("diagnosisCode3")}
                                    hasError={!!errors.diagnosisCode3}
                                    errorText={errors.diagnosisCode3?.message}
                                />
                                <Button
                                    variant="normal"
                                    className="flex items-center gap-6 border border-strokedark text-xs 
        hover:bg-strokedark focus:bg-strokedark
        "
                                    onClick={() =>
                                        setValue("diagnosisCode3", "")
                                    }
                                >
                                    <span>-</span> Remove
                                </Button>
                            </div>
                        ) : null}

                        {/* code 4 */}
                        {watch("diagnosisCode4") ? (
                            <div className="w-full flex flex-col md:flex-row md:items-center gap-12">
                                <Input
                                    placeholder="F90.9  (ADHD Unspecified type)"
                                    {...register("diagnosisCode4")}
                                    hasError={!!errors.diagnosisCode4}
                                    errorText={errors.diagnosisCode4?.message}
                                />
                                <Button
                                    variant="normal"
                                    className="flex items-center gap-6 border border-strokedark text-xs 
        hover:bg-strokedark focus:bg-strokedark
        "
                                    onClick={() =>
                                        setValue("diagnosisCode4", "")
                                    }
                                >
                                    <span>-</span> Remove
                                </Button>
                            </div>
                        ) : null}

                        {/* code 5 */}
                        {watch("diagnosisCode5") ? (
                            <div className="w-full flex flex-col md:flex-row md:items-center gap-12">
                                <Input
                                    placeholder="F90.9  (ADHD Unspecified type)"
                                    {...register("diagnosisCode5")}
                                    hasError={!!errors.diagnosisCode5}
                                    errorText={errors.diagnosisCode5?.message}
                                />
                                <Button
                                    variant="normal"
                                    className="flex items-center gap-6 border border-strokedark text-xs 
        hover:bg-strokedark focus:bg-strokedark
        "
                                    onClick={() =>
                                        setValue("diagnosisCode5", "")
                                    }
                                >
                                    <span>-</span> Remove
                                </Button>
                            </div>
                        ) : null}

                        {/* code 6 */}
                        {watch("diagnosisCode6") ? (
                            <div className="w-full flex flex-col md:flex-row md:items-center gap-12">
                                <Input
                                    placeholder="F90.9  (ADHD Unspecified type)"
                                    {...register("diagnosisCode6")}
                                    hasError={!!errors.diagnosisCode6}
                                    errorText={errors.diagnosisCode6?.message}
                                />
                                <Button
                                    variant="normal"
                                    className="flex items-center gap-6 border border-strokedark text-xs 
        hover:bg-strokedark focus:bg-strokedark
        "
                                    onClick={() =>
                                        setValue("diagnosisCode6", "")
                                    }
                                >
                                    <span>-</span> Remove
                                </Button>
                            </div>
                        ) : null}

                        {/* code 7 */}
                        {watch("diagnosisCode7") ? (
                            <div className="w-full flex flex-col md:flex-row md:items-center gap-12">
                                <Input
                                    placeholder="F90.9  (ADHD Unspecified type)"
                                    {...register("diagnosisCode7")}
                                    hasError={!!errors.diagnosisCode7}
                                    errorText={errors.diagnosisCode7?.message}
                                />
                                <Button
                                    variant="normal"
                                    className="flex items-center gap-6 border border-strokedark text-xs 
        hover:bg-strokedark focus:bg-strokedark
        "
                                    onClick={() =>
                                        setValue("diagnosisCode7", "")
                                    }
                                >
                                    <span>-</span> Remove
                                </Button>
                            </div>
                        ) : null}

                        {/* code 8 */}
                        {watch("diagnosisCode8") ? (
                            <div className="w-full flex flex-col md:flex-row md:items-center gap-12">
                                <Input
                                    placeholder="F90.9  (ADHD Unspecified type)"
                                    {...register("diagnosisCode8")}
                                    hasError={!!errors.diagnosisCode8}
                                    errorText={errors.diagnosisCode8?.message}
                                />
                                <Button
                                    variant="normal"
                                    className="flex items-center gap-6 border border-strokedark text-xs 
        hover:bg-strokedark focus:bg-strokedark
        "
                                    onClick={() =>
                                        setValue("diagnosisCode8", "")
                                    }
                                >
                                    <span>-</span> Remove
                                </Button>
                            </div>
                        ) : null}

                        {/* code 9 */}
                        {watch("diagnosisCode9") ? (
                            <div className="w-full flex flex-col md:flex-row md:items-center gap-12">
                                <Input
                                    placeholder="F90.9  (ADHD Unspecified type)"
                                    {...register("diagnosisCode9")}
                                    hasError={!!errors.diagnosisCode9}
                                    errorText={errors.diagnosisCode9?.message}
                                />
                                <Button
                                    variant="normal"
                                    className="flex items-center gap-6 border border-strokedark text-xs 
        hover:bg-strokedark focus:bg-strokedark
        "
                                    onClick={() =>
                                        setValue("diagnosisCode9", "")
                                    }
                                >
                                    <span>-</span> Remove
                                </Button>
                            </div>
                        ) : null}

                        {/* code 10 */}
                        {watch("diagnosisCode10") ? (
                            <div className="w-full flex flex-col md:flex-row md:items-center gap-12">
                                <Input
                                    placeholder="F90.9  (ADHD Unspecified type)"
                                    {...register("diagnosisCode10")}
                                    hasError={!!errors.diagnosisCode10}
                                    errorText={errors.diagnosisCode10?.message}
                                />
                                <Button
                                    variant="normal"
                                    className="flex items-center gap-6 border border-strokedark text-xs 
        hover:bg-strokedark focus:bg-strokedark
        "
                                    onClick={() =>
                                        setValue("diagnosisCode10", "")
                                    }
                                >
                                    <span>-</span> Remove
                                </Button>
                            </div>
                        ) : null}

                        {/* code 11 */}
                        {watch("diagnosisCode11") ? (
                            <div className="w-full flex flex-col md:flex-row md:items-center gap-12">
                                <Input
                                    placeholder="F90.9  (ADHD Unspecified type)"
                                    {...register("diagnosisCode11")}
                                    hasError={!!errors.diagnosisCode11}
                                    errorText={errors.diagnosisCode11?.message}
                                />
                                <Button
                                    variant="normal"
                                    className="flex items-center gap-6 border border-strokedark text-xs 
        hover:bg-strokedark focus:bg-strokedark
        "
                                    onClick={() =>
                                        setValue("diagnosisCode11", "")
                                    }
                                >
                                    <span>-</span> Remove
                                </Button>
                            </div>
                        ) : null}

                        {/* code 12 */}
                        {watch("diagnosisCode12") ? (
                            <div className="w-full flex flex-col md:flex-row md:items-center gap-12">
                                <Input
                                    placeholder="F90.9  (ADHD Unspecified type)"
                                    {...register("diagnosisCode12")}
                                    hasError={!!errors.diagnosisCode12}
                                    errorText={errors.diagnosisCode12?.message}
                                />
                                <Button
                                    variant="normal"
                                    className="flex items-center gap-6 border border-strokedark text-xs 
        hover:bg-strokedark focus:bg-strokedark
        "
                                    onClick={() =>
                                        setValue("diagnosisCode12", "")
                                    }
                                >
                                    <span>-</span> Remove
                                </Button>
                            </div>
                        ) : null}

                        <Button
                            className="flex items-center gap-2 ml-auto text-xs"
                            disabled={diagnosisCodesCount === 12}
                            onClick={() =>
                                setValue(
                                    `diagnosisCode${
                                        diagnosisCodesCount + 1
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    }` as any,
                                    " "
                                )
                            }
                        >
                            <span>+</span> Add new
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { parseISO } from "date-fns";
import { DatePicker, Input, TextArea } from "@jhool-io/fe-components";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { IClientFee, IEditClientFee } from "../../../types/clients.types";
import { calculateFees } from "../../../../../utils/helpers/fees/fees";
import {
    handleFormatDatePickerValue,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../../utils/helpers";
import { useEditClientCurrentFee } from "../../../hooks/clients.mutations";
import useToast from "../../../../../hooks/useToast";
import { useFetchPracticeSettings } from "../../../../practice-settings/hooks/queries/practice-settings";

interface EditClientFeeFormProps {
    fee: IClientFee;
    onFormSubmit(): void;
}

const schema = yup.object({
    cal_year: yup.date().required("Calender Year is required"),
    start_date: yup.date().required("Start Date is required"),
    end_date: yup
        .date()
        .required("End Date is required")
        .min(
            yup.ref("start_date"),
            "The end date cannot be earlier than the start date"
        ),
    reason_for_update: yup.string().required("Reason for update is required"),
    masters_fee: yup.string().test({
        test: (val) => (val ? Boolean(calculateFees(val as string)) : true),
        message: "Invalid fee format",
    }),
    drs_fee: yup.string().test({
        test: (val) => (val ? Boolean(calculateFees(val as string)) : true),
        message: "Invalid fee format",
    }),
    intern_fee: yup.string().test({
        test: (val) => (val ? Boolean(calculateFees(val as string)) : true),
        message: "Invalid fee format",
    }),
    testing_fee: yup.string().test({
        test: (val) => (val ? Boolean(calculateFees(val as string)) : true),
        message: "Invalid fee format",
    }),
    psych_fee: yup.string().test({
        test: (val) => (val ? Boolean(calculateFees(val as string)) : true),
        message: "Invalid fee format",
    }),
    lic_fee: yup.string().test({
        test: (val) => (val ? Boolean(calculateFees(val as string)) : true),
        message: "Invalid fee format",
    }),
    inn_masters_fee: yup.string().test({
        test: (val) => (val ? Boolean(calculateFees(val as string)) : true),
        message: "Invalid fee format",
    }),
});

export default function EditClientFeeForm({
    fee,
    onFormSubmit,
}: EditClientFeeFormProps) {
    const [startDate, setStartDate] = React.useState<Date | null>(
        parseISO(fee.start_date)
    );
    const [endDate, setEndDate] = React.useState<Date | null>(
        parseISO(fee.end_date)
    );

    const { clientId } = useParams();

    const { mutate } = useEditClientCurrentFee(clientId || "", fee.fee_id);

    const queryClient = useQueryClient();

    const { toast } = useToast();

    const { data: practiceFeeSettings } = useFetchPracticeSettings({
        setting_name: "fee_type",
        category: "fees",
    });

    const practiceHasINNFee =
        practiceFeeSettings &&
        practiceFeeSettings?.data.settings.length > 0 &&
        practiceFeeSettings?.data.settings[0].current_value === "INN";

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IEditClientFee>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const desiredFeeValuesToDisplay = [
        "drs fee",
        "lic_fee",
        "masters fee",
        "intern fee",
        "psych fee",
        "testing fee",
        "inn masters fee",
    ];

    const getFeeValuesInDesiredFormat = () => {
        const orderMap: { [key: string]: number } = {};
        desiredFeeValuesToDisplay.forEach((type, index) => {
            orderMap[type] = index;
        });

        const filteredFeeValues = fee.fee_values.filter((val) =>
            desiredFeeValuesToDisplay.includes(val.type)
        );

        return filteredFeeValues;
    };

    const getSingleFeeValueForForm = (feeType: string) => {
        return getFeeValuesInDesiredFormat().find(
            (item) => item.type === feeType
        );
    };

    const onSubmit = (payload: IEditClientFee) => {
        const dataToSend = {
            ...payload,
            start_date: handleFormatDatePickerValue(payload.start_date),
            end_date: handleFormatDatePickerValue(payload.end_date),
            inn_masters_fee: payload.inn_masters_fee || undefined,
        };

        if (fee.cal_year !== payload.cal_year)
            dataToSend.cal_year = new Date(payload.cal_year).getFullYear();

        mutate(dataToSend, {
            onSuccess: (res) => {
                queryClient.invalidateQueries({
                    queryKey: [clientId, "fees"],
                });
                toast({
                    mode: "success",
                    message: res.message || "Client fee updated successfully",
                });
                onFormSubmit();
            },
            onError: (error) => {
                toast({
                    mode: "error",
                    message:
                        error.response?.data.message ||
                        "Could not update client fee at this time",
                });
            },
        });
    };

    return (
        <form id="edit-fees" onSubmit={handleSubmit(onSubmit)}>
            <div className="py-16 px-16 border-b border-b-stroke-divider">
                <div className="grid grid-cols-[repeat(auto-fit,minmax(70px,1fr))] rounded-r8 bg-card-bg gap-8 p-12">
                    <div className="flex flex-col items-start gap-y-12 text-xs font-medium">
                        <span className="uppercase text-gray leading-normal">
                            oonded
                        </span>
                        <span>{fee.oonded || "n/a"}</span>
                    </div>
                    <div className="flex flex-col items-start gap-y-12 text-xs font-medium">
                        <span className="uppercase text-gray leading-normal">
                            oonco
                        </span>
                        <span>{fee.oonco || "n/a"}</span>
                    </div>
                    <div className="flex flex-col items-start gap-y-12 text-xs font-medium">
                        <span className="uppercase text-gray leading-normal">
                            oonoop
                        </span>
                        <span>{fee.oonoop || "n/a"}</span>
                    </div>
                    <div className="flex flex-col items-start gap-y-12 text-xs font-medium">
                        <span className="uppercase text-gray leading-normal">
                            ooncoins
                        </span>
                        <span>{fee.ooncoins || "n/a"}</span>
                    </div>
                    <div className="flex flex-col items-start gap-y-12 text-xs font-medium">
                        <span className="uppercase text-gray leading-normal">
                            innded
                        </span>
                        <span>{fee.innded || "n/a"}</span>
                    </div>
                    <div className="flex flex-col items-start gap-y-12 text-xs font-medium">
                        <span className="uppercase text-gray leading-normal">
                            innco
                        </span>
                        <span>{fee.innco || "n/a"}</span>
                    </div>
                    <div className="flex flex-col items-start gap-y-12 text-xs font-medium">
                        <span className="uppercase text-gray leading-normal">
                            innoop
                        </span>
                        <span>{fee.innoop || "n/a"}</span>
                    </div>
                    <div className="flex flex-col items-start gap-y-12 text-xs font-medium">
                        <span className="uppercase text-gray leading-normal">
                            inncoins
                        </span>
                        <span>{fee.inncoins || "n/a"}</span>
                    </div>
                    <div className="flex flex-col items-start gap-y-12 text-xs font-medium">
                        <span className="uppercase text-gray leading-normal">
                            ucr
                        </span>
                        <span>{fee.ucr || "n/a"}</span>
                    </div>
                </div>
            </div>
            <div className="pt-24 px-16">
                <div className="fg">
                    <Input
                        {...register("cal_year")}
                        defaultValue={fee.cal_year}
                        label="Calender Year"
                        placeholder="Calender Year"
                        hasError={!!errors.cal_year}
                        errorText={
                            errors.cal_year?.message?.includes(
                                "cal_year must be a `date` type, but the final value was"
                            )
                                ? "Invalid date"
                                : errors.cal_year?.message
                        }
                    />
                </div>
                <div className="fg fg-space-between two flex">
                    <Controller
                        name="start_date"
                        control={control}
                        defaultValue={fee.start_date}
                        render={({ field }) => (
                            <DatePicker
                                label="Start Date"
                                onChange={(date) => {
                                    field.onChange(date);
                                    setStartDate(date);
                                }}
                                selected={startDate}
                                hasError={!!errors.start_date}
                                errorText={
                                    errors.start_date?.type === "typeError"
                                        ? "Invalid date value"
                                        : errors.start_date?.message
                                }
                            />
                        )}
                    />
                    <Controller
                        name="end_date"
                        control={control}
                        defaultValue={fee.end_date}
                        render={({ field }) => (
                            <DatePicker
                                label="End Date"
                                onChange={(date) => {
                                    field.onChange(date);
                                    setEndDate(date);
                                }}
                                selected={endDate}
                                hasError={!!errors.end_date}
                                errorText={
                                    errors.start_date?.type === "typeError"
                                        ? "Invalid date value"
                                        : errors.end_date?.message
                                }
                            />
                        )}
                    />
                </div>
                <div className="fg fg-space-between two flex">
                    <Input
                        {...register("drs_fee")}
                        defaultValue={
                            getSingleFeeValueForForm("drs fee")?.value || ""
                        }
                        label={makeStringFirstLetterCapital(
                            removeEnumUnderscore(
                                getSingleFeeValueForForm("drs fee")?.type || ""
                            )
                        )}
                        hasError={!!errors?.drs_fee}
                        errorText={errors?.drs_fee?.message}
                    />
                    <Input
                        {...register("lic_fee")}
                        defaultValue={
                            getSingleFeeValueForForm("lic_fee")?.value || ""
                        }
                        label={makeStringFirstLetterCapital(
                            removeEnumUnderscore(
                                getSingleFeeValueForForm("lic_fee")?.type || ""
                            )
                        )}
                        hasError={!!errors?.lic_fee}
                        errorText={errors?.lic_fee?.message}
                    />
                </div>
                <div className="fg fg-space-between two flex">
                    <Input
                        {...register("masters_fee")}
                        defaultValue={
                            getSingleFeeValueForForm("masters fee")?.value || ""
                        }
                        label={makeStringFirstLetterCapital(
                            removeEnumUnderscore(
                                getSingleFeeValueForForm("masters fee")?.type ||
                                    ""
                            )
                        )}
                        hasError={!!errors?.masters_fee}
                        errorText={errors?.masters_fee?.message}
                    />
                    <Input
                        {...register("intern_fee")}
                        defaultValue={
                            getSingleFeeValueForForm("intern fee")?.value || ""
                        }
                        label={makeStringFirstLetterCapital(
                            removeEnumUnderscore(
                                getSingleFeeValueForForm("intern fee")?.type ||
                                    ""
                            )
                        )}
                        hasError={!!errors?.intern_fee}
                        errorText={errors?.intern_fee?.message}
                    />
                </div>
                <div className="fg fg-space-between two flex">
                    <Input
                        {...register("psych_fee")}
                        defaultValue={
                            getSingleFeeValueForForm("psych fee")?.value || ""
                        }
                        label={makeStringFirstLetterCapital(
                            removeEnumUnderscore(
                                getSingleFeeValueForForm("psych fee")?.type ||
                                    ""
                            )
                        )}
                        hasError={!!errors?.psych_fee}
                        errorText={errors?.psych_fee?.message}
                    />
                    <Input
                        {...register("testing_fee")}
                        defaultValue={
                            getSingleFeeValueForForm("testing fee")?.value || ""
                        }
                        label={makeStringFirstLetterCapital(
                            removeEnumUnderscore(
                                getSingleFeeValueForForm("testing fee")?.type ||
                                    ""
                            )
                        )}
                        hasError={!!errors?.testing_fee}
                        errorText={errors?.testing_fee?.message}
                    />
                </div>
                {practiceHasINNFee ? (
                    <div className="fg">
                        <Input
                            {...register("inn_masters_fee")}
                            defaultValue={
                                getSingleFeeValueForForm("inn masters fee")
                                    ?.value || ""
                            }
                            label="Inn masters fee"
                            hasError={!!errors?.inn_masters_fee}
                            errorText={errors?.inn_masters_fee?.message}
                        />
                    </div>
                ) : null}
                <div>
                    <TextArea
                        {...register("reason_for_update")}
                        defaultValue={fee.reason_for_update || ""}
                        label="Reason for update"
                        hasError={!!errors.reason_for_update}
                        errorText={errors.reason_for_update?.message}
                    />
                </div>
            </div>
        </form>
    );
}
